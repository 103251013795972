const ConvertRangesToQuery = (ranges, key) =>{
    let queryCond = []
    ranges.map(item =>{
        let isBetween = item.split("-")
        let isGreaterThan = item.split(">")
        let isLessThan = item.split("<")
        if(item.length === 1 && !isNaN(item)){
          queryCond.push({ [key]: { _eq: parseInt(item) } })
        }else{
          if(isBetween.length > 1){
            queryCond.push({ _and: [{ [key]: { _gte: parseInt(isBetween[0]) } }, { [key]: { _lte: parseInt(isBetween[1]) } }] })
          }else if(isGreaterThan.length > 1){
            queryCond.push({ [key]: { _gt: parseInt(isGreaterThan[1]) } })
          }else if(isLessThan.length > 1){
            queryCond.push({ [key]: { _lt: parseInt(isLessThan[1]) } })
          }
        }
    })
    return queryCond
}
export default ConvertRangesToQuery