import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker, SelectPicker } from 'rsuite'
import { useState } from "react"
import PropTypes from "prop-types"
import { ProductStoreFilter } from "../../../gql/query"

//load components
import CheckPickerPaginate from "../CheckPickerPaginate"

const EcommerceSystemUserFilter = (props) => {
    const [filterData, setFilterData] = useState([])
    const [filters, setFilters] = useState([])
    const [filterValues, setFilterValues] = useState({})

    const systemUserActiveData = [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
    const roleData = [{ label: "Admin", value: "admin" }, { label: "Manager", value: "manager" }, { label: "Team", value: "team" }, {label: "Support", value: "support"}]
   

    const resetFilter = (value) => {
        const filtersTemp = filters.filter(item => item !== value)
        setFilters(filtersTemp)
    }
    const getFilterCondition = (filterKey) => {
        if (props.filterQueryConditions !== null) {
            let condition = props.filterQueryConditions.filter(condition => condition.filter === filterKey)
            if (condition.length) {
                return condition[0]?.condition
            }
        } else {
            return null
        }
    }
    useEffect(() => {
        props.onFilterChange(filterValues)
    }, [filterValues])
    useEffect(() => {
        if (filters.length) {
            let filterValuesTemp = { ...filterValues }
            if (!filters.includes("active")) {
                delete filterValuesTemp["active"]
            }
            if (!filters.includes("created_at")) {
                delete filterValuesTemp["created_at"]
            }
            if (!filters.includes("role")) {
                delete filterValuesTemp["role"]
            }
            setFilterValues(filterValuesTemp)
        } else {
            setFilterValues({})
        }
    }, [filters])
    useEffect(() => {
        let filterDataTemp = []
        props.activeFilters.map((filter) => {
            if (filter === "active") {
                filterDataTemp.push({ label: "Active", value: "active" })
            }
            if (filter === "role") {
                filterDataTemp.push({ label: "Role", value: "role" })
            }
            if (filter === "created_at") {
                filterDataTemp.push({ label: "Created At", value: "created_at" })
            }
        })
        setFilterData(filterDataTemp)
    }, [props.activeFilters])
    return (
        <div className="auto-horizontal-scroll">
            <CheckPicker
                data={filterData}
                placeholder={"Filter"}
                style={{ width: 130 }}
                value={filters}
                onChange={setFilters}
                searchable={false}
            />
            {filters.map((filter, i) => {
                if (filter === "active") {
                    return (
                        <React.Fragment key={i}>
                            <SelectPicker
                                data={systemUserActiveData}
                                placeholder={"Select Status"}
                                style={{ marginLeft: 10 }}
                                onChange={(active) => setFilterValues({ ...filterValues, active })}
                                searchable={false}
                                placement="bottomEnd"
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("active")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "created_at") {
                    return (
                        <React.Fragment key={i}>
                            <DateRangePicker
                                placement="auto"
                                onChange={(created_at) => setFilterValues({ ...filterValues, created_at })}
                                style={{ marginLeft: 10 }}
                                placeholder="Select Created At Date"
                                value={Array.isArray(filterValues.created_at) ? filterValues.created_at : []}
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_at")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "role") {
                    return (
                        <React.Fragment key={i}>
                            <SelectPicker
                                data={roleData}
                                placeholder={"Select Role"}
                                style={{ marginLeft: 10 }}
                                onChange={(role) => setFilterValues({ ...filterValues, role })}
                                searchable={false}
                                placement="bottomEnd"
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("role")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
            })}
        </div>
    )
}
EcommerceSystemUserFilter.propTypes = {
    onFilterChange: PropTypes.func,
    activeFilters: PropTypes.array,
    filterQueryConditions: PropTypes.any
}
export default EcommerceSystemUserFilter