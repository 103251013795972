import React, { useEffect, useState } from 'react'
import { CheckPicker, Modal, Toggle } from "rsuite"
import { withRouter } from "react-router-dom"
import { Insert_Vehicle_Brand_One, Update_Vehicle_Brand_One } from "gql/Mutation"
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap"
import { SelectPicker } from "rsuite"
import FileUploader from "components/Common/FileUploader"
import axios from "axios"
import Swal from "sweetalert2"
import PropTypes from "prop-types"
import { useMutation } from '@apollo/client'

const VehicleBrandsCreate = (props) => {
    const [btnLoading, setBtnLoading] = useState(false)
    const [image, setImage] = useState(null)
    const [vehicleGroupValue, setVehicleGroupValue] = useState('')
    const [name, setName] = useState("")
    const [isActive, setActive] = useState(false)
    const [CreateBrandMutation] = useMutation(Insert_Vehicle_Brand_One)
    const [UpdateBrandMutation] = useMutation(Update_Vehicle_Brand_One)

     const handleUploadFile = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                let formData = new FormData()
                formData.append("uploads", image[0])
                const resp = await axios.post(
                    `${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`,
                    formData
                )
                if (resp.data?.success) {
                    if (Array.isArray(resp.data?.data?.uploaded_files)) {
                        const link = resp.data.data.uploaded_files[0].uploaded_file_url
                        resolve(link)
                    }
                } else {
                    resolve(null)
                }
            } catch (err) {
                resolve(null)
            }
        })
    }

    const [veh_group, set_veh_group] = useState(["2wheeler", "4wheeler"]?.map((item,i)=>({ label: item, value: item })))
    const [value_group, set_value_group] = useState([])

    useEffect(()=>{

        
        set_value_group(props?.category.vehicle_group)

    },[props?.category?.vehicle_group])
  
    const on_change_vehicle_group = (e) =>{
         set_value_group(e)
    }
    const handleCreateBrand = async (e) => {
        try {
            e.preventDefault()
            setBtnLoading(true)
            let logo_url = null
            if (image) {
                logo_url = await handleUploadFile()
            }
            if (props.category.id) {
                let objects = {
                    brand_name: name,
                    active: isActive,
                    vehicle_group: value_group,
                }
                if (logo_url) {
                    objects = { ...objects, logo_url: logo_url }
                }
                await UpdateBrandMutation({
                    variables: {
                        id: props?.category?.id,
                        object: objects,
                    },
                })
            } else {
                await CreateBrandMutation({
                    variables: {
                        object: {
                            brand_name: name,
                            vehicle_group: value_group,
                            logo_url: logo_url,
                        },
                    },
                })
            }
            Swal.fire(
                `Brand ${props.category?.id ? "Updated" : "Created"} Successfully`,
                "",
                "success"
            ).then(() => {
                props.onSubmit()
                props.close()
            })
        } catch (err) {
            Swal.fire("Something went wrong", "", "error")
        } finally {
            setBtnLoading(false)
        }
    }
    useEffect(() => {
        if (props.brand?.id) {
            setActive(props.category.active)
            setName(props.category.brand_name)
            setVehicleGroupValue(props.category.vehicle_group)
        } else {
            setName("")
        }
    }, [props.category])
    // useEffect(() => {
    //     if (Array.isArray(serviceType?.enum_service_type)) {
    //         let statusTemp = []
    //         serviceType.enum_service_type.map((status) => {
    //             statusTemp.push({
    //                 label: status.comments,
    //                 value: status.name,
    //             })
    //         })
    //         setCategoryServiceType(statusTemp)
    //     }
    // }, [serviceType])
    return (
        <Modal open={props.open} onClose={() => props.close()}>
            <Modal.Header>
                <Modal.Title>
                    {props.brand?.id ? "Update Brand" : "Create Brand"}
                </Modal.Title>
            </Modal.Header>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Form id="categoryform" onSubmit={handleCreateBrand}>
                                <Row>
                                    <Col sm="12">
                                        <div className="mb-3">
                                            <Label htmlFor="category_name">Brand Name</Label>
                                            <Input
                                                id="brand_name"
                                                name="brand_name"
                                                defaultValue={props.category.brand_name}
                                                type="text"
                                                className="form-control"
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <div className="mb-3">
                                            <Label>Active</Label>
                                            <Toggle
                                                checked={props?.category.active}
                                                onChange={(checked) => setActive(checked)}
                                                style={{ marginLeft: "1rem" }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
 
                                <Row>
                                    <Col sm="12">
                                        <div className="mb-3">
                                            <Label htmlFor="category_image">Brand Image</Label>
                                            <FileUploader value={props?.category.logo_url} onChange={setImage} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <div className="mb-3">
                                            <Label htmlFor="vehicle_group">Vehicle Group</Label>
                                            <CheckPicker 
                                                id="vehicle_group" 
                                                data={veh_group}
                                                value={value_group}
                                                onChange={on_change_vehicle_group}
                                                style={{ width: 224 }} />
                                        </div>
                                    </Col>
                                </Row>

                                <div className="d-flex flex-wrap gap-2">
                                    <Button
                                        color="primary"
                                        className="btn "
                                        type="submit"
                                        disabled={btnLoading}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        className=" "
                                        onClick={(e) => props.close()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Modal>
    )
}

VehicleBrandsCreate.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    brand: PropTypes.any,
    onSubmit: PropTypes.func,
    category:PropTypes.any}
export default withRouter(VehicleBrandsCreate)