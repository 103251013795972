import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withRouter, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import StarRatings from 'react-star-ratings'
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//redux
import { useQuery, useLazyQuery } from "@apollo/client"
import { ReviewsList } from "gql/query"

//filters
// import EcommerceProductsFilter from "components/Filters/Ecommerce/EcommerceProductsFilter"

const FormatReviewData = (data) => {
	const reviews = data.store_reviews.map(elem => {
		return {
			id: elem.id,
			review: elem.review || "N/A",
			ratings: elem.rating,
			created_at: elem.created_at,
			order_id: elem.order?.id,
			order_uid: elem.order?.uid
		}
	})
	return reviews
}

const EcommerceReviewsTable = props => {
	const [modal, setModal] = useState(false)
	const history = useHistory()
	const [reviews, setReviews] = useState([])
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({})

	const [getReviewList, { loading, data, error }] = useLazyQuery(ReviewsList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}
	const toggle = () => {
		setModal(!modal)
	}
	useEffect(() => {
		if (data) {
			setReviews(FormatReviewData(data))
		}
	}, [data])
	const defaultSorted = []
	const count = data?.store_reviews_aggregate?.aggregate?.count || 0
	const maxPage = Math.ceil(count / PageData.limit)

	const handleTableChange = (type, { page, searchText }) => {

	}
	const EcommerceReviewsColumns = (toggleModal) => [
		{
			text: "ID",
			dataField: "id",
			sort: false,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			dataField: "order_uid",
			text: "Order ID",
			sort: false,
			formatter: (cellContent, row) => {
				if (row.order_id) {
					return <Link to={`/ecommerce-orders/details/${row.order_id}`}>{cellContent}</Link>
				} else {
					return cellContent
				}
			}
		},
		{
			text: "Rating",
			dataField: "ratings",
			sort: false,
			formatter: (cellContent, row) =>
				<StarRatings
					rating={cellContent}
					starRatedColor="#556EE6"
					numberOfStars={5}
					name='rating'
					starDimension="14px"
					starSpacing="3px"
				/>
		},
		{
			dataField: "review",
			text: "Review",
			sort: false,
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
	]
	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			getReviewList()
		}
	}, [props.queryCondition])
	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getReviewList()
		}
	}, [queryCondition])
	var node = useRef()
	return (
		<Row>
			<Col xs="12">
				<Card>
					<CardBody>
						<ToolkitProvider
							keyField="id"
							data={reviews || []}
							columns={EcommerceReviewsColumns(toggle)}
							bootstrap4
							search
						>
							{toolkitProps => (
								<React.Fragment>
									<Row>
										<Col xl="12">
											<div className="table-responsive">
												{loading ? <Loader /> :
													<BootstrapTable
														responsive
														bordered={false}
														striped={false}
														defaultSorted={defaultSorted}
														classes={"table align-middle table-nowrap"}
														keyField="id"
														{...toolkitProps.baseProps}
														onTableChange={handleTableChange}
														ref={node}
														noDataIndication={() => "No Reviews Found!"}
													/>
												}
											</div>
										</Col>
									</Row>
									<Row className="align-items-md-center mt-40">
										<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
											<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
												Previous Page
											</Button>
											<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
											</div>
											<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
												Next Page
											</Button>
										</Col>
									</Row>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</CardBody>
				</Card>
			</Col>
		</Row>
	)
}

EcommerceReviewsTable.propTypes = {
	queryCondition: PropTypes.any,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any,
}

export default withRouter(EcommerceReviewsTable)
