import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { Modal, Toggle } from "rsuite"
import axios from "axios"
import Swal from "sweetalert2"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"

//GraphQL
import {
  Insert_ExperienceType_One,
  Update_ExperienceType_One,
} from "gql/Mutation"
import { useMutation, useApolloClient } from "@apollo/client"

//Import Breadcrumb
import FileUploader from "components/Common/FileUploader"

const ExperienceCreateType = (props) => {
  const client = useApolloClient()
  const history = useHistory()
  const [CreateCategoryMutation] = useMutation(Insert_ExperienceType_One)
  const [UpdateCategoryMutation] = useMutation(Update_ExperienceType_One)
  const [image, setImage] = useState(null)
  const [name, setName] = useState("")
  const [isActive, setActive] = useState(true)

  const [btnLoading, setBtnLoading] = useState(false)

  const handleUploadFile = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let formData = new FormData()
        formData.append("uploads", image[0])
        const resp = await axios.post(
          `${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`,
          formData
        )
        if (resp.data?.success) {
          if (Array.isArray(resp.data?.data?.uploaded_files)) {
            const link = resp.data.data.uploaded_files[0].uploaded_file_url
            resolve(link)
          }
        } else {
          resolve(null)
        }
      } catch (err) {
        resolve(null)
      }
    })
  }

  const handleCreateCategory = async (e) => {
    try {
      e.preventDefault()
      setBtnLoading(true)
      let photo_url = null
      if (image) {
        photo_url = await handleUploadFile()
      }
      if (props.category?.id) {
        let objects = {
          name: name,
          active: isActive,
        }
        if (photo_url) {
          objects = { ...objects, photo: photo_url }
        }
        await UpdateCategoryMutation({
          variables: {
            id: props.category?.id,
            object: objects,
          },
        })
      } else {
        await CreateCategoryMutation({
          variables: {
            object: {
              name: name,
              active: isActive,
              photo: photo_url,
              created_by: '7bc01544-c371-4caa-99fa-2b7897c0a912'
            },
          },
        })
      }
      Swal.fire(
        `Experience Type ${
          props.category?.id ? "Updated" : "Created"
        } Successfully`,
        "",
        "success"
      ).then(() => {
        props.onSubmit()
        props.close()
      })
    } catch (err) {
      Swal.fire("Something went wrong", "", "error")
    } finally {
      setBtnLoading(false)
    }
  }
  useEffect(() => {
    if (props.category?.id) {
      setActive(props.category.active)
      setName(props.category.name)
    } else {
      setName("")
    }
  }, [props.category])

  return (
    <Modal open={props.open} onClose={() => props.close()}>
      <Modal.Header>
        <Modal.Title>
          {props.category?.id ? "Update Experience Type" : "Create Experience Type"}
        </Modal.Title>
      </Modal.Header>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Form id="categoryform" onSubmit={handleCreateCategory}>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="category_name">Experience Type Name</Label>
                      <Input
                        id="category_name"
                        name="category_name"
                        defaultValue={name}
                        type="text"
                        className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="category_image">Type Image</Label>
                      <FileUploader onChange={setImage} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="commission_percentage">Active</Label>
                      <Toggle
                        checked={isActive}
                        onChange={(checked) => setActive(checked)}
                        style={{ marginLeft: "1rem" }}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    color="primary"
                    className="btn "
                    type="submit"
                    disabled={btnLoading}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className=" "
                    onClick={(e) => props.close()}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}
ExperienceCreateType.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onSubmit: PropTypes.func,
  category: PropTypes.any,
}
export default withRouter(ExperienceCreateType)
