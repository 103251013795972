import React, { useEffect, useState, useRef } from "react"
import PropTypes, { element } from "prop-types"
import { withRouter, Link } from "react-router-dom"
import Loader from "components/Loader/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit"
import { useMutation, useLazyQuery } from "@apollo/client"
import { PromotionList } from "gql/query"
import { useHistory } from "react-router-dom"
import { UpdatePromotion } from "gql/Mutation"
import { GoPlus } from "react-icons/go"
import Swal from "sweetalert2"
import { SelectPicker } from "rsuite"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import { Dropdown, Form } from "react-bootstrap"

//import filters
import EcommercePromotionFilter from "components/Filters/Ecommerce/EcommercePromotionFilter"

//Functions
import {
  EcommercePromotionColumns,
  FormatData,
  BuildPromotionSearchQuery,
  BuildPromotionFilterQuery,
} from "./EcommercePromotions.functions"

//components
import EcommerceCreatePromotion from "../EcommerceCreatePromotion"

import { useSelector } from "react-redux"
import EcommerceCreateServicePromotion from "../EcommerceCreateServicePromotion"
import EcommerceCreateRentalPromotion from "../EcommerceCreateRentalPromotion"
const EcommercePromotionsTable = (props) => {
  const { capabilities } = useSelector((state) => state.Login)
  const history = useHistory()
  const { ExportCSVButton } = CSVExport
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  })
  const [queryCondition, setQueryCondition] = useState({})
  const [filterValue, setFilterValue] = useState("")
  const [searchKey, setSearchKey] = useState("")
  const [createPromotionOpen, setCreatePromotionOpen] = useState(false)
  const [selectedPromotion, setSelectedPromotion] = useState({})
  const limitData = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ]

  console.log("Creawteprooanidoisas", createPromotionOpen)

  const [updatePromotionMutation] = useMutation(UpdatePromotion)
  const [getPromotionList, { loading, data, called, refetch }] = useLazyQuery(
    PromotionList,
    {
      skipCache: true,
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    }
  )
  const PageDown = () => {
    if (PageData.pageNo > 0)
      setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
  }
  const PageUp = () => {
    setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
  }
  const [orders, setOrders] = useState([])
  useEffect(() => {
    if (data && !loading) {
      setOrders(FormatData(data))
    }
  }, [data])
  const count = data?.promotions_aggregate?.aggregate?.count || 0
  const maxPage = Math.ceil(count / PageData.limit)
  const selectRow = {
    mode: "checkbox",
  }
  const handleSearch = (value) => {
    const conditionTemp = BuildPromotionSearchQuery(value, props.queryCondition)
    setQueryCondition(conditionTemp)
  }

  const handleRefetch = () => {
    if (called) {
      refetch()
    } else {
      getPromotionList()
    }
  }
  const handleChangeStatus = async (promotionId, active) => {
    try {
      Swal.fire({
        title: "Are you sure want to change the status?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          updatePromotionMutation({
            variables: {
              id: promotionId,
              object: {
                active,
              },
            },
          })
            .then(() => {
              handleRefetch()
            })
            .catch(() => {
              Swal.fire("Something went wrong!", "", "error")
            })
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  var node = useRef()
  const defaultSorted = []
  useEffect(() => {
    let conditionTemp = BuildPromotionFilterQuery(
      filterValue,
      props.queryCondition
    )
    setQueryCondition(conditionTemp)
  }, [filterValue])
  useEffect(() => {
    if (props.queryCondition !== null) {
      setQueryCondition(props.queryCondition)
    } else {
      getPromotionList()
    }
  }, [props.queryCondition])
  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getPromotionList()
    }
  }, [queryCondition])
  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey)
    } else {
      refetch()
    }
  }, [searchKey])
  const isEdit = capabilities.includes("edit")
  const pathName = props.pathName
  return (
    <Row>
      <Col xs="12">
        <Card>
          <CardBody>
            <ToolkitProvider
              keyField="id"
              data={orders}
              columns={EcommercePromotionColumns(
                searchKey,
                filterValue,
                history,
                handleChangeStatus,
                isEdit
              )}
              bootstrap4
              exportCSV={{ onlyExportSelection: true, exportAll: true }}
              // search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  {props.showFilters && (
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault()
                              }}
                            >
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <i className="bx bx-search-alt search-icon" />
                                <Form.Control
                                  type="text"
                                  placeholder="Search Promotion"
                                  onChange={(e) => setSearchKey(e.target.value)}
                                  value={searchKey}
                                />
                              </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end d-flex justify-content-end">
                          {capabilities.includes("export") && (
                            <ExportCSVButton
                              {...toolkitProps.csvProps}
                              style={{
                                border: "1px solid #74788d",
                                marginRight: 10,
                              }}
                            >
                              Export
                            </ExportCSVButton>
                          )}
                          <EcommercePromotionFilter
                            onFilterChange={setFilterValue}
                            activeFilters={props.activeFilters}
                            filterQueryConditions={props.filterQueryConditions}
                          />
                          {/* {capabilities.includes("create") && ( */}
                            <Button
                              color="primary"
                              onClick={() => setCreatePromotionOpen(true)}
                              style={{ marginLeft: 10 }}
                            >
                              <GoPlus style={{ marginRight: 5 }} />
                              Create Promotion
                            </Button>
                          {/* )} */}
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xl="12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <b>No. of Promotions:</b> {count}
                        </div>
                        <div>
                          <SelectPicker
                            data={limitData}
                            searchable={false}
                            style={{ width: 100 }}
                            placeholder="Limit"
                            value={PageData.limit}
                            onChange={(value) =>
                              setPageData({ ...PageData, limit: value })
                            }
                            cleanable={false}
                          />
                        </div>
                      </div>
                      <div className="mb-3" />
                      <div className="table-responsive">
                        {loading ? (
                          <Loader />
                        ) : (
                          <BootstrapTable
                            key={`${searchKey}_${JSON.stringify(filterValue)}`}
                            keyField="id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={
                              props.showFilters ? selectRow : undefined
                            }
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            // {...paginationTableProps}
                            ref={node}
                            noDataIndication={() => "No Promotions Found!"}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-40">
                    <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                      <Button
                        disabled={PageData.pageNo <= 1}
                        onClick={() => {
                          PageDown()
                        }}
                      >
                        Previous Page
                      </Button>
                      <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}</div>
                      <Button
                        disabled={PageData.pageNo >= maxPage}
                        onClick={() => {
                          PageUp()
                        }}
                      >
                        Next Page
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Col>
      {capabilities.includes("create") &&
        (pathName.startsWith("/groceries") ? (
          <EcommerceCreatePromotion
            open={createPromotionOpen}
            close={() => setCreatePromotionOpen(false)}
            onSubmit={handleRefetch}
            promotion={selectedPromotion}
          />
        ) : pathName.startsWith("/services") ? (
          <EcommerceCreateServicePromotion
            open={createPromotionOpen}
            close={() => setCreatePromotionOpen(false)}
            onSubmit={handleRefetch}
            promotion={selectedPromotion}
          />
        ) : 
          pathName.startsWith("/vehicles") ? (
          <EcommerceCreateRentalPromotion
            open={createPromotionOpen}
            close={() => setCreatePromotionOpen(false)}
            onSubmit={handleRefetch}
            promotion={selectedPromotion}
          />)
          : null)}
    </Row>
  )
}
EcommercePromotionsTable.propTypes = {
  queryCondition: PropTypes.any,
  activeFilters: PropTypes.array,
  filterQueryConditions: PropTypes.any,
  showFilters: PropTypes.bool,
  pathName: PropTypes.any,
}
export default withRouter(EcommercePromotionsTable)
