import { toast } from 'react-toastify'
const showAlert = (params) => {
  toast[params.type](params.message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    })
}
export default showAlert