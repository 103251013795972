import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import Loader from "../../../components/Loader/Loader"
import { filter, isEmpty } from "lodash"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { useHistory } from "react-router"
import Highlighter from "react-highlight-words"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  UncontrolledDropdown,
} from "reactstrap"
import Swal from "sweetalert2"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical } from "react-icons/go"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { SelectPicker } from "rsuite"

import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { MerchantList } from "gql/query"
//redux
import { useSelector, useDispatch } from "react-redux"
import { useQuery, useMutation } from "@apollo/client"
import {
  AddCustomerFormDataQuery,
  InsertCustomersOneMutation,
  UpdateCustomerMutation,
  DeleteCustomerMutation,
  UpdateVendorCompany,
} from "./EcommerceMerchants.query"

//import filters
import EcommerceMerchantsFilter from "components/Filters/Ecommerce/EcommerceMerchantsFilter"
//Helpers
import ConvertRangesToQuery from "helpers/range_to_query_helper"
const FormatData = (data) => {
  const users = data.users_filter_view.map((elem) => {
    let docs_submitted = false,
      gst_available = false,
      business_type = "N/A"
    if (
      elem.aadhar_image !== null ||
      elem.stores[0]?.gst_photo !== null ||
      elem.stores[0]?.pan_photo !== null
    ) {
      docs_submitted = true
    }
    gst_available =
      elem.stores.filter((store) => store.gst_available).length > 0
        ? true
        : false
    if (elem.stores?.length) {
      business_type = elem.stores[0]?.business_type
    }
    let payout_due_date = "N/A"
    if (elem.payouts?.length) {
      payout_due_date = moment(
        Math.max(...elem.payouts.map((e) => new Date(e.end_date)))
      ).format("DD/MM/YYYY")
    }
    return {
      merchant_id: elem?.vendor_company?.merchant_id || "N/A",
      business_name: elem?.vendor_company?.name || "N/A",
      business_contact: elem?.vendor_company?.email || "N/A",
      name: elem.name,
      email: elem.email,
      id: elem.id,
      phone: elem.phone,
      joiningDate: elem.created_at,
      role: elem.role,
      active: elem.active,
      status: elem?.vendor_company?.verification_status || "registered",
      city: elem?.vendor_company?.city || "N/A",
      no_of_stores: elem?.stores_aggregate?.aggregate?.count || 0,
      no_of_products: elem?.products_aggregate?.aggregate?.count || 0,
      referred_by: elem?.reference_code || "N/A",
      docs_submitted,
      docs_verified:
        elem?.vendor_company?.verification_status === "verified" ? true : false,
      gst_available,
      business_type,
      active_payouts: elem.payouts?.length || 0,
      payout_due_date,
    }
  })
  return users
}

const getAddCustomerFormData = (queryData) => {
  const { data } = queryData
  if (data) {
    const customerRole = data.enum_user_roles.map((elem) => ({
      id: elem.value,
      name: elem.comment || elem.value,
    }))
    return {
      customerRole,
    }
  }
  return {
    customerRole: [],
  }
}

const EcommerceMerchants = (props) => {
  const pathName = props.location.pathname
  const { capabilities } = useSelector((state) => state.Login)
  const { ExportCSVButton } = CSVExport
  const history = useHistory()
  const [Selectecustomer, setSelectecustomer] = useState(null)
  const [users, setUsers] = useState([])
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  })
  const [queryCondition, setQueryCondition] = useState(
    pathName.startsWith("/groceries")
      ? {
          stores: {
            module: {
              _eq: "grocery",
            },
          },
        }
      : pathName.startsWith("/services")
      ? { stores: { module: { _eq: "service" } } }
      : pathName.startsWith("/vehicles")
      ? { stores: { module: { _eq: "rental" } } }
      : pathName.startsWith("/property")
      ? { stores: { module: { _eq: "property" } } }
      : pathName.startsWith("/experience")
      ? { stores: { module: { _eq: "experience" } } }
      : {}
  )
  const [filterKey, setFilterKey] = useState("")
  const [filterValue, setFilterValue] = useState("")
  const [searchKey, setSearchKey] = useState("")
  const limitData = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ]

  const {
    data,
    loading,
    error,
    refetch: customerListRefetch,
  } = useQuery(MerchantList, {
    fetchPolicy: "cache-and-network",
    variables: {
      limit: PageData.limit,
      offset: (PageData.pageNo - 1) * PageData.limit,
      where: queryCondition,
    },
  })

  const handleSearch = (value) => {
    if (value.length >= 3) {
      setQueryCondition({
        _or: [
          { vendor_company: { merchant_id: { _ilike: `%${value}%` } } },
          { vendor_company: { name: { _ilike: `%${value}%` } } },
          { vendor_company: { email: { _ilike: `%${value}%` } } },
          { vendor_company: { city: { _ilike: `%${value}%` } } },
          { vendor_company: { verification_status: { _ilike: `%${value}%` } } },
          { name: { _ilike: `%${value}%` } },
          { email: { _ilike: `%${value}%` } },
          { phone: { _ilike: `%${value}%` } },
        ],
      })
    } else {
      setQueryCondition({})
    }
  }
  const PageDown = () => {
    if (PageData.pageNo > 0)
      setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
  }
  const PageUp = () => {
    setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
  }

  useEffect(() => {
    if (data) {
      setUsers(FormatData(data))
    }
  }, [data])
  useEffect(() => {
    if (Object.keys(filterValue).length) {
      let conditionTemp = {}
      if (filterValue?.hasOwnProperty("status")) {
        if (filterValue.status?.length > 0) {
          conditionTemp = {
            ...conditionTemp,
            vendor_company: {
              verification_status: { _in: filterValue.status },
            },
          }
        }
      }
      if (filterValue?.hasOwnProperty("referred_by")) {
        if (filterValue.referred_by?.length > 0) {
          conditionTemp = {
            ...conditionTemp,
            reference_code: { _ilike: `%${filterValue.referred_by}%` },
          }
        }
      }
      if (filterValue?.hasOwnProperty("docs_submitted")) {
        let isNull = filterValue.docs_submitted === "yes" ? false : true
        conditionTemp = {
          ...conditionTemp,
          [filterValue === "yes" ? "_or" : "_and"]: [
            { aadhar_image: { _is_null: isNull } },
            { stores: { gst_photo: { _is_null: isNull } } },
            { stores: { pan_photo: { _is_null: isNull } } },
          ],
        }
      }
      if (filterValue?.hasOwnProperty("docs_verified")) {
        if (filterValue.docs_verified === "yes") {
          conditionTemp = {
            ...conditionTemp,
            vendor_company: { verification_status: { _eq: "verified" } },
          }
        } else {
          conditionTemp = {
            ...conditionTemp,
            vendor_company: { verification_status: { _neq: "verified" } },
          }
        }
      }
      if (filterValue?.hasOwnProperty("city")) {
        if (filterValue.city?.length > 0) {
          conditionTemp = {
            ...conditionTemp,
            vendor_company: { city: { _in: filterValue.city } },
          }
        }
      }
      if (filterValue?.hasOwnProperty("gst_available")) {
        if (filterValue.gst_available === "yes") {
          conditionTemp = {
            ...conditionTemp,
            stores: { gst_available: { _eq: true } },
          }
        } else {
          conditionTemp = {
            ...conditionTemp,
            _not: { stores: { gst_available: { _eq: true } } },
          }
        }
      }
      if (filterValue?.hasOwnProperty("total_stores")) {
        if (filterValue.total_stores?.length > 0) {
          let totalStoresFilter = ConvertRangesToQuery(
            filterValue.total_stores,
            "total_stores"
          )
          conditionTemp = {
            ...conditionTemp,
            _or: totalStoresFilter,
          }
        }
      }
      setQueryCondition(conditionTemp)
    } else {
      setQueryCondition(
        pathName.startsWith("/groceries")
          ? {
              stores: {
                module: {
                  _eq: "grocery",
                },

                // store_type: {
                //   _eq: "Groceries and Essentials",
                // },
              },
            }
          : pathName.startsWith("/services")
          ? { stores: { module: { _eq: "service" } } }
          : pathName.startsWith("/vehicles")
          ? { stores: { module: { _eq: "rental" } } }
          : pathName.startsWith("/property")
          ? { stores: { module: { _eq: "property" } } }
          : pathName.startsWith("/experience")
          ? { stores: { module: { _eq: "experience" } } }
          : {}
      )
    }
  }, [filterValue])
  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey)
    } else {
      customerListRefetch()
    }
  }, [searchKey])

  const dispatch = useDispatch()

  const count = data?.users_filter_view_aggregate?.aggregate?.count || 0
  const maxPage = Math.ceil(count / PageData.limit)

  const [modal, setModal] = useState(false)

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: users.length, // replace later with size(orders),
    custom: true,
  }

  const toggle = () => {
    setModal(!modal)
  }

  var node = useRef()
  /**
   * Handling submit customer on customer form
   */

  const [insertCustomersOne] = useMutation(InsertCustomersOneMutation)
  const [deleteCustomerMutation] = useMutation(DeleteCustomerMutation)
  const [updateCustomerMutation] = useMutation(UpdateCustomerMutation)
  const [UpdateVendorCompanyMutation] = useMutation(UpdateVendorCompany)

  const resetFilter = () => {
    setFilterValue("")
    setFilterKey("")
  }
  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    // setSelectedMerchant(
    //   users.filter(customer =>
    //     Object.keys(customer).some(key =>
    //       customer[key].toLowerCase().includes(searchText.toLowerCase())
    //     )
    //   )
    // )
  }
  const handleChangeStatus = async (id, status) => {
    try {
      Swal.fire({
        title: "Are you sure want to change the status?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await UpdateVendorCompanyMutation({
            variables: {
              id,
              set: { verification_status: status },
            },
          })
          customerListRefetch()
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
  const formatHighlighter = (text) => {
    let searchWords = [searchKey]
    if (Array.isArray(filterValue?.status)) {
      searchWords = [...searchWords, ...filterValue?.status]
    }
    return (
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={String(text)}
        highlightStyle={{ background: "#ff0" }}
      />
    )
  }

  const EcommerceMerchantColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "merchant_id",
      text: "Merchant ID",
      sort: false,
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <Link
              to={
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-merchants/details/${row.id}`
                  : pathName.startsWith("/services")
                  ? `/services/service-merchants/details/${row.id}`
                  : pathName.startsWith("/vehicles")
                  ? `/vehicles/vehicles-merchants/details/${row.id}`
                  : ""
              }
            >
              {formatHighlighter(row.merchant_id)}
            </Link>
          )
        } else {
          return formatHighlighter(row.merchant_id)
        }
      },
    },
    {
      dataField: "business_name",
      text: "Business Name",
      sort: true,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      dataField: "business_contact",
      text: "Business Contact",
      sort: true,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    // {
    //   dataField: "phone",
    //   text: "Phone",
    //   sort: true,
    // },
    // {
    //   text: "Email",
    //   dataField: "email",
    //   sort: true,

    // },
    {
      text: "City",
      dataField: "city",
      sort: true,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      dataField: "joiningDate",
      text: "DOJ",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.joiningDate),
    },
    {
      text: "No. of Stores",
      dataField: "no_of_stores",
      sort: true,
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <Link
              to={
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-merchants/details/${row.id}?tab=stores`
                  : pathName.startsWith("/services")
                  ? `/services/service-merchants/details/${row.id}?tab=stores`
                  : pathName.startsWith("/property")
                  ? `/property/property-merchants/details/${row.id}?tab=stores`
                  : pathName.startsWith("/experience")
                  ? `/experience/experience-merchants/details/${row.id}?tab=stores`
                  : ""
              }
            >
              {row.no_of_stores}
            </Link>
          )
        } else {
          return row.no_of_stores
        }
      },
    },
    {
      text: "No. of Products",
      dataField: "no_of_products",
      sort: true,
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <Link
              to={
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-merchants/details/${row.id}?tab=products`
                  : pathName.startsWith("/services")
                  ? `/services/service-merchants/details/${row.id}?tab=products`
                  : pathName.startsWith("/property")
                  ? `/property/property-merchants/details/${row.id}?tab=products`
                  : pathName.startsWith("/experience")
                  ? `/experience/experience-merchants/details/${row.id}?tab=products`
                  : ""
              }
            >
              {cellContent}
            </Link>
          )
        } else {
          return cellContent
        }
      },
    },
    {
      text: "Referred By",
      dataField: "referred_by",
      sort: false,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      text: "GST Required",
      dataField: "gst_available",
      sort: false,
      formatter: (cellContent, row) => <>{row.gst_available ? "Yes" : "No"}</>,
    },
    {
      text: "Business Type",
      dataField: "business_type",
      sort: false,
    },
    {
      text: "Docs Submitted",
      dataField: "docs_submitted",
      sort: false,
      formatter: (cellContent, row) => <>{row.docs_submitted ? "Yes" : "No"}</>,
    },
    {
      text: "Docs Verified",
      dataField: "docs_verified",
      sort: false,
      formatter: (cellContent, row) => <>{row.docs_verified ? "Yes" : "No"}</>,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      text: "Active Payouts",
      dataField: "active_payouts",
      sort: true,
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <Link
              to={
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-merchants/details/${row.id}?tab=payouts`
                  : pathName.startsWith("/services")
                  ? `/services/service-merchants/details/${row.id}?tab=payouts`
                  : pathName.startsWith("/property")
                  ? `/property/property-merchants/details/${row.id}?tab=payouts`
                  : pathName.startsWith("/experience")
                  ? `/experience/experience-merchants/details/${row.id}?tab=payouts`
                  : ""
              }
            >
              {cellContent}
            </Link>
          )
        } else {
          return cellContent
        }
      },
    },
    {
      text: "Payout Due Date",
      dataField: "payout_due_date",
      sort: true,
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Action",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Dropdown>
          <Dropdown.Toggle variant="transparent" id="dropdown-basic">
            <GoKebabVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                history.push(
                  pathName.startsWith("/groceries")
                    ? `/groceries/ecommerce-merchants/details/${row.id}`
                    : pathName.startsWith("/services")
                    ? `/services/service-merchants/details/${row.id}`
                    : pathName.startsWith("/vehicles")
                    ? `/vehicles/vehicles-merchants/details/${row.id}`
                    : pathName.startsWith("/property")
                    ? `/property/property-merchants/details/${row.id}`
                    : pathName.startsWith("/experience")
                    ? `/experience/experience-merchants/details/${row.id}`
                    : ""
                )
              }
            >
              View Details
            </Dropdown.Item>
            {capabilities.includes("edit") && (
              <Dropdown.Item
                onClick={() =>
                  handleChangeStatus(
                    row.id,
                    row.status === "verified" ? "rejected" : "verified"
                  )
                }
              >
                {row.status === "verified" ? "Deactivate" : "Activate"}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
    // {
    //   dataField: "action",
    //   isDummyField: true,
    //   text: "Action",
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, order) => (
    //     <>
    //       <div className="d-flex gap-3">
    //         <Link
    //           to="#"
    //           className="text-success"
    //           onClick={() => handleMerchantClick(order)}
    //         >
    //           <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
    //           <UncontrolledTooltip placement="top" target="edittooltip">
    //             Edit
    //           </UncontrolledTooltip>
    //         </Link>
    //       </div>
    //     </>
    //   ),
    // }
  ]

  const defaultSorted = []
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }
  /** set Date formate */
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y")
    return date1
  }

  const AddCustomerFormData = useQuery(AddCustomerFormDataQuery, {
    skipCache: true,
  })

  const { customerRole } = getAddCustomerFormData(AddCustomerFormData)
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Merchants | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Merchants" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {/* <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={EcommerceMerchantColumns}
                    data={users}
                  > */}
                  {/* {({ paginationProps, paginationTableProps }) => ( */}
                  <ToolkitProvider
                    keyField="id"
                    data={users || []}
                    columns={EcommerceMerchantColumns}
                    bootstrap4
                    exportCSV={{ onlyExportSelection: true, exportAll: true }}
                    // search
                  >
                    {(toolkitProps) => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm="4">
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                {/* <SearchBar {...toolkitProps.searchProps} /> */}
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault()
                                  }}
                                >
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicEmail"
                                  >
                                    <i className="bx bx-search-alt search-icon" />
                                    <Form.Control
                                      type="text"
                                      placeholder="Search merchants"
                                      onChange={(e) =>
                                        setSearchKey(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                </Form>
                              </div>
                            </div>
                          </Col>
                          <Col sm="8">
                            <div className="text-sm-end d-flex justify-content-end">
                              {capabilities.includes("export") && (
                                <ExportCSVButton
                                  {...toolkitProps.csvProps}
                                  style={{
                                    border: "1px solid #74788d",
                                    marginRight: 10,
                                  }}
                                >
                                  Export
                                </ExportCSVButton>
                              )}
                              {/* <Button
                                type="button"
                                color="success"
                                className="btn-rounded  mb-2 me-2"
                                onClick={handleAddNewMerchantClick}
                              >
                                <i className="mdi mdi-plus me-1" />
                                New merchant
                              </Button> */}
                              <EcommerceMerchantsFilter
                                onFilterChange={setFilterValue}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl="12">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <b>No. of Merchants:</b> {count}
                              </div>
                              <div>
                                <SelectPicker
                                  data={limitData}
                                  searchable={false}
                                  style={{ width: 100 }}
                                  placeholder="Limit"
                                  value={PageData.limit}
                                  onChange={(value) =>
                                    setPageData({ ...PageData, limit: value })
                                  }
                                  cleanable={false}
                                />
                              </div>
                            </div>
                            <div className="mb-3" />
                            <div className="table-responsive">
                              {loading ? (
                                <Loader />
                              ) : (
                                <BootstrapTable
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  keyField="id"
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  // {...paginationTableProps}
                                  ref={node}
                                  selectRow={selectRow}
                                  noDataIndication={() => "No Merchants Found!"}
                                  key={`${searchKey}_${JSON.stringify(
                                    filterValue
                                  )}`}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="align-items-md-center mt-30"
                          style={{ marginTop: 30 }}
                        >
                          <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                            <Button
                              disabled={PageData.pageNo <= 1}
                              onClick={() => {
                                PageDown()
                              }}
                            >
                              Previous Page
                            </Button>
                            <div>
                              {`Page No. ${PageData.pageNo} of ${maxPage}`}
                            </div>
                            <Button
                              disabled={PageData.pageNo >= maxPage}
                              onClick={() => {
                                PageUp()
                              }}
                            >
                              Next Page
                            </Button>
                            {/* <PaginationListStandalone
                                  {...paginationProps}
                                /> */}
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                  {/* )}
                  </PaginationProvider> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceMerchants.propTypes = {
  users: PropTypes.array,
  onGetCustomers: PropTypes.func,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
  location: PropTypes.object,
}

export default EcommerceMerchants
