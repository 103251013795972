import React, { useState } from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import PropTypes from "prop-types"

 

 const LocationSearchInput = (props) =>{
    
  
  const [state, setState] =  useState()
  
  let new_location  = { address: '' }
 
  const handleChange = address => {
    setState({ address })
  }
 

 

  const handleSelect = address => {
    console.log("asdkbalsdkasd",address)
    setState({ address })
    geocodeByAddress(address)
      .then(results => {
        
        const address_components = results[0]?.address_components

          new_location = {"address":address}

        for(let i=0;i<address_components?.length;i++){

            const address_component = address_components[i]
 
            if (address_component?.types?.includes("postal_code")) new_location.postcode = address_component?.long_name 
            else if (address_component?.types?.includes("country")) new_location.country = address_component?.long_name 
            else if (address_component?.types?.includes("administrative_area_level_1")) new_location.state = address_component?.long_name 
            else if (address_component?.types?.includes("administrative_area_level_2")) new_location.district = address_component?.long_name 
            else if (address_component?.types?.includes("locality")) new_location.city = address_component?.long_name 
            else if (address_component?.types?.includes("sublocality")) {
                if ( new_location.area) new_location.area = new_location?.area + ", " + address_component?.long_name 
                else new_location.area = address_component?.long_name 
            }
        }
       return getLatLng(results[0])
    })
      .then(latLng => {

        new_location.lat = latLng["lat"]
        new_location.lng = latLng["lng"]
        
        console.log('Success', new_location)
        console.log("adsfasdfasdf", props)

        
        props.setLocation(new_location)
    
    })
      .catch(error => console.error('Error', error))
  }
 
  
    return (
      <PlacesAutocomplete
        value={ state?.address || ""}
        onChange={ handleChange}
        onSelect={ handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion,i) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <div
                   key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    )
  
}

 
LocationSearchInput.propTypes = {
    setLocation:PropTypes.func.required

}


export default LocationSearchInput


