import React from 'react'
import { Spinner } from 'reactstrap'
import "./loader.scss"

const Example = (props) => {
    return (
        <div className="d-flex justify-content-center align-items-center spinner-wrapper" >
            <Spinner color="info" style={{ width: '3rem', height: '3rem' }} />
        </div>
    )
}

export default Example