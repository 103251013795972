import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import { useRouteMatch, Link } from "react-router-dom"
import moment from "moment"
import { Badge } from "react-bootstrap"
import { Loader } from "rsuite"
import Swal from "sweetalert2"
import { post } from "helpers/api_helper"
import axios from "axios"
//GraphQL
import { ProductRequestById, BrandList, MaterialSupplierList } from "gql/query"
import { useQuery, useMutation, useApolloClient } from "@apollo/client"
import { UpdateProductRequest, Insert_Product_One, Insert_Master_Product_One, Insert_Brand_One, Insert_Supplier_One } from "gql/Mutation"
//Helpers
import isValidUrl from "helpers/url_checker"
import showAlert from "helpers/alert_helper"
//Components
import ProductImageComponent from "../../EcommerceProductDetails/ProductImageComponent"
import EditProductModal from "./EditRequestModal"

import { useSelector } from "react-redux"
const ProductOverview = (props) => {
	const { capabilities } = useSelector((state) => state.Login)
	const client = useApolloClient()
	const match = useRouteMatch()
	const [product, setProduct] = useState({})
	const [loadingBtns, setLoadingBtns] = useState({
		master: false,
		front: false,
		back: false,
		left: false,
		right: false
	})
	const [editModalOpen, setEditModalOpen] = useState(false)

	const { data: ProductDetails, loading, error, refetch } = useQuery(ProductRequestById, {
		skipCache: true,
		variables: {
			id: match?.params?.id
		}
	})
	const [UpdateProductRequestMutation] = useMutation(UpdateProductRequest)
	const [InsertProductMutation] = useMutation(Insert_Product_One)
	const [InsertMasterProductMutation] = useMutation(Insert_Master_Product_One)
	const [InsertBrandMutation] = useMutation(Insert_Brand_One)
	const [InsertSupplierMutation] = useMutation(Insert_Supplier_One)

	// const [UpdateVendorCompanyMutation] = useMutation(UpdateVendorCompany)
	const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${value.toFixed(2)}`
		} else {
			return "N/A"
		}
	}
	const getImage = (product, type) => {
		let image = "/placeholder.jpg"
		if (Object.keys(product)?.length) {
			if (typeof product.photo_urls === "object") {
				if (Array.isArray(product.photo_urls?.image)) {
					product.photo_urls.image.map(obj => {
						if (Object.keys(obj)[0] === type) {
							image = obj[type]
						}
					})
				} else if (product.photo_urls?.front) {
					image = product.photo_urls?.front
				}
			} else if (isValidUrl(product.photo_urls)) {
				image = product.photo_urls
			}
		}
		return image
	}
	const handleGetBrandID = async (name) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await client.query({
					query: BrandList,
					skipCache: true,
					variables: {
						limit: 1,
						offset: 0,
						where: { name: { _ilike: `%${name}%` } }
					}
				})
				if (Array.isArray(data?.product_brand) && data?.product_brand?.length) {
					resolve(data.product_brand[0]?.id)
				} else {
					const resp = await InsertBrandMutation({
						variables: {
							object: {
								name,
								photo_url: null
							}
						},
					})
					resolve(resp?.data?.insert_product_brand_one?.id)
				}
			} catch (err) {
				reject(err)
			}
		})
	}
	const handleCreateMasterProduct = async (brandId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const resp = await InsertMasterProductMutation({
					variables: {
						object: {
							name: product.name,
							description: product.description,
							brand_id: brandId,
							categories_id: product.category?.id,
							subcategory_id: product.subcategory?.id,
							weight: product.weight,
							weight_unit: product.weight_unit,
							collection_id: "20364fec-4a54-487d-844f-d3458fe74756",
							photo_urls: [getImage(product, "front")]
						}
					},
				})
				resolve(resp?.data?.insert_master_products_one?.id)
			} catch (err) {
				reject(err)
			}
		})
	}
	const handleGetSupplierID = async (name, contact_no, userId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await client.query({
					query: MaterialSupplierList,
					skipCache: true,
					variables: {
						limit: 1,
						offset: 0,
						where: { _and: [{ name: { _ilike: `%${name}%` } }, { created_by: { _eq: userId } }] }
					}
				})
				if (Array.isArray(data?.material_suppliers)) {
					if (data.material_suppliers?.length) {
						resolve(data.material_suppliers[0]?.id)
					} else {
						const resp = await InsertSupplierMutation({
							variables: {
								object: {
									name,
									contact_no,
									email: "",
									created_by: userId,
									updated_by: userId
								}
							},
						})
						resolve(resp?.data?.insert_material_suppliers_one?.id)
					}
				} else {
					const resp = await InsertSupplierMutation({
						variables: {
							object: {
								name,
								contact_no,
								email: "",
								created_by: userId,
								updated_by: userId
							}
						},
					})
					resolve(resp?.data?.insert_material_suppliers_one?.id)
				}
			} catch (err) {
				reject(err)
			}
		})
	}
	const handleCreateStoreProduct = async (masterProductId) => {
		return new Promise(async (resolve, reject) => {
			try {
				const supplierId = await handleGetSupplierID(product.supplier_name, product.supplier_contact, product.creator?.id)
				const resp = await InsertProductMutation({
					variables: {
						object: {
							price: product.price,
							store_id: product.store?.id,
							created_by: product.creator?.id,
							updated_by: product.creator?.id,
							master_product_id: masterProductId,
							available_quantity: product.available_quantity,
							image_url: product.photo_urls,
							weight: product.weight,
							weight_unit: product.weight_unit,
							country_origin: product.country_origin,
							out_of_stock_alert: product.out_of_stock_alert,
							material_supplier_id: supplierId
						}
					},
				})
				resolve(resp?.data?.insert_products_one?.id)
			} catch (err) {
				reject(err)
			}
		})
	}
	const handleChangeStatus = async (status) => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					if (status === "approved") {
						const brandId = await handleGetBrandID(product.brand)
 						const masterProductId = await handleCreateMasterProduct(brandId)
						await handleCreateStoreProduct(masterProductId)
					}
					await UpdateProductRequestMutation({
						variables: {
							id: product.id,
							object: {
								status
							}
						},
					})
					Swal.fire("Request status changed successfully", "", "success")
					refetch()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	const handleUpdateProductImage = async (e, name) => {
		try {
			setLoadingBtns({ ...loadingBtns, [name]: true })
			let formData = new FormData()
			formData.append("uploads", e.target.files[0])
			const resp = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`, formData)
			if (resp.data?.success) {
				if (Array.isArray(resp.data?.data?.uploaded_files)) {
					const link = resp.data.data.uploaded_files[0].uploaded_file_url
					let photoObj = {}
					if (typeof product.photo_urls === "object") {
						if (Array.isArray(product.photo_urls?.image)) {
							photoObj["image"] = []
							product.photo_urls.image.map(obj => {
								if (Object.keys(obj)[0] === name) {
									photoObj["image"].push({ [name]: link })
								} else {
									photoObj["image"].push({ [Object.keys(obj)[0]]: obj[Object.keys(obj)[0]] })
								}
							})
						} else {
							photoObj = {
								image: [{ [name]: link }]
							}
						}
					} else {
						photoObj = {
							image: [{ [name]: link }]
						}
					}
					await UpdateProductRequestMutation({
						variables: {
							id: product.id,
							object: {
								photo_urls: photoObj
							}
						},
					})
					refetch()
				} else {
					showAlert({ message: "Unable to upload image!", type: "error" })
				}
			} else {
				showAlert({ message: "Unable to upload image!", type: "error" })
			}
		} catch (err) {
			console.log(err)
			showAlert({ message: "Unable to upload image!", type: "error" })
		} finally {
			setLoadingBtns({ ...loadingBtns, [name]: false })
		}
	}
	const getStatusColor = () => {
		if (product?.status === "approved") {
			return "success"
		} else if (product?.status === "pending") {
			return "warning"
		} else {
			return "danger"
		}
	}
	useEffect(() => {
		if (Array.isArray(ProductDetails?.product_requests)) {
			setProduct(ProductDetails.product_requests[0])
		}
	}, [ProductDetails])
	return (
		<Container>
			<Row>
				<Col sm="4">
					<Row>
						<ProductImageComponent
							src={getImage(product, "front")}
							title="Front Image"
							layout="half"
							onImageSelect={(e) => handleUpdateProductImage(e, "front")}
							loading={loadingBtns.front}
							name="front"
							isEdit={capabilities.includes("edit")}
						/>
						<ProductImageComponent
							src={getImage(product, "back")}
							title="Back Image"
							layout="half"
							onImageSelect={(e) => handleUpdateProductImage(e, "back")}
							loading={loadingBtns.back}
							name="back"
							isEdit={capabilities.includes("edit")}
						/>
						<ProductImageComponent
							src={getImage(product, "left")}
							title="Left Image"
							layout="half"
							onImageSelect={(e) => handleUpdateProductImage(e, "left")}
							loading={loadingBtns.left}
							name="left"
							isEdit={capabilities.includes("edit")}
						/>
						<ProductImageComponent
							src={getImage(product, "right")}
							title="Right Image"
							layout="half"
							onImageSelect={(e) => handleUpdateProductImage(e, "right")}
							loading={loadingBtns.right}
							name="right"
							isEdit={capabilities.includes("edit")}
						/>
					</Row>
				</Col>
				<Col sm="8" className="pl-3">
					<div>
						<h3>{product?.name}
							<>
								<Badge bg={getStatusColor()} style={{ textTransform: "capitalize", marginLeft: 10 }}>
									{product?.status}
								</Badge>
							</>
						</h3>
					</div>
					<hr />
					<Row>
						<div className="stats-wrapper">
							<b>Shop Type</b>
							<p>{product?.store?.store_type || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Store Name</b>
							<Link to={`/ecommerce-shops/details/${product?.store?.id}`}>
								<p>{product?.store?.name || "N/A"}</p>
							</Link>
						</div>
						<div className="stats-wrapper">
							<b>Merchant ID</b>
							<Link to={`/ecommerce-merchants/details/${product?.creator?.id}`}>
								<p>{product?.creator?.vendor_company?.merchant_id || "N/A"}</p>
							</Link>
						</div>
						<div className="stats-wrapper">
							<b>Stock</b>
							<p>{product?.available_quantity}</p>
						</div>
						<div className="stats-wrapper">
							<b>Out of Stock Alert</b>
							<p>{product?.out_of_stock_alert}</p>
						</div>
						<div className="stats-wrapper">
							<b>Price</b>
							<p>{formatPrice(product?.price)}</p>
						</div>
						<div className="stats-wrapper">
							<b>Weight</b>
							<p>{product?.weight} {product?.weight_unit}</p>
						</div>
						<div className="stats-wrapper">
							<b>Brand</b>
							<p>{product?.brand || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Category</b>
							<p>{product?.category?.name || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Sub-Category</b>
							<p>{product?.subcategory?.name || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Supplier Name</b>
							<p>{product?.supplier_name || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Supplier Contact</b>
							<p>{product?.supplier_contact || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Country of Origin</b>
							<p>{product?.country_origin}</p>
						</div>
						<div className="stats-wrapper">
							<b>GST Bracket</b>
							<p>{product?.tax?.name || "N/A"}</p>
						</div>
					</Row>
				</Col>
			</Row>
			{capabilities.includes("edit") && (
				<>
					{product.status !== "approved" && (
						<Row>
							<Col xl="12" className="d-flex justify-content-end">
								<Button
									type="button"
									color={"info"}
									className="btn-lg  mb-2 me-2"
									onClick={() => setEditModalOpen(true)}
								>
									Edit
								</Button>
								<Button
									type="button"
									color={"primary"}
									className="btn-lg  mb-2 me-2"
									onClick={() => handleChangeStatus("approved")}
								>
									Approve
								</Button>
								{product.status === "pending" && (
									<Button
										type="button"
										color={"danger"}
										className="btn-lg  mb-2 me-2"
										onClick={() => handleChangeStatus("rejected")}
									>
										Reject
									</Button>
								)}
							</Col>
						</Row>
					)}
				</>
			)}
			<EditProductModal
				open={editModalOpen}
				close={() => setEditModalOpen(false)}
				product={product}
				refetch={refetch}
				approveBtn={false}
			/>
		</Container>
	)
}
ProductOverview.propTypes = {
	setStores: PropTypes.func
}
ProductOverview.displayName = 'ProductOverview'
export default ProductOverview