import React, { useEffect, useState } from "react"
import { CheckPicker, Modal, TagInput, Toggle } from "rsuite"
import { withRouter } from "react-router-dom"
import {
  Insert_Create_Vehicle_Rental,
  Insert_Rental_AddOns,
  Insert_Vehicle_Brand_One,
  Insert_vehicle_master_data_One,
  Update_Rental_AddOns,
  Update_Vehicle_Brand_One,
  Update_vehicle_Rental,
  Update_vehicle_master_data_One,
} from "gql/Mutation"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import { SelectPicker } from "rsuite"
import FileUploader from "components/Common/FileUploader"
import axios from "axios"
import PropTypes from "prop-types"
import Swal from "sweetalert2"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  RentalAddonsQueryList,
  VehicleMasterDataEnumQuery,
  VehicleMasterDataQuery,
  VehicleRentalsAddonsEnumData,
  VehicleRentalsEnumData,
  VehicleRentalsQuery,
} from "gql/query"
import { createHeaders } from "./columns"
import { onChangeHandlerFunction } from "./Vehicle-retnals-addons-functions"
import { BuildFields } from "../Common/BuildFields"
import { Action } from "../Common/Action"


const VehicleRentalsCreate = (props) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [CreateVehicleMasterDataMutation] = useMutation(
    Insert_Rental_AddOns
  )
  const [UpdateVehicleMasterDataMutation] = useMutation(Update_Rental_AddOns)
  const [queryCondition, setQueryCondition] = useState(null)
  const [masterData, setMasterData] = useState({})
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  })

  const [getVehicleMasterDataList, { loading, data, refetch, error }] =
    useLazyQuery(RentalAddonsQueryList, {
      skipCache: false,
      variables: {
        limit: 1,
        where: { id: { _eq: props?.masterData?.id } },
      },
    })

  const [
    getVehicleMasterDataEnumList,
    {
      loading: EnumLoading,
      data: EnumData,
      refetch: enumRefetch,
      error: Enumerror,
    },
  ] = useLazyQuery(VehicleRentalsAddonsEnumData, {
    skipCache: false,
  })


  console.log("Asdflasndlasnd")
  useEffect(() => {
    if (!queryCondition) setQueryCondition(props?.masterData?.id)
    else getVehicleMasterDataList()
    getVehicleMasterDataEnumList()
  }, [props?.masterData?.id, queryCondition])

  //If rentals data exists, merge values
  useEffect(() => {
    //Add vlaues to the all columns
    if (data?.rental_addons?.length > 0) {
      let rentals_data = data?.rental_addons?.[0]

      Object.keys(createHeaders)?.map((key, i) => {
        let obj = createHeaders?.[key]

        //If it is an array of columns
        if (
          key &&
          rentals_data?.[key] &&
          Object.keys(rentals_data?.[key])?.length > 1 &&
          Array.isArray(createHeaders?.[key])
        ) {
          createHeaders[key]?.forEach((e, index) => {
            createHeaders[key][index] = {
              ...e,
              ...{ value: rentals_data[key][e.name] },
            }
          })
        }

        //If it is an object of column and data is not an array
        else if (
          !Array.isArray(rentals_data?.[key]) &&
          !Array.isArray(createHeaders?.[key])
        ) {
          if (obj?.type == "typehead") {
            let field = createHeaders?.[key]?.access
            createHeaders[key] = {
              ...createHeaders?.[key],
              label: rentals_data?.[field]?.[createHeaders[key]?.acessLabel],
              value: rentals_data?.[field]?.[createHeaders[key]?.acessValue],
            }
          } else {
            createHeaders[key] = {
              ...createHeaders?.[key],
              value: rentals_data?.[key],
            }
          }
        }
        //If it an object of column and existing data is not array
        else if (
          Array.isArray(rentals_data?.[key]) &&
          !Array.isArray(createHeaders?.[key])
        ) {
    console.log("chalndlandslkas",key, createHeaders)

          createHeaders[key] = {
            ...createHeaders?.[key],
            value: rentals_data?.[key],
          }
        } else if (
          !Array.isArray(rentals_data?.[key]) &&
          Array.isArray(createHeaders?.[key])
        ) {
 
          createHeaders[key] = {
            ...createHeaders?.[key],
            value: rentals_data?.[key],
          }
        } else {
          createHeaders[key] = {
            ...createHeaders?.[key],
            value: rentals_data?.[key],
          }
        }
      })
    }

    setMasterData({ ...createHeaders })
  }, [data?.rental_addons?.length])
  
  //Remove an image
  const removeImage = (e) => {
    const index = e?.target?.dataset?.index
    const parent = e?.target?.dataset?.parent
    let createHeaders = {}
    createHeaders = { ...masterData }

    if (Array.isArray(createHeaders?.[parent]?.value)) {
      const newArray = [...createHeaders[parent]?.value]
      newArray.splice(index, 1)
      createHeaders[parent].value = newArray
      setMasterData(createHeaders)
    }
  }

 
  console.log("mastadssdDatasd",masterData)

  //Submit or update the master data
  const handleRentalAddOns = async (event) => {
    event.preventDefault()
    let newObj = {}
 
    Object.keys(masterData)?.map((e, i) => {
      masterData?.[e]?.length > 0
        ? [...masterData[e]] ?.filter((ee) => ee) ?.map((ei, ii) => { newObj[e] = { ...newObj?.[e], [ei?.name]: ei?.value } })
        : (newObj[e] = masterData?.[e]?.value)
    })
 

    setBtnLoading(true)




    const Result  = await Action({entity:"Rental Addons",
                                  id : props?.masterData?.id , 
                                  updateQuery:UpdateVehicleMasterDataMutation,
                                  createQuery : CreateVehicleMasterDataMutation,
                                  object:newObj,
                                  submit:props?.onSubmit,
                                  close:props?.close()
                              })

                              setBtnLoading(false)
    console.log("Result is", Result)



 
  }

  console.log("Materdta",masterData)
 
 

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      style={{ width: "100vw", margin: "0 auto" }}
    >
      <Modal.Header>
        <Modal.Title className="mb-2">
          {props.brand?.id ? "UPDATE RENTAL ADD ONS" : "CREATE RENTAL ADD ONS "}
        </Modal.Title>
      </Modal.Header>
      <nav>
        <form onSubmit={handleRentalAddOns}>
        <div className="form-group" onChange={(e) => onChangeHandlerFunction({e,masterData,setMasterData })}>
            <Row>
              {Object.keys(masterData)?.map((e, i) => {
                return !Array.isArray(masterData?.[e])
                  ?    BuildFields({naming:e, field:masterData?.[e], ind:null,EnumData,masterData, setMasterData,onChangeHandler:onChangeHandlerFunction})

                  :  [...masterData[e]] ?.filter((ee) => ee) ?.map((ei, i) => BuildFields({naming:e, field:ei, ind:i,EnumData,masterData,setMasterData,onChangeHandler:onChangeHandlerFunction}))
              })}
            </Row>
          </div>
          <button type="submit">submit</button>
        </form>
      </nav>
    </Modal>
  )
}

VehicleRentalsCreate.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  brand: PropTypes.any,
  onSubmit: PropTypes.func,
  masterData: PropTypes.any,
}
export default withRouter(VehicleRentalsCreate)
