import React from "react"
import { withRouter } from "react-router-dom"
import EcommerceProductsTable from "../../EcommerceProducts/EcommerceProductsTable"
const NonMRPRequestsTable = props => {
    return (
        <EcommerceProductsTable
            queryCondition={{non_mrp: { _eq: true }}}
            activeFilters={["product_brand", "category", "subcategory", "store", "store_type", "created_at"]}
            filterQueryConditions={null}
        />
    )
}
NonMRPRequestsTable.propTypes = {}
export default withRouter(NonMRPRequestsTable)
