import React from "react"
import PropTypes from 'prop-types'
import { Loader } from "rsuite"

const ProductImageComponent = (props) => {
	return (
		<div className={props.layout === "full" ? "product_image_container" : "product_image_container_half mt-2"}>
			<img src={props.src} className={`product_image ${props.layout === "full" ? "" : "sm"}`} />
			<div className={`product_image_overlay ${props.layout === "full" ? "" : "sm"}`}>
				{props.loading ? "Uploading Image" : props.title}
				<br />
				{
					props.loading ? (
						<label><Loader /></label>
					) : (
						<>
							{props.isEdit && (
								<>
									<label htmlFor={`upload-photo-${props.name}`}>Update</label>
									<input
										type="file"
										name={`photo-${props.name}`}
										id={`upload-photo-${props.name}`}
										onChange={props.onImageSelect}
									/>
								</>
							)}
						</>
					)}

			</div>
		</div>
	)
}
ProductImageComponent.propTypes = {
	title: PropTypes.string,
	src: PropTypes.string,
	layout: PropTypes.string,
	onImageSelect: PropTypes.func,
	loading: PropTypes.bool,
	name: PropTypes.string,
	isEdit: PropTypes.bool
}
export default ProductImageComponent