import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import moment from "moment"
import { useRouteMatch } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { CheckPicker } from 'rsuite'
import Loader from "../../../components/Loader/Loader"
import { Dropdown } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'
//Helpers
import isValidUrl from "../../../helpers/url_checker"
//GraphQL
import { MerchantProductList } from "gql/query"
import { useQuery } from "@apollo/client"

const RelatedProducts = (props) => {
	const FormatData = (data) => {
		const products = data.products.map(elem => {
			let image = "/placeholder.jpg"
			if (elem.non_mrp) {
				if (typeof elem.image_url === "object") {
					if(Array.isArray(elem.image_url?.image)){
						elem.image_url.image.map(obj => {
							if(Object.keys(obj)[0] === "back"){
								image = obj.back
							}
						})
					}else if (elem.image_url?.front) {
						image = elem.image_url?.front
					}
				} else if (isValidUrl(elem.image_url)) {
					image = elem.image_url
				}
			} else if (elem.master_product?.photo_urls?.length > 0) {
				image = elem.master_product.photo_urls[0]
			}
			return {
				image,
				sku: elem.sku || "N/A",
				name: elem?.master_product?.name,
				stock: elem.available_quantity,
				price: elem.price,
				variation: "",
				created_at: elem.created_at,
				weight: elem.weight,
				weight_unit: elem.weight_unit,
				store_name: elem?.store?.name,
				store_id: elem?.store?.id,
				brand_id: elem?.master_product?.product_brand?.id,
				brand_name: elem?.master_product?.product_brand?.name || "N/A",
				category_id: elem?.master_product?.category?.id,
				category_name: elem?.master_product?.category?.name || "N/A",
				subcategory_id: elem?.master_product?.subcategory?.id,
				subcategory_name: elem?.master_product?.subcategory?.name || "N/A",
			}
		})
		return products
	}
	const productsColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Store",
			dataField: "store_name",
			sort: true,
		},
		{
			dataField: "image",
			text: "Image",
			sort: false,
			formatter: (cellContent, row) => <img src={row.image} style={{ height: 50 }} />,
		},
		{
			dataField: "sku",
			text: "SKU",
			sort: true,
		},
		{
			dataField: "name",
			text: "Product Name",
			sort: true,
		},
		{
			text: "Stock",
			dataField: "stock",
			sort: true,
		},
		{
			dataField: "price",
			text: "Price",
			sort: true,
			formatter: (cellContent, row) => `₹${row.price}`,
		},
		{
			text: "Weight",
			dataField: "weight",
			sort: true,
			formatter: (cellContent, row) => `${row.weight} ${row.weight_unit}`,
		},
		{
			text: "Variation",
			dataField: "variation",
			sort: true,
		},
		{
			dataField: "brand_name",
			text: "Brand",
			sort: true,
		},
		{
			dataField: "category_name",
			text: "Category",
			sort: true,
		},
		{
			dataField: "subcategory_name",
			text: "Sub-Category",
			sort: true,
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
		{
			dataField: "view",
			isDummyField: true,
			text: "Action",
			sort: false,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
			  <Dropdown>
				<Dropdown.Toggle variant="transparent" id="dropdown-basic">
				  <GoKebabVertical />
				</Dropdown.Toggle>
				<Dropdown.Menu>
				  <Dropdown.Item onClick={() => {}}>View Details</Dropdown.Item>
				</Dropdown.Menu>
			  </Dropdown>
			),
		  },
	]
	const match = useRouteMatch()
	const defaultSorted = []
	const [products, setProducts] = useState([])
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({ created_by: { _eq: match?.params?.id } })
	const [storesData, setStoresData] = useState([])
	const [selectedStores, setSelectedStores] = useState([])
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}

	const { data, loading, error } = useQuery(MerchantProductList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const count = data?.products_aggregate?.aggregate?.count
	const maxPage = Math.ceil(count / PageData.limit)
	useEffect(() => {
		if (data) {
			setProducts(FormatData(data))
		}
	}, [data])
	useEffect(()=>{
		let storesTemp = []
		props.stores?.map((store)=>{
			storesTemp.push({
				label: store.name,
				value: store.id
			})
		})
		setStoresData(storesTemp)
	}, [props.stores])
	useEffect(()=>{
		if(selectedStores.length){
			setQueryCondition({
				...queryCondition,
				store_id: { _in: selectedStores }
			})
		}else{
			setQueryCondition({ created_by: { _eq: match?.params?.id } })
		}
	}, [selectedStores])
	useEffect(()=>{
		if(props.selectedStores?.stores?.length > 0 && props.selectedStores?.target==="products"){
			setSelectedStores(props.selectedStores.stores)
		}
	}, [props.selectedStores])
	return (
		<div className="p-3">
			<Row className="mb-5">
				<Row className="mb-2">
					<Col sm="8">
						<h4>No. of Products: {count}</h4>
					</Col>
					<Col sm="4">
						<div className="text-sm-end d-flex justify-content-end">
							{/* <CheckPicker 
								data={storesData} 
								placeholder="Select Store" 
								style={{ width: 224 }} 
								value={selectedStores}
								onChange={setSelectedStores}
								searchable={false}
							/> */}
						</div>
					</Col>
				</Row>
				<hr />
				<ToolkitProvider
					keyField="id"
					data={products || []}
					columns={productsColumns}
					bootstrap4
				// search
				>
					{toolkitProps => (
						<>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										{loading ? <Loader /> :
											<BootstrapTable
												responsive
												bordered={false}
												striped={false}
												defaultSorted={defaultSorted}
												classes={"table align-middle table-nowrap"}
												keyField="id"
												{...toolkitProps.baseProps}
												onTableChange={() => { }}
												noDataIndication={() => "No Products Found!"}
											// {...paginationTableProps}
											/>
										}
									</div>
								</Col>
							</Row>
							<Row className="align-items-md-center mt-40">
								<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
									<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
										Previous Page
									</Button>
									<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
									</div>
									<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
										Next Page
									</Button>
								</Col>
							</Row>
						</>
					)}
				</ToolkitProvider>
			</Row>
		</div>
	)
}
RelatedProducts.propTypes = {
	stores: PropTypes.array,
	selectedStores: PropTypes.any
}
RelatedProducts.displayName = 'RelatedProducts'
export default RelatedProducts