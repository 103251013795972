import React from "react"
import { Dropdown } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { GoKebabVertical } from 'react-icons/go'

export const FormatData = (data) => {
	const payouts = data.payouts?.map(elem => {
		return {
			id: elem.id,
			transfer_id: elem.transfer_id,
			total_amount: elem.total_amount,
			total_orders: elem.orders_aggregate?.aggregate?.count || 0,
			total_customer_payable: elem.orders_aggregate?.aggregate?.sum?.total_amount || 0,
			total_service_fees: elem.orders_aggregate?.aggregate?.sum?.delivery_charges || 0,
			total_adjustments: elem.orders_aggregate?.aggregate?.sum?.cancelled_amount || 0,
			orders_aggregate: elem.orders_aggregate,
			commissions_aggregate: elem.commissions_aggregate,
			status: elem.status,
			reference_id: elem?.reference_id || "N/A",
			start_date: elem.start_date,
			end_date: elem.end_date,
			remarks: elem.remarks,
			merchant_id: elem.merchant_id,
			store_name: elem.store?.name,
			store_id: elem.store?.id,
			created_at: elem.created_at
		}
	})
	return payouts
}
const formatHighlighter = (text, searchKey, filterValue) => {
	let searchWords = [searchKey]
	return <Highlighter
		highlightClassName="YourHighlightClass"
		searchWords={searchWords}
		autoEscape={true}
		textToHighlight={String(text)}
		highlightStyle={{ background: "#ff0" }}
	/>
}
export const EcommercePayoutsColumns = (searchKey, filterValue, handleOpen) => [
	{
		text: "ID",
		dataField: "id",
		sort: false,
		hidden: true,
		// eslint-disable-next-line react/display-name
	},
	{
		text: "Transfer ID",
		dataField: "transfer_id",
		sort: true,
		formatter: (cellContent, row) => <a href onClick={()=>handleOpen(row)} style={{color: "#1675e0"}}>{formatHighlighter(cellContent, searchKey, filterValue)}</a>,
	},
	{
		text: "Store",
		dataField: "store_name",
		sort: true,
		formatter: (cellContent, row) => <Link to={`/ecommerce-shops/details/${row?.store_id}`}>{cellContent}</Link>,
	},
	{
		text: "Total Amount",
		dataField: "total_amount",
		sort: true,
		formatter: (cellContent, row) => `₹${cellContent}`,
	},
	{
		text: "Total Orders",
		dataField: "total_orders",
		sort: true
	},
	{
		text: "Status",
		dataField: "status",
		sort: false,
		formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue),
	},
	{
		text: "Reference ID",
		dataField: "reference_id",
		sort: true,
		formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue),
	},
	{
		text: "Start Date",
		dataField: "start_date",
		sort: true,
		formatter: (cellContent, row) => moment(cellContent).format("DD/MM/YYYY"),
	},
	{
		text: "End Date",
		dataField: "end_date",
		sort: true,
		formatter: (cellContent, row) => moment(cellContent).format("DD/MM/YYYY"),
	},
	{
		text: "Created At",
		dataField: "created_at",
		sort: false,
		formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
	}
]
export const BuildProductSearchQuery = (value, queryCondition) => {
	if (value.length >= 3) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		conditionTemp = {
			...conditionTemp,
			_or: [
				{ transfer_id: { _ilike: `%${value}%` } },
				{ status: { _ilike: `%${value}%` } },
				{ reference_id: { _ilike: `%${value}%` } },
				{ store: {name: { _ilike: `%${value}%` }}}
			]
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
export const BuildPayoutFilterQuery = (filterValue, queryCondition) => {
	if (Object.keys(filterValue).length) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		if (filterValue?.hasOwnProperty("store")) {
			conditionTemp = {
				...conditionTemp,
				orders: {store_id: {_eq: filterValue.store}}
			}
		}
		if (filterValue?.hasOwnProperty("created_at")) {
			if (Array.isArray(filterValue.created_at)) {
				conditionTemp = {
					...conditionTemp,
					_and: [{ created_at: { _gte: filterValue.created_at[0] } }, { created_at: { _lte: filterValue.created_at[1] } }]
				}
			}
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}