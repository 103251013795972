const LinksArr = [
  {
    id: 0,
    name: "Home",
    slug: "",
    child: [
      {
        id: 0,
        name: "Groceries",
        slug: "groceries",
        child: [
          {
            id: 0,
            name: "Dashboard",
            slug: "dashboard",
          },
          {
            id: 1,
            name: "Orders",
            slug: "ecommerce-orders",
          },
          {
            id: 2,
            name: "Products in Shop",
            slug: "ecommerce-products",
          },
          {
            id: 3,
            name: "Customers",
            slug: "ecommerce-customers",
          },
          {
            id: 4,
            name: "Merchants",
            slug: "ecommerce-merchants",
          },
          {
            id: 5,
            name: "Shops",
            slug: "ecommerce-shops",
          },
          {
            id: 6,
            name: "Requests",
            slug: "ecommerce-requests",
          },
          {
            id: 7,
            name: "Master Catalogue",
            slug: "ecommerce-master-catalogue",
          },
          {
            id: 8,
            name: "Brands",
            slug: "ecommerce-brands",
          },
          {
            id: 8,
            name: "Categories",
            slug: "ecommerce-categories",
          },
          {
            id: 9,
            name: "Sub Categories",
            slug: "ecommerce-sub-categories",
          },
          {
            id: 9,
            name: "Promotions",
            slug: "ecommerce-promotions",
          },
        ],
      },
      {
        id: 1,
        name: "Home Services",
        slug: "services",
        child: [
          {
            id: 0,
            name: "Dashboard",
            slug: "dashboard",
          },
          {
            id: 1,
            name: "Bookings",
            slug: "service-bookings",
          },
          {
            id: 2,
            name: "Services",
            slug: "service-list",
          },
          {
            id: 3,
            name: "Customers",
            slug: "service-customers",
          },
          {
            id: 4,
            name: "Merchants",
            slug: "service-merchants",
          },
          {
            id: 5,
            name: "Shops",
            slug: "service-shops",
          },
          {
            id: 6,
            name: "Categories",
            slug: "service-categories",
          },
          {
            id: 7,
            name: "Sub Categories",
            slug: "service-sub-categories",
          },
          {
            id: 8,
            name: "Commissions",
            slug: "service-commission",
          },
          {
            id: 9,
            name: "Promotions",
            slug: "service-promotions",
          },
        ],
      },
      {
        id: 2,
        name: "Rental Vehicles",
        slug: "vehicles",
        child: [
          {
            id: 0,
            name: "Dashboard",
            slug: "dashboard",
          },
          {
            id: 1,
            name: "Rentals",
            slug: "vehicles-rentals",
          },
          {
            id: 2,
            name: "Brand",
            slug: "vehicles-brands",
          },
          {
            id: 3,
            name: "Orders",
            slug: "vehicles-orders",
          },
          {
            id: 4,
            name: "Airport",
            slug: "airport-rentals",
          },
          {
            id: 5,
            name: "Rental Filters",
            slug: "rental-filters",
          },
          {
            id: 6,
            name: "Vehicle Types",
            slug: "rental-vehicle_types",
          },
          {
            id: 7,
            name: "Rental Kyc",
            slug: "rental-kyc",
          },
          {
            id: 8,
            name: "Rental Drivers",
            slug: "rental-drivers",
          },
          {
            id: 9,
            name: "Rental Addons",
            slug: "rental-addons",
          },
          {
            id: 10,
            name: "Rental Promotions",
            slug: "rental-promotions",
          },
          {
            id: 11,
            name: "Vehicle Master Data",
            slug: "vehicles-master-data",
          },
          {
            id: 12,
            name: "Merchants",
            slug: "vehicles-merchants",
          },
          {
            id: 13,
            name: "Shops",
            slug: "rental-shops",
          },
        ],
      },
      {
        id: 1,
        name: "Property",
        slug: "property",
        child: [
          {
            id: 0,
            name: "Dashboard",
            slug: "dashboard",
          },
          {
            id: 1,
            name: "Bookings",
            slug: "property-bookings",
          },
          {
            id: 2,
            name: "Property Type",
            slug: "property-type",
          },
          {
            id: 3,
            name: "Property Rules",
            slug: "property-rules",
          },
          {
            id: 4,
            name: "Property Amenities",
            slug: "property-amenities",
          },
          {
            id: 5,
            name: "Customers",
            slug: "property-customers",
          },
          {
            id: 6,
            name: "Merchants",
            slug: "property-merchants",
          },
          {
            id: 7,
            name: "Shops",
            slug: "property-shops",
          },
          {
            id: 8,
            name: "Property List",
            slug: "property-list",
          },
          // {
          //   id: 7,
          //   name: "Sub Categories",
          //   slug: "service-sub-categories",
          // },
          // {
          //   id: 8,
          //   name: "Commissions",
          //   slug: "service-commission",
          // },
          // {
          //   id: 9,
          //   name: "Promotions",
          //   slug: "service-promotions",
          // },
        ],
      },
      {
        id: 1,
        name: "Experience",
        slug: "experience",
        child: [
          {
            id: 0,
            name: "Dashboard",
            slug: "dashboard",
          },
          {
            id: 1,
            name: "Bookings",
            slug: "experience-bookings",
          },
          // {
          //   id: 2,
          //   name: "Services",
          //   slug: "service-list",
          // },
          {
            id: 3,
            name: "Customers",
            slug: "experience-customers",
          },
          {
            id: 4,
            name: "Merchants",
            slug: "experience-merchants",
          },
          {
            id: 5,
            name: "Shops",
            slug: "experience-shops",
          },
          {
            id: 6,
            name: "Experience Type",
            slug: "experience-type",
          },
          {
            id: 6,
            name: "Experience List",
            slug: "experience-list",
          },
          // {
          //   id: 7,
          //   name: "Sub Categories",
          //   slug: "service-sub-categories",
          // },
          // {
          //   id: 8,
          //   name: "Commissions",
          //   slug: "service-commission",
          // },
          // {
          //   id: 9,
          //   name: "Promotions",
          //   slug: "service-promotions",
          // },
        ],
      },
    ],
  },
  // {
  //   id: 1,
  //   name: "System Users",
  //   slug: "system-users",
  // },
]

export default LinksArr
