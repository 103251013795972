import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import moment from "moment"
import { useRouteMatch, useHistory,Link } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { CheckPicker } from 'rsuite'
import Loader from "../../../components/Loader/Loader"
import { Dropdown } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'
//Helpers
import isValidUrl from "../../../helpers/url_checker"
//GraphQL
import { MerchantProductList } from "gql/query"
import { useQuery } from "@apollo/client"

const ServiceItems = (props) => {
	const history = useHistory()
	const [services, setServices] = useState([])
	const FormatData = (data) => {
		const productsTemp = data.map(elem => {
			return {
				item_name: elem?.service_name,
				item_price: elem.service_price,
				count: elem.count,
				total_price_amount: elem.total_price_amount,
				vendor_discounted_amount: elem.vendor_discounted_amount,
				tax_amount: elem.tax_amount,
				price_includes_tax: elem.price_includes_tax,
				eefind_discounted_amount: elem.eefind_discounted_amount,
			}
		})
		return productsTemp
	}
	const productsColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Service Name",
			dataField: "item_name",
			sort: true,
		},
		{
			dataField: "item_price",
			text: "Service Price",
			sort: true,
			formatter: (cellContent, row) => `₹${row.item_price}`,
		},
		{
			dataField: "count",
			text: "Quantity",
			sort: true,
		},
		{
			dataField: "total_price_amount",
			text: "Total Gross Price",
			sort: true,
			formatter: (cellContent, row) => `₹${row.total_price_amount}`,
		},
		{
			dataField: "view",
			isDummyField: true,
			text: "Action",
			sort: false,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
			  <Dropdown>
				<Dropdown.Toggle variant="transparent" id="dropdown-basic">
				  <GoKebabVertical />
				</Dropdown.Toggle>
				<Dropdown.Menu>
				  <Dropdown.Item>View Details</Dropdown.Item>
				</Dropdown.Menu>
			  </Dropdown>
			),
		  },
	]
	const defaultSorted = []
	useEffect(() => {
		if (props.order?.service_items?.length) {
			setServices(FormatData(props.order.service_items))
		}
	}, [props.order])
	
	return (
    <div className="p-3">
      <Row className="mb-5">
        <Row className="mb-2">
          <Col sm="8">{props.showLabel && <h4>{props.label}</h4>}</Col>
          <Col sm="4">
            <div className="text-sm-end d-flex justify-content-end"></div>
          </Col>
        </Row>
        <hr />
        <ToolkitProvider
          keyField="id"
          data={services || []}
          columns={productsColumns}
          bootstrap4
          // search
        >
          {(toolkitProps) => (
            <>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    {false ? (
                      <Loader />
                    ) : (
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        keyField="id"
                        {...toolkitProps.baseProps}
                        onTableChange={() => {}}
                        noDataIndication={() => "No Products Found!"}
                        // {...paginationTableProps}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ToolkitProvider>
      </Row>
    </div>
  )
}
ServiceItems.propTypes = {
	order: PropTypes.any,
	showLabel: PropTypes.bool,
	label: PropTypes.string
}
ServiceItems.displayName = 'ServiceItems'
export default ServiceItems