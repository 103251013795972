import React from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useParams } from "react-router-dom"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import "../../../assets/scss/custom.scss"
const Rental_orders = (props) => {
    const pathName = props.location.pathname

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Bookings | EEFind </title>
                </MetaTags>
                <Container fluid>
                <Breadcrumbs title="Rentals" breadcrumbItem="Rental Drivers" />
                <div>
                    <p>jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg

                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    jdsfhakjsdfhakldjshlakdjshlkdjshflkdjsahf lsdjhf lsdkjfh aldjshf ladjsfglsdkjfh alkdjhf lsdkjfh sdkjlfgasdkjflg
                    </p>
                </div>
                    
                </Container>
            </div>
        </React.Fragment>
    )
}
Rental_orders.propTypes = {
    location: PropTypes.object,
}
export default withRouter(Rental_orders)
