import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import { useRouteMatch, Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "react-bootstrap"
import Swal from "sweetalert2"
//GraphQL
import { useMutation } from "@apollo/client"
import { UpdatePromotion } from "gql/Mutation"

import { useSelector } from "react-redux"

const PromotionOverview = (props) => {
	const { capabilities } = useSelector((state) => state.Login)
	const match = useRouteMatch()
	const [updatePromotionMutation] = useMutation(UpdatePromotion)
	const [queryConditionStats, setQueryConditionStats] = useState({})

	// const [UpdateVendorCompanyMutation] = useMutation(UpdateVendorCompany)
	const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${value.toFixed(2)}`
		} else {
			return "N/A"
		}
	}
	const handleChangeStatus = async () => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					updatePromotionMutation({
						variables: {
							id: props.promotion.id,
							object: {
								active: !props.promotion.active
							}
						},
					}).then(() => {
						refetch()
					}).catch(() => {
						Swal.fire("Something went wrong!", "", "error")
					})
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	return (
		<Container>
			<Row>
				<Col sm="8" className="pl-3">
					<div>
						<h3>{props.promotion.code}
							<Badge bg={props.promotion.active ? "success" : "danger"} style={{ textTransform: "capitalize", marginLeft: 10 }}>{props.promotion.active ? "Active" : "Deactive"}</Badge>
						</h3>
						<h6>
							Store:  <Link to={`/ecommerce-shops/details/${props.promotion.store?.id}`}> {props.promotion.store?.name}</Link>
						</h6>
						<h6>Promoted by: {props.promotion.promoted_by}</h6>
					</div>
					<hr />
					<Row>
						<div className="stats-wrapper">
							<b>Promotion ID</b>
							<p>{props.promotion.id}</p>
						</div>
						<div className="stats-wrapper">
							<b>Description</b>
							<p>{props.promotion.description || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Discount Type</b>
							<p>{props.promotion.value_type}</p>
						</div>
						<div className="stats-wrapper">
							<b>Discount Value</b>
							<p>{props.promotion.value_type === "flat" ? `${formatPrice(props.promotion.value)}` : `${props.promotion.value}%`}</p>
						</div>
						<div className="stats-wrapper">
							<b>Min Unit Count</b>
							<p>{props.promotion.min_item_count}</p>
						</div>
						<div className="stats-wrapper">
							<b>Min Order Amount</b>
							<p>{props.promotion.min_order_amount}</p>
						</div>
						<div className="stats-wrapper">
							<b>Valid From</b>
							<p>{moment(props.promotion.valid_from).format("DD/MM/YYYY hh:mm A")}</p>
						</div>
						<div className="stats-wrapper">
							<b>Valid Till</b>
							<p>{moment(props.promotion.valid_till).format("DD/MM/YYYY hh:mm A")}</p>
						</div>
						<div className="stats-wrapper">
							<b>Maximum Usage</b>
							<p>{props.promotion.usable_count}</p>
						</div>
						<div className="stats-wrapper">
							<b>No. of Time Used</b>
							<p>{props.promotion.used_count}</p>
						</div>
						<div className="stats-wrapper">
							<b>Applicable on Delivery Charge</b>
							<p>{props.promotion.apply_on_delivery_charge ? "Yes" : "No"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Created at</b>
							<p>{moment(props.promotion.created_at).format("DD/MM/YYYY hh:mm A")}</p>
						</div>
					</Row>
				</Col>
			</Row>
			{capabilities.includes("edit") && (
			<Row>
				<Col xl="12" className="d-flex justify-content-end">
					<Button
						type="button"
						color={props.promotion.active ? "danger" : "primary"}
						className="btn-rounded btn-lg  mb-2 me-2"
						onClick={handleChangeStatus}
					>
						{props.promotion.active ? "Deactivate" : "Activate"}
					</Button>
				</Col>
			</Row>
			)}
		</Container>
	)
}
PromotionOverview.propTypes = {
	setStores: PropTypes.func,
	promotion: PropTypes.any
}
PromotionOverview.displayName = 'PromotionOverview'
export default PromotionOverview