import React, { useState, useEffect, useRef } from "react"
import { Button, Row, Col, Card, CardBody } from "reactstrap"
import { withRouter } from "react-router-dom"
import {
  VehicleBrandList,
  VehicleMasterDataQuery,
  VehicleRentalsQuery,
} from "gql/query"
import { useLazyQuery } from "@apollo/client"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from "react-icons/go"
import PropTypes from "prop-types"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useSelector } from "react-redux"
import { SelectPicker } from "rsuite"
import Loader from "../../../components/Loader/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import VehicleRentalsCreate from "./vehicle-retnals-create"
import moment from "moment"
import { HtmlToExcel } from "pages/Common/HtmlToExcel"
import { useMutation } from "@apollo/client"
import Swal from "sweetalert2"
import axios from "axios"
import {
  Delete_master_vehicle_data,
  Delete_master_vehicle_rentals_data,
} from "gql/Mutation"
import EcommerceRentalsMasterDataFilter from "components/Filters/Ecommerce/EcommerceRentalsMasterDataFilter"
import { BuildMasterDataFilterQuery } from "./Vehicle-retnals-.functions"
import { BikesHtmlToExcelHeaders, CarsHtmlToExcelHeaders } from "./columns"
import EcomerceRentalVehiclesFilter from "components/Filters/Ecommerce/EcommerceRentalsVehiclesFilter"

const FormatMasterData = (data) => {
  const brands = data?.rental_vehicles?.map((elem) => {
    return {
      id: elem?.id,
      active: elem?.active,
      minimum_hours: elem?.minimum_hours,
      minimum_kms: elem?.minimum_kms,
      driver: elem?.driver,
      photo_urls: elem?.photo_urls,
      rental_type: elem?.rental_type,
      vehicle_documents: elem?.vehicle_documents,
      deposite_amount: elem?.deposite_amount,
      kms_driven: elem?.kms_driven,
      price_per_hour: elem?.price_per_hour,
      seats: elem?.seats,
      fuel_type: elem?.fuel_type,
      transmission_type: elem?.transmission_type,
      vehicle_group: elem?.vehicle_group,
      vehicle_name: elem?.vehicle_name,
      vehicle_no: elem?.vehicle_no,
      created_at: elem?.created_at,
      updated_at: elem?.updated_at,
      created_by: elem?.user?.name,
    }
  })
  return brands
}

const VehicleRentalsList = (props) => {
  const { capabilities } = useSelector((state) => state.Login)
  const [queryCondition, setQueryCondition] = useState([])
  const [brands, setMasterData] = useState([])
  const [modal, setModal] = useState(false)
  const [brandModalOpen, setBrandOpen] = useState(false)
  const [selectedRentals, setselectedRentals] = useState({})
  const [PageData, setPageData] = useState({ limit: 10, pageNo: 1 })
  const [deleteVehicleRentalsData] = useMutation(
    Delete_master_vehicle_rentals_data
  )
  const [filterValue, setFilterValue] = useState("")

  //Open new tab if clicks on an image
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  var node = useRef()
  const limitData = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ]
  const defaultSorted = []

  const VehicleBrandColumns = (toggleModal) => [
    {
      text: "ID",
      dataField: "id",
      sort: false,
    },
    {
      text: "Active",
      dataField: "active",
      sort: true,
    },
    {
      text: "Min Hours",
      dataField: "minimum_hours",
      sort: true,
    },

    {
      text: "Min Kms",
      dataField: "minimum_kms",
      sort: true,
    },

    // {
    //   text: "Photo",
    //   dataField: "photo_urls",
    //   sort: true,
    //   formatter: (cellContent, row) => {
    //     return row?.photo_urls?.map((e, i) => {
    //       return (
    //         <span key={i} style={{ position: "relative", margin: "5px" }}>
    //           <img
    //             alt="no image"
    //             key={i}
    //             className="absolute inset-0 w-full h-full object-cover object-center "
    //             src={e}
    //             onClick={() => {
    //               openInNewTab(e)
    //             }}
    //             style={{ width: "30px", cursor: "pointer" }}
    //           />
    //         </span>
    //       )
    //     })
    //   },
    // },
    {
      text: "Rental Type",
      dataField: "rental_type",
      sort: true,
    },
    // {
    //   text: "Vehicle Documents",
    //   dataField: "vehicle_documents",
    //   sort: true,
    // },
    {
      text: "Deposite Amount",
      dataField: "deposite_amount",
      sort: true,
    },
    {
      text: "KMS Driven",
      dataField: "kms_driven",
      sort: true,
    },
    {
      text: "Price Per Hour",
      dataField: "price_per_hour",
      sort: true,
    },

    {
      text: "Price Per Hour Offer",
      dataField: "price_per_hour_offer",
      sort: true,
    },

    {
      text: "Seats",
      dataField: "seats",
      sort: true,
    },
    {
      text: "Fuel Type",
      dataField: "fuel_type",
      sort: true,
    },

    {
      text: "Transimission Type",
      dataField: "transmission_type",
      sort: true,
    },
    {
      text: "Vehicle Group",
      dataField: "vehicle_group",
      sort: true,
    },
    {
      text: "Lighting",
      dataField: "vehicle_name",
      sort: true,
    },
    {
      text: "Vehicle No",
      dataField: "vehicle_no",
      sort: true,
    },
    {
      text: "Vehicle Name",
      dataField: "vehicle_name",
      sort: true,
    },

    {
      text: "Created At",
      dataField: "created_at",
      sort: true,
      formatter: (cellContent, row) =>
        moment(row.created_at).format("DD/MM/YYYY h:mm A"),
    },

    {
      text: "Updated At",
      dataField: "updated_at",
      sort: true,
      formatter: (cellContent, row) =>
        moment(row.created_at).format("DD/MM/YYYY h:mm A"),
    },

    {
      text: "Created By",
      dataField: "created_by",
      sort: true,
    },

    {
      text: "Updated By",
      dataField: "updated_by",
      sort: true,
    },

    {
      dataField: "view",
      isDummyField: true,
      text: "Action",
      sort: false,
      hidden: !capabilities.includes("edit"),
      formatter: (cellContent, row) => (
        <Dropdown>
          <Dropdown.Toggle variant="transparent" id="dropdown-basic">
            <GoKebabVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setselectedRentals(row)
                setBrandOpen(true)
              }}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                mutateRecord(row?.id)
              }}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]

  const mutateRecord = async (id) => {
    const result = await deleteVehicleRentalsData({ variables: { id: id } })

    if (result?.data) return Swal.fire("Deleted!", "", "success")

    Swal.fire("Not Deleted. Contact Admin!", "", "success")
  }

  const handleSearch = (e) => {
    let value = e.target.value
    if (value.length >= 2) {
      let conditionTemp = {}
      if (props.queryCondition !== null) {
        conditionTemp = { ...props.queryCondition }
      }
      conditionTemp = {
        ...conditionTemp,

        ...{ naming: { _cast: { String: { _ilike: `%${value}%` } } } },
      }
      setQueryCondition(conditionTemp)
    } else {
      let conditionTemp = {}
      if (props.queryCondition !== null) {
        conditionTemp = { ...props.queryCondition }
      }
      setQueryCondition(conditionTemp)
      refetch()
    }
  }
  const PageDown = () => {
    if (PageData.pageNo > 0)
      setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
  }
  const PageUp = () => {
    setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
  }
  const toggle = () => {
    setModal(!modal)
  }
  const handleTableChange = (type, { page, searchText }) => {}
  const [getVehicleRentalsList, { loading, data, refetch, error }] =
    useLazyQuery(VehicleRentalsQuery, {
      skipCache: true,

      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: { _or: queryCondition },
      },
    })

    console.log("Vehicle-rental", data)

  useEffect(() => {
    let conditionTemp = BuildMasterDataFilterQuery(
      filterValue,
      props.queryCondition
    )
    setQueryCondition(conditionTemp)
  }, [filterValue])

  useEffect(() => {
    if (props.queryCondition !== null) {
      setQueryCondition(props.queryCondition)
    } else {
      getVehicleRentalsList()
    }
  }, [props.queryCondition])
  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getVehicleRentalsList()
    } else {
      getVehicleRentalsList()
    }
  }, [queryCondition])
  useEffect(() => {
    if (data) {
      setMasterData(FormatMasterData(data))
    }
  }, [data])

  const count = data?.rental_vehicles?.aggregate?.count || 0
  const maxPage = Math.ceil(count / PageData.limit)

  return (
    <>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                data={brands || []}
                columns={VehicleBrandColumns(toggle)}
                bootstrap4
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault()
                              }}
                            >
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <i className="bx bx-search-alt search-icon" />
                                <Form.Control
                                  type="text"
                                  placeholder="Search rentals"
                                  onChange={handleSearch}
                                />
                              </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </Col>

                      {props.showCreateBtn && capabilities.includes("create") && (
                        <Col sm="8">
                          <div className="text-sm-end d-flex justify-content-end">
                            <Button
                              className="m-1"
                              color="primary"
                              onClick={() => setBrandOpen(true)}
                            >
                              <GoPlus style={{ marginRight: 5 }} />
                              Create Rentals
                            </Button>
                            <EcomerceRentalVehiclesFilter
                              onFilterChange={setFilterValue}
                              activeFilters={props.activeFilters}
                              filterQueryConditions={
                                props.filterQueryConditions
                              }
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <SelectPicker
                          data={limitData}
                          searchable={false}
                          style={{ width: 100 }}
                          placeholder="Limit"
                          value={PageData.limit}
                          onChange={(value) =>
                            setPageData({ ...PageData, limit: value })
                          }
                          cleanable={false}
                        />
                      </div>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          {loading ? (
                            <Loader />
                          ) : (
                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              keyField="id"
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChange}
                              ref={node}
                              noDataIndication={() => "No Categories Found!"}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-40">
                      <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                        <Button
                          disabled={PageData.pageNo <= 1}
                          onClick={() => {
                            PageDown()
                          }}
                        >
                          Previous Page
                        </Button>
                        <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}</div>
                        <Button
                          disabled={PageData.pageNo >= maxPage}
                          onClick={() => {
                            PageUp()
                          }}
                        >
                          Next Page
                        </Button>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {capabilities.includes("create") && (
        <VehicleRentalsCreate
          open={brandModalOpen}
          close={() => {
            setselectedRentals({})
            setBrandOpen(false)
          }}
          onSubmit={() => refetch()}
          masterData={selectedRentals}
        />
      )}
    </>
  )
}

VehicleRentalsList.propTypes = {
  queryCondition: PropTypes.any,
  activeFilters: PropTypes.array,
  filterQueryConditions: PropTypes.any,
  showCreateBtn: PropTypes.bool,
}

export default withRouter(VehicleRentalsList)
