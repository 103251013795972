import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import Loader from "../../../components/Loader/Loader"
import { isEmpty } from "lodash"
import { useHistory } from "react-router"
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	Label,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Dropdown, Form } from "react-bootstrap"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { SelectPicker } from 'rsuite'

import { CustomrList } from "gql/query"
//redux
import { useQuery, useMutation, useLazyQuery } from "@apollo/client"
import {
	AddCustomerFormDataQuery,
	InsertCustomersOneMutation,
	UpdateCustomerMutation,
	DeleteCustomerMutation,
} from "./EcommerceCustomers.query"

//Import Filters
import EcommerceCustomerFilter from "components/Filters/Ecommerce/EcommerceCustomerFilter"

//Functions
import { EcommerceCustomersColumns, FormatData, BuildCustomerSearchQuery, BuildCustomerFilterQuery } from "./EcommerceCustomers.functions"

import { useSelector } from "react-redux"



const getAddCustomerFormData = (queryData) => {
	const { data } = queryData
	if (data) {
		const customerRole = data.enum_user_roles.map(elem => (
			{
				id: elem.value,
				name: elem.comment || elem.value,
			}
		))
		return {
			customerRole,
		}
	}
	return {
		customerRole: [],
	}
}

const EcommerceCustomersTable = props => {
	const { capabilities } = useSelector((state) => state.Login)
	const history = useHistory()
 	const { ExportCSVButton } = CSVExport
	const [Selectecustomer, setSelectecustomer] = useState(null)
	const [customers, setCustomers] = useState([])
	const [modal1, setModal1] = useState(false)
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({})
	const [filterValue, setFilterValue] = useState({})
	const [searchKey, setSearchKey] = useState("")
	const limitData = [{ label: "50", value: 50 }, { label: "100", value: 100 }, { label: "200", value: 200 }, { label: "500", value: 500 }]

	const [getCustomerList, { loading, data, refetch: customerListRefetch }] = useLazyQuery(CustomrList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition,
			order_by: props.orderBy
		}
	})
	const handleSearch = (value) => {
		const conditionTemp = BuildCustomerSearchQuery(value, props.queryCondition)
		setQueryCondition(conditionTemp)
	}

	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}

	useEffect(() => {
		if (data) {
			setCustomers(FormatData(data))
		}
	}, [data])
	const count = data?.customers_aggregate?.aggregate?.count || 0
	const maxPage = Math.ceil(count / PageData.limit)

	const [modal, setModal] = useState(false)
	const [customerList, setCustomerList] = useState([])
	const [isEdit, setIsEdit] = useState(false)

	//pagination customization
	const pageOptions = {
		sizePerPage: 10,
		totalSize: customers.length, // replace later with size(orders),
		custom: true,
	}

	const toggle = () => {
		setModal(!modal)
	}

	const handleCustomerClick = arg => {
		const customer = arg
		setCustomerList({
			id: customer.id,
			name: customer.name,
			phone: customer.phone,
			email: customer.email,
			role: customer.role,
			active: customer.active,
		})
		setIsEdit(true)
		toggle()
	}

	var node = useRef()
	/**
	 * Handling submit customer on customer form
	 */

	const [insertCustomersOne] = useMutation(InsertCustomersOneMutation)
	const [deleteCustomerMutation] = useMutation(DeleteCustomerMutation)
	const [updateCustomerMutation] = useMutation(UpdateCustomerMutation)

	const handleValidCustomerSubmit = async (e, values) => {
 		if (isEdit) {
			const updateCustomer = {
				id: customerList.id,
				name: values["name"],
				phone: values["phone"],
				email: values["email"],
				role: values["customer-role"],
				active: values["active"],
			}

			// update customer
			await updateCustomerMutation({
				variables: {
					...updateCustomer,
				},
			})
			// alert("Customer Updated")
			customerListRefetch()
		} else {
			const newCustomer = {
				name: values["name"],
				phone: values["phone"],
				email: values["email"],
				role: values["customer-role"],
				active: values["active"],
			}
			// save new customer
			insertCustomersOne({
				variables: {
					object: newCustomer,
				},
			}).then((res) => {
				if (res.data.insert_customers_one.id) {
					alert("Customer Added Suscessfully")
					customerListRefetch()
				}
			})

		}
		toggle()
	}

	useEffect(() => {
		setCustomerList(customers)
	}, [customers])

	useEffect(() => {
		if (!isEmpty(customers)) {
			setCustomerList(customers)
		}
	}, [customers])
	useEffect(() => {
		let conditionTemp = BuildCustomerFilterQuery(filterValue, props.queryCondition)
		setQueryCondition(conditionTemp)
	}, [filterValue])
	useEffect(() => {
    if(searchKey.length > 0){
      handleSearch(searchKey)
    }else{
      customerListRefetch()
    }
  }, [searchKey])

	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			getCustomerList()
		}
	}, [props.queryCondition])
	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getCustomerList()
		}
	}, [queryCondition])
	// eslint-disable-next-line no-unused-vars
	const handleTableChange = (type, { page, searchText }) => {
		setCustomerList(
			customers.filter(customer =>
				Object.keys(customer).some(key =>
					customer[key].toLowerCase().includes(searchText.toLowerCase())
				)
			)
		)
	}
	const defaultSorted = []
	const selectRow = {
		mode: 'checkbox',
		clickToSelect: true
	}
	const AddCustomerFormData = useQuery(AddCustomerFormDataQuery, {
		skipCache: true,
	})
	const {
		customerRole,
	} = getAddCustomerFormData(AddCustomerFormData)
	const canEdit = capabilities.includes("edit")
	const pathName = props.pathName
	return (
		<Row>
			<Col xs="12">
				<Card>
					<CardBody>
						<ToolkitProvider
							keyField="id"
							data={customers || []}
							columns={EcommerceCustomersColumns(searchKey, filterValue, history, handleCustomerClick, canEdit, pathName)}
							bootstrap4
							exportCSV={ { onlyExportSelection: true, exportAll: true }}
						// search
						>
							{toolkitProps => (
								<React.Fragment>
									<Row className="mb-2">
										<Col sm="4">
											<div className="search-box ms-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Form onSubmit={(e)=>{
                              e.preventDefault()
                            }}>
														<Form.Group className="mb-3" controlId="formBasicEmail">
															<i className="bx bx-search-alt search-icon" />
															<Form.Control type="text" placeholder="Search Customers" onChange={(e) => setSearchKey(e.target.value)} />
														</Form.Group>
													</Form>
												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
												{capabilities.includes("edit") && (
													<ExportCSVButton
														{...toolkitProps.csvProps}
														style={{ border: "1px solid #74788d", marginRight: 10 }}
													>Export</ExportCSVButton>
												)}
												<EcommerceCustomerFilter
													onFilterChange={setFilterValue}
													activeFilters={props.activeFilters}
													filterQueryConditions={props.filterQueryConditions}
												/>
											</div>
										</Col>
									</Row>
									<Row>
										<Col xl="12">
											<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
												<div>
													<b>No. of Customers:</b> {count}
												</div>
												<div>
													<SelectPicker
														data={limitData}
														searchable={false}
														style={{ width: 100 }}
														placeholder="Limit"
														value={PageData.limit}
														onChange={(value) => setPageData({ ...PageData, limit: value })}
														cleanable={false}
													/>
												</div>
											</div>
											<div className="mb-3" />
											<div className="table-responsive">
												{loading ? <Loader /> :
													<BootstrapTable
														responsive
														bordered={false}
														striped={false}
														defaultSorted={defaultSorted}
														classes={"table align-middle table-nowrap"}
														keyField="id"
														{...toolkitProps.baseProps}
														onTableChange={handleTableChange}
														// {...paginationTableProps}
														ref={node}
														selectRow={selectRow}
														noDataIndication={() => "No Customers Found!"}
														key={`${searchKey}_${JSON.stringify(filterValue)}`}
													/>
												}
											</div>
											<Modal isOpen={modal} toggle={toggle}>
												<ModalHeader toggle={toggle} tag="h4">
													{!!isEdit
														? "Edit Customer"
														: "Add Customer"}
												</ModalHeader>
												<ModalBody>
													<AvForm
														onValidSubmit={handleValidCustomerSubmit}
													>
														<Row form>
															<Col className="col-12">
																<div className="mb-3">
																	<AvField
																		name="name"
																		label="Name"
																		type="text"
																		errorMessage="Invalid name"
																		validate={{
																			required: { value: true },
																		}}
																		value={
																			customerList.name || ""
																		}
																	/>
																</div>
																<div className="mb-3">
																	<AvField
																		name="phone"
																		label="Phone No"
																		type="text"
																		errorMessage="Invalid Phone no"
																		validate={{
																			required: { value: true },
																		}}
																		value={customerList.phone || ""}
																	/>
																</div>
																<div className="mb-3">
																	<AvField
																		name="email"
																		label="Email Id"
																		type="email"
																		value={customerList.email || ""}
																	/>
																</div>
																<div className="mb-3">
																	<Label className="control-label">Role</Label>
																	<AvField
																		type="select"
																		name="customer-role"
																		className="form-control select2"
																		errorMessage="Invalid shop name"
																		value={customerList.role || ""}
																	>
																		<option value="">Select</option>
																		{customerRole.map((elem, index) => (
																			<option value={elem.id} key={index}>{elem.name}</option>
																		))
																		}
																	</AvField>
																</div>
																<div className="mb-3">
																	<AvField
																		name="active"
																		label="Active"
																		type="checkbox"
																		value={customerList.active || ""}
																	/>
																</div>
															</Col>
														</Row>
														<Row>
															<Col>
																<div className="text-end">
																	<button
																		type="submit"
																		className="btn btn-success save-customer"
																	>
																		Save
																	</button>
																</div>
															</Col>
														</Row>
													</AvForm>
												</ModalBody>
											</Modal>
										</Col>
									</Row>
									<Row className="align-items-md-center mt-30">
										<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
											<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
												Previous Page
											</Button>
											<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
											</div>
											<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
												Next Page
											</Button>
										</Col>
									</Row>
								</React.Fragment>
							)}
						</ToolkitProvider>
						{/* )}
            </PaginationProvider> */}
					</CardBody>
				</Card>
			</Col>
		</Row>
	)
}
EcommerceCustomersTable.propTypes = {
	orderBy: PropTypes.any,
	queryCondition: PropTypes.any,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any,
	pathName: PropTypes.any,

}
export default EcommerceCustomersTable