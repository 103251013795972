import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceCustomersTable from "../../EcommerceCustomers/EcommerceCustomersTable"
const PromotionCategories = (props) => {
    const match = useRouteMatch()
    const { customers } = props.promotion
// console.log(props.pathName)
    return (
        <div className="p-3">
            {Array.isArray(customers) && (
                <EcommerceCustomersTable
                    queryCondition={{ id: { _in: customers } }}
                    activeFilters={["gender", "created_at", "city"]}
                    filterQueryConditions={null}
                    showCreateBtn={false}
                    // pathName={pathName}
                />
            )}
        </div>
    )
}
PromotionCategories.propTypes = {
    promotion: PropTypes.any,
    // pathName:PropTypes.any
}
PromotionCategories.displayName = 'PromotionCategories'
export default PromotionCategories