import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'

//import components
import EcommerceOrdersTable from "../EcommerceOrders/EcommerceOrdersTable"

const MerchantOrders = (props) => {
    const [queryCondition, setQueryCondition] = useState({})
    useEffect(() => {
        if (props.stores?.length) {
            let storeIDs = props.stores.map(store => store.id)
            setQueryCondition({ store_id: { _in: storeIDs } })
        }
    }, [props.stores])
    return (
        <div className="p-3">
            <EcommerceOrdersTable
                queryCondition={queryCondition}
                activeFilters={["status", "store", "created_at", "city", "total_amount", "delivery_charges"]}
                filterQueryConditions={[{filter: "store", condition: {id: {_in: props.stores?.map(store => store.id)}}}]}
                showFilters={true}
            />
        </div>
    )
}
MerchantOrders.propTypes = {
    stores: PropTypes.array,
    setSelectedStores: PropTypes.func,
    setActiveTab: PropTypes.func,
    selectedStores: PropTypes.any
}
MerchantOrders.displayName = 'MerchantOrders'
export default MerchantOrders