import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Col,
	Container,
	Row,
	Button
} from "reactstrap"
import moment from "moment"
import { Badge } from "react-bootstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import download from 'downloadjs'
import Swal from "sweetalert2"
import { useSelector } from "react-redux"

//component
// import OrderEditModal from "./OrderEditModal"

const ServiceOverview = (props) => {
 	const { capabilities } = useSelector((state) => state.Login)
	const [merchant, setMerchant] = useState({})
	const [scheduleBtnLoading, setScheduleBtnLoading] = useState(false)
	const [openEditModal, setOpenEditModal] = useState(false)
	const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${parseFloat(value).toFixed(2)}`
		} else {
			return "N/A"
		}
	}
 
 
  
	return (
		<Container >
			<Row className="m-8">
				<Col sm="4">
			  
					<Row className="p-1">
					     <Col>Landmark </Col>: <Col>{props?.data?.landmark}</Col>
					</Row>
				
					<Row className="p-1">
					     <Col>Category </Col>: <Col>{props?.data?.service_category?.category}</Col>
					</Row>
					<Row className="p-1">
						 <Col>Created By </Col>: <Col>{props?.data?.UpdatedBy?.name}</Col>
					</Row>
					<Row className="p-1">
						 <Col>Created At </Col>: <Col>{new Date(props?.data?.created_at)?.toDateString()}</Col>
					</Row>
					<Row className="p-1">
						 <Col>Updated At </Col>: <Col>{new Date(props?.data?.updated_at)?.toDateString()}</Col>
					</Row>
					 
 				</Col>
				<Col sm="4" className="pl-3">
					<Row className="p-1">
							<Col>Active  </Col>: <Col>{props?.data?.active ? "True" : "False"}</Col>
					</Row>
					<Row className="p-1">
						 <Col>Deleted   </Col>: <Col>{props?.data?.is_deleted ? "True":"False"}</Col>
					</Row>
				</Col>
			 
			</Row>
		 
		</Container>
	)
}
ServiceOverview.propTypes = {
	order: PropTypes.any,
	refetch: PropTypes.func,
	data: PropTypes.any,

}
ServiceOverview.displayName = 'ServiceOverview'
export default ServiceOverview