import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import Loader from "../../../components/Loader/Loader"
import { isEmpty } from "lodash"
import { Link, useRouteMatch } from "react-router-dom"
import * as moment from "moment"
import { useHistory } from "react-router"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
import OrderOverview from "./OrderOverview"
import OrderItems from "./OrderItems"
import ServiceItems from "./ServiceItems"
import RentalVehicles from "./RentalVehicles"
import OrderTimeline from "./OrderTimeline"
import EcommerceReviewsTable from "../EcommerceReviews/EcommerceReviewsTable"
import RentalVehicleDetails from "./rentalVehicleDetails"
import RentalDriverDetails from "./rentalDriverDetails"
import RentalChecklist from "./rentalChecklist"

//Import custom styles
import "./EcommerceOrderDetails.style.scss"

//GraphQL
import { getOrderDetail_byId } from "gql/query"
import { useQuery } from "@apollo/client"

const EcommerceOrderDetails = (props) => {
  const pathName = props.location.pathname
  const match = useRouteMatch()
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState({})
  const [activeTab, setActiveTab] = useState("overview")
  const [orderDetails, setOrderDetails] = useState({})
  const { data, loading, error, refetch } = useQuery(getOrderDetail_byId, {
    skipCache: true,
    variables: {
      id: match?.params?.id,
    },
  })
  useEffect(() => {
    if (data?.orders?.length) {
      setOrderDetails(data.orders[0])
    }
  }, [data])

  return (
    <div className="EcommerceOrderDetails">
      <div className="page-content">
        <MetaTags>
          <title>
            Order Details | EEFind - React Admin & Dashboard Template
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Orders"
            breadcrumbItem={
              pathName.startsWith("/groceries/ecommerce-orders/details")
                ? "Order Details"
                : pathName.startsWith("/services/service-bookings/details")
                ? "Booking Details"
                : pathName.startsWith("/vehicles")
                ? "Rental Details"
                : "Booking Details"
            }
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs
                    activeKey={activeTab}
                    onSelect={setActiveTab}
                    className="mb-3"
                  >
                    <Tab eventKey="overview" title="Overview">
                      <OrderOverview
                        order={orderDetails}
                        refetch={refetch}
                        pathName={pathName}
                      />
                    </Tab>
                    <Tab
                      eventKey="order_timeline"
                      title={
                        pathName.startsWith("/groceries")
                          ? "Order Timeline"
                          : pathName.startsWith("/services")
                          ? "Booking Timeline"
                          : "Booking Timeline"
                      }
                    >
                      <OrderTimeline order={orderDetails} />
                    </Tab>

                    {pathName.startsWith(
                      "/groceries/ecommerce-orders/details"
                    ) && (
                      <Tab eventKey="order_items" title="Ordered Items">
                        <OrderItems
                          order={orderDetails}
                          showLabel={true}
                          label={`No. of Items: ${
                            orderDetails?.order_items?.length || 0
                          }`}
                        />
                      </Tab>
                    )}
                    {pathName.startsWith(
                      "/services/service-bookings/details"
                    ) && (
                      <Tab eventKey="service_items" title="Booked Items">
                        <ServiceItems
                          order={orderDetails}
                          showLabel={true}
                          label={`No. of Items: ${
                            orderDetails?.service_items?.length || 0
                          }`}
                        />
                      </Tab>
                    )}
                    {pathName.startsWith(
                      "/vehicles/vehicles-orders/details"
                    ) && (
                      <Tab eventKey="rental_vehicles" title="Booked Vehicles">
                        <RentalVehicles
                          order={orderDetails}
                          showLabel={true}
                          label={`No. of Items: ${
                            orderDetails?.rental_order_items?.length || 0
                          }`}
                        />
                      </Tab>
                    )}
                    {pathName.startsWith("/vehicles") && (
                      <Tab eventKey="vehicle_details" title="Vehicle Details">
                        <RentalVehicleDetails order={orderDetails} />
                      </Tab>
                    )}
                    {pathName.startsWith(
                      "/vehicles/vehicles-orders/details"
                    ) && (
                      <Tab eventKey="driver_details" title="Driver Details">
                        <RentalDriverDetails order={orderDetails} />
                      </Tab>
                    )}
                    {pathName.startsWith(
                      "/vehicles/vehicles-orders/details"
                    ) && (
                      <Tab eventKey="checklist" title="Checklist">
                        <RentalChecklist order={orderDetails} />
                      </Tab>
                    )}

                    <Tab eventKey="reviews" title="Reviews">
                      <EcommerceReviewsTable
                        queryCondition={{
                          order_id: { _eq: match?.params?.id },
                        }}
                        activeFilters={[]}
                        filterQueryConditions={null}
                      />
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommerceOrderDetails.propTypes = {
  location: PropTypes.object,
}
export default EcommerceOrderDetails
