import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceProductsTable from "../EcommerceProducts/EcommerceProductsTable"

const MerchantProducts = (props) => {
	const match = useRouteMatch()
	const [queryCondition, setQueryCondition] = useState({ created_by: { _eq: match?.params?.id } })
	useEffect(()=>{
		setQueryCondition({ created_by: { _eq: match?.params?.id } })
	}, [])
	return (
		<div className="p-3">
			<EcommerceProductsTable
                queryCondition={queryCondition}
                activeFilters={["store", "store_type", "product_brand", "category", "subcategory", "created_at", "non_mrp"]}
                filterQueryConditions={[
					{filter: "store", condition: {id: {_in: props.stores?.map(store => store.id)}}},
					{filter: "product_brand", condition: {master_products: {products: {store_id: {_in: props.stores?.map(store => store.id)}}}}},
					{filter: "subcategory", condition: {master_products: {products: {store_id: {_in: props.stores?.map(store => store.id)}}}}},
					{filter: "category", condition: {master_products: {products: {store_id: {_in: props.stores?.map(store => store.id)}}}}},
				]}
            />
		</div>
	)
}
MerchantProducts.propTypes = {
	stores: PropTypes.array,
	selectedStores: PropTypes.any
}
MerchantProducts.displayName = 'MerchantProducts'
export default MerchantProducts