import React, {useEffect, useState} from "react"
import { Modal, Timeline, Panel } from "rsuite"
import PropTypes from "prop-types"
import {
    Button,
    Col,
    Row
} from "reactstrap"
import { CreditCardPlus, Check, Close } from '@rsuite/icons'
import "./EcommercePayouts.styles.scss"
import * as moment from "moment"

//GraphQL
import {GetCancelledOrdersForPayoutCycle} from "gql/query"
import { useApolloClient } from "@apollo/client"

const EcommercePayoutsDetailModal = (props) => {
    const client = useApolloClient()
    const [cancelledOrders, setCancelledOrders] = useState({})

    const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${value.toFixed(2)}`
		} else {
			return "₹0"
		}
	}
    const {orders_aggregate, commissions_aggregate} = props.payout

    const fetchCancelledOrders = async() =>{
        const { data } = await client.query({
            query: GetCancelledOrdersForPayoutCycle,
            skipCache: true,
            variables: {
                storeId: props.payout.store_id,
                startDate: moment(props.payout.start_date).format("YYYY-MM-DD"),
                endDate: moment(props.payout.end_date).format("YYYY-MM-DD")
            }
        })
        if(data?.orders_aggregate?.aggregate){
            setCancelledOrders(data?.orders_aggregate?.aggregate)
        }
    }
    useEffect(()=>{
        if(props.payout?.id){
            fetchCancelledOrders()
        }
    }, [props.payout])
    return (
        <Modal
            open={props.open}
            onClose={() => props.close()}
            className="EcommercePayoutsDetailModal"
        >
            <Modal.Header>
                <Modal.Title>Payout Breakdown</Modal.Title>
            </Modal.Header>
            <Row className="payout-orders">
                <Col xs="5">
                    <Panel header="Delivered Orders" shaded>
                        <strong>{orders_aggregate?.aggregate?.count}</strong>
                    </Panel>
                </Col>
                <Col xs="5">
                    <Panel header="Cancelled Orders" shaded>
                        <strong>{cancelledOrders?.count}</strong>
                    </Panel>
                </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
                <Col xs="12">
                    <Timeline className="payout-timeline">
                        <Timeline.Item dot={<Check />}>
                            <strong>Delivered</strong>
                            <p>Breakdown for Delivered Orders</p>
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Total Customer Payable: </strong> {formatPrice(orders_aggregate?.aggregate?.sum?.total_amount)}
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Total Service Fees: </strong> <span className="text-danger">-{formatPrice(orders_aggregate?.aggregate?.sum?.delivery_charges)}</span>
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Order Level Adjustments: </strong> <span className="text-danger">-{formatPrice(orders_aggregate?.aggregate?.sum?.cancelled_amount)}</span>
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>TCS &amp; TDS: </strong> <span className="text-danger">-₹0</span>
                        </Timeline.Item>
                        <Timeline.Item dot={<Close />}>
                            <strong>Cancelled</strong>
                            <p>Breakdown for Cancelled Orders</p>
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Total Customer Payable: </strong> {formatPrice(cancelledOrders?.sum?.total_amount)}
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Total Service Fees: </strong> <span className="text-danger">-{formatPrice(cancelledOrders?.sum?.delivery_charges)}</span>
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Order Level Adjustments: </strong> <span className="text-danger">-{formatPrice(cancelledOrders?.sum?.cancelled_amount)}</span>
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>TCS &amp; TDS: </strong> <span className="text-danger">-₹0</span>
                        </Timeline.Item>
                        <Timeline.Item dot={<CreditCardPlus />}>
                            <strong>Other Charges &amp; Refunds</strong>
                            <p>Breakdown for Other Charges &amp; Refunds</p>
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Adjustments: </strong> {formatPrice(commissions_aggregate?.aggregate?.sum?.total_commission)}
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Refund: </strong> <span className="text-danger">-{formatPrice(cancelledOrders?.sum?.total_amount)}</span>
                        </Timeline.Item>
                        <Timeline.Item>
                            <strong>Pending Deductions: </strong> <span className="text-danger">-₹0</span>
                        </Timeline.Item>
                        <Timeline.Item dot={<CreditCardPlus />}>
                            <strong>Net Payout: </strong> <span className="text-success">{formatPrice(props.payout?.total_amount)}</span>
                            <p>Net payout for this payment cycle</p>
                        </Timeline.Item>
                    </Timeline>
                </Col>
            </Row>
        </Modal>
    )
}
EcommercePayoutsDetailModal.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    payout: PropTypes.any
}
export default EcommercePayoutsDetailModal