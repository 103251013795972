import React from "react"
import { Dropdown } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import { Link } from "react-router-dom"
import moment from "moment"
import { GoKebabVertical } from "react-icons/go"

export const FormatData = (data) => {
  const customers = data.customers.map((elem) => {
    let city = "N/A"
    if (elem.user_locations?.length && Array.isArray(elem.user_locations)) {
      city = elem.user_locations[0]?.city
      if (elem.user_locations.length > 1) {
        city += ` and ${elem.user_locations.length - 1} others`
      }
    }
    return {
      email: elem.email,
      id: elem.id,
      phone: elem.phone,
      name: elem.name,
      joiningDate: elem.created_at,
      role: elem.role,
      active: elem.active,
      photo: elem.photo_url,
      gender: elem.gender || "N/A",
      no_of_orders: elem.ordersByCustomerId_aggregate?.aggregate?.count,
      total_order_values:
        elem.ordersByCustomerId_aggregate?.aggregate?.sum?.total_amount,
      referral: "N/A",
      customer_id: elem.customer_id,
      city,
    }
  })
  return customers
}
const formatHighlighter = (text, searchKey, filterValue) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.status)) {
    searchWords = [...searchWords, ...filterValue?.status]
  }
  if (Array.isArray(filterValue?.city)) {
    searchWords = [...searchWords, ...filterValue?.city]
  }
  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  )
}
const handleValidDate = (date) => {
  const date1 = moment(new Date(date)).format("DD MMM Y")
  return date1
}
export const EcommerceCustomersColumns = (
  searchKey,
  filterValue,
  history,
  handleCustomerClick,
  canEdit,
  pathName,
  
) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => <>{row.id}</>,
  },
  {
    dataField: "customer_id",
    text: "ID",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.id) {
        return (
          <Link
            to={
              pathName?.startsWith("/groceries")
                ? `/groceries/ecommerce-customers/details/${row.id}`
                : pathName?.startsWith("/services")
                ? `/services/service-customers/details/${row.id}`
                : ""
            }
          >
            {formatHighlighter(row.customer_id, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.customer_id, searchKey, filterValue)
      }
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    text: "Email",
    dataField: "email",
    sort: true,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    text: "Gender",
    dataField: "gender",
    sort: false,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    text: "City",
    dataField: "city",
    sort: false,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    text: "No. of Orders",
    dataField: "no_of_orders",
    sort: true,
  },
  {
    text: "Total Order Value",
    dataField: "total_order_values",
    sort: true,
    formatter: (cellContent, row) =>
      row.total_order_values ? `₹${row.total_order_values}` : 0,
  },
  {
    dataField: "referral",
    text: "Referral",
    sort: false,
  },
  {
    dataField: "joiningDate",
    text: "Joining Date",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => handleValidDate(row.joiningDate),
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          <GoKebabVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              history.push(
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-customers/details/${row.id}`
                  : pathName.startsWith("/services")
                  ? `/services/service-customers/details/${row.id}`
                  : ""
              )
            }
          >
            View Details
          </Dropdown.Item>
          {canEdit && (
            <Dropdown.Item onClick={() => handleCustomerClick(row)}>
              Edit
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
]
export const BuildCustomerSearchQuery = (value, queryCondition) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { name: { _ilike: `%${value}%` } },
        { customer_id: { _ilike: `%${value}%` } },
        { phone: { _ilike: `%${value}%` } },
        { email: { _ilike: `%${value}%` } },
        { user_locations: { city: { _ilike: `%${value}%` } } },
      ],
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const BuildCustomerFilterQuery = (filterValue, queryCondition) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("gender")) {
      if (filterValue.gender?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          _or: [
            {
              gender: { _in: filterValue.gender },
            },
            {
              gender: {
                _in: filterValue.gender.map((item) =>
                  capitalizeFirstLetter(item)
                ),
              },
            },
          ],
        }
      }
    }
    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { created_at: { _gte: filterValue.created_at[0] } },
            { created_at: { _lte: filterValue.created_at[1] } },
          ],
        }
      }
    }
    if (filterValue?.hasOwnProperty("city")) {
      if (filterValue.city?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          user_locations: { city: { _in: filterValue.city } },
        }
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
