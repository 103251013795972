import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceSubCategoriesTable from "../../SubCategories/EcommerceSubCategories/EcommerceSubCategoriesTable"
import EcommerceSubServicesTable from "pages/Ecommerce/SubServices/EcommerceSubServices/EcommerceSubServicesTable"
const PromotionSubcategories = (props) => {
    const match = useRouteMatch()
    const { subservices } = props.promotion
    return (
        <div className="p-3">
            {Array.isArray(subservices) && (
                <EcommerceSubServicesTable
                    queryCondition={{ id: { _in: subservices } }}
                    activeFilters={["product_brand", "category", "subcategory", "store", "created_at", "non_mrp"]}
                    filterQueryConditions={null}
                    showCreateBtn={false}
                />
            )}
        </div>
    )
}
PromotionSubcategories.propTypes = {
    promotion: PropTypes.any
}
PromotionSubcategories.displayName = 'PromotionSubcategories'
export default PromotionSubcategories