import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withRouter, useHistory } from "react-router-dom"
import { SelectPicker } from "rsuite"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../../components/Loader/Loader"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import moment from "moment"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from "react-icons/go"

import ToolkitProvider from "react-bootstrap-table2-toolkit"

import { PropertyCreateList } from "pages/Property/PropertyList/index"
//redux
import { useQuery, useLazyQuery } from "@apollo/client"
import { PropertyDetailsList } from "gql/query"

//filters
// import EcommerceProductsFilter from "components/Filters/Ecommerce/EcommerceProductsFilter"

import { useSelector } from "react-redux"

const FormatCategoryData = (data) => {
  const categories = data.property_details.map((elem) => {
    return {
      id: elem.id,
      name: elem.property_name || "N/A",
      type: elem.portion_type || "N/A",
      address: `${elem.door_no || ""} ${elem.address_1 || ""} ${
        elem.landmark || ""
      } ${elem.city || ""} ${elem.state || ""} ${elem.pincode || ""}`,
      created_at: elem.created_at,
      active: elem.active,
      no_of_bedrooms: elem.no_of_bedrooms || "N/A",
      no_of_beds: elem.no_of_beds || "N/A",
      no_of_max_guests: elem.no_of_max_guests || "N/A",
      no_of_bathroom: elem.no_of_bathroom || "N/A",
      no_of_kitchen: elem.no_of_kitchen || "N/A",
      min_night: elem.min_night || "N/A",
      max_night: elem.max_night || "N/A",
      basic_price: elem.basic_price || "N/A",
      min_price: elem.min_price || "N/A",
      recurring_type: elem.recurring_type || "N/A",
      no_of_sqft: elem.no_of_sqft || "N/A",
      sub_title: elem.sub_title || "N/A",
    }
  })
  return categories
}

const PropertyListTable = (props) => {
  const { capabilities } = useSelector((state) => state.Login)
  const [modal, setModal] = useState(false)
  const history = useHistory()
  const [categories, setCategories] = useState([])
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  })
  const [queryCondition, setQueryCondition] = useState({})
  const [filterValue, setFilterValue] = useState("")
  const [categoryModalOpen, setCategoryOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState({})
  const limitData = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ]

  const [getCategoryList, { loading, data, refetch, error }] = useLazyQuery(
    PropertyDetailsList,
    {
      skipCache: true,
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    }
  )
  const handleSearch = (e) => {
    let value = e.target.value
    if (value.length >= 2) {
      let conditionTemp = {}
      if (props.queryCondition !== null) {
        conditionTemp = { ...props.queryCondition }
      }
      conditionTemp = {
        ...conditionTemp,
        _or: [{ property_name: { _ilike: `%${value}%` } }],
      }
      setQueryCondition(conditionTemp)
    } else {
      let conditionTemp = {}
      if (props.queryCondition !== null) {
        conditionTemp = { ...props.queryCondition }
      }
      setQueryCondition(conditionTemp)
      refetch()
    }
  }
  const PageDown = () => {
    if (PageData.pageNo > 0)
      setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
  }
  const PageUp = () => {
    setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
  }
  const toggle = () => {
    setModal(!modal)
  }
  useEffect(() => {
    if (data) {
      setCategories(FormatCategoryData(data))
    }
  }, [data])
  const defaultSorted = []
  const count = data?.property_details_aggregate?.aggregate?.count || 0
  const maxPage = Math.ceil(count / PageData.limit)

  const handleTableChange = (type, { page, searchText }) => {}
  const EcommerceCategoryColumns = (toggleModal) => [
    {
      text: "ID",
      dataField: "id",
      sort: false,
      // formatter: (cellContent, row) => <Link to={`/ecommerce-categories/details/${cellContent}`}>{cellContent}</Link>
    },
    {
      text: "Property Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Portion Type",
      dataField: "type",
      sort: true,
    },
    {
      text: "Address",
      dataField: "address",
      sort: true,
    },
    {
      text: "Bedrooms",
      dataField: "no_of_bedrooms",
      sort: true,
    },
    {
      text: "Beds",
      dataField: "no_of_beds",
      sort: true,
    },
    {
      text: "Max Guest",
      dataField: "no_of_max_guests",
      sort: true,
    },
    {
      text: "Bathrooms",
      dataField: " no_of_bathroom",
      sort: true,
    },
    {
      text: "Kitchen",
      dataField: "no_of_kitchen",
      sort: true,
    },
    {
      text: "Min Night",
      dataField: "min_night",
      sort: true,
    },
    {
      text: "Max Night",
      dataField: "max_night",
      sort: true,
    },
    {
      text: "Basic Price",
      dataField: "basic_price",
      sort: true,
    },
    {
      text: "Min Price",
      dataField: "min_price",
      sort: true,
    },
    {
      text: "Recurring Type",
      dataField: "recurring_type",
      sort: true,
    },
    {
      text: "Area(Sq/ft)",
      dataField: "no_of_sqft",
      sort: true,
    },
    {
      text: "Sub Title",
      dataField: "sub_title",
      sort: true,
    },
    {
      text: "Active",
      dataField: "active",
      sort: true,
    },
    // {
    //   text: "Created At",
    //   dataField: "created_at",
    //   sort: false,
    //   formatter: (cellContent, row) =>
    //     moment(row.created_at).format("DD/MM/YYYY h:mm A"),
    // },
    // {
    //   dataField: "view",
    //   isDummyField: true,
    //   text: "Action",
    //   sort: false,
    //   hidden: !capabilities.includes("edit"),
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <Dropdown>
    //       <Dropdown.Toggle variant="transparent" id="dropdown-basic">
    //         <GoKebabVertical />
    //       </Dropdown.Toggle>
    //       <Dropdown.Menu>
    //         <Dropdown.Item
    //           onClick={() => {
    //             setSelectedCategory(row)
    //             setCategoryOpen(true)
    //           }}
    //         >
    //           Edit
    //         </Dropdown.Item>
    //       </Dropdown.Menu>
    //     </Dropdown>
    //   ),
    // },
  ]
  useEffect(() => {
    if (props.queryCondition !== null) {
      setQueryCondition(props.queryCondition)
    } else {
      getCategoryList()
    }
  }, [props.queryCondition])
  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getCategoryList()
    }
  }, [queryCondition])
  var node = useRef()
  return (
    <>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                data={categories || []}
                columns={EcommerceCategoryColumns(toggle)}
                bootstrap4
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault()
                              }}
                            >
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <i className="bx bx-search-alt search-icon" />
                                <Form.Control
                                  type="text"
                                  placeholder="Search List"
                                  onChange={handleSearch}
                                />
                              </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </Col>
                      {props.showCreateBtn && capabilities.includes("create") && (
                        <Col sm="8">
                          <div className="text-sm-end d-flex justify-content-end">
                            {/* <Link to="/ecommerce-categories/create"> */}
                            {/* <Button
                              color="primary"
                              onClick={() => setCategoryOpen(true)}
                            >
                              <GoPlus style={{ marginRight: 5 }} />
                              Create Property Detail
                            </Button> */}
                            {/* </Link> */}
                            {/* <EcommerceProductsFilter
														onFilterChange={setFilterValue}
														activeFilters={props.activeFilters}
														filterQueryConditions={props.filterQueryConditions}
													/> */}
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <SelectPicker
                          data={limitData}
                          searchable={false}
                          style={{ width: 100 }}
                          placeholder="Limit"
                          value={PageData.limit}
                          onChange={(value) =>
                            setPageData({ ...PageData, limit: value })
                          }
                          cleanable={false}
                        />
                      </div>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          {loading ? (
                            <Loader />
                          ) : (
                            <BootstrapTable
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              keyField="id"
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChange}
                              ref={node}
                              noDataIndication={() => "No Categories Found!"}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-40">
                      <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                        <Button
                          disabled={PageData.pageNo <= 1}
                          onClick={() => {
                            PageDown()
                          }}
                        >
                          Previous Page
                        </Button>
                        <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}</div>
                        <Button
                          disabled={PageData.pageNo >= maxPage}
                          onClick={() => {
                            PageUp()
                          }}
                        >
                          Next Page
                        </Button>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {capabilities.includes("create") && (
        <PropertyCreateList
          open={categoryModalOpen}
          close={() => {
            setSelectedCategory({})
            setCategoryOpen(false)
          }}
          onSubmit={() => refetch()}
          category={selectedCategory}
        />
      )}
    </>
  )
}

PropertyListTable.propTypes = {
  queryCondition: PropTypes.any,
  activeFilters: PropTypes.array,
  filterQueryConditions: PropTypes.any,
  showCreateBtn: PropTypes.bool,
}

export default withRouter(PropertyListTable)
