import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import Loader from "components/Loader/Loader"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
import ProductOverview from "./ProductOverview"

//Import custom styles
import "./EcommerceRequestDetails.style.scss"
const EcommerceRequestDetails = props => {
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState({})
  const [activeTab, setActiveTab] = useState('overview')
  return (
    <div className="EcommerceRequestDetails">
      <div className="page-content">
        <MetaTags>
          <title>Request Details | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Products in Shops" breadcrumbItem="Product Details" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
                    <Tab eventKey="overview" title="Overview">
                      <ProductOverview setStores={setStores} />
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommerceRequestDetails.propTypes = {
}
export default EcommerceRequestDetails