import React from "react"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'

//Helpers
import ConvertRangesToQuery from "helpers/range_to_query_helper"
export const FormatData = (data) => {
  const promotions = data.promotions.map(elem => {
    return {
      id: elem.id,
      store_name: elem.store?.name || "N/A",
      store_id: elem.store?.id,
      promo_id: elem.id,
      created_at: elem.created_at,
      label: elem.label,
      code: elem.code || "N/A",
      description: elem.description || "N/A",
      value_type: elem.value_type,
      value: elem.value,
      max_order_count: elem.max_order_count || 0,
      min_order_count: elem.min_order_count || 0,
      max_order_amount: elem.max_order_amount || 0,
      min_order_amount: elem.min_order_amount || 0,
      min_item_count: elem.min_item_count || 0,
      available_hours: elem.available_hours || 0,
      no_of_time_used: elem.used_count || 0,
      active: elem.active,
      promoted_by: elem.promoted_by,
      usable_count: elem.usable_count || "N/A",
      valid_from: elem.valid_from,
      valid_till: elem.valid_till,
      merchant_id: elem.creator?.vendor_company?.merchant_id || "N/A",
      user_id: elem.creator?.id
    }
  })
  return promotions
}
const formatHighlighter = (text, searchKey, filterValue) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.status)) {
    searchWords = [...searchWords, ...filterValue?.status]
  }
  if (Array.isArray(filterValue?.city)) {
    searchWords = [...searchWords, ...filterValue?.city]
  }
  return <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={searchWords}
    autoEscape={true}
    textToHighlight={String(text)}
    highlightStyle={{ background: "#ff0" }}
  />
}
export const EcommercePromotionColumns = (
  searchKey,
  filterValue,
  history,
  handleChangeStatus,
  isEdit
) =>{
  

   const path =history?.location?.pathname?.split("/")?.[1] == 'services' ?  "/services/service-promotions" : "/groceries/ecommerce-promotions" 


 


  
  return[
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true,
  },
  {
    dataField: "promo_id",
    text: "ID",
    sort: false,
    // formatter: (cellContent, row) => (
    //   <Link to={`/groceries/ecommerce-promotions/details/${row.id}`}>
    //     {formatHighlighter(row.id, searchKey, filterValue)}
    //   </Link>
    // ),
  },
  {
    dataField: "store_name",
    text: "Store",
    sort: false,
    // formatter: (cellContent, row) => {
    //   if (row.store_id) {
    //     return (
    //       <Link to={`/groceries/ecommerce-shops/details/${row.store_id}`}>
    //         {formatHighlighter(row.store_name, searchKey, filterValue)}
    //       </Link>
    //     )
    //   } else {
    //     return formatHighlighter(row.store_name, searchKey, filterValue)
    //   }
    // },
  },
  {
    dataField: "merchant_id",
    text: "Merchant",
    sort: false,
    // formatter: (cellContent, row) => {
    //   if (row.user_id) {
    //     return (
    //       <Link to={`/groceries/ecommerce-merchants/details/${row.user_id}`}>
    //         {formatHighlighter(row.merchant_id, searchKey, filterValue)}
    //       </Link>
    //     )
    //   } else {
    //     return formatHighlighter(row.merchant_id, searchKey, filterValue)
    //   }
    // },
  },
  {
    dataField: "code",
    text: "Code",
    sort: true,
  },
  {
    text: "Description",
    dataField: "description",
    sort: true,
  },
  {
    dataField: "value_type",
    text: "Discount Type",
    sort: true,
  },
  {
    text: "Value",
    dataField: "value",
    sort: true,
  },
  // {
  // 	text: "Max Order Count",
  // 	dataField: "max_order_count",
  // 	sort: false,
  // },
  {
    text: "Min Unit Count",
    dataField: "min_item_count",
    sort: false,
  },
  // {
  // 	text: "Max Order Amount",
  // 	dataField: "max_order_amount",
  // 	sort: false,
  // 	formatter: (cellContent, row) => row.max_order_amount ? `₹${row.max_order_amount}` : 0,
  // },
  {
    text: "Min Order Amount",
    dataField: "min_order_amount",
    sort: false,
    formatter: (cellContent, row) =>
      row.min_order_amount ? `₹${row.min_order_amount}` : 0,
  },
  {
    text: "Valid From",
    dataField: "valid_from",
    sort: false,
    formatter: (cellContent) => moment(cellContent).format("DD/MM/YYYY h:mm A"),
  },
  {
    text: "Valid Till",
    dataField: "valid_till",
    sort: false,
    formatter: (cellContent) => moment(cellContent).format("DD/MM/YYYY h:mm A"),
  },
  {
    text: "Maximum Usage",
    dataField: "usable_count",
    sort: false,
  },
  {
    text: "No. of Time Used",
    dataField: "no_of_time_used",
    sort: false,
  },
  {
    text: "Active",
    dataField: "active",
    sort: false,
    formatter: (cellContent, row) => (
      <p style={{ color: row.active ? "" : "#F93B55" }}>
        {row.active ? "Yes" : "No"}
      </p>
    ),
  },
  {
    text: "Promoted By",
    dataField: "promoted_by",
    sort: false,
  },
  {
    text: "Created At",
    dataField: "created_at",
    sort: false,
    formatter: (cellContent, row) =>
      moment(row.created_at).format("DD/MM/YYYY h:mm A"),
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          <GoKebabVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() =>
              history.push(`${path}/details/${row.id}`)
            }
          >
            View Details
          </Dropdown.Item>
          {isEdit && (
            <>
              {row.active === true ? (
                <Dropdown.Item
                  onClick={() => handleChangeStatus(row.id, false)}
                >
                  Deactivate
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={() => handleChangeStatus(row.id, true)}>
                  Activate
                </Dropdown.Item>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
]}
export const BuildPromotionSearchQuery = (value, queryCondition) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { code: { _ilike: `%${value}%` } },
        { store: { name: { _ilike: `%${value}%` } } },
        { creator: { vendor_company: { merchant_id: { _ilike: `%${value}%` } } } }
      ]
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
export const BuildPromotionFilterQuery = (filterValue, queryCondition) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("active")) {
       conditionTemp = {
        ...conditionTemp,
        active: { _eq: filterValue.active === "yes" ? true : false }
      }
    }
    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        let created_at_from = moment(filterValue.created_at[0]).format("YYYY-MM-DD")
        let created_at_till = moment(filterValue.created_at[1]).format("YYYY-MM-DD")
        conditionTemp = {
          ...conditionTemp,
          _and: [{ created_at: { _gte: created_at_from } }, { created_at: { _lte: created_at_till } }]
        }
      }
    }
    if (filterValue?.hasOwnProperty("store")) {
      if (filterValue.store?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          store_id: { _in: filterValue.store }
        }
      }
    }
    if (filterValue?.hasOwnProperty("discount_type")) {
      if (filterValue.discount_type?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          value_type: { _eq: filterValue.discount_type }
        }
      }
    }
    // if (filterValue?.hasOwnProperty("total_usage")) {
    //   if (filterValue.total_usage?.length > 0) {
    //     let totalUsageFilter = ConvertRangesToQuery(filterValue.total_usage, "used_count")
    //     conditionTemp = {
    //       ...conditionTemp,
    //       _or: totalUsageFilter
    //     }
    //   }
    // }
    if (filterValue?.hasOwnProperty("promoted_by")) {
      if (filterValue.promoted_by?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          promoted_by: { _eq: filterValue.promoted_by }
        }
      }
    }
    if (filterValue?.hasOwnProperty("validity")) {
      if (Array.isArray(filterValue.validity)) {
        let valid_from = moment(filterValue.validity[0]).format("YYYY-MM-DD")
        let valid_till = moment(filterValue.validity[1]).format("YYYY-MM-DD")
        conditionTemp = {
          ...conditionTemp,
          _and: [{ valid_from: { _gte: valid_from } }, { valid_till: { _lte: valid_till } }]
        }
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}