import React from "react"
import { Dropdown } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { GoKebabVertical } from 'react-icons/go'

//Helpers
import ConvertRangesToQuery from "helpers/range_to_query_helper"
const isDiscountApplied = (order) => (Array.isArray(order?.discounts) && order?.discounts?.length > 0)
export const FormatData = (data) => {

  // return{order: data?.services}

  const Commissions = data?.services_commission?.map(is => {
    return {
      ...data?.services,
        "id": is.id,
        "uid":is.uid,
        "enum_business_type": is?.enum_services_business_type?.label,
        "enum_commission_type": is?.enum_services_commision_type?.label,
        "enum_state":is?.enum_state?.comment,
        "active":is?.active,
        "commission_type":is?.commission_type,
        "commission_value":is?.commission_value
        }
    })
    return {
      Commissions,
    }
  }




export const BuildCommissionSearchQuery = (value, queryCondition) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (isNaN(value)) {
      conditionTemp = {
        ...conditionTemp,
        _or: [
          {enum_services_commision_type:{ label:{ _ilike: `%${value}%`}    } },
          {enum_services_commision_type:{ value:{ _ilike: `%${value}%`}    } },
          {enum_state:{ comment:{ _ilike: `%${value}%`}    } },
          {enum_state:{ value:{ _ilike: `%${value}%`}    } },
          {enum_services_business_type:{ label:{ _ilike: `%${value}%`}    } },
          {enum_services_business_type:{ value:{ _ilike: `%${value}%`}    }},
          {enum_services_commision_type:{ value:{ _ilike: `%${value}%`}    } },
        ]
      }
    } else {
      conditionTemp = {
        ...conditionTemp,
        _or: [
           { commission_value: { _ilike: `%${value}%` } } ,
           { uid: { _eq: value } }
        ]
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
export const BuildOrderFilterQuery = (filterValue, queryCondition) => {
   if (Object.keys(filterValue).length) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("enum_services_business_type")) {
      if (filterValue.enum_services_business_type?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
            enum_services_business_type:{ label:{ _in: filterValue.enum_services_business_type}    },
  
        }
      }
    }
    if (filterValue?.hasOwnProperty("enum_state")) {
      if (filterValue.enum_state?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
           enum_state:{ comment:{ _in:filterValue.enum_state }    }
        }
      }
    }

 
 
    if (filterValue?.hasOwnProperty("created_by")) {
      if (filterValue.created_by?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          CreatedBy: { _in: filterValue.created_by }
        }
      }
    }
 
 
    // if (filterValue?.hasOwnProperty("category")) {
    //   if (filterValue.category?.length > 0) {
    //     conditionTemp = {
    //       ...conditionTemp,
    //       order_items: {
    //         product: {
    //           master_product: {
    //             category: { id: { _in: filterValue.category } }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // if (filterValue?.hasOwnProperty("subcategory")) {
    //   if (filterValue.subcategory?.length > 0) {
    //       conditionTemp = {
    //         ...conditionTemp,
    //         order_items: {
    //           product: {
    //             master_product: {
    //               subcategory: { id: { _in: filterValue.subcategory } }
    //             }
    //           }
    //         }
    //       }
    //   }
    // }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
 
    return conditionTemp
  }
}