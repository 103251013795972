import React from "react"
import { Dropdown } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { GoKebabVertical } from "react-icons/go"

//Helpers
import ConvertRangesToQuery from "helpers/range_to_query_helper"
const isDiscountApplied = (order) =>
  Array.isArray(order?.discounts) &&
  order?.discounts?.length > 0 &&
  order?.discounts[0]?.amount > 0
export const FormatData = (data) => {
  const order = data.order_customer_locations_view.map((elem) => {
    return {
      id: elem.id,
      orderId: elem.uid,
      customerId: elem?.customer?.id,
      customerUId: elem?.customer?.customer_id || "N/A",
      billingName: elem?.customer?.name || "N/A",
      // "id": elem.id,
      total: elem.total_amount,
      orderdate: moment(elem.created_at).format("DD/MM/YYYY"),
      ordertime: moment(elem.created_at).format("h:mm A"),
      phone: elem?.customer?.phone || "N/A",
      status: elem.order_status,
      store: elem?.store?.name,
      storeId: elem?.store?.id,
      no_of_items: elem.order_items?.length || 0,
      order_items: elem.order_items,
      commission: elem.calculated_commissions?.total,
      discounts_applicable: "N/A",
      delivery_cost: elem.delivery_charges,
      delivered_by:
        elem.order_type === "in_store_pickup" ? "In Store Pickup" : "DUNZO",
      location: elem.customer_address,
      pincode: elem.customer_postal_code,
      city: elem.customer_city,
      merchantUId: elem?.store?.vendor_company?.merchant_id || "N/A",
      merchantId: elem?.store?.vendor_company?.created_by,
      other_charges: 0,
      cost_of_items:
        elem.order_items?.length > 0
          ? elem?.order_items.reduce((a, b) => +a + +b.total_price_amount, 0)
          : 0,
      discount_applied: isDiscountApplied(elem) ? "Yes" : "No",
    }
  })
  return {
    order,
  }
}

const formatHighlighter = (text, searchKey, filterValue) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.status)) {
    searchWords = [...searchWords, ...filterValue?.status]
  }
  if (Array.isArray(filterValue?.city)) {
    searchWords = [...searchWords, ...filterValue?.city]
  }
  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  )
}
export const EcommerceOrderColumns = (
  searchKey,
  filterValue,
  history,
  pathName
) => [
  {
    dataField: "orderId",
    text: "Order ID",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.id) {
        return (
          <Link
            to={
              pathName.startsWith("/groceries")
                ? `/groceries/ecommerce-orders/details/${row.id}`
                : pathName.startsWith("/services")
                ? `/services/service-bookings/details/${row.id}`
                : pathName.startsWith("/vehicles")
                ? `/vehicles/vehicles-orders/details/${row.id}`
                : pathName.startsWith("/property")
                ? `/property/property-bookings/details/${row.id}`
                : pathName.startsWith("/experience")
                ? `/experience/experience-bookings/details/${row.id}`
                : ""
            }
          >
            {formatHighlighter(row.orderId, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.orderId, searchKey, filterValue)
      }
    },
  },
  {
    dataField: "orderdate",
    text: "Order Date",
    sort: true,
  },
  {
    dataField: "ordertime",
    text: "Order Time",
    sort: true,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "merchantUId",
    text: "Merchant ID",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.merchantId) {
        return (
          <Link
            to={
              pathName.startsWith("/groceries")
                ? `/groceries/ecommerce-merchants/details/${row.merchantId}`
                : pathName.startsWith("/services")
                ? `/services/service-merchants/details/${row.merchantId}`
                : pathName.startsWith("/vehicles")
                ? `/vehicles/vehicles-merchants/details/${row.merchantId}`
                : pathName.startsWith("/property")
                ? `/property/property-merchants/details/${row.merchantId}`
                : pathName.startsWith("/experience")
                ? `/experience/experience-merchants/details/${row.merchantId}`
                : ""
            }
          >
            {formatHighlighter(row.merchantUId, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.merchantUId, searchKey, filterValue)
      }
    },
  },
  {
    dataField: "store",
    text: "Store Name",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.storeId) {
        return (
          <Link
            to={
              pathName.startsWith("/groceries")
                ? `/groceries/ecommerce-shops/details/${row.storeId}`
                : pathName.startsWith("/services")
                ? `/services/service-shops/details/${row.storeId}`
                : pathName.startsWith("/property")
                ? `/property/property-shops/details/${row.storeId}`
                : pathName.startsWith("/experience")
                ? `/experience/experience-shops/details/${row.storeId}`
                : "#"
            }
          >
            {formatHighlighter(row.store, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.store, searchKey, filterValue)
      }
    },
  },
  {
    dataField: "customerUId",
    text: "Customer ID",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.customerId) {
        return (
          <Link
            to={
              pathName.startsWith("/groceries")
                ? `/groceries/ecommerce-customers/details/${row.customerId}`
                : pathName.startsWith("/services")
                ? `/services/service-customers/details/${row.customerId}`
                : pathName.startsWith("/property")
                ? `/property/property-customers/details/${row.customerId}`
                : pathName.startsWith("/experience")
                ? `/experience/experience-customers/details/${row.customerId}`
                : "#"
            }
          >
            {formatHighlighter(row.customerUId, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.customerUId, searchKey, filterValue)
      }
    },
  },
  {
    dataField: "billingName",
    text: "Customer",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.customerId) {
        return (
          <Link
            to={
              pathName.startsWith("/groceries")
                ? `/groceries/ecommerce-customers/details/${row.customerId}`
                : pathName.startsWith("/services")
                ? `/services/service-customers/details/${row.customerId}`
                : pathName.startsWith("/property")
                ? `/property/property-customers/details/${row.customerId}`
                : pathName.startsWith("/experience")
                ? `/experience/experience-customers/details/${row.customerId}`
                : "#"
            }
          >
            {formatHighlighter(row.billingName, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.billingName, searchKey, filterValue)
      }
    },
  },
  {
    text: "Customer Address",
    dataField: "location",
    sort: true,
    formatter: (col, row) => {
      return (
        <span
          style={{
            display: "block",
            width: 230,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {formatHighlighter(col, searchKey, filterValue)}
        </span>
      )
    },
  },
  {
    text: "City",
    dataField: "city",
    sort: true,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    text: "Postal Code",
    dataField: "pincode",
    sort: true,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    dataField: "phone",
    text: "Phone No.",
    sort: true,
    formatter: (cellContent, row) =>
      formatHighlighter(cellContent, searchKey, filterValue),
  },
  {
    text: "No. of Items",
    dataField: "no_of_items",
    sort: true,
  },
  {
    text: "Cost of Items",
    dataField: "cost_of_items",
    sort: true,
    formatter: (cellContent, row) => `₹${cellContent}`,
  },
  {
    text: "Delivery Cost",
    dataField: "delivery_cost",
    sort: true,
    formatter: (cellContent, row) => `₹${row.delivery_cost}`,
  },
  {
    dataField: "other_charges",
    text: "Other Charges",
    sort: true,
    formatter: (cellContent, row) => `₹${cellContent}`,
  },
  {
    dataField: "total",
    text: "Total Amount",
    sort: true,
    formatter: (cellContent, row) => `₹${row.total}`,
  },
  // {
  //   text: "Commission",
  //   dataField: "commission",
  //   sort: false,
  // },
  {
    text: "Discounts Applied",
    dataField: "discount_applied",
    sort: false,
  },
  // {
  //   text: "Delivered By",
  //   dataField: "delivered_by",
  //   sort: false,
  // },
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          <GoKebabVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              history.push(
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-orders/details/${row.id}`
                  : pathName.startsWith("/services")
                  ? `/services/service-bookings/details/${row.id}`
                  : pathName.startsWith("/vehicles")
                  ? `/vehicles/vehicles-orders/details/${row.id}`
                  : pathName.startsWith("/property")
                  ? `/property/property-bookings/details/${row.id}`
                  : pathName.startsWith("/experience")
                  ? `/experience/experience-bookings/details/${row.id}`
                  : ""
              )
            }}
          >
            View Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
]
export const BuildOrderSearchQuery = (value, queryCondition) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (isNaN(value)) {
      conditionTemp = {
        ...conditionTemp,
        _or: [
          { customer: { name: { _ilike: `%${value}%` } } },
          { customer: { phone: { _ilike: `%${value}%` } } },
          { customer: { customer_id: { _ilike: `%${value}%` } } },
          { store: { name: { _ilike: `%${value}%` } } },
          {
            store: {
              vendor_company: { merchant_id: { _ilike: `%${value}%` } },
            },
          },
          { order_status: { _eq: value } },
          { customer_address: { _ilike: `%${value}%` } },
          { customer_city: { _ilike: `%${value}%` } },
        ],
      }
    } else {
      conditionTemp = {
        ...conditionTemp,
        _or: [
          { customer: { phone: { _ilike: `%${value}%` } } },
          { customer_postal_code: { _like: `%${value}%` } },
          { uid: { _eq: value } },
        ],
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
export const BuildOrderFilterQuery = (filterValue, queryCondition) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("status")) {
      if (filterValue.status?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          order_status: { _in: filterValue.status },
        }
      }
    }
    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { created_at: { _gte: filterValue.created_at[0] } },
            { created_at: { _lte: filterValue.created_at[1] } },
          ],
        }
      }
    }
    if (filterValue?.hasOwnProperty("store")) {
      if (filterValue.store?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          store: { id: { _in: filterValue.store } },
        }
      }
    }
    if (filterValue?.hasOwnProperty("city")) {
      if (filterValue.city?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          customer_city: { _in: filterValue.city },
        }
      }
    }
    if (filterValue?.hasOwnProperty("total_amount")) {
      if (filterValue.total_amount?.length > 0) {
        let amountFilter = ConvertRangesToQuery(
          filterValue.total_amount,
          "total_amount"
        )
        conditionTemp = {
          ...conditionTemp,
          _or: amountFilter,
        }
      }
    }
    if (filterValue?.hasOwnProperty("delivery_charges")) {
      if (filterValue.delivery_charges?.length > 0) {
        let deliveryChargesFilter = ConvertRangesToQuery(
          filterValue.delivery_charges,
          "delivery_charges"
        )
        conditionTemp = {
          ...conditionTemp,
          _or: deliveryChargesFilter,
        }
      }
    }
    if (filterValue?.hasOwnProperty("order_items")) {
      if (filterValue.order_items?.length > 0) {
        let orderItemsFilter = ConvertRangesToQuery(
          filterValue.order_items,
          "total_order_items"
        )
        conditionTemp = {
          ...conditionTemp,
          _or: orderItemsFilter,
        }
      }
    }
    if (filterValue?.hasOwnProperty("category")) {
      if (filterValue.category?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          order_items: {
            product: {
              master_product: {
                category: { id: { _in: filterValue.category } },
              },
            },
          },
        }
      }
    }
    if (filterValue?.hasOwnProperty("subcategory")) {
      if (filterValue.subcategory?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          order_items: {
            product: {
              master_product: {
                subcategory: { id: { _in: filterValue.subcategory } },
              },
            },
          },
        }
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
