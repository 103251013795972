import { gql } from "@apollo/client"

//======= Orders Queries Start ===============//
export const Latest_Orders = gql`
  query MyQuery {
    orders(limit: 10) {
      id
      customer {
        name
        phone
      }
      store {
        name
      }
      total_amount
      order_status
      created_at
      uid
    }
  }
`
export const Order_reports = gql`
  query Order_reports(
    $revenueConditions: orders_bool_exp
    $ordersConditions: orders_bool_exp
    $storesConditions: stores_bool_exp
    $productsConditions: products_bool_exp
    $servicesConditions: sub_services_bool_exp
    $rentalConditions: rental_vehicles_bool_exp
    $payoutsConditions: payouts_commission_view_bool_exp
  ) {
    payments_aggregate: orders_aggregate(where: $revenueConditions) {
      aggregate {
        count
        sum {
          gross_amount
        }
        avg {
          gross_amount
        }
      }
    }
    orders_aggregate(where: $ordersConditions) {
      aggregate {
        count
      }
    }
    products_aggregate(where: $productsConditions) {
      aggregate {
        count
      }
    }
    sub_services_aggregate(where: $servicesConditions) {
      aggregate {
        count
      }
    }
    rental_vehicles_aggregate(where: $rentalConditions) {
      aggregate {
        count
      }
    }
    stores_aggregate(where: $storesConditions) {
      aggregate {
        count
      }
    }
    payouts_commission_view_aggregate(where: $payoutsConditions) {
      aggregate {
        sum {
          total_commission
        }
      }
    }
  }
`
export const Order_List = gql`
  query MyQuery(
    $limit: Int
    $offset: Int
    $where: order_customer_locations_view_bool_exp
  ) {
    order_customer_locations_view(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc }
    ) {
      id
      order_items {
        id
        total_price_amount
      }
      calculated_commissions {
        total
      }
      delivery_charges
      discounts
      eefind_discounted_amount
      vendor_discounted_amount
      customer {
        name
        phone
        id
        customer_id
      }
      customer_city
      customer_state
      customer_postal_code
      customer_address
      order_type
      time_slot
      store {
        name
        id
        vendor_company {
          merchant_id
          created_by
        }
      }
      total_amount
      order_status
      created_at
      uid
    }
    order_customer_locations_view_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const GetTotalOrderEarnings = gql`
  query GetTotalOrderEarnings(
    $limit: Int
    $offset: Int
    $where: orders_bool_exp
  ) {
    orders_aggregate(where: $where) {
      aggregate {
        count
        sum {
          gross_amount
        }
      }
    }
  }
`
export const getOrderDetail_byId = gql`
  query getOrderDetail_byId($id: uuid) {
    orders(where: { id: { _eq: $id } }) {
      order_items {
        id
        item_name
        item_price
        count
        total_price_amount
        vendor_discounted_amount
        tax_amount
        price_includes_tax
        eefind_discounted_amount
        product_id
        product {
          sku
          image_url
          non_mrp
        }
      }
      service_items {
        id
        service_name
        service_price
        count
        total_price_amount
        vendor_discounted_amount
        tax_amount
        price_includes_tax
        eefind_discounted_amount
      }
      customer {
        name
        phone
        email
        customer_id
        gender
        id
        photo_url
      }
      parcel_charges
      total_amount
      cancelled_amount
      tax_amount
      id
      origin_geopoint
      calculated_commissions {
        total
      }
      delivery_charges
      discounts
      eefind_discounted_amount
      vendor_discounted_amount
      customer_location
      store {
        name
        id
        photo_url
        store_id
      }
      order_status
      created_at
      confirmed_at
      cancelled_at
      rejected_at
      completed_at
      uid
      order_notes
      order_type
      time_slot
      payments {
        id
        amount
        currency
        status
        externaltransactionid
        payment_type
        payment_gateway
        bankcode
        refunded
        refunded_amount
      }
      delivery_request {
        id
        status
        update_history
        external_reference_id
      }
      rental_order_items {
        id
        active
        addons
        driver {
          driver_name
          phone
          email
          image_url
        }
        driver_customer {
          name
          phone
          email
          photo_url
        }
        driver_type
        drop_off_address
        drop_off_data
        drop_off_kms
        drop_off_map_point
        drop_off_photos
        ended_time
        extension_hours
        extension_status
        order_status
        pickup_data
        pickup_kms
        pickup_photos
        rental_type
        started_time
        trip_from
        trip_to
        vehicle {
          allowance_data
          deposite_amount
          photo_urls
          price_per_hour
          price_per_hour_offer
          rental_type
          vehicle_brand {
            brand_name
          }
          vehicle_name
          vehicle_no
        }
      }
    }
  }
`

/*  query getOrderDetail_byId($id: uuid) {
    orders(where: { id: { _eq: $id } }) {
      order_items {
        id
        item_name
        item_price
        count
        total_price_amount
        vendor_discounted_amount
        tax_amount
        price_includes_tax
        eefind_discounted_amount
        product_id
        product {
          sku
          image_url
          non_mrp
        }
      }
      service_items {
        id
        service_name
        service_price
        count
        total_price_amount
        vendor_discounted_amount
        tax_amount
        price_includes_tax
        eefind_discounted_amount
      }
      customer {
        name
        phone
        email
        customer_id
        gender
        id
      }
      parcel_charges
      total_amount
      cancelled_amount
      tax_amount
      id
      origin_geopoint
      calculated_commissions {
        total
      }
      delivery_charges
      discounts
      eefind_discounted_amount
      vendor_discounted_amount
      customer_location
      store {
        name
        id
        photo_url
        store_id
      }
      order_status
      created_at
      confirmed_at
      cancelled_at
      rejected_at
      completed_at
      uid
      order_notes
      order_type
      time_slot
      payments {
        id
        amount
        currency
        status
        externaltransactionid
        payment_type
        payment_gateway
        bankcode
        refunded
        refunded_amount
      }
      delivery_request {
        id
        status
        update_history
        external_reference_id
      }
    }
  }*/

export const GetOrderItemsList = gql`
  query GetOrderItemsList(
    $limit: Int
    $offset: Int
    $where: order_items_bool_exp
    $order_by: [order_items_order_by!]
  ) {
    order_items(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      id
      item_name
      item_price
      count
      total_price_amount
      vendor_discounted_amount
      tax_amount
      price_includes_tax
      eefind_discounted_amount
      product_id
      product {
        sku
        image_url
      }
      order {
        gross_amount
        id
        uid
      }
    }
    order_items_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const GetServiceItemsList = gql`
  query GetServiceItemsList(
    $limit: Int
    $offset: Int
    $where: service_items_bool_exp
    $order_by: [service_items_order_by!]
  ) {
    service_items(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      id
      service_name
      service_price
      count
      total_price_amount
      vendor_discounted_amount
      tax_amount
      price_includes_tax
      eefind_discounted_amount
      service_id
      order {
        gross_amount
        id
        uid
      }
    }
    service_items_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
//======= Orders Queries End ===============//

//======= Services Queries Start ===============//

export const Services_List = gql`
  query MyQuery($limit: Int, $offset: Int, $where: services_bool_exp) {
    services(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc }
    ) {
      active
      created_at
      user {
        name
        phone
        email
      }
      UpdatedBy {
        name
      }
      distance
      id
      is_deleted
      landmark
      lat
      lng
      map_point
      master_service_id
      service {
        active
        service_name
      }
      service_category {
        active
        category
        master_services {
          active
          service_name
        }
        service_type
      }
    }
    services_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GetTotalServicesEarnings = gql`
  query GetTotalServiceEarnings(
    $limit: Int
    $offset: Int
    $where: orders_bool_exp
  ) {
    services_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const GetServiceDetailsById = gql`
  query GetServiceDetailsById($id: uuid!) {
    object: services_by_pk(id: $id) {
      created_at
      created_by
      updated_by
      UpdatedBy {
        name
      }
      distance
      id
      active
      is_deleted
      landmark
      lat
      lng
      map_point
      service_category {
        active
        business_kyc
        category
        created_at
        created_by
        id
        individual_kyc
        updated_at
        updated_by
        user_types
      }
      service_items {
        canceled_by
        canceled_reason
        cancelled_at
        completed_at
        confirmed_at
        count
        created_at
        discounted_amount
        discounts
        id
        notes
        order_id
        order_status
        price_includes_tax
        rejected_at
        rejected_by
        rejected_reason
        service_id
        service_name
        service_price

        tax_amount
        taxes
        total_price_amount
        uid
        updated_at
      }
      service_items_aggregate {
        aggregate {
          count
        }
      }
      sub_services_map: sub_services {
        active
        centre_price
        created_at
        created_by
        home_price
        id
        is_deleted
        online_price
        service_id
        sub_service_id
        sub_service {
          active
          created_at
          created_by
          name
          id
          updated_at
          updated_by
          service_category {
            service_type
            updated_by
            updated_at
            user_types
            service_modes
            category
            created_at
            individual_kyc
            id
            created_by
            business_kyc
            active
          }
        }
        updated_at
        updated_by
      }
      sub_services_aggregate {
        aggregate {
          count
        }
      }
      promotion_services {
        promotion_id
        promotion {
          active
          apply_on_delivery_charge
          apply_on_parcel_charge
          before_tax
          brands

          cashback
          categories
          code
          created_at
          created_by
          customers
          description
          id
          label
          max_item_count
          max_order_amount
          max_order_count
          min_item_count
          min_order_amount
          min_order_count
          photo_url
          products
          promoted_by
          services
          service_categories
          store_id
          subcategories
          subservices
          updated_at
          updated_by
          usable_count
          used_count
          valid_from
          valid_till
          value
          value_type
        }
      }
      updated_at
    }
  }
`
export const LandmarkStoreFilter = gql`
  query ProductStoreFilter(
    $limit: Int
    $offset: Int
    $where: services_bool_exp
  ) {
    landmark: services(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: landmark
    ) {
      id
      landmark
    }
  }
`
export const ServiceFilter = gql`
  query ServiceFilter($limit: Int, $offset: Int, $where: services_bool_exp) {
    service: services(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: landmark
    ) {
      id
      service {
        service_name
      }
    }
  }
`
export const ServiceCategoryFilter = gql`
  query ServiceCategoryFilter(
    $limit: Int
    $offset: Int
    $where: services_bool_exp
  ) {
    category: services(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: service_category_id
    ) {
      id
      service_category {
        category
      }
    }
  }
`

export const ServiceSubServicesList = gql`
  query MasterServices(
    $limit: Int
    $offset: Int
    $where: master_services_bool_exp
  ) {
    master_services(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc }
    ) {
      id
      active
      created_at
      created_by
      image_url
      service_name
      specifics
      updated_at
      updated_by
      service_category_id
      service_category {
        category
        created_by
        created_at
        active
        id
        service_type
        updated_at
        updated_by
      }
    }
  }
`
export const ServiceCategoryList = gql`
  query MasterServices {
    service_categories {
      active
      created_at
      created_by
      id
      category
      service_type
      updated_at
      updated_by
    }
  }
`

//=========  Services Queries  ==============//

//=========  Commission Commission ===========//
export const GroceriesCommissionList = gql`
  query services_commission(
    $limit: Int
    $offset: Int
    $where: services_commission_bool_exp
    $order_by: [services_commission_order_by!]
  ) {
    services_commission(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      active
      commission_type
      commission_value
      enum_services_business_type {
        label
        value
      }
      enum_services_commision_type {
        label
        value
      }
      enum_state {
        comment
        value
      }
      service_type
      id
      state
      uid
    }
  }
`

export const StateCommisionFilter = gql`
  query groceries_commission_filter(
    $limit: Int
    $offset: Int
    $where: services_commission_bool_exp
  ) {
    state: services_commission(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: state
    ) {
      id
      state
      enum_state {
        value
        comment
      }
    }
  }
`

export const BusinessCommissionFilter = gql`
  query service_type_commission_filter(
    $limit: Int
    $offset: Int
    $where: services_commission_bool_exp
  ) {
    enum_services_business_type: services_commission(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: service_type
    ) {
      id
      state
      enum_services_business_type {
        value
        label
      }
    }
  }
`

export const CommissionDetailsByID = gql`
  query commisions_by_pk($id: uuid!) {
    services_commission_by_pk(id: $id) {
      active
      commission_type
      commission_value
      service_type
      id
      state
      uid
      enum_state {
        comment
        value
      }
      enum_services_commision_type {
        label
        value
      }
      enum_services_business_type {
        label
        value
      }
    }
    enum_states {
      comment
      value
    }
    enum_services_commision_type {
      label
      value
    }
    enum_services_business_type {
      label
      value
    }
  }
`

export const commissionDetails = gql`
  query commissionDetails {
    enum_states {
      value
      comment
    }

    enum_services_commision_type {
      label
      value
    }

    enum_services_business_type {
      label
      value
    }
  }
`
//======= Customer Queries Start ===============//
export const CustomrList = gql`
  query MyQuery(
    $limit: Int
    $offset: Int
    $where: customers_bool_exp
    $order_by: [customers_order_by!]
  ) {
    customers(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      id
      customer_id
      name
      email
      phone
      photo_url
      gender
      created_at
      ordersByCustomerId_aggregate {
        aggregate {
          count
          sum {
            total_amount
          }
        }
      }
      role
      active
      user_locations(distinct_on: city) {
        city
      }
    }
    customers_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const CustomerDetails = gql`
  query CustomerDetails($id: uuid) {
    customers(where: { id: { _eq: $id } }) {
      id
      customer_id
      name
      email
      phone
      photo_url
      gender
      created_at
      is_phone_verified
      is_email_verified
      ordersByCustomerId_aggregate {
        aggregate {
          count
          sum {
            total_amount
          }
          avg {
            total_amount
          }
        }
      }
      ordersByCustomerId(order_by: { created_at: desc }, limit: 1) {
        id
        created_at
        uid
      }
      role
      active
    }
  }
`
export const getCustomerAddresses = gql`
  query getCustomerAddresses(
    $limit: Int
    $offset: Int
    $where: customer_addresses_bool_exp
  ) {
    customer_addresses(limit: $limit, offset: $offset, where: $where) {
      id
      address_line_1
      state
      postcode
      country
      locality
      is_default
      name
      category
      city
      id
    }
    customer_addresses_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const getCustomerAddressById = gql`
  query getCustomerAddressById($id: uuid) {
    customer_addresses(where: { id: { _eq: $id } }) {
      id
      address_line_1
      state
      postcode
      country
      locality
      is_default
      name
      category
      city
      id
      map_point
    }
  }
`
export const getCustomerPayments = gql`
  query getCustomerPayments(
    $limit: Int
    $offset: Int
    $where: payments_bool_exp
  ) {
    payments(limit: $limit, offset: $offset, where: $where) {
      id
      amount
      status
      externaltransactionid
      payment_type
      payment_gateway
      pg_commission
      bankcode
      refunded
      refunded_amount
      created_at
      order {
        order_status
        cancelled_amount
        id
      }
    }
    payments_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const CustomerCityFilter = gql`
  query CustomerCityFilter(
    $limit: Int
    $offset: Int
    $where: customer_addresses_bool_exp
  ) {
    customer_addresses(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: city
    ) {
      city
    }
  }
`
//======= Customer Queries End ===============//

//======= Product Queries Start ===============//
export const ProductList = gql`
  query ProductList($limit: Int, $offset: Int, $where: products_bool_exp) {
    products(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc }
    ) {
      non_mrp
      nonmrp_category {
        name
        id
      }
      nonmrp_subcategory {
        name
        id
      }
      nonmrp_product_name
      available_quantity
      created_at
      id
      price
      price_includes_tax
      sku
      store {
        name
        id
        store_type
      }
      weight
      weight_unit
      master_product {
        name
        photo_urls
        product_brand {
          name
          id
        }
        category {
          name
          id
        }
        subcategory {
          name
          id
        }
      }
      image_url
      out_of_stock_alert
      material_supplier {
        name
        contact_no
      }
      approve
      country_origin
      store_product_tax {
        tax {
          id
          name
          value_type
          value
        }
      }
    }
    products_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const ProductById = gql`
  query ProductById($id: uuid) {
    products(where: { id: { _eq: $id } }) {
      non_mrp
      nonmrp_category {
        name
        id
      }
      nonmrp_subcategory {
        name
        id
      }
      nonmrp_product_name
      available_quantity
      created_at
      id
      price
      price_includes_tax
      sku
      store {
        name
        id
        store_type
      }
      weight
      weight_unit
      master_product {
        id
        name
        photo_urls
        product_brand {
          name
          id
        }
        category {
          name
          id
        }
        subcategory {
          name
          id
        }
      }
      image_url
      out_of_stock_alert
      material_supplier {
        name
        contact_no
      }
      approve
      country_origin
      store_product_tax {
        tax {
          id
          name
          value_type
          value
        }
      }
    }
  }
`
export const ProductCategoryFilter = gql`
  query ProductCategoryFilter(
    $limit: Int
    $offset: Int
    $where: categories_bool_exp
  ) {
    categories(limit: $limit, offset: $offset, where: $where) {
      id
      name
      logo
    }
  }
`
export const ProductSubCategoryFilter = gql`
  query ProductSubCategoryFilter(
    $limit: Int
    $offset: Int
    $where: subcategories_bool_exp
  ) {
    subcategories(limit: $limit, offset: $offset, where: $where) {
      id
      name
    }
  }
`
export const ProductBrandFilter = gql`
  query ProductBrandFilter(
    $limit: Int
    $offset: Int
    $where: product_brand_bool_exp
  ) {
    product_brand(limit: $limit, offset: $offset, where: $where) {
      id
      name
    }
  }
`
export const ProductWeightUnitFilter = gql`
  query ProductWeightUnitFilter(
    $limit: Int
    $offset: Int
    $where: enum_weight_units_bool_exp
  ) {
    enum_weight_units(limit: $limit, offset: $offset, where: $where) {
      name
      comments
    }
  }
`
export const ProductCollectionList = gql`
  query ProductCollectionList(
    $limit: Int
    $offset: Int
    $where: collections_bool_exp
  ) {
    collections(limit: $limit, offset: $offset, where: $where) {
      name
      id
      photo_url
    }
  }
`
export const ProductStoreFilter = gql`
  query ProductStoreFilter($limit: Int, $offset: Int, $where: stores_bool_exp) {
    stores(limit: $limit, offset: $offset, where: $where) {
      id
      name
    }
  }
`
//======= Product Queries End ===============//

//======= Shop Queries Start ===============//
export const StoreList = gql`
  query StoreList(
    $limit: Int
    $offset: Int
    $where: stores_filter_view_bool_exp
  ) {
    stores: stores_filter_view(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
      where: $where
    ) {
      vendor_company {
        verification_status
        merchant_id
        created_by
      }
      products_aggregate {
        aggregate {
          count
        }
      }
      orders_aggregate {
        aggregate {
          count
          sum {
            total_amount
          }
        }
      }
      state
      created_at
      id
      name
      phone
      photo_url
      city
      active
      email
      module
      business_gstno
      business_pan
      company_id
      store_type
      lat
      lng
      store_id
      categories
      created_user {
        id
      }
      status
    }
    stores_aggregate: stores_filter_view_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const ShopDetails = gql`
  query ShopDetails($id: uuid) {
    stores(where: { id: { _eq: $id } }) {
      vendor_company {
        merchant_id
        created_by
        website_url
      }
      orders_aggregate {
        aggregate {
          count
          sum {
            total_amount
          }
          avg {
            total_amount
          }
        }
      }
      orders(order_by: { created_at: desc }, limit: 1) {
        id
        created_at
        uid
      }
      address_line_1
      city
      state
      postcode
      landmark
      business_tax_registrationno
      state
      created_at
      id
      name
      phone
      photo_url
      city
      active
      email
      business_gstno
      business_pan
      company_id
      store_type
      gst_available
      lat
      lng
      store_id
      categories
      created_user {
        id
        reference_code
      }
      nogst_declaration
      proof_of_business
      gst_photo
      status
    }
  }
`
export const ShopCityFilter = gql`
  query ShopCityFilter($limit: Int, $offset: Int, $where: stores_bool_exp) {
    stores(limit: $limit, offset: $offset, where: $where, distinct_on: city) {
      id
      city
    }
  }
`
export const ShopStateFilter = gql`
  query ShopStateFilter($limit: Int, $offset: Int, $where: stores_bool_exp) {
    stores(limit: $limit, offset: $offset, where: $where, distinct_on: state) {
      id
      state
    }
  }
`
//======= Shop Queries End ===============//

//======= Merchant Queries Start ===============//
export const MerchantList = gql`
  query MerchantList(
    $limit: Int
    $offset: Int
    $where: users_filter_view_bool_exp
  ) {
    users_filter_view(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
      where: $where
    ) {
      created_at
      id
      role
      active
      aadhar_image
      reference_code
      vendor_company {
        merchant_id
        name
        contact_no
        email
        city
        verification_status
      }
      stores_aggregate {
        aggregate {
          count
        }
      }
      stores(order_by: { created_at: asc }) {
        gst_photo
        pan_photo
        gst_available
        business_type
      }
      products_aggregate {
        aggregate {
          count
        }
      }
      payouts(
        where: { status: { _eq: "PENDING" }, total_amount: { _gt: "0" } }
      ) {
        id
        total_amount
        start_date
        end_date
      }
    }
    users_filter_view_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const MerchantCityFilter = gql`
  query MerchantCityFilter(
    $limit: Int
    $offset: Int
    $where: vendor_company_bool_exp
  ) {
    vendor_company(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: city
    ) {
      city
    }
  }
`
export const MerchantProductList = gql`
  query MyQuery($limit: Int, $offset: Int, $where: products_bool_exp) {
    products(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
      where: $where
    ) {
      non_mrp
      available_quantity
      created_at
      id
      price
      price_includes_tax
      sku
      store {
        name
        id
      }
      weight
      weight_unit
      master_product {
        name
        photo_urls
        product_brand {
          name
          id
        }
        category {
          name
          id
        }
        subcategory {
          name
          id
        }
      }
      image_url
    }
    products_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const MerchantBankList = gql`
  query MyQuery($where: bank_accounts_bool_exp) {
    bank_accounts(order_by: { created_at: desc_nulls_last }, where: $where) {
      account_no
      account_type
      active
      bank_name
      beneficiary_address
      beneficiary_name
      branch_name
      id
      ifsc
      created_at
      store_bank_accounts {
        store {
          name
          id
        }
      }
    }
  }
`
export const MerchantDetails = gql`
  query MyQuery($where: users_bool_exp) {
    users(where: $where) {
      aadhar_image
      aadhar_no
      active
      created_at
      email
      id
      name
      phone
      photo_url
      reference_code
      notification_token
      vendor_company {
        address_line1
        address_line2
        city
        contact_no
        email
        id
        merchant_id
        logo
        locality
        name
        postcode
        verification_status
        website_url
        physical_verification
        call_verification
      }
      stores {
        id
        name
        business_pan
        business_gstno
        business_tax_registrationno
        business_tinno
        gst_photo
        pan_photo
        nogst_declaration
        proof_of_business
        gst_available
        categories
        address_line_1
      }
    }
  }
`
export const MerchantStats = gql`
  query MyQuery($where: orders_bool_exp) {
    orders_aggregate(where: $where) {
      aggregate {
        avg {
          total_amount
        }
        count
        sum {
          total_amount
        }
      }
    }
    orders(order_by: { created_at: desc }, where: $where, limit: 1) {
      id
      created_at
    }
  }
`
export const MerchantStoreList = gql`
  query MyQuery($limit: Int, $offset: Int, $where: stores_bool_exp) {
    stores(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
      where: $where
    ) {
      id
      name
      photo_url
      store_type
      city
      country
      address_line_1
      address_line_2
      gst_available
      products_aggregate {
        aggregate {
          count
        }
      }
      created_at
      website_url
      orders_aggregate {
        aggregate {
          count
        }
      }
      promotion_stores_aggregate {
        aggregate {
          count
        }
      }
    }
    stores_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const MerchantOrderList = gql`
  query MyQuery($limit: Int, $offset: Int, $where: orders_bool_exp) {
    orders(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
      where: $where
    ) {
      uid
      id
      created_at
      completed_at
      confirmed_at
      order_status
      order_items {
        id
      }
      store {
        id
        name
      }
      calculated_commissions {
        total
      }
      delivery_charges
      discounts
      eefind_discounted_amount
      total_amount
      vendor_discounted_amount
      payments {
        id
        status
        externaltransactionid
      }
    }
    orders_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const MerchantPromotionList = gql`
  query MyQuery($limit: Int, $offset: Int, $where: promotion_stores_bool_exp) {
    promotion_stores(limit: $limit, offset: $offset, where: $where) {
      promotion {
        active
        code
        created_at
        description
        label
        id
        max_item_count
        max_order_amount
        max_order_count
        min_item_count
        min_order_amount
        min_order_count
        photo_url
        promoted_by
        usable_count
        used_count
        valid_from
        valid_till
        value
        value_type
        available_hours
        store {
          id
          name
        }
      }
    }
    promotion_stores_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const MerchantNotesList = gql`
  query MerchantNotesList(
    $limit: Int
    $offset: Int
    $where: merchant_notes_bool_exp
  ) {
    merchant_notes(limit: $limit, offset: $offset, where: $where) {
      id
      note
      title
      created_at
      updated_at
    }
    merchant_notes_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
//======= Merchant Queries End ===============//

//====== Brands Queries Start ===============//
export const BrandList = gql`
  query BrandList($limit: Int, $offset: Int, $where: product_brand_bool_exp) {
    product_brand(limit: $limit, offset: $offset, where: $where) {
      id
      name
      active
      photo_url
      created_at
      master_products_aggregate {
        aggregate {
          count
        }
      }
    }
    product_brand_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
//====== Brands Queries End =================//

//====== Categories Queries Start ===============//
export const CategoryList = gql`
  query CategoryList($limit: Int, $offset: Int, $where: categories_bool_exp) {
    categories(limit: $limit, offset: $offset, where: $where) {
      id
      name
      active
      logo
      category_group_id
      created_at
      master_products_aggregate {
        aggregate {
          count
        }
      }
      commission {
        id
        commission_type
        commission_value
      }
    }
    categories_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const CategoryGroupList = gql`
  query CategoryGroupList(
    $limit: Int
    $offset: Int
    $where: categories_group_bool_exp
  ) {
    categories_group(limit: $limit, offset: $offset, where: $where) {
      id
      name
      desc
      logo
      created_at
    }
    categories_group_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const ServiceTypeList = gql`
  query ServiceTypeList {
    enum_service_type {
      name
      comments
    }
  }
`

//====== Categories Queries End =================//

//====== SubCategories Queries Start ===============//
export const SubCategoryList = gql`
  query SubCategoryList(
    $limit: Int
    $offset: Int
    $where: subcategories_bool_exp
  ) {
    subcategories(limit: $limit, offset: $offset, where: $where) {
      id
      name
      active
      created_at
      category {
        name
        id
      }
      master_products_aggregate {
        aggregate {
          count
        }
      }
    }
    subcategories_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
//====== SubCategories Queries End =================//
export const ServiceCategoryGroupList = gql`
  query ServiceCategoryGroupList(
    $limit: Int
    $offset: Int
    $where: service_categories_bool_exp
  ) {
    service_categories(limit: $limit, offset: $offset, where: $where) {
      id
      image_url
      category
      active
      created_at
      enum_service_type {
        comments
        name
      }
    }
    service_categories_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

//====== Master Catalogue Queries Start ==========//
export const MasterCatalogueList = gql`
  query MasterCatalogueList(
    $limit: Int
    $offset: Int
    $where: master_products_bool_exp
  ) {
    master_products(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc }
    ) {
      id
      name
      photo_urls
      product_brand {
        name
        id
      }
      category {
        name
        id
      }
      subcategory {
        name
        id
      }
      weight
      weight_unit
      non_mrp
      created_at
      isactive
    }
    master_products_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const MasterCatalogueById = gql`
  query MasterCatalogueById($id: uuid) {
    master_products(where: { id: { _eq: $id } }) {
      id
      name
      photo_urls
      product_brand {
        name
        id
      }
      category {
        name
        id
      }
      subcategory {
        name
        id
      }
      weight
      weight_unit
      non_mrp
      created_at
      isactive
    }
  }
`
//====== Master Catalogue Queries End ===========//

//====== Reviews Queries Start =================//
export const ReviewsList = gql`
  query ReviewsList($limit: Int, $offset: Int, $where: store_reviews_bool_exp) {
    store_reviews(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
      where: $where
    ) {
      id
      review
      rating
      created_at
      order {
        id
        uid
      }
    }
    store_reviews_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
//====== Reviews Queries End ==================//

//====== Taxes Start =================//
export const TaxesList = gql`
  query TaxesList($limit: Int, $offset: Int, $where: taxes_bool_exp) {
    taxes(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
      where: $where
    ) {
      id
      name
      label
      value_type
      value
    }
    taxes_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const GetStoreProductTax = gql`
  query GetStoreProductTax($where: store_product_taxes_bool_exp) {
    store_product_taxes(where: $where) {
      tax_id
      nonmrp_product_id
      tax {
        name
        value_type
        value
      }
    }
  }
`
//====== Taxes End ==================//

//=========Requests Queries Start=========//
export const ProductRequestsList = gql`
  query ProductRequestsList(
    $limit: Int
    $offset: Int
    $where: product_requests_bool_exp
  ) {
    product_requests(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc }
    ) {
      available_quantity
      created_at
      id
      name
      price
      store {
        name
        id
        store_type
      }
      weight
      weight_unit
      photo_urls
      out_of_stock_alert
      supplier_name
      supplier_contact
      status
      country_origin
      category {
        id
        name
      }
      subcategory {
        id
        name
      }
      brand
      creator {
        id
        vendor_company {
          id
          merchant_id
        }
      }
    }
    product_requests_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const ProductRequestById = gql`
  query ProductRequestById($id: uuid) {
    product_requests(where: { id: { _eq: $id } }) {
      available_quantity
      created_at
      id
      name
      price
      store {
        name
        id
        store_type
      }
      weight
      weight_unit
      photo_urls
      out_of_stock_alert
      supplier_name
      supplier_contact
      status
      country_origin
      category {
        id
        name
      }
      subcategory {
        id
        name
      }
      brand
      creator {
        id
        vendor_company {
          id
          merchant_id
        }
      }
      tax {
        name
        id
      }
    }
  }
`
export const GeneralRequestsList = gql`
  query GeneralRequestsList(
    $limit: Int
    $offset: Int
    $where: general_requests_bool_exp
  ) {
    general_requests(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc }
    ) {
      id
      description
      status
      created_at
      creator {
        id
        vendor_company {
          id
          merchant_id
        }
      }
    }
    general_requests_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const MaterialSupplierList = gql`
  query MaterialSupplierList(
    $limit: Int
    $offset: Int
    $where: material_suppliers_bool_exp
  ) {
    material_suppliers(limit: $limit, offset: $offset, where: $where) {
      id
      name
      contact_no
      created_at
    }
  }
`
//========Requests Queries End================//

//==========Payouts Queries Start==============//
export const GetCurrentPayoutCycle = gql`
  query GetCurrentPayoutCycle($merchantId: uuid) {
    payouts(
      where: {
        status: { _eq: PENDING }
        total_amount: { _gt: "0" }
        merchant_id: { _eq: $merchantId }
      }
    ) {
      total_amount
      created_at
      id
      start_date
      end_date
      store_id
      orders_aggregate {
        aggregate {
          count
          sum {
            total_amount
            delivery_charges
            gross_amount
            cancelled_amount
          }
        }
      }
      commissions_aggregate {
        aggregate {
          sum {
            total_commission
          }
        }
      }
      store {
        id
        name
      }
    }
  }
`
export const GetPayoutList = gql`
  query GetPayoutList($limit: Int, $offset: Int, $where: payouts_bool_exp) {
    payouts(limit: $limit, offset: $offset, where: $where) {
      id
      transfer_id
      total_amount
      status
      reference_id
      start_date
      end_date
      remarks
      merchant_id
      created_at
      updated_at
      store {
        id
        name
      }
      orders_aggregate {
        aggregate {
          count
          sum {
            total_amount
            delivery_charges
            gross_amount
            cancelled_amount
          }
        }
      }
      commissions_aggregate {
        aggregate {
          sum {
            total_commission
          }
        }
      }
    }
    payouts_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const GetCancelledOrdersForPayoutCycle = gql`
  query GetCancelledOrdersForPayoutCycle(
    $storeId: uuid
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    orders_aggregate(
      where: {
        store_id: { _eq: $storeId }
        order_status: { _in: [cancelled, rejected] }
        _or: [
          {
            _and: [
              { rejected_at: { _gte: $startDate } }
              { rejected_at: { _lte: $endDate } }
            ]
          }
          {
            _and: [
              { cancelled_at: { _gte: $startDate } }
              { cancelled_at: { _lte: $endDate } }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
        sum {
          cancelled_amount
          delivery_charges
          gross_amount
          total_amount
        }
      }
    }
  }
`
//=============Payouts Queries End===============//

//=============Commission Queries Start==========//
export const GetCommission = gql`
  query GetCommission($where: commissions_bool_exp) {
    commissions(where: $where) {
      category_id
      brand_id
      commission_type
      commission_value
      id
      product_id
      subcategory_id
      created_at
    }
  }
`
//=============Commission Queries End==========//

//=============Promotion Queries Start=========//
export const PromotionList = gql`
  query PromotionList($limit: Int, $offset: Int, $where: promotions_bool_exp) {
    promotions(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc_nulls_last }
    ) {
      active
      code
      created_at
      description
      label
      id
      max_item_count
      max_order_amount
      max_order_count
      min_item_count
      min_order_amount
      min_order_count
      photo_url
      promoted_by
      usable_count
      used_count
      valid_from
      valid_till
      value
      value_type
      available_hours
      store {
        id
        name
      }
      creator {
        id
        name
        vendor_company {
          id
          merchant_id
        }
      }
    }
    promotions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const GetPromotionDetails = gql`
  query GetPromotionDetails($id: Int) {
    promotions(where: { id: { _eq: $id } }) {
      active
      code
      created_at
      description
      label
      id
      max_item_count
      max_order_amount
      max_order_count
      min_item_count
      min_order_amount
      min_order_count
      photo_url
      promoted_by
      usable_count
      used_count
      valid_from
      valid_till
      value
      value_type
      available_hours
      apply_on_delivery_charge
      store {
        id
        name
      }
      creator {
        id
        name
        vendor_company {
          id
          merchant_id
        }
      }
      categories
      subcategories
      services
      subservices
      brands
      products
      customers
    }
  }
`
//=============Promotion Queries End===========//

//=============System User Queries Start=========//
export const SystemUserList = gql`
  query SystemUserList($limit: Int, $offset: Int, $where: admins_bool_exp) {
    admins(
      limit: $limit
      offset: $offset
      where: $where
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      name
      email
      phone
      photo_url
      active
      created_at
      role
      additional_role
    }
    admins_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
//=============Vehicle Brands===========//
export const VehicleBrandList = gql`
  query VehicleBrandList(
    $limit: Int
    $offset: Int
    $where: rental_vehicle_brands_bool_exp
  ) {
    rental_vehicle_brands(limit: $limit, offset: $offset, where: $where) {
      active
      brand_name
      created_at
      logo_url
      vehicle_group
      id
    }
    rental_vehicle_brands_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const VehicleBrandsFilter = gql`
  query VehicleBrandsFilter(
    $limit: Int
    $offset: Int
    $where: rental_vehicle_brands_bool_exp
  ) {
    brand_name: rental_vehicle_brands(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: brand_name
    ) {
      id
      brand_name
    }
  }
`
export const VehicleMasterDataQuery = gql`
  query VehicleMasterData(
    $limit: Int
    $offset: Int
    $where: rental_master_vehicles_bool_exp
  ) {
    rental_master_vehicles(limit: $limit, offset: $offset, where: $where) {
      id
      suspension_brakes_steeringandtyres
      capacity
      colors
      barking_and_traction
      colors
      comfort_and_convenience
      enginetransmission
      barking_and_traction
      capacity
      colors
      description
      dimensionsandweight
      doors_windows_mirrors_wipers
      entertainment_information_communication
      exterior
      features
      instrumentation
      keydata
      lighting
      locks_and_security
      naming
      power
      reputation
      enum_reputaion {
        value
        comments
      }
      safety
      seats_and_upholstery
      storage
      telematics
      url
      vehicle_category
    }
    rental_master_vehicles_aggregate {
      aggregate {
        count
      }
    }

    reputation: enum_rental_reputations {
      value
      comments
    }

    vehicle_category: rental_vehicle_categories {
      value: category_name
      comments: category_name
    }
  }
`

export const VehicleMasterMakeFilter = gql`
  query VehicleMasterMakeFilter(
    $limit: Int
    $offset: Int
    $where: rental_master_vehicles_bool_exp
  ) {
    make: rental_master_vehicles(
      limit: $limit
      offset: $offset
      where: $where
    ) {
      id
      make: naming(path: "make")
    }
  }
`

export const VehicleMasterModelFilter = gql`
  query VehicleMasterMakeFilter(
    $limit: Int
    $offset: Int
    $where: rental_master_vehicles_bool_exp
  ) {
    model: rental_master_vehicles(
      limit: $limit
      offset: $offset
      where: $where
    ) {
      id
      model: naming(path: "model")
    }
  }
`

export const VehicleMasterDataEnumQuery = gql`
  query VehicleMasterEnumData {
    reputation: enum_rental_reputations {
      value
      comments
    }

    vehicle_category: rental_vehicle_categories {
      value: category_name
      comments: category_name
    }
  }
`
/*export const ServiceCategoryGroupList = gql`
  query ServiceCategoryGroupList(
    $limit: Int
    $offset: Int
    $where: service_categories_bool_exp
  ) {
    service_categories(limit: $limit, offset: $offset, where: $where) {
      id
      image_url
      category
      active
      created_at
      enum_service_type {
        comments
        name
      }
    }
    service_categories_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`*/

export const VehicleRentalsQuery = gql`
  query GetRentalVehicles(
    $limit: Int
    $offset: Int
    $where: rental_vehicles_bool_exp
  ) {
    rental_vehicles(limit: $limit, offset: $offset, where: $where) {
      active
      lat
      lng
      minimum_hours
      minimum_kms
      allowance_data
      driver
      photo_urls
      rental_type
      vehicle_data
      vehicle_documents
      deposite_amount
      kms_driven
      price_per_hour
      price_per_hour_offer
      seats
      fuel_type
      transmission_type
      vehicle_group
      vehicle_name
      vehicle_no
      created_at
      updated_at
      map_point
      created_by

      user {
        id
        name
      }
      id
      updated_by

      store_id
      store {
        id
        name
      }
      vehicle_category_id
      vehicle_category {
        id
        category_name
      }

      vehicle_type_id
      vehicle_type {
        id
        type_name
      }

      vehicle_brand_id
      vehicle_brand {
        id
        brand_name
      }

      master_vehicle_id
      master_vehicle {
        id
        naming(path: "make")
      }

      enum_rental_transmissiontype {
        comments
      }
      enum_rental_fueltype {
        comments
      }
    }
  }
`

export const VehicleRentalsEnumData = gql`
  query VehicleRentalsEnumData {
    fuel_type: enum_rental_fueltype {
      value
      comments
    }
    transmission_type: enum_rental_transmissiontype {
      value
      comments
    }

    rental_type: enum_rental_type {
      value
      comments
    }

    driver: enum_rental_driver_type {
      value
      comments
    }
  }
`

export const VehicleTypesTypehead = gql`
  query GetRentalTypeheadVehicles($searchValue: String!) {
    data: rental_vehicle_types(where: { type_name: { _ilike: $searchValue } }) {
      active
      created_at
      id
      image_url
      type_name
      updated_at
    }
  }
`
export const VehicleRentalsCategoriesTypehead = gql`
  query GetRentalVehicleCategories($searchValue: String!) {
    data: rental_vehicle_categories(
      where: { category_name: { _ilike: $searchValue } }
    ) {
      id
      category_name
    }
  }
`

export const VehicleRentalMasterVehiclesTypehead = gql`
  query GetRentalVehicleCategories($searchValue: String) {
    data: rental_master_vehicles(
      where: { naming: { _cast: { String: { _ilike: $searchValue } } } }
    ) {
      id
      naming(path: "make")
      sub: naming(path: "model")
    }
  }
`
export const VehicleRentalVehicleBarndsTypehead = gql`
  query GetRentalVehicleBrands($searchValue: String!) {
    data: rental_vehicle_brands(
      where: { brand_name: { _ilike: $searchValue } }
    ) {
      id
      brand_name
    }
  }
`

export const VehicleRentalStoresTypehead = gql`
  query GetRentalStores($searchValue: String) {
    data: stores(where: { name: { _ilike: $searchValue } }) {
      id
      name
    }
  }
`

export const VehicleUsersTypehead = gql`
  query GetRentalStores($searchValue: String) {
    data: users(where: { name: { _ilike: $searchValue } }) {
      id
      name
    }
  }
`

export const VehicleRentalsVehickeGroupFilter = gql`
  query VehicleRentalsVehickeGroupFilter(
    $limit: Int
    $offset: Int
    $where: rental_vehicles_bool_exp
  ) {
    vehicle_group: rental_vehicles(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: vehicle_group
    ) {
      id
      vehicle_group
    }
  }
`

export const VehicleRentalsFuelTypeFilter = gql`
  query VehicleRentalsVehickeGroupFilter(
    $limit: Int
    $offset: Int
    $where: rental_vehicles_bool_exp
  ) {
    fuel_type: rental_vehicles(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: fuel_type
    ) {
      id
      fuel_type
    }
  }
`

export const VehicleRentalsTransmissionTypeFilter = gql`
  query VehicleRentalsTransmissionTypeFilter(
    $limit: Int
    $offset: Int
    $where: rental_vehicles_bool_exp
  ) {
    transmission_type: rental_vehicles(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: transmission_type
    ) {
      id
      transmission_type
    }
  }
`

export const VehicleRentalsStoreFilter = gql`
  query VehicleRentalsStoreFilter(
    $limit: Int
    $offset: Int
    $where: rental_vehicles_bool_exp
  ) {
    store: rental_vehicles(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: store_id
    ) {
      id
      store_id
      store {
        id
        name
      }
    }
  }
`

export const VehicleRentalsVehicleBrandFilter = gql`
  query VehicleRentalsVehicleBrandFilter(
    $limit: Int
    $offset: Int
    $where: rental_vehicles_bool_exp
  ) {
    vehicle_brand: rental_vehicles(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: vehicle_brand_id
    ) {
      id
      vehicle_brand {
        id
        brand_name
      }
    }
  }
`

export const VehicleRentalsCreatedByFilter = gql`
  query VehicleRentalsCreatedByFilter(
    $limit: Int
    $offset: Int
    $where: rental_vehicles_bool_exp
  ) {
    user: rental_vehicles(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: created_by
    ) {
      id

      created_by
      user {
        id
        name
      }
    }
  }
`

//=========== Rental AirPort

export const VehicleRentalsAirportQuery = gql`
  query GetRrentalAirports(
    $limit: Int
    $offset: Int
    $where: rental_airports_bool_exp
  ) {
    rental_airports(limit: $limit, offset: $offset, where: $where) {
      id
      address
      name
      active
      state
      map_point
    }
  }
`

//----------------- Rental Filters

export const VehicleRentalsFiltersQuery = gql`
  query GetRrentalFilters(
    $limit: Int
    $offset: Int
    $where: rental_filters_bool_exp
  ) {
    rental_filters(limit: $limit, offset: $offset, where: $where) {
      id

      filter_name

      form_type
      active
      created_at
      updated_at

      filter_fields
      enum_rental_filter {
        comments
        value
      }

      vehicle_group
      enum_rental_group {
        comments
        value
      }
    }
  }
`

export const VehicleRentalsFiltersCreateQuery = gql`
  query VehicleRentalsFiltersCreateQuery(
    $limit: Int
    $offset: Int
    $where: rental_filters_bool_exp
  ) {
    rental_filters(limit: $limit, offset: $offset, where: $where) {
      id

      filter_name

      form_type
      active
      created_at
      updated_at

      filter_fields
      enum_rental_filter {
        comments
        value
      }

      vehicle_group
      enum_rental_group {
        comments
        value
      }
    }
  }
`

export const VehicleRentalsDriversEnumData = gql`
  query VehicleRentalsDriversEnumData {
    vehicle_group: enum_rental_group {
      value
      comments
    }
    form_type: enum_rental_filters {
      value
      comments
    }
  }
`

export const VehicleRentalsNameFilter = gql`
  query VehicleMasterMakeFilter(
    $limit: Int
    $offset: Int
    $where: rental_airports_bool_exp
  ) {
    name: rental_airports(limit: $limit, offset: $offset, where: $where) {
      id
      name
    }
  }
`

export const VehicleRentalsStateFilter = gql`
  query VehicleMasterMakeFilter(
    $limit: Int
    $offset: Int
    $where: rental_airports_bool_exp
  ) {
    state: rental_airports(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: state
    ) {
      id
      state
    }
  }
`

export const VehicleRentalsFiltersFilterNameFilter = gql`
  query VehicleRentalsFiltersFilterNameFilter(
    $limit: Int
    $offset: Int
    $where: rental_filters_bool_exp
  ) {
    filter_name: rental_filters(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: filter_name
    ) {
      id
      filter_name
    }
  }
`

export const VehicleRentalsFiltersVehicleGroupFilter = gql`
  query VehicleRentalsFiltersFilterNameFilter(
    $limit: Int
    $offset: Int
    $where: rental_filters_bool_exp
  ) {
    vehicle_group: rental_filters(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: vehicle_group
    ) {
      id
      vehicle_group
    }
  }
`

//----------------- Rental Drivers

export const VehicleRentalsDriversQuery = gql`
  query GetRrentalDrivers(
    $limit: Int
    $offset: Int
    $where: rental_drivers_bool_exp
  ) {
    rental_drivers(limit: $limit, offset: $offset, where: $where) {
      id
      driver_name
      phone
      email
      dob
      dedicated_by
      store_id
      store {
        id
        name
      }
      active
      created_at
      updated_at
      created_by
      updated_by
    }
  }
`

export const VehicleRentalDriversStoresTypehead = gql`
  query VehicleRentalDriversStoresTypehead($searchValue: String) {
    data: stores(where: { name: { _ilike: $searchValue } }) {
      id
      name
    }
  }
`

export const RentalDriversFilter = gql`
  query RentalDriversFilter(
    $limit: Int
    $offset: Int
    $where: rental_drivers_bool_exp
  ) {
    driver_name: rental_drivers(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: driver_name
    ) {
      id
      driver_name
    }
  }
`

//=========== Rental Types

export const VehicleRentalsTypesQuery = gql`
  query GetRrentaltypes(
    $limit: Int
    $offset: Int
    $where: rental_vehicle_types_bool_exp
  ) {
    rental_vehicle_types(limit: $limit, offset: $offset, where: $where) {
      id
      type_name
      image_url
      active
      vehicle_group
      created_at
      updated_at
    }
  }
`

export const VehicleRentalsTypesEnumData = gql`
  query VehicleRentalsTypesEnumData {
    vehicle_group: enum_rental_group {
      value
      comments
    }
  }
`

export const Insert_Rental_Vehicle_Type = gql`
  mutation insert_rental_vehcile_types_insert_ones(
    $object: rental_vehicle_types_insert_input!
  ) {
    insert_rental_vehicle_types_one(object: $object) {
      id
    }
  }
`

export const Update_Rental_Vehicle_Type = gql`
  mutation update_rental_vehicle_types_by_pk(
    $id: uuid!
    $object: rental_vehicle_types_set_input
  ) {
    update_rental_vehicle_types_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

export const RentalVehicleTypesFilters = gql`
  query VehicleRentalsFiltersFilterNameFilter(
    $limit: Int
    $offset: Int
    $where: rental_vehicle_types_bool_exp
  ) {
    type_name: rental_vehicle_types(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: type_name
    ) {
      id
      type_name
    }
  }
`

//----------- Rental Addons------
export const RentalAddonsQueryList = gql`
  query RentalAddonsQueryList(
    $limit: Int
    $offset: Int
    $where: rental_addons_bool_exp
  ) {
    rental_addons(limit: $limit, offset: $offset, where: $where) {
      id
      addon_name
      addon_type
      created_at
      description
      enum_addon {
        comments
      }
      icon
      powered_by
      price
    }
  }
`

export const VehicleRentalsAddonsAddonNamesFilter = gql`
  query VehicleRentalsAddonsAddonNamesFilter(
    $limit: Int
    $offset: Int
    $where: rental_addons_bool_exp
  ) {
    addon_name: rental_addons(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: addon_name
    ) {
      id
      addon_name
    }
  }
`

export const VehicleRentalsAddonsTypesFilter = gql`
  query VehicleRentalsAddonsTypesFilter(
    $limit: Int
    $offset: Int
    $where: rental_addons_bool_exp
  ) {
    addon_type: rental_addons(
      limit: $limit
      offset: $offset
      where: $where
      distinct_on: addon_type
    ) {
      id
      addon_type
    }
  }
`

export const VehicleRentalsAddonsEnumData = gql`
  query VehicleRentalsAddonsEnumData {
    addon_type: enum_rental_addons {
      value
      comments
    }
  }
`

//----Rental Filters Docs---

export const VehicleRentalFiltersKyc = gql`
  query VehicleRentalFiltersKyc(
    $limit: Int
    $offset: Int
    $where: kyc_bool_exp
  ) {
    kyc(limit: $limit, offset: $offset, where: $where) {
      active
      back_photo_url
      created_at
      customer_id
      customer {
        id
        name
      }
      driver_id
      rental_driver {
        driver_name
      }
      front_photo_url
      id
      id_no
      is_uploaded
      is_verified
      kyc_name
      updated_at

      enum_kyc_name {
        comments
        value
      }
    }
  }
`

export const VehicleRentalsKYCEnumData = gql`
  query VehicleRentalsKYCEnumData {
    kyc_name: enum_kyc_names {
      value
      comments
    }
  }
`

export const VehicleRentalKYCcustomerTypehead = gql`
  query GetKYCustomer($searchValue: String) {
    data: customers(where: { name: { _ilike: $searchValue } }) {
      id
      name
    }
  }
`
export const VehicleRentalKYCDriverTypehead = gql`
  query GetKYCDriver($searchValue: String) {
    data: rental_drivers(where: { driver_name: { _ilike: $searchValue } }) {
      id
      driver_name
    }
  }
`
export const PropertyTypeList = gql`
  query PropertyTypeList(
    $limit: Int
    $offset: Int
    $where: property_type_bool_exp
  ) {
    property_type(limit: $limit, offset: $offset, where: $where) {
      id
      type_name
      active
    }
    property_type_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
export const PropertyRulesList = gql`
  query PropertyRulesList(
    $limit: Int
    $offset: Int
    $where: property_rules_bool_exp
  ) {
    property_rules(limit: $limit, offset: $offset, where: $where) {
      id
      rule_name
      description
      rule_type
      active
      created_at
    }
    property_rules_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const PropertyAmenitiesList = gql`
  query PropertyAmenitiesList(
    $limit: Int
    $offset: Int
    $where: property_amenities_bool_exp
  ) {
    property_amenities(limit: $limit, offset: $offset, where: $where) {
      id
      amenity_name
      description
      amenity_type
      active
      created_at
    }
    property_amenities_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const PropertyRulesTypeList = gql`
  query PropertyRulesTypeList {
    enum_property_rules_type {
      value
      comments
    }
  }
`

export const PropertyAmenitiesTypeList = gql`
  query PropertyAmenitiesTypeList {
    enum_property_amenities_type {
      value
      comments
    }
  }
`
export const ExperienceTypeList = gql`
  query ExperienceTypeList(
    $limit: Int
    $offset: Int
    $where: experience_type_bool_exp
  ) {
    experience_type(limit: $limit, offset: $offset, where: $where) {
      id
      name
      photo
      active
      created_at
    }
    experience_type_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const PropertyDetailsList = gql`
  query PropertyDetailsList(
    $limit: Int
    $offset: Int
    $where: property_details_bool_exp
  ) {
    property_details(limit: $limit, offset: $offset, where: $where) {
      id
      property_name
      description
      portion_type
      door_no
      address_1
      landmark
      city
      state
      pincode
      no_of_bedrooms
      no_of_beds
      no_of_max_guests
      no_of_bathroom
      no_of_kitchen
      photo_url
      start_time
      end_time
      min_night
      max_night
      basic_price
      min_price
      start_date
      end_date
      recurring_type
      booking_cut_off_time
      no_of_sqft
      sub_title
      active
      created_at
    }
    property_details_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`

export const ExperienceDetailsList = gql`
  query ExperienceDetailsList(
    $limit: Int
    $offset: Int
    $where: experience_details_bool_exp
  ) {
    experience_details(limit: $limit, offset: $offset, where: $where) {
      id
      experience_name
      description
      experience_type
      door_no
      address_1
      landmark
      city
      state
      pincode
      photo_url
      map_point
      lat
      lng
      experience_data
      guest_have_to_bring
      duration_starts
      duration_ends
      recurring_type
      active
      total_seats
      hosting_languages
      min_age
      ticket_price
      arrival_notification_status
      advanced_booking_time
      booking_cut_off_time
      identity_proof
      created_at
    }
    experience_details_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
