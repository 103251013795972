import React, { useEffect, useState } from "react"
import { CheckPicker, Modal, TagInput, Toggle } from "rsuite"
import { withRouter } from "react-router-dom"
import {
 
  Insert_Rental_Driver,
 
  Update_Rental_Driver,
 
} from "gql/Mutation"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import { SelectPicker } from "rsuite"
import FileUploader from "components/Common/FileUploader"
import axios from "axios"
import PropTypes from "prop-types"
import Swal from "sweetalert2"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  VehicleMasterDataEnumQuery,
  VehicleMasterDataQuery,
  VehicleRentalsAirportQuery,
  VehicleRentalsDriversQuery,
  VehicleRentalsEnumData,
  VehicleRentalsQuery,
} from "gql/query"
import { Columns } from "./columns"
import { Action } from "../Common/Action"
import { onChangeHandlerFunction } from "./rentals-drivers-.functions"
import { BuildFields } from "../Common/BuildFields"

const AirportRentalsCreate = (props) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [CreateVehicleMasterDataMutation] = useMutation(
    Insert_Rental_Driver
  )
  const [UpdateVehicleMasterDataMutation] = useMutation(Update_Rental_Driver)
  const [queryCondition, setQueryCondition] = useState(null)
  const [masterData, setMasterData] = useState({})
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  })

  const [getVehicleAirportsRentalsList, { loading, data, refetch, error }] =
    useLazyQuery(VehicleRentalsDriversQuery, {
      skipCache: false,
      variables: {
        limit: 1,
        where: { id: { _eq: props?.masterData?.id } },
      },
    })

    
  const [
    getVehicleMasterDataEnumList,
    {
      loading: EnumLoading,
      data: EnumData,
      refetch: enumRefetch,
      error: Enumerror,
    },
  ] = useLazyQuery(VehicleRentalsEnumData, {
    skipCache: false,
  })

  useEffect(() => {
    if (!queryCondition) setQueryCondition(props?.masterData?.id)
    else getVehicleAirportsRentalsList()
    getVehicleMasterDataEnumList()
  }, [props?.masterData?.id, queryCondition])

  //If rentals data exists, merge values
  useEffect(() => {
    //Add vlaues to the all columns
    if (data?.rental_drivers?.length > 0) {
      let airports_rentals = data?.rental_drivers?.[0]

      Object.keys(Columns)?.map((key, i) => {
        let obj = Columns?.[key]

        //If it is an array of columns
        if (
          key &&
          airports_rentals?.[key] &&
          Object.keys(airports_rentals?.[key])?.length > 1 &&
          Array.isArray(Columns?.[key])
        ) {
          Columns[key]?.forEach((e, index) => {
            Columns[key][index] = {
              ...e,
              ...{ value: airports_rentals[key][e.name] },
            }
          })
        }

        //If it is an object of column and data is not an array
        else if (
          !Array.isArray(airports_rentals?.[key]) &&
          !Array.isArray(Columns?.[key])
        ) {
          if (obj?.type == "typehead") {
            let field = Columns?.[key]?.access
            console.log("checkingotherdetails",airports_rentals, field, key)
            Columns[key] = {
              ...Columns?.[key],
              label: airports_rentals?.[field]?.[Columns[key]?.acessLabel],
              value: airports_rentals?.[field]?.[Columns[key]?.acessValue],
            }
          } else {
            Columns[key] = {
              ...Columns?.[key],
              value: airports_rentals?.[key],
              
            }
          }
        }
        //If it an object of column and existing data is not array
        else if (
          Array.isArray(airports_rentals?.[key]) &&
          !Array.isArray(Columns?.[key])
        ) {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        } else if (
          !Array.isArray(airports_rentals?.[key]) &&
          Array.isArray(Columns?.[key])
        ) {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        } else {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        }
      })
    }

    setMasterData({ ...Columns })
  }, [data?.rental_drivers?.length])


  console.log("masterDatadetails", masterData)

  //Capitalize the labels
  const camalize = function camalize(str) {
    return str
      ?.toLowerCase()
      ?.replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
        return chr?.toUpperCase()
      })
  }
  //Remove an image
  const removeImage = (e) => {
    const index = e?.target?.dataset?.index
    const parent = e?.target?.dataset?.parent
    let Columns = {}
    Columns = { ...masterData }

    if (Array.isArray(Columns?.[parent]?.value)) {
      const newArray = [...Columns[parent]?.value]
      newArray.splice(index, 1)
      Columns[parent].value = newArray
      setMasterData(Columns)
    }
  }

  //Open new tab if clicks on an image
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  //Submit or update the master data
  const handleCreateBrand = async (event) => {
    event.preventDefault()
    let newObj = {}
    let data = JSON.parse(localStorage.getItem("authUser"))

    Object.keys(masterData)?.map((e, i) => {
      masterData?.[e]?.length > 0
        ? [...masterData[e]]
            ?.filter((ee) => ee)
            ?.map((ei, ii) => {
              newObj[e] = { ...newObj?.[e], [ei?.name]: ei?.value }
            })
        : (newObj[e] = masterData?.[e]?.value)
    })
    newObj = {
      ...newObj,
      dedicated_by:"eefind",
      created_by:data.user.id,
      updated_by:data.user.id
 
    }

    console.log("checkingdatata",data,data.user.id, newObj)



    setBtnLoading(true)


    const Result  = await Action({entity:"Rental Drivers",
                                  id : props?.masterData?.id , 
                                  updateQuery:UpdateVehicleMasterDataMutation,
                                  createQuery : CreateVehicleMasterDataMutation,
                                  object:newObj,
                                  submit:props?.onSubmit,
                                  close:props?.close()
                              })

                              setBtnLoading(false)
    console.log("Result is", Result)
  
  }

  const setCoordinates = (field, locationDetails) => {

    console.log("locationDetsaasddails",field,locationDetails)
    let objs = {}
    objs = { ...masterData }

    objs[field].value = {
      type: "Point",
      crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::4326" } },
      coordinates: [locationDetails.lat, locationDetails.lng],
    }
    objs["state"].value = locationDetails?.state
    objs["address"].value = locationDetails?.address
     
    console.log("ojalsdknnflasndlk", objs,masterData)
    // objs["lat"] = {value:coordinates[0]}
    // objs["lng"] = {value: coordinates[1]}

    setMasterData(objs)
  }


  console.log("checking fields are",)

 
  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      style={{ width: "100vw", margin: "0 auto" }}
    >
      <Modal.Header>
        <Modal.Title className="mb-2">
          {props.brand?.id ? "UPDATE RENTAL DRIVERS" : "CREATE RENTAL DRIVERS"}
        </Modal.Title>
      </Modal.Header>
      <nav>
        <form onSubmit={handleCreateBrand}>
        <div className="form-group" onChange={(e) => onChangeHandlerFunction({e,masterData,setMasterData })}>
            <Row>
            {Object.keys(masterData)?.map((e, i) => {
                return !Array.isArray(masterData?.[e])
                  ? 
                  BuildFields({naming:e, field:masterData?.[e], ind:null,EnumData,masterData, setMasterData,onChangeHandler:onChangeHandlerFunction})
                  : 
                  [...masterData[e]] ?.filter((ee) => ee) ?.map((ei, i) => BuildFields({naming:e, field:ei, ind:i,EnumData,masterData,setMasterData,onChangeHandler:onChangeHandlerFunction}))

              })}
            </Row>
          </div>
          <button type="submit">submit</button>
        </form>
      </nav>
    </Modal>
  )
}

AirportRentalsCreate.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  brand: PropTypes.any,
  onSubmit: PropTypes.func,
  masterData: PropTypes.any,
}
export default withRouter(AirportRentalsCreate)
