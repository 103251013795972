import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import {
  Container,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import EcommerceSystemUsersTable from "./EcommerceSystemUsersTable"
import "../../../../assets/scss/custom.scss"
const EcommerceSystemUsers = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>System Users | EEFind </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="System Users" />
          <EcommerceSystemUsersTable 
            queryCondition={null}
            activeFilters={["active", "created_at", "role"]}
            filterQueryConditions={null}
            showFilters={true}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
EcommerceSystemUsers.propTypes = {}
export default withRouter(EcommerceSystemUsers)
