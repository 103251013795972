import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { isEmpty } from "lodash"
import { useRouteMatch, Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
import ShopOverview from "./ShopOverview"
import ShopOrders from "./ShopOrders"
import ShopProducts from "./ShopProducts"
import ShopPromotions from "./ShopPromotions"
import EcommerceReviewsTable from "../EcommerceReviews/EcommerceReviewsTable"
//Import custom styles
import "./EcommerceShopDetails.style.scss"
const EcommerceShopDetails = props => {
  const match = useRouteMatch()
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState({})
  const [activeTab, setActiveTab] = useState('overview')
  return (
    <div className="EcommerceShopDetails">
      <div className="page-content">
        <MetaTags>
          <title>Shop Details | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Shops" breadcrumbItem="Shop Details" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
                    <Tab eventKey="overview" title="Overview">
                      <ShopOverview setStores={setStores} />
                    </Tab>
                    <Tab eventKey="orders" title="Orders">
                      <ShopOrders
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="products" title="Products">
                      <ShopProducts
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="promotions" title="Promotions">
                      <ShopPromotions
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="reviews" title="Reviews">
                    <EcommerceReviewsTable
                        queryCondition={{order: {store_id: {_eq: match?.params?.id}}}}
                        activeFilters={[]}
                        filterQueryConditions={null}
                      />
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommerceShopDetails.propTypes = {
}
export default EcommerceShopDetails