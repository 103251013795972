import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import {
  Container,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import EcommerceSubCategoriesTable from "./EcommerceSubCategoriesTable"
import "../../../../assets/scss/custom.scss"

const EcommerceCategories = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Sub Categories | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Ecommerce" breadcrumbItem="Categories" />
              <EcommerceSubCategoriesTable
                queryCondition={null}
                activeFilters={["product_brand", "category", "subcategory", "store", "created_at", "non_mrp"]}
                filterQueryConditions={null}
                showCreateBtn={true}
              />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}
EcommerceCategories.propTypes = {}
export default withRouter(EcommerceCategories)
