import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
} from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
import { useRouteMatch } from "react-router-dom"
//import components
import EcommerceCustomersTable from "../EcommerceCustomers/EcommerceCustomersTable"

const MerchantTopCustomers = (props) => {
    const match = useRouteMatch()
    const [queryCondition, setQueryCondition] = useState({ordersByCustomerId: {store: {created_by: {_eq: match?.params?.id}}}})
    const pathName = props.pathName
    return (
        <div className="p-3">
            <EcommerceCustomersTable
                queryCondition={queryCondition}
                activeFilters={["gender", "created_at", "city"]}
                filterQueryConditions={null}
                orderBy={{ordersByCustomerId_aggregate: {count: "desc"}}}
                pathName={pathName}
            />
        </div>
    )
}
MerchantTopCustomers.propTypes = {
  pathName: PropTypes.any,
}
export default MerchantTopCustomers