import React, { useEffect, useState } from "react"
import { AutoComplete } from 'rsuite'
import MemberIcon from '@rsuite/icons/Member'
import { useLazyQuery } from "@apollo/client"
import { VehicleTypesTypehead } from "gql/query"
import PropTypes from "prop-types"


 const TypeHead = (props) =>{

    const [search, setSearch]  = useState('')
    const [isRequired, setIsRequired] = useState(true)
 
    const [fetchData, { loading, data, refetch, error }] = useLazyQuery(
        props?.query,
        {
            skipCache: true,
            variables: { searchValue:`%${search}%`, limit:5},
 
        }
    ) 


    const typeHead = data?.data?.map(e=>({label:`${e[props.acessLabel]} ${props?.sub ? "("+e[props?.sub]+")" : ""}`, value: e[props.acessValue]}))
  

    const onChange = (e) =>{

        setIsRequired(true)
        setSearch(e)

    }
 
     useEffect(()=>{

        fetchData()

        
        
    },[search])

    useEffect(()=>{
             setSearch(props?.label)

    },[props?.label])

    console.log("props details are", props)
 
     return   <AutoComplete
                data = {typeHead?.map(e=>e?.label) || []}
                value={search}
                required={isRequired}
                onChange={((e)=>onChange(e))}
                style={{ width: "100%" }}
                onSelect={(e)=>{
                    console.log("adslnaldslnalsdklasd", typeHead,e,typeHead?.find(ele => ele.label == e) )
                    setIsRequired(false)
                    return props.onSelects(typeHead?.find(ele => ele.label == e))}}
                renderMenuItem={item => {
                    return (
                    <div>
                        <MemberIcon /> {item}
                    </div>
                    )
                }}
             />
    


 }



 

TypeHead.propTypes = {
    onSelects: PropTypes.func.isRequired,
    entity: PropTypes.any,
    query:PropTypes.query,
    acessLabel: PropTypes.acessLabel,
    sub:PropTypes.label,
    acessValue: PropTypes.acessValue,
    value:PropTypes.value,
    label:PropTypes.label
  }

export default TypeHead
