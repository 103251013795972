import React from "react"
import { Dropdown } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { GoKebabVertical } from "react-icons/go"

//Helpers
import ConvertRangesToQuery from "helpers/range_to_query_helper"
const isDiscountApplied = (order) =>
  Array.isArray(order?.discounts) && order?.discounts?.length > 0
export const FormatData = (data) => {
 
  // return{order: data?.services}

  const order = data?.services?.map((is) => {
     return {
      ...data?.services,
      id: is.id,
      orderId: is.uid,
      landmark: is?.landmark,
      service: is?.service?.service_name,
      service_category: is?.service_category?.category,
      service_name: is?.service?.service_name,
      created_by: is?.user?.name,
      updated_by: is?.UpdatedBy?.name,
      email: is?.user?.email,
      phone: is?.user?.phone,
    }
  })
  return {
    order,
  }
}

const formatHighlighter = (text, searchKey, filterValue) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.status)) {
    searchWords = [...searchWords, ...filterValue?.status]
  }
  if (Array.isArray(filterValue?.city)) {
    searchWords = [...searchWords, ...filterValue?.city]
  }
  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  )
}
export const EcommerceOrderColumns = (searchKey, filterValue, history) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.id) {
        return (
          <Link to={`/services/service-list/details/${row.id}`}>
            {formatHighlighter(row.id, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.id, searchKey, filterValue)
      }
    },
  },

  {
    dataField: "service_name",
    text: "Service",
    sort: true,
  },

  {
    dataField: "service_category",
    text: "Category",
    sort: true,

  },
  {
    dataField: "landmark",
    text: "LandMark",
    sort: true,
  },

  {
    dataField: "created_by",
    text: "Created By",
    sort: true,
  },

  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
  },

  {
    dataField: "active",
    text: "Active",
    sort: true,
    formatter: (cellContent, row) => (cellContent?.active ? "True" : "False"),
  },
  {
    dataField: "is_deleted",
    text: "Deleted",
    sort: true,
    formatter: (cellContent, row) => (cellContent?.active ? "True" : "False"),
  },

  
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          <GoKebabVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              history.push(`/services/service-list/details/${row.id}`)
            }}
          >
            View Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
]

export const BuildOrderSearchQuery = (value, queryCondition) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (isNaN(value)) {
      conditionTemp = {
        ...conditionTemp,
        _or: [
          { service: { service_name: { _ilike: `%${value}%` } } },
          { service_category: { service_type: { _ilike: `%${value}%` } } },
          // { service_name: {   service:{service_name:{ _ilike: `%${value}%`  }}  } },
          { landmark: { _ilike: `%${value}%` } },
          { CreatedBy: { name: { _ilike: `%${value}%` } } },
          { UpdatedBy: { name: { _ilike: `%${value}%` } } },
          { UpdatedBy: { email: { _ilike: `%${value}%` } } },
          { UpdatedBy: { name: { _ilike: `%${value}%` } } },
        ],
      }
    } else {
      conditionTemp = {
        ...conditionTemp,
        _or: [{ phone: { _ilike: `%${value}%` } }, { uid: { _eq: value } }],
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
export const BuildMasterDataFilterQuery = (filterValue, queryCondition) => {
   if (Object.keys(filterValue).length) {
    let conditionTemp = []
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("make")) {
      if (filterValue.make?.length > 0) {   

          filterValue?.make.forEach((e,i)=>{
            conditionTemp.push({naming:  { _contains: { make:e} }})
          })
  
      }
    }
    if (filterValue?.hasOwnProperty("model")) {
      if (filterValue.model?.length > 0) {
        filterValue?.model.forEach((e,i)=>{
          conditionTemp.push({naming:  { _contains: { model:e} }})
        })

 
      }
    }

 
 
     return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
 
    return conditionTemp
  }
}
