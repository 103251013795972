import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import {
  Container,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import ExperienceTypeTable from "./ExperienceTypeTable"
import "../../../../assets/scss/custom.scss"

const ExperienceType = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Experience | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Experience" breadcrumbItem="Experience Type" />
              <ExperienceTypeTable
                queryCondition={null}
                activeFilters={null}
                filterQueryConditions={null}
                showCreateBtn={true}
              />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}
ExperienceType.propTypes = {}
export default withRouter(ExperienceType)
