import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { useRouteMatch } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
import { Dropdown } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from 'react-icons/go'
import Swal from "sweetalert2"
//Helpers
import isValidUrl from "../../../helpers/url_checker"
//GraphQL
import { MerchantNotesList } from "gql/query"
import { DeleteMerchantNote } from "gql/Mutation"
import { useQuery, useMutation } from "@apollo/client"

//Import components
import MerchantCreateNote from "./MerchantCreateNote"

import { useSelector } from "react-redux"
const MerchantNotes = (props) => {
	const { capabilities } = useSelector((state) => state.Login)
	const noteColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Note",
			dataField: "note",
			sort: true,
		},
		{
			dataField: "title",
			text: "Title",
			sort: false,
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
		{
			dataField: "view",
			isDummyField: true,
			text: "Action",
			sort: false,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
				<Dropdown>
					<Dropdown.Toggle variant="transparent" id="dropdown-basic">
						<GoKebabVertical />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{capabilities.includes("edit") && (
							<Dropdown.Item onClick={() => {
								setNote(row)
								setNoteModalOpen(true)
							}}>Edit</Dropdown.Item>
						)}
						{capabilities.includes("delete") && (
							<Dropdown.Item onClick={() => handleDeleteNote(row.id)}>Delete</Dropdown.Item>
						)}
					</Dropdown.Menu>
				</Dropdown>
			),
		},
	]
	const [DeleteMerchantNoteMutation] = useMutation(DeleteMerchantNote)
	const match = useRouteMatch()
	const defaultSorted = []
	const [notes, setNotes] = useState([])
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({ merchant_id: { _eq: match?.params?.id } })
	const [noteModalOpen, setNoteModalOpen] = useState(false)
	const [note, setNote] = useState({})

	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}

	const { data, loading, error, refetch } = useQuery(MerchantNotesList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const count = data?.merchant_notes_aggregate?.aggregate?.count
	const maxPage = Math.ceil(count / PageData.limit)

	const handleDeleteNote = async (id) => {
		try {
			Swal.fire({
				title: 'Are you sure want to delete this note?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					await DeleteMerchantNoteMutation({
						variables: {
							id
						},
					})
					refetch()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	useEffect(() => {
		if (Array.isArray(data?.merchant_notes)) {
			setNotes(data.merchant_notes)
		}
	}, [data])
	useEffect(() => {
		if (!noteModalOpen) {
			setNote({})
		}
	}, [noteModalOpen])
	return (
		<div className="p-3">
			<Row className="mb-5">
				<Row className="mb-2">
					<Col sm="12">
						{capabilities.includes("create") && (
							<div className="text-sm-end d-flex justify-content-end">
								<Button color="primary" onClick={() => setNoteModalOpen(true)}>
									<GoPlus style={{ marginRight: 5 }} />
									Create Note
								</Button>
							</div>
						)}
					</Col>
				</Row>
				<hr />
				<ToolkitProvider
					keyField="id"
					data={notes}
					columns={noteColumns}
					bootstrap4
				>
					{toolkitProps => (
						<>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										{loading ? <Loader /> :
											<BootstrapTable
												responsive
												bordered={false}
												striped={false}
												defaultSorted={defaultSorted}
												classes={"table align-middle table-nowrap"}
												keyField="id"
												{...toolkitProps.baseProps}
												onTableChange={() => { }}
												noDataIndication={() => "No Notes Found!"}
											// {...paginationTableProps}
											/>
										}
									</div>
								</Col>
							</Row>
							<Row className="align-items-md-center mt-40">
								<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
									<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
										Previous Page
									</Button>
									<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
									</div>
									<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
										Next Page
									</Button>
								</Col>
							</Row>
						</>
					)}
				</ToolkitProvider>
			</Row>
			{capabilities.includes("create") && (
			<MerchantCreateNote
				open={noteModalOpen}
				close={() => setNoteModalOpen(false)}
				onCreate={refetch}
				merchantId={match?.params?.id}
				note={note}
			/>
			)}
		</div>
	)
}
MerchantNotes.propTypes = {}
MerchantNotes.displayName = 'MerchantNotes'
export default MerchantNotes