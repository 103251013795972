import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from "prop-types"

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16
}

const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box'
}

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
}

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
}


function FileUploader(props) {
	const [files, setFiles] = useState([])
	const thumbs = files.map(file => (
		<div style={thumb} key={file.name}>
			<div style={thumbInner}>
				<img
					src={file.preview}
					style={img}
				/>
			</div>
		</div>
	))
	useEffect(() => () => {
		// Make sure to revoke the data uris to avoid memory leaks
		files.forEach(file => URL.revokeObjectURL(file.preview))
	}, [files])
	return (
		<Dropzone
			onDrop={acceptedFiles => {
				props.onChange(acceptedFiles)
				setFiles(acceptedFiles.map(file => Object.assign(file, {
					preview: URL.createObjectURL(file)
				})))
			}}
			multiple={false}
			accept={'image/*'}
		>
			{({ getRootProps, getInputProps }) => (
				<>
				<div className="dropzone">
					<div
						className="dz-message needsclick"
						{...getRootProps()}
					>
						<input {...getInputProps()} />
						<div className="dz-message needsclick">
							<div className="mb-3">
								<i className="display-4 text-muted bx bxs-cloud-upload" />
							</div>
							<h4>Drop file here or click to upload.</h4>
						</div>
					</div>
				</div>
				<aside style={thumbsContainer}>
        {thumbs}
      </aside>
			</>
			)}
		</Dropzone>
	)
}
FileUploader.propTypes = {
	onChange: PropTypes.func
}
export default FileUploader