import React from "react"
import { Dropdown } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { GoKebabVertical } from "react-icons/go"

//Helpers
import ConvertRangesToQuery from "helpers/range_to_query_helper"
const isDiscountApplied = (order) =>
  Array.isArray(order?.discounts) && order?.discounts?.length > 0
export const FormatData = (data) => {
 
  // return{order: data?.services}

  const order = data?.services?.map((is) => {
     return {
      ...data?.services,
      id: is.id,
      orderId: is.uid,
      landmark: is?.landmark,
      service: is?.service?.service_name,
      service_category: is?.service_category?.category,
      service_name: is?.service?.service_name,
      created_by: is?.user?.name,
      updated_by: is?.UpdatedBy?.name,
      email: is?.user?.email,
      phone: is?.user?.phone,
    }
  })
  return {
    order,
  }
}

const formatHighlighter = (text, searchKey, filterValue) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.status)) {
    searchWords = [...searchWords, ...filterValue?.status]
  }
  if (Array.isArray(filterValue?.city)) {
    searchWords = [...searchWords, ...filterValue?.city]
  }
  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  )
}
export const EcommerceOrderColumns = (searchKey, filterValue, history) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.id) {
        return (
          <Link to={`/services/service-list/details/${row.id}`}>
            {formatHighlighter(row.id, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.id, searchKey, filterValue)
      }
    },
  },

  {
    dataField: "service_name",
    text: "Service",
    sort: true,
  },
  // {
  //   dataField: "service_name",
  //   text: "Service Name",
  //   sort: true,
  // },

  {
    dataField: "service_category",
    text: "Category",
    sort: true,
    // formatter: (cellContent, row) => {
    //   console.log("CELLasdCASDASD",row, cellContent)
    //   return row?.service_category?.category
    // }
  },
  {
    dataField: "landmark",
    text: "LandMark",
    sort: true,
  },

  {
    dataField: "created_by",
    text: "Created By",
    sort: true,
  },
  // {
  //   dataField: "updated_by",
  //   text: "Updated By",
  //   sort: true,
  // },

  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
  },

  {
    dataField: "active",
    text: "Active",
    sort: true,
    formatter: (cellContent, row) => (cellContent?.active ? "True" : "False"),
  },
  {
    dataField: "is_deleted",
    text: "Deleted",
    sort: true,
    formatter: (cellContent, row) => (cellContent?.active ? "True" : "False"),
  },
 
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          <GoKebabVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              history.push(`/services/service-list/details/${row.id}`)
            }}
          >
            View Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
]

export const BuildOrderSearchQuery = (value, queryCondition) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (isNaN(value)) {
      conditionTemp = {
        ...conditionTemp,
        _or: [
          { service: { service_name: { _ilike: `%${value}%` } } },
          { service_category: { service_type: { _ilike: `%${value}%` } } },
          // { service_name: {   service:{service_name:{ _ilike: `%${value}%`  }}  } },
          { landmark: { _ilike: `%${value}%` } },
          { CreatedBy: { name: { _ilike: `%${value}%` } } },
          { UpdatedBy: { name: { _ilike: `%${value}%` } } },
          { UpdatedBy: { email: { _ilike: `%${value}%` } } },
          { UpdatedBy: { name: { _ilike: `%${value}%` } } },
        ],
      }
    } else {
      conditionTemp = {
        ...conditionTemp,
        _or: [{ phone: { _ilike: `%${value}%` } }, { uid: { _eq: value } }],
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
export const BuildOrderFilterQuery = (filterValue, queryCondition) => {
   if (Object.keys(filterValue).length) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("service")) {
      if (filterValue.service?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          service: { service_name: { _in: filterValue.service } },
        }
      }
    }
    if (filterValue?.hasOwnProperty("service_category")) {
      if (filterValue.service_category?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          service: {
            service_category: {
              category: { _in: filterValue.service_category },
            },
          },
        }
      }
    }

    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        conditionTemp = {
          ...conditionTemp,
          _and: [
            { created_at: { _gte: filterValue.created_at[0] } },
            { created_at: { _lte: filterValue.created_at[1] } },
          ],
        }
      }
    }
    if (filterValue?.hasOwnProperty("landmark")) {
       if (filterValue.landmark?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          landmark: { _in: filterValue.landmark },
        }
       }
    }
    if (filterValue?.hasOwnProperty("created_by")) {
      if (filterValue.created_by?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          CreatedBy: { _in: filterValue.created_by },
        }
      }
    }
    if (filterValue?.hasOwnProperty("created_by")) {
      if (filterValue.created_by?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          UpdatedBy: { _in: filterValue.updated_by },
        }
      }
    }

    if (filterValue?.hasOwnProperty("email")) {
      if (filterValue.email?.length > 0) {
        // let deliveryChargesFilter = ConvertRangesToQuery(filterValue.delivery_charges, "delivery_charges")
        conditionTemp = {
          ...conditionTemp,
          UpdatedBy: { _in: filterValue.email },
        }
      }
    }
    if (filterValue?.hasOwnProperty("phone")) {
      if (filterValue.name?.length > 0) {
        // let orderItemsFilter = ConvertRangesToQuery(filterValue.order_items, "total_order_items")
        conditionTemp = {
          ...conditionTemp,
          UpdatedBy: { _in: filterValue.name },
        }
      }
    }
    // if (filterValue?.hasOwnProperty("category")) {
    //   if (filterValue.category?.length > 0) {
    //     conditionTemp = {
    //       ...conditionTemp,
    //       order_items: {
    //         product: {
    //           master_product: {
    //             category: { id: { _in: filterValue.category } }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // if (filterValue?.hasOwnProperty("subcategory")) {
    //   if (filterValue.subcategory?.length > 0) {
    //       conditionTemp = {
    //         ...conditionTemp,
    //         order_items: {
    //           product: {
    //             master_product: {
    //               subcategory: { id: { _in: filterValue.subcategory } }
    //             }
    //           }
    //         }
    //       }
    //   }
    // }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
 
    return conditionTemp
  }
}
