import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import {
  Container,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import EcommerceMasterCatalogueTable from "./EcommerceMasterCatalogueTable"
import "../../../../assets/scss/custom.scss"

const EcommerceMasterCatalogue = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Master Catalogue | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Dashboard" breadcrumbItem="Master Catalogue" />
              <EcommerceMasterCatalogueTable
                queryCondition={null}
                activeFilters={["product_brand", "category", "subcategory", "created_at"]}
                filterQueryConditions={null}
              />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}
EcommerceMasterCatalogue.propTypes = {}
export default withRouter(EcommerceMasterCatalogue)
