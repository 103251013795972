import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import { useRouteMatch } from "react-router-dom"
import moment from "moment"
import { Badge } from "react-bootstrap"
import { Loader } from "rsuite"
import Swal from "sweetalert2"
import { post } from "helpers/api_helper"
import axios from "axios"
//GraphQL
import { MasterCatalogueById } from "gql/query"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { UpdateMasterProduct } from "gql/Mutation"
//Helpers
import isValidUrl from "helpers/url_checker"
import showAlert from "helpers/alert_helper"
//Components
import ProductImageComponent from "./ProductImageComponent"

import { useSelector } from "react-redux"
const ProductOverview = (props) => {
	const { capabilities } = useSelector((state) => state.Login)
	const match = useRouteMatch()
	const [product, setProduct] = useState({})
	const [loadingBtns, setLoadingBtns] = useState({
		master: false,
	})

	const { data: ProductDetails, loading, error, refetch } = useQuery(MasterCatalogueById, {
		skipCache: true,
		variables: {
			id: match?.params?.id
		}
	})
	const [UpdateMasterProductMutation] = useMutation(UpdateMasterProduct)

	const getImage = (product) => {
		let image = "/placeholder.jpg"
		if (product) {
			if (Object.keys(product)?.length) {
				if (product?.photo_urls?.length > 0) {
					image = product.photo_urls[0]
				}
			}
		}
		return image
	}
	const handleChangeStatus = async () => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					await UpdateMasterProductMutation({
						variables: {
							id: product.id,
							object: {
								isactive: !product.isactive
							}
						},
					})
					refetch()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	const handleUpdateProductImage = async (e, name) => {
		try {
			setLoadingBtns({ ...loadingBtns, [name]: true })
			let formData = new FormData()
			formData.append("uploads", e.target.files[0])
			const resp = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`, formData)
			if (resp.data?.success) {
				if (Array.isArray(resp.data?.data?.uploaded_files)) {
					const link = resp.data.data.uploaded_files[0].uploaded_file_url
					if (name === "master") {
						await UpdateMasterProductMutation({
							variables: {
								id: product.id,
								object: {
									photo_urls: [link]
								}
							},
						})
					}
					refetch()
				} else {
					showAlert({ message: "Unable to upload image!", type: "error" })
				}
			} else {
				showAlert({ message: "Unable to upload image!", type: "error" })
			}
		} catch (err) {
			console.log(err)
			showAlert({ message: "Unable to upload image!", type: "error" })
		} finally {
			setLoadingBtns({ ...loadingBtns, [name]: false })
		}
	}
	useEffect(() => {
		if (Array.isArray(ProductDetails?.master_products)) {
			setProduct(ProductDetails.master_products[0])
		}
	}, [ProductDetails])
	return (
		<Container>
			{loading ?
				<div className="text-center">
					<Loader size="md" />
				</div>
				: (
					<>
						{product ? (
							<>
								<Row>
									<Col sm="4">
										<ProductImageComponent
											src={getImage(product, "main")}
											title="Master Product Image"
											layout="full"
											onImageSelect={(e) => handleUpdateProductImage(e, "master")}
											loading={loadingBtns.master}
											name="master"
											isEdit={capabilities.includes("edit")}
										/>
									</Col>
									<Col sm="8" className="pl-3">
										<div>
											<h3>{product?.name}
												<>
													{product?.isactive ? (
														<Badge bg={"success"} style={{ textTransform: "capitalize", marginLeft: 10 }}>
															Active
														</Badge>
													) : (
														<Badge bg={"danger"} style={{ textTransform: "capitalize", marginLeft: 10 }}>
															Deactive
														</Badge>
													)}
												</>
											</h3>
										</div>
										<hr />
										<Row>
											<div className="stats-wrapper">
												<b>Product ID</b>
												<p>{product?.id}</p>
											</div>
											<div className="stats-wrapper">
												<b>Weight</b>
												<p>{product?.weight} {product?.weight_unit}</p>
											</div>
											<div className="stats-wrapper">
												<b>Brand</b>
												<p>{product?.product_brand?.name || "N/A"}</p>
											</div>
											<div className="stats-wrapper">
												<b>Category</b>
												<p>{product?.category?.name || "N/A"}</p>
											</div>
											<div className="stats-wrapper">
												<b>Sub-Category</b>
												<p>{product?.subcategory?.name || "N/A"}</p>
											</div>
											<div className="stats-wrapper">
												<b>Non-MRP</b>
												<p>{product?.non_mrp ? "Yes" : "No"}</p>
											</div>
										</Row>
									</Col>
								</Row>
								{capabilities.includes("edit") && (
									<Row>
										<Col xl="12" className="d-flex justify-content-end">
											<Button
												type="button"
												color={product?.isactive ? "danger" : "primary"}
												className="btn-lg  mb-2 me-2"
												onClick={handleChangeStatus}
											>
												{product?.isactive ? "Deactivate" : "Activate"}
											</Button>
										</Col>
									</Row>
								)}
							</>
						) : (
							<p>No Product Found!</p>
						)}
					</>
				)}
		</Container>
	)
}
ProductOverview.propTypes = {
	setStores: PropTypes.func
}
ProductOverview.displayName = 'ProductOverview'
export default ProductOverview