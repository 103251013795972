import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Card, CardBody } from 'reactstrap'
import { Link, withRouter } from "react-router-dom"
import { RentalAddonsQueryList, VehicleBrandList, VehicleMasterDataQuery, VehicleRentalsQuery } from "gql/query"
import { useLazyQuery } from "@apollo/client"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from "react-icons/go"
import PropTypes from "prop-types"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useSelector } from "react-redux"
import { SelectPicker } from "rsuite"
import Loader from "../../../components/Loader/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import VehicleRentalsCreate from './vehicle-retnals-addibs-create'
import moment from "moment"
import { HtmlToExcel } from 'pages/Common/HtmlToExcel'
import { useMutation } from "@apollo/client"
import Swal from "sweetalert2"
import axios from "axios"
import { Delete_master_vehicle_data, Delete_master_vehicle_rentals_data } from 'gql/Mutation'
import EcommerceRentalsMasterDataFilter from 'components/Filters/Ecommerce/EcommerceRentalsMasterDataFilter'
import { BuildMasterDataFilterQuery } from './Vehicle-retnals-addons-functions'
import { BikesHtmlToExcelHeaders, CarsHtmlToExcelHeaders } from './columns'
import EcomerceRentalVehiclesFilter from 'components/Filters/Ecommerce/EcommerceRentalsVehiclesFilter'
import RentalAddonsFilter from 'components/Filters/Ecommerce/EcommerceRentalsAddonsFilter'



const FormatMasterData = (data) => {

    console.log("rentaldknaldsnflansdlnaslkd",data)

    const brands = data?.rental_addons?.map((elem) => {
         return {
            id: elem?.id,
            addon_name: elem?.addon_name,
            description: elem?.description,
            icon: elem?.icon,
            addon_type: elem?.enum_addon.comments,
            price:elem?.price
  
        }
    })
    return brands
}

const RentalAddonsList = (props) => {
    const { capabilities } = useSelector((state) => state.Login)
    const [queryCondition, setQueryCondition] = useState([])
    const [brands, setMasterData] = useState([])
    const [modal, setModal] = useState(false)
    const [brandModalOpen, setBrandOpen] = useState(false)
    const [selectedRentals, setselectedRentals] = useState({})
    const [PageData, setPageData] = useState({ limit: 10, pageNo: 1 })
    const [deleteVehicleRentalsData] = useMutation(Delete_master_vehicle_rentals_data)
    const [filterValue, setFilterValue] = useState("")

    //Open new tab if clicks on an image
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    

    var node = useRef()
    const limitData = [
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "200", value: 200 },
        { label: "500", value: 500 },
    ]
    
    const defaultSorted = []

    const VehicleBrandColumns = (toggleModal) => [
        {
            text: "ID",
            dataField: "id",
            sort: false,
            formatter:(cellContent, row) =>{
                return <Link to="/detail" style={{"pointer-events": "auto"}}>{row.id}</Link>
            }
        },
        {
            text: "Add on Name",
            dataField: "addon_name",
            sort: true,
        },
        {
            text: "Description",
            dataField: "description",
            sort: true,
            headerStyle: { width: "100px" },

        },

        {
            text: "Add on Type",
            dataField: "addon_type",
            sort: true,
        },
        {
            text: "Price",
            dataField: "price",
            sort: true,
        },

        {
            text: "Icon",
            dataField: "icon",
            sort: true,
            formatter:(cellContent, row) =>{

               return   <span   style={{position:"relative", margin:'5px'}}>
                    <img
                        alt="no image"
                        className="absolute inset-0 w-full h-full object-cover object-center "
                        src={row.icon}
                        onClick={() => { openInNewTab(row.icon) }}
                        style={{width:"30px",cursor: "pointer",}}
                    />
                
                </span> 

                }

            
        },
 
 
        {
            dataField: "view",
            isDummyField: true,
            text: "Action",
            sort: false,
            hidden: !capabilities.includes("edit"),
            formatter: (cellContent, row) => (
                <Dropdown>
                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                        <GoKebabVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                setselectedRentals(row)
                                setBrandOpen(true)
                            }}
                        >
                            Edit
                        </Dropdown.Item>
                    <Dropdown.Item
                            onClick={() => {
                                mutateRecord(row?.id)
                            }}
                        >
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ),
        },
    ]



    const mutateRecord=async(id)=>{
 
       const result =  await deleteVehicleRentalsData({variables:{"id":id}})
  
            if(result?.data) return Swal.fire( "Deleted!", "", "success" )
            
            Swal.fire( "Not Deleted. Contact Admin!", "", "success" )

     }

    const handleSearch = (e) => {
        let value = e.target.value
        if (value.length >= 2) {
            let conditionTemp = {}
            if (props.queryCondition !== null) {
                conditionTemp = { ...props.queryCondition }
            }
            // conditionTemp = {
            //     ...conditionTemp,
               
            //         ...{naming: {_cast: {String: {_ilike: `%${value}%`}}}},
            
            // }

            conditionTemp =  [
                {addon_name:  {_ilike: `%${value}%`}},
                 // {vehicle_group:  {_ilike: `_%${as}%`}},
            ]

            setQueryCondition(conditionTemp)
        } else {
            let conditionTemp = {}
            if (props.queryCondition !== null) {
                conditionTemp = { ...props.queryCondition }
            }
            setQueryCondition(conditionTemp)
            refetch()
        }
    }
    const PageDown = () => {
        if (PageData.pageNo > 0)
            setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
    }
    const PageUp = () => {
        setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
    }
    const toggle = () => {
        setModal(!modal)
    }
    const handleTableChange = (type, { page, searchText }) => { }
    const [getRentalAddons, { loading, data, refetch, error }] = useLazyQuery(
        RentalAddonsQueryList,
        {
            skipCache: true,

            variables: { limit: PageData.limit,
                 offset: (PageData.pageNo - 1) * PageData.limit, where: {_or:queryCondition}
                 },
        }
    )

    useEffect(() => {
        let conditionTemp = BuildMasterDataFilterQuery(filterValue, props.queryCondition)
        setQueryCondition(conditionTemp)
      }, [filterValue])

     useEffect(() => {
        if (props.queryCondition !== null) {
            setQueryCondition(props.queryCondition)
        } else {
            getRentalAddons()
        }
    }, [props.queryCondition])
    useEffect(() => {
        if (Object.keys(queryCondition).length) {
            getRentalAddons()
        } else {
            getRentalAddons()
        }
    }, [queryCondition])
    useEffect(() => {
        if (data) {
            setMasterData(FormatMasterData(data))
        }
    }, [data])

    const count = data?.rental_vehicles?.aggregate?.count || 0
    const maxPage = Math.ceil(count / PageData.limit)

    return (
        <>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <ToolkitProvider
                                keyField="id"
                                data={brands || []}
                                columns={VehicleBrandColumns(toggle)}
                                bootstrap4
                                search
                            >
                                {(toolkitProps) => (
                                    <React.Fragment>
                                        <Row className="mb-2">
                                            <Col sm="4">
                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault()
                                                            }}
                                                        >
                                                            <Form.Group
                                                                className="mb-3"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <i className="bx bx-search-alt search-icon" />
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Search rentals"
                                                                    onChange={handleSearch}
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>

                                            
                                            {props?.showCreateBtn && capabilities.includes("create") && (
                                                <Col sm="8">
                                                    <div className="text-sm-end d-flex justify-content-end">
                                                    <Button className="m-1"
                                                            color="primary"
                                                            onClick={() => setBrandOpen(true)}
                                                        >
                                                            <GoPlus />
                                                            Create Addons
                                                        </Button>
                                                      <RentalAddonsFilter 
                                                                onFilterChange={setFilterValue}
                                                                activeFilters={props.activeFilters}
                                                                filterQueryConditions={props.filterQueryConditions}
                                                        />



                                                    </div>
       
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <SelectPicker
                                                    data={limitData}
                                                    searchable={false}
                                                    style={{ width: 100 }}
                                                    placeholder="Limit"
                                                    value={PageData.limit}
                                                    onChange={(value) =>
                                                        setPageData({ ...PageData, limit: value })
                                                    }
                                                    cleanable={false}
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    {loading ? (
                                                        <Loader />
                                                    ) : (
                                                        <BootstrapTable
                                                            responsive
                                                            bordered={false}
                                                            striped={false}
                                                            defaultSorted={defaultSorted}
                                                            classes={"table align-middle table-nowrap"}
                                                            keyField="id"
                                                            {...toolkitProps.baseProps}
                                                            onTableChange={handleTableChange}
                                                            ref={node}
                                                            noDataIndication={() => "No Categories Found!"}
                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-40">
                                            <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                                                <Button
                                                    disabled={PageData.pageNo <= 1}
                                                    onClick={() => {
                                                        PageDown()
                                                    }}
                                                >
                                                    Previous Page
                                                </Button>
                                                <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}</div>
                                                <Button
                                                    disabled={PageData.pageNo >= maxPage}
                                                    onClick={() => {
                                                        PageUp()
                                                    }}
                                                >
                                                    Next Page
                                                </Button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
             {capabilities.includes("create") && (
                <VehicleRentalsCreate
                    open={brandModalOpen}
                    close={() => {

                        setselectedRentals({})
                        setBrandOpen(false)
                    }}
                    onSubmit={() => refetch()}
                    masterData={selectedRentals}
                />
            )}
        </>
    )
}

RentalAddonsList.propTypes = {
    queryCondition: PropTypes.any,
    activeFilters: PropTypes.array,
    filterQueryConditions: PropTypes.any,
    showCreateBtn: PropTypes.bool,
}

export default withRouter(RentalAddonsList)