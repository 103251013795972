import React from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import EcommerceCustomersTable from "./EcommerceCustomersTable"
const EcommerceCustomers = (props) => {
  const pathName = props.location.pathname

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customers | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Customers" />
          <EcommerceCustomersTable
            queryCondition={
              pathName.startsWith("/groceries")
                ? {
                    ordersByCustomerId: {
                      order_type: {
                        _in: ["delivery", "home_delivery", "in_store_pickup"],
                      },
                    },
                  }
                : pathName.startsWith("/services")
                ? { ordersByCustomerId: { order_type: { _eq: "service" } } }
                : pathName.startsWith("/property")
                ? { ordersByCustomerId: { order_type: { _eq: "property" } } }
                : pathName.startsWith("/experience")
                ? { ordersByCustomerId: { order_type: { _eq: "experience" } } }
                : null
            }
            activeFilters={["gender", "created_at", "city"]}
            filterQueryConditions={null}
            showFilters={true}
            orderBy={{ created_at: "desc_nulls_last" }}
            pathName={pathName}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
EcommerceCustomers.propTypes = {
  customers: PropTypes.array,
  location: PropTypes.object,
}
export default EcommerceCustomers
