import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
 import AirportRentalsList from "./airport-rentals-list"
//import "../../../../assets/scss/custom.scss"

const RentalAirPorts = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Rental AirPorts | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Rentals" breadcrumbItem="Airport Rentals" />
              <AirportRentalsList queryCondition={null} activeFilters={["name","state" ]} filterQueryConditions={null} showCreateBtn={true} />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}

RentalAirPorts.propTypes = {}
export default withRouter(RentalAirPorts)
