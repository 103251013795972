import React, { useState, useEffect } from "react"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import {
	Col,
	Row,
} from "reactstrap"
import PropTypes from 'prop-types'
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
import axios from "axios"
import { Link } from "react-router-dom"

const MerchantBusinessDetails = (props) => {
	const defaultSorted = []
	const [loading, setLoading] = useState(false)
	const [storeData, setStoreData] = useState([])
	const businessDetailsColumns = [
		{
			text: "Shop",
			dataField: "shop",
			sort: true,
			formatter: (cellContent, row) => {
				if (row.id) {
					return <Link to={`/ecommerce-shops/details/${row.id}`}>{cellContent}</Link>
				} else {
					return cellContent
				}
			}
		},
		{
			text: "GST",
			dataField: "gst",
			sort: true,
		},
		{
			dataField: "business_name",
			text: "Business Name",
			sort: true,
		},
		{
			text: "Address",
			dataField: "business_address",
			sort: true,
		},
	]
	const getGSTdetails = async (gst_no) => {
		return new Promise(async (resolve, reject) => {
			try {
				const resp = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}user/verify_business`, {
					"number": gst_no,
					"type": "gst"
				})
				resolve(resp.data)
			} catch (err) {
				resolve(null)
			}
		})
	}
	const handleSetStoreData = async () => {
		try {
			setLoading(true)
			let storesDataTemp = []
			for (let count = 0; count < props.stores.length; count++) {
				const store = props.stores[count]
				let gstResp = null
				if (store.gst_available) {
					gstResp = await getGSTdetails(store.business_gstno)
				}
				let business_name = store.name
				let business_address = store.address_line_1
				if (gstResp) {
					business_name = `${gstResp?.data?.business_name} (*as per GST)`
					business_address = `${gstResp?.data?.address} (*as per GST)`
				}
				storesDataTemp.push({
					shop: store.name,
					gst: store.gst_available ? store.business_gstno : "N/A",
					business_name,
					business_address,
					id: store.id
				})
			}
			setStoreData([...storesDataTemp])
		} catch (err) {

		} finally {
			setLoading(false)
		}
	}
	useEffect(() => {
		if (Array.isArray(props.stores) && props.stores?.length) {
			handleSetStoreData()
		}
	}, [props.stores])
	return (
		<ToolkitProvider
			keyField="shop"
			data={storeData}
			columns={businessDetailsColumns}
			bootstrap4
		>
			{toolkitProps => (
				<>
					<Row>
						<Col xl="12">
							<div className="table-responsive">
								{loading ? <Loader /> :
									<BootstrapTable
										responsive
										bordered={false}
										striped={false}
										defaultSorted={defaultSorted}
										classes={"table align-middle table-nowrap"}
										keyField="id"
										{...toolkitProps.baseProps}
										onTableChange={() => { }}
										noDataIndication={() => "No Details Found!"}
									// {...paginationTableProps}
									/>
								}
							</div>
						</Col>
					</Row>
				</>
			)}
		</ToolkitProvider>
	)
}
MerchantBusinessDetails.propTypes = {
	stores: PropTypes.any
}
export default MerchantBusinessDetails