import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Row,
  Col,
} from "reactstrap"
import { Toggle } from "rsuite"

import img7 from "../../../assets/images/product/img-7.png"
import img4 from "../../../assets/images/product/img-4.png"
import { useMutation, useQuery } from "@apollo/client"
import {
  CommissionDetailsByID,
  commissionDetails,
  getOrderDetail_byId,
} from "gql/query"
import "../../../assets/scss/custom.scss"
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"
import { CheckPicker } from "rsuite"
import CheckPickerPaginate from "components/Filters/CheckPickerPaginate"
import { UpdateCommissionByPK } from "./EcommerceServices.query"
import Swal from "sweetalert2"

const EcommerceOrdersModal = (props) => {
  const { isOpen, Selectedorder, close } = props
  const [updateCommissionByPk] = useMutation(UpdateCommissionByPK)
  const [percentage, setPercentage] = useState()
  const [isActive, setActive] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)

  // const updateDa = async (e) => {
  //   e?.preventDefault()
  //   const formData = new FormData(e.target)

  //   if (!formData.get("percentage")) return

  //   const result = await updateCommissionByPk({
  //     variables: {
  //       id: Selectedorder?.id,
  //       setObject: {
  //         commission_value: formData.get("percentage"),
  //         active: isActive,
  //       },
  //     },
  //   })
  //   Swal.fire(`Commission Updated Successfully`).then(() => {
  //     props.onSubmit()
  //     props.close()
  //   })
  // }

  const handleupdateCommission = async (e) => {
    try {
      e.preventDefault()
      setBtnLoading(true)
      if (Selectedorder?.id) {
        let objects = {
          commission_value: percentage,
          active: isActive,
        }
         await updateCommissionByPk({
          variables: {
            id: Selectedorder?.id,
            setObject: objects,
          },
        })
      }
      Swal.fire(`Commission Updated Successfully`, "", "success").then(() => {
        props.onSubmit()
        props.close()
      })
    } catch (err) {
      Swal.fire("Something went wrong", "", "error")
    } finally {
      setBtnLoading(false)
    }
  }

  useEffect(() => {
    if (Selectedorder?.id) {
      setActive(Selectedorder.active)
      setPercentage(Selectedorder.commission_value)
    }
  }, [Selectedorder])
  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={close}
      >
        <div className="modal-content">
          <ModalHeader toggle={close}>Commission Details</ModalHeader>
          <ModalBody>
            <form onSubmit={handleupdateCommission}>
              <div className="mb-3">
                <Label htmlFor="percentage">Percentage</Label>
                <Input
                  id="percentage"
                  name="percentage"
                  value={percentage}
                  type="text"
                  className="form-control"
                  onChange={(e) => setPercentage(e.target.value)}
                  required
                />
              </div>
              <Row>
                <Col sm="12">
                  <div className="mb-3">
                    <Label>Active</Label>
                    <Toggle
                      checked={isActive}
                      onChange={(checked) => setActive(checked)}
                      style={{ marginLeft: "1rem" }}
                    />
                  </div>
                </Col>
              </Row>
              <Button type="submit" disabled={btnLoading}>
                Save
              </Button>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={close}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  )
}
EcommerceOrdersModal.propTypes = {
  close: PropTypes.func,
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  Selectedorder: PropTypes.any,
}

export default EcommerceOrdersModal
