import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Col, Container, Row, Button } from "reactstrap"
import moment from "moment"
import { Badge } from "react-bootstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import download from "downloadjs"
import Swal from "sweetalert2"
import { useSelector } from "react-redux"

const RentalVehicleDetails = (props) => {
  const [vehicleDetails, setVehicleDetails] = useState([])

  useEffect(() => {
    if (props.order?.rental_order_items?.length) {
      setVehicleDetails(props.order.rental_order_items[0]?.vehicle)
    }
  }, [props.order])

  // console.log("Vehicle details", vehicleDetails)

  return (
    <Container>
      <Row>
        <Col sm="4">
          <img
            src={
              vehicleDetails.photo_urls?.length > 0
                ? vehicleDetails.photo_urls[0]
                : "/placeholder.jpg"
            }
            className="w-100"
          />
        </Col>
        <Col sm="8" className="pl-3">
          <h3>{vehicleDetails.vehicle_name}</h3>
          <Row>
            <Col>
              <h6>Brand Name</h6>
              <p>{vehicleDetails?.vehicle_brand?.brand_name}</p>
            </Col>
            <Col>
              <h6>Vehicle No</h6>
              <p>{vehicleDetails?.vehicle_no}</p>
            </Col>
          </Row>

          <hr />
          <Row>
            <Col>
              <h6>Rate per hour </h6>
              <p>Rs. {vehicleDetails?.price_per_hour}</p>
            </Col>
            <Col>
              <h6>Deposit Amount</h6>
              <p>Rs. {vehicleDetails?.deposite_amount}</p>
            </Col>
            <Col>
              {" "}
              <h6>Rental Type</h6>
              {vehicleDetails?.rental_type?.map((type, i) => (
                <Badge pill bg="primary" key={i} style={{marginLeft: 5}}>
                  {type}
                </Badge>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
RentalVehicleDetails.propTypes = {
  order: PropTypes.any,
  refetch: PropTypes.func,
  pathName: PropTypes.any,
}
RentalVehicleDetails.displayName = "RentalVehicleDetails"
export default RentalVehicleDetails
