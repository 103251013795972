import React, { useState, useEffect } from "react"
import { CheckPicker, Button as RSButton } from 'rsuite'
import { useQuery, useApolloClient } from "@apollo/client"
import PropTypes from "prop-types"

const CheckProductPickerPaginate = (props) => {
	const client = useApolloClient()
	const [queryCondition, setQueryCondition] = useState({})
	const [PageData, setPageData] = useState({
		limit: 30,
		pageNo: 1
	})
	const [list, setList] = useState([])

	const { data: items, loading, error } = useQuery(props.query, {
		skipCache: true,
		variables: {
			limit: 30,
			offset: 0,
			where: queryCondition
		}
	})
	const resetQueryCondition = () =>{
		let conditionTemp = {}
			if (props.filterCondition !== null) {
				conditionTemp = { ...props.filterCondition }
			}
			setQueryCondition(conditionTemp)
	}
	const renderPickerFooter = () => {
		if (props.paginate) {
			return (
				<div style={{
					padding: '10px 2px',
					borderTop: '1px solid #e5e5e5'
				}}>
					<RSButton
						style={{
							float: 'right',
							marginRight: 10,
							marginTop: 2
						}}
						appearance="primary"
						size="sm"
						onClick={loadMore}
					>
						Load More
					</RSButton>
				</div>
			)
		} else {
			return null
		}
	}
	const handleSearch = (value) => {
		if (value.length >= 3) {
			setQueryCondition({
				...queryCondition,
				_or: [
					{nonmrp_product_name: {_ilike: `%${value}%`}}, 
					{master_product: {name: {_ilike: `%${value}%`}}}
				]
			})
		} else {
			resetQueryCondition()
		}
	}
	const loadMore = async () => {
		let conditionTemp = {}
		if (props.filterCondition !== null) {
			conditionTemp = { ...props.filterCondition }
		}
		let newPageNo = PageData.pageNo + 1
		const { data } = await client.query({
			query: props.query,
			skipCache: true,
			variables: {
				limit: PageData.limit,
				offset: (newPageNo - 1) * PageData.limit,
				where: conditionTemp
			}
		})
		let temp = []
		if (Array.isArray(data?.[props.arrKey])) {
			data[props.arrKey].map((item) => {
				temp.push({
					label: item.non_mrp ? `${item.nonmrp_product_name} (₹${item.price})` : `${item.master_product?.name} (₹${item.price})`,
					value: JSON.stringify(item)
				})
			})
			// setList([...list, ...temp])
			setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
		}
	}
	useEffect(() => {
		let temp = []
		if (Array.isArray(items?.[props.arrKey])) {
			items[props.arrKey]?.map((item) => {
				temp.push({
					label: item.non_mrp ? `${item.nonmrp_product_name} (₹${item.price})` : `${item.master_product?.name} (₹${item.price})`,
					value: JSON.stringify(item)
				})
			})
			setList(temp)
		}
	}, [items])
	useEffect(() => {
		if (props.filterCondition !== null) {
			setQueryCondition(props.filterCondition)
		}
	}, [props.filterCondition])
	useEffect(()=>{
		if(list.length && props.value?.length){
			let itemInList = list.filter(item => item.value === props.value)
			if(itemInList.length === 0 && props.searchValue){
				handleSearch(props.searchValue)
			}
		}
	}, [props.value, list])
	return (
		<CheckPicker
			{...props}
			data={list}
			placeholder={props.placeholder}
			onSearch={handleSearch}
			onChange={props.onChange}
			renderExtraFooter={renderPickerFooter}
			placement="bottomStart"
			onClean={resetQueryCondition}
		/>
	)
}
CheckProductPickerPaginate.propTypes = {
	query: PropTypes.any,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	arrKey: PropTypes.string,
	paginate: PropTypes.bool,
	filterCondition: PropTypes.any,
	searchValue: PropTypes.any
}
export default CheckProductPickerPaginate