import React, { useEffect, useState } from "react"
import { Modal, } from "rsuite"
import { withRouter } from "react-router-dom"
import { Insert_Rental_Vehicle_Type, } from "gql/Mutation"
import { Row, } from "reactstrap"
import PropTypes from "prop-types"
import axios from "axios"
import { useLazyQuery, useMutation } from "@apollo/client"
import { Update_Rental_Vehicle_Type, VehicleRentalsTypesEnumData, VehicleRentalsTypesQuery, } from "gql/query"
import { Columns } from "./columns"
import { BuildFields } from "../Common/BuildFields"
import { onChangeHandlerFunction } from "./rental-vehicle-type.functions"
import { Action } from "../Common/Action"

const AirportRentalsCreate = (props) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [CreateVehicleMasterDataMutation] = useMutation( Insert_Rental_Vehicle_Type )
  const [UpdateVehicleMasterDataMutation] = useMutation(Update_Rental_Vehicle_Type)
  const [queryCondition, setQueryCondition] = useState(null)
  const [masterData, setMasterData] = useState({})
  const [PageData, setPageData] = useState({ limit: 50, pageNo: 1, })

  const [getVehicleAirportsRentalsList, { loading, data, refetch, error }] =
    useLazyQuery(VehicleRentalsTypesQuery, {
      skipCache: false,
      variables: {
        limit: 1,
        where: { id: { _eq: props?.masterData?.id } },
      },
    })

    
  const [
    getVehicleMasterDataEnumList,
    {
      loading: EnumLoading,
      data: EnumData,
      refetch: enumRefetch,
      error: Enumerror,
    },
  ] = useLazyQuery(VehicleRentalsTypesEnumData, {
    skipCache: false,
  })

  useEffect(() => {
    if (!queryCondition) setQueryCondition(props?.masterData?.id)
    else getVehicleAirportsRentalsList()
    getVehicleMasterDataEnumList()
   }, [props?.masterData?.id, queryCondition])

  console.log("chekcoadsinsda", data)

  //If rentals data exists, merge values
  useEffect(() => {
    //Add vlaues to the all columns
    if (data?.rental_vehicle_types?.length > 0) {
      let airports_rentals = data?.rental_vehicle_types?.[0]

      Object.keys(Columns)?.map((key, i) => {
        let obj = Columns?.[key]

        console.log("chekcoadsinsda", obj, airports_rentals)
        //If it is an array of columns
        if (
          key &&
          airports_rentals?.[key] &&
          Object.keys(airports_rentals?.[key])?.length > 1 &&
          Array.isArray(Columns?.[key])
        ) {
          Columns[key]?.forEach((e, index) => {
            Columns[key][index] = {
              ...e,
              ...{ value: airports_rentals[key][e.name] },
            }
          })
        }

        //If it is an object of column and data is not an array
        else if (
          !Array.isArray(airports_rentals?.[key]) &&
          !Array.isArray(Columns?.[key])
        ) {
          if (obj?.type == "typehead") {
            let field = Columns?.[key]?.access
            Columns[key] = {
              ...Columns?.[key],
              label: airports_rentals?.[field]?.[Columns[key]?.acessLabel],
              value: airports_rentals?.[field]?.[Columns[key]?.acessValue],
            }
          } else {
            Columns[key] = {
              ...Columns?.[key],
              value: airports_rentals?.[key],
            }
          }
        }
        //If it an object of column and existing data is not array
        else if (
          Array.isArray(airports_rentals?.[key]) &&
          !Array.isArray(Columns?.[key])
        ) {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        } else if (
          Array.isArray(airports_rentals?.[key]) &&
          !Array.isArray(Columns?.[key])
        ) {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        } else {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        }
      })
    }

    setMasterData({ ...Columns })
  }, [data?.rental_vehicle_types?.length])

 
  //Remove an image
  const removeImage = (e) => {
    const index = e?.target?.dataset?.index
    const parent = e?.target?.dataset?.parent
    let Columns = {}
    Columns = { ...masterData }

    if (Array.isArray(Columns?.[parent]?.value)) {
      const newArray = [...Columns[parent]?.value]
      newArray.splice(index, 1)
      Columns[parent].value = newArray
      setMasterData(Columns)
    }
  }


  //Submit or update the master data
  const handleCreateVehicleType = async (event) => {
    event.preventDefault()
    const newObj = {}

    Object.keys(masterData)?.map((e, i) => {
      masterData?.[e]?.length > 0
        ? [...masterData[e]]
            ?.filter((ee) => ee)
            ?.map((ei, ii) => {
              newObj[e] = { ...newObj?.[e], [ei?.name]: ei?.value }
            })
        : (newObj[e] = masterData?.[e]?.value)
    })
 
   setBtnLoading(true)
   const Result  = await Action({entity:"Rental Vehicle Type",
                                 id : props?.masterData?.id , 
                                 updateQuery:UpdateVehicleMasterDataMutation,
                                 createQuery : CreateVehicleMasterDataMutation,
                                 object:newObj,
                                 submit:props?.onSubmit,
                                 close:props?.close()
                          })
    
   console.group("Result is", Result)
   setBtnLoading(false)
 
  }

  



  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      style={{ width: "100vw", margin: "0 auto" }}
    >
      <Modal.Header>
        <Modal.Title className="mb-2">
          {props.brand?.id ? "UPDATE VEHICLE RENTAL TYPE" : "CREATE VEHICLE RENTAL TYPE"}
        </Modal.Title>
      </Modal.Header>
      <nav >
        <form onSubmit={handleCreateVehicleType}>
          <div className="container-fluid" onChange={(e)=>onChangeHandlerFunction({e, masterData,setMasterData })}>
            <Row className="d-flex" style={{widht:"100%"}}>
              {Object.keys(masterData)?.map((e, i) => {
                return !Array.isArray(masterData?.[e])
                  ? BuildFields({naming:e, field:masterData?.[e],masterData,setMasterData, ind:null,EnumData,onChangeHandler:onChangeHandlerFunction})
                  : [...masterData[e]]
                      ?.filter((ee) => ee)
                      ?.map((ei, i) =>  BuildFields({naming:e, field:ei,masterData,setMasterData, ind:i,EnumData,onChangeHandler:onChangeHandlerFunction}))
              })}

              
            </Row>
          </div>
          <button type="submit">submit</button>
        </form>
      </nav>
    </Modal>
  )
}

AirportRentalsCreate.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  brand: PropTypes.any,
  onSubmit: PropTypes.func,
  masterData: PropTypes.any,
}
export default withRouter(AirportRentalsCreate)
