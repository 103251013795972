import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import { useRouteMatch } from "react-router-dom"
import moment from "moment"
import { Badge } from "react-bootstrap"
import { Loader } from "rsuite"
import Swal from "sweetalert2"
import { post } from "helpers/api_helper"
import axios from "axios"
//GraphQL
import { ProductById } from "gql/query"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { UpdateMasterProduct, UpdateProduct } from "gql/Mutation"
//Helpers
import isValidUrl from "helpers/url_checker"
import showAlert from "helpers/alert_helper"
//Components
import ProductImageComponent from "./ProductImageComponent"
import EditProductModal from "./EditProductModal"

import { useSelector } from "react-redux"

const ProductOverview = (props) => {
	const { capabilities } = useSelector((state) => state.Login)
	const match = useRouteMatch()
	const [product, setProduct] = useState({})
	const [queryConditionStats, setQueryConditionStats] = useState({})
	const [loadingBtns, setLoadingBtns] = useState({
		master: false,
		front: false,
		back: false,
		left: false,
		right: false
	})
	const [editModalOpen, setEditModalOpen] = useState(false)

	const { data: ProductDetails, loading, error, refetch } = useQuery(ProductById, {
		skipCache: true,
		variables: {
			id: match?.params?.id
		}
	})
	const [UpdateMasterProductMutation] = useMutation(UpdateMasterProduct)
	const [UpdateProductMutation] = useMutation(UpdateProduct)

	// const [UpdateVendorCompanyMutation] = useMutation(UpdateVendorCompany)
	const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${value.toFixed(2)}`
		} else {
			return "N/A"
		}
	}
	const getImage = (product, type) => {
		let image = "/placeholder.jpg"
		if (Object.keys(product)?.length) {
			if (type === "main") {
				if (product.master_product?.photo_urls?.length > 0) {
					image = product.master_product.photo_urls[0]
				}
			} else {
				if (typeof product.image_url === "object") {
					if (Array.isArray(product.image_url?.image)) {
						product.image_url.image.map(obj => {
							if (Object.keys(obj)[0] === type) {
								image = obj[type]
							}
						})
					} else if (product.image_url?.front) {
						image = product.image_url?.front
					}
				} else if (isValidUrl(product.image_url)) {
					image = product.image_url
				}
			}
		}
		return image
	}
	const handleChangeStatus = async () => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					await UpdateProductMutation({
						variables: {
							id: product.id,
							object: {
								approve: !product.approve
							}
						},
					})
					refetch()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	const handleUpdateProductImage = async (e, name) => {
		try {
			setLoadingBtns({ ...loadingBtns, [name]: true })
			let formData = new FormData()
			formData.append("uploads", e.target.files[0])
			const resp = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`, formData)
			if (resp.data?.success) {
				if (Array.isArray(resp.data?.data?.uploaded_files)) {
					const link = resp.data.data.uploaded_files[0].uploaded_file_url
					if (name === "master") {
						await UpdateMasterProductMutation({
							variables: {
								id: product.master_product.id,
								object: {
									photo_urls: [link]
								}
							},
						})
					} else {
						let photoObj = {}
						if (typeof product.image_url === "object") {
							if (Array.isArray(product.image_url?.image)) {
								photoObj["image"] = []
								product.image_url.image.map(obj => {
									if (Object.keys(obj)[0] === name) {
										photoObj["image"].push({ [name]: link })
									} else {
										photoObj["image"].push({ [Object.keys(obj)[0]]: obj[Object.keys(obj)[0]] })
									}
								})
							} else {
								photoObj = {
									image: [{ [name]: link }]
								}
							}
						} else {
							photoObj = {
								image: [{ [name]: link }]
							}
						}
						await UpdateProductMutation({
							variables: {
								id: product.id,
								object: {
									image_url: photoObj
								}
							},
						})
					}
					refetch()
				} else {
					showAlert({ message: "Unable to upload image!", type: "error" })
				}
			} else {
				showAlert({ message: "Unable to upload image!", type: "error" })
			}
		} catch (err) {
 			showAlert({ message: "Unable to upload image!", type: "error" })
		} finally {
			setLoadingBtns({ ...loadingBtns, [name]: false })
		}
	}
	useEffect(() => {
		if (Array.isArray(ProductDetails?.products)) {
			setProduct(ProductDetails.products[0])
		}
	}, [ProductDetails])
	return (
		<Container>
			<Row>
				<Col sm="4">
					{!product?.non_mrp && (
						<ProductImageComponent
							src={getImage(product, "main")}
							title="Master Product Image"
							layout="full"
							onImageSelect={(e) => handleUpdateProductImage(e, "master")}
							loading={loadingBtns.master}
							name="master"
							isEdit={capabilities.includes("edit")}
						/>
					)}
					<Row>
						<ProductImageComponent
							src={getImage(product, "front")}
							title="Front Image"
							layout="half"
							onImageSelect={(e) => handleUpdateProductImage(e, "front")}
							loading={loadingBtns.front}
							name="front"
							isEdit={capabilities.includes("edit")}
						/>
						<ProductImageComponent
							src={getImage(product, "back")}
							title="Back Image"
							layout="half"
							onImageSelect={(e) => handleUpdateProductImage(e, "back")}
							loading={loadingBtns.back}
							name="back"
							isEdit={capabilities.includes("edit")}
						/>
						<ProductImageComponent
							src={getImage(product, "left")}
							title="Left Image"
							layout="half"
							onImageSelect={(e) => handleUpdateProductImage(e, "left")}
							loading={loadingBtns.left}
							name="left"
							isEdit={capabilities.includes("edit")}
						/>
						<ProductImageComponent
							src={getImage(product, "right")}
							title="Right Image"
							layout="half"
							onImageSelect={(e) => handleUpdateProductImage(e, "right")}
							loading={loadingBtns.right}
							name="right"
							isEdit={capabilities.includes("edit")}
						/>
					</Row>
				</Col>
				<Col sm="8" className="pl-3">
					<div>
						<h3>{product.non_mrp ? product?.nonmrp_product_name : product?.master_product?.name} {(product.available_quantity < product.out_of_stock_alert) && (
							<Badge bg={"danger"} style={{ textTransform: "capitalize", marginRight: 5 }}>
								Out Of Stock
							</Badge>
						)}
							{product.non_mrp && (
								<>
									{product.approve ? (
										<Badge bg={"success"} style={{ textTransform: "capitalize" }}>
											Approved
										</Badge>
									) : (
										<Badge bg={"danger"} style={{ textTransform: "capitalize" }}>
											Not Approved
										</Badge>
									)}
								</>
							)}
						</h3>
					</div>
					<hr />
					<Row>
						<div className="stats-wrapper">
							<b>Product ID</b>
							<p>{product?.id}</p>
						</div>
						<div className="stats-wrapper">
							<b>Shop Type</b>
							<p>{product?.store?.store_type || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Store Name</b>
							<p>{product?.store?.name || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>SKU</b>
							<p>{product?.sku}</p>
						</div>
						<div className="stats-wrapper">
							<b>Stock</b>
							<p>{product.available_quantity}</p>
						</div>
						<div className="stats-wrapper">
							<b>Out of Stock Alert</b>
							<p>{product.out_of_stock_alert}</p>
						</div>
						<div className="stats-wrapper">
							<b>Price</b>
							<p>{formatPrice(product?.price)}</p>
						</div>
						<div className="stats-wrapper">
							<b>Weight</b>
							<p>{product.weight} {product.weight_unit}</p>
						</div>
						<div className="stats-wrapper">
							<b>Variation</b>
							<p>N/A</p>
						</div>
						<div className="stats-wrapper">
							<b>Brand</b>
							{product?.non_mrp ? <p>Unbranded</p> : (
								<p>{product?.master_product?.product_brand?.name || "N/A"}</p>
							)}
						</div>
						<div className="stats-wrapper">
							<b>Category</b>
							{product.non_mrp
								? <p>{product?.nonmrp_category?.name || "N/A"}</p>
								:
								<p>{product?.master_product?.category?.name || "N/A"}</p>}
						</div>
						<div className="stats-wrapper">
							<b>Sub-Category</b>
							{product.non_mrp
								? <p>{product?.nonmrp_subcategory?.name || "N/A"}</p>
								:
								<p>{product?.master_product?.subcategory?.name || "N/A"}</p>}
						</div>
						<div className="stats-wrapper">
							<b>Non-MRP</b>
							<p>{product?.non_mrp ? "Yes" : "No"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Supplier Name</b>
							<p>{product?.material_supplier?.name || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Supplier Contact</b>
							<p>{product?.material_supplier?.contact_no || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Country of Origin</b>
							<p>{product.country_origin}</p>
						</div>
						{product?.non_mrp && (
							<div className="stats-wrapper">
								<b>GST Bracket</b>
								<p>{product.store_product_tax?.tax?.name || "N/A"}</p>
							</div>
						)}
					</Row>
				</Col>
			</Row>
			{capabilities.includes("edit") && (
				<>
					{product?.non_mrp && (
						<Row>
							<Col xl="12" className="d-flex justify-content-end">
								<Button
									type="button"
									color={"info"}
									className="btn-lg  mb-2 me-2"
									onClick={() => setEditModalOpen(true)}
								>
									Edit
								</Button>
								<Button
									type="button"
									color={product.approve ? "danger" : "primary"}
									className="btn-lg  mb-2 me-2"
									onClick={handleChangeStatus}
								>
									{product.approve ? "Reject" : "Approve"}
								</Button>
							</Col>
						</Row>
					)}
				</>
			)}
			<EditProductModal
				open={editModalOpen}
				close={() => setEditModalOpen(false)}
				product={product}
				refetch={refetch}
				approveBtn={false}
			/>
		</Container>
	)
}
ProductOverview.propTypes = {
	setStores: PropTypes.func
}
ProductOverview.displayName = 'ProductOverview'
export default ProductOverview