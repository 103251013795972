import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import moment from "moment"
import { useRouteMatch, useHistory,Link } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { CheckPicker } from 'rsuite'
import Loader from "../../../components/Loader/Loader"
import { Dropdown } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'
//Helpers
import isValidUrl from "../../../helpers/url_checker"
//GraphQL
import { MerchantProductList } from "gql/query"
import { useQuery } from "@apollo/client"

const ServiceItems = (props) => {

 	
	const history = useHistory()
	const [products, setProducts] = useState([])
	const FormatData = (data) => {
 		const ServiceItems = data.map(elem => {
			return {
				 ...elem,
				  
			}
		})
		return ServiceItems
	}
	const columns = [
		{
			text: "Id",
			dataField: "uid",
			sort: true,
			// hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Status",
			dataField: "order_status",
			sort: true,
			// hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "ServiceName",
			dataField: "service_name",
			sort: true,
			// hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Service Price",
			dataField: "service_price",
			sort: true,
			// hidden: true,
			// eslint-disable-next-line react/display-name
		},
 
		{
			text: "Tax Amount",
			dataField: "tax_amount",
			sort: true,
			// hidden: true,
			// eslint-disable-next-line react/display-name
		},

		{
			text: "Total Price Amount",
			dataField: "total_price_amount",
			sort: true,
			// hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Disc. Amount",
			dataField: "discounted_amount",
			sort: true,
			// hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Price Includes Tax",
			dataField: "price_includes_tax",
			sort: true,
			formatter: (cellContent, row) => row?.price_includes_tax ? "True":"False" 
			 
		},
		{
			text: "Active",
			dataField: "active",
			sort: true,
			formatter: (cellContent, row) => row?.delete ? "True":"False" 
			 
		},

    
		// {
		// 	dataField: "view",
		// 	isDummyField: true,
		// 	text: "Action",
		// 	sort: false,
		// 	// eslint-disable-next-line react/display-name
		// 	formatter: (cellContent, row) => (
		// 	  <Dropdown>
		// 		<Dropdown.Toggle variant="transparent" id="dropdown-basic">
		// 		  <GoKebabVertical />
		// 		</Dropdown.Toggle>
		// 		<Dropdown.Menu>
		// 		  <Dropdown.Item onClick={() => history.push(`/ecommerce-products/detail/${row.product_id}`)}>View Details</Dropdown.Item>
		// 		</Dropdown.Menu>
		// 	  </Dropdown>
		// 	),
		//   },
	]
	const defaultSorted = []
	useEffect(() => {
		if (props.data) {
			setProducts(FormatData(props.data))
		}
	}, [props.data])
	
	return (
		<div className="p-3">
			<Row className="mb-5">
				<Row className="mb-2">
					<Col sm="8">
						{props.showLabel && (
							<h4>{props.label}</h4>
						)}
					</Col>
					<Col sm="4">
						<div className="text-sm-end d-flex justify-content-end">
							
						</div>
					</Col>
				</Row>
				<hr />
				<ToolkitProvider
					keyField="id"
					data={products || []}
					columns={columns}
					bootstrap4
				// search
				>
					{toolkitProps => (
						<>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										{false ? <Loader /> :
											<BootstrapTable
												responsive
												bordered={false}
												striped={false}
												defaultSorted={defaultSorted}
												classes={"table align-middle table-nowrap"}
												keyField="id"
												{...toolkitProps.baseProps}
												onTableChange={() => { }}
												noDataIndication={() => "No Products Found!"}
											// {...paginationTableProps}
											/>
										}
									</div>
								</Col>
							</Row>
						</>
					)}
				</ToolkitProvider>
			</Row>
		</div>
	)
}
ServiceItems.propTypes = {
	order: PropTypes.any,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	data: PropTypes.any,
}
ServiceItems.displayName = 'ServiceItems'
export default ServiceItems