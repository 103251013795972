import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withRouter, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "components/Loader/Loader"
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Highlighter from "react-highlight-words"

import EcommerceRequestDetailsModal from "../EcommerceRequestDetailsModal/EcommerceRequestDetailsModal"
//redux
import { useLazyQuery } from "@apollo/client"
import { GeneralRequestsList } from "gql/query"

//components

const FormatRequestData = (data) => {
	const generalRequests = data.general_requests.map(elem => {
		return {
			id: elem.id,
			description: elem?.description,
			created_at: elem.created_at,
			merchant_id: elem?.creator?.id,
			merchant_uid: elem?.creator?.vendor_company?.merchant_id,
			status: elem?.status
		}
	})
	return generalRequests
}

const EcommerceGeneralRequestsTable = props => {
	const [modal, setModal] = useState(false)
	const history = useHistory()
	const [products, setProducts] = useState([])
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({})
	const [filterValue, setFilterValue] = useState("")
	const [searchKey, setSearchKey] = useState("")
	const [selectedRequest, setSelectedRequest] = useState({})

	const [getGeneralRequestList, { loading, data, error, refetch }] = useLazyQuery(GeneralRequestsList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const handleSearch = (value) => {
		if (value.length >= 3) {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			conditionTemp = {
				...conditionTemp,
				_or: [
					{ creator: { vendor_company: { merchant_id: {_ilike: `%${value}%` }}}},
					{ description: {_ilike: `%${value}%` }}
				]
			}
			setQueryCondition(conditionTemp)
		} else {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			setQueryCondition(conditionTemp)
		}
	}
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}
	const toggle = () => {
		setModal(!modal)
	}
	useEffect(() => {
		if (data) {
			setProducts(FormatRequestData(data))
		}
	}, [data])
	const defaultSorted = []
	const count = data?.general_requests_aggregate?.aggregate?.count || 0
	const maxPage = Math.ceil(count / PageData.limit)

	const handleTableChange = (type, { page, searchText }) => {

	}
	const formatHighlighter = (text) => {
		let searchWords = [searchKey]
		return <Highlighter
			highlightClassName="YourHighlightClass"
			searchWords={searchWords}
			autoEscape={true}
			textToHighlight={String(text)}
			highlightStyle={{ background: "#ff0" }}
		/>
	}
	const EcommerceRequestsColumns = (toggleModal) => [
		{
			text: "ID",
			dataField: "id",
			sort: false,
            formatter: (cellContent, row) => (
				<p 
					style={{cursor: "pointer", color: "#1675e0"}}
					onClick={()=>{
						setSelectedRequest(row)
						setModal(true)
					}}
				>
					{cellContent}
				</p>
			)
		},
        {
			text: "Merchant ID",
			dataField: "merchant_uid",
			sort: true,
			formatter: (cellContent, row) => {
				if (row.merchant_id) {
					return <Link to={`/groceries/ecommerce-merchants/details/${row.merchant_id}`}>{formatHighlighter(cellContent)}</Link>
				} else {
					return formatHighlighter(cellContent)
				}
			}
		},
		{
			dataField: "description",
			text: "Description",
			sort: false,
			formatter: (cellContent, row) => { return <span style={{ display: 'block', width: 230, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{formatHighlighter(cellContent)}</span> }
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
		{
			dataField: "view",
			isDummyField: true,
			text: "Action",
			sort: false,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
				<Dropdown>
					<Dropdown.Toggle variant="transparent" id="dropdown-basic">
						<GoKebabVertical />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => {
							setSelectedRequest(row)
							setModal(true)
						}}>
							View Details
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			),
		},
	]
	//Apply filters
	useEffect(() => {
		if (Object.keys(filterValue).length) {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			if (filterValue?.hasOwnProperty("created_at")) {
				if (Array.isArray(filterValue.created_at)) {
					conditionTemp = {
						...conditionTemp,
						_and: [{ created_at: { _gte: filterValue.created_at[0] } }, { created_at: { _lte: filterValue.created_at[1] } }]
					}
				}
			}
			setQueryCondition(conditionTemp)
		} else {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			setQueryCondition(conditionTemp)
		}
	}, [filterValue])
	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			getGeneralRequestList()
		}
	}, [props.queryCondition])
	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getGeneralRequestList()
		}
	}, [queryCondition])
	useEffect(() => {
    if(searchKey.length > 0){
      handleSearch(searchKey)
    }else{
      refetch()
    }
  }, [searchKey])
	var node = useRef()
	return (
		<Row>
			<Col xs="12">
				<Card>
					<CardBody>
						<ToolkitProvider
							keyField="id"
							data={products || []}
							columns={EcommerceRequestsColumns(toggle)}
							bootstrap4
							search
						>
							{toolkitProps => (
								<React.Fragment>
									<Row className="mb-2">
										<Col sm="4">
											<div className="search-box ms-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Form onSubmit={(e)=>{
                              e.preventDefault()
                            }}>
														<Form.Group className="mb-3" controlId="formBasicEmail">
															<i className="bx bx-search-alt search-icon" />
															<Form.Control 
																type="text" 
																placeholder="Search Requests" 
																onChange={(e) => setSearchKey(e.target.value)} 
															/>
														</Form.Group>
													</Form>
												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
												
											</div>
										</Col>
									</Row>
									<Row>
										<Col xl="12">
											<b>No. of General Requests:</b> {count}
											<div className="mb-3" />
											<div className="table-responsive">
												{loading ? <Loader /> :
													<BootstrapTable
														responsive
														bordered={false}
														striped={false}
														defaultSorted={defaultSorted}
														classes={"table align-middle table-nowrap"}
														keyField="id"
														{...toolkitProps.baseProps}
														onTableChange={handleTableChange}
														ref={node}
														noDataIndication={() => "No Requests Found!"}
														key={`${searchKey}_${JSON.stringify(filterValue)}`}
													/>
												}
											</div>
										</Col>
									</Row>
									<Row className="align-items-md-center mt-40">
										<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
											<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
												Previous Page
											</Button>
											<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
											</div>
											<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
												Next Page
											</Button>
										</Col>
									</Row>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</CardBody>
				</Card>
			</Col>
			<EcommerceRequestDetailsModal
				open={modal}
				close={()=>setModal(false)} 
				request={selectedRequest}
			/>
		</Row>
	)
}

EcommerceGeneralRequestsTable.propTypes = {
	queryCondition: PropTypes.any,
}

export default withRouter(EcommerceGeneralRequestsTable)
