import axios from "axios"
export const handleUploadFile = async (image, multiple = false) => {
    return new Promise(async (resolve, reject) => {
        try {
            let formData = new FormData()
            if(multiple){
                image.map(_img => {
                    formData.append("uploads", _img)
                })
            }else{
                formData.append("uploads", image[0])
            }
            const resp = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`, formData)
            if (resp.data?.success) {
                if (Array.isArray(resp.data?.data?.uploaded_files)) {
                    if(multiple){
                        resolve(resp.data.data.uploaded_files.map(_file => _file.uploaded_file_url))
                    }else{
                        const link = resp.data.data.uploaded_files[0].uploaded_file_url
                        resolve(link)
                    }
                    
                }
            } else {
                resolve(null)
            }
        } catch (err) {
            resolve(null)
        }
    })
}