import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import moment from "moment"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
import { Dropdown } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'

const RentalVehicles = (props) => {
	const [services, setServices] = useState([])
	const FormatData = (data) => {
		const productsTemp = data.map(elem => {
            
			return {
                id: elem.id,
                rental_type: elem.rental_type,
                /*vehicle_name: elem?.vehicle.vehicle_name,
                vehicle_no: elem?.vehicle.vehicle_no,
                driver_type: elem?.driver_type,
                trip_from: elem?.trip_from,
                trip_to: elem?.trip_to,
                total_price_amount: elem.total_price_amount*/
			}
		})
        
		return productsTemp
	}
	const productsColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
        {
			text: "Rental Type",
			dataField: "rental_type",
			sort: true,
		},
		/*{
			text: "Vehicle Name",
			dataField: "vehicle_name",
			sort: true,
		},
        /*{
			text: "Vehicle Photo",
			dataField: "vehicle_name",
			sort: true,
		},*/
		/*{
			text: "Vehicle No",
            dataField: "vehicle_no",
			sort: true,
		},
        {
			text: "Driver Name",
            dataField: "driver_type",
			sort: true,
		},
        /*{
			text: "Driver Phone",
            dataField: "driver_type",
			sort: true,
		},
        {
			text: "driver_type",
			dataField: "driver_type",
			sort: true,
		},*/
		/*{
			text: "Start Date",
            dataField: "trip_from",
			sort: true,
		},
        {
			text: "End Date",
            dataField: "trip_to",
			sort: true,
		},
		/*{
			text: "Total Gross Price",
            dataField: "total_price_amount",
			sort: true,
			formatter: (cellContent, row) => `₹${row.total_price_amount}`,
		},
		{
			dataField: "view",
			isDummyField: true,
			text: "Action",
			sort: false,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
			  <Dropdown>
				<Dropdown.Toggle variant="transparent" id="dropdown-basic">
				  <GoKebabVertical />
				</Dropdown.Toggle>
				<Dropdown.Menu>
				  <Dropdown.Item>View Details</Dropdown.Item>
				</Dropdown.Menu>
			  </Dropdown>
			),
		  },*/
	]
    
	const defaultSorted = []
	useEffect(() => {
        
		if (props.order?.rental_order_items?.length) {
            
            
			setServices(FormatData(props.order.rental_order_items))
		}
	}, [props.order])
	
	return (
    <div className="p-3">
      <Row className="mb-5">
        <Row className="mb-2">
          <Col sm="8">{props.showLabel && <h4>{props.label}</h4>}</Col>
          <Col sm="4">
            <div className="text-sm-end d-flex justify-content-end"></div>
          </Col>
        </Row>
        <hr />
        <ToolkitProvider
          keyField="id"
          data={services || []}
          columns={productsColumns}
          bootstrap4
          // search
        >
          {(toolkitProps) => (
            <>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    {false ? (
                      <Loader />
                    ) : (
                      <BootstrapTable
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        keyField="id"
                        {...toolkitProps.baseProps}
                        onTableChange={() => {}}
                        noDataIndication={() => "No Products Found!"}
                        // {...paginationTableProps}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ToolkitProvider>
      </Row>
    </div>
  )
}
RentalVehicles.propTypes = {
	order: PropTypes.any,
	showLabel: PropTypes.bool,
	label: PropTypes.string
}
RentalVehicles.displayName = 'RentalVehicles'
export default RentalVehicles