import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import VehicleMasterDataList from "./vehicle-master-data-list"
//import "../../../../assets/scss/custom.scss"

const VehicleMasterData = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Vehicle Master Data | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Rentals" breadcrumbItem="Vehicle Master Data" />
              <VehicleMasterDataList queryCondition={null} activeFilters={["make", "model"]} filterQueryConditions={null} showCreateBtn={true} />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}

VehicleMasterData.propTypes = {}
export default withRouter(VehicleMasterData)
