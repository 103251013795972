import React from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"
//import components
import EcommerceOrdersTable from "../EcommerceOrders/EcommerceOrdersTable"
const ShopOrders = (props) => {
    const match = useRouteMatch()
    return (
        <div className="p-3">
            <EcommerceOrdersTable
                queryCondition={{ store_id: { _eq: match?.params?.id } }}
                activeFilters={["status", "created_at", "city", "total_amount", "delivery_charges"]}
                filterQueryConditions={null}
                showFilters={true}
            />
        </div>
    )
}
ShopOrders.propTypes = {
    setActiveTab: PropTypes.func,
}
ShopOrders.displayName = 'ShopOrders'
export default ShopOrders