import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withRouter, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "components/Loader/Loader"
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'
import isValidUrl from "helpers/url_checker"
import Swal from "sweetalert2"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import Highlighter from "react-highlight-words"

//redux
import { useMutation, useLazyQuery } from "@apollo/client"
import { ProductRequestsList } from "gql/query"
import { UpdateProduct } from "gql/Mutation"

//filters
import EcommerceProductsFilter from "components/Filters/Ecommerce/EcommerceProductsFilter"

//components

const FormatProductData = (data) => {
	const productRequests = data.product_requests.map(elem => {
		let image = "/placeholder.jpg"
		let imageCount = 0
		if (typeof elem.photo_urls === "object") {
			if (Array.isArray(elem.photo_urls?.image)) {
				elem.photo_urls.image.map(obj => {
					if (isValidUrl(obj?.front)) {
						image = obj.front
					} else if (isValidUrl(obj?.back)) {
						image = obj.back
					}
				})
			} else if (elem.photo_urls?.front) {
				image = elem.photo_urls?.front
			}
		} else if (isValidUrl(elem.photo_urls)) {
			image = elem.photo_urls
		}
		if (Array.isArray(elem.photo_urls?.image)) {
			imageCount = elem.photo_urls?.image?.length
		}
		return {
			id: elem.id,
			image,
			imageCount,
			name: elem?.name,
			stock: elem.available_quantity,
			price: elem.price,
			created_at: elem.created_at,
			weight: elem.weight,
			weight_unit: elem.weight_unit,
			store_name: elem?.store?.name,
			store_id: elem?.store?.id,
			store_type: elem?.store?.store_type,
			brand_name: elem?.brand,
			category_id: elem?.category?.id,
			category_name: elem?.category?.name || "N/A",
			subcategory_id: elem?.subcategory?.id,
			subcategory_name: elem?.subcategory?.name || "N/A",
			out_of_stock_alert: elem?.out_of_stock_alert,
			material_supplier_name: elem?.supplier_name || "N/A",
			material_supplier_contact: elem?.supplier_contact || "N/A",
			merchant_id: elem?.creator?.id,
			merchant_uid: elem?.creator?.vendor_company?.merchant_id,
			status: elem?.status
		}
	})
	return productRequests
}

const EcommerceProductRequestsTable = props => {
	const [UpdateProductMutation] = useMutation(UpdateProduct)
	const [modal, setModal] = useState(false)
	const history = useHistory()
	const [products, setProducts] = useState([])
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({})
	const [filterValue, setFilterValue] = useState("")
	const [searchKey, setSearchKey] = useState("")

	const [getProductRequestList, { loading, data, error, refetch }] = useLazyQuery(ProductRequestsList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const handleSearch = (value) => {
		if (value.length >= 3) {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			conditionTemp = {
				...conditionTemp,
				_or: [
					{ name: { _ilike: `%${value}%` } },
					{ store: { name: { _ilike: `%${value}%` } } },
					{ store: { store_type: { _ilike: `%${value}%` } } },
					{ brand: { _ilike: `%${value}%` } },
					{ category: { name: { _ilike: `%${value}%` } } },
					{ subcategory: { name: { _ilike: `%${value}%` } } },
					{ supplier_name: { _ilike: `%${value}%` } },
					{ supplier_contact: { _ilike: `%${value}%` } },
					{ creator: { vendor_company: { merchant_id: { _ilike: `%${value}%` } } } },
				]
			}
			setQueryCondition(conditionTemp)
		} else {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			setQueryCondition(conditionTemp)
		}
	}
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}
	const toggle = () => {
		setModal(!modal)
	}
	useEffect(() => {
		if (data) {
			setProducts(FormatProductData(data))
		}
	}, [data])
	const defaultSorted = []
	const count = data?.product_requests_aggregate?.aggregate?.count || 0
	const maxPage = Math.ceil(count / PageData.limit)

	const handleTableChange = (type, { page, searchText }) => {

	}
	const handleChangeStatus = async (product) => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					await UpdateProductMutation({
						variables: {
							id: product.id,
							object: {
								approve: !product.approve
							}
						},
					})
					refetch()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	const formatHighlighter = (text) => {
		let searchWords = [searchKey]
		if (Array.isArray(filterValue?.store_type)) {
			searchWords = [...searchWords, ...filterValue?.store_type]
		}
		return <Highlighter
			highlightClassName="YourHighlightClass"
			searchWords={searchWords}
			autoEscape={true}
			textToHighlight={String(text)}
			highlightStyle={{ background: "#ff0" }}
		/>
	}
	const EcommerceProductColumns = (toggleModal) => [
		{
			text: "ID",
			dataField: "id",
			sort: false,
			formatter: (cellContent, row) => <Link to={`/groceries/ecommerce-requests/details/${cellContent}`}>{cellContent}</Link>
		},
		{
			text: "Store",
			dataField: "store_name",
			sort: true,
			formatter: (cellContent, row) => {
				if (row.store_id) {
					return <Link to={`/groceries/ecommerce-shops/details/${row.store_id}`}>{formatHighlighter(row.store_name)}</Link>
				} else {
					return formatHighlighter(row.store_name)
				}
			}
		},
		{
			text: "Store Type",
			dataField: "store_type",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		{
			text: "Merchant ID",
			dataField: "merchant_uid",
			sort: true,
			formatter: (cellContent, row) => {
				if (row.merchant_id) {
					return <Link to={`/groceries/ecommerce-merchants/details/${row.merchant_id}`}>{formatHighlighter(cellContent)}</Link>
				} else {
					return formatHighlighter(cellContent)
				}
			}
		},
		{
			dataField: "image",
			text: "Image",
			sort: false,
			formatter: (cellContent, row) => <div style={{ position: "relative" }}>
				<img src={row.image} style={{ height: 50 }} />
				{row.imageCount && (
					<b className="image-count-tag">+{row.imageCount}</b>
				)}
			</div>,
		},
		{
			dataField: "name",
			text: "Product Name",
			sort: true,
			formatter: (col, row) => { return <span style={{ display: 'block', width: 230, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{row.non_mrp ? formatHighlighter(row.nonmrp_product_name) : formatHighlighter(col)}</span> }
		},
		{
			text: "Stock",
			dataField: "stock",
			sort: true,
		},
		{
			text: "Out Of Stock",
			dataField: "out_of_stock_alert",
			sort: false,
			formatter: (cellContent, row) => row.stock <= row.out_of_stock_alert ? "Yes" : "No",
		},
		{
			dataField: "price",
			text: "Price",
			sort: true,
			formatter: (cellContent, row) => `₹${row.price}`,
		},
		{
			text: "Volume",
			dataField: "weight",
			sort: true,
		},
		{
			text: "Unit",
			dataField: "weight_unit",
			sort: true,
		},
		{
			dataField: "brand_name",
			text: "Brand",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		{
			dataField: "category_name",
			text: "Category",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		{
			dataField: "subcategory_name",
			text: "Sub-Category",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		{
			dataField: "status",
			text: "Approval Status",
			sort: false,
			formatter: (cellContent, row) => <p style={{ color: cellContent === "approved" ? "" : "#F93B55" }}>{cellContent === "approved" ? "Approved" : "Not Approved"}</p>,
		},
		{
			text: "Supplier Name",
			dataField: "material_supplier_name",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		{
			text: "Supplier Contact",
			dataField: "material_supplier_contact",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
		{
			dataField: "view",
			isDummyField: true,
			text: "Action",
			sort: false,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
				<Dropdown>
					<Dropdown.Toggle variant="transparent" id="dropdown-basic">
						<GoKebabVertical />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => history.push(`ecommerce-requests/details/${row.id}`)}>
							View Details
						</Dropdown.Item>
						{/* <Dropdown.Item onClick={() => {
								if(row.approve){
										handleChangeStatus(row.product)
								}else{
										setSelectedProduct(row.product)
										setEditModalOpen(true)
								}
						}}>
								{row.status === "verified" ? "Reject" : "Approve"}
						</Dropdown.Item> */}
					</Dropdown.Menu>
				</Dropdown>
			),
		},
	]
	//Apply filters
	useEffect(() => {
		if (Object.keys(filterValue).length) {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			// if (filterValue?.hasOwnProperty("product_brand")) {
			// 	if (filterValue.product_brand?.length > 0) {
			// 		conditionTemp = {
			// 			...conditionTemp,
			// 			master_product: { product_brand: { id: { _in: filterValue.product_brand } } }
			// 		}
			// 	}
			// }
			if (filterValue?.hasOwnProperty("category")) {
				if (filterValue.category?.length > 0) {
					conditionTemp = {
						...conditionTemp,
						category: { id: { _in: filterValue.category } }
					}
				}
			}
			if (filterValue?.hasOwnProperty("subcategory")) {
				if (filterValue.subcategory?.length > 0) {
					conditionTemp = {
						...conditionTemp,
						subcategory: { id: { _in: filterValue.subcategory } }
					}
				}
			}
			if (filterValue?.hasOwnProperty("store")) {
				if (filterValue.store?.length > 0) {
					conditionTemp = {
						...conditionTemp,
						store: { id: { _in: filterValue.store } }
					}
				}
			}
			if (filterValue?.hasOwnProperty("store_type")) {
				if (filterValue.store_type?.length > 0) {
					conditionTemp = {
						...conditionTemp,
						store: { store_type: { _in: filterValue.store_type } }
					}
				}
			}
			if (filterValue?.hasOwnProperty("created_at")) {
				if (Array.isArray(filterValue.created_at)) {
					conditionTemp = {
						...conditionTemp,
						_and: [{ created_at: { _gte: filterValue.created_at[0] } }, { created_at: { _lte: filterValue.created_at[1] } }]
					}
				}
			}
			setQueryCondition(conditionTemp)
		} else {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			setQueryCondition(conditionTemp)
		}
	}, [filterValue])
	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			getProductRequestList()
		}
	}, [props.queryCondition])
	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getProductRequestList()
		}
	}, [queryCondition])
	useEffect(() => {
    if(searchKey.length > 0){
      handleSearch(searchKey)
    }else{
      refetch()
    }
  }, [searchKey])
	var node = useRef()
	return (
		<Row>
			<Col xs="12">
				<Card>
					<CardBody>
						<ToolkitProvider
							keyField="id"
							data={products || []}
							columns={EcommerceProductColumns(toggle)}
							bootstrap4
							search
						>
							{toolkitProps => (
								<React.Fragment>
									<Row className="mb-2">
										<Col sm="4">
											<div className="search-box ms-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Form onSubmit={(e) => {
														e.preventDefault()
													}}>
														<Form.Group className="mb-3" controlId="formBasicEmail">
															<i className="bx bx-search-alt search-icon" />
															<Form.Control
																type="text"
																placeholder="Search Requests"
																onChange={(e) => setSearchKey(e.target.value)}
															/>
														</Form.Group>
													</Form>

												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
												<EcommerceProductsFilter
													onFilterChange={setFilterValue}
													activeFilters={["product_brand", "category", "subcategory", "store", "store_type", "created_at"]}
													filterQueryConditions={null}
												/>
											</div>
										</Col>
									</Row>
									<Row>
										<Col xl="12">
											<b>No. of Product Requests:</b> {count}
											<div className="mb-3" />
											<div className="table-responsive">
												{loading ? <Loader /> :
													<BootstrapTable
														responsive
														bordered={false}
														striped={false}
														defaultSorted={defaultSorted}
														classes={"table align-middle table-nowrap"}
														keyField="id"
														{...toolkitProps.baseProps}
														onTableChange={handleTableChange}
														ref={node}
														noDataIndication={() => "No Products Found!"}
														key={`${searchKey}_${JSON.stringify(filterValue)}`}
													/>
												}
											</div>
										</Col>
									</Row>
									<Row className="align-items-md-center mt-40">
										<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
											<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
												Previous Page
											</Button>
											<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
											</div>
											<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
												Next Page
											</Button>
										</Col>
									</Row>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</CardBody>
				</Card>
			</Col>
		</Row>
	)
}

EcommerceProductRequestsTable.propTypes = {
	queryCondition: PropTypes.any,
}

export default withRouter(EcommerceProductRequestsTable)
