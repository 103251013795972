import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommercePayoutsTable from "../EcommercePayouts/EcommercePayoutsTable"

const MerchantPayouts = (props) => {
    const match = useRouteMatch()
    const [queryCondition, setQueryCondition] = useState({})
    useEffect(() => {
        if (props.stores?.length) {
            setQueryCondition({ merchant_id: { _eq: match?.params?.id }, status: {
                _neq: "PENDING"
            } })
        }
    }, [props.stores])
    return (
        <div className="p-3">
            <EcommercePayoutsTable
                queryCondition={queryCondition}
                activeFilters={["status", "store", "created_at"]}
                filterQueryConditions={[{filter: "store", condition: {id: {_in: props.stores?.map(store => store.id)}}}]}
                origin="merchant_details"
            />
        </div>
    )
}
MerchantPayouts.propTypes = {
    stores: PropTypes.array,
    setSelectedStores: PropTypes.func,
    setActiveTab: PropTypes.func,
    selectedStores: PropTypes.any
}
MerchantPayouts.displayName = 'MerchantPayouts'
export default MerchantPayouts