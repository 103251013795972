import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import RentalAddonsList from "./vehicle-rentals-addons-list"
//import "../../../../assets/scss/custom.scss"

const RentalAddons = props => {

  console.log("rentalapropsddons",props)
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Rental Addons| EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Rentals" breadcrumbItem="Rental Addons" />
              <RentalAddonsList queryCondition={null} activeFilters={["type_name"]} filterQueryConditions={null} showCreateBtn={true} />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}

RentalAddons.propTypes = {}
export default withRouter(RentalAddons)
