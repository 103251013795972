import React, { useEffect, useState } from 'react'
import { Modal, Toggle } from "rsuite"
import { withRouter } from "react-router-dom"
import { Insert_Vehicle_Brand_One, Insert_vehicle_master_data_One, Update_Vehicle_Brand_One, Update_vehicle_master_data_One } from "gql/Mutation"
import { Button, Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap"
import { SelectPicker } from "rsuite"
import FileUploader from "components/Common/FileUploader"
import axios from "axios"
import PropTypes from "prop-types"
import Swal from "sweetalert2"
import { useLazyQuery, useMutation } from '@apollo/client'
import { VehicleMasterDataEnumQuery, VehicleMasterDataQuery } from 'gql/query'
import { createHeaders } from './columns'

const VehicleBrandsCreate = (props) => {
    const [btnLoading, setBtnLoading] = useState(false)
    const [image, setImage] = useState(null)
    const [vehicleGroupValue, setVehicleGroupValue] = useState('')
    const [name, setName] = useState("")
    const [isActive, setActive] = useState(false)
    const [CreateVehicleMasterDataMutation] = useMutation(Insert_vehicle_master_data_One)
    const [UpdateVehicleMasterDataMutation] = useMutation(Update_vehicle_master_data_One)
    const [ queryCondition, setQueryCondition] = useState([])
    const [masterData, setMasterData] = useState({})
 
   
 

    const [getVehicleMasterDataList, { loading, data, refetch, error }] = useLazyQuery(
    VehicleMasterDataQuery,
    {
        skipCache: true,
        variables: { 
            limit: 1,
            // offset: (PageData.pageNo - 1) * PageData.limit, 
            where: { id : {_eq:props?.masterData?.id }}
        }  
    })

    const [getVehicleMasterDataEnumList, { loading:EnumLoading, data:EnumData, refetch:enumRefetch, error:Enumerror }] = useLazyQuery(
        VehicleMasterDataEnumQuery,
        {
            skipCache: true,
        })

     useEffect(() => {
        if(!queryCondition)   setQueryCondition(props?.masterData?.id)
        else getVehicleMasterDataList()
        getVehicleMasterDataEnumList()
    }, [props?.masterData?.id,queryCondition])



 
 
  
   //If rentals data exists, merge values
   useEffect(()=>{
 
    //Add vlaues to the all columns
    if(data?.rental_master_vehicles?.length>0){

        let rentals_data = data?.rental_master_vehicles?.[0]
 
        Object.keys(createHeaders)?.map((key,i)=>{

          //If it is an array of columns 
          if(key&&rentals_data?.[key]&&Object.keys(rentals_data?.[key])?.length>1&&Array.isArray(createHeaders?.[key])){

          createHeaders[key]?.forEach((e,index)=>{

             createHeaders[key][index] = {...e,...{value:rentals_data[key][e.name]}}

              })
          }

          //If it is an object of column
          if(!Array.isArray(rentals_data[key])&&!Array.isArray(createHeaders[key])){

 
             createHeaders[key]= {...createHeaders[key],value:rentals_data[key]}

          }




        })


    }
 
    setMasterData({...createHeaders})

   },[data?.rental_master_vehicles?.length])



 
    //Capitalize the labels
    const camalize = function camalize(str) {
        return str?.toLowerCase()?.replace(/[^a-zA-Z0-9]+(.)/g, function(match, chr)
        {
            return chr?.toUpperCase()
        })

    }

    //Open new tab if clicks on an image
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

     
    //Submit or update the master data
    const handleCreateBrand = async (event) => {
 
        event.preventDefault()
 
         let newObj = {}

         Object.keys(masterData)?.map((e,i) => {

            masterData?.[e]?.length>0 ? 
            
            [...masterData[e]]?.filter(ee => ee)?.map((ei, ii) => {

                   newObj[e] =  {...newObj?.[e],[ei?.name]:ei?.value}
             
            })

            :  

            newObj[e] =  masterData?.[e]?.value
         })
 
 
        try {
             setBtnLoading(true)
 
            if (props?.masterData?.id) {
                
                
                await UpdateVehicleMasterDataMutation({
                    variables: {
                        id:props?.masterData?.id,
                        object: newObj,
                    },
                })
            } else {
                await CreateVehicleMasterDataMutation({
                    variables: {
                        object:   newObj,
                    },
                })
            }
            Swal.fire(
                `Vehcile  ${props?.masterData?.id ? "Updated" : "Created"} Successfully`,
                "",
                "success"
            ).then(() => {
                props.onSubmit()
                props.close()
            })
        } catch (err) {
            Swal.fire("Something went wrong", "", "error")
        } finally {
            setBtnLoading(false)
        }
    }
 


    //Update the state on onchange listeners
    const checkBox = (e) => { 
      
         const parent = e?.target?.dataset?.parent
         const index = e?.target?.dataset?.index
         const name = e?.target?.dataset?.name
         const value = e?.target?.value
         const element = e?.target
         const type = e?.target?.dataset?.type
         const single = e?.target?.dataset?.single

 
         let createHeaders= {}
         if(element?.type === "text"){
 
            createHeaders = {...masterData}
            createHeaders[parent][index] = {...createHeaders[parent][index], "value":value}
             setMasterData(createHeaders)
         }
         if(type==="boolean"){
 
            createHeaders = {...masterData}
            createHeaders[parent][index] = {...createHeaders[parent][index], "value":String(value)}
             setMasterData(createHeaders)
         }
         if(type==="enum_select"){
    
               createHeaders = {...masterData}
               createHeaders[parent] = {...createHeaders[parent], "value":String(value)}
                setMasterData(createHeaders)
            }

         if(type==="img"){
            const form_data = new FormData()

            Object.entries(e?.target?.files).map(([, value]) => form_data.append("uploads", value))
            axios
            .post(
                `${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`,
                form_data,
            )
            .then((result) => {
                
                if (result?.data?.data?.uploaded_files?.length>0) {

                    if(!single){

                        createHeaders = {...masterData}
                        createHeaders[parent][index] = {...createHeaders[parent][index], "value":result?.data?.data?.uploaded_files?.[0]?.uploaded_file_url}
                         setMasterData(createHeaders)
                    }

                    if(single){
                        createHeaders = {...masterData}
                        createHeaders[parent] = {...createHeaders[parent], "value":result?.data?.data?.uploaded_files?.[0]?.uploaded_file_url}
                         setMasterData(createHeaders)
                    }


                } 
            })
            .catch((error) => console.log("error", error))
           
         }
    
 
      }
 
 
 
      //Build the fields
      const Fields = (naming, createHeaders,ind) =>{



        let title = createHeaders?.name?.replaceAll("_"," ") 

            title = camalize(title)?.charAt(0).toUpperCase() + title?.slice(1)

           switch (createHeaders?.type) {

                        case "img":
                            return <Col sm={createHeaders?.span}> 
                                        <div className="mb-3">
                                        <Label htmlFor={createHeaders?.name}>{title}</Label>
                                       
                                      <input type="file" 
                                            data-index={ind}
                                            data-parent={naming}
                                            data-type="img"
                                            data-name={createHeaders?.name}
                                            name={createHeaders?.value} 
                                            id={createHeaders?.name}
                                       
                                           />
                                           {createHeaders?.value ? <img
                                                alt="no image"
                                                className="absolute inset-0 w-full h-full object-cover object-center"
                                                src={createHeaders?.value}
                                                onClick={() => { openInNewTab(createHeaders?.value) }}
                                                style={{width:"30px",cursor: "pointer"}}
                                            /> : null}
                                        </div>
                                </Col>


                
 

                        case "boolean":
                            return <Col sm={createHeaders?.span}> 
                                    <div className="mb-3" >
                                        <Label htmlFor={createHeaders?.name}>{title}</Label>
                                        <br/>
                                        <select className="form-select" 
                                                type="boolean"
                                                data-index={ind}
                                                data-parent={naming}
                                                data-type="boolean"
                                                data-name={createHeaders?.name}
                                                name={createHeaders?.value} 
                                                id={createHeaders?.name}
                                                value={createHeaders?.value}
                                                aria-label="Default select example">
                                            <option value="null">N/A</option>
                                            <option value="Yes">YES</option>
                                            <option value="No">NO</option>
                                        </select>                       
                                    </div>
                                </Col>
                                        
                                    case "select":
                                        return <Col sm={createHeaders?.span}> 
                                                <div className="mb-3" >
                                                    <Label htmlFor={createHeaders?.name}>{title}</Label>
                                                    <br/>
                                                    <select className="form-select" 
                                                            type="select"
                                                            data-index={ind}
                                                            data-parent={naming}
                                                            data-type="enum_select"
                                                            data-name={createHeaders?.name}
                                                            name={createHeaders?.value} 
                                                            id={createHeaders?.name}
                                                            value={createHeaders?.value}
                                                            aria-label="Default select example">
                                                                {  (data?.[naming] || EnumData?.[naming]) ? 
                                                                    [...data?.[naming] || EnumData?.[naming]] ?.map((element,i)=>{
                                                                         return  <option key={i} value={element?.value}>{element?.comments}</option>
                                                                    }) : null
                                                                }
                                                    </select>                       
                                                </div>
                                            </Col>
                        default:
                            return   <Col sm={createHeaders?.span}> 
                                        <div className="mb-3" >
                                            <Label htmlFor={createHeaders?.name}>{title}</Label>
                                            <input 
                                            type="text"
                                            data-index={ind}
                                            data-parent={naming}
                                            data-name={createHeaders?.name}
                                            className="form-control" 
                                            id={createHeaders?.name} 
                                            defaultValue={createHeaders?.value}/>
                                        </div>
                                    </Col>
    
                    }
          }
      
 
      return (
        <Modal open={props.open} onClose={() => props.close()} style={{width:"100vw", margin:"0 auto"}}>
            <Modal.Header>
                <Modal.Title  className="mb-2">
                    {props.brand?.id ? "UPDATE VEHICLE" : "CREATE VEHICLE"}
                </Modal.Title>
            </Modal.Header>
            <nav className="nav">
            <form onSubmit={handleCreateBrand} >
            <div className="form-group" onChange={checkBox}>
           {
            Object.keys(masterData)?.map((e,i) => {

             
            return  <div key={i}> 
                     
                    <Row>
 
                        {!Array.isArray(masterData?.[e]) ?
                        <>
                            <h5 className="mb-2"> { camalize(e?.replaceAll("_"," "))?.charAt(0).toUpperCase() + e?.replaceAll("_"," ")?.slice(1) } </h5>
                           { Fields(e,masterData?.[e],null) }
                        </>
                           : 
                        <>
                            <h5  className="mb-2"> { camalize(e?.replaceAll("_"," "))?.charAt(0).toUpperCase() + e?.replaceAll("_"," ")?.slice(1)} </h5>
                            {[...masterData[e]]?.filter(ee => ee)?.map((ei, i) => Fields(e,ei,i))}
                        </>
                
                        }
                    </Row>
                </div>
            })
          }
        </div>
         <button type="submit">submit</button>

         </form>
         </nav>
        </Modal>
    )
}

VehicleBrandsCreate.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    brand: PropTypes.any,
    onSubmit: PropTypes.func,
    masterData: PropTypes.any
}
export default withRouter(VehicleBrandsCreate)