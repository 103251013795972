import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
import { useRouteMatch } from "react-router-dom"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
import PromotionOverview from "./PromotionOverview"
import PromotionCategories from "./PromotionCategories"
import PromotionSubcategories from "./PromotionSubcategories"
import PromotionBrands from "./PromotionBrands"
import PromotionProducts from "./PromotionProducts"
import PromotionCustomers from "./PromotionCustomers"

//Import custom styles
import "./EcommercePromotionDetails.style.scss"

//GraphQL
import { GetPromotionDetails } from "gql/query"
import { useQuery } from "@apollo/client"

const EcommercePromotionServicesDetails = (props) => {
  const match = useRouteMatch()
  const [activeTab, setActiveTab] = useState("overview")
  const [promotion, setPromotion] = useState({})
  // const pathName = props.location.pathname

  const {
    data: promotionsDetails,
    loading,
    error,
    refetch,
  } = useQuery(GetPromotionDetails, {
    skipCache: true,
    variables: {
      id: match?.params?.id,
    },
  })
  useEffect(() => {
    if (promotionsDetails?.promotions?.length > 0) {
      setPromotion(promotionsDetails.promotions[0])
    }
  }, [promotionsDetails])
   return (
    <div className="EcommercePromotionDetails">
      <div className="page-content">
        <MetaTags>
          <title>
            Promotion Details | EEFind - React Admin &amp; Dashboard Template
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Promotions" breadcrumbItem="Promotion Details" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs
                    activeKey={activeTab}
                    onSelect={setActiveTab}
                    className="mb-3"
                  >
                    <Tab eventKey="overview" title="Overview">
                      <PromotionOverview promotion={promotion} />
                    </Tab>
                    <Tab eventKey="categories" title="Services">
                      <PromotionCategories promotion={promotion} />
                    </Tab>
                    <Tab eventKey="subcategories" title="Sub Categories">
                      <PromotionSubcategories promotion={promotion} />
                    </Tab>
                    {/* <Tab eventKey="brands" title="Brands">
                      <PromotionBrands promotion={promotion} />
                    </Tab>
                    <Tab eventKey="products" title="Products">
                      <PromotionProducts promotion={promotion} />
                    </Tab> */}
                    <Tab eventKey="customers" title="Customers">
                      <PromotionCustomers promotion={promotion} />
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommercePromotionServicesDetails.propTypes = {
  // location: PropTypes.object,
}
export default EcommercePromotionServicesDetails