import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker } from 'rsuite'
import { useState } from "react"
import PropTypes from "prop-types"
import CheckPickerPaginate from "../CheckPickerPaginate"
import { CustomerCityFilter } from "gql/query"
const EcommerceCustomerFilter = (props) => {
	const [filterData, setFilterData] = useState([])
	const genderData = [{ label: "Male", value: "male" }, { label: "Female", value: "female" }, { label: "Other", value: "other" }]
	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
	}
	useEffect(() => {
		props.onFilterChange(filterValues)
	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			let filterValuesTemp = { ...filterValues }
			if (!filters.includes("gender")) {
				delete filterValuesTemp["gender"]
			}
			if (!filters.includes("created_at")) {
				delete filterValuesTemp["created_at"]
			}
			if (!filters.includes("city")) {
				delete filterValuesTemp["city"]
			}
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])
	useEffect(() => {
		let filterDataTemp = []
		props.activeFilters.map((filter) => {
			if (filter === "gender") {
				filterDataTemp.push({ label: "Gender", value: "gender" })
			}
			if (filter === "created_at") {
				filterDataTemp.push({ label: "Created At", value: "created_at" })
			}
			if (filter === "city") {
				filterDataTemp.push({ label: "City", value: "city" })
			}
		})
		setFilterData(filterDataTemp)
	}, [props.activeFilters])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "gender") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={genderData}
								placeholder={"Select Gender"}
								style={{ width: 130, marginLeft: 10 }}
								onChange={(gender) => setFilterValues({ ...filterValues, gender })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("gender")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "created_at") {
					return (
						<React.Fragment key={i}>
							<DateRangePicker
								placement="auto"
								onChange={(created_at) => setFilterValues({ ...filterValues, created_at })}
								style={{ marginLeft: 10 }}
								placeholder="Select Joining Date"
								value={Array.isArray(filterValues.created_at) ? filterValues.created_at : []}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_at")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "city") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={CustomerCityFilter}
								placeholder="Select City"
								value={Array.isArray(filterValues.city) ? filterValues.city : []}
								onChange={(city) => setFilterValues({ ...filterValues, city })}
								arrKey="customer_addresses"
								objectLabel="city"
								objectValue="city"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={null}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("city")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
EcommerceCustomerFilter.propTypes = {
	onFilterChange: PropTypes.func,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any
}
export default EcommerceCustomerFilter