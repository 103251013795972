import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { Modal, List, Message, toaster } from "rsuite"
import axios from "axios"
import Swal from "sweetalert2"
import { v4 as uuidv4 } from 'uuid'
import * as moment from "moment"
import { FiPlus, FiMinus } from "react-icons/fi"
import {
	Button,
	Card,
	CardBody,
	CardSubtitle,
	CardTitle,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row
} from "reactstrap"
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"
import SelectProductPickerPaginate from "./SelectProductPickerPaginate"
//GraphQL
import { UpdateOrder, CreateOrderItems, UpdateOrderItems } from "gql/Mutation"
import { getCustomerAddresses, ProductList, getCustomerAddressById, GetStoreProductTax } from "gql/query"
import { useMutation, useApolloClient } from "@apollo/client"

const OrderEditModal = (props) => {
	const [UpdateOrderMutation] = useMutation(UpdateOrder)
	const [CreateOrderItemsMutation] = useMutation(CreateOrderItems)
	const [UpdateOrderItemsMutation] = useMutation(UpdateOrderItems)

	const client = useApolloClient()
	const history = useHistory()
	const [btnLoading, setBtnLoading] = useState(false)
	const [deliveryAddress, setDeliveryAddress] = useState("")
	const [deliveryAddressObj, setDeliveryAddressObj] = useState(null)
	const [orderItems, setOrderItems] = useState([])
	const [loadingAddressCheck, setLoadingAddressCheck] = useState(false)
	const [refundableDeliveryCharges, setRefundableDeliveryCharges] = useState(0)

	useEffect(() => {
		setDeliveryAddress(props.deliveryAddress)
	}, [props.deliveryAddress])
	useEffect(() => {
		setOrderItems(props.orderItems)
	}, [props.orderItems])
	const totalGross = props.orderItems.reduce((a, b) => +a + +b.total_price_amount, 0)
	const orderItemsGross = orderItems.reduce((a, b) => +a + (b.item_price * b.count), 0)

	const handleUpdateCount = (index, action) => {
		let _orderItems = []
		orderItems.map((item, _index) => {
			let _count = item.count
			if (_index === index) {
				if (action === "plus") {
					_count += 1
				} else {
					_count -= 1
				}
			}
			_orderItems.push({
				...item,
				count: _count
			})
		})
		const newGross = _orderItems.reduce((a, b) => +a + (b.item_price * b.count), 0)
		if (newGross > totalGross) {
			const message = (
				<Message showIcon type={"warning"} >
					Adjusted amount can&apos;t be greater than actual gross amount
				</Message>
			)
			toaster.push(message, { placement: "topEnd" })
		} else {
			setOrderItems([..._orderItems])
		}
	}
	const handleAddProduct = (item) => {
		if (item?.id) {
			let _orderItems = [...orderItems]
			let itemExists = orderItems.find(o => o.product_id === item.id)
			if (itemExists) {
				const message = (
					<Message showIcon type={"warning"} >
						Item is already added. Please increase the quantity.
					</Message>
				)
				toaster.push(message, { placement: "topEnd" })
			} else {
				_orderItems.push({
					count: 1,
					eefind_discounted_amount: 0,
					id: null,
					item_name: item.non_mrp ? item.nonmrp_product_name : item.master_product?.name,
					item_price: item.price,
					price_includes_tax: item.price_includes_tax,
					product: { __typename: 'products', sku: item.sku, image_url: item.image_url },
					product_id: item.id,
					tax_amount: 0,
					total_price_amount: item.price,
					vendor_discounted_amount: 0,
					__typename: "order_items"
				})
				const newGross = _orderItems.reduce((a, b) => +a + (b.item_price * b.count), 0)
				if (newGross > totalGross) {
					const message = (
						<Message showIcon type={"warning"} >
							Adjusted amount can&apos;t be greater than actual gross amount
						</Message>
					)
					toaster.push(message, { placement: "topEnd" })
				} else {
					setOrderItems([..._orderItems])
				}
			}
		}
	}
	const checkDeliveryAvailable = async (origin, destination, address) => {
		const token = localStorage.getItem("TOKEN")
		axios({
			url: `${process.env.REACT_APP_SERVICE_BASE_URL}api/order/delivery/quote`,
			method: 'post',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			data: {
				origin,
				destination
			}
		})
			.then(response => {
				const { status, message, cost } = response.data
				if (status === "failed") {
					const _message = (
						<Message showIcon type={"error"} >
							{message || "Address is not deliverable at the moment."}
						</Message>
					)
					toaster.push(_message, { placement: "topEnd" })
				} else {
					if (cost > props.order.delivery_charges) {
						const _message = (
							<Message showIcon type={"error"}>
								Delivery charges for this address is higher than the actual delivery address.
							</Message>
						)
						toaster.push(_message, { placement: "topEnd" })
					} else {
						setDeliveryAddressObj(address)
						setDeliveryAddress(address.id)
						setRefundableDeliveryCharges(props.order.delivery_charges - cost)
					}
				}
			})
			.catch(error => console.log(error))
			.finally(() => setLoadingAddressCheck(false))
	}
	const handleSetDeliveryAddress = async (addressId) => {
		if (addressId !== props.deliveryAddress) {
			try {
				setLoadingAddressCheck(true)
				const { data } = await client.query({
					query: getCustomerAddressById,
					skipCache: true,
					variables: {
						id: addressId
					}
				})
				if (data?.customer_addresses) {
					const destination = data.customer_addresses[0]?.map_point?.coordinates
					let origin = props.order?.origin_geopoint?.coordinates
					if (destination?.length && origin?.length) {
						await checkDeliveryAvailable({
							lat: origin[0],
							lng: origin[1]
						}, {
							lat: destination[0],
							lng: destination[1]
						}, data.customer_addresses[0])
					}
				}
			} catch (err) {
				console.log(err)
				setLoadingAddressCheck(false)
			}
		}
	}
	const handleClose = () => {
		setDeliveryAddress(props.deliveryAddress)
		setOrderItems(props.orderItems)
		setRefundableDeliveryCharges(0)
		props.close()
	}
	const calculateCommission = async(productId, nonMrp, price) =>{
		if(nonMrp){
			const { data } = await client.query({
				query: GetStoreProductTax,
				skipCache: true,
				variables: {
					where: {
						nonmrp_product_id: {_eq: productId}
					}
				}
			})
 		}else{
			return (price * 18)/100
		}
	}
	const handleUpdateOrder = async (e) => {
		try {
			e.preventDefault()
			setBtnLoading(true)
			//====update address if changed========//
			let orderObj = { 
				order_status: "acknowledged",
				confirmed_at: moment().toDate() 
			}
			if (deliveryAddressObj !== null) {
				orderObj = {
					...orderObj,
					destination_geopoint: deliveryAddressObj.map_point,
					customer_location: {
						"city": deliveryAddressObj.city,
						"phone": props.order?.customer?.phone,
						"state": deliveryAddressObj.state,
						"landmark": "",
						"map_point": deliveryAddressObj.map_point,
						"address_id": deliveryAddressObj.id,
						"customer_id": props.order?.customer?.id,
						"postal_code": deliveryAddressObj.postcode,
						"address_line_1": deliveryAddressObj.address_line_1
					}
				}
				if(refundableDeliveryCharges > 0){ //set new delivery charge
					orderObj = {
						...orderObj,
						delivery_charges: props.order.delivery_charges - refundableDeliveryCharges
					}
				}
			}
			//====update address if changed========//

			//====add cancelled_amount if available==========//
			let cancelled_amount = 0
			cancelled_amount = (totalGross - orderItemsGross) + refundableDeliveryCharges
			if (cancelled_amount > 0) {
				orderObj.cancelled_amount = cancelled_amount
			}
			await UpdateOrderMutation({
				variables: {
					id: props.order?.id,
					object: orderObj
				}
			})
			//====add cancelled_amount if available==========//
			
			//=====Update/Add order items============//
			const newItems = orderItems.filter((item) => item.id === null && item.count > 0)
			const oldItems = orderItems.filter((item) => item.id !== null)
			let _newItems = []
			for(let i=0; i<newItems.length; i++){
				// let tax_amount = await calculateCommission(newItems[i].product_id, newItems[i].non_mrp, newItems[i].item_price)
				_newItems.push({
					id: uuidv4(),
					order_status: "submitted",
					order_id: props.order.id,
					item_price: newItems[i].item_price,
					count: newItems[i].count,
					tax_amount: newItems[i].tax_amount,
					taxes: [],
					price_includes_tax:false,
					total_price_amount: newItems[i].item_price * newItems[i].count,
					product_id: newItems[i].product_id,
					item_name: newItems[i].item_name
				})
			}
			if(_newItems.length > 0){
				await CreateOrderItemsMutation({
					variables: {
						objects: _newItems
					}
				})
			}
			let promises = []
			oldItems.map((item)=>{
				promises.push(
					UpdateOrderItemsMutation({
						variables: {
							id: item.id,
							object: {
								count: item.count,
								total_price_amount: item.item_price * item.count
							}
						}
					})
				)
			})
			await Promise.all(promises)
			//=====Update/Add order items============//

			Swal.fire("Order Updated Successfully", "", "success").then(() => {
				props.refetch()
				handleClose()
			})
		} catch (err) {
			console.log(err)
			Swal.fire("Something Went Wrong!", "", "error")
		} finally {
			setBtnLoading(false)
		}
	}
	return (
		<Modal open={props.open} onClose={handleClose} size="lg">
			<Modal.Header>
				<Modal.Title>Edit Order</Modal.Title>
			</Modal.Header>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<Form id="brandform" onSubmit={handleUpdateOrder}>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<label>Update Order Address</label>
											<SelectPickerPaginate
												query={getCustomerAddresses}
												placeholder="Change Address"
												value={deliveryAddress}
												onChange={(address) => handleSetDeliveryAddress(address)}
												arrKey="customer_addresses"
												objectLabel="address_line_1"
												objectValue="id"
												style={{ width: "100%" }}
												paginate={true}
												filterCondition={{ customer_id: { _eq: props.customerId } }}
											/>
											{loadingAddressCheck && (
												<label className="text-warning mt-3">Verifying new address for delivery...</label>
											)}
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<label>Update Ordered Items</label>
											<List bordered>
												{orderItems.map((item, index) => (
													<List.Item key={index} index={index}>
														<Row>
															<Col sm="5">
																<span style={{ textDecoration: item.count === 0 ? "line-through" : "none" }}>
																	{item.item_name}
																</span>
															</Col>
															<Col sm="2">
																<span style={{ textDecoration: item.count === 0 ? "line-through" : "none" }}>
																	₹{item.item_price}
																</span>
															</Col>
															<Col sm="1">
																<span style={{ textDecoration: item.count === 0 ? "line-through" : "none" }}>
																	{item.count}
																</span>
															</Col>
															<Col sm="1">
																<span style={{ textDecoration: item.count === 0 ? "line-through" : "none" }}>
																	₹{item.item_price * item.count}
																</span>
															</Col>
															<Col sm="3">
																<div className="d-flex justify-content-around align-items-center">
																	<Button
																		type="button"
																		disabled={orderItemsGross >= totalGross}
																		onClick={() => handleUpdateCount(index, "plus")}
																	>
																		<FiPlus />
																	</Button>
																	<p>{item.count}</p>
																	<Button
																		type="button"
																		disabled={item.count <= 0}
																		onClick={() => handleUpdateCount(index, "minus")}
																	>
																		<FiMinus />
																	</Button>
																</div>
															</Col>
														</Row>
													</List.Item>
												))}
												{orderItemsGross < totalGross && (
													<List.Item>
														<SelectProductPickerPaginate
															query={ProductList}
															placeholder="Select Item"
															onChange={(item) => handleAddProduct(JSON.parse(item))}
															arrKey="products"
															objectValue="id"
															style={{ width: "100%" }}
															paginate={true}
															filterCondition={{ store_id: { _eq: props.shopId }, price: { _lte: (totalGross - orderItemsGross) } }}
														/>
													</List.Item>
												)}
											</List>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<label>Actual Cost of Items: ₹{totalGross}</label>
									</Col>
									<Col>
										<label>Adjusted Cost of Items: ₹{orderItemsGross}</label>
									</Col>
									<Col>
										<label>Refundable Amount: ₹{(totalGross - orderItemsGross) + refundableDeliveryCharges}</label>
									</Col>
								</Row>
								<div className="d-flex justify-content-end flex-wrap gap-2 mt-5">
									<Button
										color="primary"
										className="btn "
										type="submit"
										disabled={btnLoading}
									>
										Submit
									</Button>
									<Button
										type="button"
										color="secondary"
										className=" "
										onClick={handleClose}
									>
										Cancel
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Modal>
	)
}
OrderEditModal.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	onSubmit: PropTypes.func,
	customerId: PropTypes.string,
	deliveryAddress: PropTypes.string,
	orderItems: PropTypes.array,
	totalAmount: PropTypes.number,
	shopId: PropTypes.string,
	order: PropTypes.order,
	refetch: PropTypes.func
}
export default withRouter(OrderEditModal)
