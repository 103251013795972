import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import Rental_addons_docs from "pages/Vehicles/Rental_addons_docs"
import { useParams } from "react-router-dom"


 //import "../../../../assets/scss/custom.scss"

const RentalAddonsDocs = props => {
  

  const UrlParams = useParams()

  console.log("Params details", UrlParams)
  return <Rental_addons_docs/>
  
}

RentalAddonsDocs.propTypes = {}
export default withRouter(RentalAddonsDocs)
