import { VehicleRentalMasterVehiclesTypehead, VehicleRentalStoresTypehead, VehicleRentalVehicleBarndsTypehead, VehicleRentalsCategoriesTypehead, VehicleTypesTypehead, VehicleUsersTypehead } from "gql/query"


export const Columns = {

                     type_name    : {name:"type_name", span:3,type:"text", },
                        active    : {name:"active", span:3,type:"boolean", },
                      image_url   : {name:"image_url", span:3,type:"img", },
                  vehicle_group   : {name:"vehicle_group", span:3,type:"select_picker" },
                

 
         }



