import React from "react"
import { Dropdown } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { GoKebabVertical } from "react-icons/go"

//Helpers
import ConvertRangesToQuery from "helpers/range_to_query_helper"
const isDiscountApplied = (order) =>
  Array.isArray(order?.discounts) && order?.discounts?.length > 0
export const FormatData = (data) => {
 
  // return{order: data?.services}

  const order = data?.services?.map((is) => {
     return {
      ...data?.services,
      id: is.id,
      orderId: is.uid,
      landmark: is?.landmark,
      service: is?.service?.service_name,
      service_category: is?.service_category?.category,
      service_name: is?.service?.service_name,
      created_by: is?.user?.name,
      updated_by: is?.UpdatedBy?.name,
      email: is?.user?.email,
      phone: is?.user?.phone,
    }
  })
  return {
    order,
  }
}

const formatHighlighter = (text, searchKey, filterValue) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.status)) {
    searchWords = [...searchWords, ...filterValue?.status]
  }
  if (Array.isArray(filterValue?.city)) {
    searchWords = [...searchWords, ...filterValue?.city]
  }
  return (
    <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  )
}
export const EcommerceOrderColumns = (searchKey, filterValue, history) => [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    formatter: (cellContent, row) => {
      if (row.id) {
        return (
          <Link to={`/services/service-list/details/${row.id}`}>
            {formatHighlighter(row.id, searchKey, filterValue)}
          </Link>
        )
      } else {
        return formatHighlighter(row.id, searchKey, filterValue)
      }
    },
  },

  {
    dataField: "service_name",
    text: "Service",
    sort: true,
  },

  {
    dataField: "service_category",
    text: "Category",
    sort: true,

  },
  {
    dataField: "landmark",
    text: "LandMark",
    sort: true,
  },

  {
    dataField: "created_by",
    text: "Created By",
    sort: true,
  },

  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
  },

  {
    dataField: "active",
    text: "Active",
    sort: true,
    formatter: (cellContent, row) => (cellContent?.active ? "True" : "False"),
  },
  {
    dataField: "is_deleted",
    text: "Deleted",
    sort: true,
    formatter: (cellContent, row) => (cellContent?.active ? "True" : "False"),
  },

  
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          <GoKebabVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              history.push(`/services/service-list/details/${row.id}`)
            }}
          >
            View Details
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
]

export const BuildOrderSearchQuery = (value, queryCondition) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (isNaN(value)) {
      conditionTemp = {
        ...conditionTemp,
        _or: [
          { store: { name: { _ilike: `%${value}%` } } },
          { vehicle_brand: { brand_name: { _ilike: `%${value}%` } } },
          { vehicle_name: { _ilike: `%${value}%` } },
          { vehicle_no: { _ilike: `%${value}%` } },
 
        ],
      }
    } else {
      conditionTemp = {
        ...conditionTemp,
        _or: [{ phone: { _ilike: `%${value}%` } }, { uid: { _eq: value } }],
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
 
export const BuildMasterDataFilterQuery = (filterValue, queryCondition) => {
  if (Object.keys(filterValue).length) {
   let conditionTemp = []
   if (queryCondition !== null) {
     conditionTemp = { ...queryCondition }
   }
   if (filterValue?.hasOwnProperty("vehicle_group")) {
    if (filterValue.vehicle_group?.length > 0) {
      conditionTemp = {
        ...conditionTemp,
        vehicle_group:  { _in: filterValue.vehicle_group },
      }
    }
  }
  if (filterValue?.hasOwnProperty("fuel_type")) {
    if (filterValue.fuel_type?.length > 0) {
      conditionTemp = {
        ...conditionTemp,
 
        fuel_type:  { _in: filterValue.fuel_type },

      }
    }
  }
  if (filterValue?.hasOwnProperty("transmission_type")) {
    if (filterValue.transmission_type?.length > 0) {
      conditionTemp = {
        ...conditionTemp,
 
        transmission_type:  { _in: filterValue.transmission_type },

      }
    }
  }
  if (filterValue?.hasOwnProperty("store")) {
    if (filterValue.store?.length > 0) {
      conditionTemp = {
        ...conditionTemp,
 
        store:  { name:{ _in: filterValue.store } },

      }
    }
  }

  if (filterValue?.hasOwnProperty("store")) {
    if (filterValue.store?.length > 0) {
      conditionTemp = {
        ...conditionTemp,
 
        store:  { name:{ _in: filterValue.store } },

      }
    }
  }

    return conditionTemp
 } else {
   let conditionTemp = {}
   if (queryCondition !== null) {
     conditionTemp = { ...queryCondition }
   }

   return conditionTemp
 }
}



  //Update the state on onchange listeners
  export const onChangeHandlerFunction = ({e,masterData,setMasterData }) => {
    console.log("handler function calling",e, masterData,)
    //  e?.stopPropagation()
    const parent = e?.target?.dataset?.parent || e?.parent
    const index = e?.target?.dataset?.index || e?.index
    const name = e?.target?.dataset?.name || e?.name
    const value = e?.target?.value || e?.value
    const element = e?.target || e?.element
    const type = e?.target?.dataset?.type || e?.type
    const single = e?.target?.dataset?.single
    const multiImage = e?.target?.multiple

    let createHeaders = {}
    createHeaders = { ...masterData }

    if (type === "text") {
      if (parent == name) {
        createHeaders[parent] = { ...createHeaders[parent], value: value }
      } else {
        createHeaders[parent][index] = {
          ...createHeaders[parent][index],
          value: value,
        }
      }
      return setMasterData(createHeaders)
    }

    if (type === "number") {
      if (parent == name) {
        createHeaders[parent] = { ...createHeaders[parent], value: value }
      } else {
        createHeaders[parent][index] = {
          ...createHeaders[parent][index],
          value: value,
        }
      }
      return setMasterData(createHeaders)
    }

    if (type === "boolean") {
      createHeaders[parent][index] = {
        ...createHeaders[parent][index],
        value: String(value),
      }
      return setMasterData(createHeaders)
    }

    if (type === "select") {
      createHeaders[parent] = { ...createHeaders[parent], value: String(value) }
      return setMasterData(createHeaders)
    }

    if (type === "select_picker") {
      createHeaders[parent] = { ...createHeaders[parent], value: value }
      return setMasterData(createHeaders)
    }

    if (type === "typehead") {
      createHeaders[parent] = {
        ...createHeaders[parent],
        label: e?.label,
        value: value,
      }
      return setMasterData(createHeaders)
    }

    if (type === "tag") {
      createHeaders[parent][index] = {
        ...createHeaders[parent][index],
        value: value,
      }
      return setMasterData(createHeaders)
    }

    if (type === "img") {
      const form_data = new FormData()
      Object.entries(e?.target?.files).map(([, value]) =>
        form_data.append("uploads", value)
      )

      axios
        .post(
          `${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`,
          form_data
        )
        .then((result) => {
          if (result?.data?.data?.uploaded_files?.length > 0) {
            // if(!single&&!multiImage){

            //     createHeaders = {...masterData}
            //     createHeaders[parent][index] = {...createHeaders[parent][index]?.value, "value":[...createHeaders[parent][index]?.value,result?.data?.data?.uploaded_files?.[0]?.uploaded_file_url]}
            //      setMasterData(createHeaders)
            // }

            // if(single&&!multiImage){
            //     createHeaders = {...masterData}
            //     createHeaders[parent] = {...createHeaders[parent], "value":result?.data?.data?.uploaded_files?.[0]?.uploaded_file_url}
            //      setMasterData(createHeaders)
            // }

            if (single && multiImage) {
              createHeaders = { ...masterData }

              let existingValues = createHeaders[parent]?.value
                ? [createHeaders[parent]?.value][0]
                : []
              createHeaders[parent].value = [
                ...existingValues,
                result?.data?.data?.uploaded_files?.[0]?.uploaded_file_url,
              ]

              setMasterData(createHeaders)
            }
          }
        })
        .catch((error) => console.log("error", error))
    }
  }