import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import { FiMap } from "react-icons/fi"
import { useRouteMatch, Link } from "react-router-dom"
import moment from "moment"
import { Badge } from "react-bootstrap"
import axios from "axios"
import download from 'downloadjs'
import Swal from "sweetalert2"
import { Popover, Whisper, Panel } from "rsuite"
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import { addDays } from 'date-fns'
//helpers
import isValidUrl from "helpers/url_checker"
//GraphQL
import { ShopDetails } from "gql/query"
import { UpdateStore } from "gql/Mutation"
import { useQuery, useMutation } from "@apollo/client"
import {
	UpdateVendorCompany
} from "../EcommerceMerchants/EcommerceMerchants.query"

import { useSelector } from "react-redux"
const CustomerOverview = (props) => {
	const { capabilities } = useSelector((state) => state.Login)
	const match = useRouteMatch()
	const [shop, setShop] = useState({})
	const [reportDates, setReportDates] = useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 7),
			key: 'selection'
		}
	])

	const { data: shopDetails, loading, error, refetch } = useQuery(ShopDetails, {
		skipCache: true,
		variables: {
			id: match?.params?.id
		}
	})
	const [UpdateVendorCompanyMutation] = useMutation(UpdateVendorCompany)
	const [updateStoreMutation] = useMutation(UpdateStore)

	const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${value.toFixed(2)}`
		} else {
			return "N/A"
		}
	}
	const getKYCImages = () => {
		let jsx = []
		let images = []
		if (!shop.gst_available) {
 			if (shop.nogst_declaration?.length > 0 && typeof shop.nogst_declaration === "string") {
				if (isValidUrl(shop.nogst_declaration)) {
					images.push({ label: "NO-GST DECLARATION", image: shop.nogst_declaration, store: shop.name, store_id: shop.id })
				} else {
					images.push({ label: "NO-GST DECLARATION", image: "/no_image.png", store: shop.name, store_id: shop.id })
				}
			}
			if (shop.proof_of_business?.length > 0 && typeof shop.proof_of_business === "string") {
				if (isValidUrl(shop.proof_of_business)) {
					images.push({ label: "PROOF OF BUSINESS", image: shop.proof_of_business, store: shop.name, store_id: shop.id })
				} else {
					images.push({ label: "PROOF OF BUSINESS", image: "/no_image.png", store: shop.name, store_id: shop.id })
				}
			}
		} else {
			if (shop.gst_photo?.length > 0 && typeof shop.gst_photo === "string") {
				if (isValidUrl(shop.gst_photo)) {
					images.push({ label: "GST", image: shop.gst_photo, store: shop.name, store_id: shop.id })
				} else {
					images.push({ label: "GST", image: "/no_image.png", store: shop.name, store_id: shop.id })
				}
			}
		}
		if (images.length) {
			images.map((item) => {
				if (item?.image) {
					jsx.push(
						<Panel shaded bordered bodyFill className="kyc-cards">
							<img src={item.image} className="kyc-card-img" />
							<Panel header={item.label} >
							</Panel>
						</Panel>
					)
				}
			})
			return jsx
		} else {
			return (
				<p>No KYC Details found!</p>
			)
		}
	}
	const handleChangeStatus = async (status) => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					await updateStoreMutation({
						variables: {
							id: shop.id,
							object: {
								status
							}
						},
					})
					refetch()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	const handleGenerateReport = async () => {
		const token = localStorage.getItem("TOKEN")
		let startDate = moment(reportDates[0]?.startDate).format("YYYY-MM-DD HH:mm:ss")
		let endDate = moment(reportDates[0]?.endDate).format("YYYY-MM-DD HH:mm:ss")
		axios({
			url: `${process.env.REACT_APP_SERVICE_BASE_URL}api/order/report_csv`,
			method: 'post',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			data: {
				"order_from": startDate,
				"order_to": endDate,
				"store_id": match?.params?.id
			},
			responseType: "blob"
		})
			.then(response => {
				const content = response.headers['content-type']
				download(response.data, `report_${shop.store_id}.csv`, content)
			})
			.catch(error => console.log(error))
	}
	const speaker = (
		<Popover title="Select Date Range">
			<div>
				<DateRangePicker
					onChange={item => setReportDates([item.selection])}
					showSelectionPreview={true}
					moveRangeOnFirstSelection={false}
					months={1}
					ranges={reportDates}
					direction="horizontal"
				/>
			</div>
			<div style={{ textAlign: "right" }}>
				<Button
					type="button"
					color={"primary"}
					onClick={handleGenerateReport}
				>
					Submit
				</Button>
			</div>
		</Popover>
	)
	useEffect(() => {
		if (shopDetails?.stores?.length > 0) {
			setShop(shopDetails.stores[0])
		}
	}, [shopDetails])
	return (
		<Container>
			<Row>
				<Col sm="4">
					<img src={shop.photo_url?.length > 0 ? shop.photo_url : "/placeholder.jpg"} className="w-100" />
				</Col>
				<Col sm="8" className="pl-3">
					<div>
						<h3>{shop.name} {shop?.status?.length > 0 && (
							<Badge bg={shop.status === "pending" ? "warning" : shop.status === "verified" ? "success" : "danger"} style={{ textTransform: "capitalize" }}>{shop.status}</Badge>
						)}</h3>
						<a href={`mailto: ${shop.email}`}>
							<h6>{shop.email}</h6>
						</a>
						<a href={`tel: ${shop.phone}`}>
							<h6>{shop.phone}</h6>
						</a>
					</div>
					<hr />
					<Row>
						<div className="stats-wrapper">
							<b>Shop ID</b>
							<p>{shop.store_id}</p>
						</div>
						<div className="stats-wrapper">
							<b>Shop Type</b>
							<p>{shop.store_type}</p>
						</div>
						<div className="stats-wrapper">
							<b>Shop Categories</b>
							<p>{Array.isArray(shop.categories) ? shop.categories.join(", ") : "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Last Order</b>
							{shop?.orders?.length > 0 ? (
								<p>{moment(shop?.orders[0]?.created_at).fromNow()} <Link to={`/ecommerce-orders/details/${shop?.orders[0]?.id}`}>#{shop?.orders[0]?.uid}</Link></p>
							) : (
								<p>No orders found!</p>
							)}
						</div>
						<div className="stats-wrapper">
							<b>Average Order Value</b>
							<p>{formatPrice(shop?.orders_aggregate?.aggregate?.avg?.total_amount)}</p>
						</div>
						{/* <div className="stats-wrapper">
							<b>Lifetime Order Value</b>
							<p>{formatPrice(shop?.orders_aggregate?.aggregate?.sum?.total_amount)}</p>
						</div> */}
						<div className="stats-wrapper">
							<b>Lifetime Order Count</b>
							<p>{shop?.orders_aggregate?.aggregate?.count}</p>
						</div>
					</Row>
					<h4 className="mt-2">Business Details</h4>
					<hr />
					<Row>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Type of Business: </b> {shop.gst_available ? "GST" : "Non-GST"}
							</div>
							<div className="mb-2">
								<b>GST: </b> {shop.business_gstno || "N/A"}
							</div>
							<div className="mb-2">
								<b>PAN: </b> {shop.business_pan || "N/A"}
							</div>
						</Col>
						<Col sm="6">
							{/* <div className="mb-2">
								<b>Tax ID: </b> {shop.business_tax_registrationno || "N/A"}
							</div> */}
							<div className="mb-2">
								<b>Referral name: </b> {shop.created_user?.reference_code || "N/A"}
							</div>
							{/* <div className="mb-2">
								<b>Website: </b> {shop?.vendor_company?.website_url || "N/A"}
							</div> */}
						</Col>
					</Row>
					<div className="d-flex justify-content-between align-items-center">
						<h4 className="mt-2">Address Details</h4> <a rel="noreferrer" target="_blank" href={`http://maps.google.com/maps?q=loc:${shop.lat},${shop.lng}(${shop.address_line_1})`}><FiMap /></a>
					</div>
					<hr />
					<Row>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Address Line 1: </b> {shop.address_line_1 || "N/A"}
							</div>
							<div className="mb-2">
								<b>City: </b> {shop.city || "N/A"}
							</div>
							<div className="mb-2">
								<b>State: </b> {shop.state || "N/A"}
							</div>
						</Col>
						<Col sm="6">

							<div className="mb-2">
								<b>Postal Code: </b> {shop.postcode || "N/A"}
							</div>
							<div className="mb-2">
								<b>Landmark: </b> {shop.landmark || "N/A"}
							</div>
							<div className="mb-2">
								<b>Latitude: </b> {shop.lat}
							</div>
							<div className="mb-2">
								<b>Longitude: </b> {shop.lng}
							</div>
							{/* http://maps.google.com/maps?q=loc:${state.latitude},${state.longitude}(${state.street_address}) */}
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<h4>KYC Details</h4>
				<hr />
				<Row>
					{getKYCImages()}
				</Row>
			</Row>
			{/* <Row>
				<Col xl="12" className="d-flex justify-content-end">
					<Whisper
						preventOverflow
						trigger="click"
						controlId="control-id-container"
						speaker={speaker}
						placement="auto"
					>
						<Button
							type="button"
							color={"primary"}
							className="btn-rounded btn-lg  mb-2 me-2"
						>
							Generate Report
						</Button>
					</Whisper>
				</Col>
			</Row> */}
			{capabilities.includes("edit") && (
			<Row>
				<Col xl="12" className="d-flex justify-content-end">
					{shop.status === "pending" ? (
						<>
							<Button
								type="button"
								color={"primary"}
								className="btn-lg  mb-2 me-2"
								onClick={() => handleChangeStatus("verified")}
							>
								Approve
							</Button>
							<Button
								type="button"
								color={"danger"}
								className="btn-lg  mb-2 me-2"
								onClick={() => handleChangeStatus("rejected")}
							>
								Reject
							</Button>
						</>
					) : (
						<Button
							type="button"
							color={shop.status === "verified" ? "danger" : "primary"}
							className="btn-lg  mb-2 me-2"
							onClick={() => handleChangeStatus(shop.status === "verified" ? "rejected" : "verified")}
						>
							{shop.status === "verified" ? "Reject" : "Approve"}
						</Button>
					)}
				</Col>
			</Row>
			)}
		</Container>
	)
}
CustomerOverview.propTypes = {}
CustomerOverview.displayName = 'CustomerOverview'
export default CustomerOverview