import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
import {
  Order_reports,
  StoreList,
  GetOrderItemsList,
  GetServiceItemsList,
} from "gql/query"
import classNames from "classnames"
import axios from "axios"
import * as moment from "moment"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import "./dashboard.scss"
//import Charts
import StackedColumnChart from "./StackedColumnChart"
import { DateRangePicker } from "rsuite"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

//Helpers
import isValidUrl from "../../helpers/url_checker"

// Pages Components
import LatestOrders from "./LatestOrders"
import TopProducts from "./TopProducts"
import TopBookings from "./TopBookings"
import EcommerceReviewsTable from "pages/Ecommerce/EcommerceReviews/EcommerceReviewsTable"
import EcommercePayoutsTable from "pages/Ecommerce/EcommercePayouts/EcommercePayoutsTable"

import { useQuery } from "@apollo/client"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const FormateData = (data, pathName) => {
  const totalOrders = data.orders_aggregate.aggregate.count || 0
  const totalRevenue = data.payments_aggregate.aggregate.sum.gross_amount || 0
  const totalCommission =
    data.payouts_commission_view_aggregate?.aggregate?.sum?.total_commission ||
    0
  const averageOrderValue = totalRevenue / totalOrders || 0
  const reports = [
    { title: "Orders", iconClass: "bx-copy-alt", description: totalOrders },
    {
      title: "Total Revenue",
      iconClass: "bx-dollar",
      description: "₹" + totalRevenue.toFixed(0),
    },
    {
      title: "Average order value",
      iconClass: "bx-purchase-tag-alt",
      description: "₹" + averageOrderValue.toFixed(0),
    },
    {
      title: "Stores",
      iconClass: "bx-store",
      description: data.stores_aggregate.aggregate.count,
    },
    {
      title: pathName.startsWith("/groceries")
        ? "Products"
        : pathName.startsWith("/services")
        ? "Services"
        : pathName.startsWith("/rentals")
        ? "Rentals"
        : "Property",
      iconClass: "bx-shopping-bag",
      description: pathName.startsWith("/groceries")
        ? data.products_aggregate.aggregate.count
        : pathName.startsWith("/services")
        ? data.sub_services_aggregate.aggregate.count
        : data.rental_vehicles_aggregate.aggregate.count,
    },
    {
      title: "EEfind Earnings",
      iconClass: "bx-dollar",
      description: "₹" + totalCommission.toFixed(0),
    },
  ]
  return reports
}
const Dashboard = (props) => {
  const history = useHistory()
  // let { type } = useParams()
  // console.log(type, "page type")
  const pathName = props.location.pathname
  const [report, setReport] = useState([])
  const [modal, setmodal] = useState(false)
  const [subscribemodal, setSubscribemodal] = useState(false)
  const [mapInstance, setMapInstance] = useState(null)
  const [dateRange, setDateRange] = useState([])
  const [queryConditions, setQueryConditions] = useState({
    revenueConditions:
      pathName == "/groceries/dashboard"
        ? {
            order_type: {
              _in: ["delivery", "home_delivery", "in_store_pickup"],
            },
            order_status: { _eq: "completed" },
          }
        : pathName == "/services/dashboard"
        ? { order_type: { _eq: "service" }, order_status: { _eq: "completed" } }
        : pathName == "/property/dashboard"
        ? {
            order_type: { _eq: "property" },
            order_status: { _eq: "completed" },
          }
        : pathName == "/experience/dashboard"
        ? {
            order_type: { _eq: "experience" },
            order_status: { _eq: "completed" },
          }
        : {},
    ordersConditions:
      pathName == "/groceries/dashboard"
        ? {
            order_type: {
              _in: ["delivery", "home_delivery", "in_store_pickup"],
            },
          }
        : pathName == "/services/dashboard"
        ? { order_type: { _eq: "service" } }
        : pathName == "/property/dashboard"
        ? { order_type: { _eq: "property" } }
        : pathName == "/experience/dashboard"
        ? { order_type: { _eq: "experience" } }
        : {},
    storesConditions:
      pathName == "/groceries/dashboard"
        ? {
            // store_type: {
            //   _eq: "Groceries and Essentials",
            // },
            module: { _eq: "grocery" },
          }
        : pathName == "/services/dashboard"
        ? {
            // store_type: { _eq: "Individual" }
            module: { _eq: "service" },
          }
        : pathName == "/property/dashboard"
        ? {
            module: { _eq: "property" },
          }
        : pathName == "/experience/dashboard"
        ? {
            module: { _eq: "experience" },
          }
        : {},
    productsConditions: {},
    payoutsConditions: {},
  })
  const [PageData, setPageData] = useState({
    limit: 100,
    pageNo: 1,
  })
  const [PageDataRevenue, setPageDataRevenue] = useState({
    limit: 5,
    pageNo: 1,
  })
  const PageDownRevenue = () => {
    if (PageDataRevenue.pageNo > 0)
      setPageDataRevenue({
        ...PageDataRevenue,
        pageNo: PageDataRevenue.pageNo - 1,
      })
  }
  const PageUpRevenue = () => {
    setPageDataRevenue({
      ...PageDataRevenue,
      pageNo: PageDataRevenue.pageNo + 1,
    })
  }

  const [PageDataUnitsSold, setPageDataUnitsSold] = useState({
    limit: 5,
    pageNo: 1,
  })
  const PageDownUnitsSold = () => {
    if (PageDataUnitsSold.pageNo > 0)
      setPageDataUnitsSold({
        ...PageDataUnitsSold,
        pageNo: PageDataUnitsSold.pageNo - 1,
      })
  }
  const PageUpUnitsSold = () => {
    setPageDataUnitsSold({
      ...PageDataUnitsSold,
      pageNo: PageDataUnitsSold.pageNo + 1,
    })
  }

  const { data, loading, error } = useQuery(Order_reports, {
    skipCache: true,
    variables: {
      revenueConditions: queryConditions.revenueConditions,
      ordersConditions: queryConditions.ordersConditions,
      storesConditions: queryConditions.storesConditions,
      productsConditions: queryConditions.productsConditions,
      payoutsConditions: queryConditions.payoutsConditions,
    },
  })

  const { data: stores } = useQuery(StoreList, {
    skipCache: true,
    variables: {
      limit: PageData.limit,
      offset: (PageData.pageNo - 1) * PageData.limit,
      where:
        pathName == "/groceries/dashboard"
          ? {
              module: { _eq: "grocery" },
            }
          : pathName == "/services/dashboard"
          ? {
              module: { _eq: "service" },
            }
          : pathName == "/vehicles/dashboard"
          ? {
              module: { _eq: "rental" },
            }
          : pathName == "/property/dashboard"
          ? {
              module: { _eq: "property" },
            }
          : {},
    },
  })
  const { data: productsByRevenueData } = useQuery(GetOrderItemsList, {
    skipCache: true,
    variables: {
      limit: PageDataRevenue.limit,
      offset: (PageDataRevenue.pageNo - 1) * PageDataRevenue.limit,
      order_by: { total_price_amount: "desc" },
      where: { order: { order_status: { _eq: "completed" } } },
    },
  })
  const countRevenue =
    productsByRevenueData?.order_items_aggregate?.aggregate?.count || 0
  const maxPageRevenue = Math.ceil(countRevenue / PageDataRevenue.limit)

  const { data: productsByUnitSoldData } = useQuery(GetOrderItemsList, {
    skipCache: true,
    variables: {
      limit: PageDataUnitsSold.limit,
      offset: (PageDataUnitsSold.pageNo - 1) * PageDataUnitsSold.limit,
      order_by: { count: "desc" },
      where: { order: { order_status: { _eq: "completed" } } },
    },
  })
  const countUnitsSold =
    productsByUnitSoldData?.order_items_aggregate?.aggregate?.count || 0
  const maxPageUnitsSold = Math.ceil(countUnitsSold / PageDataUnitsSold.limit)

  const { data: servicesByRevenueData } = useQuery(GetServiceItemsList, {
    skipCache: true,
    variables: {
      limit: PageDataRevenue.limit,
      offset: (PageDataRevenue.pageNo - 1) * PageDataRevenue.limit,
      order_by: { total_price_amount: "desc" },
      where: { order: { order_status: { _eq: "completed" } } },
    },
  })
  const countServiceRevenue =
    servicesByRevenueData?.service_items_aggregate?.aggregate?.count || 0
  const maxPageServiceRevenue = Math.ceil(
    countServiceRevenue / PageDataRevenue.limit
  )

  const { data: servicesByUnitSoldData } = useQuery(GetServiceItemsList, {
    skipCache: true,
    variables: {
      limit: PageDataUnitsSold.limit,
      offset: (PageDataUnitsSold.pageNo - 1) * PageDataUnitsSold.limit,
      order_by: { count: "desc" },
      where: { order: { order_status: { _eq: "completed" } } },
    },
  })
  const countServiceUnitsSold =
    servicesByUnitSoldData?.service_items_aggregate?.aggregate?.count || 0
  const maxPageServiceUnitsSold = Math.ceil(
    countServiceUnitsSold / PageDataUnitsSold.limit
  )

  useEffect(() => {
    if (data) {
      setReport(FormateData(data, pathName))
    }
  }, [data])

  useEffect(() => {
    initialize()
    setTimeout(() => {
      setSubscribemodal(true)
    }, 2000)
  }, [])

  const [periodData, setPeriodData] = useState({})
  const [xAxisData, setXAxisData] = useState([])
  const [periodType, setPeriodType] = useState("weekly")
  const [periodDateRange, setPeriodDateRange] = useState({
    startDate: moment().subtract(7, "d").format("DD/MM/YYYY"),
    endDate: moment().format("DD/MM/YYYY"),
  })
  const [hasPermission, setHaspermission] = useState()

  const fetchCurrentLocation = async () => {
    return new Promise(async (resolve, reject) => {
      await navigator.geolocation.getCurrentPosition(
        async (position) => {
          let lat = position.coords.latitude,
            lng = position.coords.longitude
          resolve({
            lat,
            lng,
          })
        },
        (err) => reject(err)
      )
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      })
      setHaspermission(permissionStatus.state)
    })
  }
  async function initialize() {
    let center = await fetchCurrentLocation()
    var mapOptions = {
      zoom: 14,
      center: new window.google.maps.LatLng(
        parseFloat(center.lat),
        parseFloat(center.lng)
      ),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      draggable: true,
    }
    let map = new window.google.maps.Map(
      document.getElementById("map_canvas"),
      mapOptions
    )
    setMapInstance(map)
  }
  //api call for chart
  const fetchRevenueData = async () => {
    const token = localStorage.getItem("TOKEN")
    const resp = await axios({
      url: `${process.env.REACT_APP_SERVICE_BASE_URL}api/order/stats`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        order_from: periodDateRange.startDate,
        order_to: periodDateRange.endDate,
        store: [],
        order_type:
          pathName == "/groceries/dashboard"
            ? ["delivery", "home_delivery", "in_store_pickup"]
            : pathName == "/services/dashboard"
            ? ["service"]
            : pathName == "/property/dashboard"
            ? ["property"]
            : pathName == "/experience/dashboard"
            ? ["experience"]
            : "",
      },
    })
    if (resp.data?.length) {
      const totalArray = resp.data.map((item) => item.total)
      const daysArray = resp.data.map((item) => item.day)
      setPeriodData({
        name: "Revenue",
        data: totalArray,
      })
      setXAxisData(daysArray)
    } else {
      setXAxisData(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Mon"])
    }
  }
  const handleChangePeriodDate = (action) => {
    if (action === "prev") {
      let endDate = periodDateRange.startDate
      let startDate = moment(periodDateRange.startDate, "DD/MM/YYYY")
        .subtract(7, "d")
        .format("DD/MM/YYYY")
      setPeriodDateRange({ startDate, endDate })
    } else {
      let endDate = moment(periodDateRange.endDate, "DD/MM/YYYY")
        .add(7, "d")
        .format("DD/MM/YYYY")
      let startDate = periodDateRange.endDate
      setPeriodDateRange({ startDate, endDate })
    }
  }
  useEffect(() => {
    if (Array.isArray(stores?.stores) && mapInstance) {
      stores.stores.map((store) => {
        let image = "/placeholder.jpg"
        if (isValidUrl(store.photo_url)) {
          image = store.photo_url
        }

        // let marker = new window.google.maps.Marker({
        //   store,
        //   position: { lat: parseFloat(store.lat), lng: parseFloat(store.lng) },
        //   mapInstance,
        //   label: {
        //     color: '#556ee6',
        //     fontWeight: 'bold',
        //     text: store.name,
        //   },
        //   icon: {
        //     labelOrigin: new google.maps.Point(11, 50),
        //     url: image,
        //     size: new google.maps.Size(30, 40),
        //     origin: new google.maps.Point(0, 0),
        //     anchor: new google.maps.Point(11, 40),
        //   },
        // })
        // window.google.maps.event.addDomListener(marker, 'click', function () {
        //   history.push(`/ecommerce-shops/details/${store.id}`)
        // })
        // marker.setMap(mapInstance)

        let overlay = new window.CustomMarker(
          new google.maps.LatLng(parseFloat(store.lat), parseFloat(store.lng)),
          mapInstance,
          {
            ...store,
            marker_id: String(Date.now()),
            callback: () => {
              history.push(
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-shops/details/${store.id}`
                  : pathName.startsWith("/services")
                  ? `/services/service-shops/details/${store.id}`
                  : pathName.startsWith("/property")
                  ? `/property/property-shops/details/${store.id}`
                  : pathName.startsWith("/experience")
                  ? `/experience/experience-shops/details/${store.id}`
                  : ""
              )
            },
          }
        )
      })
    }
  }, [stores, mapInstance])
  useEffect(() => {
    if (dateRange?.length > 0) {
      setQueryConditions({
        revenueConditions: {
          order_status: { _eq: "completed" },
          _and: [
            { created_at: { _gte: dateRange[0] } },
            { created_at: { _lte: dateRange[1] } },
          ],
        },
        ordersConditions: {
          _and: [
            { created_at: { _gte: dateRange[0] } },
            { created_at: { _lte: dateRange[1] } },
          ],
        },
        storesConditions: {
          _and: [
            { created_at: { _gte: dateRange[0] } },
            { created_at: { _lte: dateRange[1] } },
          ],
        },
        productsConditions: {
          _and: [
            { created_at: { _gte: dateRange[0] } },
            { created_at: { _lte: dateRange[1] } },
          ],
        },
        payoutsConditions: {
          _and: [
            { created_at: { _gte: dateRange[0] } },
            { created_at: { _lte: dateRange[1] } },
          ],
        },
      })
    } else {
      setQueryConditions({
        revenueConditions:
          pathName == "/groceries/dashboard"
            ? {
                order_type: {
                  _in: ["delivery", "home_delivery", "in_store_pickup"],
                },
                order_status: { _eq: "completed" },
              }
            : pathName == "/services/dashboard"
            ? {
                order_type: { _eq: "service" },
                order_status: { _eq: "completed" },
              }
            : pathName == "/vehicles/dashboard"
            ? {
                order_type: { _eq: "rental" },
                order_status: { _eq: "completed" },
              }
            : pathName == "/property/dashboard"
            ? {
                order_type: { _eq: "property" },
                order_status: { _eq: "completed" },
              }
            : pathName == "/experience/dashboard"
            ? {
                order_type: { _eq: "experience" },
                order_status: { _eq: "completed" },
              }
            : {},
        ordersConditions:
          pathName == "/groceries/dashboard"
            ? {
                order_type: {
                  _in: ["delivery", "home_delivery", "in_store_pickup"],
                },
              }
            : pathName == "/services/dashboard"
            ? { order_type: { _eq: "service" } }
            : pathName == "/vehicles/dashboard"
            ? { order_type: { _eq: "rental" } }
            : pathName == "/property/dashboard"
            ? {
                order_type: { _eq: "property" },
              }
            : pathName == "/experience/dashboard"
            ? {
                order_type: { _eq: "experience" },
              }
            : {},
        storesConditions:
          pathName == "/groceries/dashboard"
            ? {
                module: { _eq: "grocery" },
              }
            : pathName == "/services/dashboard"
            ? {
                module: { _eq: "service" },
              }
            : pathName == "/vehicles/dashboard"
            ? {
                module: { _eq: "rental" },
              }
            : pathName == "/property/dashboard"
            ? {
                module: { _eq: "property" },
              }
            : pathName == "/experience/dashboard"
            ? {
                module: { _eq: "experience" },
              }
            : {},
        productsConditions: {},
        payoutsConditions: {},
      })
    }
  }, [dateRange])
  useEffect(() => {
    fetchRevenueData()
  }, [periodDateRange])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | EEFind </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Home")}
            breadcrumbItem={props.t(
              pathName.startsWith("/groceries")
                ? "Grocery-Dashboard"
                : pathName.startsWith("/services")
                ? "Services-Dashboard"
                : pathName.startsWith("/vehicles")
                ? "Rental-Dashboard"
                : pathName.startsWith("/property")
                ? "Property-Dashboard"
                : pathName.startsWith("/experience")
                ? "Experience-Dashboard"
                : ""
            )}
          />
          <Row>
            <Col xl="12" className="d-flex justify-content-end">
              <DateRangePicker
                placement="auto"
                onChange={setDateRange}
                style={{ marginLeft: 10 }}
                placeholder="Select Date Range"
                value={dateRange}
              />
            </Col>
          </Row>
          <Row>
            {/* <Col xl="4">
              <WelcomeComp />
              <MonthlyEarning />
            </Col> */}
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {report.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      {/* {console.log(report, "reports")} */}
                      <CardBody>
                        <Media>
                          <Media body>
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0 text-size">
                              {report.description}
                            </h4>
                          </Media>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </Media>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Revenue Over Time</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item rev-date-range">
                          <FiChevronLeft
                            style={{ marginRight: 10, cursor: "pointer" }}
                            onClick={() => handleChangePeriodDate("prev")}
                          />
                          {moment(
                            periodDateRange.startDate,
                            "DD/MM/YYYY"
                          ).format("DD MMM")}{" "}
                          -{" "}
                          {moment(periodDateRange.endDate, "DD/MM/YYYY").format(
                            "DD MMM"
                          )}
                          {periodDateRange.endDate !==
                            moment().format("DD/MM/YYYY") && (
                            <FiChevronRight
                              style={{ marginLeft: 10, cursor: "pointer" }}
                              onClick={() => handleChangePeriodDate("next")}
                            />
                          )}
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly")
                            }}
                            id="one_month"
                          >
                            Week
                          </Link>{" "}
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <StackedColumnChart
                    periodData={periodData}
                    xAxisData={xAxisData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>

            <Col xl="4">
              <TopCities />
            </Col>
          </Row> */}
          <Row>
            <Col lg="12">
              <LatestOrders />
            </Col>
            {pathName == "/groceries/dashboard" && (
              <>
                {" "}
                <Col lg="12">
                  <TopProducts
                    order={{
                      order_items: productsByRevenueData?.order_items || [],
                    }}
                    showLabel={true}
                    label="Top performing products by revenue"
                  />
                  <Row className="align-items-md-center mb-5 p-3">
                    <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                      <Button
                        disabled={PageDataRevenue.pageNo <= 1}
                        onClick={() => {
                          PageDownRevenue()
                        }}
                      >
                        Previous Page
                      </Button>
                      <div>
                        {`Page No. ${PageDataRevenue.pageNo} of ${maxPageRevenue}`}
                      </div>
                      <Button
                        disabled={PageDataRevenue.pageNo >= maxPageRevenue}
                        onClick={() => {
                          PageUpRevenue()
                        }}
                      >
                        Next Page
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <TopProducts
                    order={{
                      order_items: productsByUnitSoldData?.order_items || [],
                    }}
                    showLabel={true}
                    label="Top performing products by number of units sold"
                  />
                  <Row className="align-items-md-center mb-5 p-3">
                    <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                      <Button
                        disabled={PageDataUnitsSold.pageNo <= 1}
                        onClick={() => {
                          PageDownUnitsSold()
                        }}
                      >
                        Previous Page
                      </Button>
                      <div>
                        {`Page No. ${PageDataUnitsSold.pageNo} of ${maxPageUnitsSold}`}
                      </div>
                      <Button
                        disabled={PageDataUnitsSold.pageNo >= maxPageUnitsSold}
                        onClick={() => {
                          PageUpUnitsSold()
                        }}
                      >
                        Next Page
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </>
            )}

            {pathName == "/services/dashboard" && (
              <>
                {" "}
                <Col lg="12">
                  <TopBookings
                    order={{
                      service_items: servicesByRevenueData?.service_items || [],
                    }}
                    showLabel={true}
                    label="Top performing services by revenue"
                  />
                  <Row className="align-items-md-center mb-5 p-3">
                    <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                      <Button
                        disabled={PageDataRevenue.pageNo <= 1}
                        onClick={() => {
                          PageDownRevenue()
                        }}
                      >
                        Previous Page
                      </Button>
                      <div>
                        {`Page No. ${PageDataRevenue.pageNo} of ${maxPageServiceRevenue}`}
                      </div>
                      <Button
                        disabled={
                          PageDataRevenue.pageNo >= maxPageServiceRevenue
                        }
                        onClick={() => {
                          PageUpRevenue()
                        }}
                      >
                        Next Page
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col lg="12">
                  <TopBookings
                    order={{
                      service_items:
                        servicesByUnitSoldData?.service_items || [],
                    }}
                    showLabel={true}
                    label="Top performing services by number of units sold"
                  />
                  <Row className="align-items-md-center mb-5 p-3">
                    <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                      <Button
                        disabled={PageDataUnitsSold.pageNo <= 1}
                        onClick={() => {
                          PageDownUnitsSold()
                        }}
                      >
                        Previous Page
                      </Button>
                      <div>
                        {`Page No. ${PageDataUnitsSold.pageNo} of ${maxPageServiceUnitsSold}`}
                      </div>
                      <Button
                        disabled={
                          PageDataUnitsSold.pageNo >= maxPageServiceUnitsSold
                        }
                        onClick={() => {
                          PageUpUnitsSold()
                        }}
                      >
                        Next Page
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </>
            )}

            <Col lg="12">
              <Row className="mb-2">
                <Col sm="8">
                  <h4>Active Payouts</h4>
                </Col>
              </Row>
              <hr />
              <EcommercePayoutsTable
                queryCondition={{
                  status: { _eq: "PENDING" },
                  total_amount: { _gt: 0 },
                  store: pathName.startsWith("/groceries")
                    ? {
                        module: {
                          _eq: "grocery",
                        },
                      }
                    : pathName.startsWith("/services")
                    ? { module: { _eq: "service" } }
                    : pathName.startsWith("/vehicles")
                    ? { module: { _eq: "rental" } }
                    : pathName.startsWith("/property")
                    ? { module: { _eq: "property" } }
                    : pathName.startsWith("/experience")
                    ? { module: { _eq: "experience" } }
                    : {},
                }}
                activeFilters={["status", "store", "created_at"]}
                filterQueryConditions={null}
                origin="dashboard"
              />
            </Col>
            <Col lg="12">
              <Row className="mb-2">
                <Col sm="8">
                  <h4>Latest Reviews</h4>
                </Col>
              </Row>
              <hr />
              <EcommerceReviewsTable
                queryCondition={
                  pathName == "/groceries/dashboard"
                    ? {
                        store: {
                          module: { _eq: "grocery" },
                        },
                      }
                    : pathName == "/services/dashboard"
                    ? {
                        store: {
                          module: { _eq: "service" },
                        },
                      }
                    : pathName == "/vehicles/dashboard"
                    ? {
                        store: {
                          module: { _eq: "rental" },
                        },
                      }
                    : pathName == "/property/dashboard"
                    ? {
                        store: {
                          module: { _eq: "property" },
                        },
                      }
                    : {}
                }
                activeFilters={[]}
                filterQueryConditions={null}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div
                id="map_canvas"
                className={`${hasPermission == "denied" ? "d-none" : ""}`}
              ></div>
            </Col>
            {hasPermission == "denied" && (
              <Col
                lg="12"
                className="d-flex justify-content-center align-items-center"
                style={{ height: 400 }}
              >
                <div>
                  <p className="bg-warning text-dark p-2">
                    Enable Location permission to view Map!
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      {/* <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal)
        }}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <ModalHeader
              toggle={() => {
                setSubscribemodal(!subscribemodal)
              }}
            ></ModalHeader>
          </div>
          <div className="modal-body">
            <div className="text-center mb-4">
              <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-light  rounded-circle text-primary h1">
                  <i className="mdi mdi-email-open"></i>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <h4 className="text-primary">Subscribe !</h4>
                  <p className="text-muted font-size-14 mb-4">
                    Subscribe our newletter and get notification to stay update.
                  </p>

                  <div
                    className="input-group rounded bg-light"
                  >
                    <Input
                      type="email"
                      className="form-control bg-transparent border-0"
                      placeholder="Enter Email address"
                    />
                    <Button color="primary" type="button" id="button-addon2">
                      <i className="bx bxs-paper-plane"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
