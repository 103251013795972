import React, { useEffect, useState } from "react"
import {  Modal } from "rsuite"
import { withRouter } from "react-router-dom"
import { Insert_Create_AirPort_Rental, Update_AirPort_Rental, } from "gql/Mutation"
import { Row} from "reactstrap"
import PropTypes from "prop-types"
import { useLazyQuery, useMutation } from "@apollo/client"
import { VehicleRentalsAirportQuery, } from "gql/query"
import { Columns } from "./columns"
import { BuildFields } from "../Common/BuildFields"
import { Action } from "../Common/Action"
import { onChangeHandlerFunction } from "./airport-rentals-.functions"

const AirportRentalsCreate = (props) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [CreateVehicleMasterDataMutation] = useMutation( Insert_Create_AirPort_Rental )
  const [UpdateVehicleMasterDataMutation] = useMutation(Update_AirPort_Rental)
  const [queryCondition, setQueryCondition] = useState(null)
  const [masterData, setMasterData] = useState({})

  const [getVehicleAirportsRentalsList, { loading, data, refetch, error }] =
    useLazyQuery(VehicleRentalsAirportQuery, {
      skipCache: false,
      variables: { limit: 1, where: { id: { _eq: props?.masterData?.id } }, },
    })

  useEffect(() => {
      if (!queryCondition) setQueryCondition(props?.masterData?.id)
      else getVehicleAirportsRentalsList()
   }, [props?.masterData?.id, queryCondition])

  //If rentals data exists, merge values
  useEffect(() => {
    //Add vlaues to the all columns
    if (data?.rental_airports?.length > 0) {
      let airports_rentals = data?.rental_airports?.[0]
      Object.keys(Columns)?.map((key, i) => {

        let obj = Columns?.[key]

        //If it is an array of columns
        if ( key && airports_rentals?.[key] && Object.keys(airports_rentals?.[key])?.length > 1 && Array.isArray(Columns?.[key]) ) {
          Columns[key]?.forEach((e, index) => {
            Columns[key][index] = { ...e, ...{ value: airports_rentals[key][e.name] }, }
          })
        }

        //If it is an object of column and data is not an array
        else if ( !Array.isArray(airports_rentals?.[key]) && !Array.isArray(Columns?.[key]) ) {
           if (obj?.type == "typehead") {
            let field = Columns?.[key]?.access
            Columns[key] = {
              ...Columns?.[key],
              label: airports_rentals?.[field]?.[Columns[key]?.acessLabel],
              value: airports_rentals?.[field]?.[Columns[key]?.acessValue],
            }
          } else {
            Columns[key] = { ...Columns?.[key], value: airports_rentals?.[key], }
          }
        }
        //If it an object of column and existing data is not array
        else if ( Array.isArray(airports_rentals?.[key]) && !Array.isArray(Columns?.[key]) ) {
          Columns[key] = { ...Columns?.[key], value: airports_rentals?.[key], }
        } else if ( Array.isArray(airports_rentals?.[key]) && !Array.isArray(Columns?.[key]) ) {
          Columns[key] = { ...Columns?.[key], value: airports_rentals?.[key], }
        } else {
          Columns[key] = { ...Columns?.[key], value: airports_rentals?.[key], }
        }
      })
    }

    setMasterData({ ...Columns })
  }, [data?.rental_airports?.length])
 
  //Submit or update the master data
  const handleCreateAirport = async (event) => {
    event.preventDefault()
    const newObj = {}

    Object.keys(masterData)?.map((e, i) => {
      masterData?.[e]?.length > 0
        ? [...masterData[e]] ?.filter((ee) => ee) ?.map((ei, ii) => { newObj[e] = { ...newObj?.[e], [ei?.name]: ei?.value } })
        : (newObj[e] = masterData?.[e]?.value)
    })
 
    setBtnLoading(true)
   const Result  = await Action({entity:"AirPort Rentals",
                                 id : props?.masterData?.id , 
                                 updateQuery:UpdateVehicleMasterDataMutation,
                                 createQuery : CreateVehicleMasterDataMutation,
                                 object:newObj,
                                 submit:props?.onSubmit,
                                 close:props?.close()
                          })
    
   console.group("Result is", Result)
   setBtnLoading(false)
  }

  const setCoordinates = (field, locationDetails) => {
    let objs = {}
    objs = { ...masterData }

    objs[field].value = {
      type: "Point",
      crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::4326" } },
      coordinates: [locationDetails.lat, locationDetails.lng],
    }
    objs["state"].value = locationDetails?.state
    objs["address"].value = locationDetails?.address 

    setMasterData(objs)
  }
 
 
  return (
    <Modal open={props.open} onClose={() => props.close()} style={{ width: "100vw", margin: "0 auto" }} >
      <Modal.Header>
        <Modal.Title className="mb-2"> {props.brand?.id ? "UPDATE RENTAL AIRPORT" : "CREATE RENTAL AIRPORT"} </Modal.Title>
      </Modal.Header>
      <nav>
        <form onSubmit={handleCreateAirport}>
          <div className="form-group" onChange={(e) => onChangeHandlerFunction({e,masterData,setMasterData })}>
            <Row>
              {Object.keys(masterData)?.map((e, i) => {
                return !Array.isArray(masterData?.[e])
                  ?  
                   BuildFields({naming:e, field:masterData?.[e], ind:null,setCoordinates})
                  : 
                  [...masterData[e]] ?.filter((ee) => ee) ?.map((ei, i) => BuildFields({naming:e, field:ei, ind:i,setCoordinates,onChangeHandler:onChangeHandlerFunction}))
              })}
            </Row>
          </div>
          <button type="submit">submit</button>
        </form>
      </nav>
    </Modal>
  )
}

AirportRentalsCreate.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  brand: PropTypes.any,
  onSubmit: PropTypes.func,
  masterData: PropTypes.any,
}
export default withRouter(AirportRentalsCreate)
