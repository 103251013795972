import Swal from "sweetalert2"

export const  Action = async  ({
     entity, 
     id,
     updateQuery, 
     createQuery,
     object,
     submit=()=>{},
     close=()=>{}
    
    }) => {

    let CreatedResult = null
    let UpdatedResult = null
    let error = false

    try {

        if(id){
            CreatedResult =   await updateQuery({variables:{id, object}})
        } else {
            UpdatedResult =   await createQuery({variables:{ object}})
        }
          Swal.fire(` ${entity} ${ id ? "Updated" : "Created" } Successfully`,
           "",
          "success"
           )

        

    }catch(err){



        Swal.fire(`${err || "Please Fill all the details"}`, "", `error`)
        error = err 

        
    }  finally{

        if(error){
          Swal.fire(`${error || "Please Fill all the details"}`, "", `error`)
        } else{
            close()
            submit() 
        }

        return {CreatedResult, UpdatedResult, error}  

    }




}