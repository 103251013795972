import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import { Link, useRouteMatch } from "react-router-dom"
import * as moment from "moment"
import { useHistory } from "react-router"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
 
import RentalAddonsDocs from "./kyc-list"

//GraphQL
import { getOrderDetail_byId } from "gql/query"
import { useQuery } from "@apollo/client"
 
const EcommerceOrderDetails = (props) => {
  const pathName = props.location.pathname
  const match = useRouteMatch()
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState({})
  const [activeTab, setActiveTab] = useState("overview")
  const [orderDetails, setOrderDetails] = useState({})
  const { data, loading, error, refetch } = useQuery(getOrderDetail_byId, {
    skipCache: true,
    variables: {
      id: match?.params?.id,
    },
  })
  useEffect(() => {
    if (data?.orders?.length) {
      setOrderDetails(data.orders[0])
    }
  }, [data])

  return (
    <div className="EcommerceOrderDetails">
      <div className="page-content">
        <MetaTags>
          <title>
            Rental Addons Details | EEFind - React Admin & Dashboard Template
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Orders"
            breadcrumbItem={
              pathName.startsWith("/groceries/ecommerce-orders/details")
                ? "Order Details"
                : pathName.startsWith("/services/service-bookings/details")
                ? "Booking Details"
                : ""
            }
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs
                    activeKey={activeTab}
                    onSelect={setActiveTab}
                    className="mb-3"
                  >
                    <Tab eventKey="overview" title="Overview">
                      <RentalAddonsDocs order={orderDetails} refetch={refetch} pathName={pathName} />
                    </Tab>
 
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommerceOrderDetails.propTypes = {
  location: PropTypes.object,
  id: PropTypes.any,

}
export default EcommerceOrderDetails
