import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withRouter, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'
import Swal from "sweetalert2"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { SelectPicker } from 'rsuite'

//redux
import { useMutation, useLazyQuery } from "@apollo/client"
import { ProductList } from "gql/query"
import { UpdateProduct } from "gql/Mutation"

//filters
import EcommerceProductsFilter from "components/Filters/Ecommerce/EcommerceProductsFilter"

//components
import EditProductModal from "../EcommerceProductDetails/EditProductModal"

//functions
import { FormatData, BuildProductSearchQuery, BuildProductFilterQuery, EcommerceProductColumns } from "./EcommerceProducts.functions"

import { useSelector } from "react-redux"

const EcommerceProductsTable = props => {
	const { capabilities } = useSelector((state) => state.Login)
	const [UpdateProductMutation] = useMutation(UpdateProduct)
	const [modal, setModal] = useState(false)
	const history = useHistory()
	const [products, setProducts] = useState([])
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({})
	const [filterValue, setFilterValue] = useState("")
	const [editModalOpen, setEditModalOpen] = useState(false)
	const [selectedProduct, setSelectedProduct] = useState({})
	const [searchKey, setSearchKey] = useState("")
	const limitData = [{ label: "50", value: 50 }, { label: "100", value: 100 }, { label: "200", value: 200 }, { label: "500", value: 500 }]

	const [getProductList, { loading, data, error, refetch }] = useLazyQuery(ProductList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const handleSearch = (value) => {
		const conditionTemp = BuildProductSearchQuery(value, props.queryCondition)
		setQueryCondition(conditionTemp)
	}
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}
	const toggle = () => {
		setModal(!modal)
	}
	useEffect(() => {
		if (data) {
			setProducts(FormatData(data))
		}
	}, [data])
	const defaultSorted = []
	const count = data?.products_aggregate?.aggregate?.count || 0
	const maxPage = Math.ceil(count / PageData.limit)

	const handleTableChange = (type, { page, searchText }) => {

	}
	const handleChangeStatus = async (product) => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					await UpdateProductMutation({
						variables: {
							id: product.id,
							object: {
								approve: !product.approve
							}
						},
					})
					refetch()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	//Apply filters
	useEffect(() => {
		const conditionTemp = BuildProductFilterQuery(filterValue, props.queryCondition)
		setQueryCondition(conditionTemp)
	}, [filterValue])
	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			getProductList()
		}
	}, [props.queryCondition])
	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getProductList()
		}
	}, [queryCondition])
	useEffect(() => {
    if(searchKey.length > 0){
      handleSearch(searchKey)
    }else{
      refetch()
    }
  }, [searchKey])
	var node = useRef()
	const isEdit = capabilities.includes("edit")
	return (
		<Row>
			<Col xs="12">
				<Card>
					<CardBody>
						<ToolkitProvider
							keyField="id"
							data={products || []}
							columns={EcommerceProductColumns(searchKey, filterValue, handleChangeStatus, setSelectedProduct, setEditModalOpen, history, isEdit)}
							bootstrap4
							search
						>
							{toolkitProps => (
								<React.Fragment>
									<Row className="mb-2">
										<Col sm="4">
											<div className="search-box ms-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Form onSubmit={(e)=>{
                              e.preventDefault()
                            }}>
														<Form.Group className="mb-3" controlId="formBasicEmail">
															<i className="bx bx-search-alt search-icon" />
															<Form.Control type="text" placeholder="Search Products" onChange={(e) => setSearchKey(e.target.value)} />
														</Form.Group>
													</Form>

												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
												<EcommerceProductsFilter
													onFilterChange={setFilterValue}
													activeFilters={props.activeFilters}
													filterQueryConditions={props.filterQueryConditions}
												/>
											</div>
										</Col>
									</Row>
									<Row>
										<Col xl="12">
											<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
												<div>
													<b>No. of Products:</b> {count}
												</div>
												<div>
													<SelectPicker
														data={limitData}
														searchable={false}
														style={{ width: 100 }}
														placeholder="Limit"
														value={PageData.limit}
														onChange={(value) => setPageData({ ...PageData, limit: value })}
														cleanable={false}
													/>
												</div>
											</div>
											<div className="mb-3" />
											<div className="table-responsive">
												{loading ? <Loader /> :
													<BootstrapTable
														responsive
														bordered={false}
														striped={false}
														defaultSorted={defaultSorted}
														classes={"table align-middle table-nowrap"}
														keyField="id"
														{...toolkitProps.baseProps}
														onTableChange={handleTableChange}
														ref={node}
														noDataIndication={() => "No Products Found!"}
														key={`${searchKey}_${JSON.stringify(filterValue)}`}
													/>
												}
											</div>
										</Col>
									</Row>
									<Row className="align-items-md-center mt-40">
										<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
											<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
												Previous Page
											</Button>
											<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
											</div>
											<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
												Next Page
											</Button>
										</Col>
									</Row>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</CardBody>
				</Card>
			</Col>
			<EditProductModal
				open={editModalOpen}
				close={() => setEditModalOpen(false)}
				product={selectedProduct}
				refetch={refetch}
				approveBtn={true}
			/>
		</Row>
	)
}

EcommerceProductsTable.propTypes = {
	queryCondition: PropTypes.any,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any,
}

export default withRouter(EcommerceProductsTable)
