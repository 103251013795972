import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
 import RentalsFilters from "./rental-filters-list"
//import "../../../../assets/scss/custom.scss"

const RentalFilters = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Rental Filters | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Rentals" breadcrumbItem="Rental Filters" />
              <RentalsFilters queryCondition={null} activeFilters={["vehicle_group", "filter_name"]} filterQueryConditions={null} showCreateBtn={true} />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}

RentalFilters.propTypes = {}
export default withRouter(RentalFilters)
