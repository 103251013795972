import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link ,useParams} from "react-router-dom"
 import {
  Container,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import CommissionsGroceriesTable from "./EcommerceServicesTable"
import "../../../assets/scss/custom.scss"
const CommissionsGroceries = props => {
  const { type } = useParams()

 
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Commissions | EEFind </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Commission" />
          <CommissionsGroceriesTable 
            queryCondition={null}
            activeFilters={["commission_type", "enum_services_business_type", "enum_services_commision_type", "enum_state", "service_type",  ]}
            filterQueryConditions={null}
            showFilters={true}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
CommissionsGroceries.propTypes = {}
export default withRouter(CommissionsGroceries)
