import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { Modal, Toggle } from "rsuite"
import Swal from "sweetalert2"
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	Input,
	Label,
	Row
} from "reactstrap"
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"

//GraphQL
import { Insert_Sub_Category_One, Update_Sub_Category_One } from "gql/Mutation"
import { CategoryList } from "gql/query"
import { useMutation } from "@apollo/client"

//Import Breadcrumb
import FileUploader from "components/Common/FileUploader"

const EcommerceCreateSub = (props) => {
	const history = useHistory()
	const [CreateSubCategoryMutation] = useMutation(Insert_Sub_Category_One)
	const [UpdateSubCategoryMutation] = useMutation(Update_Sub_Category_One)
	const [name, setName] = useState("")
	const [category, setCategory] = useState("")
	const [btnLoading, setBtnLoading] = useState(false)
	const [isActive, setActive] = useState(true)

	const handleCreateSubCategory = async (e) => {
		try {
			e.preventDefault()
			setBtnLoading(true)
			let user = JSON.parse(localStorage.getItem("authUser"))
			if (user?.user?.id) {
				if (props.subcategory?.id) {
					await UpdateSubCategoryMutation({
						variables: {
							id: props.subcategory?.id,
							object: {
								name,
								category_id: category,
								active: isActive
							}
						}
					})
				} else {
					await CreateSubCategoryMutation({
						variables: {
							object: {
								name,
								sort_order: 0,
								category_id: category,
								created_by: user?.user?.id,
								updated_by: user?.user?.id
							}
						},
					})
				}
				Swal.fire(`Sub Category ${props.subcategory?.id ? "Updated" : "Created"} Successfully`, "", "success").then(() => {
					props.onSubmit()
					props.close()
				})
			}

		} catch (err) {
			Swal.fire("Something went wrong", "", "error")
		} finally {
			setBtnLoading(false)
		}
	}
	useEffect(() => {
		if (props.subcategory?.id) {
			setName(props.subcategory.name)
			setCategory(props.subcategory.category_id)
			setActive(props.subcategory.active)
		} else {
			setName("")
			setCategory("")
		}
	}, [props.subcategory])
	return (
		<Modal open={props.open} onClose={() => props.close()}>
			<Modal.Header>
				<Modal.Title>{props.subcategory?.id ? "Update Subcategory" : "Create Subcategory"}</Modal.Title>
			</Modal.Header>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<Form id="subcategoryform" onSubmit={handleCreateSubCategory}>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="subcategory_name">Sub Category Name</Label>
											<Input
												id="subcategory_name"
												name="subcategory_name"
												defaultValue={name}
												type="text"
												className="form-control"
												onChange={(e) => setName(e.target.value)}
												required
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="category_group">Category</Label>
											<br />
											<SelectPickerPaginate
												query={CategoryList}
												placeholder="Select Category"
												value={category}
												onChange={setCategory}
												arrKey="categories"
												objectLabel="name"
												objectValue="id"
												style={{ width: "100%" }}
												paginate={true}
												filterCondition={null}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="commission_percentage">Active</Label>
											<Toggle 
												checked={isActive}
												onChange={(checked)=>setActive(checked)}
												style={{marginLeft: "1rem"}}
											/>
										</div>
									</Col>
								</Row>
								<div className="d-flex flex-wrap gap-2">
									<Button
										color="primary"
										className="btn "
										type="submit"
										disabled={btnLoading}
									>
										Submit
									</Button>
									<Button
										type="button"
										color="secondary"
										className=" "
										onClick={(e) => props.close()}
									>
										Cancel
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Modal>
	)
}
EcommerceCreateSub.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	onSubmit: PropTypes.func,
	subcategory: PropTypes.any
}
export default withRouter(EcommerceCreateSub)
