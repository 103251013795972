import React from "react"
import { Dropdown } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { GoKebabVertical } from 'react-icons/go'

//helpers
import isValidUrl from "helpers/url_checker"
export const FormatData = (data) => {
	const products = data.products.map(elem => {
		let image = "/placeholder.jpg"
		let imageCount = 0
		if (elem.non_mrp) {
			if (typeof elem.image_url === "object") {
				if (Array.isArray(elem.image_url?.image)) {
					elem.image_url.image.map(obj => {
						if (Object.keys(obj)[0] === "back") {
							image = obj.back
						}
					})
				} else if (elem.image_url?.front) {
					image = elem.image_url?.front
				}
			} else if (isValidUrl(elem.image_url)) {
				image = elem.image_url
			}
		} else if (elem.master_product?.photo_urls?.length > 0) {
			image = elem.master_product.photo_urls[0]
		}
		if (Array.isArray(elem.image_url?.image)) {
			imageCount = elem.image_url?.image?.length
		}
		return {
			id: elem.id,
			image,
			imageCount,
			sku: elem.sku || "N/A",
			name: elem?.master_product?.name,
			stock: elem.available_quantity,
			price: elem.price,
			variation: "N/A",
			created_at: elem.created_at,
			weight: elem.weight,
			weight_unit: elem.weight_unit,
			store_name: elem?.store?.name,
			store_id: elem?.store?.id,
			store_type: elem?.store?.store_type,
			brand_id: elem?.master_product?.product_brand?.id,
			brand_name: elem?.master_product?.product_brand?.name || "N/A",
			category_id: elem?.master_product?.category?.id,
			category_name: elem?.master_product?.category?.name || "N/A",
			subcategory_id: elem?.master_product?.subcategory?.id,
			subcategory_name: elem?.master_product?.subcategory?.name || "N/A",
			out_of_stock_alert: elem?.out_of_stock_alert,
			material_supplier_name: elem?.material_supplier?.name || "N/A",
			material_supplier_contact: elem?.material_supplier?.contact_no || "N/A",
			non_mrp: elem.non_mrp,
			nonmrp_product_name: elem.nonmrp_product_name,
			nonmrp_category: elem.nonmrp_category?.name,
			nonmrp_subcategory: elem.nonmrp_subcategory?.name,
			approve: elem.approve,
			product: elem
		}
	})
	return products
}
const formatHighlighter = (text, searchKey, filterValue) => {
	let searchWords = [searchKey]
	return <Highlighter
		highlightClassName="YourHighlightClass"
		searchWords={searchWords}
		autoEscape={true}
		textToHighlight={String(text)}
		highlightStyle={{ background: "#ff0" }}
	/>
}
export const EcommerceProductColumns = (searchKey, filterValue, handleChangeStatus, setSelectedProduct, setEditModalOpen, history, isEdit) => [
	{
		text: "ID",
		dataField: "id",
		sort: false,
		hidden: true,
		// eslint-disable-next-line react/display-name
	},
	{
		dataField: "sku",
		text: "SKU",
		sort: true,
		formatter: (cellContent, row) => {
			if (row.id) {
				return <Link to={`/groceries/ecommerce-products/detail/${row.id}`}>{formatHighlighter(cellContent, searchKey, filterValue)}</Link>
			} else {
				return formatHighlighter(cellContent, searchKey, filterValue)
			}
		}
	},
	{
		text: "Store",
		dataField: "store_name",
		sort: true,
		formatter: (cellContent, row) => {
			if (row.store_id) {
				return <Link to={`/groceries/ecommerce-shops/details/${row.store_id}`}>{formatHighlighter(row.store_name, searchKey, filterValue)}</Link>
			} else {
				return formatHighlighter(row.store_name, searchKey, filterValue)
			}
		}
	},
	{
		text: "Store Type",
		dataField: "store_type",
		sort: true,
		formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue),
	},
	{
		dataField: "image",
		text: "Image",
		sort: false,
		formatter: (cellContent, row) => <div style={{ position: "relative" }}>
			<img src={row.image} style={{ height: 50 }} />
			{row.imageCount && (
				<b className="image-count-tag">+{row.imageCount}</b>
			)}
		</div>,
	},
	{
		dataField: "name",
		text: "Product Name",
		sort: true,
		formatter: (col, row) => { return <span style={{ display: 'block', width: 230, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{row.non_mrp ? formatHighlighter(row.nonmrp_product_name, searchKey, filterValue) : formatHighlighter(col, searchKey, filterValue)}</span> }
	},
	{
		text: "Stock",
		dataField: "stock",
		sort: true,
	},
	{
		text: "Out Of Stock",
		dataField: "out_of_stock_alert",
		sort: false,
		formatter: (cellContent, row) => row.stock <= row.out_of_stock_alert ? "Yes" : "No",
	},
	{
		dataField: "price",
		text: "Price",
		sort: true,
		formatter: (cellContent, row) => `₹${row.price}`,
	},
	{
		text: "Volume",
		dataField: "weight",
		sort: true,
	},
	{
		text: "Unit",
		dataField: "weight_unit",
		sort: true,
	},
	// {
	// 	text: "Variation",
	// 	dataField: "variation",
	// 	sort: true,
	// },
	{
		dataField: "brand_name",
		text: "Brand",
		sort: true,
		formatter: (cellContent, row) => row.non_mrp ? "Unbranded" : formatHighlighter(cellContent, searchKey, filterValue),
	},
	{
		dataField: "category_name",
		text: "Category",
		sort: true,
		formatter: (cellContent, row) => row.non_mrp ? formatHighlighter(row.nonmrp_category, searchKey, filterValue)  : formatHighlighter(cellContent, searchKey, filterValue),
	},
	{
		dataField: "subcategory_name",
		text: "Sub-Category",
		sort: true,
		formatter: (cellContent, row) => row.non_mrp ? formatHighlighter(row.nonmrp_subcategory, searchKey, filterValue) : formatHighlighter(cellContent, searchKey, filterValue),
	},
	{
		dataField: "non_mrp",
		text: "Non MRP",
		sort: false,
		formatter: (cellContent, row) => cellContent ? "Yes" : "No",
	},
	{
		dataField: "approve",
		text: "Approval Status",
		sort: false,
		formatter: (cellContent, row) => <p style={{ color: cellContent ? "" : "#F93B55" }}>{cellContent ? "Approved" : "Not Approved"}</p>,
	},
	{
		text: "Supplier Name",
		dataField: "material_supplier_name",
		sort: true,
		formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue),
	},
	{
		text: "Supplier Contact",
		dataField: "material_supplier_contact",
		sort: true,
		formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue),
	},
	{
		text: "Created At",
		dataField: "created_at",
		sort: false,
		formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
	},
	{
		dataField: "view",
		isDummyField: true,
		text: "Action",
		sort: false,
		// eslint-disable-next-line react/display-name
		formatter: (cellContent, row) => (
			<Dropdown>
				<Dropdown.Toggle variant="transparent" id="dropdown-basic">
					<GoKebabVertical />
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={()=>history.push(`/groceries/ecommerce-products/detail/${row.id}`)}>View Details</Dropdown.Item>
					{(row.non_mrp && isEdit) && (
						<Dropdown.Item onClick={() => {
							if (row.approve) {
								handleChangeStatus(row.product)
							} else {
								setSelectedProduct(row.product)
								setEditModalOpen(true)
							}
						}}>
							{row.approve ? "Reject" : "Approve"}
						</Dropdown.Item>
					)}
				</Dropdown.Menu>
			</Dropdown>
		),
	},
]
export const BuildProductSearchQuery = (value, queryCondition) => {
	if (value.length >= 3) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		conditionTemp = {
			...conditionTemp,
			_or: [
				{ sku: { _ilike: `%${value}%` } },
				{ master_product: { name: { _ilike: `%${value}%` } } },
				{ nonmrp_product_name: { _ilike: `%${value}%` } },
				{ store: { name: { _ilike: `%${value}%` } } },
				{ store: { store_type: { _ilike: `%${value}%` } } },
				{ master_product: { product_brand: { name: { _ilike: `%${value}%` } } } },
				{ master_product: { category: { name: { _ilike: `%${value}%` } } } },
				{ master_product: { subcategory: { name: { _ilike: `%${value}%` } } } },
				{ nonmrp_category: { name: { _ilike: `%${value}%` } } },
				{ nonmrp_subcategory: { name: { _ilike: `%${value}%` } } },
				{ material_supplier: { name: { _ilike: `%${value}%` } } },
				{ material_supplier: { contact_no: { _ilike: `%${value}%` } } },
			]
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}
export const BuildProductFilterQuery = (filterValue, queryCondition) => {
	if (Object.keys(filterValue).length) {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		if (filterValue?.hasOwnProperty("product_brand")) {
			if (filterValue.product_brand?.length > 0) {
				conditionTemp = {
					...conditionTemp,
					master_product: { product_brand: { id: { _in: filterValue.product_brand } } }
				}
			}
		}
		if (filterValue?.hasOwnProperty("category")) {
			if (filterValue.category?.length > 0) {
				if (conditionTemp.master_product) {
					conditionTemp = {
						...conditionTemp,
						master_product: {
							...conditionTemp.master_product,
							category: { id: { _in: filterValue.category } }
						}
					}
				} else {
					conditionTemp = {
						...conditionTemp,
						master_product: {
							category: { id: { _in: filterValue.category } }
						}
					}
				}
			}
		}
		if (filterValue?.hasOwnProperty("subcategory")) {
			if (filterValue.subcategory?.length > 0) {
				if (conditionTemp.master_product) {
					conditionTemp = {
						...conditionTemp,
						master_product: {
							...conditionTemp.master_product,
							subcategory: { id: { _in: filterValue.subcategory } }
						}
					}
				} else {
					conditionTemp = {
						...conditionTemp,
						master_product: {
							subcategory: { id: { _in: filterValue.subcategory } }
						}
					}
				}
			}
		}
		if (filterValue?.hasOwnProperty("store")) {
			if (filterValue.store?.length > 0) {
				conditionTemp = {
					...conditionTemp,
					store: { id: { _in: filterValue.store } }
				}
			}
		}
		if (filterValue?.hasOwnProperty("store_type")) {
			if (filterValue.store_type?.length > 0) {
				conditionTemp = {
					...conditionTemp,
					store: { store_type: { _in: filterValue.store_type } }
				}
			}
		}
		if (filterValue?.hasOwnProperty("created_at")) {
			if (Array.isArray(filterValue.created_at)) {
				conditionTemp = {
					...conditionTemp,
					_and: [{ created_at: { _gte: filterValue.created_at[0] } }, { created_at: { _lte: filterValue.created_at[1] } }]
				}
			}
		}
		if (filterValue?.hasOwnProperty("non_mrp")) {
			conditionTemp = {
				...conditionTemp,
				non_mrp: { _eq: filterValue.non_mrp === "yes" ? true : false }
			}
		}
		return conditionTemp
	} else {
		let conditionTemp = {}
		if (queryCondition !== null) {
			conditionTemp = { ...queryCondition }
		}
		return conditionTemp
	}
}