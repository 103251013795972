import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter, Link } from "react-router-dom"
import { Col, Row } from "reactstrap"
import EcommerceOrdersTable from "../Ecommerce/EcommerceOrders/EcommerceOrdersTable"

const LatestOrders = (props) => {
  const pathName = props.location.pathname

  return (
    <div className="p-3">
      <Row>
        <Row className="mb-2">
          <Col sm="8">
            <h4>
              Latest{" "}
              {pathName.startsWith("/groceries")
                ? "Orders"
                : pathName.startsWith("/services")
                ? "Bookings"
                : pathName.startsWith("/vehicles")
                ? "Rentals"
                : "Bookings"}
            </h4>
          </Col>
          <Col sm="4">
            <div className="text-sm-end d-flex justify-content-end">
              <Link
                to={
                  pathName == "/groceries/dashboard"
                    ? "/groceries/ecommerce-orders"
                    : pathName == "/services/dashboard"
                    ? "/services/service-bookings"
                    : pathName == "/services/dashboard"
                    ? "/vehicles/service-bookings"
                    : pathName == "/property/dashboard"
                    ? "/property/property-bookings"
                    : pathName == "/experience/dashboard"
                    ? "/experience/experience-bookings"
                    : null
                }
              >
                View more
              </Link>
            </div>
          </Col>
        </Row>
        <hr />
        <EcommerceOrdersTable
          queryCondition={
            pathName == "/groceries/dashboard"
              ? {
                  order_type: {
                    _in: ["delivery", "home_delivery", "in_store_pickup"],
                  },
                }
              : pathName == "/services/dashboard"
              ? { order_type: { _eq: "service" } }
              : pathName == "/vehicles/dashboard"
              ? { order_type: { _eq: "rental" } }
              : pathName == "/property/dashboard"
              ? { order_type: { _eq: "property" } }
              : pathName == "/experience/dashboard"
              ? { order_type: { _eq: "experience" } }
              : {}
          }
          activeFilters={[
            "status",
            "store",
            "created_at",
            "city",
            "total_amount",
            "delivery_charges",
            "order_items",
          ]}
          filterQueryConditions={null}
          showFilters={false}
        />
      </Row>
    </div>
  )
}
LatestOrders.propTypes = {
  location: PropTypes.object,
}
export default withRouter(LatestOrders)
