import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker } from 'rsuite'
import { Dropdown } from "react-bootstrap"
import { useState } from "react"
import PropTypes from "prop-types"
import { ProductStoreFilter, CustomerCityFilter, ProductCategoryFilter, ProductSubCategoryFilter, LandmarkStoreFilter, ServiceFilter, ServiceCategoryFilter, VehicleBrandsFilter, VehicleMasterMakeFilter, VehicleMasterModelFilter, VehicleRentalsVehickeGroupFilter, VehicleRentalsFuelTypeFilter, VehicleRentalsTransmissionTypeFilter, VehicleRentalsStoreFilter, VehicleRentalsVehicleBrandFilter, VehicleRentalsCreatedByFilter, VehicleRentalsFiltersFilterNameFilter, VehicleRentalsFiltersVehicleGroupFilter, RentalVehicleTypesFilters } from "../../../gql/query"
import {
	AddOrderFormDataQuery,
} from "../../../pages/Ecommerce/EcommerceOrders/EcommerceOrders.query"

import { useQuery } from "@apollo/client"
//load components
import CheckPickerPaginate from "../CheckPickerPaginate"

const RentalVehicleTypesFilter = (props) => {
 	const [filterData, setFilterData] = useState([])
	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})
	const amountRangeData = [{ label: "< ₹200", value: "<200" }, { label: "₹200 - ₹500", value: "200-500" }, { label: "₹500 - ₹750", value: "500-750" }, { label: "₹750 - ₹1000", value: "750-1000" }, { label: "> ₹1000", value: ">1000" }]
	const deliveryChargesData = [{ label: "< ₹25", value: "<25" }, { label: "₹25 - ₹50", value: "25-50" }, { label: "₹50 - ₹75", value: "50-75" }, { label: "> ₹75", value: "75" }]
	const orderItemsData = [{ label: "< 5", value: "<5" }, { label: "1 - 5", value: "1-5" }, { label: "5 - 10", value: "5-10" }, { label: "10 - 15", value: "10-15" }, { label: "15 - 20", value: "15-20" }, { label: "> 20", value: ">20" }]
	const [statusData, setStatusData] = useState([])

	const { data:statuses, loading, error } = useQuery(AddOrderFormDataQuery, {
		skipCache: true
	})

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
	}
	const getFilterCondition = (filterKey) => {
		if (props.filterQueryConditions !== null) {
			let condition = props.filterQueryConditions.filter(condition => condition.filter === filterKey)
			if (condition.length) {
				return condition[0]?.condition
			}
		} else {
			return null
		}
	}

 	useEffect(() => {
		props.onFilterChange(filterValues)
 	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			let filterValuesTemp = { ...filterValues }
			if (!filters.includes("filter_name")) {
				delete filterValuesTemp["filter_name"]
			}
 
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])
	console.log("activeFilters", props?.activeFilters)
	useEffect(() => {
		let filterDataTemp = []
		props.activeFilters.map((filter) => {
			if (filter === "type_name") {
				filterDataTemp.push({ label: "Filter Name", value: "type_name" })
			}
	 
 
		})
		setFilterData(filterDataTemp)
	}, [props.activeFilters])
	useEffect(()=>{
		if(Array.isArray(statuses?.enum_order_status)){
			let statusTemp = []
			statuses.enum_order_status.map((status)=>{
				statusTemp.push({
					label: status.comment,
					value: status.value,
					type: ["acknowledged", "submitted", "cancelled", "rejected"].includes(status.value) ? "EEfind" : "Dunzo"
				})
			})
			setStatusData(statusTemp)
		}
	}, [statuses])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "type_name") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={RentalVehicleTypesFilters}
								placeholder="Select group"
								value={Array.isArray(filterValues.type_name) ? filterValues.type_name : []}
								onChange={(type_name) => setFilterValues({ ...filterValues, type_name })}
								arrKey="type_name"
								objectLabel="type_name"
								objectValue="type_name"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("type_name")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("type_name")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}

				if (filter === "vehicle_group") {
					return (
					<React.Fragment key={i}>
							<CheckPickerPaginate
								query={VehicleRentalsFiltersVehicleGroupFilter}
								placeholder="Select fuel type"
								value={Array.isArray(filterValues.vehicle_group) ? filterValues.vehicle_group : []}
								onChange={(vehicle_group) => setFilterValues({ ...filterValues, vehicle_group })}
								arrKey="vehicle_group"
								objectLabel="vehicle_group"
								objectValue="vehicle_group"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("vehicle_group")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("vehicle_group")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "transmission_type") {
					return (
					<React.Fragment key={i}>
							<CheckPickerPaginate
								query={VehicleRentalsTransmissionTypeFilter}
								placeholder="Select transmission_type"
								value={Array.isArray(filterValues.transmission_type) ? filterValues.transmission_type : []}
								onChange={(transmission_type) => setFilterValues({ ...filterValues, transmission_type })}
								arrKey="transmission_type"
								objectLabel="transmission_type"
								objectValue="transmission_type"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("transmission_type")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("transmission_type")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "vehicle_brand") {
					return (
					<React.Fragment key={i}>
							<CheckPickerPaginate
								query={VehicleRentalsVehicleBrandFilter}
								placeholder="Select vehicle_brand"
								value={Array.isArray(filterValues.vehicle_brand) ? filterValues.vehicle_brand : []}
								onChange={(vehicle_brand) => setFilterValues({ ...filterValues, vehicle_brand })}
								arrKey="vehicle_brand"
								objectLabel="brand_name"
								objectValue="brand_name"
								nestedObj="vehicle_brand"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("vehicle_brand")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("vehicle_brand")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}

				if (filter === "created_by") {
					return (
					<React.Fragment key={i}>
							<CheckPickerPaginate
								query={VehicleRentalsCreatedByFilter}
								placeholder="Select created_by"
								value={Array.isArray(filterValues.created_by) ? filterValues.created_by : []}
								onChange={(created_by) => setFilterValues({ ...filterValues, created_by })}
								arrKey="user"
								objectLabel="name"
								objectValue="name"
								nestedObj="user"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("created_by")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_by")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				// if (filter === "service") {
				// 	return (
				// 		<React.Fragment key={i}>
				// 			<CheckPicker
				// 				data={statusData}
				// 				placeholder={"Select Service"}
				// 				style={{ marginLeft: 10 }}
				// 				value={Array.isArray(filterValues.status) ? filterValues.status : []}
				// 				onChange={(status) => setFilterValues({ ...filterValues, status })}
				// 				searchable={false}
				// 				groupBy="type"
				// 			/>
				// 			<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("status")}>
				// 				<i className="bx bx-x-circle" />
				// 			</div>
				// 		</React.Fragment>
				// 	)
				// }
				if (filter === "store") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={VehicleRentalsStoreFilter}
								placeholder="Select store"
								value={Array.isArray(filterValues.store) ? filterValues.store : []}
								onChange={(store) => setFilterValues({ ...filterValues, store })}
								arrKey="store"
								objectLabel="name"
								objectValue="name"
								nestedObj="store"
								nested={true}

								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={null}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("store")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				 if (filter === "service_category") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ServiceCategoryFilter}
								placeholder="Select Category"
								value={Array.isArray(filterValues.service_category) ? filterValues.service_category : []}
								onChange={(service_category) => setFilterValues({ ...filterValues, service_category })}
								arrKey="category"
								objectLabel="category"
								objectValue="category"
								nestedObj="service_category"
								nested={true}

								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={null}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("city")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}

				if (filter === "ss") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={ServiceCategoryFilter}
								placeholder={"Select amount ranges"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.total_amount) ? filterValues.total_amount : []}
								onChange={(total_amount) => setFilterValues({ ...filterValues, total_amount })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("total_amount")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "delivery_charges") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={deliveryChargesData}
								placeholder={"Select delivery charge ranges"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.delivery_charges) ? filterValues.delivery_charges : []}
								onChange={(delivery_charges) => setFilterValues({ ...filterValues, delivery_charges })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("delivery_charges")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "order_items") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={orderItemsData}
								placeholder={"No. of Ordered Items"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.order_items) ? filterValues.order_items : []}
								onChange={(order_items) => setFilterValues({ ...filterValues, order_items })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("order_items")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "category") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ProductCategoryFilter}
								placeholder="Select Category"
								value={Array.isArray(filterValues.category) ? filterValues.category : []}
								onChange={(category) => setFilterValues({ ...filterValues, category })}
								arrKey="categories"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("category")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("category")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "subcategory") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ProductSubCategoryFilter}
								placeholder="Select Sub Category"
								value={Array.isArray(filterValues.subcategory) ? filterValues.subcategory : []}
								onChange={(subcategory) => setFilterValues({ ...filterValues, subcategory })}
								arrKey="subcategories"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("subcategory")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("subcategory")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
RentalVehicleTypesFilter.propTypes = {
	onFilterChange: PropTypes.func,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any
}
export default RentalVehicleTypesFilter