import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker, SelectPicker } from 'rsuite'
import { Dropdown } from "react-bootstrap"
import { useState } from "react"
import PropTypes from "prop-types"
import { ProductBrandFilter, ProductCategoryFilter, ProductSubCategoryFilter, ProductStoreFilter } from "../../../gql/query"
//load components
import CheckPickerPaginate from "../CheckPickerPaginate"

const EcommerceProductsFilter = (props) => {
	const [filterData, setFilterData] = useState([])
	const docsData = [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
	const [storeTypeData, setStoreTypeData] = useState([{ label: "Groceries and Essentials", value: "Groceries and Essentials" }, { label: "Books", value: "Books" }])

	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
	}
	const getFilterCondition = (filterKey) => {
		if (props.filterQueryConditions !== null) {
			let condition = props.filterQueryConditions.filter(condition => condition.filter === filterKey)
			if (condition.length) {
				return condition[0]?.condition
			}
		} else {
			return null
		}
	}
	useEffect(() => {
		props.onFilterChange(filterValues)
	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			let filterValuesTemp = { ...filterValues }
			if (!filters.includes("product_brand")) {
				delete filterValuesTemp["product_brand"]
			} 
			if (!filters.includes("created_at")) {
				delete filterValuesTemp["created_at"]
			} 
			if (!filters.includes("category")) {
				delete filterValuesTemp["category"]
			} 
			if (!filters.includes("subcategory")) {
				delete filterValuesTemp["subcategory"]
			} 
			if (!filters.includes("store")) {
				delete filterValuesTemp["store"]
			} 
			if (!filters.includes("non_mrp")) {
				delete filterValuesTemp["non_mrp"]
			}
			if (!filters.includes("store_type")) {
				delete filterValuesTemp["store_type"]
			}
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])

	useEffect(() => {
		let filterDataTemp = []
		props.activeFilters.map((filter) => {
			if (filter === "product_brand") {
				filterDataTemp.push({ label: "Brand", value: "product_brand" })
			}
			if (filter === "category") {
				filterDataTemp.push({ label: "Category", value: "category" })
			}
			if (filter === "subcategory") {
				filterDataTemp.push({ label: "Sub Category", value: "subcategory" })
			}
			if (filter === "store") {
				filterDataTemp.push({ label: "Store", value: "store" })
			}
			if (filter === "store_type") {
				filterDataTemp.push({ label: "Store Type", value: "store_type" })
			}
			if (filter === "created_at") {
				filterDataTemp.push({ label: "Created At", value: "created_at" })
			}
			if (filter === "non_mrp") {
				filterDataTemp.push({ label: "Non MRP", value: "non_mrp" })
			}
		})
		setFilterData(filterDataTemp)
	}, [props.activeFilters])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "created_at") {
					return (
						<React.Fragment key={i}>
							<DateRangePicker
								placement="auto"
								onChange={(created_at) => setFilterValues({ ...filterValues, created_at })}
								style={{ marginLeft: 10 }}
								placeholder="Select Created At Date"
								value={Array.isArray(filterValues.created_at) ? filterValues.created_at : []}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_at")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "product_brand") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ProductBrandFilter}
								placeholder="Select Brand"
								value={Array.isArray(filterValues.product_brand) ? filterValues.product_brand : []}
								onChange={(product_brand) => setFilterValues({ ...filterValues, product_brand })}
								arrKey="product_brand"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("product_brand")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("product_brand")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "category") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ProductCategoryFilter}
								placeholder="Select Category"
								value={Array.isArray(filterValues.category) ? filterValues.category : []}
								onChange={(category) => setFilterValues({ ...filterValues, category })}
								arrKey="categories"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("category")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("category")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "subcategory") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ProductSubCategoryFilter}
								placeholder="Select Sub Category"
								value={Array.isArray(filterValues.subcategory) ? filterValues.subcategory : []}
								onChange={(subcategory) => setFilterValues({ ...filterValues, subcategory })}
								arrKey="subcategories"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("subcategory")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("subcategory")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "store") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ProductStoreFilter}
								placeholder="Select Store"
								value={Array.isArray(filterValues.store) ? filterValues.store : []}
								onChange={(store) => setFilterValues({ ...filterValues, store })}
								arrKey="stores"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("store")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("store")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "non_mrp") {
					return (
						<React.Fragment key={i}>
							<SelectPicker
								data={docsData}
								placeholder={"Select an Option"}
								style={{ marginLeft: 10 }}
								onChange={(non_mrp) => setFilterValues({ ...filterValues, non_mrp })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("non_mrp")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "store_type") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={storeTypeData}
								placeholder={"Select Shop Type"}
								style={{ marginLeft: 10 }}
								// value={Array.isArray(filterValues.store_type) || []}
								onChange={(store_type) => setFilterValues({ ...filterValues, store_type })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("store_type")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
EcommerceProductsFilter.propTypes = {
	onFilterChange: PropTypes.func,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any
}
export default EcommerceProductsFilter