import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Col, Container, Row, Button } from "reactstrap"
import moment from "moment"
import { Badge } from "react-bootstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import download from "downloadjs"
import Swal from "sweetalert2"
import { useSelector } from "react-redux"
import { RiH4 } from "react-icons/ri"

const RentalChecklist = (props) => {
  const [vehicleDetails, setVehicleDetails] = useState([])

  const FormatData = (elem) => {
    return {
      name: elem?.name || elem?.driver_name,
      photo_url: elem?.photo_url || elem?.image_url,
      email: elem?.email,
      phone: elem?.phone,
    }
    return FormatData
  }

  useEffect(() => {
    if (props.order?.rental_order_items?.length) {
      setVehicleDetails(props.order.rental_order_items[0])
    }
  }, [props.order])

  console.log("Driver details", vehicleDetails)

  return (
    <Container>
      <Row>
        <Col sm="6" className="pl-3">
          <h3 className="mb-3">Pickup Data</h3>
          <h5 className="mb-2">Features</h5>
          <Row>
            <Col>
              Air Conditioning
              <p>
                {vehicleDetails?.pickup_data?.features?.air_conditioning == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>

            <Col>
              Bluetooth
              <p>
                {vehicleDetails?.pickup_data?.features?.bluetooth == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>
            <Col>
              Electrical Sublinds
              <p>
                {vehicleDetails?.pickup_data?.features?.electrical_sublinds ==
                true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              KeyLess
              <p>
                {vehicleDetails?.pickup_data?.features?.key_less == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>
            <Col>
              Power Steering
              <p>
                {vehicleDetails?.pickup_data?.features?.power_steering == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>
            <Col>
              Ventialted Front Seats
              <p>
                {vehicleDetails?.pickup_data?.features
                  ?.ventialted_front_seats == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              Odometer Image
              <img
                src={
                  vehicleDetails?.pickup_data?.odometer_image.length > 0
                    ? vehicleDetails?.pickup_data?.odometer_image
                    : "/placeholder.jpg"
                }
                className="w-50 mt-1"
              />
            </Col>
            <Col>
              Fuel Percentage
              <p>{vehicleDetails?.pickup_data?.fuel_percentage}</p>
            </Col>
            <Col>
              Vehicle Group
              <p>{vehicleDetails?.pickup_data?.vehicle_group}</p>
            </Col>
          </Row>
          <hr />
          <h4 className="mb-3">Pickup Photos</h4>
          <h6 className="mb-3">Interior Photos</h6>
          {vehicleDetails?.pickup_photos?.interior.map((data, i) => (
            <Row key={i}>
              <Col>
                Type
                <p>{data.type}</p>
              </Col>
              <Col>
                Damage
                <p>{data.is_damaged == true ? "Present" : "N/A"}</p>
              </Col>
              <Col>
                Damage Type
                <p>{data.damage_type}</p>
              </Col>
              <img
                src={
                  data.photos.length > 0 ? data.photos[0] : "/placeholder.jpg"
                }
                className="w-20 mt-1"
              />
            </Row>
          ))}
          <h6 className="mb-3">Exterior Photos</h6>
          {vehicleDetails?.pickup_photos?.exterior.map((data, i) => (
            <Row key={i}>
              <Col>
                Type
                <p>{data.type}</p>
              </Col>
              <Col>
                Damage
                <p>{data.is_damaged == true ? "Present" : "N/A"}</p>
              </Col>
              <Col>
                Damage Type
                <p>{data.damage_type}</p>
              </Col>
              <img
                src={
                  data.photos.length > 0 ? data.photos[0] : "/placeholder.jpg"
                }
                className="w-20 mt-1"
              />
            </Row>
          ))}
        </Col>
        <Col sm="6" className="pl-3">
          <h3 className="mb-3">Dropoff Data</h3>
          <h5 className="mb-2">Features</h5>
          <Row>
            <Col>
              Balance
              <p>
                {vehicleDetails?.drop_off_data?.features_status?.balance == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>

            <Col>
              Parking
              <p>
                {vehicleDetails?.drop_off_data?.features_status?.parking == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>
            <Col>
              Reading
              <p>
                {vehicleDetails?.drop_off_data?.features_status?.reading == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>
            <Col>
              Vehicle
              <p>
                {vehicleDetails?.drop_off_data?.features_status?.vehicle == true
                  ? "Avaliable"
                  : "N/A"}
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              Odometer Image
              <br/>
              <img
                src={
                  vehicleDetails?.drop_off_data?.odometer_image.length > 0
                    ? vehicleDetails?.drop_off_data?.odometer_image
                    : "/placeholder.jpg"
                }
                className="w-50"
              />
            </Col>
            <Col>
              Fuel Percentage
              <p>{vehicleDetails?.drop_off_data?.fuel_percentage}</p>
            </Col>
          </Row>
          <hr />
          <h4 className="mb-3">Dropoff Photos</h4>
          <h6 className="mb-3">Exterior Photos</h6>
          {vehicleDetails?.drop_off_photos?.exterior.map((data, i) => (
            <Row key={i}>
              <Col>
                Type
                <p>{data.type}</p>
              </Col>
              <Col>
                Damage
                <p>{data.is_damaged == true ? "Present" : "N/A"}</p>
              </Col>
              <Col>
                Damage Type
                <p>{data.damage_type}</p>
              </Col>
              <img
                src={
                  data.photos.length > 0 ? data.photos[0] : "/placeholder.jpg"
                }
                className="w-20 mt-1"
              />
            </Row>
          ))}
          {/* <h6 className="mb-3">Exterior Photos</h6>
          {vehicleDetails?.pickup_photos?.exterior.map((data, i) => (
            <Row key={i}>
              <Col>
                Type
                <p>{data.type}</p>
              </Col>
              <Col>
                Damage
                <p>{data.is_damaged == true ? "Present" : "N/A"}</p>
              </Col>
              <Col>
                Damage Type
                <p>{data.damage_type}</p>
              </Col>
              <img
                src={
                  data.photos.length > 0 ? data.photos[0] : "/placeholder.jpg"
                }
                className="w-20 mt-1"
              />
            </Row>
          ))} */}
        </Col>
      </Row>
    </Container>
  )
}
RentalChecklist.propTypes = {
  order: PropTypes.any,
}
RentalChecklist.displayName = "RentalChecklist"
export default RentalChecklist
