import {React} from "react"
import PropTypes from "prop-types"
import ReactHTMLTableToExcel from 'react-html-table-to-excel'


export const HtmlToExcel = ({ headers=[] , ButtonText="Template", Title="", key={}, FileName="EEfind",Sheet="sheet1"}) =>{
  return (
    // <div className="App">
      <>
        <table id={FileName} className="table" style={{ display:"none"  }}>  
              <thead style={{visibility:"hidden", height:"0px"}}>  
                      <tr> { headers?.map((e,i)=><td key={i}>{e}</td>) } </tr>  
              </thead>  
        </table>  

        <div >  
                <ReactHTMLTableToExcel className={"btn btn-sm btn-primary m-1"} table={FileName} filename={Title} sheet={Sheet} buttonText={ButtonText} />  
        </div>
    </>
  )
}

HtmlToExcel.propTypes = {
    headers: PropTypes.any,
    Title: PropTypes.any,
    key: PropTypes.any,
    FileName: PropTypes.any,
    Sheet:PropTypes.any,
    ButtonText:PropTypes.string
}
