import React, { useEffect, useState } from "react"
import { Modal, Timeline, Panel } from "rsuite"
import PropTypes from "prop-types"
import {
    Button,
    Col,
    Row
} from "reactstrap"
import { CreditCardPlus, Check, Close } from '@rsuite/icons'
import { GoFileSymlinkFile, GoX } from "react-icons/go"
import { FiUserCheck, FiCheckCircle } from "react-icons/fi"
import { GiScooter } from "react-icons/gi"
import { GrRestaurant } from "react-icons/gr"
import { BiAddToQueue } from "react-icons/bi"
import * as moment from "moment"

//GraphQL
import { useApolloClient } from "@apollo/client"

const OrderTimeline = (props) => {
    const client = useApolloClient()
    const formatPrice = (value) => {
        if (!isNaN(value) && value !== null && value !== undefined) {
            return `₹${parseFloat(value).toFixed(2)}`
        } else {
            return "N/A"
        }
    }
    const isRefundApplicable = () => props.order?.order_status === "cancelled" || props.order?.order_status === "rejected" || props.order?.cancelled_amount !== null
    const formatStatus = (status) => {
        switch (status) {
            case "queued":
                return { title: "Queued", description: "", icon: <BiAddToQueue size={25} /> }
            case "runner_accepted":
                return { title: "Runner Accepted", description: "", icon: <FiUserCheck size={25} /> }
            case "reached_for_pickup":
                return { title: "Reached for Pickup", description: "", icon: <GiScooter size={25} /> }
            case "pickup_complete":
                return { title: "Pickup Complete", description: "", icon: <GrRestaurant size={25} /> }
            case "started_for_delivery":
                return { title: "Started for Delivery", description: "", icon: <GiScooter size={25} /> }
            case "reached_for_delivery":
                return { title: "Reached for Delivery", description: "", icon: <GiScooter size={25} /> }
            case "delivered":
                return { title: "Delivered", description: "", icon: <FiCheckCircle size={25} /> }
            case "cancelled":
                return { title: "Cancelled", description: "", icon: <GoX size={25} /> }
            default:
                return status
        }
    }
    return (
        <Row style={{ marginTop: 10 }}>
            <Col xs="12">
                <Timeline className="payout-timeline">
                    <Timeline.Item dot={
                        <div className="icon">
                            <GoFileSymlinkFile size={25} />
                        </div>
                    }>
                        <strong>Submitted</strong>
                        <p>Order is placed by {props.order?.customer?.name}</p>
                    </Timeline.Item>
                    <Timeline.Item>
                        <strong>Submitted On: </strong> {moment(props.order?.created_at).format("DD/MM/YYYY h:mm A")}
                    </Timeline.Item>
                    <Timeline.Item>
                        <strong>Payable Amount: </strong> {formatPrice(props.order?.total_amount)}
                    </Timeline.Item>
                    {props.order?.confirmed_at && (
                        <>
                            <Timeline.Item dot={
                                <div className="icon">
                                    <GoFileSymlinkFile size={25} />
                                </div>
                            }>
                                <strong>Acknowledged</strong>
                                <p>Order is confirmed by {props.order?.store?.name}</p>
                            </Timeline.Item>
                            <Timeline.Item>
                                <strong>Confirmed On: </strong> {moment(props.order?.confirmed_at).format("DD/MM/YYYY h:mm A")}
                            </Timeline.Item>
                        </>
                    )}
                    {(props.order?.order_status === "cancelled" || props.order?.order_status === "rejected") ? (
                        <>
                            <Timeline.Item dot={
                                <div className="icon">
                                    <GoX size={25} />
                                </div>
                            }>
                                <strong style={{ textTransform: "capitalize" }}>{props.order?.order_status}</strong>
                                <p>{props.order?.order_status === "cancelled" ? "Order cancelled by the customer" : "Order rejected by the merchant"}</p>
                            </Timeline.Item>
                            <Timeline.Item>
                                <strong>{props.order?.order_status === "cancelled" ? "Cancelled On: " : "Rejected On: "}: </strong> {moment(props.order?.order_status === "cancelled" ? props.order?.cancelled_at : props.order?.rejected_at).format("DD/MM/YYYY h:mm A")}
                            </Timeline.Item>
                            {isRefundApplicable() && (
                                <>
                                    <Timeline.Item>
                                        <strong>Refunded: </strong> <span className="text-danger">{props.order?.payments[0]?.refunded === true ? "Yes" : "No"}</span>
                                    </Timeline.Item>
                                    <Timeline.Item>
                                        <strong>Refunded Amount: </strong> <span className="text-danger">{props.order?.payments[0]?.payment_gateway === "razorpay" ? formatPrice(props.order?.payments[0]?.refunded_amount / 100) : formatPrice(props.order?.payments[0]?.refunded_amount)}</span>
                                    </Timeline.Item>
                                </>
                            )}
                        </>
                    ) : (
                        Array.isArray(props.order?.delivery_request?.update_history) && (
                            <>
                                {props.order?.delivery_request?.update_history?.map((history) => (
                                    <>
                                        <Timeline.Item dot={
                                            <div className="icon">
                                                {formatStatus(history.status).icon}
                                            </div>
                                        }>
                                            <strong>{formatStatus(history.status).title}</strong>
                                            <p></p>
                                        </Timeline.Item>
                                        <Timeline.Item>
                                            <strong>Timestamp: </strong> {moment(history.time).format("DD/MM/YYYY h:mm A")}
                                        </Timeline.Item>
                                        {(history.hasOwnProperty("delivery_agent") && history.status === "runner_accepted") && (
                                            <>
                                                <Timeline.Item>
                                                    <strong>Delivery Boy&apos;s Name: </strong> <span>{history.delivery_agent?.name}</span>
                                                </Timeline.Item>
                                                <Timeline.Item>
                                                    <strong>Delivery Boy&apos;s Phone: </strong> <span>{history.delivery_agent?.phone}</span>
                                                </Timeline.Item>
                                            </>
                                        )}
                                        {history.cancelled_by && (
                                            <Timeline.Item>
                                                <strong>Cancelled By: </strong> <span className="text-danger">{history.cancelled_by}</span>
                                            </Timeline.Item>
                                        )}
                                        {history.cancelled_reason && (
                                            <Timeline.Item>
                                                <strong>Cancelled Reason: </strong> <span className="text-danger">{history.cancelled_reason}</span>
                                            </Timeline.Item>
                                        )}
                                    </>
                                ))}
                            </>
                        )
                    )}
                </Timeline>
            </Col>
        </Row>
    )
}
OrderTimeline.propTypes = {
    order: PropTypes.any
}
export default OrderTimeline