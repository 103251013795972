import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Card, CardBody } from 'reactstrap'
import { withRouter } from "react-router-dom"
import { VehicleBrandList } from "gql/query"
import { useLazyQuery, useMutation } from "@apollo/client"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from "react-icons/go"
import PropTypes from "prop-types"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useSelector } from "react-redux"
import { SelectPicker } from "rsuite"
import Loader from "../../../components/Loader/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import VehicleBrandCreate from './vehicle-brand-create'
import moment from "moment"
import { Delete_vehicle_brand_data } from 'gql/Mutation'
import Swal from 'sweetalert2'
import EcommerceServicesFilter from 'components/Filters/Ecommerce/EcommerceServicesFilter'
import EcommerceRentalBrandFilter from 'components/Filters/Ecommerce/EcommerceRentalsBrandFilter'
import { BuildBrandFilterQuery } from './Vehicle-brands.functions'
 

const FormatBrandData = (data) => {
    const brands = data.rental_vehicle_brands.map((elem) => {
        let image = "/placeholder.jpg"
        return {
            id: elem.id,
            logo_url: elem.logo_url || image,
            brand_name: elem.brand_name,
            vehicle_group: elem.vehicle_group,
            created_at: elem.created_at,
            active: elem.active,
        }
    })
    return brands
}

const VehicleBrandsList = (props) => {
    const { capabilities } = useSelector((state) => state.Login)
    const [queryCondition, setQueryCondition] = useState({})
    const [brands, setBrands] = useState([])
    const [modal, setModal] = useState(false)
    const [brandModalOpen, setBrandOpen] = useState(false)
    const [selectedBrand, setSelectedBrand] = useState({})
    const [PageData, setPageData] = useState({ limit: 10, pageNo: 1 })
    const [deleteVehicleBrand] = useMutation(Delete_vehicle_brand_data)
  const [filterValue, setFilterValue] = useState("")

    var node = useRef()
    const limitData = [
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "200", value: 200 },
        { label: "500", value: 500 },
    ]
    const defaultSorted = []

    const VehicleBrandColumns = (toggleModal) => [
        {
            text: "ID",
            dataField: "id",
            sort: false,
        },
        {
            text: "Brand Name",
            dataField: "brand_name",
            sort: true,
        },
        {
            dataField: "logo_url",
            text: "Logo",
            sort: false,
            formatter: (cellContent, row) => (
                <img src={row.logo_url} style={{ height: 80 }} />
            ),
        },
        {
            text: "Vehicle Group",
            dataField: "vehicle_group",
            sort: false,
            formatter: (cellContent, row) => row?.vehicle_group?.map((e,i)=>e?.replaceAll("_",""))?.join(","),
        },
        {
            text: "Created At",
            dataField: "created_at",
            sort: false,
            formatter: (cellContent, row) =>
                moment(row.created_at).format("DD/MM/YYYY h:mm A"),
        },
        {
            dataField: "active",
            text: "Active",
            formatter: (cellContent, row) => (
                <p style={{ color: row.status ? "" : "#F93B55" }}>
                    {row.active == true ? "Yes" : "No"}
                </p>
            ),
        },
        {
            dataField: "view",
            isDummyField: true,
            text: "Action",
            sort: false,
            hidden: !capabilities.includes("edit"),
            formatter: (cellContent, row) => (
                <Dropdown>
                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                        <GoKebabVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                setSelectedBrand(row)
                                setBrandOpen(true)
                            }}
                        >
                            Edit
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => {
                                mutateRecord(row?.id)
                            }}
                        >
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ),
        },
    ]
    const handleSearch = (e) => {
        let value = e.target.value
        if (value.length >= 2) {
            let conditionTemp = {}
            if (props.queryCondition !== null) {
                conditionTemp = { ...props.queryCondition }
            }
            conditionTemp = {
                ...conditionTemp,
                _or: [{ brand_name: { _ilike: `%${value}%` } }],
            }
            setQueryCondition(conditionTemp)
        } else {
            let conditionTemp = {}
            if (props.queryCondition !== null) {
                conditionTemp = { ...props.queryCondition }
            }
            setQueryCondition(conditionTemp)
            refetch()
        }
    }
    const PageDown = () => {
        if (PageData.pageNo > 0)
            setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
    }
    const PageUp = () => {
        setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
    }
    const toggle = () => {
        setModal(!modal)
    }
    const handleTableChange = (type, { page, searchText }) => { }
    const [getBrandList, { loading, data, refetch, error }] = useLazyQuery(
        VehicleBrandList,
        {
            skipCache: true,
            variables: { limit: PageData.limit, offset: (PageData.pageNo - 1) * PageData.limit, where: queryCondition },
        }
    )
    useEffect(() => {
        if (props.queryCondition !== null) {
            setQueryCondition(props.queryCondition)
        } else {
            getBrandList()
        }
    }, [props.queryCondition])
    useEffect(() => {
        if (Object.keys(queryCondition).length) {
            getBrandList()
        } else {
            getBrandList()
        }
    }, [queryCondition])
    useEffect(() => {
        if (data) {
            setBrands(FormatBrandData(data))
        }
    }, [data])

    useEffect(() => {
        let conditionTemp = BuildBrandFilterQuery(filterValue, props.queryCondition)
         setQueryCondition(conditionTemp)
      }, [filterValue])


    const count = data?.rental_vehicle_brands_aggregate?.aggregate?.count || 0
    const maxPage = Math.ceil(count / PageData.limit)

    
    const mutateRecord=async(id)=>{
 
        const result =  await deleteVehicleBrand({variables:{"id":id}})
              
   
        if(result?.data)  return Swal.fire( "Deleted!", "", "success" )
        
        Swal.fire( "Not Deleted. Contact Admin!", "", "success" )
 
      }
    return (
        <>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <ToolkitProvider
                                keyField="id"
                                data={brands || []}
                                columns={VehicleBrandColumns(toggle)}
                                bootstrap4
                                search
                            >
                                {(toolkitProps) => (
                                    <React.Fragment>
                                        <Row className="mb-2">
                                            <Col sm="4">
                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault()
                                                            }}
                                                        >
                                                            <Form.Group
                                                                className="mb-3"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <i className="bx bx-search-alt search-icon" />
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Search Brands"
                                                                    onChange={handleSearch}
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>
                                            {props.showCreateBtn && capabilities.includes("create") && (
                                                <Col sm="8">
                                                    <div className="text-sm-end d-flex justify-content-end">
                                                        {/* <Link to="/ecommerce-categories/create"> */}
                                                        <Button
                                                             className="m-1"
                                                            color="primary"
                                                            onClick={() => setBrandOpen(true)}
                                                        >
                                                            <GoPlus style={{ marginRight: 5 }} />
                                                            Create Brand
                                                        </Button>
                                                        {/* </Link> */}
                                                        <EcommerceRentalBrandFilter
                                                               
                                                                onFilterChange={setFilterValue}
                                                                activeFilters={props.activeFilters}
                                                                filterQueryConditions={props.filterQueryConditions}
                                                        />
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <SelectPicker
                                                    data={limitData}
                                                    searchable={false}
                                                    style={{ width: 100 }}
                                                    placeholder="Limit"
                                                    value={PageData.limit}
                                                    onChange={(value) =>
                                                        setPageData({ ...PageData, limit: value })
                                                    }
                                                    cleanable={false}
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    {loading ? (
                                                        <Loader />
                                                    ) : (
                                                        <BootstrapTable
                                                            responsive
                                                            bordered={false}
                                                            striped={false}
                                                            defaultSorted={defaultSorted}
                                                            classes={"table align-middle table-nowrap"}
                                                            keyField="id"
                                                            {...toolkitProps.baseProps}
                                                            onTableChange={handleTableChange}
                                                            ref={node}
                                                            noDataIndication={() => "No Categories Found!"}
                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-40">
                                            <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                                                <Button
                                                    disabled={PageData.pageNo <= 1}
                                                    onClick={() => {
                                                        PageDown()
                                                    }}
                                                >
                                                    Previous Page
                                                </Button>
                                                <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}</div>
                                                <Button
                                                    disabled={PageData.pageNo >= maxPage}
                                                    onClick={() => {
                                                        PageUp()
                                                    }}
                                                >
                                                    Next Page
                                                </Button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {capabilities.includes("create") && (
                <VehicleBrandCreate
                    open={brandModalOpen}
                    close={() => {
                        setSelectedBrand({})
                        setBrandOpen(false)
                    }}
                    onSubmit={() => refetch()}
                    category={selectedBrand}
                />
            )}
        </>
    )
}

VehicleBrandsList.propTypes = {
    queryCondition: PropTypes.any,
    activeFilters: PropTypes.array,
    filterQueryConditions: PropTypes.any,
    showCreateBtn: PropTypes.bool,
}

export default withRouter(VehicleBrandsList)