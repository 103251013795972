import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
import ProductOverview from "./ProductOverview"

//Import custom styles
import "./EcommerceMasterCatalogueDetails.style.scss"
const EcommerceMasterCatalogueDetails = props => {
  const [stores, setStores] = useState([])
  const [activeTab, setActiveTab] = useState('overview')
  return (
    <div className="EcommerceMasterCatalogueDetails">
      <div className="page-content">
        <MetaTags>
          <title>Product Details | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Master Catalogue" breadcrumbItem="Product Details" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
                    <Tab eventKey="overview" title="Overview">
                      <ProductOverview setStores={setStores} />
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommerceMasterCatalogueDetails.propTypes = {
}
export default EcommerceMasterCatalogueDetails