import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link ,useParams} from "react-router-dom"
 import {
  Container,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import EcommerceOrdersTable from "./EcommerceServicesTable"
import "../../../assets/scss/custom.scss"
const EcommerceOrders = props => {
  const { type } = useParams()

 
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Services | EEFind </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Services" />
          <EcommerceOrdersTable 
            queryCondition={null}
            activeFilters={["landmark", "service", "service_category", "service_name", "created_by", "updated_by", "email", "phone"]}
            filterQueryConditions={null}
            showFilters={true}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
EcommerceOrders.propTypes = {}
export default withRouter(EcommerceOrders)
