import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Card, CardBody } from 'reactstrap'
import { withRouter } from "react-router-dom"
import { VehicleBrandList, VehicleMasterDataQuery } from "gql/query"
import { useLazyQuery } from "@apollo/client"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from "react-icons/go"
import PropTypes from "prop-types"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useSelector } from "react-redux"
import { SelectPicker } from "rsuite"
import Loader from "../../../components/Loader/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import VehicleBrandsCreate from './vehicle-master-data-create'
import moment from "moment"
import { HtmlToExcel } from 'pages/Common/HtmlToExcel'
import { useMutation } from "@apollo/client"
import Swal from "sweetalert2"
import axios from "axios"
import { Delete_master_vehicle_data } from 'gql/Mutation'
import EcommerceRentalsMasterDataFilter from 'components/Filters/Ecommerce/EcommerceRentalsMasterDataFilter'
import { BuildMasterDataFilterQuery } from './Vehicle-master-data.functions'
import { BikesHtmlToExcelHeaders, CarsHtmlToExcelHeaders } from './columns'



const FormatMasterData = (data) => {


    const brands = data.rental_master_vehicles.map((elem) => {
         return {
            id: elem?.id,
            make: elem?.naming?.make,
            model: elem?.naming?.model,
            price: elem?.naming?.price,
            version: elem?.naming?.version,
            fuel_type:elem?.enginetransmission?.fueltype,
            transmission:elem?.enginetransmission?.transmission,
            capacity:elem?.capacity?.bootspace,
            no_seating_rows:elem?.capacity?.no_of_seating_rows,
            seating_capacity:elem?.capacity?.seating_capacity,
            doors:elem?.capacity?.doors,
            air_bags:elem?.safety?.airbags,
            lighting:elem?.lighting?.headlights,
            engine:elem?.keydata?.key_engine,
            url: elem?.url,
            // active: elem?.active,
        }
    })
    return brands
}

const VehicleMasterData = (props) => {
    const { capabilities } = useSelector((state) => state.Login)
    const [queryCondition, setQueryCondition] = useState([])
    const [brands, setMasterData] = useState([])
    const [modal, setModal] = useState(false)
    const [brandModalOpen, setBrandOpen] = useState(false)
    const [selectedBrand, setSelectedBrand] = useState({})
    const [PageData, setPageData] = useState({ limit: 10, pageNo: 1 })
    const [deleteVehicleMasterData] = useMutation(Delete_master_vehicle_data)
    const [filterValue, setFilterValue] = useState("")


    var node = useRef()
    const limitData = [
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "200", value: 200 },
        { label: "500", value: 500 },
    ]
    const defaultSorted = []

    const VehicleBrandColumns = (toggleModal) => [
        {
            text: "ID",
            dataField: "id",
            sort: false,
        },
        {
            text: "Make",
            dataField: "make",
            sort: true,
        },
        {
            text: "Model",
            dataField: "model",
            sort: true,
        },
        {
            text: "Price",
            dataField: "price",
            sort: true,
        },
        {
            text: "Version",
            dataField: "version",
            sort: true,
        },

        {
            text: "Fuel Type",
            dataField: "fuel_type",
            sort: true,
        },
        {
            text: "Transimision",
            dataField: "transmission",
            sort: true,
        },
        {
            text: "Capacity",
            dataField: "capacity",
            sort: true,
        },
        {
            text: "Engine",
            dataField: "engine",
            sort: true,
        },
        {
            text: "Seating Rows",
            dataField: "no_seating_rows",
            sort: true,
        },
        {
            text: "Capacity",
            dataField: "seating_capacity",
            sort: true,
        },
        {
            text: "Doors",
            dataField: "doors",
            sort: true,
        },
        {
            text: "Air Bags",
            dataField: "air_bags",
            sort: true,
        },
        {
            text: "Lighting",
            dataField: "lighting",
            sort: true,
        },
 
        // {
        //     text: "Image",
        //     dataField: "url",
        //     sort: true,
        // },
        {
            dataField: "url",
            text: "Logo",
            sort: false,
            formatter: (cellContent, row) => (
                <img src={row.url} style={{ height: 80 }} />
            ),
        },
        {
            dataField: "view",
            isDummyField: true,
            text: "Action",
            sort: false,
            hidden: !capabilities.includes("edit"),
            formatter: (cellContent, row) => (
                <Dropdown>
                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                        <GoKebabVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                setSelectedBrand(row)
                                setBrandOpen(true)
                            }}
                        >
                            Edit
                        </Dropdown.Item>
                    <Dropdown.Item
                            onClick={() => {
                                mutateRecord(row?.id)
                            }}
                        >
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ),
        },
    ]



    const mutateRecord=async(id)=>{
 
       const result =  await deleteVehicleMasterData({variables:{"id":id}})
  
            if(result?.data) return Swal.fire( "Deleted!", "", "success" )
            
            Swal.fire( "Not Deleted. Contact Admin!", "", "success" )

     }

    const handleSearch = (e) => {
        let value = e.target.value
        if (value.length >= 2) {
            let conditionTemp = {}
            if (props.queryCondition !== null) {
                conditionTemp = { ...props.queryCondition }
            }
            conditionTemp = {
                ...conditionTemp,
               
                    ...{naming: {_cast: {String: {_ilike: `%${value}%`}}}},
            
            }
            setQueryCondition(conditionTemp)
        } else {
            let conditionTemp = {}
            if (props.queryCondition !== null) {
                conditionTemp = { ...props.queryCondition }
            }
            setQueryCondition(conditionTemp)
            refetch()
        }
    }
    const PageDown = () => {
        if (PageData.pageNo > 0)
            setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
    }
    const PageUp = () => {
        setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
    }
    const toggle = () => {
        setModal(!modal)
    }
    const handleTableChange = (type, { page, searchText }) => { }
    const [getVehicleMasterDataList, { loading, data, refetch, error }] = useLazyQuery(
        VehicleMasterDataQuery,
        {
            skipCache: true,
            variables: { limit: PageData.limit, offset: (PageData.pageNo - 1) * PageData.limit, where: {_or:queryCondition} },
        }
    )

    useEffect(() => {
        
        let conditionTemp = BuildMasterDataFilterQuery(filterValue, props.queryCondition)
         setQueryCondition(conditionTemp)
      }, [filterValue])

     useEffect(() => {
        if (props.queryCondition !== null) {
            setQueryCondition(props.queryCondition)
        } else {
            getVehicleMasterDataList()
        }
    }, [props.queryCondition])
    useEffect(() => {
        if (Object.keys(queryCondition).length) {
            getVehicleMasterDataList()
        } else {
            getVehicleMasterDataList()
        }
    }, [queryCondition])
    useEffect(() => {
        if (data) {
            setMasterData(FormatMasterData(data))
        }
    }, [data])

    const count = data?.rental_master_vehicles_aggregate?.aggregate?.count || 0
    const maxPage = Math.ceil(count / PageData.limit)

 
 




   const excelOnChange=(e)=>{

    // e?.preventDefault()

    const form_data = new FormData()
 
    form_data.append("file", e?.target?.files?.[0])
    
    form_data.append("import_type", 'RM_vehicles')

    form_data.append("vehicle_category", e?.target?.dataset?.type)

    axios.post(`https://eefind-admin-dev.herokuapp.com/import`, 
              form_data, 
              {headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
              }},
              ).then((result) => {
                 if(result?.data?.result == 0) throw new Error("No Records Uploaded!")
                Swal.fire( `${result?.data?.result} Excel Records Uploaded`, "", "success" )
              }).catch((error) => {
                 Swal.fire( `No Excel Records Uploaded`, "", "error" )
              })?.finally(()=>{
                // e?.target?.files   
                //eslint-disabl
                // e.target.files = new FileList()
                
                // e.target.filses = '' // clear the input value
                e.target.files = new DataTransfer().files // set an empty FileList object

              })
    }





    return (
        <>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <ToolkitProvider
                                keyField="id"
                                data={brands || []}
                                columns={VehicleBrandColumns(toggle)}
                                bootstrap4
                                search
                            >
                                {(toolkitProps) => (
                                    <React.Fragment>
                                        <Row className="mb-2">
                                            <Col sm="4">
                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault()
                                                            }}
                                                        >
                                                            <Form.Group
                                                                className="mb-3"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <i className="bx bx-search-alt search-icon" />
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Search Vehicle"
                                                                    onChange={handleSearch}
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>

                                            
                                            {props.showCreateBtn && capabilities.includes("create") && (
                                                <Col sm="8">
                                                    <div className="text-sm-end d-flex justify-content-end">
                                                    <Button className="m-1"
                                                            color="primary"
                                                            onClick={() => setBrandOpen(true)}
                                                        >
                                                            <GoPlus style={{ marginRight: 5 }} />
                                                            Create Master Vehicles
                                                        </Button>
                                                      <EcommerceRentalsMasterDataFilter 
                                                                onFilterChange={setFilterValue}
                                                                activeFilters={props.activeFilters}
                                                                filterQueryConditions={props.filterQueryConditions}
                                                        />



                                                    </div>
                                                    <div className='d-flex justify-content-end'>

                                                    { [
                                                    {label:"Cars",type:"car", headers:CarsHtmlToExcelHeaders},
                                                    {label:"Bikes",type:"bike", headers:BikesHtmlToExcelHeaders}
                                                    ]?.map((e,i)=>{
                                                        return <HtmlToExcel 
                                                                    key={i} 
                                                                    ButtonText={`${e?.label}Template`} 
                                                                    FileName={`${e?.label}Template`} 
                                                                    headers={e?.headers}
                                                                />

                                                    })
                                                    }
                                                    {[
                                                        {label:"Cars",type:"car"},
                                                        {label:"Bikes",type:"bike"},
                                                    ]?.map((e,i)=>{
                                                        return <div key={i}>
                                                                
                                                                <label htmlFor={`Excel${e?.label}`} className={"btn btn-sm btn-primary m-1"}>
                                                                        {e?.label}  Upload
                                                                </label>
                                                                <input
                                                                    id={`Excel${e?.label}`}
                                                                    type="file"
                                                                    accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                                                    style={{ display: "none" }}
                                                                    data-type = {e?.type}
                                                                    onChange={excelOnChange}
                                                                />

                                                        </div>                                  
                                                    })}
 
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <SelectPicker
                                                    data={limitData}
                                                    searchable={false}
                                                    style={{ width: 100 }}
                                                    placeholder="Limit"
                                                    value={PageData.limit}
                                                    onChange={(value) =>
                                                        setPageData({ ...PageData, limit: value })
                                                    }
                                                    cleanable={false}
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    {loading ? (
                                                        <Loader />
                                                    ) : (
                                                        <BootstrapTable
                                                            responsive
                                                            bordered={false}
                                                            striped={false}
                                                            defaultSorted={defaultSorted}
                                                            classes={"table align-middle table-nowrap"}
                                                            keyField="id"
                                                            {...toolkitProps.baseProps}
                                                            onTableChange={handleTableChange}
                                                            ref={node}
                                                            noDataIndication={() => "No Categories Found!"}
                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-40">
                                            <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                                                <Button
                                                    disabled={PageData.pageNo <= 1}
                                                    onClick={() => {
                                                        PageDown()
                                                    }}
                                                >
                                                    Previous Page
                                                </Button>
                                                <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}</div>
                                                <Button
                                                    disabled={PageData.pageNo >= maxPage}
                                                    onClick={() => {
                                                        PageUp()
                                                    }}
                                                >
                                                    Next Page
                                                </Button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
             {capabilities.includes("create") && (
                <VehicleBrandsCreate
                    open={brandModalOpen}
                    close={() => {
                        setSelectedBrand({})
                        setBrandOpen(false)
                    }}
                    onSubmit={() => refetch()}
                    masterData={selectedBrand}
                />
            )}
        </>
    )
}

VehicleMasterData.propTypes = {
    queryCondition: PropTypes.any,
    activeFilters: PropTypes.array,
    filterQueryConditions: PropTypes.any,
    showCreateBtn: PropTypes.bool,
}

export default withRouter(VehicleMasterData)