import React, { useState, useEffect } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { Modal } from "rsuite"
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	Input,
	Label,
	Row
} from "reactstrap"

const EditRequestDetailsModal = (props) => {
	const history = useHistory()
	const [description, setDescription] = useState("")

	useEffect(() => {
		if (props.request?.id) {
			setDescription(props.request?.description)
		}
	}, [props.request])
	return (
		<Modal open={props.open} onClose={() => props.close()}>
			<Modal.Header>
				<Modal.Title>Request Details</Modal.Title>
			</Modal.Header>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<Row>
								<Col sm="12">
									<div className="mb-3">
										<Label htmlFor="description">Description</Label>
										<Input
											type="textarea"
											name="description"
											id="description"
											value={description}
											onChange={(e) => setDescription(e.target.value)}
											disabled
										/>
									</div>
								</Col>
							</Row>
							<div className="d-flex flex-wrap gap-2">
								<Button
									type="button"
									color="secondary"
									className=" "
									onClick={(e) => props.close()}
								>
									Close
								</Button>
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Modal>
	)
}
EditRequestDetailsModal.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	request: PropTypes.any,
}
export default withRouter(EditRequestDetailsModal)
