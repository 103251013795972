import React from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import EcommerceShopTable from "./EcommerceShopTable"
import "../../../assets/scss/custom.scss"
const EcommerceOrders = (props) => {
  const pathName = props.location.pathname

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Shops | EEFind </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Shops" />
          <EcommerceShopTable
            queryCondition={
              pathName.startsWith("/groceries")
                ? {
                    module: {
                      _eq: "grocery",
                    },
                  }
                : pathName.startsWith("/services")
                ? { module: { _eq: "service" } }
                : pathName.startsWith("/vehicles")
                ? { module: { _eq: "rental" } }
                : pathName.startsWith("/property")
                ? { module: { _eq: "property" } }
                : pathName.startsWith("/experience")
                ? { module: { _eq: "experience" } }
                : null
            }
            activeFilters={[
              "city",
              "state",
              "total_sales",
              "total_orders",
              "total_products",
              "created_at",
              "active",
            ]}
            filterQueryConditions={null}
            pathName={pathName}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
EcommerceOrders.propTypes = {
  location: PropTypes.any,
}
export default withRouter(EcommerceOrders)
