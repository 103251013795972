import React from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link, useParams } from "react-router-dom"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import EcommerceOrdersTable from "./EcommerceOrdersTable"
import "../../../assets/scss/custom.scss"
const EcommerceOrders = (props) => {
  const pathName = props.location.pathname

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Orders | EEFind </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem={
              pathName.startsWith("/groceries")
                ? "Orders"
                : pathName.startsWith("/services")
                ? "Bookings"
                : pathName.startsWith("/vehicles")
                ? "Orders"
                : pathName.startsWith("/property")
                ? "Bookings"
                : "Bookings"
            }
          />
          <EcommerceOrdersTable
            queryCondition={
              pathName == "/groceries/ecommerce-orders"
                ? {
                    order_type: {
                      _in: ["delivery", "home_delivery", "in_store_pickup"],
                    },
                  }
                : pathName == "/services/service-bookings"
                ? { order_type: { _eq: "service" } }
                : pathName == "/vehicles/vehicles-orders"
                ? { order_type: { _eq: "rental" } }
                : pathName == "/property/property-bookings"
                ? { order_type: { _eq: "property" } }
                : pathName == "/experience/experience-bookings"
                ? { order_type: { _eq: "experience" } }
                : null
            }
            activeFilters={[
              "status",
              "store",
              "created_at",
              "city",
              "total_amount",
              "delivery_charges",
              "order_items",
              "category",
              "subcategory",
            ]}
            filterQueryConditions={null}
            showFilters={true}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
EcommerceOrders.propTypes = {
  location: PropTypes.object,
}
export default withRouter(EcommerceOrders)
