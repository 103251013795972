import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import MetaTags from 'react-meta-tags'

import {
	Button,
	Card,
	CardBody,
	CardSubtitle,
	CardTitle,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row
} from "reactstrap"
import axios from "axios"
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"
import { FiPlus } from "react-icons/fi"
import MDEditor from "@uiw/react-md-editor"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import FileUploader from "components/Common/FileUploader"
import EcommerceCreateBrand from "pages/Ecommerce/EcommerceCreateBrand"
import { EcommerceCreateCategory } from "pages/Ecommerce/Categories/index"
import { EcommerceCreateSubCategory } from "pages/Ecommerce/SubCategories/index"

import Swal from "sweetalert2"
//Graphql
import {
	ProductBrandFilter,
	ProductCategoryFilter,
	ProductSubCategoryFilter,
	ProductWeightUnitFilter,
	ProductCollectionList
} from "gql/query"
import { Insert_Master_Product_One } from "gql/Mutation"
import { useQuery, useMutation } from "@apollo/client"

const EcommerceCreateMasterCatalogue = (props) => {
	const history = useHistory()
	const [CreateMasterProductMutation] = useMutation(Insert_Master_Product_One)
	const [state, setState] = useState({
		name: "",
		description: "",
		brand_id: "",
		categories_id: "",
		subcategory_id: "",
		weight: "",
		weight_unit: "",
		collection_id: ""
	})
	const [image, setImage] = useState(null)
	const [btnLoading, setBtnLoading] = useState(false)
	const [brandModalOpen, setBrandOpen] = useState(false)
	const [categoryModalOpen, setCategoryOpen] = useState(false)
	const [subcategoryModalOpen, setSubcategoryOpen] = useState(false)

	const handleFieldChange = (e) => {
		setState({
			...state,
			[e.target.name]: e.target.value
		})
	}
	const handleUploadFile = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()
				formData.append("uploads", image[0])
				const resp = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`, formData)
				if (resp.data?.success) {
					if (Array.isArray(resp.data?.data?.uploaded_files)) {
						const link = resp.data.data.uploaded_files[0].uploaded_file_url
						resolve([link])
					}
				} else {
					resolve([])
				}
			} catch (err) {
				resolve([])
			}
		})

	}
	const handleCreateProduct = async (e) => {
		try {
			e.preventDefault()
			setBtnLoading(true)
			let category = JSON.parse(state.categories_id)
			let photo_urls = []
			if (image) {
				photo_urls = await handleUploadFile()
			}else if(category.logo){
				photo_urls = [category.logo]
			}
			await CreateMasterProductMutation({
				variables: {
					object: {
						...state,
						photo_urls,
						categories_id: category.id
					}
				},
			})
			Swal.fire("Product Created Successfully", "", "success").then(() => {
				history.goBack()
			})
		} catch (err) {
			Swal.fire("Something went wrong", "", "error")
		} finally {
			setBtnLoading(false)
		}
	}
	return (
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title>Create Master Catalogue | EEFind - React Admin & Dashboard Template</title>
				</MetaTags>
				<Container fluid>
					{/* Render Breadcrumb */}
					<Breadcrumbs title="Master Catalogue" breadcrumbItem="Create Master Catalogue" />
					<Row>
						<Col xs="12">
							<Card>
								<CardBody>
									<Form id="brandform" onSubmit={handleCreateProduct}>
										<Row>
											<Col sm="12">
												<div className="mb-3">
													<Label htmlFor="product_name">Product Name</Label>
													<Input
														id="product_name"
														name="name"
														defaultValue={state.name}
														type="text"
														className="form-control"
														onChange={handleFieldChange}
														required
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col sm="12">
												<div className="mb-3">
													<Label htmlFor="product_desc">Product Description</Label>
													<MDEditor height={200} value={state.description} onChange={(value)=>setState({...state, description: value})} />
													{/* <Input
														id="product_desc"
														name="description"
														defaultValue={state.description}
														type="textarea"
														className="form-control"
														onChange={handleFieldChange}
													/> */}
												</div>
											</Col>
										</Row>
										<Row>
											<Col sm="12">
												<div className="mb-3">
													<Label>Brand</Label>
													<SelectPickerPaginate
														query={ProductBrandFilter}
														placeholder="Select Brand"
														value={state.brand_id}
														onChange={(product_brand) => setState({ ...state, brand_id: product_brand })}
														arrKey="product_brand"
														objectLabel="name"
														objectValue="id"
														style={{ marginLeft: 10 }}
														paginate={true}
														filterCondition={null}
													/>
													<Button
														type="button"
														color={"primary"}
														style={{ marginLeft: 10 }}
														onClick={() => setBrandOpen(true)}
													><FiPlus /></Button>
												</div>
											</Col>
										</Row>
										<Row>
											<Col sm="12">
												<div className="mb-3">
													<Label>Category</Label>
													<SelectPickerPaginate
														query={ProductCategoryFilter}
														placeholder="Select Category"
														value={state.categories_id}
														onChange={(product_category) => setState({ ...state, categories_id: product_category })}
														arrKey="categories"
														objectLabel="name"
														objectValue="id"
														style={{ marginLeft: 10 }}
														paginate={true}
														filterCondition={null}
														getFullObj
													/>
													<Button
														type="button"
														color={"primary"}
														style={{ marginLeft: 10 }}
														onClick={() => setCategoryOpen(true)}
													><FiPlus /></Button>
												</div>
											</Col>
										</Row>
										<Row>
											<Col sm="12">
												<div className="mb-3">
													<Label>Sub Category</Label>
													<SelectPickerPaginate
														query={ProductSubCategoryFilter}
														placeholder="Select Sub Category"
														value={state.subcategory_id}
														onChange={(product_subcategory) => setState({ ...state, subcategory_id: product_subcategory })}
														arrKey="subcategories"
														objectLabel="name"
														objectValue="id"
														style={{ marginLeft: 10 }}
														paginate={true}
														filterCondition={null}
													/>
													<Button
														type="button"
														color={"primary"}
														style={{ marginLeft: 10 }}
														onClick={() => setSubcategoryOpen(true)}
													><FiPlus /></Button>
												</div>
											</Col>
										</Row>
										<Row>
											<Col sm="3">
												<div className="mb-3">
													<Label htmlFor="product_weight">Weight</Label>
													<Input
														id="product_weight"
														name="weight"
														defaultValue={state.weight}
														type="number"
														className="form-control"
														min={0}
														onChange={handleFieldChange}
														required
													/>
												</div>
											</Col>
											<Col sm="12">
												<div className="mb-3">
													<Label>Weight Unit</Label>
													<br />
													<SelectPickerPaginate
														query={ProductWeightUnitFilter}
														placeholder="Select Weight Unit"
														value={state.weight_unit}
														onChange={(weight_unit) => setState({ ...state, weight_unit })}
														arrKey="enum_weight_units"
														objectLabel="comments"
														objectValue="name"
														style={{ marginLeft: 10 }}
														paginate={false}
														filterCondition={null}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col sm="12">
												<div className="mb-3">
													<Label>Collection</Label>
													<SelectPickerPaginate
														query={ProductCollectionList}
														placeholder="Select Collection"
														value={state.collection_id}
														onChange={(collection_id) => setState({ ...state, collection_id })}
														arrKey="collections"
														objectLabel="name"
														objectValue="id"
														style={{ marginLeft: 10 }}
														paginate={false}
														filterCondition={null}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											<Col sm="12">
												<div className="mb-3">
													<Label htmlFor="product_image">Product Image</Label>
													<FileUploader
														onChange={setImage}
													/>
												</div>
											</Col>
										</Row>
										<div className="d-flex flex-wrap gap-2">
											<Button
												color="primary"
												className="btn "
												type="submit"
												disabled={btnLoading}
											>
												Submit
											</Button>
											<Button
												type="button"
												color="secondary"
												className=" "
												onClick={(e) => history.goBack()}
											>
												Cancel
											</Button>
										</div>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
			<EcommerceCreateBrand
				open={brandModalOpen}
				close={() => setBrandOpen(false)}
				onSubmit={()=>{}}
			/>
			<EcommerceCreateCategory
				open={categoryModalOpen}
				close={() => setCategoryOpen(false)}
				onSubmit={()=>{}}
			/>
			<EcommerceCreateSubCategory
				open={subcategoryModalOpen}
				close={() => setSubcategoryOpen(false)}
				onSubmit={()=>{}}
			/>
		</React.Fragment>
	)
}

export default withRouter(EcommerceCreateMasterCatalogue)
