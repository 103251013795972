import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withRouter, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "components/Loader/Loader"
import Highlighter from "react-highlight-words"
import { SelectPicker } from 'rsuite'
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from 'react-icons/go'
import isValidUrl from "helpers/url_checker"

import ToolkitProvider from "react-bootstrap-table2-toolkit"

//redux
import { useQuery, useLazyQuery } from "@apollo/client"
import { MasterCatalogueList } from "gql/query"

//filters
import EcommerceProductsFilter from "components/Filters/Ecommerce/EcommerceProductsFilter"

import { useSelector } from "react-redux"
const FormatProductData = (data) => {
	const master_products = data.master_products.map(elem => {
		let image = "/placeholder.jpg"
		if (elem?.photo_urls?.length > 0) {
			image = elem.photo_urls[0]
		}
		return {
			id: elem.id,
			image,
			sku: elem.sku || "N/A",
			name: elem?.name,
			created_at: elem.created_at,
			weight: elem.weight,
			weight_unit: elem.weight_unit,
			brand_id: elem?.product_brand?.id,
			brand_name: elem?.product_brand?.name || "N/A",
			category_id: elem?.category?.id,
			category_name: elem?.category?.name || "N/A",
			subcategory_id: elem?.subcategory?.id,
			subcategory_name: elem?.subcategory?.name || "N/A",
			non_mrp: elem.non_mrp,
			isactive: elem.isactive
		}
	})
	return master_products
}

const EcommerceMasterCatalogueTable = props => {
	const { capabilities } = useSelector((state) => state.Login)
	const [modal, setModal] = useState(false)
	const history = useHistory()
	const [master_products, setMasterProducts] = useState([])
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({})
	const [filterValue, setFilterValue] = useState("")
	const [searchKey, setSearchKey] = useState("")
	const limitData = [{ label: "50", value: 50 }, { label: "100", value: 100 }, { label: "200", value: 200 }, { label: "500", value: 500 }]


	const [getMasterCatalogueList, { loading, data, error, refetch }] = useLazyQuery(MasterCatalogueList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const handleSearch = (value) => {
		if (value.length >= 3) {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			conditionTemp = {
				...conditionTemp,
				_or: [
					{ name: { _ilike: `%${value}%` } },
					{ product_brand: { name: { _ilike: `%${value}%` } } },
					{ category: { name: { _ilike: `%${value}%` } } },
					{ subcategory: { name: { _ilike: `%${value}%` } } },
				]
			}
			setQueryCondition(conditionTemp)
		} else {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			setQueryCondition(conditionTemp)
		}
	}
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}
	const toggle = () => {
		setModal(!modal)
	}
	useEffect(() => {
		if (data) {
			setMasterProducts(FormatProductData(data))
		}
	}, [data])
	const defaultSorted = []
	const count = data?.master_products_aggregate?.aggregate?.count || 0
	const maxPage = Math.ceil(count / PageData.limit)

	const handleTableChange = (type, { page, searchText }) => {

	}
	const formatHighlighter = (text) => {
		let searchWords = [searchKey]
		return <Highlighter
			highlightClassName="YourHighlightClass"
			searchWords={searchWords}
			autoEscape={true}
			textToHighlight={String(text)}
			highlightStyle={{ background: "#ff0" }}
		/>
	}
	const EcommerceProductColumns = (toggleModal) => [
		{
			text: "ID",
			dataField: "id",
			sort: false,
			formatter: (cellContent, row) => <Link to={`/groceries/ecommerce-master-catalogue/detail/${row.id}`}>{cellContent}</Link>
		},
		{
			dataField: "image",
			text: "Image",
			sort: false,
			formatter: (cellContent, row) => <img src={row.image} style={{ height: 50 }} />,
		},
		{
			dataField: "name",
			text: "Product Name",
			sort: true,
			formatter: (col, row) => { return <span style={{ display: 'block', width: 230, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{formatHighlighter(col)}</span> }
		},
		// {
		// 	text: "Weight",
		// 	dataField: "weight",
		// 	sort: true,
		// },
		// {
		// 	text: "Unit",
		// 	dataField: "weight_unit",
		// 	sort: true,
		// },
		{
			dataField: "brand_name",
			text: "Brand",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		{
			dataField: "category_name",
			text: "Category",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		{
			dataField: "subcategory_name",
			text: "Sub-Category",
			sort: true,
			formatter: (cellContent, row) => formatHighlighter(cellContent)
		},
		// {
		// 	dataField: "non_mrp",
		// 	text: "Non MRP",
		// 	sort: false,
		// 	formatter: (cellContent, row) => cellContent ? "Yes" : "No",
		// },
		{
			dataField: "isactive",
			text: "Activation Status",
			sort: false,
			formatter: (cellContent, row) => cellContent ? "Active" : "Not Active",
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
		{
			dataField: "view",
			isDummyField: true,
			text: "Action",
			sort: false,
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
				<Dropdown>
					<Dropdown.Toggle variant="transparent" id="dropdown-basic">
						<GoKebabVertical />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => history.push(`/ecommerce-master-catalogue/detail/${row.id}`)}>View Details</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			),
		},
	]
	//Apply filters
	useEffect(() => {
		if (Object.keys(filterValue).length) {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			if (filterValue?.hasOwnProperty("product_brand")) {
				if (filterValue.product_brand?.length > 0) {
					conditionTemp = {
						...conditionTemp,
						product_brand: { id: { _in: filterValue.product_brand } }
					}
				}
			}
			if (filterValue?.hasOwnProperty("category")) {
				if (filterValue.category?.length > 0) {
					conditionTemp = {
						...conditionTemp,
						category: { id: { _in: filterValue.category } }
					}
				}
			}
			if (filterValue?.hasOwnProperty("subcategory")) {
				if (filterValue.subcategory?.length > 0) {
					conditionTemp = {
						...conditionTemp,
						subcategory: { id: { _in: filterValue.subcategory } }
					}
				}
			}
			if (filterValue?.hasOwnProperty("created_at")) {
				if (Array.isArray(filterValue.created_at)) {
					conditionTemp = {
						...conditionTemp,
						_and: [{ created_at: { _gte: filterValue.created_at[0] } }, { created_at: { _lte: filterValue.created_at[1] } }]
					}
				}
			}
			if (filterValue?.hasOwnProperty("non_mrp")) {
				conditionTemp = {
					...conditionTemp,
					non_mrp: { _eq: filterValue.non_mrp === "yes" ? true : false }
				}
			}
			setQueryCondition(conditionTemp)
		} else {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			setQueryCondition(conditionTemp)
		}
	}, [filterValue])
	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			getMasterCatalogueList()
		}
	}, [props.queryCondition])
	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getMasterCatalogueList()
		}
	}, [queryCondition])
	useEffect(() => {
    if(searchKey.length > 0){
      handleSearch(searchKey)
    }else{
      refetch()
    }
  }, [searchKey])
	var node = useRef()
	return (
		<Row>
			<Col xs="12">
				<Card>
					<CardBody>
						<ToolkitProvider
							keyField="id"
							data={master_products || []}
							columns={EcommerceProductColumns(toggle)}
							bootstrap4
							search
						>
							{toolkitProps => (
								<React.Fragment>
									<Row className="mb-2">
										<Col sm="4">
											<div className="search-box ms-2 mb-2 d-inline-block">
												<div className="position-relative">
													<Form onSubmit={(e) => {
														e.preventDefault()
													}}>
														<Form.Group className="mb-3" controlId="formBasicEmail">
															<i className="bx bx-search-alt search-icon" />
															<Form.Control
																type="text"
																placeholder="Search Products"
																onChange={(e) => setSearchKey(e.target.value)}
															/>
														</Form.Group>
													</Form>

												</div>
											</div>
										</Col>
										<Col sm="8">
											<div className="text-sm-end d-flex justify-content-end">
												<EcommerceProductsFilter
													onFilterChange={setFilterValue}
													activeFilters={props.activeFilters}
													filterQueryConditions={props.filterQueryConditions}
												/>
												{capabilities.includes("create") && (
													<Link to="/groceries/ecommerce-master-catalogue/create" style={{ marginLeft: 10 }}>
														<Button color="primary">
															<GoPlus style={{ marginRight: 5 }} />
															Create Product
														</Button>
													</Link>
												)}
											</div>
										</Col>
									</Row>
									<Row>
										<Col xl="12">
											<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
												<div>
													<b>No. of Products:</b> {count}
												</div>
												<div>
													<SelectPicker
														data={limitData}
														searchable={false}
														style={{ width: 100 }}
														placeholder="Limit"
														value={PageData.limit}
														onChange={(value) => setPageData({ ...PageData, limit: value })}
														cleanable={false}
													/>
												</div>
											</div>
											<div className="mb-3" />
											<div className="table-responsive">
												{loading ? <Loader /> :
													<BootstrapTable
														responsive
														bordered={false}
														striped={false}
														defaultSorted={defaultSorted}
														classes={"table align-middle table-nowrap"}
														keyField="id"
														{...toolkitProps.baseProps}
														onTableChange={handleTableChange}
														ref={node}
														noDataIndication={() => "No Products Found!"}
														key={`${searchKey}_${JSON.stringify(filterValue)}`}
													/>
												}
											</div>
										</Col>
									</Row>
									<Row className="align-items-md-center mt-40">
										<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
											<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
												Previous Page
											</Button>
											<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
											</div>
											<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
												Next Page
											</Button>
										</Col>
									</Row>
								</React.Fragment>
							)}
						</ToolkitProvider>
					</CardBody>
				</Card>
			</Col>
		</Row>
	)
}

EcommerceMasterCatalogueTable.propTypes = {
	queryCondition: PropTypes.any,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any,
}

export default withRouter(EcommerceMasterCatalogueTable)
