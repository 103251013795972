import { gql } from '@apollo/client'

export const AddCustomerFormDataQuery = 
gql`
    query AddCustomerFormDataQuery {
        enum_user_roles {
            value
            comment
        }
    }
`

export const InsertCustomersOneMutation =
  gql`mutation InsertCustomersOne(\$object: customers_insert_input!) {
    insert_customers_one(object: \$object) {
        id
    }
  }
`

export const UpdateCustomerMutation =
  gql`mutation updateCustomer(
    \$id: uuid!,
    \$role: enum_user_roles_enum,
    \$name: String,
    \$active: Boolean,
    \$phone: String,
    \$email: String,
  ) {
    update_customers_by_pk(
      pk_columns: {id: \$id},
      _set: {
        name: \$name,
        active: \$active,
        role: \$role,
        phone: \$phone,
        email: \$email,
      }
    ){
      id
    }
  }
`

export const DeleteCustomerMutation =
  gql`mutation DeleteCustomer(\$id: uuid!) {
    delete_customers_by_pk(id: \$id){
      id
    }
  }
`
