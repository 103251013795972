import { VehicleRentalMasterVehiclesTypehead, VehicleRentalStoresTypehead, VehicleRentalVehicleBarndsTypehead, VehicleRentalsCategoriesTypehead, VehicleTypesTypehead, VehicleUsersTypehead } from "gql/query"


export const Columns = {
    // name    : {name:"name", span:3,type:"text", },

                     vehicle_group   : {name:"vehicle_group", span:3,type:"select", },
                         filter_name : {name:"filter_name", span:3,type:"text", },
                             active  : {name:"active", span:3,type:"boolean", },
                      filter_fields  : {name:"filter_fields", span:3,type:"tag", },
                          form_type  : {name:"form_type", span:3,type:"select" },
                

 
         }



