import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link, useLocation } from "react-router-dom"
import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import EcommercePromotionsTable from "./EcommercePromotionsTable"
import "../../../../assets/scss/custom.scss"
const EcommercePromotions = (props) => {
  const location = useLocation()
  const pathName = location.pathname

   console.log("PahthNkjjkame",location.pathname)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Promotions | EEFind </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Promotions" />
          <EcommercePromotionsTable
            queryCondition={
              pathName.startsWith("/groceries")
                ? {
                    store: {
                      // store_type: {
                      //   _eq: "Groceries and Essentials",
                      // },
                      module: {
                        _eq: "grocery",
                      },
                    },
                  }
                : pathName.startsWith("/services")
                
                ? { store: { module: { _eq: "service" } } }

                // ? { store: { store_type: { _eq: "Individual" } } }
                : pathName.startsWith("/vehicle") ?

                 { module: { _eq: "rental" } } 


                :null
            }
            activeFilters={[
              "active",
              "store",
              "created_at",
              "discount_type",
              "total_usage",
              "promoted_by",
              "validity",
            ]}
            filterQueryConditions={null}
            showFilters={true}
            pathName={pathName}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
EcommercePromotions.propTypes = {
  // location: PropTypes.any,
}
export default withRouter(EcommercePromotions)
