import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceCategoriesTable from "../../Categories/EcommerceCategories/EcommerceCategoriesTable"
import EcommerceServicesTable from "pages/Ecommerce/EcommerceServices/EcommerceServicesTable"
import EcommerceSubServicesTable from "pages/Ecommerce/SubServices/EcommerceSubServices/EcommerceSubServicesTable"
import EcommerceSubCategoriesTable from "pages/Ecommerce/SubCategories/EcommerceSubCategories/EcommerceSubCategoriesTable"
import ServiceCategoriesTable from "pages/Services/Categories/ServiceCategories/ServiceCategoriesTable"
const PromotionCategories = (props) => {
    const match = useRouteMatch()
    const { services } = props.promotion
     return (
        <div className="p-3">
            {Array.isArray(services) && (
                <ServiceCategoriesTable
                    queryCondition={{ id: { _in: services } }}
                    activeFilters={["product_brand", "category", "subcategory", "store", "created_at", "non_mrp"]}
                    filterQueryConditions={null}
                    showCreateBtn={false}
                />
            //     <EcommerceSubServicesTable
            //     queryCondition={{ id: { _in: services } }}
            //     activeFilters={["product_brand", "category", "subcategory", "store", "created_at", "non_mrp"]}
            //     filterQueryConditions={null}
            //     showCreateBtn={true}
            //   />
            )}
        </div>
    )
}
PromotionCategories.propTypes = {
    promotion: PropTypes.any
}
PromotionCategories.displayName = 'PromotionCategories'
export default PromotionCategories