import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { Modal, Toggle, SelectPicker, DatePicker, Uploader } from "rsuite"
import * as moment from "moment"
import Swal from "sweetalert2"
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	Input,
	Label,
	Row
} from "reactstrap"

//components
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"
import CheckPickerPaginate from "components/Filters/CheckPickerPaginate"
//GraphQL
import CheckProductPickerPaginate from "../../EcommerceOrderDetails/CheckProductPickerPaginate"
import { Insert_Promotion_One, UpdatePromotion } from "gql/Mutation"
import { CustomrList, CategoryList, SubCategoryList, BrandList, StoreList, ProductList, ServiceCategoryList, ServiceSubServicesList } from "gql/query"
import { useMutation } from "@apollo/client"

//utils
import { handleUploadFile } from "utils"

const EcommerceCreateRentaPromotion = (props) => {
	const history = useHistory()
	const [CreatePromotionMutation] = useMutation(Insert_Promotion_One)
	const [UpdatePromotionMutation] = useMutation(UpdatePromotion)
	const [state, setState] = useState({
		code: "",
		description: "",
		value: "",
		value_type: "percentage",
		min_item_count: "",
		min_order_amount: "",
		max_order_amount: "",
		usable_count: "",
		valid_from: "",
		valid_till: "",
		promoted_by: "",
		customers: [],
		categories: [],
		subcategories: [],
		brands: [],
		products: [],
		apply_on_delivery_charge: false,
		active: true,
		store_id: "",
		informational_banner: false,
		max_order_count: ""
	})
	const [btnLoading, setBtnLoading] = useState(false)
	const [isActive, setActive] = useState(true)
	const [files, setFiles] = useState([])
	const discountTypeOptions = [{ label: "Percentage", value: "percentage" }, { label: "Flat", value: "flat" }]
	const promotedByOptions = [{ label: "EEfind", value: "eefind" }, { label: "Store", value: "store" }]

	const formatPickerValue = (arr) => {
		// return arr.map((item) => {
		// 	return {
		// 		id: JSON.parse(item).id,
		// 		name: JSON.parse(item).name
		// 	}
		// })
		return arr.map((item) => JSON.parse(item).id)
	}
	const handleCreatePromotion = async (e) => {
		try {
			e.preventDefault()
			setBtnLoading(true)
			let imageLinks = []
			if (files.length) {
				imageLinks = await handleUploadFile(files.map(_file => _file.blobFile), true)
			}
			let customers = formatPickerValue(state.customers)
			let services = formatPickerValue(state.services)
			let subservices = formatPickerValue(state.subservices)
			let brands = formatPickerValue(state.brands)
			let products = formatPickerValue(state.products)
			let objects = {
				...state,
				label: state.code,
				services,
				subservices,
				brands,
				products,
				customers,
				min_item_count: state.min_item_count || 0,
				min_order_amount: state.min_order_amount || 0,
				max_order_amount: state.max_order_amount || null,
				max_order_count: state.max_order_count || null,
				usable_count: state.usable_count || 0,
				informational_banner: undefined,
				store_id: state.store_id || null,
				code: state.code,
				valid_till: state.valid_till || null,
				valid_from: state.valid_from || null,
				photo_url: imageLinks,
				promoted_by:"eefind"
			}
			if (props.promotion?.id) {
				await UpdatePromotionMutation({
					variables: {
						id: props.promotion?.id,
						object: objects
					},
				})
			} else {
				await CreatePromotionMutation({
					variables: {
						object: objects
					},
				})
			}
			Swal.fire(`Promotion ${props.promotion?.id ? "Updated" : "Created"} Successfully`, "", "success").then(() => {
				props.onSubmit()
				props.close()
			})
		} catch (err) {
			Swal.fire("Something went wrong", "", "error")
		} finally {
			setBtnLoading(false)
		}
	}
	const handleSetPickerValues = (value, key) => {
		setState({
			...state,
			[key]: value
		})
	}
	useEffect(() => {
		if (props.promotion?.id) {
			setState({
				code: props.promotion.code,
				description: props.promotion.description,
				value: props.promotion.value,
				value_type: props.promotion.value_type,
				min_item_count: props.promotion.min_item_count,
				min_order_amount: props.promotion.min_order_amount,
				max_order_amount: props.promotion.max_order_amount,
				max_order_count: props.promotion.max_order_count,
				usable_count: props.promotion.usable_count,
				valid_from: props.promotion.valid_from,
				valid_till: props.promotion.valid_till,
				promoted_by: 'eefind',
				// promoted_by: props.promotion.promoted_by,
				customers: [],
				categories: [],
				subcategories: [],
				brands: [],
				products: [],
				apply_on_delivery_charge: props.promotion.apply_on_delivery_charge,
				active: props.promotion.active,
				store_id: props.promotion.store_id
			})
		} else {
			setState({
				code: "",
				description: "",
				value: "",
				value_type: "percentage",
				min_item_count: "",
				min_order_amount: "",
				max_order_amount: "",
				max_order_count: "",
				usable_count: "",
				valid_from: "",
				valid_till: "",
				promoted_by: "",
				customers: [],
				categories: [],
				subcategories: [],
				brands: [],
				products: [],
				apply_on_delivery_charge: false,
				active: true,
				store_id: ""
			})
		}
	}, [props.promotion])
	return (
		<Modal open={props.open} onClose={() => props.close()}>
			<Modal.Header>
				<Modal.Title>{props.promotion?.id ? "Update Promotion" : "Create Promotion"}</Modal.Title>
			</Modal.Header>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<Form id="brandform" onSubmit={handleCreatePromotion}>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="label">Code</Label>
											<Input
												id="label"
												name="label"
												type="text"
												className="form-control"
												value={state.code}
												onChange={(e) => setState({ ...state, code: e.target.value })}
												required={!state.informational_banner}
												disabled={state.informational_banner}
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="label">Description</Label>
											<Input
												id="description"
												name="description"
												type="text"
												className="form-control"
												value={state.description}
												onChange={(e) => setState({ ...state, description: e.target.value })}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="discount_value">Discount Value</Label>
											<Input
												id="discount_value"
												name="discount_value"
												type="number"
												className="form-control"
												required
												value={state.value}
												onChange={(e) => setState({ ...state, value: e.target.value })}
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="discount_type">Discount Type</Label>
											<SelectPicker
												id="discount_type"
												data={discountTypeOptions}
												searchable={false}
												position="auto"
												className="form-control"
												style={{ border: "none" }}
												onChange={(value) => setState({ ...state, value_type: value })}
												value={state.value_type}
												cleanable={false}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="min_item_count">Min Unit Count</Label>
											<Input
												id="min_item_count"
												name="min_item_count"
												type="number"
												className="form-control"
												value={state.min_item_count}
												onChange={(e) => setState({ ...state, min_item_count: e.target.value })}
												min={0}
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="min_order_amount">Min Order Amount</Label>
											<Input
												id="min_order_amount"
												name="min_order_amount"
												type="number"
												className="form-control"
												value={state.min_order_amount}
												onChange={(e) => setState({ ...state, min_order_amount: e.target.value })}
												min={0}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="max_order_amount">Max Order Amount</Label>
											<Input
												id="max_order_amount"
												name="max_order_amount"
												type="number"
												className="form-control"
												value={state.max_order_amount}
												onChange={(e) => setState({ ...state, max_order_amount: e.target.value })}
												min={0}
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="usable_count">Maximum Usage</Label>
											<Input
												id="usable_count"
												name="usable_count"
												type="number"
												className="form-control"
												value={state.usable_count}
												onChange={(e) => setState({ ...state, usable_count: e.target.value })}
												min={0}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="min_order_count">Valid From</Label>
											<DatePicker
												className="form-control"
												style={{ border: "none" }}
												onChange={(value) => setState({
													...state,
													valid_from: moment(value).format("YYYY-MM-DD")
												})}
												disabledDate={date => moment(date).isBefore(new Date())}
												required={true}
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="min_order_amount">Valid Till</Label>
											<DatePicker
												className="form-control"
												style={{ border: "none" }}
												onChange={(value) => setState({
													...state,
													valid_till: moment(value).format("YYYY-MM-DD")
												})}
												disabledDate={date => moment(date).isBefore(new Date())}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									{/* <Col sm="6">
										<div className="mb-3">
											<Label htmlFor="promoted_by">Promoted By</Label>
											<SelectPicker
												id="promoted_by"
												data={promotedByOptions}
												searchable={false}
												position="auto"
												className="form-control"
												style={{ border: "none" }}
												onChange={(value) => setState({ ...state, promoted_by: value })}
												value={state.promoted_by}
											/>
										</div>
									</Col> */}
 										<Col sm="6">
											<div className="mb-3">
												<Label htmlFor="promoted_by">Select Customer</Label>
												<CheckPickerPaginate
													query={CustomrList}
													placeholder="Select Customer"
													value={state.customers}
													onChange={(value) => handleSetPickerValues(value, "customers")}
													arrKey="customers"
													objectLabel="name"
													objectValue="id"
													style={{ width: "100%", display: "block", marginTop: 10 }}
													paginate={true}
													filterCondition={null}
													getFullObj
												/>
											</div>
										</Col>
 								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="promoted_by">Select Service</Label>
											<CheckPickerPaginate
												query={ServiceCategoryList}
												placeholder="Select Service"
												value={state.services}
												onChange={(value) => handleSetPickerValues(value, "services")}
												arrKey="service_categories"
												objectLabel="category"
												objectValue="id"
												style={{ width: "100%", display: "block", marginTop: 10 }}
												paginate={true}
												filterCondition={null}
												getFullObj
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="promoted_by">Select SubServices</Label>
											<CheckPickerPaginate
												query={ServiceSubServicesList}
												placeholder="Select SubServices"
												value={state.subservices}
												onChange={(value) => handleSetPickerValues(value, "subservices")}
												arrKey="master_services"
												objectLabel="service_name"
												objectValue="id"
												style={{ width: "100%", display: "block", marginTop: 10 }}
												paginate={true}
												filterCondition={null}
												getFullObj
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="promoted_by">Select Store</Label>
											<SelectPickerPaginate
												query={StoreList}
												placeholder="Select Store"
												value={state.store_id}
												onChange={(store_id) => setState({ ...state, store_id })}
												arrKey="stores"
												objectLabel="name"
												objectValue="id"
												style={{ width: "100%", display: "block", marginTop: 10 }}
												paginate={true}
												filterCondition={ { store_type: { _eq: "Individual" }  }}
											/>
										</div>
									</Col>
									{/* {state.store_id?.length > 0 && (
										<Col sm="6">
											<div className="mb-3">
												<Label htmlFor="promoted_by">Select Products</Label>
												<CheckProductPickerPaginate
													query={ProductList}
													placeholder="Select Product"
													value={state.products}
													onChange={(value) => handleSetPickerValues(value, "products")}
													arrKey="products"
													objectValue="id"
													style={{ width: "100%" }}
													paginate={true}
													filterCondition={{ store_id: { _eq: state.store_id } }}
												/>
											</div>
										</Col>
									)} */}
								</Row>
								<Row>
									{/* <Col sm="12">
										<div className="mb-3">
											<Label htmlFor="promoted_by">Select Brands</Label>
											<CheckPickerPaginate
												query={BrandList}
												placeholder="Select Brand"
												value={state.brands}
												onChange={(value) => handleSetPickerValues(value, "brands")}
												arrKey="product_brand"
												objectLabel="name"
												objectValue="id"
												style={{ width: "100%", display: "block", marginTop: 10 }}
												paginate={true}
												filterCondition={null}
												getFullObj
											/>
										</div>
									</Col> */}
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="max_order_count">Cart Discount</Label>
											<Input
												id="max_order_count"
												name="max_order_count"
												type="number"
												className="form-control"
												value={state.max_order_count}
												onChange={(e) => setState({ ...state, max_order_count: e.target.value })}
												min={0}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="promoted_by">Upload Images</Label>
											<Uploader
												action="//jsonplaceholder.typicode.com/posts/"
												draggable
												fileList={files}
												onChange={setFiles}
												autoUpload={false}
												accept="image/*"
											>
												<div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
													<span>Click or Drag files to this area to upload</span>
												</div>
											</Uploader>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="commission_percentage">Apply on Delivery Charge</Label>
											<Toggle
												checked={state.apply_on_delivery_charge}
												onChange={(checked) => setState({ ...state, apply_on_delivery_charge: checked })}
												style={{ marginLeft: "1rem" }}
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="commission_percentage">Active</Label>
											<Toggle
												checked={state.active}
												onChange={(checked) => setState({ ...state, active: checked })}
												style={{ marginLeft: "1rem" }}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="commission_percentage">Informational Banner</Label>
											<Toggle
												checked={state.informational_banner}
												onChange={(checked) => setState({ ...state, informational_banner: checked })}
												style={{ marginLeft: "1rem" }}
											/>
										</div>
									</Col>
								</Row>
								<div className="d-flex flex-wrap gap-2">
									<Button
										color="primary"
										className="btn "
										type="submit"
										disabled={btnLoading}
									>
										Submit
									</Button>
									<Button
										type="button"
										color="secondary"
										className=" "
										onClick={(e) => props.close()}
									>
										Cancel
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Modal>
	)
}
EcommerceCreateRentaPromotion.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	onSubmit: PropTypes.func,
	promotion: PropTypes.any
}
export default withRouter(EcommerceCreateRentaPromotion)
