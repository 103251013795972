import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceCategoriesTable from "../../Categories/EcommerceCategories/EcommerceCategoriesTable"
const PromotionCategories = (props) => {
    const match = useRouteMatch()
    const { categories } = props.promotion
    return (
        <div className="p-3">
            {Array.isArray(categories) && (
                <EcommerceCategoriesTable
                    queryCondition={{ id: { _in: categories } }}
                    activeFilters={["product_brand", "category", "subcategory", "store", "created_at", "non_mrp"]}
                    filterQueryConditions={null}
                    showCreateBtn={false}
                />
            )}
        </div>
    )
}
PromotionCategories.propTypes = {
    promotion: PropTypes.any
}
PromotionCategories.displayName = 'PromotionCategories'
export default PromotionCategories