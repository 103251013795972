import { VehicleRentalKYCDriverTypehead, VehicleRentalKYCcustomerTypehead, VehicleRentalMasterVehiclesTypehead, VehicleRentalStoresTypehead, VehicleRentalVehicleBarndsTypehead, VehicleRentalsCategoriesTypehead, VehicleTypesTypehead, VehicleUsersTypehead } from "gql/query"


export const Columns = {
    // name    : {name:"name", span:3,type:"text", },

                       id_no : {name:"id_no", span:3,type:"text", },
                         is_verified : {name:"is_verified", span:3,type:"boolean", },
                         is_uploaded : {name:"is_uploaded", span:3,type:"boolean", },
                             back_photo_url  : {name:"back_photo_url", span:3,type:"img", },
                             front_photo_url  : {name:"front_photo_url", span:3,type:"img", },
                             active  : {name:"active", span:3,type:"boolean", },
                          
                             kyc_name  : {name:"kyc_name", span:3,type:"select", },
                             customer_id :  {name:"customer_id", 
                                                access:"customer", 
                                                span:3,
                                                type:"typehead", 
                                                entity:"customer", 
                                                query :VehicleRentalKYCcustomerTypehead,
                                                acessLabel:"name",
                                                acessValue : "id"
                                                },
                            driver_id :  {name:"driver_id", 
                                            access:"rental_driver", 
                                            span:3,
                                            type:"typehead", 
                                            entity:"retnal_driver", 
                                            query :VehicleRentalKYCDriverTypehead,
                                            acessLabel:"driver_name",
                                            acessValue : "id"
                                            },
                


         }



