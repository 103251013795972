import React from "react"
import { Redirect } from "react-router-dom"

//404
import My404Page from "pages/404"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//HomePage
import Homepage from "../pages/Homepage/index"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceProductDetails from "../pages/Ecommerce/EcommerceProductDetails/index"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceOrderDetails from "pages/Ecommerce/EcommerceOrderDetails"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceCustomerDetails from "../pages/Ecommerce/EcommerceCustomerDetails/index"
import EcommerceMerchants from "../pages/Ecommerce/EcommerceMerchants/index"
import EcommerceMerchantDetails from "../pages/Ecommerce/EcommerceMerchantDetails/index"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import EcommerceShopDetails from "../pages/Ecommerce/EcommerceShopDetails/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct/index"
import EcommerceBrands from "../pages/Ecommerce/EcommerceBrands/index"
import EcommerceCreateBrand from "../pages/Ecommerce/EcommerceCreateBrand/index"
import { EcommerceCategories } from "../pages/Ecommerce/Categories/index"
import { EcommerceSubCategories } from "../pages/Ecommerce/SubCategories/index"
import {
  EcommerceMasterCatalogue,
  EcommerceMasterCatalogueDetails,
  EcommerceCreateMasterCatalogue,
} from "../pages/Ecommerce/MasterCatalogue/index"
import {
  EcommerceRequests,
  EcommerceRequestDetails,
} from "../pages/Ecommerce/Requests/index"
import {
  EcommercePromotions,
  EcommercePromotionDetails,
} from "../pages/Ecommerce/Promotions/index"
import EcommerceServices from "../pages/Ecommerce/EcommerceServices/index"
import EcommerceServiceDetails from "../pages/Ecommerce/EcommerceServicesDetails/index"
import { ServiceCategories } from "pages/Services/Categories/index"
import { EcommerceSystemUsers } from "../pages/Ecommerce/SystemUsers/index"
import EcommerceSubServices from "pages/Ecommerce/SubServices/EcommerceSubServices"
import Groceries_commission from "pages/Ecommerce/EcommerceGCommission/index"
import VehicleBrands from "pages/Vehicles/Brands/index"
import EcommercePromotionServicesDetails from "pages/Ecommerce/Promotions/EcommercePromotionServiceDetails"
import Vehicle_Master_data from "pages/Vehicles/Vehicle_Master_data/index"
import VehicleRentals from "pages/Vehicles/Rental_vehicles/index"
import AirportRentals from "pages/Vehicles/Rental_airports/index"
import RentalsFilters from "pages/Vehicles/Rental_filters/index"
import RentalsDrivers from "pages/Vehicles/Rental_drivers/index"
import rentalVehicleTypeList from "pages/Vehicles/Rental_vehicle_Types/index"
import vehicleRentalsAddonsList from "pages/Vehicles/Rental_addons/vehicle-rentals-addons-list"
import Rental_kyc from "pages/Vehicles/Rental_addons_docs/index"
import Rental_addons from "pages/Vehicles/Rental_addons/index"
import Rental_addons_details from "pages/Vehicles/Rental_drivers/Rental_addons_details/index"
import Rental_orders from "../pages/Vehicles/Orders/index"
import { PropertyType } from "pages/Property/PropertyType/index"
import { PropertyRule } from "pages/Property/PropertyRule/index"
import { PropertyAmenities } from "pages/Property/PropertyAmenities/index"
import { PropertyList } from "pages/Property/PropertyList/index"
import { ExperienceType } from "pages/Experience/ExperienceType/index"
import { ExperienceList } from "pages/Experience/ExperienceList/index"



const authProtectedRoutes = [
  { path: "/", exact: true, component: Homepage },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  // { path: "/", exact: true, component: () => <Redirect to="/" /> },

  //Ecommerce
  // { path: "/:type/dashboard",component: Dashboard },
  { path: "/groceries/dashboard", exact: true, component: Dashboard },
  {
    path: "/groceries/ecommerce-products/detail/:id",
    component: EcommerceProductDetails,
    module: "products",
  },
  {
    path: "/groceries/ecommerce-products",
    component: EcommerceProducts,
    module: "products",
  },
  {
    path: "/groceries/ecommerce-orders",
    component: EcommerceOrders,
    module: "orders",
  },

  {
    path: "/groceries/ecommerce-orders/details/:id",
    component: EcommerceOrderDetails,
    module: "orders",
  },

  {
    path: "/groceries/ecommerce-customers",
    component: EcommerceCustomers,
    module: "customers",
  },
  {
    path: "/groceries/ecommerce-customers/details/:id",
    component: EcommerceCustomerDetails,
    module: "customers",
  },
  {
    path: "/groceries/ecommerce-merchants",
    component: EcommerceMerchants,
    module: "merchants",
  },
  {
    path: "/groceries/ecommerce-merchants/details/:id",
    component: EcommerceMerchantDetails,
    module: "merchants",
  },
  { path: "/groceries/ecommerce-cart", component: EcommerceCart },
  { path: "/groceries/ecommerce-checkout", component: EcommerceCheckout },
  {
    path: "/groceries/ecommerce-shops",
    component: EcommerceShops,
    module: "shops",
  },
  {
    path: "/groceries/ecommerce-shops/details/:id",
    component: EcommerceShopDetails,
    module: "shops",
  },
  { path: "/groceries/ecommerce-add-product", component: EcommerceAddProduct },
  {
    path: "/groceries/ecommerce-brands",
    component: EcommerceBrands,
    module: "brands",
  },
  {
    path: "/groceries/ecommerce-brands/create",
    component: EcommerceCreateBrand,
    module: "brands",
  },
  {
    path: "/groceries/ecommerce-categories",
    component: EcommerceCategories,
    module: "categories",
  },
  {
    path: "/groceries/ecommerce-sub-categories",
    component: EcommerceSubCategories,
    module: "sub_categories",
  },
  {
    path: "/groceries/ecommerce-master-catalogue",
    component: EcommerceMasterCatalogue,
    module: "master_catalogue",
    module: "master",
  },
  {
    path: "/groceries/ecommerce-master-catalogue/detail/:id",
    component: EcommerceMasterCatalogueDetails,
    module: "master_catalogue",
    module: "master",
  },
  {
    path: "/groceries/ecommerce-master-catalogue/create",
    component: EcommerceCreateMasterCatalogue,
    module: "master_catalogue",
    module: "master",
  },
  {
    path: "/groceries/ecommerce-requests",
    component: EcommerceRequests,
    module: "requests",
  },
  {
    path: "/groceries/ecommerce-requests/details/:id",
    component: EcommerceRequestDetails,
    module: "requests",
  },
  {
    path: "/groceries/ecommerce-promotions",
    component: EcommercePromotions,
    module: "promotions",
  },
  {
    path: "/groceries/ecommerce-promotions/details/:id",
    component: EcommercePromotionDetails,
    module: "promotions",
  },

  {
    path: "/system-users/",
    component: EcommerceSystemUsers,
    module: "system_users",
  },
  { path: "/logout", component: Logout },

  //Services
  { path: "/services/dashboard", exact: true, component: Dashboard },
  {
    path: "/services/service-bookings",
    component: EcommerceOrders,
    module: "orders",
  },
  {
    path: "/services/service-bookings/details/:id",
    component: EcommerceOrderDetails,
    module: "orders",
  },
  {
    path: "/services/service-customers",
    component: EcommerceCustomers,
    module: "customers",
  },
  {
    path: "/services/service-customers/details/:id",
    component: EcommerceCustomerDetails,
    module: "customers",
  },
  {
    path: "/services/service-list",
    component: EcommerceServices,
    // module: "bookings",
  },
  {
    path: "/services/service-list/details/:id",
    component: EcommerceServiceDetails,
    // module: "orders",
  },
  {
    path: "/services/service-merchants",
    component: EcommerceMerchants,
    module: "merchants",
  },
  {
    path: "/services/service-merchants/details/:id",
    component: EcommerceMerchantDetails,
    module: "merchants",
  },
  {
    path: "/services/service-shops",
    component: EcommerceShops,
    module: "shops",
  },
  {
    path: "/services/service-shops/details/:id",
    component: EcommerceShopDetails,
    module: "shops",
  },
  {
    path: "/services/service-categories",
    component: ServiceCategories,
    module: "categories",
  },

  {
    path: "/services/service-sub-categories",
    component: EcommerceSubServices,
    // module: "sub_services",
  },
  {
    path: "/services/service-commission",
    component: Groceries_commission,
    // module: "sub_services",
  },
  {
    path: "/services/service-promotions",
    component: EcommercePromotions,
    module: "promotions",
  },
  {
    path: "/services/service-promotions/details/:id",
    component: EcommercePromotionServicesDetails,
    module: "promotions",
  },

  //Vehicles
  { path: "/vehicles/dashboard", exact: true, component: Dashboard },
  { path: "/vehicles/vehicles-brands", exact: true, component: VehicleBrands },
  {
    path: "/vehicles/vehicles-rentals",
    exact: true,
    component: VehicleRentals,
  },
  { path: "/vehicles/airport-rentals", exact: true, component: AirportRentals },
  { path: "/vehicles/rental-filters", exact: true, component: RentalsFilters },
  { path: "/vehicles/rental-drivers", exact: true, component: RentalsDrivers },
  { path: "/vehicles/rental-kyc", exact: true, component: Rental_kyc },
  { path: "/vehicles/rental-addons", exact: true, component: Rental_addons },
  {
    path: "/vehicles/rental-drivers/detail/:id",
    exact: true,
    component: Rental_addons_details,
  },
  {
    path: "/vehicles/rental-promotions",
    exact: true,
    component: EcommercePromotions,
  },
  {
    path: "/vehicles/rental-vehicle_types",
    exact: true,
    component: rentalVehicleTypeList,
  },
  {
    path: "/vehicles/vehicles-master-data",
    exact: true,
    component: Vehicle_Master_data,
  },
  //{ path: "/vehicles/vehicles-orders", exact: true, component: Rental_orders },
  {
    path: "/vehicles/vehicles-orders",
    component: EcommerceOrders,
    module: "orders",
  },
  {
    path: "/vehicles/vehicles-orders/details/:id",
    component: EcommerceOrderDetails,
    module: "orders",
  },
  {
    path: "/vehicles/vehicles-merchants",
    component: EcommerceMerchants,
    module: "merchants",
  },
  {
    path: "/vehicles/vehicles-merchants/details/:id",
    component: EcommerceMerchantDetails,
    module: "merchants",
  },
  {
    path: "/vehicles/rental-shops",
    component: EcommerceShops,
    module: "shops",
  },
  {
    path: "/vehicles/rental-shops/details/:id",
    component: EcommerceShopDetails,
    module: "shops",
  },

  //Property
  { path: "/property/dashboard", exact: true, component: Dashboard },
  {
    path: "/property/property-bookings",
    component: EcommerceOrders,
    module: "orders",
  },
  {
    path: "/property/property-bookings/details/:id",
    component: EcommerceOrderDetails,
    module: "orders",
  },
  {
    path: "/property/property-customers",
    component: EcommerceCustomers,
    module: "customers",
  },
  {
    path: "/property/property-customers/details/:id",
    component: EcommerceCustomerDetails,
    module: "customers",
  },
  {
    path: "/property/property-list",
    component: PropertyList,
    // module: "bookings",
  },
  // {
  //   path: "/services/service-list/details/:id",
  //   component: EcommerceServiceDetails,
  //   // module: "orders",
  // },
  {
    path: "/property/property-merchants",
    component: EcommerceMerchants,
    module: "merchants",
  },
  {
    path: "/property/property-merchants/details/:id",
    component: EcommerceMerchantDetails,
    module: "merchants",
  },
  {
    path: "/property/property-shops",
    component: EcommerceShops,
    module: "shops",
  },
  {
    path: "/property/property-shops/details/:id",
    component: EcommerceShopDetails,
    module: "shops",
  },
  {
    path: "/property/property-type",
    component: PropertyType,
    // module: "categories",
  },
  {
    path: "/property/property-rules",
    component: PropertyRule,
    // module: "categories",
  },
  {
    path: "/property/property-amenities",
    component: PropertyAmenities,
    // module: "categories",
  },

  // {
  //   path: "/services/service-sub-categories",
  //   component: EcommerceSubServices,
  //   // module: "sub_services",
  // },
  // {
  //   path: "/services/service-commission",
  //   component: Groceries_commission,
  //   // module: "sub_services",
  // },
  // {
  //   path: "/services/service-promotions",
  //   component: EcommercePromotions,
  //   module: "promotions",
  // },
  // {
  //   path: "/services/service-promotions/details/:id",
  //   component: EcommercePromotionServicesDetails,
  //   module: "promotions",
  // },

  //Experience
  { path: "/experience/dashboard", exact: true, component: Dashboard },
  {
    path: "/experience/experience-bookings",
    component: EcommerceOrders,
    module: "orders",
  },
  {
    path: "/experience/experience-bookings/details/:id",
    component: EcommerceOrderDetails,
    module: "orders",
  },
  {
    path: "/experience/experience-customers",
    component: EcommerceCustomers,
    module: "customers",
  },
  {
    path: "/experience/experience-customers/details/:id",
    component: EcommerceCustomerDetails,
    module: "customers",
  },
  {
    path: "/experience/experience-type",
    component: ExperienceType,
    // module: "bookings",
  },
  {
    path: "/experience/experience-list",
    component: ExperienceList,
    // module: "bookings",
  },
  // {
  //   path: "/services/service-list/details/:id",
  //   component: EcommerceServiceDetails,
  //   // module: "orders",
  // },
  {
    path: "/experience/experience-merchants",
    component: EcommerceMerchants,
    module: "merchants",
  },
  {
    path: "/experience/experience-merchants/details/:id",
    component: EcommerceMerchantDetails,
    module: "merchants",
  },
  {
    path: "/experience/experience-shops",
    component: EcommerceShops,
    module: "shops",
  },
  {
    path: "/experience/experience-shops/details/:id",
    component: EcommerceShopDetails,
    module: "shops",
  },
  // {
  //   path: "/services/service-categories",
  //   component: ServiceCategories,
  //   module: "categories",
  // },

  // {
  //   path: "/services/service-sub-categories",
  //   component: EcommerceSubServices,
  //   // module: "sub_services",
  // },
  // {
  //   path: "/services/service-commission",
  //   component: Groceries_commission,
  //   // module: "sub_services",
  // },
  // {
  //   path: "/services/service-promotions",
  //   component: EcommercePromotions,
  //   module: "promotions",
  // },
  // {
  //   path: "/services/service-promotions/details/:id",
  //   component: EcommercePromotionServicesDetails,
  //   module: "promotions",
  // },

  { path: "*", component: My404Page },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
