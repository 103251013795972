export const CarsHtmlToExcelHeaders=[
    "naming.versionId",
    "naming.source_url",
   "naming.make",
    "naming.model",
   "naming.version",
    "naming.bodystyle",
   "naming.notes",
    "naming.image_url",
    "naming.price",
    "naming.onroadprice_delhi",
    "keydata.key_price",
   "keydata.key_mileage_arai",
    "keydata.key_engine",
    "keydata.key_transmission",
    "keydata.key_fueltype",
    "keydata.key_seatingcapacity",
    "enginetransmission.engine",
    "enginetransmission.engine_type",
    "enginetransmission.top_speed",
    "enginetransmission.acceleration_0_100_kmph",
    "enginetransmission.fueltype",
    "enginetransmission.maxpower",
    "enginetransmission.maxpowerRPM",
    "enginetransmission.maxtorque",
    "enginetransmission.maxtorqueRPM",
    "enginetransmission.performanceonalternatefuel",
    "enginetransmission.maxengineperformance",
    "enginetransmission.maxmotorperformance",
    "enginetransmission.mileage_arai",
    "enginetransmission.powerconsumptionpermileage",
    "enginetransmission.driving_range",
    "enginetransmission.drivetrain",
    "enginetransmission.transmission",
    "enginetransmission.emissionstandard",
    "enginetransmission.turbocharger_supercharger",
    "enginetransmission.battery",
    "enginetransmission.battery_charging",
    "enginetransmission.electric_motor",
    "enginetransmission.others",
    "enginetransmission.alternatefuel",
    "dimensionsandweight.length",
    "dimensionsandweight.width",
    "dimensionsandweight.height",
    "dimensionsandweight.wheelbase",
    "dimensionsandweight.groundclearance",
    "dimensionsandweight.kerbweight",
    "capacity.doors",
    "capacity.seating_capacity",
    "capacity.no_of_seating_rows",
    "capacity.bootspace",
    "capacity.fuel_tank_capacity",
    "suspension_brakes_steeringandtyres.front_suspension",
    "suspension_brakes_steeringandtyres.rear_suspension",
    "suspension_brakes_steeringandtyres.front_brake_type",
    "suspension_brakes_steeringandtyres.rear_brake_type",
    "suspension_brakes_steeringandtyres.minimum_turning_radius",
    "suspension_brakes_steeringandtyres.steering_type",
    "suspension_brakes_steeringandtyres.wheels",
    "suspension_brakes_steeringandtyres.spare_wheels",
    "suspension_brakes_steeringandtyres.front_tyres",
    "suspension_brakes_steeringandtyres.rear_tyres",
    "suspension_brakes_steeringandtyres.four_wheel_steering",
    "suspension_brakes_steeringandtyres.braking_performance",
    "safety.overspeed_warning",
    "safety.lane_departure_warning",
    "safety.emergency_brake_light_flashing",
    "safety.forward_collision_warning_fcw",
    "safety.automatic_emergency_braking_aeb",
    "safety.high_beam_assist",
    "safety.ncap_rating",
    "safety.blind_spot_detection",
    "safety.lane_departure_prevention",
    "safety.puncture_repair_kit",
    "safety.rear_cross_traffic_assist",
    "safety.airbags",
    "safety.middle_rear_three_point_seatbelt",
    "safety.middle_rear_headrest",
    "safety.tyre_pressure_monitoring_system_tpms",
    "safety.child_seat_anchor_points",
    "safety.seatbelt_warning",
    "barking_and_traction.antilock_barking_system_abs",
    "barking_and_traction.electronic_brakeforce_distribution_ebd",
    "barking_and_traction.brake_assist_ba",
    "barking_and_traction.electronic_stability_program",
    "barking_and_traction.four_wheel_drive",
    "barking_and_traction.hill_hold_control",
    "barking_and_traction.traction_control_system_tc_tcs",
    "barking_and_traction.ride_height_adjustment",
    "barking_and_traction.hill_descent_control",
    "barking_and_traction.limited_slip_differential_lsd",
    "barking_and_traction.differential_lock",
    "locks_and_security.engine_immobilizer",
    "locks_and_security.central_locking",
    "locks_and_security.speed_sensing_doorlock",
    "locks_and_security.child_safety_lock",
    "comfort_and_convenience.air_conditioner",
    "comfort_and_convenience.front_ac",
    "comfort_and_convenience.rear_ac",
    "comfort_and_convenience.headlight_and_ignition_on_reminder",
    "comfort_and_convenience.keyless_start_button_start",
    "comfort_and_convenience.steering_adjustment",
    "comfort_and_convenience.12v_power_outlets",
    "comfort_and_convenience.cruise_control",
    "comfort_and_convenience.parking_sensors",
    "comfort_and_convenience.parking_assist",
    "comfort_and_convenience.antiglare_mirrors",
    "comfort_and_convenience.vanity_mirrors_on_sunvisors",
    "comfort_and_convenience.heater",
    "comfort_and_convenience.cabin_bootaccess",
    "comfort_and_convenience.third_row_ac",
    "telematics.remote_car_light_flashing_and_honking_via_app",
    "telematics.geofence",
    "telematics.remote_sunroof_open_close_via_app",
    "telematics.over_the_air_updates_ota",
    "telematics.check_vehicle_status_via_app",
    "telematics.remote_car_lock_unlock_via_app",
    "telematics.emergency_call",
    "telematics.find_my_car",
    "telematics.remote_ac_on_off_via_app",
    "telematics.alexa_compatibility",
    "seats_and_upholstery.driver_seat_adjustment",
    "seats_and_upholstery.front_passenger_seat_adjustment",
    "seats_and_upholstery.rear_row_seat_adjustment",
    "seats_and_upholstery.third_row_seat_adjustment",
    "seats_and_upholstery.seat_upholstery",
    "seats_and_upholstery.leather_wrapped_steering_wheel",
    "seats_and_upholstery.leather_wrapped_gear_knob",
    "seats_and_upholstery.driver_armrest",
    "seats_and_upholstery.rear_passenger_seats_type",
    "seats_and_upholstery.third_row_seats_type",
    "seats_and_upholstery.ventilated_seats",
    "seats_and_upholstery.ventilated_seat_type",
    "seats_and_upholstery.interiors",
    "seats_and_upholstery.interior_colours",
    "seats_and_upholstery.rear_armrest",
    "seats_and_upholstery.folding_rear_seat",
    "seats_and_upholstery.split_rear_seat",
    "seats_and_upholstery.split_third_row_seat",
    "seats_and_upholstery.front_seat_pocket",
    "seats_and_upholstery.headrests",
    "seats_and_upholstery.fourth_row_seat_adjustment",
    "storage.cup_holders",
    "storage.driver_armrest_storage",
    "storage.cooled_glove_box",
    "storage.sunglass_holder",
    "storage.third_row_cup_holders",
    "doors_windows_mirrors_wipers.one_touch_down",
    "doors_windows_mirrors_wipers.one_touch_up",
    "doors_windows_mirrors_wipers.power_windows",
    "doors_windows_mirrors_wipers.adjustable_orvm",
    "doors_windows_mirrors_wipers.turn_indicators_on_orvm",
    "doors_windows_mirrors_wipers.rear_defogger",
    "doors_windows_mirrors_wipers.rear_wiper",
    "doors_windows_mirrors_wipers.exterior_door_handles",
    "doors_windows_mirrors_wipers.rain_sensing_wipers",
    "doors_windows_mirrors_wipers.interior_door_handles",
    "doors_windows_mirrors_wipers.door_pockets",
    "doors_windows_mirrors_wipers.side_window_blinds",
    "doors_windows_mirrors_wipers.bootlid_opener",
    "doors_windows_mirrors_wipers.rear_windshield_blind",
    "doors_windows_mirrors_wipers.outside_rearview_mirrors_orvms",
    "doors_windows_mirrors_wipers.scuff_plates",
    "exterior.sunroof_moonroof",
    "exterior.roof_rails",
    "exterior.roof_mounted_antenna",
    "exterior.body_coloured_bumpers",
    "exterior.chrome_finish_exhaust_pipe",
    "exterior.body_kit",
    "exterior.rub_strips",
    "lighting.fog_lights",
    "lighting.daytime_running_lights",
    "lighting.headlights",
    "lighting.automatic_head_lamps",
    "lighting.followme_home_headlamps",
    "lighting.tail_lights",
    "lighting.cabin_lamps",
    "lighting.headlight_height_adjuster",
    "lighting.glove_box_lamp",
    "lighting.lights_on_vanity_mirrors",
    "lighting.rear_reading_lamp",
    "lighting.cornering_headlights",
    "lighting.puddle_lamps",
    "lighting.ambient_interior_lighting",
    "instrumentation.instrument_cluster",
    "instrumentation.trip_meter",
    "instrumentation.average_fuel_consumption",
    "instrumentation.average_speed",
    "instrumentation.distance_to_empty",
    "instrumentation.clock",
    "instrumentation.low_fuel_level_warning",
    "instrumentation.door_ajar_warning",
    "instrumentation.adjustable_cluster_brightness",
    "instrumentation.gear_indicator",
    "instrumentation.shift_indicator",
    "instrumentation.headsupdisplay_hud",
    "instrumentation.tachometer",
    "instrumentation.instantaneous_consumption",
    "entertainment_information_communication.smart_connectivity",
    "entertainment_information_communication.integrated_indash_musicsystem",
    "entertainment_information_communication.headunit_size",
    "entertainment_information_communication.display",
    "entertainment_information_communication.display_screen_for_rear_passengers",
    "entertainment_information_communication.gps_navigation_system",
    "entertainment_information_communication.speakers",
    "entertainment_information_communication.usb_compatibility",
    "entertainment_information_communication.aux_compatibility",
    "entertainment_information_communication.bluetooth_compatibility",
    "entertainment_information_communication.mp3_playback",
    "entertainment_information_communication.cd_player",
    "entertainment_information_communication.dvd_playback",
    "entertainment_information_communication.am_fm_radio",
    "entertainment_information_communication.ipod_compatibility",
    "entertainment_information_communication.internal_hard_drive",
    "entertainment_information_communication.steering_mounted_controls",
    "entertainment_information_communication.voice_command",
    "entertainment_information_communication.wireless_charger",
    "entertainment_information_communication.gesture_control",
    "rear_row.seat_adjustment",
    "rear_row.seat_base_sliding",
    "manufacturer_warranty.warranty_in_years",
    "manufacturer_warranty.warranty_in_kms",
    "manufacturer_warranty.battery_warranty_in_years",
    "manufacturer_warranty.battery_warranty_in_kms",
    "colors.color_name",
    "colors.color_rgb",
    "price_breakdown.ex_showroom_price",
    "price_breakdown.rto",
    "price_breakdown.insurance",
    "price_breakdown.tax_collected_at_source_tcs",
    "price_breakdown.handling_logistic_charges",
    "price_breakdown.fast_tag",
    "on_road_price_by_city.mumbai",
    "on_road_price_by_city.bangalore",
    "on_road_price_by_city.delhi",
    "on_road_price_by_city.pune",
    "on_road_price_by_city.navi_mumbai",
    "on_road_price_by_city.hyderabad",
    "on_road_price_by_city.ahmedabad",
    "on_road_price_by_city.chennai",
    "on_road_price_by_city.kolkata",
    "description",
    "vehicle_category",
    "reputation"
]

export const BikesHtmlToExcelHeaders=[
    "url",

    "naming.versionId",
    "naming.source_url",
    "naming.make",
    "naming.model",
    "naming.version",
    "naming.bodystyle",
    "naming.notes",
    "naming.image_url",
    "naming.price",
    "naming.onroadprice_delhi",

    "colors.color_name",
    "colors.color_rgb",

 
    "power.bore",
    "power.clutch",
    "power.stroke",
    "power.ignition",
    "power.cylinders",
    "power.fuel_type",
    "power.max_power",
    "power.top_speed",
    "power.max_torque",
    "power.motor_type" ,
    "power.rated_power",
    "power.spark_plugs",
    "power.battery_type",
    "power.displacement",
    "power.mileage_arai",
    "power.riding_range",
    "power.transmission",
    "power.charger_output",
    "power.cooling_system",
    "power.battery_capacity",
    "power.carrying_capacity",
    "power.compression_ratio",
    "power.emission_standard",
    "power.transmission_type",
    "power.fast_charging_time",
    "power.fuel_tank_capacity",
    "power.valves_per_cylinder",
    "power.fuel_delivery_system",
    "power.battery_charging_time",
    "power.reserve_fuel_capacity",
    "power.mileage_owner_reported",

    "barking_and_traction.antilock_barking_system_abs",
    "barking_and_traction.electronic_brakeforce_distribution_ebd",
    "barking_and_traction.brake_assist_ba",
    "barking_and_traction.electronic_stability_program",
    "barking_and_traction.four_wheel_drive",
    "barking_and_traction.hill_hold_control",
    "barking_and_traction.traction_control_system_tc_tcs",
    "barking_and_traction.ride_height_adjustment",
    "barking_and_traction.hill_descent_control",
    "barking_and_traction.limited_slip_differential_lsd",
    "barking_and_traction.differential_lock",


    "dimensionsandweight.length",
    "dimensionsandweight.width",
    "dimensionsandweight.height",
    "dimensionsandweight.wheelbase",
    "dimensionsandweight.groundclearance",
    "dimensionsandweight.kerbweight",


    "features.bore" ,
    "features.clutch" ,
    "features.stroke" ,
    "features.ignition" ,
    "features.cylinders" ,
    "features.fuel_type",
    "features.max_power",
    "features.top_speed",
    "features.max_torque",
    "features.motor_type",
    "features.rated_power",
    "features.spark_plugs" ,
    "features.battery_type",
    "features.displacement" ,
    "features.mileage_arai" ,
    "features.riding_range",
    "features.transmission",
    "features.charger_output",
    "features.cooling_system" ,
    "features.battery_capacity",
    "features.carrying_capacity",
    "features.compression_ratio" ,
    "features.emission_standard" ,
    "features.transmission_type" ,
    "features.fast_charging_time",
    "features.fuel_tank_capacity" ,
    "features.valves_per_cylinder" ,
    "features.fuel_delivery_system" ,
    "features.battery_charging_time",
    "features.reserve_fuel_capacity" ,
    "features.mileage_owner_reported" ,

    "description",
    "vehicle_category"
   
 
   ]


  export const createHeaders = {

    naming : [    {name:"make", span:3,type:"text"},
                  {name:"model",span:3,type:"text"},
                  {name:"notes", span:3,type:"text"},
                  {name:"price", span:3,type:"text"},
                  {name:"version", span:3,type:"text"},
                  {name:"bodystyle", span:3,type:"text"},
                  {name:"image_url", span:3,type:"img"},
                  {name:"versionId", span:3,type:"text"},
                  {name:"source_url", span:3,type:"img"},
                  {name:"onroadprice_delhi",span:3,type:"text"}
                 ],

    keydata : [
                  {name:"key_price", span:3,type:"text"},
                  {name:"key_engine", span:3,type:"text"},
                  {name:"key_fueltype", span:3,type:"text"},
                  {name:"key_mileage_arai", span:3,type:"text"},
                  {name:"key_transmission", span:3,type:"text"},
                  {name:"key_seatingcapacity", span:3,type:"text"},
               ],

    enginetransmission : [
                  {name:"engine", span:3,type:"text"},
                  {name:"others", span:3,type:"text"},
                  {name:"battery", span:3,type:"text"},
                  {name:"fueltype", span:3,type:"text"},
                  {name:"maxpower", span:3,type:"text"},
                  {name:"maxtorque", span:3,type:"text"},
                  {name:"top_speed", span:3,type:"text"},
                  {name:"drivetrain", span:3,type:"text"},
                  {name:"engine_type", span:3,type:"text"},
                  {name:"maxpowerRPM", span:3,type:"text"},
                  {name:"mileage_arai", span:3,type:"text"},
                  {name:"transmission", span:3,type:"text"},
                  {name:"alternatefuel", span:3,type:"text"},
                  {name:"driving_range", span:3,type:"text"},
                  {name:"electric_motor", span:3,type:"text"},
                  {name:"battery_charging", span:3,type:"text"},
                  {name:"emissionstandard", span:3,type:"text"},
                  {name:"maxmotorperformance", span:3,type:"text"},
                  {name:"maxengineperformance", span:3,type:"text"},
                  {name:"acceleration_0_100_kmph", span:3,type:"text"},
                  {name:"turbocharger_supercharger", span:3,type:"boolean"},
                  {name:"performanceonalternatefuel", span:3,type:"text"},
                  {name:"powerconsumptionpermileage", span:3,type:"text"},
           ],
  
    dimensionsandweight : [
                  {name:"width",span:3,type:"text"},
                  {name:"height",span:3,type:"text"},
                  {name:"length",span:3,type:"text"},
                  {name:"wheelbase",span:3,type:"text"},
                  {name:"kerbweight",span:3,type:"text"},
                  {name:"groundclearance",span:3,type:"text"},
              ],

    capacity : [
                  {name:"doors", span:3,type:"text"},
                  {name:"bootspace", span:3,type:"text"},
                  {name:"seating_capacity", span:3,type:"text"},
                  {name:"fuel_tank_capacity", span:3,type:"text"},
                  {name:"no_of_seating_rows", span:3,type:"text"},
              ],

     suspension_brakes_steeringandtyres : [
                  {name:"wheels", span:3,type:"text"},
                  {name:"rear_tyres", span:3,type:"text"},
                  {name:"front_tyres", span:3,type:"text"},
                  {name:"spare_wheels", span:3,type:"text"},
                  {name:"steering_type", span:3,type:"text"},
                  {name:"rear_brake_type", span:3,type:"text"},
                  {name:"front_brake_type", span:3,type:"text"},
                  {name:"front_suspension", span:3,type:"text"},
                  {name:"braking_performance", span:3,type:"text"},
                  {name:"four_wheel_steering", span:3,type:"text"},
                  {name:"minimum_turning_radius", span:3,type:"text"},
          
              ],

    safety : [
              {name:"airbags", span:3,type:"text"},
              {name:"ncap_rating", span:3,type:"text"},
              {name:"high_beam_assist", span:3,type:"text"},
              {name:"seatbelt_warning", span:3,type:"boolean"},
              {name:"overspeed_warning", span:3,type:"text"},
              {name:"puncture_repair_kit", span:3,type:"text"},
              {name:"blind_spot_detection", span:3,type:"text"},
              {name:"middle_rear_headrest", span:3,type:"text"},
              {name:"lane_departure_warning", span:3,type:"text"},
              {name:"child_seat_anchor_points", span:3,type:"boolean"},
              {name:"lane_departure_prevention", span:3,type:"text"},
              {name:"rear_cross_traffic_assist", span:3,type:"text"},
              {name:"forward_collision_warning_fcw", span:3,type:"text"},
              {name:"emergency_brake_light_flashing", span:3,type:"text"},
              {name:"automatic_emergency_braking_aeb", span:3,type:"text"},
              {name:"middle_rear_three_point_seatbelt", span:3,type:"text"},
              {name:"tyre_pressure_monitoring_system_tpms", span:3,type:"text"},
          ],

    barking_and_traction : [
              {name:"brake_assist_ba",span:3,type:'boolean'},
              {name:"four_wheel_drive",span:3,type:'boolean'},
              {name:"differential_lock",span:3,type:'boolean'},
              {name:"hill_hold_control",span:3,type:'boolean'},
              {name:"hill_descent_control",span:3,type:'boolean'},
              {name:"ride_height_adjustment",span:3,type:'boolean'},
              {name:"antilock_barking_system_abs",span:3,type:'boolean'},
              {name:"electronic_stability_program",span:3,type:'boolean'},
              {name:"limited_slip_differential_lsd",span:3,type:'boolean'},
              {name:"traction_control_system_tc_tcs",span:3,type:'boolean'},
              {name:"electronic_brakeforce_distribution_ebd",span:3,type:'boolean'},
          ]
,
    locks_and_security : [
              {name:"central_locking", span:3,type:"text"},
              {name:"child_safety_lock", span:3,type:"boolean"},
              {name:"engine_immobilizer", span:3,type:"boolean"},
              {name:"speed_sensing_doorlock", span:3,type:"boolean"},
          ],

    comfort_and_convenience : [
              {name:"heater",span:3,type:"boolean"},
              {name:"rear_ac",span:3,type:"text"},
              {name:"front_ac",span:3,type:"text"},
              {name:"third_row_ac",span:3,type:"text"},
              {name:"cruise_control",span:3,type:"yes"},
              {name:"parking_assist",span:3,type:"boolean"},
              {name:"air_conditioner",span:3,type:"text"},
              {name:"parking_sensors",span:3,type:"text"},
              {name:"cabin_bootaccess",span:3,type:"text"},
              {name:"12v_power_outlets",span:3,type:"text"},
              {name:"antiglare_mirrors",span:3,type:"text"},
              {name:"steering_adjustment",span:3,type:"text"},
              {name:"keyless_start_button_start",span:3,type:"boolean"},
              {name:"vanity_mirrors_on_sunvisors",span:3,type:"boolean"},
              {name:"headlight_and_ignition_on_reminder",span:3,type:"text"},
          ],

    telematics : [
              {name:"geofence",span:3,type:"boolean"},
              {name:"find_my_car",span:3,type:"boolean"},
              {name:"emergency_call",span:3,type:"boolean"},
              {name:"alexa_compatibility",span:3,type:"boolean"},
              {name:"over_the_air_updates_ota",span:3,type:"boolean"},
              {name:"remote_ac_on_off_via_app",span:3,type:"boolean"},
              {name:"check_vehicle_status_via_app",span:3,type:"boolean"},
              {name:"remote_car_lock_unlock_via_app",span:3,type:"boolean"},
              {name:"remote_sunroof_open_close_via_app",span:3,type:"boolean"},
              {name:"remote_car_light_flashing_and_honking_via_app",span:3,type:"boolean"},
          ],

    seats_and_upholstery : [
              {name:"headrests", span:3,type:"text"},
              {name:"interiors", span:3,type:"text"},
              {name:"rear_armrest", span:3,type:"boolean"},
              {name:"driver_armrest", span:3,type:"boolean"},
              {name:"seat_upholstery", span:3,type:"text"},
              {name:"split_rear_seat", span:3,type:"boolean"},
              {name:"interior_colours", span:3,type:"text"},
              {name:"ventilated_seats", span:3,type:"text"},
              {name:"folding_rear_seat", span:3,type:"boolean"},
              {name:"front_seat_pocket", span:3,type:"boolean"},
              {name:"split_third_row_seat", span:3,type:"text"},
              {name:"third_row_seats_type", span:3,type:"text"},
              {name:"ventilated_seat_type", span:3,type:"text"},
              {name:"driver_seat_adjustment", span:3,type:"text"},
              {name:"rear_row_seat_adjustment", span:3,type:"text"},
              {name:"leather_wrapped_gear_knob", span:3,type:"boolean"},
              {name:"rear_passenger_seats_type", span:3,type:"text"},
              {name:"third_row_seat_adjustment", span:3,type:"text"},
              {name:"fourth_row_seat_adjustment", span:3,type:"text"},
              {name:"leather_wrapped_steering_wheel", span:3,type:"boolean"},
              {name:"front_passenger_seat_adjustment", span:3,type:"text"},
              {name:"fourth_row_seat_adjustment", span:3,type:"text"},
          ],


    storage : [
              {name:"cup_holders", span:3,type:"text"},
              {name:"sunglass_holder", span:3,type:"boolean"},
              {name:"cooled_glove_box", span:3,type:"boolean"},
              {name:"third_row_cup_holders", span:3,type:"text"},
              {name:"driver_armrest_storage", span:3,type:"boolean"},
      
          ],


    doors_windows_mirrors_wipers : [
              {name:"rear_wiper",span:3,type:"boolean"},
              {name:"door_pockets",span:3,type:"text"},
              {name:"one_touch_up",span:3,type:"text"},
              {name:"scuff_plates",span:3,type:"text"},
              {name:"power_windows",span:3,type:"text"},
              {name:"rear_defogger",span:3,type:"boolean"},
              {name:"bootlid_opener",span:3,type:"text"},
              {name:"one_touch_down",span:3,type:"text"},
              {name:"adjustable_orvm",span:3,type:"text"},
              {name:"side_window_blinds",span:3,type:"text"},
              {name:"rain_sensing_wipers",span:3,type:"boolean"},
              {name:"exterior_door_handles",span:3,type:"text"},
              {name:"interior_door_handles",span:3,type:"text"},
              {name:"rear_windshield_blind",span:3,type:"text"},
              {name:"turn_indicators_on_orvm",span:3,type:"boolean"},
              {name:"outside_rearview_mirrors_orvms",span:3,type:"text"},
          ],

    exterior : [
              {name:"body_kit", span:3,type:"boolean"},
              {name:"roof_rails", span:3,type:"boolean"},
              {name:"rub_strips", span:3,type:"boolean"},
              {name:"sunroof_moonroof", span:3,type:"boolean"},
              {name:"roof_mounted_antenna", span:3,type:"boolean"},
              {name:"body_coloured_bumpers", span:3,type:"boolean"},
              {name:"chrome_finish_exhaust_pipe", span:3,type:"boolean"},
          ],


    lighting : [
              {name:"fog_lights", span:3,type:"text"},
              {name:"headlights", span:3,type:"text"},
              {name:"cabin_lamps", span:3,type:"text"},
              {name:"tail_lights", span:3,type:"text"},
              {name:"puddle_lamps", span:3,type:"text"},
              {name:"glove_box_lamp", span:3,type:"text"},
              {name:"rear_reading_lamp", span:3,type:"text"},
              {name:"automatic_head_lamps", span:3,type:"boolean"},
              {name:"cornering_headlights", span:3,type:"boolean"},
              {name:"daytime_running_lights", span:3,type:"boolean"},
              {name:"followme_home_headlamps", span:3,type:"boolean"},
              {name:"lights_on_vanity_mirrors", span:3,type:"boolean"},
              {name:"ambient_interior_lighting", span:3,type:"text"},
              {name:"headlight_height_adjuster", span:3,type:"boolean"},
          ]
,

    instrumentation : [
              {name:"clock", span:3,type:"text"},
              {name:"tachometer", span:3,type:"text"},
              {name:"trip_meter", span:3,type:"text"},
              {name:"average_speed", span:3,type:"boolean"},
              {name:"shift_indicator", span:3,type:"boolean"},
              {name:"distance_to_empty", span:3,type:"boolean"},
              {name:"door_ajar_warning", span:3,type:"boolean"},
              {name:"gear_indicator", span:3,type:"boolean"},
              {name:"headsupdisplay_hud", span:3,type:"text"},
              {name:"instrument_cluster", span:3,type:"text"},
              {name:"low_fuel_level_warning", span:3,type:"boolean"},
              {name:"average_fuel_consumption", span:3,type:"boolean"},
              {name:"instantaneous_consumption", span:3,type:"boolean"},
              {name:"adjustable_cluster_brightness", span:3,type:"boolean"},
              {name:"average_fuel_consumption", span:3,type:"text"},
          ],

    entertainment_information_communication : [
              {name:"display", span:3,type:"boolean"},
              {name:"speakers", span:3,type:"boolean"},
              {name:"cd_player", span:3,type:"boolean"},
              {name:"am_fm_radio", span:3,type:"boolean"},
              {name:"dvd_playback", span:3,type:"boolean"},
              {name:"headunit_size", span:3,type:"boolean"},
              {name:"voice_command", span:3,type:"boolean"},
              {name:"wireless_charger", span:3,type:"boolean"},
              {name:"usb_compatibility", span:3,type:"boolean"},
              {name:"ipod_compatibility", span:3,type:"boolean"},
              {name:"smart_connectivity", span:3,type:"boolean"},
              {name:"internal_hard_drive", span:3,type:"boolean"},
              {name:"gps_navigation_system", span:3,type:"boolean"},
              {name:"bluetooth_compatibility", span:3,type:"boolean"},
              {name:"steering_mounted_controls", span:3,type:"boolean"},
              {name:"integrated_indash_musicsystem", span:3,type:"boolean"},
          ],


    colors : [

              {name:"color_rgb", span:3,type:"text"},
              {name:"color_name", span:3,type:"text"},
      
             ],
     url   : {name:"url", span:3,type:"img", },

     reputation   : {name:"reputation", span:3,type:"select", },

     vehicle_category   : {name:"vehicle_category", span:3, type:"select", },
       
           
         }