import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
    Button,
    Col,
    Row,
} from "reactstrap"
import { CheckPicker } from 'rsuite'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
import moment from "moment"
//GraphQL
import { MerchantPromotionList } from "gql/query"
import { useQuery, useLazyQuery } from "@apollo/client"

const MerchantPromotions = (props) => {
    const FormatData = (data) => {
        const promotions = data.promotion_stores.map(elem => {
			return {
				id: elem.promotion?.id,
                store_name: elem.promotion?.store?.name,
                store_id: elem.promotion?.store?.id,
				promo_id: elem.promotion?.id,
				created_at: elem.promotion?.created_at,
				label: elem.promotion?.label,
				code: elem.promotion?.code,
				description: elem.promotion?.description || "N/A",
				value_type: elem.promotion?.value_type,
				value: elem.promotion?.value,
				max_order_count: elem.promotion?.max_order_count,
				min_order_count: elem.promotion?.min_order_count,
				max_order_amount: elem.promotion?.max_order_amount,
				min_order_amount: elem.promotion?.min_order_amount,
				available_hours: elem.promotion?.available_hours,
				no_of_time_used: elem.promotion?.used_count,
				active: elem.promotion?.active,
				promoted_by: elem.promotion?.promoted_by,
                usable_count: elem.promotion?.usable_count || "N/A",
                valid_from: elem.promotion?.valid_from,
                valid_till: elem.promotion?.valid_till
			}
		})
		return promotions
    }
    const PromoCodesColumn = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			dataField: "promo_id",
			text: "ID",
			sort: false,
		},
        {
			dataField: "store_name",
			text: "Store",
			sort: false,
		},
		{
			dataField: "code",
			text: "Code",
			sort: true,
		},
		{
			text: "Description",
			dataField: "description",
			sort: true,
		},
		{
			dataField: "value_type",
			text: "Discount Type",
			sort: true,
		},
		{
			text: "Value",
			dataField: "value",
			sort: true,
		},
		// {
		// 	text: "Max Order Count",
		// 	dataField: "max_order_count",
		// 	sort: false,
		// },
		{
			text: "Min Unit Count",
			dataField: "min_order_count",
			sort: false
		},
		// {
		// 	text: "Max Order Amount",
		// 	dataField: "max_order_amount",
		// 	sort: false,
		// 	formatter: (cellContent, row) => row.max_order_amount ? `₹${row.max_order_amount}` : 0,
		// },
		{
			text: "Min Order Amount",
			dataField: "min_order_amount",
			sort: false,
			formatter: (cellContent, row) => row.min_order_amount ? `₹${row.min_order_amount}` : 0,
		},
		{
			text: "Valid From",
			dataField: "valid_from",
			sort: false,
            formatter: (cellContent) => moment(cellContent).format("DD/MM/YYYY h:mm A")
		},
        {
			text: "Valid Till",
			dataField: "valid_till",
			sort: false,
            formatter: (cellContent) => moment(cellContent).format("DD/MM/YYYY h:mm A")
		},
        {
			text: "Maximum Usage",
			dataField: "usable_count",
			sort: false,
		},
		{
			text: "No. of Time Used",
			dataField: "no_of_time_used",
			sort: false,
		},
		{
			text: "Active",
			dataField: "active",
			sort: false,
            formatter: (cellContent, row) => row.active ? "Yes" : "No",
		},
		// {
		// 	text: "Promoted By",
		// 	dataField: "promoted_by",
		// 	sort: false,
		// },
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		}
	]
    const defaultSorted = []
    const [PageData, setPageData] = useState({
        limit: 10,
        pageNo: 1
    })
    const [queryCondition, setQueryCondition] = useState({})
    const [promotions, setPromotions] = useState([])
    const [storesData, setStoresData] = useState([])
    const [selectedStores, setSelectedStores] = useState([])

    const PageDown = () => {
        if (PageData.pageNo > 0)
            setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
    }
    const PageUp = () => {
        setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
    }
    const [getData, { loading, data }] = useLazyQuery(MerchantPromotionList, {
        skipCache: true,
        variables: {
            limit: PageData.limit,
            offset: (PageData.pageNo - 1) * PageData.limit,
            where: queryCondition
        }
    })
    const count = data?.promotion_stores_aggregate?.aggregate?.count
    const maxPage = Math.ceil(count / PageData.limit)
    useEffect(() => {
        if (data) {
            setPromotions(FormatData(data))
        }
    }, [data])
    useEffect(() => {
        if (props.stores?.length) {
            let storeIDs = props.stores.map(store => store.id)
            setQueryCondition({ store_id: { _in: storeIDs } })
            let storesTemp = []
            props.stores?.map((store) => {
                storesTemp.push({
                    label: store.name,
                    value: store.id
                })
            })
            setStoresData(storesTemp)
        }
    }, [props.stores])
    useEffect(() => {
        if (selectedStores.length) {
            setQueryCondition({
                ...queryCondition,
                store_id: { _in: selectedStores }
            })
        } else {
            let storeIDs = props.stores.map(store => store.id)
            setQueryCondition({ store_id: { _in: storeIDs } })
        }
    }, [selectedStores])
    useEffect(() => {
        if (queryCondition.store_id) {
            getData()
        }
    }, [queryCondition])
    useEffect(()=>{
		if(props.selectedStores?.stores?.length > 0 && props.selectedStores?.target==="promotions"){
			setSelectedStores(props.selectedStores.stores)
		}
	}, [props.selectedStores])
    return (
        <div className="p-3">
            <Row className="mb-5">
                <Row className="mb-2">
                    <Col sm="8">
                        <h4>No. of Promo Codes: {count}</h4>
                    </Col>
                    <Col sm="4">
                        <div className="text-sm-end d-flex justify-content-end">
                            <CheckPicker
                                data={storesData}
                                placeholder="Select Store"
                                style={{ width: 224 }}
                                value={selectedStores}
                                onChange={setSelectedStores}
                                searchable={false}
                            />
                        </div>
                    </Col>
                </Row>
                <hr />
                <ToolkitProvider
                    keyField="id"
                    data={promotions}
                    columns={PromoCodesColumn}
                    bootstrap4
                // search
                >
                    {toolkitProps => (
                        <>
                            <Row>
                                <Col xl="12">
                                    <div className="table-responsive">
                                        {loading ? <Loader /> :
                                            <BootstrapTable
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                classes={"table align-middle table-nowrap"}
                                                keyField="id"
                                                {...toolkitProps.baseProps}
                                                onTableChange={() => { }}
                                                noDataIndication={() => "No Promotions Found!"}
                                            // {...paginationTableProps}
                                            />
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-md-center mt-40">
                                <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                                    <Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
                                        Previous Page
                                    </Button>
                                    <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
                                    </div>
                                    <Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
                                        Next Page
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </ToolkitProvider>
            </Row>
        </div>
    )
}
MerchantPromotions.propTypes = {
    stores: PropTypes.array,
    setSelectedStores: PropTypes.func,
    selectedStores: PropTypes.any
}
MerchantPromotions.displayName = 'MerchantPromotions'
export default MerchantPromotions