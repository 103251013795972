import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
import EcommerceProductRequestsTable from "./ProductRequests"
import EcommerceGeneralRequestsTable from "./GeneralRequests"
import NonMRPRequestsTable from "./NonMRPRequests"
//Import custom styles
import "./EcommerceRequests.style.scss"
const EcommerceRequests = props => {
	const [activeTab, setActiveTab] = useState('product_requests')
	return (
		<div className="EcommerceRequests">
			<div className="page-content">
				<MetaTags>
					<title>Product Requests | EEFind - React Admin &amp; Dashboard Template</title>
				</MetaTags>
				<Container fluid>
					<Breadcrumbs title="Dashboard" breadcrumbItem="Requests" />
					<Row>
						<Col xs="12">
							<Card>
								<CardBody>
									<Tabs activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
										<Tab eventKey="product_requests" title="Product Requests">
											<EcommerceProductRequestsTable
												queryCondition={null}
											/>
										</Tab>
										<Tab eventKey="non_mrp_requests" title="Non MRP Requests">
											<NonMRPRequestsTable
												queryCondition={null}
											/>
										</Tab>
										<Tab eventKey="general_requests" title="General Requests">
											<EcommerceGeneralRequestsTable
												queryCondition={null}
											/>
										</Tab>
									</Tabs>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	)
}
EcommerceRequests.propTypes = {
}
export default EcommerceRequests