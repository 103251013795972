import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withRouter, useHistory, useRouteMatch } from "react-router-dom"
import { Panel } from "rsuite"
import BootstrapTable from "react-bootstrap-table-next"
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'
import Swal from "sweetalert2"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import axios from "axios"

//redux
import { useMutation, useLazyQuery } from "@apollo/client"
import { GetPayoutList, GetCurrentPayoutCycle } from "gql/query"
import { UpdateProduct } from "gql/Mutation"

//filters
import EcommercePayoutsFilter from "components/Filters/Ecommerce/EcommercePayoutsFilter"

//functions
import { FormatData, BuildProductSearchQuery, BuildPayoutFilterQuery, EcommercePayoutsColumns } from "./EcommercePayouts.functions"

//components
import Loader from "../../../components/Loader/Loader"
import EcommercePayoutsDetailModal from "./EcommercePayoutsDetailModal"

const EcommercePayoutsTable = props => {
	const match = useRouteMatch()
	const [UpdateProductMutation] = useMutation(UpdateProduct)
	const [modal, setModal] = useState(false)
	const history = useHistory()
	const [payouts, setPayouts] = useState([])
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({})
	const [queryConditionCurrentPayout, setQueryConditionCurrentPayout] = useState({})
	const [filterValue, setFilterValue] = useState("")
	const [searchKey, setSearchKey] = useState("")
	const [currentPayoutCycles, setCurrentPayoutCycles] = useState([])
	const [btnLoading, setBtnLoading] = useState(false)
	const [openPayoutDetailModal, setPayoutDetailModal] = useState(false)
	const [selectedPayout, setSelectedPayout] = useState({})

	const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${value.toFixed(2)}`
		} else {
			return "N/A"
		}
	}

	const [getPayouts, { loading, data, error, refetch }] = useLazyQuery(GetPayoutList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const [getCurrentPayout, { loading: loadingCP, data: dataCP, errorCP, refetch: refetchCP }] = useLazyQuery(GetCurrentPayoutCycle, {
		skipCache: true,
		variables: {
			merchantId: match?.params?.id
		}
	})
	const handleSearch = (value) => {
		const conditionTemp = BuildProductSearchQuery(value, props.queryCondition)
		setQueryCondition(conditionTemp)
	}
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}
	const toggle = () => {
		setModal(!modal)
	}
	useEffect(() => {
		if (data) {
			setPayouts(FormatData(data))
		}
	}, [data])
	const defaultSorted = []
	const count = data?.payouts_aggregate?.aggregate?.count || 0
	const maxPage = Math.ceil(count / PageData.limit)

	const handleTableChange = (type, { page, searchText }) => {

	}
	const handleSendPayouts = async(payout) => {
		try {
			setBtnLoading(true)
			const token = localStorage.getItem("TOKEN")
			const resp = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}payment/payouts/transfer_csfree`, {
				store_id: payout.store_id,
				amount: payout.total_amount,
				payout_id: payout.id
			}, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			})
			if (resp.data?.status === "ACCEPTED") {
				refetch()
				refetchCP()
				Swal.fire("Payout Initiated Successfully!", "", "success")
			} else {
				let message = "Something Went Wrong!"
				if(resp.data?.message){
					message = resp.data?.message
				}
				Swal.fire(message, "", "warning")
			}
		} catch (err) {
 			Swal.fire("Something Went Wrong!", "", "warning")
		} finally{
			setBtnLoading(false)
		}
	}

	//Apply filters
	useEffect(() => {
		const conditionTemp = BuildPayoutFilterQuery(filterValue, props.queryCondition)
		setQueryCondition(conditionTemp)
		setQueryConditionCurrentPayout({ store_id: filterValue.store })
	}, [filterValue])
	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			getPayouts()
		}
	}, [props.queryCondition])
	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getPayouts()
		}
	}, [queryCondition])
	useEffect(() => {
		if (Object.keys(queryConditionCurrentPayout).length) {
			getCurrentPayout()
		}
	}, [queryConditionCurrentPayout])
	useEffect(() => {
    if(searchKey.length > 0){
      handleSearch(searchKey)
    }else{
      refetch()
			refetchCP()
    }
  }, [searchKey])
	useEffect(() => {
		if (Array.isArray(dataCP?.payouts)) {
			setCurrentPayoutCycles(dataCP?.payouts)
		}
	}, [dataCP])
	var node = useRef()

	return (
		<>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<ToolkitProvider
								keyField="id"
								data={payouts || []}
								columns={EcommercePayoutsColumns(searchKey, filterValue, (payout)=>{
									setSelectedPayout(payout)
									setPayoutDetailModal(true)
								})}
								bootstrap4
								search
							>
								{toolkitProps => (
									<React.Fragment>
										<Row className="mb-2">
											<Col sm="4">
												<div className="search-box ms-2 mb-2 d-inline-block">
													<div className="position-relative">
														<Form onSubmit={(e) => {
                              e.preventDefault()
                            }}>
															<Form.Group className="mb-3" controlId="formBasicEmail">
																<i className="bx bx-search-alt search-icon" />
																<Form.Control type="text" placeholder="Search Payouts" onChange={(e) => setSearchKey(e.target.value)} />
															</Form.Group>
														</Form>

													</div>
												</div>
											</Col>
											<Col sm="8">
												<div className="text-sm-end d-flex justify-content-end">
													<EcommercePayoutsFilter
														onFilterChange={setFilterValue}
														activeFilters={props.activeFilters}
														filterQueryConditions={props.filterQueryConditions}
													/>
												</div>
											</Col>
										</Row>
										<Row>
											{currentPayoutCycles.length > 0 && (
												<>
													{currentPayoutCycles.map((payout, i)=>(
														<Col xl="12" className="mb-3" key={i}>
														<Panel shaded bordered bodyFill>
															{/* <img src="https://via.placeholder.com/240x240" height="240" /> */}
															<Panel header={`Payout Cycle (${moment(payout.start_date).format("DD/MM/YYYY")} to ${moment(payout.end_date).format("DD/MM/YYYY")})`}>
																<p>
																	
																	<b>Store: </b>
																	<Link to={`/ecommerce-shops/details/${payout.store?.id}`}>{payout.store?.name}</Link>
																</p>
																<p>
																	<b>No. of orders: </b>{payout.orders_aggregate?.aggregate?.count}
																</p>
																<p>
																	<b>Payout for current cycle: </b>{formatPrice(payout.total_amount)}
																</p>
																<p>
																	{payout.total_amount > 0 && (
																		<>
																		<Button 
																			color="success" 
																			onClick={() => handleSendPayouts(payout)}
																			disabled={btnLoading}
																		>
																			Send Payouts
																		</Button>
																		<Button 
																			color="info" 
																			onClick={() => {
																				setSelectedPayout(payout)
																				setPayoutDetailModal(true)
																			}}
																			style={{marginLeft: 10}}
																		>
																			View Details
																		</Button>
																		</>
																	)}
																</p>
															</Panel>
														</Panel>
													</Col>
													))}
												</>
											)}
											<Col xl="12">
												<b>{props.origin === "dashboard" ? "No. of Active Payouts:" : "No. of Past Payouts:"}</b> {count}
												<div className="mb-3" />
												<div className="table-responsive">
													{loading ? <Loader /> :
														<BootstrapTable
															responsive
															bordered={false}
															striped={false}
															defaultSorted={defaultSorted}
															classes={"table align-middle table-nowrap"}
															keyField="id"
															{...toolkitProps.baseProps}
															onTableChange={handleTableChange}
															ref={node}
															noDataIndication={() => "No Payouts Found!"}
															key={`${searchKey}_${JSON.stringify(filterValue)}`}
														/>
													}
												</div>
											</Col>
										</Row>
										<Row className="align-items-md-center mt-40">
											<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
												<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
													Previous Page
												</Button>
												<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
												</div>
												<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
													Next Page
												</Button>
											</Col>
										</Row>
									</React.Fragment>
								)}
							</ToolkitProvider>
						</CardBody>
					</Card>
				</Col>
			</Row>
			<EcommercePayoutsDetailModal 
				open={openPayoutDetailModal} 
				close={()=>setPayoutDetailModal(false)}
				payout={selectedPayout}
			/>
		</>
	)
}

EcommercePayoutsTable.propTypes = {
	queryCondition: PropTypes.any,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any,
	origin: PropTypes.string
}

export default withRouter(EcommercePayoutsTable)
