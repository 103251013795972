import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker } from 'rsuite'
import { Dropdown } from "react-bootstrap"
import { useState } from "react"
import PropTypes from "prop-types"
import { ProductStoreFilter, CustomerCityFilter, ProductCategoryFilter, ProductSubCategoryFilter, LandmarkStoreFilter, ServiceFilter, ServiceCategoryFilter } from "../../../gql/query"
import {
	AddOrderFormDataQuery,
} from "../../../pages/Ecommerce/EcommerceOrders/EcommerceOrders.query"

import { useQuery } from "@apollo/client"
//load components
import CheckPickerPaginate from "../CheckPickerPaginate"

const EcommerceServicesFilter = (props) => {
 	const [filterData, setFilterData] = useState([])
	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})
	const amountRangeData = [{ label: "< ₹200", value: "<200" }, { label: "₹200 - ₹500", value: "200-500" }, { label: "₹500 - ₹750", value: "500-750" }, { label: "₹750 - ₹1000", value: "750-1000" }, { label: "> ₹1000", value: ">1000" }]
	const deliveryChargesData = [{ label: "< ₹25", value: "<25" }, { label: "₹25 - ₹50", value: "25-50" }, { label: "₹50 - ₹75", value: "50-75" }, { label: "> ₹75", value: "75" }]
	const orderItemsData = [{ label: "< 5", value: "<5" }, { label: "1 - 5", value: "1-5" }, { label: "5 - 10", value: "5-10" }, { label: "10 - 15", value: "10-15" }, { label: "15 - 20", value: "15-20" }, { label: "> 20", value: ">20" }]
	const [statusData, setStatusData] = useState([])

	const { data:statuses, loading, error } = useQuery(AddOrderFormDataQuery, {
		skipCache: true
	})

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
	}
	const getFilterCondition = (filterKey) => {
		if (props.filterQueryConditions !== null) {
			let condition = props.filterQueryConditions.filter(condition => condition.filter === filterKey)
			if (condition.length) {
				return condition[0]?.condition
			}
		} else {
			return null
		}
	}
	useEffect(() => {
		props.onFilterChange(filterValues)
	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			let filterValuesTemp = { ...filterValues }
			if (!filters.includes("landmark")) {
				delete filterValuesTemp["landmark"]
			}
			if (!filters.includes("service")) {
				delete filterValuesTemp["service"]
			}
			if (!filters.includes("service_category")) {
				delete filterValuesTemp["service_category"]
			}
			if (!filters.includes("service_name")) {
				delete filterValuesTemp["service_name"]
			}
			if (!filters.includes("created_by")) {
				delete filterValuesTemp["created_by"]
			}
			if (!filters.includes("email")) {
				delete filterValuesTemp["email"]
			}
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])
	useEffect(() => {
		let filterDataTemp = []
		props.activeFilters.map((filter) => {
			if (filter === "landmark") {
				filterDataTemp.push({ label: "Landmark", value: "landmark" })
			}
			if (filter === "service") {
				filterDataTemp.push({ label: "Service", value: "service" })
			}
			if (filter === "service_category") {
				filterDataTemp.push({ label: "Service Category", value: "service_category" })
			}
			// if (filter === "service_name") {
			// 	filterDataTemp.push({ label: "Service Name", value: "service_name" })
			// }
			// if (filter === "created_by") {
			// 	filterDataTemp.push({ label: "Created By", value: "created_by" })
			// }
			// if (filter === "email") {
			// 	filterDataTemp.push({ label: "Email", value: "email" })
			// }
 
		})
		setFilterData(filterDataTemp)
	}, [props.activeFilters])
	useEffect(()=>{
		if(Array.isArray(statuses?.enum_order_status)){
			let statusTemp = []
			statuses.enum_order_status.map((status)=>{
				statusTemp.push({
					label: status.comment,
					value: status.value,
					type: ["acknowledged", "submitted", "cancelled", "rejected"].includes(status.value) ? "EEfind" : "Dunzo"
				})
			})
			setStatusData(statusTemp)
		}
	}, [statuses])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "created_at") {
					return (
						<React.Fragment key={i}>
							<DateRangePicker
								placement="auto"
								onChange={(created_at) => setFilterValues({ ...filterValues, created_at })}
								style={{ marginLeft: 10 }}
								placeholder="Select Created At Date"
								value={Array.isArray(filterValues.created_at) ? filterValues.created_at : []}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_at")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "landmark") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={LandmarkStoreFilter}
								placeholder="Select LandMark"
								value={Array.isArray(filterValues.landmark) ? filterValues.landmark : []}
								onChange={(landmark) => setFilterValues({ ...filterValues, landmark })}
								arrKey="landmark"
								objectLabel="landmark"
								objectValue="landmark"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("landmark")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("store")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				// if (filter === "service") {
				// 	return (
				// 		<React.Fragment key={i}>
				// 			<CheckPicker
				// 				data={statusData}
				// 				placeholder={"Select Service"}
				// 				style={{ marginLeft: 10 }}
				// 				value={Array.isArray(filterValues.status) ? filterValues.status : []}
				// 				onChange={(status) => setFilterValues({ ...filterValues, status })}
				// 				searchable={false}
				// 				groupBy="type"
				// 			/>
				// 			<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("status")}>
				// 				<i className="bx bx-x-circle" />
				// 			</div>
				// 		</React.Fragment>
				// 	)
				// }
				if (filter === "service") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ServiceFilter}
								placeholder="Select Service"
								value={Array.isArray(filterValues.service) ? filterValues.service : []}
								onChange={(service) => setFilterValues({ ...filterValues, service })}
								arrKey="service"
								objectLabel="service_name"
								objectValue="service_name"
								nestedObj="service"
								nested={true}

								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={null}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("city")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				 if (filter === "service_category") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ServiceCategoryFilter}
								placeholder="Select Category"
								value={Array.isArray(filterValues.service_category) ? filterValues.service_category : []}
								onChange={(service_category) => setFilterValues({ ...filterValues, service_category })}
								arrKey="category"
								objectLabel="category"
								objectValue="category"
								nestedObj="service_category"
								nested={true}

								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={null}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("city")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}

				if (filter === "ss") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={ServiceCategoryFilter}
								placeholder={"Select amount ranges"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.total_amount) ? filterValues.total_amount : []}
								onChange={(total_amount) => setFilterValues({ ...filterValues, total_amount })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("total_amount")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "delivery_charges") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={deliveryChargesData}
								placeholder={"Select delivery charge ranges"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.delivery_charges) ? filterValues.delivery_charges : []}
								onChange={(delivery_charges) => setFilterValues({ ...filterValues, delivery_charges })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("delivery_charges")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "order_items") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={orderItemsData}
								placeholder={"No. of Ordered Items"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.order_items) ? filterValues.order_items : []}
								onChange={(order_items) => setFilterValues({ ...filterValues, order_items })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("order_items")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "category") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ProductCategoryFilter}
								placeholder="Select Category"
								value={Array.isArray(filterValues.category) ? filterValues.category : []}
								onChange={(category) => setFilterValues({ ...filterValues, category })}
								arrKey="categories"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("category")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("category")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "subcategory") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ProductSubCategoryFilter}
								placeholder="Select Sub Category"
								value={Array.isArray(filterValues.subcategory) ? filterValues.subcategory : []}
								onChange={(subcategory) => setFilterValues({ ...filterValues, subcategory })}
								arrKey="subcategories"
								objectLabel="name"
								objectValue="id"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("subcategory")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("subcategory")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
EcommerceServicesFilter.propTypes = {
	onFilterChange: PropTypes.func,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any
}
export default EcommerceServicesFilter