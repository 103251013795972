import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker } from 'rsuite'
import { Dropdown } from "react-bootstrap"
import { useState } from "react"
import PropTypes from "prop-types"
import { ProductStoreFilter, CustomerCityFilter, ProductCategoryFilter, ProductSubCategoryFilter, LandmarkStoreFilter, ServiceFilter, ServiceCategoryFilter, StateCommisionFilter, BusinessCommissionFilter } from "../../../gql/query"
import {
	AddOrderFormDataQuery,
} from "../../../pages/Ecommerce/EcommerceOrders/EcommerceOrders.query"

import { useQuery } from "@apollo/client"
//load components
import CheckPickerPaginate from "../CheckPickerPaginate"

const EcommerceCommissionFilter = (props) => {
	const [filterData, setFilterData] = useState([])
	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})
	const amountRangeData = [{ label: "< ₹200", value: "<200" }, { label: "₹200 - ₹500", value: "200-500" }, { label: "₹500 - ₹750", value: "500-750" }, { label: "₹750 - ₹1000", value: "750-1000" }, { label: "> ₹1000", value: ">1000" }]
	const deliveryChargesData = [{ label: "< ₹25", value: "<25" }, { label: "₹25 - ₹50", value: "25-50" }, { label: "₹50 - ₹75", value: "50-75" }, { label: "> ₹75", value: "75" }]
	const orderItemsData = [{ label: "< 5", value: "<5" }, { label: "1 - 5", value: "1-5" }, { label: "5 - 10", value: "5-10" }, { label: "10 - 15", value: "10-15" }, { label: "15 - 20", value: "15-20" }, { label: "> 20", value: ">20" }]
	const [statusData, setStatusData] = useState([])

	const { data:statuses, loading, error } = useQuery(AddOrderFormDataQuery, {
		skipCache: true
	})

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
	}
	const getFilterCondition = (filterKey) => {
 		if (props.filterQueryConditions !== null) {
			let condition = props.filterQueryConditions.filter(condition => condition.filter === filterKey)
			if (condition.length) {
				return condition[0]?.condition
			}
		} else {
			return null
		}
	}
	useEffect(() => {
		props.onFilterChange(filterValues)
	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			let filterValuesTemp = { ...filterValues }
			if (!filters.includes("enum_services_business_type")) {
				delete filterValuesTemp["enum_services_business_type"]
			}
			if (!filters.includes("enum_state")) {
				delete filterValuesTemp["enum_state"]
			}
	 
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])
	useEffect(() => {
		let filterDataTemp = []
		props.activeFilters.map((filter) => {
			if (filter === "enum_services_business_type") {
				filterDataTemp.push({ label: "Business Type", value: "enum_services_business_type" })
			}
			if (filter === "enum_state") {
				filterDataTemp.push({ label: "State", value: "enum_state" })
			}
		 
 
 
		})
		setFilterData(filterDataTemp)
	}, [props.activeFilters])
	useEffect(()=>{
		if(Array.isArray(statuses?.enum_order_status)){
			let statusTemp = []
			statuses.enum_order_status.map((status)=>{
				statusTemp.push({
					label: status.comment,
					value: status.value,
					type: ["acknowledged", "submitted", "cancelled", "rejected"].includes(status.value) ? "EEfind" : "Dunzo"
				})
			})
			setStatusData(statusTemp)
		}
	}, [statuses])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "enum_state") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={StateCommisionFilter}
								placeholder="Select state"
								value={Array.isArray(filterValues.enum_state) ? filterValues.enum_state : []}
								onChange={(enum_state) => setFilterValues({ ...filterValues, enum_state })}
								arrKey="state"
								objectLabel="comment"
								objectValue="comment"
								style={{ marginLeft: 10 }}
								nestedObj="enum_state"
								nested={true}
								paginate={true}
								filterCondition={getFilterCondition("enum_state")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("enum_state")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "enum_services_business_type") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={BusinessCommissionFilter}
								placeholder="Select Business"
								value={Array.isArray(filterValues.enum_services_business_type) ? filterValues.enum_services_business_type : []}
								onChange={(enum_services_business_type) => setFilterValues({ ...filterValues, enum_services_business_type })}
								arrKey="enum_services_business_type"
								objectLabel="label"
								objectValue="label"
								style={{ marginLeft: 10 }}
								nestedObj="enum_services_business_type"
								nested={true}
								paginate={true}
								filterCondition={getFilterCondition("enum_services_business_type")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("enum_services_business_type")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
EcommerceCommissionFilter.propTypes = {
	onFilterChange: PropTypes.func,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any
}
export default EcommerceCommissionFilter