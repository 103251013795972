import React from "react"
import Highlighter from "react-highlight-words"
import * as moment from "moment"
import { Link } from "react-router-dom"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'

export const FormatData = (data) => {
  const admins = data.admins.map(elem => {
    return {
      id: elem.id,
      name: elem.name,
      email: elem.email,
      phone : elem.phone || "N/A",
      created_at: elem.created_at,
      photo_url: elem.photo_url,
      active: elem.active,
      role: elem.additional_role,
    }
  })
  return admins
}
const formatHighlighter = (text, searchKey, filterValue) => {
  let searchWords = [searchKey]
  if (Array.isArray(filterValue?.role)) {
    searchWords = [...searchWords, ...filterValue?.role]
  }
  return <Highlighter
    highlightClassName="YourHighlightClass"
    searchWords={searchWords}
    autoEscape={true}
    textToHighlight={String(text)}
    highlightStyle={{ background: "#ff0" }}
  />
}
export const EcommerceSystemUsersColumns = (searchKey, filterValue, history, handleChangeStatus, onEditClick) => [
  {
    text: "id",
    dataField: "id",
    sort: true,
    hidden: true
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
    formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue)
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue)
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
    formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue)
  },
  {
    dataField: "role",
    text: "Role",
    sort: true,
    formatter: (cellContent, row) => formatHighlighter(cellContent, searchKey, filterValue)
  },
  {
    text: "Active",
    dataField: "active",
    sort: false,
    formatter: (cellContent, row) => <p style={{ color: row.active ? "" : "#F93B55" }}>{row.active ? "Yes" : "No"}</p>,
  },
  {
    text: "Created At",
    dataField: "created_at",
    sort: false,
    formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
  },
  {
    dataField: "view",
    isDummyField: true,
    text: "Action",
    sort: false,
    // eslint-disable-next-line react/display-name
    formatter: (cellContent, row) => (
      <Dropdown>
        <Dropdown.Toggle variant="transparent" id="dropdown-basic">
          <GoKebabVertical />
        </Dropdown.Toggle>
        <Dropdown.Menu>
        <Dropdown.Item onClick={() => onEditClick(row)}>
          Edit
        </Dropdown.Item>
          {row.active === true ? (
            <Dropdown.Item onClick={() => handleChangeStatus(row.id, false)}>
              Deactivate
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => handleChangeStatus(row.id, true)}>
              Activate
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
]
export const BuildSystemUserSearchQuery = (value, queryCondition) => {
  if (value.length >= 3) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    conditionTemp = {
      ...conditionTemp,
      _or: [
        { name: { _ilike: `%${value}%` } },
        { email: { _ilike: `%${value}%` } },
        { phone: { _ilike: `%${value}%` } },
      ]
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}
export const BuildSystemUserFilterQuery = (filterValue, queryCondition) => {
  if (Object.keys(filterValue).length) {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    if (filterValue?.hasOwnProperty("active")) {
       conditionTemp = {
        ...conditionTemp,
        active: { _eq: filterValue.active === "yes" ? true : false }
      }
    }
    if (filterValue?.hasOwnProperty("created_at")) {
      if (Array.isArray(filterValue.created_at)) {
        let created_at_from = moment(filterValue.created_at[0]).format("YYYY-MM-DD")
        let created_at_till = moment(filterValue.created_at[1]).format("YYYY-MM-DD")
        conditionTemp = {
          ...conditionTemp,
          _and: [{ created_at: { _gte: created_at_from } }, { created_at: { _lte: created_at_till } }]
        }
      }
    }
    if (filterValue?.hasOwnProperty("role")) {
      if (filterValue.role?.length > 0) {
        conditionTemp = {
          ...conditionTemp,
          additional_role: { _in: filterValue.role }
        }
      }
    }
    return conditionTemp
  } else {
    let conditionTemp = {}
    if (queryCondition !== null) {
      conditionTemp = { ...queryCondition }
    }
    return conditionTemp
  }
}