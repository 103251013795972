import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Col,
	Container,
	Row,
} from "reactstrap"
import { FiEdit, FiXCircle } from "react-icons/fi"
import { useRouteMatch, Link } from "react-router-dom"
import moment from "moment"
import { Badge } from "react-bootstrap"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Swal from "sweetalert2"
import { SelectPicker, Panel } from "rsuite"
//Import components
import MerchantCreateNote from "./MerchantCreateNote"
import Loader from "../../../components/Loader/Loader"
import MerchantBusinessDetails from "./MerchantBusinessDetails"
import MerchantPushNotificationModal from "./MerchantPushNotificationModal"

//GraphQL
import { MerchantBankList, MerchantDetails, MerchantStats } from "gql/query"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import {
	UpdateVendorCompany
} from "../EcommerceMerchants/EcommerceMerchants.query"
//helpers
import isValidUrl from "helpers/url_checker"

import { useSelector } from "react-redux"

const MerchantOverview = (props) => {
	const { capabilities } = useSelector((state) => state.Login)
	const FormatData = (data) => {
		const bank_accounts = data.bank_accounts.map(elem => {
			let store_names = []
			if (elem.store_bank_accounts?.length > 0) {
				elem.store_bank_accounts.map((store) => {
					store_names.push(store?.store?.name)
				})
			}
			store_names = store_names.join(", ")
			return {
				id: elem.id,
				account_no: elem.account_no,
				account_type: elem.account_type,
				ben_name: elem.beneficiary_name || "N/A",
				ben_address: elem.beneficiary_address || "N/A",
				ifsc: elem.ifsc,
				branch: elem.branch_name,
				store_name: store_names,
				created_at: elem.created_at
			}
		})
		return bank_accounts
	}
	const BankDetailsColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			dataField: "account_no",
			text: "Account No.",
			sort: false,
		},
		{
			dataField: "account_type",
			text: "Account Type",
			sort: true,
		},
		{
			dataField: "ben_name",
			text: "Benificiary Name",
			sort: true,
		},
		{
			text: "Benificiary Address",
			dataField: "ben_address",
			sort: true,
		},
		{
			dataField: "ifsc",
			text: "IFSC",
			sort: true,
		},
		{
			text: "Branch",
			dataField: "branch",
			sort: true,
		},
		{
			text: "Store",
			dataField: "store_name",
			sort: false,
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		}
	]
	const match = useRouteMatch()
	const defaultSorted = []
	const [bankAcounts, setBankAccounts] = useState([])
	const [merchant, setMerchant] = useState({})
	const [queryCondition, setQueryCondition] = useState({ created_by: { _eq: match?.params?.id } })
	const [queryConditionStats, setQueryConditionStats] = useState({})
	const [isPhysicalVerificationEditOn, setPhysicalVerificationEditOn] = useState(false)
	const [isCallVerificationEditOn, setCallVerificationEditOn] = useState(false)
	const [noteModalOpen, setNoteModalOpen] = useState(false)
	const [noteModalTitle, setNoteModalTitle] = useState("")
	const [pushNotificationModalOpen, setPushNotificationModalOpen] = useState(false)

	const verificationStatusOptions = [{ label: "Pending", value: "pending" }, { label: "Verified", value: "verified" }, { label: "Rejected", value: "rejected" }]

	const { data, loading, error } = useQuery(MerchantBankList, {
		skipCache: true,
		variables: {
			where: queryCondition
		}
	})
	const { data: merchantDetails, loading: merchantLoading, error: merchantError, refetch: RefetchMerchant } = useQuery(MerchantDetails, {
		skipCache: true,
		variables: {
			where: { id: { _eq: match?.params?.id } }
		}
	})
	const [getStats, { loading: loadingStats, data: stats }] = useLazyQuery(MerchantStats, {
		skipCache: true,
		variables: {
			where: queryConditionStats
		}
	})
	const [UpdateVendorCompanyMutation] = useMutation(UpdateVendorCompany)
	const getAddress = () => {
		let address = ""
		if (merchant.vendor_company) {
			if (merchant.vendor_company?.address_line1) {
				address += merchant.vendor_company.address_line1
			}
			// if(merchant.vendor_company?.address_line2){
			// 	address += merchant.vendor_company.address_line2
			// }
			// if(merchant.vendor_company?.city){
			// 	address += merchant.vendor_company.city
			// }
		}
		return address
	}
	const getGSTNo = () => {
		let gstArr = []
		if (merchant.stores?.length > 0) {
			merchant.stores.map((store) => {
				if (store.business_gstno) {
					gstArr.push(<li>{store.business_gstno} (<Link to={`/ecommerce-shops/details/${store.id}`}>{store.name}</Link>)</li>)
				}
			})
		}
		if (gstArr.length) {
			return gstArr
		} else {
			return null
		}
	}
	const getPanNo = () => {
		let panNo = "N/A"
		if (merchant.stores?.length > 0) {
			panNo = merchant.stores[0]?.business_pan
		}
		return panNo
	}
	function IsJsonString(str) {
		try {
			JSON.parse(str)
		} catch (e) {
			return false
		}
		return true
	}
	const getKYCImages = () => {
		let jsx = []
		let images = []
		if (merchant.aadhar_image) {
			let aadhar_image = "/no_image.png"
			if (isValidUrl(merchant.aadhar_image)) {
				aadhar_image = merchant.aadhar_image
				images.push({ label: "AADHAR", image: aadhar_image })
			} else if (IsJsonString(merchant.aadhar_image)) {
				let imageArr = JSON.parse(merchant.aadhar_image)
				if (Array.isArray(imageArr)) {
					imageArr.map((image, i) => {
						if (isValidUrl(image)) {
							images.push({ label: `AADHAR ${i === 0 ? "FRONT" : "BACK"}`, image: image })
						} else {
							images.push({ label: `AADHAR ${i === 0 ? "FRONT" : "BACK"}`, image: aadhar_image })
						}
					})
				}
			}
		}
		if (merchant.stores?.length > 0) {
			if (merchant.stores[0].pan_photo?.length > 0 && typeof merchant.stores[0].pan_photo === "string") {
				if (isValidUrl(merchant.stores[0].pan_photo)) {
					images.push({ label: "PAN", image: merchant.stores[0].pan_photo })
				} else {
					images.push({ label: "PAN", image: "/no_image.png" })
				}
			}
			merchant.stores.map((store) => {
				if (store.gst_photo?.length > 0 && typeof store.gst_photo === "string") {
					if (isValidUrl(store.gst_photo)) {
						images.push({ label: "GST", image: store.gst_photo, store: store.name, store_id: store.id })
					} else {
						images.push({ label: "GST", image: "/no_image.png", store: store.name, store_id: store.id })
					}
				}
				if (!store.gst_available) {
					if (store.nogst_declaration?.length > 0 && typeof store.nogst_declaration === "string") {
						if (isValidUrl(store.nogst_declaration)) {
							images.push({ label: "NO-GST DECLARATION", image: store.nogst_declaration, store: store.name, store_id: store.id })
						} else {
							images.push({ label: "NO-GST DECLARATION", image: "/no_image.png", store: store.name, store_id: store.id })
						}
					}
					if (store.proof_of_business?.length > 0 && typeof store.proof_of_business === "string") {
						if (isValidUrl(store.proof_of_business)) {
							images.push({ label: "PROOF OF BUSINESS", image: store.proof_of_business, store: store.name, store_id: store.id })
						} else {
							images.push({ label: "PROOF OF BUSINESS", image: "/no_image.png", store: store.name, store_id: store.id })
						}
					}
				}
			})
		}
		if (images.length) {
			images.map((item) => {
				if (item?.image) {
					jsx.push(
						<Panel shaded bordered bodyFill className="kyc-cards">
							<img src={item.image} className="kyc-card-img" />
							<Panel header={item.label} >
								{item.store && (
									<Link to={`/ecommerce-shops/details/${item.store_id}`}>{item.store}</Link>
								)}
							</Panel>
						</Panel>
					)
				}
			})
			return jsx
		} else {
			return (
				<p>No KYC Details found!</p>
			)
		}
	}
	const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${value.toFixed(2)}`
		} else {
			return "N/A"
		}
	}
	const handleChangeStatus = async () => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					let status = (merchant.vendor_company?.verification_status === "verified" ? "rejected" : "verified")
					await UpdateVendorCompanyMutation({
						variables: {
							id: merchant.id,
							set: { verification_status: status }
						},
					})
					RefetchMerchant()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	const getBusinessType = () => {
		let businessType = ""
		if (merchant.stores?.length > 0) {
			let gst_available = merchant.stores.filter((store) => store.gst_available).length > 0 ? true : false
			businessType = gst_available ? "GST" : "Non-GST"
		}
		return businessType
	}
	const getCategories = () => {
		let categories = "N/A"
		if (merchant.stores?.length > 0) {
			let categoriesArr = []
			merchant.stores.map(store => {
				if (Array.isArray(store.categories)) {
					categoriesArr = [...categoriesArr, ...store.categories]
				}
			})
			let uniqueCategories = [...new Set(categoriesArr)]
			categories = uniqueCategories.join(", ")
		}
		return categories
	}
	const onChangeVerificationStatus = async (type, value) => {
		await UpdateVendorCompanyMutation({
			variables: {
				id: merchant.id,
				set: { [type === "physical" ? "physical_verification" : "call_verification"]: value }
			},
		})
		if (type === "physical") {
			setPhysicalVerificationEditOn(false)
		} else {
			setCallVerificationEditOn(false)
		}
		if (value === "rejected") {
			setNoteModalOpen(true)
			setNoteModalTitle(type === "physical" ? "Physical Verification" : "Call Verification")
		}
		RefetchMerchant()
	}
	useEffect(() => {
		if (data) {
			setBankAccounts(FormatData(data))
		}
	}, [data])
	useEffect(() => {
		if (merchantDetails?.users?.length > 0) {
			setMerchant(merchantDetails.users[0])
			props.setStores(merchantDetails.users[0].stores)
		}
	}, [merchantDetails])
	useEffect(() => {
		if (merchant?.stores?.length) {
			let storeIDs = merchant.stores.map(store => store.id)
			setQueryConditionStats({ store_id: { _in: storeIDs } })
		}
	}, [merchant])
	useEffect(() => {
		if (queryConditionStats.store_id) {
			getStats()
		}
	}, [queryConditionStats])
	// console.log(merchant, stats)
	return (
		<Container>
			<Row>
				<Col sm="4">
					<img src={merchant.photo_url?.length > 0 ? merchant.photo_url : "/avatar.jpg"} className="w-100" />
				</Col>
				<Col sm="8" className="pl-3">
					<div>
						<h3>{merchant.name} {merchant.vendor_company?.verification_status?.length > 0 && (
							<Badge bg={merchant.vendor_company.verification_status === "pending" ? "warning" : merchant.vendor_company.verification_status === "verified" ? "success" : "danger"} style={{ textTransform: "capitalize" }}>{merchant.vendor_company.verification_status}</Badge>
						)}</h3>
						<h6><a href={`mailto:${merchant.email}`}>{merchant.email}</a></h6>
						<h6><a href={`tel:${merchant.phone}`}>{merchant.phone}</a></h6>
					</div>
					<Row>
						<Col xl="6" className="d-flex justify-content-start">
							<Button
								type="button"
								color={"primary"}
								className="btn btn-md  my-2 me-2"
								onClick={() => setPushNotificationModalOpen(true)}
							>
								Send Push Notification
							</Button>
						</Col>
						{/* <Col xl="6" className="d-flex justify-content-start">
						<Button
							type="button"
							color={"primary"}
							className="btn-rounded btn-lg  mb-2 me-2"
							onClick={handleChangeStatus}
						>
							Send Email
						</Button>
					</Col> */}
					</Row>
					<hr />
					<Row>
						<div className="stats-wrapper">
							<b>Last Order</b>
							{stats?.orders?.length > 0 ? (
								<p>{moment(stats?.orders[0]?.created_at).fromNow()}</p>
							) : (
								<p>No orders found!</p>
							)}
						</div>
						<div className="stats-wrapper">
							<b>Average Order Details</b>
							<p>{loadingStats ? "" : formatPrice(stats?.orders_aggregate?.aggregate?.avg?.total_amount)}</p>
						</div>
						{/* <div className="stats-wrapper">
							<b>Lifetime Order Value</b>
							<p>{loadingStats ? "" : formatPrice(stats?.orders_aggregate?.aggregate?.sum?.total_amount)}</p>
						</div> */}
						{/* <div className="stats-wrapper">
							<b>Acquisition Channel</b>
							<p>Online</p>
						</div> */}
						<div className="stats-wrapper">
							<b>Referral Code</b>
							<p>{merchant.reference_code || "N/A"}</p>
						</div>
						<div className="stats-wrapper">
							<b>Lifetime Order Count</b>
							<p>{loadingStats ? "" : stats?.orders_aggregate?.aggregate?.count}</p>
						</div>
						<div className="stats-wrapper">
							<b>No. of days inactive</b>
							<p>0</p>
						</div>
						<div className="stats-wrapper">
							<b>Seller Level</b>
							<p>1</p>
						</div>
					</Row>
					<h4 className="mt-2">Business Details</h4>
					<hr />
					<Row>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Merchant ID: </b> {merchant?.vendor_company?.merchant_id || "N/A"}
							</div>
							<div className="mb-2">
								<b>PAN: </b> {getPanNo()}
							</div>
							<div className="mb-2">
								<b>AADHAAR: </b> {merchant?.aadhar_no || "N/A"}
							</div>
							{/* <div className="mb-2">
								<b className="mb-5">GST: </b>
								{getGSTNo() !== null ? (
								<ol style={{padding: "0 15px"}}>
									{getGSTNo()}
								</ol>
								) : "N/A"}
							</div>
							<div className="mb-2">
								<b>Business name {getBusinessType() === "GST" ? "as per GST:" : ":"}  </b> {merchant?.vendor_company?.name || "N/A"}
							</div>
							<div className="mb-2">
								<b>Address {getBusinessType() === "GST" ? "as per GST:" : ":"} </b> {getAddress()}
							</div> */}
						</Col>
						<Col sm="6">
							{/* <div className="mb-2">
								<b>GST: </b> {getGSTNo()}
							</div> */}
							<div className="mb-2">
								<b>Referral name: </b> N/A
							</div>
							{/* <div className="mb-2">
								<b>Website: </b> {merchant?.vendor_company?.website_url || "N/A"}
							</div> */}
							<div className="mb-2">
								<b>Categories: </b> {getCategories()}
							</div>
							<div className="mb-2">
								<b>Self Delivery: </b> No
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm="12" style={{ padding: 0 }}>
							<MerchantBusinessDetails
								stores={merchant.stores || []}
							/>
						</Col>
					</Row>
					<h4 className="mt-2">Consent &amp; Verification</h4>
					<hr />
					<Row>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Consent Taken: </b> {getBusinessType() === "GST" ? "No" : "Yes"}
							</div>
							<div className="mb-2">
								<b className="mb-5">Communication Consent: </b> <br />
								Whatsapp: Yes <br />
								Email: Yes <br />
								SMS: Yes
							</div>
							<div className="mb-2">
								<b className="mb-5">Terms &amp; Conditions Consent: </b> Yes
							</div>
						</Col>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Physical Verification: </b>
								{!isPhysicalVerificationEditOn ?
									<Badge bg={merchant?.vendor_company?.physical_verification === "pending" ? "warning" : merchant?.vendor_company?.physical_verification === "verified" ? "success" : "danger"} style={{ textTransform: "capitalize" }}>{merchant?.vendor_company?.physical_verification || "N/A"}</Badge> : (
										<>
											{capabilities.includes("edit") && (
												<SelectPicker
													data={verificationStatusOptions}
													searchable={false}
													position="auto"
													className="ml-3"
													onChange={(value) => onChangeVerificationStatus("physical", value)}
													value={merchant?.vendor_company?.physical_verification}
												/>
											)}
										</>
									)}
								{capabilities.includes("edit") && (
									<>
										{!isPhysicalVerificationEditOn ? (
											<FiEdit
												onClick={() => setPhysicalVerificationEditOn(true)}
												style={{ marginLeft: 5, cursor: "pointer" }}
											/>
										) : (
											<FiXCircle
												onClick={() => setPhysicalVerificationEditOn(false)}
												style={{ marginLeft: 5, cursor: "pointer" }}
											/>
										)}
									</>
								)}
							</div>
							<div className="mb-2">
								<b className="mb-5">Call Verification: </b>
								{!isCallVerificationEditOn ? <Badge bg={merchant?.vendor_company?.call_verification === "pending" ? "warning" : merchant?.vendor_company?.call_verification === "verified" ? "success" : "danger"} style={{ textTransform: "capitalize" }}>{merchant?.vendor_company?.call_verification}</Badge> : (
									<>
										{capabilities.includes("edit") && (
											<SelectPicker
												data={verificationStatusOptions}
												searchable={false}
												position="auto"
												className="ml-3"
												onChange={(value) => onChangeVerificationStatus("call", value)}
												value={merchant?.vendor_company?.call_verification}
											/>
										)}
									</>
								)}
								{capabilities.includes("edit") && (
									<>
										{!isCallVerificationEditOn ? (
											<FiEdit
												onClick={() => setCallVerificationEditOn(true)}
												style={{ marginLeft: 5, cursor: "pointer" }}
											/>
										) : (
											<FiXCircle
												onClick={() => setCallVerificationEditOn(false)}
												style={{ marginLeft: 5, cursor: "pointer" }}
											/>
										)}
									</>
								)}
							</div>
						</Col>
					</Row>
					<h4 className="mt-2">Social Handles</h4>
					<hr />
					<Row>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Facebook: N/A</b>
								{/* <a href="https://www.facebook.com">https://www.facebook.com</a> */}
							</div>
							<div className="mb-2">
								<b className="mb-5">Instagram: N/A</b>
								{/* <a href="https://www.instagram.com">https://www.instagram.com</a> */}
							</div>
						</Col>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Twitter: N/A</b>
								{/* <a href="https://www.twitter.com">https://www.twitter.com</a> */}
							</div>
							<div className="mb-2">
								<b className="mb-5">LinkedIn: N/A</b>
								{/* <a href="https://www.linkedin.com">https://www.linkedin.com</a> */}
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className="mb-5">
				<h4>Bank Details</h4>
				<hr />
				<ToolkitProvider
					keyField="id"
					data={bankAcounts || []}
					columns={BankDetailsColumns}
					bootstrap4
				// search
				>
					{toolkitProps => (
						<Row>
							<Col xl="12">
								<div className="table-responsive">
									{loading ? <Loader /> :
										<BootstrapTable
											responsive
											bordered={false}
											striped={false}
											defaultSorted={defaultSorted}
											classes={"table align-middle table-nowrap"}
											keyField="id"
											{...toolkitProps.baseProps}
											onTableChange={() => { }}
											noDataIndication={() => "No Bank Details Found!"}
										// {...paginationTableProps}
										/>
									}
								</div>
							</Col>
						</Row>
					)}
				</ToolkitProvider>
			</Row>
			<Row>
				<h4>KYC Details</h4>
				<hr />
				<Row>
					{getKYCImages()}
				</Row>
			</Row>
			{capabilities.includes("edit") && (
				<>
					{merchant?.vendor_company?.physical_verification === "verified" && merchant?.vendor_company?.call_verification === "verified" && (
						<Row>
							<Col xl="12" className="d-flex justify-content-end">
								<Button
									type="button"
									color={merchant.vendor_company?.verification_status === "verified" ? "danger" : "primary"}
									className="btn-rounded btn-lg  mb-2 me-2"
									onClick={handleChangeStatus}
								>
									{merchant.vendor_company?.verification_status === "verified" ? "Deactivate" : "Activate"}
								</Button>
							</Col>
						</Row>
					)}
				</>
			)}
			<MerchantCreateNote
				open={noteModalOpen}
				close={() => setNoteModalOpen(false)}
				onCreate={() => { }}
				merchantId={match?.params?.id}
				note={{}}
				title={noteModalTitle}
			/>
			<MerchantPushNotificationModal
				open={pushNotificationModalOpen}
				close={() => setPushNotificationModalOpen(false)}
				merchant={merchant}
			/>
		</Container>
	)
}
MerchantOverview.propTypes = {
	setStores: PropTypes.func
}
MerchantOverview.displayName = 'MerchantOverview'
export default MerchantOverview