import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardDeck,
  CardImg,
  CardTitle,
} from "reactstrap"
import { Link, useHistory, NavLink } from "react-router-dom"
import classNames from "classnames"
import axios from "axios"
import * as moment from "moment"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import "./dashboard.scss"

//Helpers
import isValidUrl from "../../helpers/url_checker"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { MdLocalGroceryStore } from "react-icons/md"
import { RiHomeGearFill } from "react-icons/ri"
import { AiFillCar } from "react-icons/ai"
import { MdLuggage, MdFastfood } from "react-icons/md"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const reports = [
  {
    title: "Groceries",
    iconClass: <MdLocalGroceryStore size={25} />,
    description: `\u00A0`,
    link: "groceries/dashboard",
  },
  {
    title: "Home Services",
    iconClass: <RiHomeGearFill size={25} />,
    description: `\u00A0`,
    link: "services/dashboard",
  },
  {
    title: "Save on Ride",
    iconClass: <AiFillCar size={25} />,
    description: "\u00A0",
    link: "vehicles/dashboard",
  },
  {
    title: "Property",
    iconClass: <MdFastfood size={25} />,
    description: "\u00A0",
    link: "property/dashboard",
  },
  {
    title: "Experience",
    iconClass: <MdLuggage size={25} />,
    description: "\u00A0",
    link: "experience/dashboard",
  },
]
const Homepage = (props) => {
  const history = useHistory()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Homepage | EEFind </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Homepage")}
            breadcrumbItem={props.t("HomePage")}
          />

          <Row>
            <Col xl="12">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <NavLink to={"/" + report.link} className="">
                      <Card>
                        {/* {console.log(report, "reports")} */}
                        <CardBody>
                          <CardTitle>
                            {report.iconClass} {"   "}
                            {report.title}
                          </CardTitle>
                          <p className="text-muted fw-small">
                            {report.description}
                          </p>
                        </CardBody>
                      </Card>
                    </NavLink>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Homepage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Homepage)
