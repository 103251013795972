import { gql } from '@apollo/client'

export const AddOrderFormDataQuery = 
gql`
    query AddOrderFormDataQuery {
        enum_order_status {
            value
            comment
        }
    }
`

export const getCurrentMutationParamsData = 
gql`
    query getCurrentMutationParamsData (
        \$storeId: uuid,
        \$customerPhone: String,
    ) {
        stores(where: {id: {_eq: \$storeId }}) {
            id
            map_point
        }
        customers(where: {phone: {_eq: \$customerPhone }}) {
            id
            phone
            user_locations {
                city
                state
                country
                map_point
                address_line_1
                address_line_2
                postcode
            }
        }
    }
`

export const InsertOrdersOneMutation = 
gql`
    mutation InsertOrdersOneMutation (\$object: orders_insert_input!) {
        insert_orders_one(object: \$object) {
            id
        }
    }
`

export const UpdateOrdersMutation =
  gql`mutation UpdateOrdersMutation(
    \$id: uuid!,
    \$setObject: orders_set_input,
  ) {
    update_orders_by_pk(
      pk_columns: {id: \$id},
      _set: \$setObject,
    ){
      id
    }
  }
`
