import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import {
  Container,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import "../../../../assets/scss/custom.scss"
import EcommerceSubServicesTable from "./EcommerceSubServicesTable"

const EcommerceServices = props => {
   return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Sub Services | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Ecommerce" breadcrumbItem="SubServices" />
              <EcommerceSubServicesTable
                queryCondition={null}
                activeFilters={["product_brand", "category", "subcategory", "store", "created_at", "non_mrp"]}
                filterQueryConditions={null}
                showCreateBtn={true}
              />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}
EcommerceServices.propTypes = {}
export default withRouter(EcommerceServices)
