import React, { useEffect, useState } from "react"
import { CheckPicker, Modal, TagInput, Toggle } from "rsuite"
import { withRouter } from "react-router-dom"
import {
  Insert_Create_Vehicle_Rental,
  Insert_Vehicle_Brand_One,
  Insert_vehicle_master_data_One,
  Update_Vehicle_Brand_One,
  Update_vehicle_Rental,
  Update_vehicle_master_data_One,
} from "gql/Mutation"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import { SelectPicker } from "rsuite"
import FileUploader from "components/Common/FileUploader"
import axios from "axios"
import PropTypes from "prop-types"
import Swal from "sweetalert2"
import { useLazyQuery, useMutation } from "@apollo/client"
import {
  VehicleMasterDataEnumQuery,
  VehicleMasterDataQuery,
  VehicleRentalsEnumData,
  VehicleRentalsQuery,
} from "gql/query"
import { createHeaders } from "./columns"
import Map from "./map"
import TypeHead from "./typehead"
import { BuildFields } from "../Common/BuildFields"
import { onChangeHandlerFunction } from "./Vehicle-retnals-.functions"

const VehicleRentalsCreate = (props) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [CreateVehicleMasterDataMutation] = useMutation(
    Insert_Create_Vehicle_Rental
  )
  const [UpdateVehicleMasterDataMutation] = useMutation(Update_vehicle_Rental)
  const [queryCondition, setQueryCondition] = useState(null)
  const [masterData, setMasterData] = useState({})
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  })

  const [getVehicleMasterDataList, { loading, data, refetch, error }] =
    useLazyQuery(VehicleRentalsQuery, {
      skipCache: false,
      variables: {
        limit: 1,
        where: { id: { _eq: props?.masterData?.id } },
      },
    })

  const [
    getVehicleMasterDataEnumList,
    {
      loading: EnumLoading,
      data: EnumData,
      refetch: enumRefetch,
      error: Enumerror,
    },
  ] = useLazyQuery(VehicleRentalsEnumData, {
    skipCache: false,
  })

  useEffect(() => {
    if (!queryCondition) setQueryCondition(props?.masterData?.id)
    else getVehicleMasterDataList()
    getVehicleMasterDataEnumList()
  }, [props?.masterData?.id, queryCondition])

  //If rentals data exists, merge values
  useEffect(() => {
    //Add vlaues to the all columns
    if (data?.rental_vehicles?.length > 0) {
      let rentals_data = data?.rental_vehicles?.[0]

      Object.keys(createHeaders)?.map((key, i) => {
        let obj = createHeaders?.[key]

        //If it is an array of columns
        if (
          key &&
          rentals_data?.[key] &&
          Object.keys(rentals_data?.[key])?.length > 1 &&
          Array.isArray(createHeaders?.[key])
        ) {
          createHeaders[key]?.forEach((e, index) => {
            createHeaders[key][index] = {
              ...e,
              ...{ value: rentals_data[key][e.name] },
            }
          })
        }

        //If it is an object of column and data is not an array
        else if (
          !Array.isArray(rentals_data?.[key]) &&
          !Array.isArray(createHeaders?.[key])
        ) {
          if (obj?.type == "typehead") {
            let field = createHeaders?.[key]?.access
            createHeaders[key] = {
              ...createHeaders?.[key],
              label: rentals_data?.[field]?.[createHeaders[key]?.acessLabel],
              value: rentals_data?.[field]?.[createHeaders[key]?.acessValue],
            }
          } else {
            createHeaders[key] = {
              ...createHeaders?.[key],
              value: rentals_data?.[key],
            }
          }
        }
        //If it an object of column and existing data is not array
        else if (
          Array.isArray(rentals_data?.[key]) &&
          !Array.isArray(createHeaders?.[key])
        ) {
          createHeaders[key] = {
            ...createHeaders?.[key],
            value: rentals_data?.[key],
          }
        } else if (
          Array.isArray(rentals_data?.[key]) &&
          !Array.isArray(createHeaders?.[key])
        ) {
          createHeaders[key] = {
            ...createHeaders?.[key],
            value: rentals_data?.[key],
          }
        } else {
          createHeaders[key] = {
            ...createHeaders?.[key],
            value: rentals_data?.[key],
          }
        }
      })
    }

    setMasterData({ ...createHeaders })
  }, [data?.rental_vehicles?.length])

  //Capitalize the labels
  const camalize = function camalize(str) {
    return str
      ?.toLowerCase()
      ?.replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
        return chr?.toUpperCase()
      })
  }
  //Remove an image
  const removeImage = (e) => {
    const index = e?.target?.dataset?.index
    const parent = e?.target?.dataset?.parent
    let createHeaders = {}
    createHeaders = { ...masterData }

    if (Array.isArray(createHeaders?.[parent]?.value)) {
      const newArray = [...createHeaders[parent]?.value]
      newArray.splice(index, 1)
      createHeaders[parent].value = newArray
      setMasterData(createHeaders)
    }
  }

  //Open new tab if clicks on an image
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  //Submit or update the master data
  const handleCreateBrand = async (event) => {
    event.preventDefault()
    let newObj = {}
    let user = JSON.parse(localStorage.getItem("authUser"))

    Object.keys(masterData)?.map((e, i) => {
      masterData?.[e]?.length > 0
        ? [...masterData[e]]
            ?.filter((ee) => ee)
            ?.map((ei, ii) => {
              newObj[e] = { ...newObj?.[e], [ei?.name]: ei?.value }
            })
        : (newObj[e] = masterData?.[e]?.value)
    })
    newObj = {
      ...newObj,
      updated_by: user?.user?.id,
      lat: newObj["map_point"]?.coordinates[0],
      lng: newObj["map_point"]?.coordinates[1],
    }

    try {
      setBtnLoading(true)
      if (props?.masterData?.id) {
        await UpdateVehicleMasterDataMutation({
          variables: {
            id: props?.masterData?.id,
            object: newObj,
          },
        })
      } else {
        await CreateVehicleMasterDataMutation({
          variables: {
            object: newObj,
          },
        })
      }
      Swal.fire(
        `Vehcile  ${
          props?.masterData?.id ? "Updated" : "Created"
        } Successfully`,
        "",
        "success"
      ).then(() => {
        props.onSubmit()
        props.close()
      })
    } catch (err) {
      Swal.fire("Something went wrong", "", "error")
    } finally {
      setBtnLoading(false)
    }
  }

  const setCoordinates = (field, locationDetails) => {
    console.log("locationDetsaasddails",field,locationDetails)

    let objs = {}
    objs = { ...masterData }

    objs[field].value = {
      type: "Point",
      crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::4326" } },
      locationDetails: [locationDetails[0], locationDetails[1]],
    }
    // objs["lat"] = {value:coordinates[0]}
    // objs["lng"] = {value: coordinates[1]}

    // setMasterData(objs)
  }

 

  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      style={{ width: "100vw", margin: "0 auto" }}
    >
      <Modal.Header>
        <Modal.Title className="mb-2">
          {props.brand?.id ? "UPDATE VEHICLE" : "CREATE VEHICLE"}
        </Modal.Title>
      </Modal.Header>
      <nav className="nav">
      <form onSubmit={handleCreateBrand}>
          <div className="form-group" onChange={(e) => onChangeHandlerFunction({e,masterData,setMasterData })}>
            <Row>
              {Object.keys(masterData)?.map((e, i) => {
                return !Array.isArray(masterData?.[e])
                  ?  
                  BuildFields({naming:e, field:masterData?.[e], ind:null,EnumData,setCoordinates,masterData, setMasterData, onChangeHandler:onChangeHandlerFunction})
                  : 
                  [...masterData[e]] ?.filter((ee) => ee) ?.map((ei, i) => BuildFields({naming:e, field:ei, ind:i,EnumData,setCoordinates,onChangeHandler:onChangeHandlerFunction}))
              })}
            </Row>
          </div>
          <button type="submit">submit</button>
        </form>
      </nav>
    </Modal>
  )
}

VehicleRentalsCreate.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  brand: PropTypes.any,
  onSubmit: PropTypes.func,
  masterData: PropTypes.any,
}
export default withRouter(VehicleRentalsCreate)
