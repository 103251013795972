import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import Loader from "../../../components/Loader/Loader"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
import CustomerOverview from "./CustomerOverview"
import CustomerStores from "./CustomerStores"
import CustomerOrders from "./CustomerOrders"
import CustomerAddresses from "./CustomerAddresses"
import CustomerPayments from "./CustomerPayments"
import CustomerReviews from "./CustomerReviews"

//Import custom styles
import "./EcommerceCustomerDetails.style.scss"
const EcommerceCustomerDetails = props => {
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState({})
  const [activeTab, setActiveTab] = useState('overview')
  return (
    <div className="EcommerceCustomerDetails">
      <div className="page-content">
        <MetaTags>
          <title>Customer Details | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Customers" breadcrumbItem="Customer Details" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
                    <Tab eventKey="overview" title="Overview">
                      <CustomerOverview setStores={setStores} />
                    </Tab>
                    <Tab eventKey="orders" title="Orders">
                      <CustomerOrders
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="addresses" title="Addresses">
                      <CustomerAddresses
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="stores" title="Favourite Stores">
                      <CustomerStores
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                      />
                    </Tab>
                    <Tab eventKey="payments" title="Payments">
                      <CustomerPayments
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="reviews" title="Reviews">
                      <CustomerReviews
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommerceCustomerDetails.propTypes = {
}
export default EcommerceCustomerDetails