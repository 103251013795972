import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { useRouteMatch } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
//GraphQL
import { getCustomerAddresses } from "gql/query"
import { useQuery } from "@apollo/client"

const CustomerAddresses = (props) => {
	const FormatData = (data) => {
		const addresses = data.customer_addresses.map(elem => {
			return {
				address: elem.address_line_1 || "N/A",
				city: elem.city || "N/A",
				state: elem.state || "N/A",
				postcode: elem.postcode || "N/A",
				country: elem.country || "N/A",
				created_at: elem.created_at,
				name: elem.name || "N/A",
				category: elem.category || "N/A",
				locality: elem.locality || "N/A",
				is_default: elem.is_default,
			}
		})
		return addresses
	}
	const addressColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Address",
			dataField: "address",
			sort: true,
		},
		{
			dataField: "city",
			text: "City",
			sort: true,
		},
		{
			dataField: "state",
			text: "State",
			sort: true,
		},
		// {
		// 	text: "Postal Code",
		// 	dataField: "postcode",
		// 	sort: true,
		// },
		{
			dataField: "country",
			text: "Country",
			sort: true,
		},
		// {
		// 	text: "Title",
		// 	dataField: "name",
		// 	sort: true,
		// },
		{
			text: "Category",
			dataField: "category",
			sort: true,
		},
		{
			dataField: "locality",
			text: "Locality",
			sort: true,
		},
		{
			dataField: "is_default",
			text: "Default",
			sort: true,
			formatter: (cellContent, row) => cellContent ? "Yes" : "No"
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
	]
	const match = useRouteMatch()
	const defaultSorted = []
	const [addresses, setAddresses] = useState([])
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({ customer_id: { _eq: match?.params?.id } })
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}

	const { data, loading, error } = useQuery(getCustomerAddresses, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const count = data?.customer_addresses_aggregate?.aggregate?.count
	const maxPage = Math.ceil(count / PageData.limit)
	useEffect(() => {
		if (data) {
			setAddresses(FormatData(data))
		}
	}, [data])
 	return (
		<div className="p-3">
			<Row className="mb-5">
				<Row className="mb-2">
					<Col sm="8">
						<h4>No. of Addresses: {count}</h4>
					</Col>
					<Col sm="4">
						<div className="text-sm-end d-flex justify-content-end">
							{/* <CheckPicker 
								data={storesData} 
								placeholder="Select Store" 
								style={{ width: 224 }} 
								value={selectedStores}
								onChange={setSelectedStores}
								searchable={false}
							/> */}
						</div>
					</Col>
				</Row>
				<hr />
				<ToolkitProvider
					keyField="id"
					data={addresses || []}
					columns={addressColumns}
					bootstrap4
				>
					{toolkitProps => (
						<>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										{loading ? <Loader /> :
											<BootstrapTable
												responsive
												bordered={false}
												striped={false}
												defaultSorted={defaultSorted}
												classes={"table align-middle table-nowrap"}
												keyField="id"
												{...toolkitProps.baseProps}
												onTableChange={() => { }}
												noDataIndication={() => "No Addresses Found!"}
											/>
										}
									</div>
								</Col>
							</Row>
							<Row className="align-items-md-center mt-40">
								<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
									<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
										Previous Page
									</Button>
									<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
									</div>
									<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
										Next Page
									</Button>
								</Col>
							</Row>
						</>
					)}
				</ToolkitProvider>
			</Row>
		</div>
	)
}
CustomerAddresses.propTypes = {
	
}
CustomerAddresses.displayName = 'CustomerAddresses'
export default CustomerAddresses