import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker, SelectPicker } from 'rsuite'
import { Dropdown } from "react-bootstrap"
import { useState } from "react"
import PropTypes from "prop-types"
import { ProductStoreFilter, CustomerCityFilter, ProductCategoryFilter, ProductSubCategoryFilter } from "../../../gql/query"

//load components
import CheckPickerPaginate from "../CheckPickerPaginate"

const EcommercePromotionFilter = (props) => {
    const [filterData, setFilterData] = useState([])
    const [filters, setFilters] = useState([])
    const [filterValues, setFilterValues] = useState({})

    const promotionActiveData = [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
    const discountTypeData = [{ label: "Percentage", value: "percentage" }, { label: "Flat", value: "flat" }]
    const promotedByData = [{ label: "Store", value: "store" }, { label: "EEfind", value: "eefind" }]
    const totalUsageRangeData = [{ label: "< 10", value: "<10" }, { label: "10 - 20", value: "10-20" }, { label: "20 - 50", value: "20-50" }, { label: "50 - 100", value: "50-100" }, { label: "> 100", value: ">1000" }]

    const resetFilter = (value) => {
        const filtersTemp = filters.filter(item => item !== value)
        setFilters(filtersTemp)
    }
    const getFilterCondition = (filterKey) => {
        if (props.filterQueryConditions !== null) {
            let condition = props.filterQueryConditions.filter(condition => condition.filter === filterKey)
            if (condition.length) {
                return condition[0]?.condition
            }
        } else {
            return null
        }
    }
    useEffect(() => {
        props.onFilterChange(filterValues)
    }, [filterValues])
    useEffect(() => {
        if (filters.length) {
            let filterValuesTemp = { ...filterValues }
            if (!filters.includes("active")) {
                delete filterValuesTemp["active"]
            }
            if (!filters.includes("created_at")) {
                delete filterValuesTemp["created_at"]
            }
            if (!filters.includes("store")) {
                delete filterValuesTemp["store"]
            }
            if (!filters.includes("discount_type")) {
                delete filterValuesTemp["discount_type"]
            }
            // if (!filters.includes("total_usage")) {
            // 	delete filterValuesTemp["total_usage"]
            // }
            if (!filters.includes("promoted_by")) {
                delete filterValuesTemp["promoted_by"]
            }
            if (!filters.includes("validity")) {
                delete filterValuesTemp["validity"]
            }
            setFilterValues(filterValuesTemp)
        } else {
            setFilterValues({})
        }
    }, [filters])
    useEffect(() => {
        let filterDataTemp = []
        props.activeFilters.map((filter) => {
            if (filter === "active") {
                filterDataTemp.push({ label: "Active", value: "active" })
            }
            if (filter === "store") {
                filterDataTemp.push({ label: "Store", value: "store" })
            }
            if (filter === "created_at") {
                filterDataTemp.push({ label: "Created At", value: "created_at" })
            }
            if (filter === "discount_type") {
                filterDataTemp.push({ label: "Discount Type", value: "discount_type" })
            }
            // if (filter === "total_usage") {
            // 	filterDataTemp.push({ label: "Total Usage", value: "total_usage" })
            // }
            if (filter === "promoted_by") {
                filterDataTemp.push({ label: "Promoted By", value: "promoted_by" })
            }
            if (filter === "validity") {
                filterDataTemp.push({ label: "Validity", value: "validity" })
            }
        })
        setFilterData(filterDataTemp)
    }, [props.activeFilters])
    return (
        <div className="auto-horizontal-scroll">
            <CheckPicker
                data={filterData}
                placeholder={"Filter"}
                style={{ width: 130 }}
                value={filters}
                onChange={setFilters}
                searchable={false}
            />
            {filters.map((filter, i) => {
                if (filter === "active") {
                    return (
                        <React.Fragment key={i}>
                            <SelectPicker
                                data={promotionActiveData}
                                placeholder={"Select Status"}
                                style={{ marginLeft: 10 }}
                                onChange={(active) => setFilterValues({ ...filterValues, active })}
                                searchable={false}
                                placement="bottomEnd"
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("active")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "created_at") {
                    return (
                        <React.Fragment key={i}>
                            <DateRangePicker
                                placement="auto"
                                onChange={(created_at) => setFilterValues({ ...filterValues, created_at })}
                                style={{ marginLeft: 10 }}
                                placeholder="Select Created At Date"
                                value={Array.isArray(filterValues.created_at) ? filterValues.created_at : []}
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_at")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "store") {
                    return (
                        <React.Fragment key={i}>
                            <CheckPickerPaginate
                                query={ProductStoreFilter}
                                placeholder="Select Store"
                                value={Array.isArray(filterValues.store) ? filterValues.store : []}
                                onChange={(store) => setFilterValues({ ...filterValues, store })}
                                arrKey="stores"
                                objectLabel="name"
                                objectValue="id"
                                style={{ marginLeft: 10 }}
                                paginate={true}
                                filterCondition={getFilterCondition("store")}
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("store")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "discount_type") {
                    return (
                        <React.Fragment key={i}>
                            <SelectPicker
                                data={discountTypeData}
                                placeholder={"Select Discount Type"}
                                style={{ marginLeft: 10 }}
                                onChange={(discount_type) => setFilterValues({ ...filterValues, discount_type })}
                                searchable={false}
                                placement="bottomEnd"
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("discount_type")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                // if (filter === "total_usage") {
                // 	return (
                // 		<React.Fragment key={i}>
                // 			<CheckPicker
                // 				data={totalUsageRangeData}
                // 				placeholder={"Select total usage ranges"}
                // 				style={{ marginLeft: 10 }}
                // 				value={Array.isArray(filterValues.total_usage) ? filterValues.total_usage : []}
                // 				onChange={(total_usage) => setFilterValues({ ...filterValues, total_usage })}
                // 				searchable={false}
                // 				placement="bottomEnd"
                // 			/>
                // 			<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("total_usage")}>
                // 				<i className="bx bx-x-circle" />
                // 			</div>
                // 		</React.Fragment>
                // 	)
                // }
                if (filter === "promoted_by") {
                    return (
                        <React.Fragment key={i}>
                            <SelectPicker
                                data={promotedByData}
                                placeholder={"Select Promoted By"}
                                style={{ marginLeft: 10 }}
                                onChange={(promoted_by) => setFilterValues({ ...filterValues, promoted_by })}
                                searchable={false}
                                placement="bottomEnd"
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("promoted_by")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
                if (filter === "validity") {
                    return (
                        <React.Fragment key={i}>
                            <DateRangePicker
                                placement="auto"
                                onChange={(validity) => setFilterValues({ ...filterValues, validity })}
                                style={{ marginLeft: 10 }}
                                placeholder="Select Validity"
                                value={Array.isArray(filterValues.validity) ? filterValues.validity : []}
                            />
                            <div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("validity")}>
                                <i className="bx bx-x-circle" />
                            </div>
                        </React.Fragment>
                    )
                }
            })}
        </div>
    )
}
EcommercePromotionFilter.propTypes = {
    onFilterChange: PropTypes.func,
    activeFilters: PropTypes.array,
    filterQueryConditions: PropTypes.any
}
export default EcommercePromotionFilter