import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceBrandsTable from "../../EcommerceBrands/EcommerceBrandsTable"
const PromotionBrands = (props) => {
    const match = useRouteMatch()
    const { brands } = props.promotion
    return (
        <div className="p-3">
            {Array.isArray(brands) && (
                <EcommerceBrandsTable
                    queryCondition={{ id: { _in: brands } }}
                    activeFilters={["product_brand", "category", "subcategory", "store", "created_at", "non_mrp"]}
                    filterQueryConditions={null}
                    showCreateBtn={false}
                />
            )}
        </div>
    )
}
PromotionBrands.propTypes = {
    promotion: PropTypes.any
}
PromotionBrands.displayName = 'PromotionBrands'
export default PromotionBrands