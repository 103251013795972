import React, { useState } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { Modal } from "rsuite"
import Swal from "sweetalert2"
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	Input,
	Label,
	Row
} from "reactstrap"
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"

//GraphQL
import { UpdateProduct, UpdateProductTax } from "gql/Mutation"
import { CategoryList, SubCategoryList, TaxesList } from "gql/query"
import { useMutation } from "@apollo/client"
import { useEffect } from "react"

const EditProductModal = (props) => {
	const history = useHistory()
	const [UpdateProductMutation] = useMutation(UpdateProduct)
	const [UpdateProductTaxMutation] = useMutation(UpdateProductTax)
	const [name, setName] = useState("")
	const [category, setCategory] = useState("")
	const [categoryLabel, setCategoryLabel] = useState("")
	const [subcategory, setSubcategory] = useState("")
	const [subcategoryLabel, setSubcategoryLabel] = useState("")
	const [countryOrigin, setCountryOrigin] = useState("")
	const [gstBracket, setGstBracket] = useState("")
	const [btnLoading, setBtnLoading] = useState(false)
	const handleUpdateProduct = async (e) => {
		try {
			e.preventDefault()
			setBtnLoading(true)
			let obj = {
				nonmrp_product_name: name,
				nonmrp_categories_id: category,
				nonmrp_subcategory_id: subcategory,
				country_origin: countryOrigin
			}
			if (props.approveBtn) {
				obj = {
					...obj,
					approve: !props.product.approve
				}
			}
			await UpdateProductMutation({
				variables: {
					id: props.product?.id,
					object: obj
				},
			})
			if (gstBracket) {
				await UpdateProductTaxMutation({
					variables: {
						id: props.product?.id,
						object: {
							tax_id: parseInt(gstBracket)
						}
					},
				})
			}
			Swal.fire("Product Updated Successfully", "", "success").then(() => {
				props.close()
				props.refetch()
			})
		} catch (err) {
			Swal.fire("Something went wrong", "", "error")
		} finally {
			setBtnLoading(false)
		}
	}
	useEffect(() => {
		if (props.product?.id) {
			setName(props.product?.nonmrp_product_name)
			setCategory(props.product?.nonmrp_category?.id || "")
			setCategoryLabel(props.product?.nonmrp_category?.name || "")
			setSubcategory(props.product?.nonmrp_subcategory?.id || "")
			setSubcategoryLabel(props.product?.nonmrp_subcategory?.name || "")
			setCountryOrigin(props.product?.country_origin || "")
			setGstBracket(props.product?.store_product_tax?.tax?.id || "")
		}
	}, [props.product])
	return (
		<Modal open={props.open} onClose={() => props.close()}>
			<Modal.Header>
				<Modal.Title>Edit Product</Modal.Title>
			</Modal.Header>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<Form id="productform" onSubmit={handleUpdateProduct}>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="product_name">Product Name</Label>
											<Input
												id="product_name"
												name="product_name"
												defaultValue={name}
												type="text"
												className="form-control"
												onChange={(e) => setName(e.target.value)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="category_group">Category</Label>
											<br />
											<SelectPickerPaginate
												query={CategoryList}
												placeholder="Select Category"
												value={category}
												onChange={setCategory}
												arrKey="categories"
												objectLabel="name"
												objectValue="id"
												style={{ width: "100%" }}
												paginate={true}
												filterCondition={null}
												searchValue={categoryLabel}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="subcategory_group">Subcategory</Label>
											<br />
											<SelectPickerPaginate
												query={SubCategoryList}
												placeholder="Select Subcategory"
												value={subcategory}
												onChange={setSubcategory}
												arrKey="subcategories"
												objectLabel="name"
												objectValue="id"
												style={{ width: "100%" }}
												paginate={true}
												filterCondition={null}
												searchValue={subcategoryLabel}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="subcategory_group">GST Bracket</Label>
											<br />
											<SelectPickerPaginate
												query={TaxesList}
												placeholder="Select GST Bracket"
												value={gstBracket}
												onChange={setGstBracket}
												arrKey="taxes"
												objectLabel="name"
												objectValue="id"
												style={{ width: "100%" }}
												paginate={false}
												filterCondition={null}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="country_of_origin">Country of Origin</Label>
											<Input
												id="country_of_origin"
												name="country_of_origin"
												defaultValue={countryOrigin}
												type="text"
												className="form-control"
												onChange={(e) => setCountryOrigin(e.target.value)}
											/>
										</div>
									</Col>
								</Row>
								<div className="d-flex flex-wrap gap-2">
									<Button
										color="primary"
										className="btn "
										type="submit"
										disabled={btnLoading}
									>
										{props.approveBtn ? "Update & Approve" : "Update"}
									</Button>
									<Button
										type="button"
										color="secondary"
										className=" "
										onClick={(e) => props.close()}
									>
										Cancel
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Modal>
	)
}
EditProductModal.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	product: PropTypes.any,
	refetch: PropTypes.func,
	approveBtn: PropTypes.bool
}
export default withRouter(EditProductModal)
