import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import { useRouteMatch, Link } from "react-router-dom"
import moment from "moment"
import { Badge } from "react-bootstrap"
import Loader from "../../../components/Loader/Loader"
import Swal from "sweetalert2"
//GraphQL
import { CustomerDetails, MerchantStats } from "gql/query"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import {
	UpdateVendorCompany
} from "../EcommerceMerchants/EcommerceMerchants.query"

const CustomerOverview = (props) => {
	const match = useRouteMatch()
	const [customer, setCustomer] = useState({})
	const [queryConditionStats, setQueryConditionStats] = useState({})

	const { data: customerDetails, loading, error, refetch } = useQuery(CustomerDetails, {
		skipCache: true,
		variables: {
			id: match?.params?.id
		}
	})
	
	const [UpdateVendorCompanyMutation] = useMutation(UpdateVendorCompany)
	const formatPrice = (value) => {
		if (!isNaN(value) && value !== null && value !== undefined) {
			return `₹${value.toFixed(2)}`
		} else {
			return "N/A"
		}
	}
	const handleChangeStatus = async () => {
		try {
			Swal.fire({
				title: 'Are you sure want to change the status?',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				icon: "warning"
			}).then(async (result) => {
				if (result.isConfirmed) {
					let status = (customer.vendor_company?.verification_status === "verified" ? "rejected" : "verified")
					await UpdateVendorCompanyMutation({
						variables: {
							id: customer.id,
							set: { verification_status: status }
						},
					})
					RefetchMerchant()
				}
			})
		} catch (err) {
			console.log(err)
		}
	}
	useEffect(() => {
		if (customerDetails?.customers?.length > 0) {
			setCustomer(customerDetails.customers[0])
		}
	}, [customerDetails])
	return (
		<Container>
			<Row>
				<Col sm="4">
					<img src={customer.photo_url?.length > 0 ? customer.photo_url : "/avatar.jpg"} className="w-100" />
				</Col>
				<Col sm="8" className="pl-3">
					<div>
						<h3>{customer.name} 
							{/* <Badge bg={customer.active ? "success" : "danger"} style={{ textTransform: "capitalize" }}>{customer.active ? "Active" : "Blocked"}</Badge> */}
						</h3>
						<h6><a href={`mailto:${customer.email}`}>{customer.email}</a></h6>
						<h6><a href={`tel:${customer.phone}`}>{customer.phone}</a></h6>
					</div>
					<hr />
					<Row>
						<div className="stats-wrapper">
							<b>Customer ID</b>
							<p>{customer.customer_id}</p>
						</div>
						<div className="stats-wrapper">
							<b>Last Order</b>
							{customer?.ordersByCustomerId?.length > 0 ? (
								<p>{moment(customer?.ordersByCustomerId[0]?.created_at).fromNow()} <Link to={`/ecommerce-orders/details/${customer?.ordersByCustomerId[0]?.id}`}>#{customer?.ordersByCustomerId[0]?.uid}</Link></p>
							) : (
								<p>No orders found!</p>
							)}
						</div>
						{/* <div className="stats-wrapper">
							<b>Average Order Details</b>
							<p>{loading ? "" : formatPrice(customer.ordersByCustomerId_aggregate?.aggregate?.avg?.total_amount)}</p>
						</div> */}
						<div className="stats-wrapper">
							<b>Lifetime Order Value</b>
							<p>{loading ? "" : formatPrice(customer.ordersByCustomerId_aggregate?.aggregate?.sum?.total_amount)}</p>
						</div>
						<div className="stats-wrapper">
							<b>Referral Code</b>
							<p>N/A</p>
						</div>
						<div className="stats-wrapper">
							<b>Lifetime Order Count</b>
							<p>{loading ? "" : customer.ordersByCustomerId_aggregate?.aggregate?.count}</p>
						</div>
						<div className="stats-wrapper">
							<b>Gender</b>
							<p>{customer.gender || "N/A"}</p>
						</div>
					</Row>
					<h4 className="mt-2">Consent &amp; Verification</h4>
					<hr />
					<Row>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Terms &amp; Conditions Consent: </b> Yes
							</div>
						</Col>
						<Col sm="6">
							<div className="mb-2">
								<b className="mb-5">Phone Verification: </b> {customer.is_phone_verified ? "Verified" : "Pending"}
							</div>
							<div className="mb-2">
								<b className="mb-5">Email Verification: </b> {customer.is_email_verified ? "Verified" : "Pending"}
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
			{/* <Row>
				<Col xl="12" className="d-flex justify-content-end">
					<Button
						type="button"
						color={customer.vendor_company?.verification_status === "verified" ? "danger" : "primary"}
						className="btn-rounded btn-lg  mb-2 me-2"
						onClick={handleChangeStatus}
					>
						{customer.vendor_company?.verification_status === "verified" ? "Deactivate" : "Activate"}
					</Button>
				</Col>
			</Row> */}
		</Container>
	)
}
CustomerOverview.propTypes = {
	setStores: PropTypes.func
}
CustomerOverview.displayName = 'CustomerOverview'
export default CustomerOverview