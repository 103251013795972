import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker, Form, InputNumber, InputGroup } from 'rsuite'
import { Dropdown } from "react-bootstrap"
import { useState } from "react"
import PropTypes from "prop-types"
import { ShopCityFilter, ShopStateFilter } from "../../../gql/query"
import { FiInfo } from "react-icons/fi"
//load components
import CheckPickerPaginate from "../CheckPickerPaginate"

const EcommerceShopFilter = (props) => {
	const [filterData, setFilterData] = useState([])
	const shopActiveData = [{ label: "Yes", value: true }, { label: "No", value: false }]
	const [storeTypeData, setStoreTypeData] = useState([{ label: "Groceries and Essentials", value: "Groceries and Essentials" }, { label: "Books", value: "Books" }])
	const salesRangeData = [{ label: "< ₹200", value: "<200" }, { label: "₹200 - ₹500", value: "200-500" }, { label: "₹500 - ₹750", value: "500-750" }, { label: "₹750 - ₹1000", value: "750-1000" }, { label: "> ₹1000", value: ">1000" }]
	const totalOrdersData = [{ label: "1 - 5", value: "1-5" }, { label: "5 - 10", value: "5-10" }, { label: "10 - 15", value: "10-15" }, { label: "15 - 20", value: "15-20" }, { label: "> 20", value: ">20" }]
	const totalProductsData = [{ label: "< 50", value: "<50" }, { label: "50 - 100", value: "50-100" }, { label: "100 - 200", value: "100-200" }, { label: "200 - 400", value: "200-400" }, { label: "> 400", value: ">400" }]

	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})
	const [totalSales, setTotalSales] = useState({
		from: "",
		to: ""
	})
	const [totalOrders, setTotalOrders] = useState({
		from: "",
		to: ""
	})

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
		if (value === "total_sales") {
			setTotalSales({
				from: "",
				to: ""
			})
		}
		if (value === "total_orders") {
			setTotalOrders({
				from: "",
				to: ""
			})
		}
	}
	const getFilterCondition = (filterKey) => {
		if (props.filterQueryConditions !== null) {
			let condition = props.filterQueryConditions.filter(condition => condition.filter === filterKey)
			if (condition.length) {
				return condition[0]?.condition
			}
		} else {
			return null
		}
	}
	useEffect(() => {
		props.onFilterChange(filterValues)
	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			let filterValuesTemp = { ...filterValues }
			if (!filters.includes("store_type")) {
				delete filterValuesTemp["store_type"]
			}
			if (!filters.includes("created_at")) {
				delete filterValuesTemp["created_at"]
			}
			if (!filters.includes("active")) {
				delete filterValuesTemp["active"]
			}
			if (!filters.includes("city")) {
				delete filterValuesTemp["city"]
			}
			if (!filters.includes("state")) {
				delete filterValuesTemp["state"]
			}
			if (!filters.includes("total_sales")) {
				delete filterValuesTemp["total_sales"]
			}
			if (!filters.includes("total_products")) {
				delete filterValuesTemp["total_products"]
			}
			if (!filters.includes("total_orders")) {
				delete filterValuesTemp["total_orders"]
			}
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])
	useEffect(() => {
		let filterDataTemp = []
		props.activeFilters.map((filter) => {
			if (filter === "store_type") {
				filterDataTemp.push({ label: "Shop Type", value: "store_type" })
			}
			if (filter === "city") {
				filterDataTemp.push({ label: "City", value: "city" })
			}
			if (filter === "state") {
				filterDataTemp.push({ label: "State", value: "state" })
			}
			if (filter === "created_at") {
				filterDataTemp.push({ label: "Created At", value: "created_at" })
			}
			if (filter === "active") {
				filterDataTemp.push({ label: "Active", value: "active" })
			}
			if (filter === "total_sales") {
				filterDataTemp.push({ label: "Total Sales", value: "total_sales" })
			}
			if (filter === "total_products") {
				filterDataTemp.push({ label: "Total Products", value: "total_products" })
			}
			if (filter === "total_orders") {
				filterDataTemp.push({ label: "Total Orders", value: "total_orders" })
			}
		})
		setFilterData(filterDataTemp)
	}, [props.activeFilters])
	useEffect(() => {
		if (!isNaN(totalSales?.from) && !isNaN(totalSales?.to)) {
			if ((parseFloat(totalSales.from) > 0) && (parseFloat(totalSales.to) > 0)) {
				setFilterValues({ ...filterValues, total_sales: [`${totalSales.from}-${totalSales.to}`] })
			}
		}
	}, [totalSales])
	useEffect(() => {
		if (!isNaN(totalOrders?.from) && !isNaN(totalOrders?.to)) {
			if ((parseFloat(totalOrders.from) > 0) && (parseFloat(totalOrders.to) > 0)) {
				setFilterValues({ ...filterValues, total_orders: [`${totalOrders.from}-${totalOrders.to}`] })
			}
		}
	}, [totalOrders])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "store_type") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={storeTypeData}
								placeholder={"Select Shop Type"}
								style={{ marginLeft: 10 }}
								// value={Array.isArray(filterValues.store_type) || []}
								onChange={(store_type) => setFilterValues({ ...filterValues, store_type })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("store_type")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "created_at") {
					return (
						<React.Fragment key={i}>
							<DateRangePicker
								placement="auto"
								onChange={(created_at) => setFilterValues({ ...filterValues, created_at })}
								style={{ marginLeft: 10 }}
								placeholder="Select Created At Date"
								value={Array.isArray(filterValues.created_at) ? filterValues.created_at : []}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("created_at")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "active") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={shopActiveData}
								placeholder={"Select Shop Status"}
								style={{ marginLeft: 10 }}
								onChange={(active) => setFilterValues({ ...filterValues, active })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("active")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "city") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ShopCityFilter}
								placeholder="Select City"
								value={Array.isArray(filterValues.city) ? filterValues.city : []}
								onChange={(city) => setFilterValues({ ...filterValues, city })}
								arrKey="stores"
								objectLabel="city"
								objectValue="city"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("city")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("city")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "state") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={ShopStateFilter}
								placeholder="Select State"
								value={Array.isArray(filterValues.state) ? filterValues.state : []}
								onChange={(state) => setFilterValues({ ...filterValues, state })}
								arrKey="stores"
								objectLabel="state"
								objectValue="state"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={getFilterCondition("state")}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("state")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "total_sales") {
					return (
						<React.Fragment key={i}>
							<InputGroup style={{ marginLeft: 10, minWidth: 350 }}>
								<InputNumber
									prefix="From"
									value={totalSales.from}
									onChange={(from) => setTotalSales({ ...totalSales, from })}
								/>
								<InputNumber
									prefix="To"
									value={totalSales.to}
									onChange={(to) => setTotalSales({ ...totalSales, to })}
								/>
								<InputGroup.Addon>
									<p>Total Sales</p>
								</InputGroup.Addon>
							</InputGroup>
							{/* <CheckPicker
								data={salesRangeData}
								placeholder={"Select sales ranges"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.total_sales) ? filterValues.total_sales : []}
								onChange={(total_sales) => setFilterValues({ ...filterValues, total_sales })}
								searchable={false}
								placement="bottomEnd"
							/> */}
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("total_sales")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "total_orders") {
					return (
						<React.Fragment key={i}>
							<InputGroup style={{ marginLeft: 10, minWidth: 350 }}>
								<InputNumber
									prefix="From"
									value={totalOrders.from}
									onChange={(from) => setTotalOrders({ ...totalOrders, from })}
								/>
								<InputNumber
									prefix="To"
									value={totalOrders.to}
									onChange={(to) => setTotalOrders({ ...totalOrders, to })}
								/>
								<InputGroup.Addon>
									<p>Total Orders</p>
								</InputGroup.Addon>
							</InputGroup>
							{/* <CheckPicker
								data={totalOrdersData}
								placeholder={"Select order ranges"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.total_orders) ? filterValues.total_orders : []}
								onChange={(total_orders) => setFilterValues({ ...filterValues, total_orders })}
								searchable={false}
								placement="bottomEnd"
							/> */}
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("total_orders")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "total_products") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={totalProductsData}
								placeholder={"Select product ranges"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.total_products) ? filterValues.total_products : []}
								onChange={(total_products) => setFilterValues({ ...filterValues, total_products })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("total_products")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
EcommerceShopFilter.propTypes = {
	onFilterChange: PropTypes.func,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any
}
export default EcommerceShopFilter