import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"
//import components
import EcommerceReviewsTable from "../EcommerceReviews/EcommerceReviewsTable"

const CustomerReviews = (props) => {
    const match = useRouteMatch()
    return (
        <div className="p-3">
            <EcommerceReviewsTable
                queryCondition={{ user_id: { _eq: match?.params?.id } }}
                activeFilters={[]}
                filterQueryConditions={null}
            />
        </div>
    )
}
CustomerReviews.propTypes = {}
CustomerReviews.displayName = 'CustomerReviews'
export default CustomerReviews