import { VehicleRentalMasterVehiclesTypehead, VehicleRentalStoresTypehead, VehicleRentalVehicleBarndsTypehead, VehicleRentalsCategoriesTypehead, VehicleTypesTypehead, VehicleUsersTypehead } from "gql/query"


export const createHeaders = {

              addon_name   : {name:"addon_name", span:3,type:"text", },
             description   : {name:"description", span:3,type:"text", },
                   price   : {name:"price", span:3,type:"number", },
                    icon   : {name:"icon", span:3,type:"img", },
              addon_type   : {name:"addon_type", span:3,type:"select", },
              powered_by   : [
                               {name:"name", span:3,type:"text", },
                               {name:"web_link", span:3,type:"text", },
                               {name:"logo_link", span:3,type:"text", },
            
                             ],
 
                

 
         }


