import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { Modal, Toggle, SelectPicker, DatePicker } from "rsuite"
import * as moment from "moment"
import axios from "axios"
import Swal from "sweetalert2"
import {
	Button,
	Card,
	CardBody,
	CardSubtitle,
	CardTitle,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row
} from "reactstrap"

//components
import FileUploader from "components/Common/FileUploader"

//utils
import {handleUploadFile} from "../../../../utils/index"

const EcommerceCreateSystemUser = (props) => {
	const history = useHistory()
	const [state, setState] = useState({
		name: "",
		email: "",
		phone: "",
		photo_url: "",
		active: true,
		password: "",
		additional_role: "",
	})
	const [image, setImage] = useState(null)
	const [btnLoading, setBtnLoading] = useState(false)
	const roleOptions = [{ label: "Superadmin", value: "superadmin" }, { label: "Admin", value: "admin" }, { label: "Manager", value: "manager" }, { label: "Team", value: "team" }, { label: "Support", value: "support" }]

	const handleCreateUser = async(e) =>{
		e.preventDefault()
		try{
			setBtnLoading(true)
			const token = localStorage.getItem("TOKEN")
			let photo_url = ""
			if(image){
				photo_url = await handleUploadFile(image)
			}
			let url = props.user?.id ? `${process.env.REACT_APP_SERVICE_BASE_URL}admin/edit/${props.user?.id}` : `${process.env.REACT_APP_SERVICE_BASE_URL}admin/register`
			await axios({
				url,
				method: props.user?.id ? 'put' : 'post',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				data: {
					...state,
					photo_url,
					password: state.password?.length > 0 ? state.password : undefined
				}
			})
			Swal.fire(`User ${props.user?.id ? "updated" : "created"} successfully`, "", "success").then(()=>{
				props.onSubmit()
				props.close()
			})
		}catch(err){
			let message = "Something went wrong"
			if(err?.response?.data?.error?.message){
				message = err?.response?.data?.error?.message
			}
			Swal.fire(message, "", "warning")
		}finally{
			setBtnLoading(false)
		}
	}
	useEffect(() => {
		if (props.user?.id) {
			setState({
				name: props.user.name,
				email: props.user.email,
				phone: props.user.phone,
				photo_url: "",
				active: props.user.active,
				password: "",
				additional_role: props.user.role,
			})
		} else {
			setState({
				name: "",
				email: "",
				phone: "",
				photo_url: "",
				active: true,
				password: "",
				additional_role: "",
			})
		}
	}, [props.user])
	return (
		<Modal open={props.open} onClose={() => props.close()}>
			<Modal.Header>
				<Modal.Title>{props.user?.id ? "Update User" : "Create User"}</Modal.Title>
			</Modal.Header>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<Form id="brandform" onSubmit={handleCreateUser}>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="name">Name</Label>
											<Input
												id="name"
												name="name"
												type="text"
												className="form-control"
												value={state.name}
												onChange={(e) => setState({ ...state, name: e.target.value })}
												required
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="label">Email</Label>
											<Input
												id="email"
												name="email"
												type="email"
												className="form-control"
												value={state.email}
												onChange={(e) => setState({ ...state, email: e.target.value })}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="phone">Phone</Label>
											<Input
												id="phone"
												name="phone"
												type="number"
												className="form-control"
												required
												value={state.phone}
												onChange={(e) => setState({ ...state, phone: e.target.value })}
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="role">Role</Label>
											<SelectPicker
												id="role"
												data={roleOptions}
												searchable={false}
												position="auto"
												className="form-control"
												style={{ border: "none" }}
												onChange={(value) => setState({ ...state, additional_role: value })}
												value={state.additional_role}
												cleanable={false}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="password">Password</Label>
											<Input
												id="password"
												name="password"
												type="password"
												className="form-control"
												value={state.password}
												onChange={(e) => setState({ ...state, password: e.target.value })}
												required={props.user?.id ? false : true}
											/>
										</div>
									</Col>
									<Col sm="6">
										<div className="mb-3">
											<Label htmlFor="active">Active</Label>
											<Toggle
												id="active"
												checked={state.active}
												onChange={(checked) => setState({ ...state, active: checked })}
												style={{ marginLeft: "1rem" }}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="avatar">Avatar</Label>
											<FileUploader 
												onChange={setImage}
											/>
										</div>
									</Col>
								</Row>
								<div className="d-flex flex-wrap gap-2">
									<Button
										color="primary"
										className="btn "
										type="submit"
										disabled={btnLoading}
									>
										Submit
									</Button>
									<Button
										type="button"
										color="secondary"
										className=" "
										onClick={(e) => props.close()}
									>
										Cancel
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Modal>
	)
}
EcommerceCreateSystemUser.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	onSubmit: PropTypes.func,
	user: PropTypes.any
}
export default withRouter(EcommerceCreateSystemUser)
