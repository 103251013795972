import { VehicleRentalMasterVehiclesTypehead, VehicleRentalStoresTypehead, VehicleRentalVehicleBarndsTypehead, VehicleRentalsCategoriesTypehead, VehicleTypesTypehead, VehicleUsersTypehead } from "gql/query"


export const Columns = {

                     name    : {name:"name", span:3,type:"text", },
                     address : {name:"address", span:3,type:"text", },
                     active  : {name:"active", span:3,type:"boolean", },
                      state  : {name:"state", span:3,type:"text", },
                  map_point  : {name:"map_point", span:24,type:"map" },
                

 
         }



