import React, { useEffect, useState } from "react"
import { Modal } from "rsuite"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	CardSubtitle,
	CardTitle,
	Col,
	Container,
	Form,
	FormGroup,
	Input,
	Label,
	Row
} from "reactstrap"
//GraphQL
import { useMutation } from "@apollo/client"
import { CreateMerchantNote, UpdateMerchantNote } from "gql/Mutation"
const MerchantCreateNote = (props) => {
	const [loading, setLoading] = useState(false)
	const [title, setTitle] = useState("")
	const [note, setNote] = useState("")
	const [CreateMerchantNoteMutation] = useMutation(CreateMerchantNote)
	const [UpdateMerchantNoteMutation] = useMutation(UpdateMerchantNote)

	const handleCreateNote = async (e) => {
		try {
			e.preventDefault()
			setLoading(true)
			if(props.note?.id){
				await UpdateMerchantNoteMutation({
					variables: {
						id: props.note?.id,
						object: {
							title,
							note
						}
					},
				})
			}else{
				await CreateMerchantNoteMutation({
					variables: {
						object: {
							title,
							note,
							merchant_id: props.merchantId
						},
					},
				})
			}
			props.close()
			props.onCreate()
		} catch (err) {
			console.log(err)
		} finally {
			setLoading(false)
		}
	}
	useEffect(()=>{
		if(props.note?.id){
			setNote(props.note.note)
			setTitle(props.note.title)
		}else{
			setNote("")
			setTitle(props.title ? props.title : "Physical Verification")
		}
	}, [props.note])
	useEffect(()=>{
		if(props.title){
		setTitle(props.title)
		}
	}, [props.title])
	return (
		<Modal open={props.open} onClose={() => props.close()}>
			<Modal.Header>
				<Modal.Title>Create Note</Modal.Title>
			</Modal.Header>
			<Form style={{ maxWidth: "90%" }} onSubmit={handleCreateNote}>
				<Modal.Body>
					<Row>
						<Col sm="12">
							<div className="mb-3">
								<Label htmlFor="noteTitle">Title</Label>
								<Input
									type="select"
									name="title"
									id="noteTitle"
									required
									value={title}
									onChange={(e) => setTitle(e.target.value)}
								>
									<option value="Physical Verification">Physical Verification</option>
									<option value="Call Verification">Call Verification</option>
									<option value="Bank Verification">Bank Verification</option>
									<option value="Other">Other</option>
								</Input>
							</div>
						</Col>
					</Row>
					<Row>
						<Col sm="12">
							<div className="mb-3">
								<Label htmlFor="note">Note</Label>
								<Input
									type="textarea"
									name="note"
									id="note"
									value={note}
									onChange={(e) => setNote(e.target.value)}
								/>
							</div>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button 
						color="primary" 
						type="submit"
						disabled={loading}
					>
						Submit
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)
}
MerchantCreateNote.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	onCreate: PropTypes.func,
	merchantId: PropTypes.string,
	note: PropTypes.any,
	title: PropTypes.any,
}
export default MerchantCreateNote