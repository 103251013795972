import React, { useState, useEffect, useRef } from 'react'
import { Button, Row, Col, Card, CardBody } from 'reactstrap'
import { withRouter } from "react-router-dom"
import { VehicleBrandList, VehicleMasterDataQuery, VehicleRentalsAirportQuery, VehicleRentalsQuery } from "gql/query"
import { useLazyQuery } from "@apollo/client"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from "react-icons/go"
import PropTypes from "prop-types"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { useSelector } from "react-redux"
import { SelectPicker } from "rsuite"
import Loader from "../../../components/Loader/Loader"
import BootstrapTable from "react-bootstrap-table-next"
 import moment from "moment"
import { HtmlToExcel } from 'pages/Common/HtmlToExcel'
import { useMutation } from "@apollo/client"
import Swal from "sweetalert2"
import axios from "axios"
import { Delete_master_vehicle_data, Delete_master_vehicle_rentals_data } from 'gql/Mutation'
import EcommerceRentalsMasterDataFilter from 'components/Filters/Ecommerce/EcommerceRentalsMasterDataFilter'
import { BuildRentalsAirportFilterQuery } from './airport-rentals-.functions'
import { BikesHtmlToExcelHeaders, CarsHtmlToExcelHeaders } from './columns'
import EcomerceRentalVehiclesFilter from 'components/Filters/Ecommerce/EcommerceRentalsVehiclesFilter'
import AirportRentalsCreate from './airport-rentals-create'
import AirportRentalsFilter from 'components/Filters/Ecommerce/EcommercAirportRentals'
// import AirportRentalsCreate from './airport-rentals-create'
 


const FormatMasterData = (data) => {

    console.log("airportdata",data)

    const airports = data?.rental_airports?.map((elem) => {
         return {
            id: elem?.id,
            address: elem?.address,
            name:elem?.name,
            active: elem?.active,
            state: elem?.state,
            created_at: elem?.created_at,
            updated_at: elem?.updated_at,
        }
    })
    return airports
}

const AirportRentalsList = (props) => {
    const { capabilities } = useSelector((state) => state.Login)
    const [queryCondition, setQueryCondition] = useState({})
    const [brands, setMasterData] = useState([])
    const [modal, setModal] = useState(false)
    const [brandModalOpen, setBrandOpen] = useState(false)
    const [selectedRentals, setselectedRentals] = useState({})
    const [PageData, setPageData] = useState({ limit: 10, pageNo: 1 })
    const [deleteVehicleRentalsData] = useMutation(Delete_master_vehicle_rentals_data)
    const [filterValue, setFilterValue] = useState("")

    //Open new tab if clicks on an image
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    

    var node = useRef()
    const limitData = [
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "200", value: 200 },
        { label: "500", value: 500 },
    ]
    const defaultSorted = []

    const VehicleBrandColumns = (toggleModal) => [
        {
            text: "ID",
            dataField: "id",
            sort: false,
        },
        {
            text: "Name",
            dataField: "name",
            sort: true,
        },

        {
            text: "Address",
            dataField: "address",
            sort: true,
        },

        {
            text: "State",
            dataField: "state",
            sort: true,
        },
 
        {
            text: "Active",
            dataField: "active",
            sort: true,
        },



        {
            text: "Created At",
            dataField: "created_at",
            sort: true,
            formatter: (cellContent, row) =>
            moment(row.created_at).format("DD/MM/YYYY h:mm A"),
        },

        {
            text: "Updated At",
            dataField: "updated_at",
            sort: true,
            formatter: (cellContent, row) =>
            moment(row.created_at).format("DD/MM/YYYY h:mm A"),
        },
        
 

        {
            dataField: "view",
            isDummyField: true,
            text: "Action",
            sort: false,
            hidden: !capabilities.includes("edit"),
            formatter: (cellContent, row) => (
                <Dropdown>
                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                        <GoKebabVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => {
                                setselectedRentals(row)
                                setBrandOpen(true)
                            }}
                        >
                            Edit
                        </Dropdown.Item>
                    <Dropdown.Item
                            onClick={() => {
                                mutateRecord(row?.id)
                            }}
                        >
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ),
        },
    ]



    const mutateRecord=async(id)=>{
 
       const result =  await deleteVehicleRentalsData({variables:{"id":id}})
  
            if(result?.data) return Swal.fire( "Deleted!", "", "success" )
            
            Swal.fire( "Not Deleted. Contact Admin!", "", "success" )

     }

    const handleSearch = (e) => {
        let value = e.target.value
        if (value.length >= 2) {
            let conditionTemp = {}
            // if (props.queryCondition !== null) {
            //     conditionTemp = { ...props.queryCondition }
            // }
            conditionTemp = { _or : [
                {name:  {_ilike: `%${value}%`}},
                {state:  {_ilike: `%${value}%`}},
            ]

            }
                // ...conditionTemp,
               
                //     ...{name:  {_ilike: `%${value}%`}},
                //     ...{state:  {_ilike: `%${value}%`}},
            
            
            setQueryCondition(conditionTemp)
        } else {
            let conditionTemp = {}
            if (props.queryCondition !== null) {
                conditionTemp = { ...props.queryCondition }
            }
            setQueryCondition(conditionTemp)
            refetch()
        }
    }
    const PageDown = () => {
        if (PageData.pageNo > 0)
            setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
    }
    const PageUp = () => {
        setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
    }
    const toggle = () => {
        setModal(!modal)
    }
    const handleTableChange = (type, { page, searchText }) => { }
    const [getAirportRentalsList, { loading, data, refetch, error }] = useLazyQuery(
        VehicleRentalsAirportQuery,
        {
            skipCache: true,

            variables: { limit: PageData.limit,
                 offset: (PageData.pageNo - 1) * PageData.limit, where: {_or:queryCondition}
                 },
        }
    )

    
    useEffect(() => {
        let conditionTemp = BuildRentalsAirportFilterQuery(filterValue, props.queryCondition)
        setQueryCondition(conditionTemp)
      }, [filterValue])

     useEffect(() => {
        if (props.queryCondition !== null) {
            setQueryCondition(props.queryCondition)
        } else {
            getAirportRentalsList()
        }
    }, [props.queryCondition])
    useEffect(() => {
        if (queryCondition&&Object.keys(queryCondition).length) {
            getAirportRentalsList()
        } else {
            getAirportRentalsList()
        }
    }, [queryCondition])
    useEffect(() => {
        if (data) {
            setMasterData(FormatMasterData(data))
        }
    }, [data])

    console.log("adasdfadsftata",data,error,)

    const count = data?.rental_airports?.aggregate?.count || 0
    const maxPage = Math.ceil(count / PageData.limit)

    return (
        <>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <ToolkitProvider
                                keyField="id"
                                data={brands || []}
                                columns={VehicleBrandColumns(toggle)}
                                bootstrap4
                                search
                            >
                                {(toolkitProps) => (
                                    <React.Fragment>
                                        <Row className="mb-2">
                                            <Col sm="4">
                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault()
                                                            }}
                                                        >
                                                            <Form.Group
                                                                className="mb-3"
                                                                controlId="formBasicEmail"
                                                            >
                                                                <i className="bx bx-search-alt search-icon" />
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Search Airports"
                                                                    onChange={handleSearch}
                                                                />
                                                            </Form.Group>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </Col>

                                            
                                            {/* // {props.showCreateBtn && capabilities.includes("create") && ( */}
                                                <Col sm="8">
                                                    <div className="text-sm-end d-flex justify-content-end">
                                                    <Button className="m-1"
                                                            color="primary"
                                                            onClick={() => setBrandOpen(true)}
                                                        >
                                                            <GoPlus style={{ marginRight: 5 }} />
                                                            Create Airport Rentals
                                                        </Button>
                                                     <AirportRentalsFilter 
                                                                onFilterChange={setFilterValue}
                                                                activeFilters={props.activeFilters}
                                                                filterQueryConditions={props.filterQueryConditions}
                                                        /> 



                                                    </div>
       
                                                </Col>
                                            {/* )} */}
                                        </Row>
                                        <Row>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <SelectPicker
                                                    data={limitData}
                                                    searchable={false}
                                                    style={{ width: 100 }}
                                                    placeholder="Limit"
                                                    value={PageData.limit}
                                                    onChange={(value) =>
                                                        setPageData({ ...PageData, limit: value })
                                                    }
                                                    cleanable={false}
                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col xl="12">
                                                <div className="table-responsive">
                                                    {loading ? (
                                                        <Loader />
                                                    ) : (
                                                        <BootstrapTable
                                                            responsive
                                                            bordered={false}
                                                            striped={false}
                                                            defaultSorted={defaultSorted}
                                                            classes={"table align-middle table-nowrap"}
                                                            keyField="id"
                                                            {...toolkitProps.baseProps}
                                                            onTableChange={handleTableChange}
                                                            ref={node}
                                                            noDataIndication={() => "No Categories Found!"}
                                                        />
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-40">
                                            <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                                                <Button
                                                    disabled={PageData.pageNo <= 1}
                                                    onClick={() => {
                                                        PageDown()
                                                    }}
                                                >
                                                    Previous Page
                                                </Button>
                                                <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}</div>
                                                <Button
                                                    disabled={PageData.pageNo >= maxPage}
                                                    onClick={() => {
                                                        PageUp()
                                                    }}
                                                >
                                                    Next Page
                                                </Button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
             {/* {capabilities.includes("create") && ( */}
                <AirportRentalsCreate
                    open={brandModalOpen}
                    close={() => {

                        setselectedRentals({})
                        setBrandOpen(false)
                    }}
                    onSubmit={() => refetch()}
                    masterData={selectedRentals}
                />
            {/* )} */}
        </>
    )
}

AirportRentalsList.propTypes = {
    queryCondition: PropTypes.any,
    activeFilters: PropTypes.array,
    filterQueryConditions: PropTypes.any,
    showCreateBtn: PropTypes.bool,
}

export default withRouter(AirportRentalsList)