import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import axios from "axios"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import {loginSuccess} from "../../../store/auth/login/actions"

// users
import user1 from "../../../assets/images/users/avatar.jpg"

const ProfileMenu = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")

  const [user, setUser] = useState({})

  const fetchProfile = async (token, payload) => {
    try {
      const resp = await axios.get(`${process.env.REACT_APP_SERVICE_BASE_URL}admin/me`, {
        headers: {
          Authorization: token
        }
      })
      let obj ={
        ...payload,
        user: resp.data
      }
      localStorage.setItem("authUser", JSON.stringify(obj))
      dispatch(loginSuccess(resp.data))
    } catch (err) {
      history.push("/logout")
    }
  }
  useEffect(() => {
    let _user = localStorage.getItem("authUser")
    if (_user) {
      _user = JSON.parse(_user)
      fetchProfile(_user?.token?.value, _user)
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user.photo_url || user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{user.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile" disabled={true}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/crypto-wallet" disabled={true}>
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#" disabled={true}>
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen" disabled={true}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            <span>{user.name}</span>
          </div>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
