import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"
//import components
import EcommerceOrdersTable from "../EcommerceOrders/EcommerceOrdersTable"

const CustomerOrders = (props) => {
    const match = useRouteMatch()
    return (
        <div className="p-3">
            <EcommerceOrdersTable
                queryCondition={{ customer_id: { _eq: match?.params?.id } }}
                activeFilters={["status", "store", "created_at", "city", "total_amount", "delivery_charges"]}
                filterQueryConditions={[{filter: "store", condition: {orders: {customer_id: {_eq: match?.params?.id}}}}]}
                showFilters={true}
            />
        </div>
    )
}
CustomerOrders.propTypes = {
    stores: PropTypes.array,
    setSelectedStores: PropTypes.func,
    setActiveTab: PropTypes.func,
    selectedStores: PropTypes.any
}
CustomerOrders.displayName = 'CustomerOrders'
export default CustomerOrders