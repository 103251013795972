import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
    Button,
    Col,
    Row,
} from "reactstrap"
import { CheckPicker } from 'rsuite'
import { useRouteMatch } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
import moment from "moment"
//GraphQL
import { getCustomerPayments } from "gql/query"
import { useQuery, useLazyQuery } from "@apollo/client"

const CustomerPayments = (props) => {
    const isRefundApplicable = (order) => order?.order_status === "cancelled" || order?.order_status === "rejected" || order?.cancelled_amount !== null
    const FormatData = (data) => {
        const payments = data.payments.map(elem => {
			return {
				id: elem.id,
                amount: elem.amount/100,
                status: elem.status,
                externaltransactionid: elem.externaltransactionid,
                payment_type: elem.payment_type,
                payment_gateway: elem.payment_gateway,
                pg_commission: elem.pg_commission,
                bankcode: elem.bankcode,
                refund_applicable: isRefundApplicable(elem.order),
                refunded: elem.refunded,
                refunded_amount: elem.refunded_amount/100,
                created_at: elem.created_at
			}
		})
		return payments
    }
    const PromoCodesColumn = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			dataField: "amount",
			text: "Amount",
			sort: false,
            formatter: (cellContent, row) => `₹${cellContent}`,
		},
        {
			dataField: "status",
			text: "Status",
			sort: false,
		},
		{
			dataField: "externaltransactionid",
			text: "Transaction ID",
			sort: true,
		},
		{
			dataField: "payment_type",
			text: "Payment Type",
			sort: true,
		},
		{
			text: "Payment Gateway",
			dataField: "payment_gateway",
			sort: true,
		},
		// {
		// 	dataField: "pg_commission",
		// 	text: "Commission",
		// 	sort: true,
        //     formatter: (cellContent, row) => `₹${cellContent}`,
		// },
		{
			text: "Bank Code",
			dataField: "bankcode",
			sort: true,
		},
        {
			text: "Refund Applicable",
			dataField: "refund_applicable",
			sort: false,
            formatter: (cellContent, row) => cellContent ? "Yes" : "No",
		},
		{
			text: "Refunded",
			dataField: "refunded",
			sort: false,
            formatter: (cellContent, row) => row.refund_applicable ? cellContent ? "Yes" : "No" : "N/A",
		},
		{
			text: "Refunded Amount",
			dataField: "refunded_amount",
			sort: false,
            formatter: (cellContent, row) => row.refund_applicable ? `₹${cellContent}` : "N/A",
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(cellContent).format("DD/MM/YYYY h:mm A"),
		}
	]
    const match = useRouteMatch()
    const defaultSorted = []
    const [PageData, setPageData] = useState({
        limit: 10,
        pageNo: 1
    })
    const [queryCondition, setQueryCondition] = useState({customer_id: { _eq: match?.params?.id}})
    const [payments, setPayments] = useState([])

    const PageDown = () => {
        if (PageData.pageNo > 0)
            setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
    }
    const PageUp = () => {
        setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
    }
    const { data, loading, error } = useQuery(getCustomerPayments, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
    const count = data?.payments_aggregate?.aggregate?.count
    const maxPage = Math.ceil(count / PageData.limit)
    useEffect(() => {
        if (data) {
            setPayments(FormatData(data))
        }
    }, [data])
    return (
        <div className="p-3">
            <Row className="mb-5">
                <Row className="mb-2">
                    <Col sm="8">
                        <h4>No. of Payments: {count}</h4>
                    </Col>
                    <Col sm="4">
                        <div className="text-sm-end d-flex justify-content-end">
                            {/* <CheckPicker
                                data={storesData}
                                placeholder="Select Store"
                                style={{ width: 224 }}
                                value={selectedStores}
                                onChange={setSelectedStores}
                                searchable={false}
                            /> */}
                        </div>
                    </Col>
                </Row>
                <hr />
                <ToolkitProvider
                    keyField="id"
                    data={payments}
                    columns={PromoCodesColumn}
                    bootstrap4
                // search
                >
                    {toolkitProps => (
                        <>
                            <Row>
                                <Col xl="12">
                                    <div className="table-responsive">
                                        {loading ? <Loader /> :
                                            <BootstrapTable
                                                responsive
                                                bordered={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                classes={"table align-middle table-nowrap"}
                                                keyField="id"
                                                {...toolkitProps.baseProps}
                                                onTableChange={() => { }}
                                                noDataIndication={() => "No Payments Found!"}
                                            // {...paginationTableProps}
                                            />
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-md-center mt-40">
                                <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                                    <Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
                                        Previous Page
                                    </Button>
                                    <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
                                    </div>
                                    <Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
                                        Next Page
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </ToolkitProvider>
            </Row>
        </div>
    )
}
CustomerPayments.propTypes = {
    stores: PropTypes.array,
    setSelectedStores: PropTypes.func,
    selectedStores: PropTypes.any
}
CustomerPayments.displayName = 'CustomerPayments'
export default CustomerPayments