import React from "react"
import { Col, Label } from "reactstrap"
import TypeHead from "./Typehead"
import { CheckPicker, TagInput } from "rsuite"
import PropTypes from "prop-types"
import Map from "./GoogleMap"

export const BuildFields = ({
  naming,
  field,
  data,
  EnumData,
  masterData,
  setMasterData,
  ind,
  setCoordinates,
  onChangeHandler,
}) => {
  let title = field?.name?.replaceAll("_", " ")
  title = title?.replaceAll("_id", "")

  title = camalize(title)?.charAt(0).toUpperCase() + title?.slice(1)

  switch (field?.type) {
    case "img":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <input
              type="file"
              key={title}
              data-index={ind}
              data-parent={naming}
              data-type={field.type}
              data-single={field?.multi ? "true" : "false"}
              multiple={field?.multi ? "multiple" : "null"}
              data-name={field?.name}
              name={field?.value}
              id={field?.name}
            />
            {field?.value && !field?.multi ? (
              <img
                alt="no image"
                className="absolute inset-0 w-full h-full object-cover object-center"
                src={field?.value}
                onClick={() => {
                  openInNewTab(field?.value)
                }}
                style={{ width: "30px", cursor: "pointer" }}
              />
            ) : (
              field?.value?.map((e, i) => {
                return (
                  <span key={i} style={{ position: "relative", margin: "5px" }}>
                    <img
                      alt="no image"
                      key={i}
                      className="absolute inset-0 w-full h-full object-cover object-center "
                      src={e}
                      onClick={() => {
                        openInNewTab(e)
                      }}
                      style={{ width: "30px", cursor: "pointer" }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: "-20%",
                        top: "30%",
                      }}
                    >
                      <button
                        type="button"
                        data-parent={naming}
                        data-index={i}
                        style={{ fontSize: "10px" }}
                        className="btn-close"
                        onClick={(e) => {
                          removeImage(e)
                        }}
                        aria-label="Close"
                      >
                        {" "}
                      </button>
                    </span>
                  </span>
                )
              })
            )}
          </div>
        </Col>
      )

    case "boolean":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <br />
            <select
              className="form-select"
              key={title}
              type={field.type}
              data-index={ind}
              data-parent={naming}
              data-type={field.type}
              data-name={field?.name}
              name={field?.value}
              id={field?.name}
              defaultValue={field?.value}
              required
              value={field?.value}
              aria-label="Default select example"
            >
              <option value="">Select</option>
              <option value="true">YES</option>
              <option value="false">NO</option>
            </select>
          </div>
        </Col>
      )

    case "select":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <br />
            <select
              className="form-select"
              key={title}
              type={field.type}
              data-index={ind}
              data-parent={naming}
              data-type={field.type}
              data-name={field?.name}
              name={field?.value}
              id={field?.name}
              defaultValue={field?.value}
              value={field?.value}
              required
              aria-label="Default select example"
            >
              {data?.[naming] || EnumData?.[naming]
                ? [
                    { comments: "Select", value: "" },
                    ...(data?.[naming] || EnumData?.[naming]),
                  ]?.map((element, i) => {
                    return (
                      <option key={i} value={element?.value}>
                        {element?.comments}
                      </option>
                    )
                  })
                : null}
            </select>
          </div>
        </Col>
      )

    case "select_picker":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <br />
            <CheckPicker
              key={title}
              data-index={ind}
              data-parent={naming}
              data-type={field.type}
              style={{ width: "100%" }}
              data-name={field?.name}
              name={field?.value}
              id={field?.name}
              required
              value={field?.value}
              appearance="default"
              onChange={(ee) =>
                onChangeHandler({
                  e: {
                    parent: naming,
                    index: ind,
                    name: field?.name,
                    value: ee,
                    element: ee,
                    type: field.type,
                  },
                  masterData,
                  setMasterData,
                })
              }
              aria-label="Default select example"
              data={EnumData?.[naming]?.map((element, i) => ({
                label: element?.comments,
                value: element?.value,
              }))}
            ></CheckPicker>
          </div>
        </Col>
      )
    case "tag":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <br />
            <TagInput
              key={title}
              data-index={ind}
              data-parent={naming}
              data-type={field.type}
              style={{ width: "100%" }}
              data-name={field?.name}
              required
              name={field?.value}
              id={field?.name}
              value={field?.value}
              appearance="default"
              onChange={(ee) =>
                onChangeHandler({
                  e: {
                    parent: naming,
                    index: ind,
                    name: field?.name,
                    value: ee,
                    element: ee,
                    type: "tag",
                  },
                  masterData,
                  setMasterData,
                })
              }
              aria-label="Default select example"
            ></TagInput>
          </div>
        </Col>
      )

    case "map":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <br />
            <Map
              key={field.name}
              coordinates={field?.value?.coordinates}
              setCoordinates={setCoordinates}
              field={field?.name}
            />
          </div>
        </Col>
      )
    case "number":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <input
              className="form-control"
              type={field.type}
              key={title}
              data-index={ind}
              required
              data-parent={naming}
              data-type={field.type}
              data-name={field?.name}
              id={field?.name}
              value={field?.value}
            />
          </div>
        </Col>
      )
    case "typehead":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <TypeHead
              className="form-control"
              type={field.type}
              key={title}
              data-index={ind}
              data-parent={naming}
              data-type={field.type}
              data-name={field?.name}
              entity={field?.entity}
              query={field?.query}
              acessLabel={field?.acessLabel}
              acessValue={field?.acessValue}
              value={field?.value}
              label={field?.label}
              sub={field?.sub}
              onSelects={(result) => {
                console.log("checking result is", result)
                return onChangeHandler({
                  e: {
                    parent: naming,
                    index: ind,
                    name: field?.name,
                    element: result?.id,
                    type: field.type,
                    label: result?.label,
                    value: result?.value,
                  },
                  masterData,
                  setMasterData,
                })
              }}
              id={field?.name}
              defaultValue={field?.value}
            />
          </div>
        </Col>
      )
    case "date":
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <input
              className="form-control"
              type={field.type}
              key={title}
              data-index={ind}
              data-parent={naming}
              required
              data-type={field.type}
              data-name={field?.name}
              id={field?.name}
              defaultValue={field?.value}
            />
          </div>
        </Col>
      )
    default:
      return (
        <Col sm={field?.span}>
          <div className="mb-3">
            <Label htmlFor={field?.name}>{title}</Label>
            <input
              className="form-control"
              type={field.type}
              key={title}
              data-index={ind}
              required
              data-parent={naming}
              data-type={field.type}
              data-name={field?.name}
              id={field?.name}
              value={field?.value}
            />
          </div>
        </Col>
      )
  }
}

BuildFields.propTypes = {
  naming: PropTypes.any,
  field: PropTypes.any,
  ind: PropTypes.any,
  data: PropTypes.any,
  EnumData: PropTypes.any,
  setCoordinates: PropTypes.any,
  onChangeHandler: PropTypes.any,
  masterData: PropTypes.any,
  setMasterData: PropTypes.any,
}

export const camalize = (str) => {
  return str
    ?.toLowerCase()
    ?.replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr?.toUpperCase()
    })
}

//Open new tab if clicks on an image
const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer")
  if (newWindow) newWindow.opener = null
}
