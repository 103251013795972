import { gql } from "@apollo/client"

//=======PRODUCT MUTATIONS START========//
export const Insert_Product_One = gql`
  mutation Insert_Product_One($object: products_insert_input!) {
    insert_products_one(object: $object) {
      id
    }
  }
`
export const UpdateMasterProduct = gql`
  mutation UpdateMasterProduct($id: uuid!, $object: master_products_set_input) {
    update_master_products_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
export const UpdateProduct = gql`
  mutation UpdateProduct($id: uuid!, $object: products_set_input) {
    update_products_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======PRODUCT MUTATIONS END========//

//=======MERCHANT MUTATIONS START========//
export const CreateMerchantNote = gql`
  mutation CreateMerchantNote($object: merchant_notes_insert_input!) {
    insert_merchant_notes_one(object: $object) {
      id
    }
  }
`
export const UpdateMerchantNote = gql`
  mutation UpdateMerchantNote($id: uuid!, $object: merchant_notes_set_input) {
    update_merchant_notes_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
export const DeleteMerchantNote = gql`
  mutation DeleteMerchantNote($id: uuid!) {
    delete_merchant_notes_by_pk(id: $id) {
      id
    }
  }
`
export const Insert_Master_Product_One = gql`
  mutation Insert_Master_Product_One($object: master_products_insert_input!) {
    insert_master_products_one(object: $object) {
      id
    }
  }
`
//=======MERCHANT MUTATIONS END========//

//=======BRAND MUTATIONS START=========//
export const Insert_Brand_One = gql`
  mutation Insert_Brand_One($object: product_brand_insert_input!) {
    insert_product_brand_one(object: $object) {
      id
    }
  }
`
export const Update_Brand_One = gql`
  mutation Update_Brand_One($id: uuid!, $object: product_brand_set_input) {
    update_product_brand_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======BRAND MUTATIONS END=========//

//=======CATEGORY MUTATIONS START=========//
export const Insert_Category_One = gql`
  mutation Insert_Category_One($object: categories_insert_input!) {
    insert_categories_one(object: $object) {
      id
    }
  }
`
export const Update_Category_One = gql`
  mutation Update_Category_One($id: uuid!, $object: categories_set_input) {
    update_categories_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======CATEGORY MUTATIONS END=========//

//=======SUB CATEGORY MUTATIONS START=========//
export const Insert_Sub_Category_One = gql`
  mutation Insert_Sub_Category_One($object: subcategories_insert_input!) {
    insert_subcategories_one(object: $object) {
      id
    }
  }
`
export const Update_Sub_Category_One = gql`
  mutation Update_Sub_Category_One(
    $id: uuid!
    $object: subcategories_set_input
  ) {
    update_subcategories_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======SUB CATEGORY MUTATIONS END=========//

//=======SERVICE CATEGORY MUTATIONS START=========//
export const Insert_Service_Category_One = gql`
  mutation Insert_Service_Category_One(
    $object: service_categories_insert_input!
  ) {
    insert_service_categories_one(object: $object) {
      id
    }
  }
`
export const Update_Service_Category_One = gql`
  mutation Update_Service_Category_One(
    $id: uuid!
    $object: service_categories_set_input
  ) {
    update_service_categories_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
export const Insert_Sub_Service_One = gql`
  mutation insert_master_services_one($object: master_services_insert_input!) {
    insert_master_services_one(object: $object) {
      id
    }
  }
`
export const Update_Sub_Service_One = gql`
  mutation update_master_services_one(
    $id: uuid!
    $object: master_services_set_input
  ) {
    update_master_services_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======Service MUTATIONS END=========//

//=======TAXES MUTATIONS START=========//
export const UpdateProductTax = gql`
  mutation UpdateProductTax(
    $id: uuid!
    $object: store_product_taxes_set_input
  ) {
    update_store_product_taxes_by_pk(
      pk_columns: { nonmrp_product_id: $id }
      _set: $object
    ) {
      nonmrp_product_id
    }
  }
`
//=======TAXES MUTATIONS END=========//

//======= REQUESTS MUTATIONS START=========//
export const UpdateProductRequest = gql`
  mutation UpdateProductRequest(
    $id: uuid!
    $object: product_requests_set_input
  ) {
    update_product_requests_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
export const Insert_Supplier_One = gql`
  mutation Insert_Supplier_One($object: material_suppliers_insert_input!) {
    insert_material_suppliers_one(object: $object) {
      id
    }
  }
`
//======= REQUESTS MUTATIONS END===========//

//======= STORES MUTATIONS START===========//
export const UpdateStore = gql`
  mutation UpdateStore($id: uuid!, $object: stores_set_input) {
    update_stores_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//======= STORES MUTATIONS END===========//

//=======COMMISSION MUTATIONS START=========//
export const Insert_Commission_One = gql`
  mutation Insert_Commission_One($object: commissions_insert_input!) {
    insert_commissions_one(object: $object) {
      id
    }
  }
`
export const Update_Commission_One = gql`
  mutation Update_Commission_One($id: uuid!, $object: commissions_set_input) {
    update_commissions_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======COMMISSION MUTATIONS END=========//

//=======ORDER MUTATIONS START============//
export const UpdateOrder = gql`
  mutation UpdateOrder($id: uuid!, $object: orders_set_input) {
    update_orders_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
export const CreateOrderItems = gql`
  mutation CreateOrderItems($objects: [order_items_insert_input!]!) {
    insert_order_items(objects: $objects) {
      affected_rows
    }
  }
`
export const UpdateOrderItems = gql`
  mutation UpdateOrderItems($id: uuid!, $object: order_items_set_input) {
    update_order_items_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======ORDER MUTATIONS END============//

//=======PROMOTION MUTATIONS START========//
export const UpdatePromotion = gql`
  mutation UpdatePromotion($id: Int!, $object: promotions_set_input) {
    update_promotions_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
export const Insert_Promotion_One = gql`
  mutation Insert_Promotion_One($object: promotions_insert_input!) {
    insert_promotions_one(object: $object) {
      id
    }
  }
`
//=======PROMOTION MUTATIONS END==========//

//=======ADMIN MUTATIONS START============//
export const UpdateAdminStatus = gql`
  mutation UpdateAdminStatus($id: uuid!, $status: Boolean) {
    update_admins_by_pk(pk_columns: { id: $id }, _set: { active: $status }) {
      id
    }
  }
`
//=======ADMIN MUTATIONS END=============//

//=======Vehicle Brand MUTATIONS START=========//
export const Insert_Vehicle_Brand_One = gql`
  mutation Insert_Vehicle_Brand_One(
    $object: rental_vehicle_brands_insert_input!
  ) {
    insert_rental_vehicle_brands_one(object: $object) {
      id
    }
  }
`
export const Update_Vehicle_Brand_One = gql`
  mutation Update_Vehicle_Brand_One(
    $id: uuid!
    $object: rental_vehicle_brands_set_input
  ) {
    update_rental_vehicle_brands_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

//========Vehicle Master Data=====//

export const Insert_vehicle_master_data_One = gql`
  mutation insert_rental_master_vehicles_ones(
    $object: rental_master_vehicles_insert_input!
  ) {
    insert_rental_master_vehicles_one(object: $object) {
      id
    }
  }
`

export const Update_vehicle_master_data_One = gql`
  mutation update_rental_master_vehicles_by_pk(
    $id: uuid!
    $object: rental_master_vehicles_set_input
  ) {
    update_rental_master_vehicles_by_pk(
      pk_columns: { id: $id }
      _set: $object
    ) {
      id
    }
  }
`

export const Delete_master_vehicle_data = gql`
  mutation MyMutation($id: uuid!) {
    delete_rental_master_vehicles_by_pk(id: $id) {
      id
    }
  }
`

export const Delete_master_vehicle_rentals_data = gql`
  mutation MyMutation($id: uuid!) {
    delete_rental_vehicle_types_by_pk(id: $id) {
      id
    }
  }
`

// export const DeleteStoreByIdMutation =
//   gql`mutation DeleteStoreById(\$id: uuid!) {
//     delete_stores_by_pk(id: \$id) {
//       id
//     }
//   }
// `

//============Vehicle brand===========
export const Delete_vehicle_brand_data = gql`
  mutation MyMutation($id: uuid!) {
    delete_rental_vehicle_brands_by_pk(id: $id) {
      id
    }
  }
`

// ===== Rental vehicles =======
export const Insert_Create_Vehicle_Rental = gql`
  mutation insert_rental_vehicles_insert_ones(
    $object: rental_vehicles_insert_input!
  ) {
    insert_rental_vehicles_one(object: $object) {
      id
    }
  }
`
export const Update_vehicle_Rental = gql`
  mutation update_rental_vehicles_by_pk(
    $id: uuid!
    $object: rental_vehicles_set_input
  ) {
    update_rental_vehicles_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

// export const Insert_vehicle_master_data_One =  gql`

//      mutation insert_rental_master_vehicles_ones(
//     $object: rental_master_vehicles_insert_input!
//     ){
//       insert_rental_master_vehicles_one(object:$object){
//         id
//       }
// }
// `

// export const Update_vehicle_master_data_One = gql`
//   mutation update_rental_master_vehicles_by_pk(
//     $id: uuid!
//     $object:  rental_master_vehicles_set_input
//   ) {
//     update_rental_master_vehicles_by_pk(pk_columns: { id: $id }, _set: $object) {
//       id
//     }
//   }
// `

//---- Rental Air ports

export const Insert_Create_AirPort_Rental = gql`
  mutation insert_rental_airports_insert_ones(
    $object: rental_airports_insert_input!
  ) {
    insert_rental_airports_one(object: $object) {
      id
    }
  }
`
export const Update_AirPort_Rental = gql`
  mutation update_rental_airports_by_pk(
    $id: Int!
    $object: rental_airports_set_input
  ) {
    update_rental_airports_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

//---- Rental Drivers

export const Insert_Rental_Driver = gql`
  mutation insert_rental_drivers_insert_ones(
    $object: rental_drivers_insert_input!
  ) {
    insert_rental_drivers_one(object: $object) {
      id
    }
  }
`
export const Update_Rental_Driver = gql`
  mutation update_rental_drivers_by_pk(
    $id: uuid!
    $object: rental_drivers_set_input
  ) {
    update_rental_drivers_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

//------ Rental Filters ----------
export const Insert_Rental_Filters = gql`
  mutation insert_rental_filters_insert_ones(
    $object: rental_filters_insert_input!
  ) {
    insert_rental_filters_one(object: $object) {
      id
    }
  }
`
export const Update_Rental_filters = gql`
  mutation update_rental_filters_by_pk(
    $id: Int!
    $object: rental_filters_set_input
  ) {
    update_rental_filters_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

export const Insert_Rental_Vehicle_Type = gql`
  mutation insert_rental_vehcile_types_insert_ones(
    $object: rental_vehicle_types_insert_input!
  ) {
    insert_rental_vehicle_types_one(object: $object) {
      id
    }
  }
`
export const Update_Rental_Vehicle_Type = gql`
  mutation update_rental_vehicle_types_by_pk(
    $id: uuid!
    $object: rental_vehicle_types_set_input
  ) {
    update_rental_vehicle_types_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

export const Delete_Rental_Vehicle_Type = gql`
  mutation MyMutation($id: uuid!) {
    delete_rental_vehicles__types_by_pk(id: $id) {
      id
    }
  }
`

export const Insert_Rental_AddOns = gql`
  mutation insert_rental_addons_insert_ones(
    $object: rental_addons_insert_input!
  ) {
    insert_rental_addons_one(object: $object) {
      id
    }
  }
`
export const Update_Rental_AddOns = gql`
  mutation update_rental_addons_by_pk(
    $id: uuid!
    $object: rental_addons_set_input
  ) {
    update_rental_addons_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

export const Insert_Rental_Kyc = gql`
  mutation insert_kyc_insert_ones($object: kyc_insert_input!) {
    insert_kyc_one(object: $object) {
      id
    }
  }
`
export const Update_rental_Kyc = gql`
  mutation update_kyc_by_pk($id: Int!, $object: kyc_set_input) {
    update_kyc_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`

//=======PROPERTY TYPE MUTATIONS START=========//
export const Insert_PropertyType_One = gql`
  mutation Insert_PropertyType_One($object: property_type_insert_input!) {
    insert_property_type_one(object: $object) {
      id
    }
  }
`
export const Update_PropertyType_One = gql`
  mutation Update_PropertyType_One(
    $id: uuid!
    $object: property_type_set_input
  ) {
    update_property_type_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======PROPERTY TYPE MUTATIONS END=========//

//=======PROPERTY RULES MUTATIONS START=========//
export const Insert_PropertyRules_One = gql`
  mutation Insert_PropertyRules_One($object: property_rules_insert_input!) {
    insert_property_rules_one(object: $object) {
      id
    }
  }
`
export const Update_PropertyRules_One = gql`
  mutation Update_PropertyRules_One(
    $id: uuid!
    $object: property_rules_set_input
  ) {
    update_property_rules_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======PROPERTY RULES MUTATIONS END=========//

//=======PROPERTY AMENITIES MUTATIONS START=========//
export const Insert_PropertyAmenities_One = gql`
  mutation Insert_PropertyAmenities_One(
    $object: property_amenities_insert_input!
  ) {
    insert_property_amenities_one(object: $object) {
      id
    }
  }
`
export const Update_PropertyAmenities_One = gql`
  mutation Update_PropertyAmenities_One(
    $id: uuid!
    $object: property_amenities_set_input
  ) {
    update_property_amenities_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======PROPERTY RULES MUTATIONS END=========//

//=======EXPERIENCE TYPE MUTATIONS START=========//
export const Insert_ExperienceType_One = gql`
  mutation Insert_ExperienceType_One(
    $object: experience_type_insert_input!
  ) {
    insert_experience_type_one(object: $object) {
      id
    }
  }
`
export const Update_ExperienceType_One = gql`
  mutation Update_ExperienceType_One(
    $id: uuid!
    $object: experience_type_set_input
  ) {
    update_experience_type_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`
//=======EXPERIENCE TYPE MUTATIONS END=========//
