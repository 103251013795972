import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
 import {
  Container,
  Row,
  Col

} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
  
//i18n
import { withTranslation } from "react-i18next"
 
 
const Map = (props) => {

  const [mapInstance, setMapInstance] = useState(null)
  const [hasPermission, setHaspermission] = useState() 

  const [cord, setCord] = useState({lat:"12.9073152", lng:"77.6470528"})

  useEffect(()=>{

    if(props?.coordinates?.[0] && props?.coordinates?.[1]){

      setCord({lat:props?.coordinates?.[0], lng:props?.coordinates?.[1]})

    }
    
  },[props?.coordinates?.[0] || props?.coordinates?.[1]])
 
 
  //Fetch Current Location 
  const fetchCurrentLocation = async () => {
    return new Promise(async (resolve, reject) => {
      await navigator.geolocation.getCurrentPosition(
        async (position) => {
          let lat = position.coords.latitude,
            lng = position.coords.longitude
          resolve({
            lat,
            lng,
          })
        },
        (err) => reject(err)
      )
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      })
      setHaspermission(permissionStatus.state)
    })
  }

  // setCord({lat:center.lat, lng:center.lng})

  console.log("k jllnlnlk", "kj k  k kj k k kj kj",cord)

 
  useEffect(()=>{

    
    const mapOptions = {
      zoom: 14,
      center: new window.google.maps.LatLng(
        parseFloat(cord.lat),
        parseFloat(cord.lng)
      ),
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      draggable: true,
    }


    const map = new window.google.maps.Map(
      document.getElementById("map_canvas"),
      mapOptions,
      
    )

    const marker = new google.maps.Marker({
        position: {     lat:    parseFloat(cord.lat),
                        lng:   parseFloat(cord.lng)
                  },
        map: map,
        draggable:true
      })


     google.maps.event.addListener(marker, 'dragend',
      function(marker) {
        var latLng = marker.latLng
        props.setCoordinates(props?.field,[latLng.lat(), latLng.lng()])
      }
    )
    // setMapInstance(map)


  }, [cord])

 


 

  // const fetchLocation = async () =>{

  //   const center = await fetchCurrentLocation()

  //   console.log("COrdinateasdasd",center,props,props?.coordinates,props?.coordinates?.coordinates?.[0], props,props?.coordinates?.coordinates?.[1])

  //   setCord({ 
  //             lat:  props?.coordinates?.[0] ||   center?.lat, 
  //             lng:  props?.coordinates?.[1] ||   center?.lng
  //          })

  // }



  // useEffect(()=>{

  //   fetchLocation()

  // },[])

 
  
  return (

 
 
     
 
          <Row>
              <Col sm="12">
                <div id="map_canvas" className={`${hasPermission == "denied" ? "d-none" : ""}`}></div>
              </Col>
              {hasPermission == "denied" && (
              <Col
                sm="12"
                className="d-flex justify-content-center align-items-center"
                style={{ height: 400 }}
              >
                <div>
                  <p className="bg-warning text-dark p-2">
                    Enable Location permission to view Map!
                  </p>
                </div>
              </Col>
            )}
          </Row>
 

  )
}

Map.propTypes = {
    setCoordinates: PropTypes.any,
    coordinates:PropTypes.any,
    field:PropTypes.anys
}

export default withTranslation()(React.memo(Map))
