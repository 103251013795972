import { VehicleRentalMasterVehiclesTypehead, VehicleRentalStoresTypehead, VehicleRentalVehicleBarndsTypehead, VehicleRentalsCategoriesTypehead, VehicleTypesTypehead, VehicleUsersTypehead } from "gql/query"


export const Columns = {

                 driver_name   : {name:"driver_name", span:3,type:"text", },
                         phone : {name:"phone", span:3,type:"text", },
                       active  : {name:"active", span:3,type:"boolean", },
                        email  : {name:"email", span:3,type:"text", },
                          dob  : {name:"dob", span:3,type:"date" },
                //  dedicated_by  : {name:"dedicated_by", span:3,type:"text" },
                    //  store_id  : {name:"store_id", span:3,type:"text" },
                    //  kyc_data  : {name:"kyc_data", span:3,type:"text" },
                    //  created_by  : {name:"created_by", span:3,type:"text" },
                    //  updated_by  : {name:"updated_by", span:3,type:"text" },

                     store_id :  {name:"store_id", 
                                  access:"store", 
                                  span:3,
                                  type:"typehead", 
                                  entity:"store", 
                                  query :VehicleRentalStoresTypehead,
                                  acessLabel:"name",
                                  acessValue : "id"
                                 },


                        //   created_by  : {name:"created_by", span:24,type:"text" },
                        //   kyc_data  : {name:"kyc_data", span:24,type:"text" },
                

 
         }



