import React, { useState } from "react"
import { Modal } from "rsuite"
import PropTypes from 'prop-types'
import {
    Button,
    Col,
    Form,
    Input,
    Label,
    Row
} from "reactstrap"
import axios from "axios"
import Swal from "sweetalert2"

const MerchantPushNotificationModal = (props) => {
    const [state, setState] = useState({
        title: "",
        message: ""
    })
    const [loading, setLoading] = useState(false)
    const handleSendPushNotification = async (e) => {
        e.preventDefault()
        try {
            const token = localStorage.getItem("TOKEN")
            await axios({
                url: `${process.env.REACT_APP_SERVICE_BASE_URL}notification/admin/notify`,
                method: 'post',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    notification_token: props.merchant.notification_token,
                    title: state.title,
                    message: state.message,
                    type: "OTHER",
                    payload: {},
                    role: "provider",
                    user_id: props.merchant.id
                },
                responseType: "arraybuffer"
            })
            Swal.fire("Notification Sent Successfully!", "", "success").then(() => {
                props.close()
                setState({
                    title: "",
                    message: ""
                })
            })
        } catch (err) {
            Swal.fire("Something Went Wrong!", "", "error")
        }
    }
    return (
        <Modal open={props.open} onClose={() => props.close()}>
            <Modal.Header>
                <Modal.Title>Send Push Notification</Modal.Title>
            </Modal.Header>
            <Form style={{ maxWidth: "90%" }} onSubmit={handleSendPushNotification}>
                <Modal.Body style={{ overflow: "hidden" }}>
                    <Row>
                        <Col sm="12">
                            <div className="mb-3">
                                <Label htmlFor="pushTitle">Title</Label>
                                <Input
                                    type="text"
                                    name="title"
                                    id="pushTitle"
                                    value={state.title}
                                    onChange={(e) => setState({ ...state, title: e.target.value })}
                                    required
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div className="mb-3">
                                <Label htmlFor="message">Message</Label>
                                <Input
                                    type="textarea"
                                    name="message"
                                    id="message"
                                    value={state.message}
                                    onChange={(e) => setState({ ...state, message: e.target.value })}
                                    required
                                />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >
                        Send
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
MerchantPushNotificationModal.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    merchant: PropTypes.any
}
export default MerchantPushNotificationModal