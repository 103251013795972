import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
 import VehicleRentalsList from "./vehicle-rentals-list"
//import "../../../../assets/scss/custom.scss"

const VehicleRentals = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Rental Vehicles| EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Rentals" breadcrumbItem="Rental Vehicles" />
              <VehicleRentalsList queryCondition={null} activeFilters={["type_name"]} filterQueryConditions={null} showCreateBtn={true} />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}

VehicleRentals.propTypes = {}
export default withRouter(VehicleRentals)
