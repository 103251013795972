import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { useRouteMatch, useHistory, Link } from "react-router-dom"
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { CheckPicker } from 'rsuite'
import Loader from "components/Loader/Loader"
import { Dropdown } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'

const TopBookings = (props) => {
	const { ExportCSVButton } = CSVExport
	const history = useHistory()
	const [products, setProducts] = useState([])
	const FormatData = (data) => {
		const productsTemp = data.map(elem => {
			return {
				item_name: elem?.service_name,
				item_price: elem.service_price,
				count: elem.count,
				total_price_amount: elem.total_price_amount,
				vendor_discounted_amount: elem.vendor_discounted_amount,
				tax_amount: elem.tax_amount,
				price_includes_tax: elem.price_includes_tax,
				eefind_discounted_amount: elem.eefind_discounted_amount,
				product_id: elem.product_id,
				id: elem.id,
				order_id: elem.order?.id,
				order_uid: elem.order?.uid
			}
		})
		return productsTemp
	}
	const productsColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Booking ID",
			dataField: "order_uid",
			sort: true,
			formatter: (cellContent, row) => {
				if (row.order_id) {
					return <Link to={`/ecommerce-orders/details/${row.order_id}`}>{cellContent}</Link>
				} else {
					return cellContent
				}
			}
		},
		{
			text: "Service Name",
			dataField: "item_name",
			sort: true,
		},
		{
			dataField: "item_price",
			text: "Service Price",
			sort: true,
			formatter: (cellContent, row) => `₹${row.item_price}`,
		},
		{
			dataField: "count",
			text: "Quantity",
			sort: true,
		},
		{
			dataField: "total_price_amount",
			text: "Total Gross Price",
			sort: true,
			formatter: (cellContent, row) => `₹${row.total_price_amount}`,
		},
		// {
		// 	text: "Vendor Discounted Amount",
		// 	dataField: "vendor_discounted_amount",
		// 	sort: true,
		// 	formatter: (cellContent, row) => `₹${row.vendor_discounted_amount}`,
		// },
		// {
		// 	dataField: "tax_amount",
		// 	text: "Tax Amount",
		// 	sort: true,
		// 	formatter: (cellContent, row) => `₹${row.tax_amount}`,
		// },
		// {
		// 	text: "Including Tax",
		// 	dataField: "price_includes_tax",
		// 	sort: true,
		// 	formatter: (cellContent, row) => row.price_includes_tax ? "Yes" : "No",
		// },
		// {
		// 	text: "EEfind Discounted Amount",
		// 	dataField: "eefind_discounted_amount",
		// 	sort: true,
		// 	formatter: (cellContent, row) => `₹${row.eefind_discounted_amount}`,
		// },
	]
	const defaultSorted = []
	const selectRow = {
    mode: "checkbox",
  }
	useEffect(() => {
		if (props.order?.service_items?.length) {
			setProducts(FormatData(props.order.service_items))
		}
	}, [props.order])
	return (
		<div className="p-3">
			<Row>
				<Row className="mb-2">
					<Col sm="8">
						{props.showLabel && (
							<h4>{props.label}</h4>
						)}
					</Col>
					<Col sm="4">
						<div className="text-sm-end d-flex justify-content-end">
						</div>
					</Col>
				</Row>
				<hr />
				<ToolkitProvider
					keyField="id"
					data={products || []}
					columns={productsColumns}
					bootstrap4
					exportCSV={{ onlyExportSelection: true, exportAll: true }}
				// search
				>
					{toolkitProps => (	
						<>
							<Row className="mb-2">
								<Col sm="12">
									<div className="text-sm-end d-flex justify-content-end">
										<ExportCSVButton
											{...toolkitProps.csvProps}
											style={{ border: "1px solid #74788d", marginRight: 10 }}
										>Export</ExportCSVButton>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										{false ? <Loader /> :
											<BootstrapTable
												responsive
												bordered={false}
												striped={false}
												defaultSorted={defaultSorted}
												classes={"table align-middle table-nowrap"}
												keyField="id"
												onTableChange={() => { }}
												noDataIndication={() => "No Services Found!"}
												selectRow={selectRow}
												{...toolkitProps.baseProps}
											// {...paginationTableProps}
											/>
										}
									</div>
								</Col>
							</Row>
						</>
					)}
				</ToolkitProvider>
			</Row>
		</div>
	)
}
TopBookings.propTypes = {
	order: PropTypes.any,
	showLabel: PropTypes.bool,
	label: PropTypes.string
}
TopBookings.displayName = 'TopBookings'
export default TopBookings