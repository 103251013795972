import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter } from "react-router-dom"
import MetaTags from 'react-meta-tags'
import { v4 as uuidv4 } from 'uuid'
import { CheckBoxWrapper } from './EcommerceAddProduct.styles'
import {
  AddProductFormDataQuery,
  InsertMasterProductsOne,
  GetProductByID
} from './EcommerceAddProduct.query'

import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Insert_Product_One } from "gql/Mutation"
import { useMutation, useQuery } from "@apollo/client"
import { controllers } from "chart.js"


const getFormateAllDropdownData = (response) => {
  const { data, loading, error } = response
  if (data && !loading) {
    const categories = data.categories.map(({
      id,
      name,
    }) => (
      {
        id,
        name,
      }
    ))
    const collections = data.collections.map(({
      id,
      name,
    }) => (
      {
        id,
        name,
      }
    ))
    const weightUnits = data.enum_weight_units.map(({
      comments,
      name,
    }, index) => (
      {
        id: name,
        name: comments || name,
      }
    ))
    const productBrand = data.product_brand.map(({
      id,
      name,
    }, index) => (
      {
        id,
        name,
      }
    ))

    return {
      categories,
      collections,
      weightUnits,
      productBrand,
    }
  }

  return {
    categories: [],
    collections: [],
    weightUnits: [],
    productBrand: [],
  }
}




const EcommerceAddProduct = (props) => {
  let productdata = null
  const productId = window.localStorage.getItem("productId")
  const GetProductID = useQuery(GetProductByID, {
    skipCache: true,
    variables: {
      id: productId
    }
  })
  if (GetProductID?.data?.master_products.length) {
    productdata = GetProductID?.data?.master_products[0]
    window.localStorage.setItem("productId", "")
  }
   // console.log(props)
  const FormRef = useRef()
  let FilledData = {}
  // if (!GetProductID.loading && productId) {
  FilledData = {
    productname: productdata?.name ? productdata.name : "",
    shortproductname: productdata?.short_name ? productdata.short_name : "",
    category: productdata?.categories_id ? productdata.categories_id : "",
    collections: productdata?.collection_id ? productdata.collection_id : "",
    weight: productdata?.weight ? productdata.weight : null,
    weightUnits: productdata?.weight_unit ? productdata.weight_unit : "",
    productBrand: productdata?.product_brand.id ? productdata.product_brand.id : "",
    recommended: productdata?.recommended ? productdata.recommended : false,
    productdesc: productdata?.description ? productdata.description : "",
  }
  // }
  // else {
  //   FilledData = {
  //     productname: "",
  //     shortproductname: "",
  //     category: "",
  //     collections: "",
  //     weight: null,
  //     weightUnits: "",
  //     productBrand: "",
  //     recommended: false,
  //     productdesc: "",
  //   }
  // }
   const AddProductFormData = useQuery(AddProductFormDataQuery, {
    skipCache: true,
  })


  const [insertMasterProductsOneMutation] = useMutation(InsertMasterProductsOne)
  const onSaveClick = () => {
    const {
      productname,
      shortproductname,
      category,
      collections,
      weight,
      weightUnits,
      productBrand,
      recommended,
      productdesc,
    } = FilledData
    insertMasterProductsOneMutation({
      variables: {
        object: {
          name: productname,
          short_name: shortproductname,
          categories_id: category,
          collection_id: collections,
          weight,
          weight_unit: weightUnits,
          brand_id: productBrand,
          recommended,
          description: productdesc,
        }
      }
    }).then((res) => {
      if (res?.data?.insert_master_products_one.id) {
        alert("Product Added Succsessfully")
        document.querySelector("#productform").reset()

      }
    })
  }

  const {
    categories,
    collections,
    weightUnits,
    productBrand,
  } = getFormateAllDropdownData(AddProductFormData)

  const handleFieldChange = (event) => {
    let data = event.target.value
    switch (event.target.name) {
      case "productname":
        FilledData = { ...FilledData, productname: data }
        break
      case "shortproductname":
        FilledData = { ...FilledData, shortproductname: data }
        break
      case "category":
        FilledData = { ...FilledData, category: data }
        break
      case "collections":
        FilledData = { ...FilledData, collections: data }
        break
      case "weight":
        FilledData = { ...FilledData, weight: (+data < 0 ? 0 : +data) }
        break
      case "weigth-units":
        FilledData = { ...FilledData, weightUnits: data }
        break
      case "product-brand":
        FilledData = { ...FilledData, productBrand: data }
        break
      case "recommended":
        FilledData = { ...FilledData, recommended: event.target.checked }
        break
      case "productdesc":
        FilledData = { ...FilledData, productdesc: data }
        break
    }
  }
  const ClearDataField = (event) => {
    event.preventDefault()
    FilledData = {
      productname: "",
      shortproductname: "",
      category: "",
      collections: "",
      weight: null,
      weightUnits: "",
      productBrand: "",
      recommended: false,
      productdesc: "",
    }
    document.querySelector("#productform").reset()
  }

  const [selectedFiles, setselectedFiles] = useState([])

  let uuid = uuidv4()
  const AddDataToDb = (event) => {
    event.preventDefault()

    const weight = { weight: "grams" }
    const enumWeight = Object.freeze(weight)
    let object =
    {
      master_product: {
        data: {
          id: uuidv4(),
          collection_id: "f20b5f05-0774-45c4-b33b-35570db121bb",
          categories_id: FilledData.category,
          weight_unit: enumWeight.weight,
          name: FilledData.productname,
          product_brand: {
            data: {
              name: FilledData.manufacturerbrand
            }
          }
        }
      },
      id: uuidv4(),
      store_id: "03d480fa-639b-42bb-86c5-296cc09119a2",
      created_by: "5d184f49-c231-4c64-a0b1-ef1366c94836",
      updated_by: "5d184f49-c231-4c64-a0b1-ef1366c94836",
      price: FilledData.price,
    }
    addData({
      variables: {
        object: object
      }
    })
    document.querySelector("#productform").reset()
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Product | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>
                  <Form ref={FormRef} id="productform">
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">Product Name</Label>
                          <Input
                            id="productname"
                            name="productname"
                            defaultValue={FilledData.productname}
                            type="text"
                            className="form-control"
                            onChange={(e) => handleFieldChange(e)}
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Category</Label>
                          <select type="select" name="category" defaultValue={FilledData.category} className="form-control select2" onChange={(e) => { handleFieldChange(e) }}>
                            <option value="">Select</option>
                            {categories.map((elem, index) => (
                              <option value={elem.id} key={index}>{elem.name}</option>
                            ))
                            }
                          </select>
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Weight</Label>
                          <Input
                            id="weight"
                            name="weight"
                            type="number"
                            defaultValue={FilledData.weight}
                            className="form-control"
                            onChange={(e) => handleFieldChange(e)}
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Product Brand</Label>
                          <select type="select" name="product-brand" defaultValue={FilledData.productBrand} className="form-control select2" onChange={(e) => { handleFieldChange(e) }}>
                            <option value="">Select</option>
                            {productBrand.map((elem, index) => (
                              <option value={elem.id} key={index}>{elem.name}</option>
                            ))
                            }
                          </select>
                        </div>
                        <CheckBoxWrapper className="mb-3">
                          <Label htmlFor="recommended">
                            Recommended
                          </Label>
                          <Input
                            id="recommended"
                            name="recommended"
                            type="checkbox"
                            className="form-control"
                            defaultChecked={FilledData.recommended}
                            onChange={(e) => handleFieldChange(e)}
                          />
                        </CheckBoxWrapper>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="shortproductname">Short Name</Label>
                          <Input
                            id="shortproductname"
                            name="shortproductname"
                            type="text"
                            defaultValue={FilledData.shortproductname}
                            className="form-control"
                            onChange={(e) => handleFieldChange(e)}
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Collection</Label>
                          <select type="select" defaultValue={FilledData.collections} name="collections" className="form-control select2" onChange={(e) => { handleFieldChange(e) }}>
                            <option value="">Select</option>
                            {collections.map((elem, index) => (
                              <option value={elem.id} key={index}>{elem.name}</option>
                            ))
                            }
                          </select>
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Weight Units</Label>
                          <select type="select" defaultValue={FilledData.weightUnits} name="weigth-units" className="form-control select2" onChange={(e) => { handleFieldChange(e) }}>
                            <option value="">Select</option>
                            {weightUnits.map((elem, index) => (
                              <option value={elem.id} key={index}>{elem.name}</option>
                            ))
                            }
                          </select>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Product Description
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control mb-3"
                            id="productdesc"
                            name="productdesc"
                            rows="5"
                            defaultValue={FilledData.productdesc}
                            onChange={(e) => handleFieldChange(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        color="primary"
                        className="btn "
                        onClick={(e) => onSaveClick(FilledData)}
                      >
                        Save Changes
                      </Button>
                      <Button
                        type="submit"
                        color="secondary"
                        className=" "
                        onClick={(e) => ClearDataField(e)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              {/* <Card>
                <CardBody>
                  <CardTitle className="mb-3">Product Images</CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                </CardBody>
              </Card> */}

              {/* <Card>
                <CardBody>
                  <CardTitle>Meta Data</CardTitle>
                  <CardSubtitle className="mb-3">
                    Fill all information below
                  </CardSubtitle>

                  <Form>
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="metatitle">Meta title</Label>
                          <Input
                            id="metatitle"
                            name="productname"
                            type="text"
                            className="form-control"
                          />
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="metakeywords">Meta Keywords</Label>
                          <Input
                            id="metakeywords"
                            name="manufacturername"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </Col>

                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="metadescription">
                            Meta Description
                          </Label>
                          <textarea
                            className="form-control"
                            id="metadescription"
                            rows="5"
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="submit"
                        color="primary"
                        className=""
                      >
                        Save Changes
                      </Button>
                      <Button
                        type="submit"
                        color="secondary"
                        className=""
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(EcommerceAddProduct)
