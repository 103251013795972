import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import Loader from "../../../components/Loader/Loader"
import { isEmpty } from "lodash"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
import MerchantOverview from "./MerchantOverview"
import MerchantStores from "./MerchantStores"
import MerchantOrders from "./MerchantOrders"
import MerchantProducts from "./MerchantProducts"
import MerchantPromotions from "./MerchantPromotions"
import MerchantStaffs from "./MerchantStaffs"
import MerchantNotes from "./MerchantNotes"
import MerchantPayouts from "./MerchantPayouts"
import MerchantTopCustomers from "./MerchantTopCustomers"
//Import custom styles
import "./EcommerceMerchantDetails.style.scss"
const EcommerceMerchantDetails = props => {
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState({})
  const [activeTab, setActiveTab] = useState('overview')
  useEffect(() => {
    const query = new URLSearchParams(props.location.search)
    const tab = query.get('tab')
    if (["overview", "stores", "orders", "products", "promotions", "staffs", "payouts", "requests"].includes(tab)) {
      setActiveTab(tab)
    }
  }, [])
  const pathName = props.location.pathname
  return (
    <div className="EcommerceMerchantDetails">
      <div className="page-content">
        <MetaTags>
          <title>Merchant Details | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Merchants" breadcrumbItem="Merchant Details" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
                    <Tab eventKey="overview" title="Overview">
                      <MerchantOverview setStores={setStores} />
                    </Tab>
                    <Tab eventKey="stores" title="Stores">
                      <MerchantStores
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        pathName={pathName}
                      />
                    </Tab>
                    <Tab eventKey="orders" title="Orders">
                      <MerchantOrders
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        setActiveTab={setActiveTab}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="products" title="Products">
                      <MerchantProducts
                        stores={stores}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="promotions" title="Promotions">
                      <MerchantPromotions
                        stores={stores}
                        setSelectedStores={setSelectedStores}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="staffs" title="Staffs">
                      <MerchantStaffs
                        stores={stores}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="payouts" title="Payouts">
                      <MerchantPayouts
                        stores={stores}
                        selectedStores={selectedStores}
                      />
                    </Tab>
                    <Tab eventKey="requests" title="Requests">
                      {/* lorem ipsum */}
                    </Tab>
                    <Tab eventKey="notes" title="Notes">
                      <MerchantNotes />
                    </Tab>
                    <Tab eventKey="top_customers" title="Top Customers">
                      <MerchantTopCustomers pathName={pathName} />
                    </Tab>
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommerceMerchantDetails.propTypes = {
  location: PropTypes.any
}
export default EcommerceMerchantDetails