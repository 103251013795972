import React, { useEffect, useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import Loader from "../../../components/Loader/Loader"
import { isEmpty } from "lodash"
import { Link, useRouteMatch } from "react-router-dom"
import * as moment from "moment"
import { useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,  
} from "reactstrap"
import { Tabs, Tab } from "react-bootstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//Import components
// import OrderOverview from "./OrderOverview"
// import OrderItems from "./OrderItems"
// import OrderTimeline from "./OrderTimeline"
// import EcommerceReviewsTable from "../EcommerceReviews/EcommerceReviewsTable"
//Import custom styles
import "./EcommerceOrderDetails.style.scss"

//GraphQL
import { getOrderDetail_byId, GetServiceDetailsById } from "gql/query"
import { useQuery } from "@apollo/client"
import SubServices from "./SubServices"
import ServiceItems from "./ServiceItems"
import Promotions from "./Promotions"
import ServiceOverview from "./ServicesOverview"

const EcommerceServiceDetails = props => {
   const match = useRouteMatch()
  const [stores, setStores] = useState([])
  const [selectedStores, setSelectedStores] = useState({})
  const [activeTab, setActiveTab] = useState('overview')
  const [ServiceDetails, setServiceDetails] = useState({})
  const { data, loading, error, refetch } = useQuery(GetServiceDetailsById, {
    skipCache: true,
    variables: {
      id: match?.params?.id
    }
  })
   useEffect(() => {
    if (data?.object) {
      setServiceDetails(data.object)
    }
  }, [data])

 
  return (
    <div className="EcommerceServiceDetails">
      <div className="page-content">
        <MetaTags>
          <title>Service Details | EEFind - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Services" breadcrumbItem="Service Details" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Tabs activeKey={activeTab} onSelect={setActiveTab} className="mb-3">
                    <Tab eventKey="overview" title="Overview">
                      <ServiceOverview
                        data={ServiceDetails}
                        refetch={refetch}
                      />
                    </Tab>
                    <Tab eventKey="sub_services" title="Services">
                      <SubServices 
                        data={ServiceDetails?.sub_services_map}
                      />
                    </Tab>
                    <Tab eventKey="service_items" title="Items">
                      <ServiceItems 
                        data={ServiceDetails?.service_items}
                      />
                    </Tab>
                    <Tab eventKey="promotion_services" title="Promotions">
                      <Promotions 
                        data={ServiceDetails?.promotion_services}
                      />
                    </Tab>

                    
                    {/* <Tab eventKey="service_category" title="Category">
                      <OrderItems
                        order={ServiceDetails}
                        showLabel={true}
                        label={`No. of Items: ${ServiceDetails?.order_items?.length || 0}`}
                      />
                    </Tab> */}
                    {/* <Tab eventKey="reviews" title="Reviews">
                      <EcommerceReviewsTable
                        queryCondition={{ order_id: { _eq: match?.params?.id } }}
                        activeFilters={[]}
                        filterQueryConditions={null}
                      />
                    </Tab> */}
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
EcommerceServiceDetails.propTypes = {
}
export default EcommerceServiceDetails