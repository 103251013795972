import { gql } from '@apollo/client'

export const AddProductFormDataQuery =
  gql`
    query AddProductFormDataQuery {
        categories {
            id
            name
        }
        collections {
            id
            name
        }
        enum_weight_units {
            comments
            name
        }
        product_brand {
            id
            name
        }
    }
`

export const InsertMasterProductsOne =
  gql`mutation InsertMasterProductsOne(\$object: master_products_insert_input!) {
    insert_master_products_one(object: \$object) {
      id
    }
  }
`
export const GetProductByID =
  gql`query MyQuery (\$id:uuid) {
  master_products(where: {products: {id: {_eq: \$id}}}) {
    name
    short_name
    categories_id
    collection_id
    weight
    weight_unit
    product_brand {
      id
    }
    recommended
    description
  }
}


`
