import React, { useEffect, useState, useRef } from "react"
import PropTypes, { element } from "prop-types"
import { withRouter, Link } from "react-router-dom"
import Loader from "../../../components/Loader/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit"
import { useQuery, useLazyQuery } from "@apollo/client"
import { Order_List, GetTotalOrderEarnings, GetTotalServicesEarnings, Services_List, GroceriesCommissionList } from "gql/query"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
//Import Breadcrumb
import { Dropdown, Form } from "react-bootstrap"
import Highlighter from "react-highlight-words"
import { SelectPicker } from 'rsuite'

//import filters
import EcommerceOrdersFilter from "components/Filters/Ecommerce/EcommerceOrdersFilter"

//Functions
import { EcommerceOrderColumns, FormatData, BuildOrderSearchQuery, BuildOrderFilterQuery, BuildCommissionSearchQuery } from "./EcommerceServices.functions"
 import EcommerceCommissionFilter from "components/Filters/Ecommerce/EcommerceCommissionFilter"
import { GoKebabVertical } from "react-icons/go"
import EcommerceOrdersModal from "./EcommerceServicesModal"

const CommissionsGroceries = props => {
  
  const { capabilities } = useSelector((state) => state.Login)
  const history = useHistory()
  const { ExportCSVButton } = CSVExport
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  })
  const [queryCondition, setQueryCondition] = useState({})
  const [filterValue, setFilterValue] = useState("")
  const [searchKey, setSearchKey] = useState("")
  const limitData = [{ label: "50", value: 50 }, { label: "100", value: 100 }, { label: "200", value: 200 }, { label: "500", value: 500 }]

  const [getCommissionsList, { loading, data, error, refetch }] = useLazyQuery(GroceriesCommissionList, {
    skipCache: true,
    variables: {
      limit: PageData.limit,
      offset: (PageData.pageNo - 1) * PageData.limit,
      where: queryCondition
    }
  })

  const PageDown = () => {
    if (PageData.pageNo > 0)
      setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
  }
  const PageUp = () => {
    setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
  }
  const [orders, setOrders] = useState([])
  useEffect(() => {
    if (data && !loading) {
      const {
        Commissions,
      } = FormatData(data)
       setOrders(Commissions)
    }
  }, [data])
  const count = data?.services_commission?.length || 0
  // const total_earnings = totalEarningsData?.orders_aggregate?.aggregate?.sum?.gross_amount || 0
  const maxPage = Math.ceil(count / PageData.limit)
  const selectRow = {
    mode: "checkbox",
  }

  const [modalOpen, setModalOpen] = useState(false)
	const [selectedCommission, setSelectedCommission] = useState({})
  const changeModal = (row) =>{
    setModalOpen((modal) =>!modal)
    setSelectedCommission(row)
  }
 
  const formatHighlighter = (text, searchKey, filterValue) => {
    let searchWords = [searchKey]
    if (Array.isArray(filterValue?.status)) {
      searchWords = [...searchWords, ...filterValue?.status]
    }
    if (Array.isArray(filterValue?.city)) {
      searchWords = [...searchWords, ...filterValue?.city]
    }
    return <Highlighter
      highlightClassName="YourHighlightClass"
      searchWords={searchWords}
      autoEscape={true}
      textToHighlight={String(text)}
      highlightStyle={{ background: "#ff0" }}
    />
  }
   
  const EcommerceOrderColumns = (searchKey, filterValue, history) => [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      // formatter: (cellContent, row) => {
      //   if (row.id) {
      //     return (
      //       <Link to={`/services/service-list/details/${row.id}`}>
      //         {formatHighlighter(row.id, searchKey, filterValue)}
      //       </Link>
      //     )
      //   } else {
      //     return formatHighlighter(row.id, searchKey, filterValue)
      //   }
      // }
    },
    {
      dataField: "enum_state",
      text: "State",
      sort: true,
    },
  
    {
      dataField: "enum_business_type",
      text: "Business Type",
      sort: true,
    },
   
    {
      dataField: "enum_commission_type",
      text: "Commission Type",
      sort: true,
   
    },
  
   
    {
      dataField: "commission_value",
      text: "Commission Value",
      sort: true,
    },
    {
      dataField: "active",
      text: "Active",
      sort: true,
      formatter: (cellContent, row) => row?.active === true ? "True" :"False",
    },
   
     
    {
      dataField: "view",
      isDummyField: true,
      text: "Action",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Dropdown>
          <Dropdown.Toggle variant="transparent" id="dropdown-basic">
            <GoKebabVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => {
							// setSelectedBrand(row)
							// setBrandOpen(true)
              setModalOpen((modal) =>!modal)
              setSelectedCommission(row)

						}}>Edit</Dropdown.Item>

            <EcommerceOrdersModal 
              onClick={() => changeModal(row)} 
              isOpen={modalOpen}
              Selectedorder ={row}
             />
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]

  const handleSearch = (value) => {
    const conditionTemp = BuildCommissionSearchQuery(value, props.queryCondition)
     setQueryCondition(conditionTemp)
  }
  var node = useRef()
  const defaultSorted = []
  useEffect(() => {
    let conditionTemp = BuildOrderFilterQuery(filterValue, props.queryCondition)
    setQueryCondition(conditionTemp)
  }, [filterValue])
  useEffect(() => {
    if (props.queryCondition !== null) {
      setQueryCondition(props.queryCondition)
    } else {
      getCommissionsList()
      // _GetTotalOrderEarnings()
    }
  }, [props.queryCondition])
  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getCommissionsList()
      // _GetTotalOrderEarnings()
    }
  }, [queryCondition])
  useEffect(() => {
    if(searchKey.length > 0){
      handleSearch(searchKey)
    }else{
      refetch()
    }
  }, [searchKey])
  return (
    <>
    <Row>
      <Col xs="12">
        <Card>
          <CardBody>
            <ToolkitProvider
              keyField="id"
              data={orders}
              columns={EcommerceOrderColumns(searchKey, filterValue, history)}
              bootstrap4
              exportCSV={{ onlyExportSelection: true, exportAll: true }}
            // search
            >
              {toolkitProps => (
                <React.Fragment>
                  {props.showFilters && (
                    <Row className="mb-2">
                      <Col sm="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Form onSubmit={(e)=>{
                              e.preventDefault()
                            }}>
                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                <i className="bx bx-search-alt search-icon" />
                                <Form.Control
                                  type="text"
                                  placeholder="Search Commissions"
                                  onChange={(e) => setSearchKey(e.target.value)}
                                  value={searchKey}
                                />
                              </Form.Group>
                            </Form>
                          </div>
                        </div>
                      </Col>
                      <Col sm="8">
                        <div className="text-sm-end d-flex justify-content-end">
                          {capabilities.includes("export") && (
                            <ExportCSVButton
                              {...toolkitProps.csvProps}
                              style={{ border: "1px solid #74788d", marginRight: 10 }}
                            >Export</ExportCSVButton>
                          )}
                          <EcommerceCommissionFilter
                            onFilterChange={setFilterValue}
                            activeFilters={props.activeFilters}
                            filterQueryConditions={props.filterQueryConditions}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xl="12">
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                          <b>No. of Commissions:</b> {count}
                          {/* <b className="mx-3">|</b> */}
                          {/* <b>Total Earnings:</b> ₹{total_earnings} */}
                        </div>
                        <div>
                          <SelectPicker
                            data={limitData}
                            searchable={false}
                            style={{ width: 100 }}
                            placeholder="Limit"
                            value={PageData.limit}
                            onChange={(value) => setPageData({ ...PageData, limit: value })}
                            cleanable={false}
                          />
                        </div>
                      </div>
                      <div className="mb-3" />
                      <div className="table-responsive">
                        {loading ? <Loader /> :
                          <BootstrapTable
                            key={`${searchKey}_${JSON.stringify(filterValue)}`}
                            keyField="id"
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={props.showFilters ? selectRow : undefined}
                            classes={
                              "table align-middle table-nowrap table-check"
                            }
                            headerWrapperClasses={"table-light"}
                            {...toolkitProps.baseProps}
                            // {...paginationTableProps}
                            ref={node}
                            noDataIndication={() => "No Orders Found!"}
                          />
                        }
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-40">
                    <Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
                      <Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
                        Previous Page
                      </Button>
                      <div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
                      </div>
                      <Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
                        Next Page
                      </Button>
                    </Col>
                  </Row>

                </React.Fragment>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  			{capabilities.includes("create") && (
      
          <EcommerceOrdersModal 
          onClick={() => changeModal(row)} 
          isOpen={modalOpen}
          close={() => {
            setModalOpen((modal) =>!modal)
            setSelectedCommission({})
          }}
          onSubmit={() => refetch()}

          Selectedorder ={selectedCommission}
         />
        )}
        </>
  )
}
CommissionsGroceries.propTypes = {
  queryCondition: PropTypes.any,
  activeFilters: PropTypes.array,
  filterQueryConditions: PropTypes.any,
  showFilters: PropTypes.bool
}
export default withRouter(CommissionsGroceries)
