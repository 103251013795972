import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Col, Container, Row, Button } from "reactstrap"
import moment from "moment"
import { Badge } from "react-bootstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import download from "downloadjs"
import Swal from "sweetalert2"
import { useSelector } from "react-redux"

const RentalDriverDetails = (props) => {
  const [vehicleDetails, setVehicleDetails] = useState([])

  const FormatData = (elem) => {
    return {
      name: elem?.name || elem?.driver_name,
      photo_url: elem?.photo_url || elem?.image_url,
      email: elem?.email,
      phone: elem?.phone,
    }
    return FormatData
  }

  useEffect(() => {
    if (props.order?.rental_order_items?.length) {
      if (props.order?.rental_order_items[0]?.driver_type === "self") {
        setVehicleDetails(FormatData(props.order.customer))
      } else if (props.order?.rental_order_items[0]?.driver_type === "friend") {
        setVehicleDetails(
          FormatData(props.order.rental_order_items[0].driver_customer)
        )
      } else if (props.order?.rental_order_items[0]?.driver_type === "addon") {
        setVehicleDetails(FormatData(props.order.rental_order_items[0]?.driver))
      }
    }
  }, [props.order])

  console.log("Driver details", vehicleDetails)

  return (
    <Container>
      <Row>
        <Col sm="4">
          <img
            src={
              vehicleDetails.photo_url?.length > 0
                ? vehicleDetails.photo_url
                : "/placeholder.jpg"
            }
            className="w-100"
          />
        </Col>
        <Col sm="8" className="pl-3">
          <h3>{vehicleDetails?.name}</h3>
          <Row>
            <Col>
              <h6>Phone Number</h6>
              <p>{vehicleDetails?.phone}</p>
            </Col>
            <Col>
              <h6>Email</h6>
              <p>{vehicleDetails?.email}</p>
            </Col>
          </Row>

          {/* <hr />
          <Row>
            <Col>
              <h6>Rate per hour </h6>
              <p>Rs. {vehicleDetails?.price_per_hour}</p>
            </Col>
            <Col>
              <h6>Deposit Amount</h6>
              <p>Rs. {vehicleDetails?.deposite_amount}</p>
            </Col>
            <Col>
              {" "}
              <h6>Rental Type</h6>
              {vehicleDetails?.rental_type?.map((type, i) => (
                <Badge pill bg="primary" key={i} style={{ marginLeft: 5 }}>
                  {type}
                </Badge>
              ))}
            </Col>
          </Row> */}
        </Col>
      </Row>
    </Container>
  )
}
RentalDriverDetails.propTypes = {
  order: PropTypes.any,
}
RentalDriverDetails.displayName = "RentalDriverDetails"
export default RentalDriverDetails
