import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceProductsTable from "../../EcommerceProducts/EcommerceProductsTable"
const PromotionProducts = (props) => {
    const match = useRouteMatch()
    const { products, categories, subcategories, brands } = props.promotion
    return (
        <div className="p-3">
            {Array.isArray(products) && (
                <EcommerceProductsTable
                    queryCondition={{ id: { _in: products } }}
                    activeFilters={["product_brand", "category", "subcategory", "store_type", "created_at", "non_mrp"]}
                    filterQueryConditions={[
                        {filter: "category", condition: {id: {_in: categories.map((cat) => cat.id)}}},
                        {filter: "subcategory", condition: {id: {_in: subcategories.map((subcat) => subcat.id)}}},
                        {filter: "product_brand", condition: {id: {_in: brands.map((brand) => brand.id)}}},
                    ]}
                    showCreateBtn={false}
                />
            )}
        </div>
    )
}
PromotionProducts.propTypes = {
    promotion: PropTypes.any
}
PromotionProducts.displayName = 'PromotionProducts'
export default PromotionProducts