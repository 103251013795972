import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceProductsTable from "../EcommerceProducts/EcommerceProductsTable"
const ShopProducts = (props) => {
	const match = useRouteMatch()
	return (
		<div className="p-3">
			<EcommerceProductsTable
                queryCondition={{ store_id: { _eq: match?.params?.id } }}
                activeFilters={["product_brand", "category", "subcategory", "created_at", "non_mrp"]}
                filterQueryConditions={[
					{filter: "product_brand", condition: {master_products: {products: {store_id: {_eq:  match?.params?.id}}}}},
					{filter: "subcategory", condition: {master_products: {products: {store_id: {_eq:  match?.params?.id}}}}},
					{filter: "category", condition: {master_products: {products: {store_id: {_eq:  match?.params?.id}}}}},
				]}
            />
		</div>
	)
}
ShopProducts.propTypes = {}
ShopProducts.displayName = 'ShopProducts'
export default ShopProducts