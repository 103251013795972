import React, { useEffect } from "react"
import { DateRangePicker, CheckPicker, Form, SelectPicker  } from 'rsuite'
import CheckPickerPaginate from "../CheckPickerPaginate"
import { useState } from "react"
import PropTypes from "prop-types"

import {MerchantCityFilter} from "gql/query"
const EcommerceMerchantsFilter = (props) => {
	const filterData = [{ label: "Referred By", value: "referred_by" }, { label: "Docs Submitted", value: "docs_submitted" }, { label: "Docs Verified", value: "docs_verified" }, { label: "Status", value: "status" }, { label: "City", value: "city" }, { label: "GST Require", value: "gst_available" }, { label: "No. of Stores", value: "total_stores" }]
	const statusData = [{ label: "Registered", value: "registered" }, { label: "Pending", value: "pending" }, { label: "Verified", value: "verified" }, { label: "Rejected", value: "rejected" }]
	const totalStoresData = [{ label: "1", value: "1" }, { label: "2 - 3", value: "2-3" }, { label: "> 3", value: ">3" }]
	const docsData = [{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]
	const [filters, setFilters] = useState([])
	const [filterValues, setFilterValues] = useState({})

	const resetFilter = (value) => {
		const filtersTemp = filters.filter(item => item !== value)
		setFilters(filtersTemp)
	}
	useEffect(() => {
		props.onFilterChange(filterValues)
	}, [filterValues])
	useEffect(() => {
		if (filters.length) {
			let filterValuesTemp = { ...filterValues }
			if (!filters.includes("status")) {
				delete filterValuesTemp["status"]
			} 
			if (!filters.includes("referred_by")) {
				delete filterValuesTemp["referred_by"]
			} 
			if (!filters.includes("docs_submitted")) {
				delete filterValuesTemp["docs_submitted"]
			} 
			if (!filters.includes("docs_verified")) {
				delete filterValuesTemp["docs_verified"]
			} 
			if (!filters.includes("city")) {
				delete filterValuesTemp["city"]
			} 
			if (!filters.includes("gst_available")) {
				delete filterValuesTemp["gst_available"]
			}
			if (!filters.includes("total_stores")) {
				delete filterValuesTemp["total_stores"]
			}
			setFilterValues(filterValuesTemp)
		} else {
			setFilterValues({})
		}
	}, [filters])
	return (
		<div className="auto-horizontal-scroll">
			<CheckPicker
				data={filterData}
				placeholder={"Filter"}
				style={{ width: 130 }}
				value={filters}
				onChange={setFilters}
				searchable={false}
			/>
			{filters.map((filter, i) => {
				if (filter === "referred_by") {
					return (
						<React.Fragment key={i}>
							<Form style={{ marginLeft: 10 }}>
								<Form.Group controlId="formBasicEmail">
									<Form.Control
										type="text"
										placeholder="Referred By"
										onChange={(referred_by) => setFilterValues({ ...filterValues, referred_by})}
										value={filterValues.referred_by}
										style={{ width: 130 }}
									/>
								</Form.Group>
							</Form>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("referred_by")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "status") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={statusData}
								placeholder={"Select Status"}
								style={{ width: 130, marginLeft: 10 }}
								onChange={(status) => setFilterValues({ ...filterValues, status })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("status")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "docs_verified") {
					return (
						<React.Fragment key={i}>
							<SelectPicker 
								data={docsData}
								placeholder={"Select Docs Verified"}
								style={{ marginLeft: 10 }}
								onChange={(docs_verified) => setFilterValues({ ...filterValues, docs_verified })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("docs_verified")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "docs_submitted") {
					return (
						<React.Fragment key={i}>
							<SelectPicker 
								data={docsData}
								placeholder={"Select Docs Submitted"}
								style={{marginLeft: 10 }}
								onChange={(docs_submitted) => setFilterValues({ ...filterValues, docs_submitted })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("docs_submitted")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "city") {
					return (
						<React.Fragment key={i}>
							<CheckPickerPaginate
								query={MerchantCityFilter}
								placeholder="Select City"
								value={Array.isArray(filterValues.city) ? filterValues.city : []}
								onChange={(city) => setFilterValues({ ...filterValues, city })}
								arrKey="vendor_company"
								objectLabel="city"
								objectValue="city"
								style={{ marginLeft: 10 }}
								paginate={true}
								filterCondition={null}
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("city")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "gst_available") {
					return (
						<React.Fragment key={i}>
							<SelectPicker 
								data={docsData}
								placeholder={"Select GST Require"}
								style={{marginLeft: 10 }}
								onChange={(gst_available) => setFilterValues({ ...filterValues, gst_available })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("gst_available")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
				if (filter === "total_stores") {
					return (
						<React.Fragment key={i}>
							<CheckPicker
								data={totalStoresData}
								placeholder={"No. of Stores"}
								style={{ marginLeft: 10 }}
								value={Array.isArray(filterValues.total_stores) ? filterValues.total_stores : []}
								onChange={(total_stores) => setFilterValues({ ...filterValues, total_stores })}
								searchable={false}
								placement="bottomEnd"
							/>
							<div style={{ marginLeft: 5, cursor: "pointer", height: 40, display: "flex", alignItems: "center" }} onClick={() => resetFilter("total_stores")}>
								<i className="bx bx-x-circle" />
							</div>
						</React.Fragment>
					)
				}
			})}
		</div>
	)
}
EcommerceMerchantsFilter.propTypes = {
	onFilterChange: PropTypes.func,
}
export default EcommerceMerchantsFilter