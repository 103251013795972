import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
 import RentalsFiltersDocuments from "./rental-kyc-list"
 import {useParams} from "react-router-dom"
import PropTypes from "prop-types"

 import { v4 as uuidv4 } from 'uuid'

//import "../../../../assets/scss/custom.scss"

const RentalAddonsDocs = props => {

  const params = useParams()

  console.log("pramas",params )

  let isDetailsPage = params?.id ? true : false
  let condition = isDetailsPage ? [{driver_id : {"_eq":params?.id}}] : null
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Rental Kyc | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Rentals" breadcrumbItem="Rental Kyc" />
              <RentalsFiltersDocuments  
               detailPage={isDetailsPage}  
               activeFilters={["vehicle_group", "filter_name"]} 
               queryCondition = {condition}
               filterQueryConditions={null} 
              //  filterQueryConditions={condition} 
               showCreateBtn={isDetailsPage ? false: true} />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}

RentalAddonsDocs.propTypes = {
  id: PropTypes.any,
}
export default withRouter(RentalAddonsDocs)
