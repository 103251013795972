import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import VehicleBrandsList from "./vehicle-brands-list"
//import "../../../../assets/scss/custom.scss"

const VehicleBrands = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Vehicle Brands | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Rentals" breadcrumbItem="Brand" />
              <VehicleBrandsList 
              queryCondition={null} 
              activeFilters={["brand_name" ]} 
              filterQueryConditions={null} showCreateBtn={true} />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}

VehicleBrands.propTypes = {}
export default withRouter(VehicleBrands)
