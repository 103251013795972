import React, { useEffect, useState, useRef } from "react"
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	UncontrolledTooltip,
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	UncontrolledDropdown,
} from "reactstrap"
import PropTypes from 'prop-types'
import moment from "moment"
import { useRouteMatch } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import Loader from "../../../components/Loader/Loader"
//Helpers
import isValidUrl from "../../../helpers/url_checker"
//GraphQL
import { MerchantStoreList, MerchantPromotionList } from "gql/query"
import { useQuery, useLazyQuery } from "@apollo/client"
const CustomerStores = (props) => {
	const match = useRouteMatch()
	const FormatData = (data) => {
		const stores = data.stores.map(elem => {
			let image = "/placeholder.jpg"
			if (isValidUrl(elem.photo_url)) {
				image = elem.photo_url
			}
			return {
				id: elem.id,
				store_id: "N/A",
				name: elem.name || "N/A",
				logo: image,
				shop_type: elem.store_type || "N/A",
				sub_categories: "N/A",
				location: elem.city || "N/A",
				no_of_products: elem.products_aggregate?.aggregate?.count,
				no_promo_codes: elem.promotion_stores_aggregate?.aggregate?.count,
				no_of_orders: elem.orders_aggregate?.aggregate?.count,
				gst_required: elem.gst_available,
				store_link: elem.website_url || "N/A",
				store_qr: "",
				docs_applicable: "N/A",
				created_at: elem.created_at,
				shop_level: 1
			}
		})
		return stores
	}
	const StoresColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			dataField: "store_id",
			text: "Store ID",
			sort: true,
		},
		{
			dataField: "name",
			text: "Store Name",
			sort: true,
		},
		// {
		// 	dataField: "logo",
		// 	text: "Shop Logo",
		// 	sort: false,
		// 	formatter: (cellContent, row) => <img src={row.logo} style={{ height: 50 }} />,
		// },
		{
			text: "Shop Type",
			dataField: "shop_type",
			sort: true,
		},
		{
			dataField: "sub_categories",
			text: "Sub-Categories",
			sort: true,
		},
		{
			text: "Location",
			dataField: "location",
			sort: true,
		},
		{
			text: "No. of products",
			dataField: "no_of_products",
			sort: true,
			formatter: (cellContent, row) => <div onClick={() => {
				props.setSelectedStores({target: "products", stores:[row.id]})
				props.setActiveTab("products")
			}} style={{ cursor: "pointer" }}>{row.no_of_products}</div>,
		},
		{
			text: "No. of promo codes",
			dataField: "no_promo_codes",
			sort: true,
			formatter: (cellContent, row) => <div onClick={() => {
				setQueryConditionPromo({ store_id: { _eq: row.id } })
				setModal(true)
			}} style={{ cursor: "pointer" }}>{row.no_promo_codes}</div>,
		},
		{
			text: "No. of orders",
			dataField: "no_of_orders",
			sort: true,
			formatter: (cellContent, row) => <div onClick={() => {
				props.setSelectedStores({target: "orders", stores:[row.id]})
				props.setActiveTab("orders")
			}} style={{ cursor: "pointer" }}>{row.no_of_orders}</div>,
		},
		// {
		// 	text: "GST required",
		// 	dataField: "gst_required",
		// 	sort: false,
		// 	formatter: (cellContent, row) => <>{row.gst_required ? "Yes" : "No"}</>,
		// },
		// {
		// 	text: "Store Link",
		// 	dataField: "store_link",
		// 	sort: false,
		// },
		// {
		// 	text: "Store QR",
		// 	dataField: "store_qr",
		// 	sort: false,
		// },
		// {
		// 	text: "Shop Level",
		// 	dataField: "shop_level",
		// 	sort: false,
		// },
		// {
		// 	text: "Created At",
		// 	dataField: "created_at",
		// 	sort: true,
		// 	formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		// }
	]
	const PromoCodesColumn = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			dataField: "promo_id",
			text: "ID",
			sort: false,
		},
		{
			dataField: "label",
			text: "Label",
			sort: true,
		},
		{
			dataField: "code",
			text: "Code",
			sort: true,
		},
		{
			text: "Description",
			dataField: "description",
			sort: true,
		},
		{
			dataField: "value_type",
			text: "Discount Type",
			sort: true,
		},
		{
			text: "Value",
			dataField: "value",
			sort: true,
		},
		{
			text: "Max Order Count",
			dataField: "max_order_count",
			sort: false,
		},
		{
			text: "Min Order Count",
			dataField: "min_order_count",
			sort: false
		},
		{
			text: "Max Order Amount",
			dataField: "max_order_amount",
			sort: false,
			formatter: (cellContent, row) => `₹${row.max_order_amount}`,
		},
		{
			text: "Min Order Amount",
			dataField: "min_order_amount",
			sort: false,
			formatter: (cellContent, row) => `₹${row.min_order_amount}`,
		},
		{
			text: "Available Hours",
			dataField: "available_hours",
			sort: false,
		},
		{
			text: "No. of Time Used",
			dataField: "no_of_time_used",
			sort: false,
		},
		{
			text: "Active",
			dataField: "active",
			sort: false,
			formatter: (cellContent, row) => row.active ? "Yes" : "No",
		},
		// {
		// 	text: "Promoted By",
		// 	dataField: "promoted_by",
		// 	sort: false,
		// },
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		}
	]
	const defaultSorted = []
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({ created_by: { _eq: match?.params?.id } })
	const [modal, setModal] = useState(false)
	const [stores, setStores] = useState([])
	const toggle = () => {
		setModal(!modal)
	}
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}
	const { data, loading, error } = useQuery(MerchantStoreList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const count = data?.stores_aggregate?.aggregate?.count
	const maxPage = Math.ceil(count / PageData.limit)
	useEffect(() => {
		if (data) {
			setStores(FormatData(data))
		}
	}, [data])

	//=============== PROMOTIONS =======================//
	const FormatDataPromo = (data) => {
		const promotions = data.promotion_stores.map(elem => {
			return {
				id: elem.promotion?.id,
				promo_id: elem.promotion?.id,
				created_at: elem.promotion?.created_at,
				label: elem.promotion?.label,
				code: elem.promotion?.code,
				description: elem.promotion?.description || "N/A",
				value_type: elem.promotion?.value_type,
				value: elem.promotion?.value,
				max_order_count: elem.promotion?.max_order_count,
				min_order_count: elem.promotion?.min_order_count,
				max_order_amount: elem.promotion?.max_order_amount,
				min_order_amount: elem.promotion?.min_order_amount,
				available_hours: elem.promotion?.available_hours,
				no_of_time_used: elem.promotion?.used_count,
				active: true,
				promoted_by: elem.promotion?.promoted_by
			}
		})
		return promotions
	}
	const [PageDataPromo, setPageDataPromo] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryConditionPromo, setQueryConditionPromo] = useState({})
	const [promotions, setPromotions] = useState([])
	const PageDownPromo = () => {
		if (PageDataPromo.pageNo > 0)
			setPageDataPromo({ ...PageDataPromo, pageNo: PageDataPromo.pageNo - 1 })
	}
	const PageUpPromo = () => {
		setPageDataPromo({ ...PageDataPromo, pageNo: PageDataPromo.pageNo + 1 })
	}
	const [getPromotions, { loading: loadingPromo, data: dataPromo }] = useLazyQuery(MerchantPromotionList, {
		skipCache: true,
		variables: {
			limit: PageDataPromo.limit,
			offset: (PageDataPromo.pageNo - 1) * PageDataPromo.limit,
			where: queryConditionPromo
		}
	})
	const countPromo = dataPromo?.promotion_stores_aggregate?.aggregate?.count
	const maxPagePromo = Math.ceil(countPromo / PageDataPromo.limit)
	useEffect(() => {
		if (dataPromo) {
			setPromotions(FormatDataPromo(dataPromo))
		}
	}, [dataPromo])
	useEffect(() => {
		if (queryConditionPromo?.store_id) {
			getPromotions()
		}
	}, [queryConditionPromo])
	return (
		<div className="p-3">
			<Row className="mb-5">
				<h4>No. of Stores: {count}</h4>
				<hr />
				<ToolkitProvider
					keyField="id"
					data={stores}
					columns={StoresColumns}
					bootstrap4
				// search
				>
					{toolkitProps => (
						<>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										{loading ? <Loader /> :
											<BootstrapTable
												responsive
												bordered={false}
												striped={false}
												defaultSorted={defaultSorted}
												classes={"table align-middle table-nowrap"}
												keyField="id"
												{...toolkitProps.baseProps}
												onTableChange={() => { }}
												noDataIndication={()=>"No Stores Found!"}
											// {...paginationTableProps}
											/>
										}
									</div>
								</Col>
							</Row>
							<Row className="align-items-md-center mt-40">
								<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
									<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
										Previous Page
									</Button>
									<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
									</div>
									<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
										Next Page
									</Button>
								</Col>
							</Row>
						</>
					)}
				</ToolkitProvider>
			</Row>
			<Modal isOpen={modal} toggle={toggle} size="xl" centered={true}>
				<ModalHeader toggle={toggle} tag="h4">
					Promo Codes
				</ModalHeader>
				<ModalBody>
					<ToolkitProvider
						keyField="id"
						data={promotions}
						columns={PromoCodesColumn}
						bootstrap4
					>
						{toolkitProps => (
							<>
								<Row>
									<Col xl="12">
										<div className="table-responsive">
											{loadingPromo ? <Loader /> :
												<BootstrapTable
													responsive
													bordered={false}
													striped={false}
													defaultSorted={defaultSorted}
													classes={"table align-middle table-nowrap"}
													keyField="id"
													{...toolkitProps.baseProps}
													onTableChange={() => { }}
													noDataIndication={()=>"No Promotions Found!"}
												/>
											}
										</div>
									</Col>
								</Row>
								<Row className="align-items-md-center" style={{ marginTop: 40 }}>
									<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
										<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDownPromo() }}>
											Previous Page
										</Button>
										<div>{`Page No. ${PageDataPromo.pageNo} of ${maxPagePromo}`}
										</div>
										<Button disabled={PageDataPromo.pageNo >= maxPagePromo} onClick={() => { PageUpPromo() }}>
											Next Page
										</Button>
									</Col>
								</Row>
							</>
						)}
					</ToolkitProvider>
				</ModalBody>
			</Modal>
		</div>
	)
}
CustomerStores.propTypes = {
    setSelectedStores: PropTypes.func,
	setActiveTab: PropTypes.func
}
CustomerStores.displayName = 'CustomerStores'
export default CustomerStores