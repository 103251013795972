import { VehicleRentalMasterVehiclesTypehead, VehicleRentalStoresTypehead, VehicleRentalVehicleBarndsTypehead, VehicleRentalsCategoriesTypehead, VehicleTypesTypehead, VehicleUsersTypehead } from "gql/query"


export const createHeaders = {

                 vehicle_name   : {name:"vehicle_name", span:3,type:"text", },
                   vehicle_no   : {name:"vehicle_no", span:3,type:"text", },
                vehicle_group   : {name:"vehicle_group", span:3,type:"text", },
                    fuel_type   : {name:"fuel_type", span:3,type:"select", },
            transmission_type   : {name:"transmission_type", span:3,type:"select", },
                  rental_type   : {name:"rental_type", span:3,type:"select_picker", },
                       driver   : {name:"driver", span:3,type:"select_picker", },
                        seats   : {name:"seats", span:3,type:"number", },
                   kms_driven   : {name:"kms_driven", span:3,type:"number", },
               price_per_hour   : {name:"price_per_hour", span:3,type:"number", },
         price_per_hour_offer   : {name:"price_per_hour_offer", span:3,type:"number", },
              deposite_amount   : {name:"deposite_amount", span:3,type:"number", },
              minimum_hours     : {name:"minimum_hours", span:3,type:"number", },
              minimum_kms       : {name:"minimum_kms", span:3,type:"number", },
              vehicle_type_id   : { name:"vehicle_type_id", 
                                    access:"vehicle_type", 
                                    span:3,
                                    type:"typehead", 
                                    entity:"rental_vehicle_types", 
                                    query :VehicleTypesTypehead,
                                    acessLabel:"type_name",
                                    acessValue : "id"
                                   },
                   photo_urls   : {name:"photo_urls", span:3,type:"img",multi:true },

          vehicle_category_id   :  {  name:"vehicle_category_id", 
                                      access:"vehicle_category",
                                      span:3,
                                      type:"typehead", 
                                      entity:"rental_vehicle_categories", 
                                      query :VehicleRentalsCategoriesTypehead,
                                      acessLabel:"category_name",
                                      acessValue : "id"
                                    },
          vehicle_brand_id   :     {   name:"vehicle_brand_id", 
                                       access:"vehicle_brand",
                                       span:3,
                                       type:"typehead", 
                                       entity:"rental_vehicle_brands", 
                                       query :VehicleRentalVehicleBarndsTypehead,
                                       acessLabel:"brand_name",
                                       acessValue : "id"
                                    },                    
           master_vehicle_id       :  { name:"master_vehicle_id", 
                                        access:"master_vehicle", 
                                        span:3,
                                        type:"typehead", 
                                        entity:"rental_master_vehicles", 
                                        query :VehicleRentalMasterVehiclesTypehead,
                                        acessLabel:"naming",
                                        acessValue : "id",
                                        sub:"sub"
                                        },
           store_id                :  { name:"store_id", 
                                        access:"store", 
                                        span:3,
                                        type:"typehead", 
                                        entity:"stores", 
                                        query :VehicleRentalStoresTypehead,
                                        acessLabel:"name",
                                        acessValue : "id"
                                       
                                        },


          created_by                :    {  name:"user_id", 
                                            access:"user", 
                                            span:3,
                                            type:"typehead", 
                                            entity:"user", 
                                            query :VehicleUsersTypehead,
                                            acessLabel:"name",
                                            acessValue : "id"
                                          
                                        },
                                   
                                   
                 vehicle_data   : [    
                                        {name:"budget",span:3,type:"text"},
                                        {name:"engine", span:3,type:"text"},
                                        {name:"features", span:3,type:"tag"},
                                  ],
                 allowance_data : [    
                                    {name:"night_allowance",span:3,type:"text"},
                                    {name:"other_allowance", span:3,type:"text"},
                                    {name:"per_day_allowance", span:3,type:"text"},
                                  ],
                 map_point  : {name:"map_point", span:12,type:"map" },
                

 
         }


