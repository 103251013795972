import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Col, Container, Row, Button } from "reactstrap"
import moment from "moment"
import { Badge } from "react-bootstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import download from "downloadjs"
import Swal from "sweetalert2"
import { useSelector } from "react-redux"

//component
import OrderEditModal from "./OrderEditModal"

const OrderOverview = (props) => {
  const { capabilities } = useSelector((state) => state.Login)
  const [merchant, setMerchant] = useState({})
  const [scheduleBtnLoading, setScheduleBtnLoading] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const formatPrice = (value) => {
    if (!isNaN(value) && value !== null && value !== undefined) {
      return `₹${parseFloat(value).toFixed(2)}`
    } else {
      return "N/A"
    }
  }
  const handleDownloadInvoice = async () => {
    const token = localStorage.getItem("TOKEN")
    axios({
      url: `${process.env.REACT_APP_SERVICE_BASE_URL}api/order/invoice`,
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        order_id: props.order?.id,
      },
      responseType: "arraybuffer",
    })
      .then((response) => {
        const content = response.headers["content-type"]
        download(response.data, "order-invoice.pdf", content)
      })
      .catch((error) => console.log(error))
  }
  const handleRefund = async () => {
    Swal.fire({
      title: "Are you sure want to initiate refund?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const token = localStorage.getItem("TOKEN")
          await axios({
            url: `${process.env.REACT_APP_SERVICE_BASE_URL}api/payment/refund_csfree`,
            method: "post",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: {
              orderId: props.order?.payments[0]?.payment_id,
              refundAmount: props.order?.total_amount,
              refundNote: "",
            },
            responseType: "arraybuffer",
          })
          props.refetch()
        } catch (err) {}
      }
    })
  }
  const handleScheduleOrder = async () => {
    Swal.fire({
      title: "Are you sure want to manually schedule this order?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      icon: "warning",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setScheduleBtnLoading(true)
        const token = localStorage.getItem("TOKEN")
        axios({
          url: `${process.env.REACT_APP_SERVICE_BASE_URL}api/order/delivery/schedule`,
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: {
            order_id: props.order?.id,
          },
        })
          .then((response) => {
            Swal.fire("Order Scheduled Successfully", "", "success").then(
              () => {
                props.refetch()
              }
            )
          })
          .catch((error) => console.log(error))
          .finally(() => {
            setScheduleBtnLoading(false)
          })
      }
    })
  }
  const isDiscountApplied = () =>
    Array.isArray(props.order?.discounts) && props.order?.discounts?.length > 0

  const isRefundApplicable = () =>
    props.order?.order_status === "cancelled" ||
    props.order?.order_status === "rejected" ||
    props.order?.cancelled_amount !== null
  const pathName = props.pathName
  return (
    <Container>
      <Row>
        {pathName.startsWith("/groceries") && (
          <Col sm="4">
            <img
              src={
                props.order?.store?.photo_url?.length > 0
                  ? props.order?.store?.photo_url
                  : "/placeholder.jpg"
              }
              className="w-100"
            />
          </Col>
        )}
        <Col sm="8" className="pl-3">
          <div>
            <h3>
              {props.order?.store?.name}{" "}
              {merchant.vendor_company?.verification_status?.length > 0 && (
                <Badge
                  bg={
                    merchant.vendor_company.verification_status === "pending"
                      ? "warning"
                      : merchant.vendor_company.verification_status ===
                        "verified"
                      ? "success"
                      : "danger"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {merchant.vendor_company.verification_status}
                </Badge>
              )}
            </h3>
            <h6>Order ID: #{props.order?.uid}</h6>
            <h6>Status: {props.order?.order_status}</h6>
          </div>
          <hr />
          <Row>
            <div className="stats-wrapper">
              <b>Shop ID</b>
              <Link
                to={
                  pathName.startsWith("/groceries")
                    ? `/groceries/ecommerce-shops/details/${props.order?.store?.id}`
                    : pathName.startsWith("/services")
                    ? `/services/service-shops/details/${props.order?.store?.id}`
                    : ""
                }
              >
                <p>{props.order?.store?.store_id}</p>
              </Link>
            </div>
            <div className="stats-wrapper">
              <b>Ordered On</b>
              <p>
                {moment(props.order?.created_at).format("DD/MM/YYYY h:mm A")}
              </p>
            </div>
            <div className="stats-wrapper">
              <b>Total Amount</b>
              <p>{formatPrice(props.order?.total_amount)}</p>
            </div>
            <div className="stats-wrapper">
              <b>Cost of Items</b>
              {Array.isArray(props.order?.order_items) &&
              props.order?.order_items?.length ? (
                <p>
                  {formatPrice(
                    props.order?.order_items.reduce(
                      (a, b) => +a + +b.total_price_amount,
                      0
                    )
                  )}
                </p>
              ) : (
                <p>{formatPrice(0)}</p>
              )}
            </div>
            <div className="stats-wrapper">
              <b>Delivery Cost</b>
              <p>
                {props.order?.order_type === "in_store_pickup"
                  ? "N/A"
                  : formatPrice(props.order?.delivery_charges)}
              </p>
            </div>
            <div className="stats-wrapper">
              <b>Commission</b>
              <p>N/A</p>
            </div>
            {props.order?.order_type === "in_store_pickup" ? (
              <div className="stats-wrapper">
                <b>Order Type</b>
                <p>In Store Pickup</p>
              </div>
            ) : (
              pathName.startsWith("/groceries") && (
                <div className="stats-wrapper">
                  <b>Delivered By</b>
                  <p>DUNZO</p>
                </div>
              )
            )}
            {props.order?.time_slot !== null && (
              <div className="stats-wrapper">
                <b>Timeslot</b>
                <p>{props.order?.time_slot}</p>
              </div>
            )}
            <div className="stats-wrapper">
              <b>Order Notes</b>
              <p>{props.order?.order_notes || "N/A"}</p>
            </div>
            <div className="stats-wrapper">
              <b>Discount Applied</b>
              <p>{isDiscountApplied() ? "Yes" : "No"}</p>
            </div>
            {isDiscountApplied() && (
              <>
                <div className="stats-wrapper">
                  <b>Discount Code</b>
                  <p>{props.order?.discounts?.[0]?.code || "N/A"}</p>
                </div>
                <div className="stats-wrapper">
                  <b>Discount Amount</b>
                  <p>{formatPrice(props.order?.discounts?.[0]?.amount)}</p>
                </div>
              </>
            )}
          </Row>
          <h4 className="mt-2">Payment Details</h4>
          <hr />
          {props.order?.payments?.length > 0 ? (
            <Row>
              <Col sm="6">
                <div className="mb-2">
                  <b className="mb-5">Status: </b>{" "}
                  {props.order?.payments[0]?.status}
                </div>
                <div className="mb-2">
                  <b>Transaction ID: </b>{" "}
                  {props.order?.payments[0]?.externaltransactionid || "N/A"}
                </div>
                <div className="mb-2">
                  <b>Payment Type: </b>{" "}
                  {props.order?.payments[0]?.payment_type || "N/A"}
                </div>
                <div className="mb-2">
                  <b>Payment Gateway: </b>{" "}
                  {props.order?.payments[0]?.payment_gateway || "N/A"}
                </div>
              </Col>
              <Col sm="6">
                {pathName.startsWith("/groceries") && (
                  <div className="mb-2">
                    <b>Bank Code: </b>{" "}
                    {props.order?.payments[0]?.bankcode || "N/A"}
                  </div>
                )}
                {isRefundApplicable() ? (
                  <>
                    <div className="mb-2">
                      <b>Refund Applicable: </b> Yes
                    </div>
                    <div className="mb-2">
                      <b>Refunded: </b>{" "}
                      {props.order?.payments[0]?.refunded === true
                        ? "Yes"
                        : "No"}
                    </div>
                    <div className="mb-2">
                      <b>Refunded Amount: </b>{" "}
                      {props.order?.payments[0]?.payment_gateway === "razorpay"
                        ? formatPrice(
                            props.order?.payments[0]?.refunded_amount / 100
                          )
                        : formatPrice(
                            props.order?.payments[0]?.refunded_amount
                          )}
                    </div>
                  </>
                ) : (
                  <div className="mb-2">
                    <b>Refund Applicable: </b> No
                  </div>
                )}
              </Col>
            </Row>
          ) : (
            <Row style={{ marginTop: -10 }}>
              <Col sm="12">No Payment Details Found!</Col>
            </Row>
          )}
          {pathName.startsWith("/groceries") && (
            <>
              <h4 className="mt-2">Delivery Address</h4>
              <hr />
              {props.order?.customer_location ? (
                <Row>
                  <Col sm="6">
                    <div className="mb-2">
                      <b className="mb-5">Address Line 1: </b>{" "}
                      {props.order?.customer_location?.address_line_1 || "N/A"}
                    </div>
                    <div className="mb-2">
                      <b>City: </b>{" "}
                      {props.order?.customer_location?.city || "N/A"}
                    </div>
                    <div className="mb-2">
                      <b>State: </b>{" "}
                      {props.order?.customer_location?.state || "N/A"}
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="mb-2">
                      <b>Postal Code: </b>{" "}
                      {props.order?.customer_location?.postal_code || "N/A"}
                    </div>
                    <div className="mb-2">
                      <b>Landmark: </b> N/A
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row style={{ marginTop: -10 }}>
                  <Col sm="12">No Address Details Found!</Col>
                </Row>
              )}
            </>
          )}
          <h4 className="mt-2">Customer Details</h4>
          <hr />
          {props.order?.customer ? (
            <Row>
              <Col sm="6">
                <div className="mb-2">
                  <b className="mb-5">Customer ID: </b>{" "}
                  <Link
                    to={
                      pathName.startsWith("/groceries")
                        ? `/groceries/ecommerce-customers/details/${props.order?.customer?.id}`
                        : pathName.startsWith("/services")
                        ? `/services/service-customers/details/${props.order?.customer?.id}`
                        : ""
                    }
                  >
                    {props.order?.customer?.customer_id}
                  </Link>
                </div>
                <div className="mb-2">
                  <b className="mb-5">Name: </b> {props.order?.customer?.name}
                </div>
                <div className="mb-2">
                  <b>Phone: </b>{" "}
                  <a href={`tel:${props.order?.customer?.phone}`}>
                    {props.order?.customer?.phone}
                  </a>
                </div>
              </Col>
              <Col sm="6">
                <div className="mb-2">
                  <b>Email: </b>{" "}
                  <a href={`mailto:${props.order?.customer?.email}`}>
                    {props.order?.customer?.email}
                  </a>
                </div>
                <div className="mb-2">
                  <b>Gender: </b> {props.order?.customer?.gender}
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ marginTop: -10 }}>
              <Col sm="12">No Customer Details Found!</Col>
            </Row>
          )}
          {pathName.startsWith("/services") &&
            (props.order?.customer_location ? (
              <Row>
                <Col sm="6">
                  <div className="mb-2">
                    <b className="mb-5">Address Line 1: </b>{" "}
                    {props.order?.customer_location?.address_line_1 || "N/A"}
                  </div>
                  <div className="mb-2">
                    <b>City: </b>{" "}
                    {props.order?.customer_location?.city || "N/A"}
                  </div>
                  <div className="mb-2">
                    <b>State: </b>{" "}
                    {props.order?.customer_location?.state || "N/A"}
                  </div>
                </Col>
                <Col sm="6">
                  <div className="mb-2">
                    <b>Postal Code: </b>{" "}
                    {props.order?.customer_location?.postal_code || "N/A"}
                  </div>
                  <div className="mb-2">
                    <b>Landmark: </b> N/A
                  </div>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginTop: -10 }}>
                <Col sm="12">No Address Details Found!</Col>
              </Row>
            ))}
        </Col>
      </Row>
      {capabilities.includes("edit") && (
        <>
          {props.order?.order_status === "submitted" && (
            <Row>
              <Col xl="12" className="d-flex justify-content-end">
                <Button
                  type="button"
                  color={"warning"}
                  className="btn-rounded btn-lg  mb-2 me-2"
                  onClick={() => setOpenEditModal(true)}
                >
                  Edit Order
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
      {capabilities.includes("edit") && pathName.startsWith("/groceries") && (
        <>
          {props.order?.order_status === "acknowledged" &&
            props.order?.delivery_request === null && (
              <Row>
                <Col xl="12" className="d-flex justify-content-end">
                  <Button
                    type="button"
                    color={"success"}
                    className="btn-rounded btn-lg  mb-2 me-2"
                    onClick={handleScheduleOrder}
                    disabled={scheduleBtnLoading}
                  >
                    Schedule Delivery
                  </Button>
                </Col>
              </Row>
            )}
        </>
      )}
      {["cancelled", "rejected", "completed"].includes(
        props.order?.order_status
      ) && (
        <Row>
          <Col xl="12" className="d-flex justify-content-end">
            {capabilities.includes("edit") && (
              <>
                {isRefundApplicable() && !props.order?.payments[0]?.refunded && (
                  <Button
                    type="button"
                    color={"danger"}
                    className="btn-rounded btn-lg  mb-2 me-2"
                    onClick={handleRefund}
                  >
                    Refund
                  </Button>
                )}
              </>
            )}
            <Button
              type="button"
              color={"primary"}
              className="btn-rounded btn-lg  mb-2 me-2"
              onClick={handleDownloadInvoice}
            >
              Download Invoice
            </Button>
          </Col>
        </Row>
      )}
      {props.order?.customer?.id?.length > 0 && (
        <OrderEditModal
          open={openEditModal}
          close={() => setOpenEditModal(false)}
          customerId={props.order?.customer?.id}
          deliveryAddress={props.order?.customer_location?.address_id}
          orderItems={props.order?.order_items}
          totalAmount={props.order?.total_amount}
          shopId={props.order?.store?.id}
          order={props.order}
          refetch={props.refetch}
        />
      )}
    </Container>
  )
}
OrderOverview.propTypes = {
  order: PropTypes.any,
  refetch: PropTypes.func,
  pathName: PropTypes.any,
}
OrderOverview.displayName = "OrderOverview"
export default OrderOverview
