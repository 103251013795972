import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { Modal, Toggle, SelectPicker } from "rsuite"
import axios from "axios"
import Swal from "sweetalert2"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"

//GraphQL
import {
  Insert_PropertyRules_One,
  Update_PropertyRules_One,
} from "gql/Mutation"
import { PropertyRulesTypeList } from "gql/query"
import { useMutation, useApolloClient, useQuery } from "@apollo/client"

//Import Breadcrumb
import FileUploader from "components/Common/FileUploader"

const PropertyCreateRule = (props) => {
  const client = useApolloClient()
  const history = useHistory()
  const [CreateCategoryMutation] = useMutation(Insert_PropertyRules_One)
  const [UpdateCategoryMutation] = useMutation(Update_PropertyRules_One)
  const [vehicleTypeValue, setVehicleTypeValue] = useState("")
  const [categoryVehicleType, setCategoryVehicleType] = useState([])

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [isActive, setActive] = useState(true)

  const [btnLoading, setBtnLoading] = useState(false)

  const { loading, data, refetch, error } = useQuery(PropertyRulesTypeList)

  useEffect(() => {
    if (Array.isArray(data?.enum_property_rules_type)) {
      let statusTemp = []
      data?.enum_property_rules_type.map((status) => {
        statusTemp.push({
          label: status.comments,
          value: status.value,
        })
      })
      setCategoryVehicleType(statusTemp)
    }
  }, [data])

  const handleCreateCategory = async (e) => {
    try {
      e.preventDefault()
      setBtnLoading(true)
      if (props.category?.id) {
        let objects = {
          rule_name: name,
          description: description,
          rule_type: vehicleTypeValue,
          active: isActive,
        }
        await UpdateCategoryMutation({
          variables: {
            id: props.category?.id,
            object: objects,
          },
        })
      } else {
        await CreateCategoryMutation({
          variables: {
            object: {
              rule_name: name,
              description: description,
              rule_type: vehicleTypeValue,
              active: isActive,
              created_by: '7bc01544-c371-4caa-99fa-2b7897c0a912'
            },
          },
        })
      }
      Swal.fire(
        `Property Type ${
          props.category?.id ? "Updated" : "Created"
        } Successfully`,
        "",
        "success"
      ).then(() => {
        props.onSubmit()
        props.close()
      })
    } catch (err) {
      Swal.fire("Something went wrong", "", "error")
    } finally {
      setBtnLoading(false)
    }
  }
  useEffect(() => {
    if (props.category?.id) {
      setActive(props.category.active)
      setName(props.category.name)
      setVehicleTypeValue(props.category.type)
      setDescription(props.category.description)
    } else {
      setName("")
      setDescription("")
    }
  }, [props.category])

  return (
    <Modal open={props.open} onClose={() => props.close()}>
      <Modal.Header>
        <Modal.Title>
          {props.category?.id ? "Update Property Rule" : "Create Property Rule"}
        </Modal.Title>
      </Modal.Header>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Form id="categoryform" onSubmit={handleCreateCategory}>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="category_name">Property Rule</Label>
                      <Input
                        id="category_name"
                        name="category_name"
                        defaultValue={name}
                        type="text"
                        className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="category_name">Description</Label>
                      <Input
                        id="category_name"
                        name="category_name"
                        defaultValue={description}
                        type="text"
                        className="form-control"
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="category_group">Rule Type</Label>
                      <br />
                      <SelectPicker
                        data={categoryVehicleType}
                        placeholder="Select Amenity Type"
                        onChange={(value) => setVehicleTypeValue(value)}
                        value={vehicleTypeValue}
                        defaultValue={vehicleTypeValue}
                        searchable={false}
                        block
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="commission_percentage">Active</Label>
                      <Toggle
                        checked={isActive}
                        onChange={(checked) => setActive(checked)}
                        style={{ marginLeft: "1rem" }}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    color="primary"
                    className="btn "
                    type="submit"
                    disabled={btnLoading}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className=" "
                    onClick={(e) => props.close()}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}
PropertyCreateRule.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onSubmit: PropTypes.func,
  category: PropTypes.any,
}
export default withRouter(PropertyCreateRule)
