import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { useRouteMatch } from "react-router-dom"

//import components
import EcommerceShopTable from "../EcommerceShops/EcommerceShopTable"

const MerchantStores = (props) => {
    const match = useRouteMatch()
    const [queryCondition, setQueryCondition] = useState({created_by: { _eq: match?.params?.id }})
    const pathName = props.pathName

    return (
        <div className="p-3">
            <EcommerceShopTable
                queryCondition={queryCondition}
                activeFilters={["store_type", "city", "state", "created_at", "active"]}
                filterQueryConditions={null}
                pathName={pathName}
            />
        </div>
    )
}
MerchantStores.propTypes = {
  location: PropTypes.object,
  pathName: PropTypes.any
}
MerchantStores.displayName = 'MerchantStores'
export default MerchantStores