import React, { useEffect, useState, useRef } from "react"
import * as moment from "moment"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Loader from "../../../components/Loader/Loader"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical } from "react-icons/go"
import { useHistory } from "react-router"
import Highlighter from "react-highlight-words"

import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import Swal from "sweetalert2"
import { SelectPicker } from "rsuite"

//Import Card
// import CardShop from "./CardShop"
import { StoreList } from "gql/query"
import { UpdateStore } from "gql/Mutation"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
//redux
import { useDispatch } from "react-redux"

//Helpers
import isValidUrl from "helpers/url_checker"
import ConvertRangesToQuery from "helpers/range_to_query_helper"
//Filters
import EcommerceShopFilter from "components/Filters/Ecommerce/EcommerceShopFilter"

import { useSelector } from "react-redux"
const FormateShopData = (data) => {
  const shops = data.stores.map((elem) => {
    let image = "/placeholder.jpg"
    if (isValidUrl(elem.photo_url)) {
      image = elem.photo_url
    }
    let docs_submitted = false
    if (
      elem?.gst_photo !== null ||
      elem?.pan_photo !== null ||
      elem?.proof_of_business !== null
    ) {
      docs_submitted = true
    }

    return {
      id: elem.id,
      active: elem.active,
      status: elem.status,
      name: elem.name,
      shop_logo: image,
      phoneNumber: elem.phone,
      email: elem.email,
      state: elem.state,
      city: elem.city,
      companyId: elem.company_id,
      gstno: elem.business_gstno || "N/A",
      pan: elem.business_pan,
      merchant_id: elem.vendor_company?.merchant_id || "N/A",
      merchant_uuid: elem.vendor_company?.created_by,
      shop_type: elem.store_type,
      no_of_products: elem.products_aggregate?.aggregate?.count || 0,
      total_sales: elem.orders_aggregate?.aggregate?.sum?.total_amount || 0,
      no_of_orders: elem.orders_aggregate?.aggregate?.count || 0,
      created_at: elem.created_at,
      shop_level: 1,
      store_id: elem.store_id,
      docs_submitted,
      docs_verified: elem?.status === "verified" ? true : false,
    }
  })
  return shops
}

const EcommerceShopTable = (props) => {
  const pathName = props.pathName
  const { capabilities } = useSelector((state) => state.Login)
  const history = useHistory()
  const { ExportCSVButton } = CSVExport
  var node = useRef()
  const dispatch = useDispatch()
  const [shops, setShops] = useState([])
  const [modal, setModal] = useState(false)
  const [PageData, setPageData] = useState({
    limit: 50,
    pageNo: 1,
  })
  const [filterValue, setFilterValue] = useState({})
  const [queryCondition, setQueryCondition] = useState({})
  const [searchKey, setSearchKey] = useState("")
  const limitData = [
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "200", value: 200 },
    { label: "500", value: 500 },
  ]

  const [getShopList, { data, loading, error, refetch: storeListRefetch }] =
    useLazyQuery(StoreList, {
      skipCache: true,
      variables: {
        limit: PageData.limit,
        offset: (PageData.pageNo - 1) * PageData.limit,
        where: queryCondition,
      },
    })
  const handleSearch = (value) => {
    if (value.length >= 3) {
      let conditionTemp = {}
      if (props.queryCondition !== null) {
        conditionTemp = { ...props.queryCondition }
      }
      conditionTemp = {
        ...conditionTemp,
        _or: [
          { name: { _ilike: `%${value}%` } },
          { store_type: { _ilike: `%${value}%` } },
          { phone: { _ilike: `%${value}%` } },
          { vendor_company: { merchant_id: { _ilike: `%${value}%` } } },
          { city: { _ilike: `%${value}%` } },
          { state: { _ilike: `%${value}%` } },
        ],
      }
      setQueryCondition(conditionTemp)
    } else {
      let conditionTemp = {}
      if (props.queryCondition !== null) {
        conditionTemp = { ...props.queryCondition }
      }
      setQueryCondition(conditionTemp)
    }
  }

  const count = data?.stores_aggregate?.aggregate?.count || 0
  const maxPage = Math.ceil(count / PageData.limit)

  const PageDown = () => {
    if (PageData.pageNo > 0)
      setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
  }
  const PageUp = () => {
    setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
  }
  const defaultSorted = []
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
  }
  const toggle = () => {
    setModal(!modal)
  }
  const handleTableChange = (type, { page, searchText }) => {
    setCustomerList(
      customers.filter((customer) =>
        Object.keys(customer).some((key) =>
          customer[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }
  useEffect(() => {
    if (!loading && !error && data) {
      setShops(FormateShopData(data))
    }
  }, [data])

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD/MM/YYYY h:mm A")
    return date1
  }

  const [updateStoreMutation] = useMutation(UpdateStore)

  const handleChangeStatus = async (storeId, status) => {
    try {
      Swal.fire({
        title: "Are you sure want to change the status?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await updateStoreMutation({
            variables: {
              id: storeId,
              object: {
                status,
              },
            },
          })
          storeListRefetch()
        }
      })
    } catch (err) {
      console.log(err)
    }
  }
  const formatHighlighter = (text) => {
    let searchWords = [searchKey]
    if (Array.isArray(filterValue?.city)) {
      searchWords = [...searchWords, ...filterValue?.city]
    }
    if (Array.isArray(filterValue?.state)) {
      searchWords = [...searchWords, ...filterValue?.state]
    }
    if (Array.isArray(filterValue?.store_type)) {
      searchWords = [...searchWords, ...filterValue?.store_type]
    }
    return (
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={searchWords}
        autoEscape={true}
        textToHighlight={String(text)}
        highlightStyle={{ background: "#ff0" }}
      />
    )
  }
  const EcommerceShopsColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "store_id",
      text: "Shop ID",
      sort: false,
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <Link
              to={
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-shops/details/${row.id}`
                  : pathName.startsWith("/services")
                  ? `/services/service-shops/details/${row.id}`
                  : pathName.startsWith("/vehicles")
                  ? `/vehicles/rental-shops/details/${row.id}`
                  : pathName.startsWith("/property")
                  ? `/property/property-shops/details/${row.id}`
                  : pathName.startsWith("/experience")
                  ? `/experience/experience-shops/details/${row.id}`
                  : null
              }
            >
              {formatHighlighter(cellContent)}
            </Link>
          )
        } else {
          return formatHighlighter(cellContent)
        }
      },
    },
    {
      dataField: "merchant_id",
      text: "Merchant ID",
      sort: false,
      formatter: (cellContent, row) => {
        if (row.id) {
          return (
            <Link
              to={
                pathName.startsWith("/groceries")
                  ? `/groceries/ecommerce-merchants/details/${row.merchant_uuid}`
                  : pathName.startsWith("/services")
                  ? `/services/service-merchants/details/${row.merchant_uuid}`
                  : pathName.startsWith("/property")
                  ? `/property/property-merchants/details/${row.merchant_uuid}`
                  : pathName.startsWith("/experience")
                  ? `/experience/experience-merchants/details/${row.merchant_uuid}`
                  : ""
              }
            >
              {formatHighlighter(row.merchant_id)}
            </Link>
          )
        } else {
          return formatHighlighter(row.merchant_id)
        }
      },
    },
    {
      dataField: "gstno",
      text: "GST",
      sort: true,
    },
    {
      dataField: "name",
      text: "Shop Name",
      sort: true,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      dataField: "shop_logo",
      text: "Shop Logo",
      sort: false,
      formatter: (cellContent, row) => (
        <img src={row.shop_logo} style={{ height: 50 }} />
      ),
    },
    {
      dataField: "shop_type",
      text: "Shop Type",
      sort: true,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      dataField: "no_of_products",
      text: "No. of Products",
      sort: true,
    },
    {
      dataField: "no_of_orders",
      text: "No. of Orders",
      sort: true,
    },
    {
      dataField: "total_sales",
      text: "Total Sales",
      sort: true,
      formatter: (cellContent, row) => `₹${row.total_sales}`,
    },
    {
      dataField: "phoneNumber",
      text: "Phone",
      sort: true,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      text: "Shop Level",
      dataField: "shop_level",
      sort: false,
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.created_at),
    },
    {
      text: "Docs Submitted",
      dataField: "docs_submitted",
      sort: false,
      formatter: (cellContent, row) => <>{row.docs_submitted ? "Yes" : "No"}</>,
    },
    {
      text: "Docs Verified",
      dataField: "docs_verified",
      sort: false,
      formatter: (cellContent, row) => <>{row.docs_verified ? "Yes" : "No"}</>,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: (cellContent, row) => formatHighlighter(cellContent),
    },
    {
      dataField: "active",
      text: "Active",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <p style={{ color: row.status ? "" : "#F93B55" }}>
          {row.status == true ? "Yes" : "No"}
        </p>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Action",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Dropdown>
          <Dropdown.Toggle variant="transparent" id="dropdown-basic">
            <GoKebabVertical />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                history.push(
                  pathName.startsWith("/groceries")
                    ? `/groceries/ecommerce-shops/details/${row.id}`
                    : pathName.startsWith("/services")
                    ? `/services/service-shops/details/${row.id}`
                    : pathName.startsWith("/vehicles")
                    ? `/vehicles/rental-shops/details/${row.id}`
                    : pathName.startsWith("/property")
                    ? `/property/property-shops/details/${row.id}`
                    : pathName.startsWith("/experience")
                    ? `/experience/experience-shops/details/${row.id}`
                    : null
                )
              }
            >
              View Details
            </Dropdown.Item>
            {capabilities.includes("edit") && (
              <>
                {row.status === "pending" ? (
                  <>
                    <Dropdown.Item
                      onClick={() => handleChangeStatus(row.id, "verified")}
                    >
                      Approve
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleChangeStatus(row.id, "rejected")}
                    >
                      Reject
                    </Dropdown.Item>
                  </>
                ) : (
                  <Dropdown.Item
                    onClick={() =>
                      handleChangeStatus(
                        row.id,
                        row.status === "verified" ? "rejected" : "verified"
                      )
                    }
                  >
                    {row.status === "verified" ? "Reject" : "Approve"}
                  </Dropdown.Item>
                )}
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ]
  //Apply filters
  useEffect(() => {
    if (Object.keys(filterValue).length) {
      let conditionTemp = {}
      if (props.queryCondition !== null) {
        conditionTemp = { ...props.queryCondition }
      }
      if (filterValue?.hasOwnProperty("store_type")) {
        if (filterValue.store_type?.length > 0) {
          conditionTemp = {
            ...conditionTemp,
            store_type: { _in: filterValue.store_type },
          }
        }
      }
      if (filterValue?.hasOwnProperty("created_at")) {
        if (Array.isArray(filterValue.created_at)) {
          conditionTemp = {
            ...conditionTemp,
            _and: [
              { created_at: { _gte: filterValue.created_at[0] } },
              { created_at: { _lte: filterValue.created_at[1] } },
            ],
          }
        }
      }
      if (filterValue?.hasOwnProperty("active")) {
        if (filterValue.active?.length > 0) {
          conditionTemp = {
            ...conditionTemp,
            active: { _in: filterValue.active },
          }
        }
      }
      if (filterValue?.hasOwnProperty("city")) {
        if (filterValue.city?.length > 0) {
          conditionTemp = {
            ...conditionTemp,
            city: { _in: filterValue.city },
          }
        }
      }
      if (filterValue?.hasOwnProperty("state")) {
        if (filterValue.state?.length > 0) {
          conditionTemp = {
            ...conditionTemp,
            state: { _in: filterValue.state },
          }
        }
      }
      if (filterValue?.hasOwnProperty("total_sales")) {
        if (filterValue.total_sales?.length > 0) {
          let totalSalesFilter = ConvertRangesToQuery(
            filterValue.total_sales,
            "total_sales"
          )
          conditionTemp = {
            ...conditionTemp,
            _or: totalSalesFilter,
          }
        }
      }
      if (filterValue?.hasOwnProperty("total_orders")) {
        if (filterValue.total_orders?.length > 0) {
          let totalOrdersFilter = ConvertRangesToQuery(
            filterValue.total_orders,
            "total_orders"
          )
          conditionTemp = {
            ...conditionTemp,
            _or: totalOrdersFilter,
          }
        }
      }
      if (filterValue?.hasOwnProperty("total_products")) {
        if (filterValue.total_products?.length > 0) {
          let totalProductsFilter = ConvertRangesToQuery(
            filterValue.total_products,
            "total_products"
          )
          conditionTemp = {
            ...conditionTemp,
            _or: totalProductsFilter,
          }
        }
      }
      setQueryCondition(conditionTemp)
    } else {
      let conditionTemp = {}
      if (props.queryCondition !== null) {
        conditionTemp = { ...props.queryCondition }
      }
      setQueryCondition(conditionTemp)
    }
  }, [filterValue])
  useEffect(() => {
    if (props.queryCondition !== null) {
      setQueryCondition(props.queryCondition)
    } else {
      getShopList()
    }
  }, [props.queryCondition])
  useEffect(() => {
    if (Object.keys(queryCondition).length) {
      getShopList()
    }
  }, [queryCondition])
  useEffect(() => {
    if (searchKey.length > 0) {
      handleSearch(searchKey)
    } else {
      storeListRefetch()
    }
  }, [searchKey])
  return (
    <Row>
      <Col xs="12">
        <Card>
          <CardBody>
            <ToolkitProvider
              keyField="id"
              data={shops || []}
              columns={EcommerceShopsColumns}
              bootstrap4
              // search
              exportCSV={{ onlyExportSelection: true, exportAll: true }}
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box ms-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault()
                            }}
                          >
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <i className="bx bx-search-alt search-icon" />
                              <Form.Control
                                type="text"
                                placeholder="Search shops"
                                onChange={(e) => setSearchKey(e.target.value)}
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end d-flex justify-content-end">
                        {capabilities.includes("export") && (
                          <ExportCSVButton
                            {...toolkitProps.csvProps}
                            style={{
                              border: "1px solid #74788d",
                              marginRight: 10,
                            }}
                          >
                            Export
                          </ExportCSVButton>
                        )}
                        <EcommerceShopFilter
                          onFilterChange={setFilterValue}
                          activeFilters={props.activeFilters}
                          filterQueryConditions={props.filterQueryConditions}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <b>No. of Shops:</b> {count}
                        </div>
                        <div>
                          <SelectPicker
                            data={limitData}
                            searchable={false}
                            style={{ width: 100 }}
                            placeholder="Limit"
                            value={PageData.limit}
                            onChange={(value) =>
                              setPageData({ ...PageData, limit: value })
                            }
                            cleanable={false}
                          />
                        </div>
                      </div>
                      <div className="mb-3" />
                      <div className="table-responsive">
                        {loading ? (
                          <Loader />
                        ) : (
                          <BootstrapTable
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            keyField="id"
                            {...toolkitProps.baseProps}
                            onTableChange={handleTableChange}
                            // {...paginationTableProps}
                            ref={node}
                            selectRow={selectRow}
                            noDataIndication={() => "No Shops Found!"}
                            key={`${searchKey}_${JSON.stringify(filterValue)}`}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="align-items-md-center mt-30">
                    <Col className="pagination pagination-rounded align-items-center justify-content-between mb-2 inner-custom-pagination">
                      <Button
                        disabled={PageData.pageNo <= 1}
                        onClick={() => {
                          PageDown()
                        }}
                      >
                        Previous Page
                      </Button>
                      <div>{`Page No. ${PageData.pageNo} of ${maxPage}`} </div>
                      <Button
                        disabled={PageData.pageNo >= maxPage}
                        onClick={() => {
                          PageUp()
                        }}
                      >
                        Next Page
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
EcommerceShopTable.propTypes = {
  queryCondition: PropTypes.any,
  activeFilters: PropTypes.array,
  filterQueryConditions: PropTypes.any,
  pathName: PropTypes.any,
}
export default EcommerceShopTable
