import React, { useEffect, useState, useRef } from "react"
import PropTypes from 'prop-types'
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
} from "reactstrap"
import moment from "moment"
import { useRouteMatch } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { CheckPicker } from 'rsuite'
import Loader from "../../../components/Loader/Loader"
import { Dropdown } from "react-bootstrap"
import { GoKebabVertical } from 'react-icons/go'
//Helpers
import isValidUrl from "../../../helpers/url_checker"
//GraphQL
import { MerchantProductList } from "gql/query"
import { useQuery } from "@apollo/client"

const MerchantStaffs = (props) => {
	const FormatData = (data) => {
		return []
	}
	const staffColumns = [
		{
			text: "id",
			dataField: "id",
			sort: true,
			hidden: true,
			// eslint-disable-next-line react/display-name
		},
		{
			text: "Name",
			dataField: "name",
			sort: true,
		},
		{
			dataField: "phone",
			text: "Phone",
			sort: false,
		},
		{
			dataField: "role",
			text: "Role",
			sort: true,
		},
		{
			dataField: "shop_name",
			text: "Shop Name",
			sort: true,
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
	]
	const match = useRouteMatch()
	const defaultSorted = []
	const [products, setProducts] = useState([])
	const [PageData, setPageData] = useState({
		limit: 10,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({ created_by: { _eq: match?.params?.id } })
	const [storesData, setStoresData] = useState([])
	const [selectedStores, setSelectedStores] = useState([])
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}

	// const { data, loading, error } = useQuery(MerchantProductList, {
	// 	skipCache: true,
	// 	variables: {
	// 		limit: PageData.limit,
	// 		offset: (PageData.pageNo - 1) * PageData.limit,
	// 		where: queryCondition
	// 	}
	// })
	const count = 0
	// const count = data?.products_aggregate?.aggregate?.count
	const maxPage = Math.ceil(count / PageData.limit)
	// useEffect(() => {
	// 	if (data) {
	// 		setProducts(FormatData(data))
	// 	}
	// }, [data])
	useEffect(()=>{
		let storesTemp = []
		props.stores?.map((store)=>{
			storesTemp.push({
				label: store.name,
				value: store.id
			})
		})
		setStoresData(storesTemp)
	}, [props.stores])
	useEffect(()=>{
		if(selectedStores.length){
			setQueryCondition({
				...queryCondition,
				store_id: { _in: selectedStores }
			})
		}else{
			setQueryCondition({ created_by: { _eq: match?.params?.id } })
		}
	}, [selectedStores])
	useEffect(()=>{
		if(props.selectedStores?.stores?.length > 0 && props.selectedStores?.target==="products"){
			setSelectedStores(props.selectedStores.stores)
		}
	}, [props.selectedStores])
	return (
		<div className="p-3">
			<Row className="mb-5">
				<Row className="mb-2">
					<Col sm="8">
						<h4>No. of Staffs: {count}</h4>
					</Col>
					<Col sm="4">
						<div className="text-sm-end d-flex justify-content-end">
							<CheckPicker 
								data={storesData} 
								placeholder="Select Store" 
								style={{ width: 224 }} 
								value={selectedStores}
								onChange={setSelectedStores}
								searchable={false}
							/>
						</div>
					</Col>
				</Row>
				<hr />
				<ToolkitProvider
					keyField="id"
					data={[]}
					columns={staffColumns}
					bootstrap4
				>
					{toolkitProps => (
						<>
							<Row>
								<Col xl="12">
									<div className="table-responsive">
										{false ? <Loader /> :
											<BootstrapTable
												responsive
												bordered={false}
												striped={false}
												defaultSorted={defaultSorted}
												classes={"table align-middle table-nowrap"}
												keyField="id"
												{...toolkitProps.baseProps}
												onTableChange={() => { }}
												noDataIndication={() => "No Staffs Found!"}
											// {...paginationTableProps}
											/>
										}
									</div>
								</Col>
							</Row>
							<Row className="align-items-md-center mt-40">
								<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
									<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
										Previous Page
									</Button>
									<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
									</div>
									<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
										Next Page
									</Button>
								</Col>
							</Row>
						</>
					)}
				</ToolkitProvider>
			</Row>
		</div>
	)
}
MerchantStaffs.propTypes = {
	stores: PropTypes.array,
	selectedStores: PropTypes.any
}
MerchantStaffs.displayName = 'MerchantStaffs'
export default MerchantStaffs