import React, { useEffect, useState } from "react"
import { CheckPicker, Modal, TagInput, Toggle } from "rsuite"
import { withRouter } from "react-router-dom"
import {
  Insert_Create_AirPort_Rental,
  Insert_Create_Vehicle_Rental,
  Insert_Rental_Filters,
  Insert_Vehicle_Brand_One,
  Insert_vehicle_master_data_One,
  Update_AirPort_Rental,
  Update_Rental_filters,
  Update_Vehicle_Brand_One,
  Update_vehicle_Rental,
  Update_vehicle_master_data_One,
} from "gql/Mutation"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import { SelectPicker } from "rsuite"
import FileUploader from "components/Common/FileUploader"
import axios from "axios"
import PropTypes from "prop-types"
import Swal from "sweetalert2"
import { useLazyQuery, useMutation } from "@apollo/client"
import { VehicleRentalsDriversEnumData, VehicleRentalsFiltersQuery, } from "gql/query"
import { Columns } from "./columns"
import { BuildFields } from "../Common/BuildFields"
import { onChangeHandlerFunction } from "./rentals-filters-.functions"
import { Action } from "../Common/Action"

const AirportRentalsCreate = (props) => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [CreateRentalFilters] = useMutation( Insert_Rental_Filters )
  const [UpdateRentalFilters] = useMutation(Update_Rental_filters)
  const [queryCondition, setQueryCondition] = useState(null)
  const [masterData, setMasterData] = useState({})
 

  const [getVehicleRentalsFitlters, { loading, data, refetch, error }] =
    useLazyQuery(VehicleRentalsFiltersQuery
    , {
      skipCache: false,
      variables: {
        limit: 1,
        where: { id: { _eq: props?.masterData?.id } },
      },
    })

    
  const [
    getVehicleMasterDataEnumList,
    {
      loading: EnumLoading,
      data: EnumData,
      refetch: enumRefetch,
      error: Enumerror,
    },
  ] = useLazyQuery(VehicleRentalsDriversEnumData, {
    skipCache: false,
  })
  
  useEffect(() => {
    if (!queryCondition) setQueryCondition(props?.masterData?.id)
    else getVehicleRentalsFitlters()
    getVehicleMasterDataEnumList()
  }, [props?.masterData?.id, queryCondition])

  //If rentals data exists, merge values
  useEffect(() => {
    //Add vlaues to the all columns
    if (data?.rental_filters?.length > 0) {
      let airports_rentals = data?.rental_filters?.[0]

      Object.keys(Columns)?.map((key, i) => {
        let obj = Columns?.[key]

        //If it is an array of columns
        if (
          key &&
          airports_rentals?.[key] &&
          Object.keys(airports_rentals?.[key])?.length > 1 &&
          Array.isArray(Columns?.[key])
        ) {
          Columns[key]?.forEach((e, index) => {
            Columns[key][index] = {
              ...e,
              ...{ value: airports_rentals[key][e.name] },
            }
          })
        }

        //If it is an object of column and data is not an array
        else if (
          !Array.isArray(airports_rentals?.[key]) &&
          !Array.isArray(Columns?.[key])
        ) {
          if (obj?.type == "typehead") {
            let field = Columns?.[key]?.access
            Columns[key] = {
              ...Columns?.[key],
              label: airports_rentals?.[field]?.[Columns[key]?.acessLabel],
              value: airports_rentals?.[field]?.[Columns[key]?.acessValue],
            }
          } else {
            Columns[key] = {
              ...Columns?.[key],
              value: airports_rentals?.[key],
            }
          }
        }
        //If it an object of column and existing data is not array
        else if (
          Array.isArray(airports_rentals?.[key]) &&
          !Array.isArray(Columns?.[key])
        ) {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        } else if (
          Array.isArray(airports_rentals?.[key]) &&
          !Array.isArray(Columns?.[key])
        ) {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        } else {
          Columns[key] = {
            ...Columns?.[key],
            value: airports_rentals?.[key],
          }
        }
      })
    }

    setMasterData({ ...Columns })
  }, [data?.rental_filters?.length])

  

  //Submit or update the master data
  const handleRentalFilters = async (event) => {
    event.preventDefault()
    let newObj = {}
    let user = JSON.parse(localStorage.getItem("authUser"))

 
    Object.keys(masterData)?.map((e, i) => {
      masterData?.[e]?.length > 0
        ? [...masterData[e]] ?.filter((ee) => ee) ?.map((ei, ii) => { newObj[e] = { ...newObj?.[e], [ei?.name]: ei?.value } })
        : (newObj[e] = masterData?.[e]?.value)
    })
 

    newObj ={
      ...newObj, 
      created_by:user.id,
      updated_by:user.id
    }

    setBtnLoading(true)

    const Result  = await Action({entity:"Rental Filters",
                                  id : props?.masterData?.id , 
                                  updateQuery:UpdateRentalFilters,
                                  createQuery : CreateRentalFilters,
                                  object:newObj,
                                  submit:props?.onSubmit,
                                  close:props?.close()
                              })

                              setBtnLoading(false)
    console.log("Result is", Result)

  
  }
 

 
  return (
    <Modal
      open={props.open}
      onClose={() => props.close()}
      style={{ width: "100vw", margin: "0 auto" }}
    >
      <Modal.Header>
        <Modal.Title className="mb-2">
          {props.brand?.id ? "UPDATE Filter" : "CREATE Filter"}
        </Modal.Title>
      </Modal.Header>
      <nav >
        <form onSubmit={handleRentalFilters}>
        <div className="form-group" onChange={(e) => onChangeHandlerFunction({e,masterData,setMasterData })}>
            <Row>
              {Object.keys(masterData)?.map((e, i) => {
                return !Array.isArray(masterData?.[e])
                  ? 
                  BuildFields({naming:e, field:masterData?.[e], ind:null,EnumData,masterData, setMasterData,onChangeHandler:onChangeHandlerFunction})
                  : 
                  [...masterData[e]] ?.filter((ee) => ee) ?.map((ei, i) => BuildFields({naming:e, field:ei, ind:i,EnumData,masterData,setMasterData,onChangeHandler:onChangeHandlerFunction}))

              })}
            </Row>
          </div>
          <button type="submit">submit</button>
        </form>
      </nav>
    </Modal>
  )
}

AirportRentalsCreate.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  brand: PropTypes.any,
  onSubmit: PropTypes.func,
  masterData: PropTypes.any,
}
export default withRouter(AirportRentalsCreate)
