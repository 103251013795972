import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Link, withRouter, useHistory } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import { SelectPicker } from 'rsuite'
import Loader from "../../../../components/Loader/Loader"
import {
	Button,
	Card,
	CardBody,
	Col,
	Row,
} from "reactstrap"
import moment from "moment"
import { Dropdown, Form } from "react-bootstrap"
import { GoKebabVertical, GoPlus } from 'react-icons/go'

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { EcommerceCreateSubCategory } from "pages/Ecommerce/SubCategories/index"

//redux
import { useQuery, useLazyQuery } from "@apollo/client"
import { SubCategoryList } from "gql/query"

//filters
// import EcommerceProductsFilter from "components/Filters/Ecommerce/EcommerceProductsFilter"

import { useSelector } from "react-redux"

const FormatSubCategoryData = (data) => {
	const subcategories = data.subcategories.map(elem => {
		let image = "/placeholder.jpg"
		return {
			id: elem.id,
			name: elem.name,
			created_at: elem.created_at,
			products_count: elem.master_products_aggregate?.aggregate?.count || 0,
			category_name: elem.category?.name || "N/A",
			category_id: elem.category?.id,
			active: elem.active
		}
	})
	return subcategories
}

const EcommerceSubCategoriesTable = props => {
	const { capabilities } = useSelector((state) => state.Login)
	const [modal, setModal] = useState(false)
	const history = useHistory()
	const [subcategories, setSubCategories] = useState([])
	const [PageData, setPageData] = useState({
		limit: 50,
		pageNo: 1
	})
	const [queryCondition, setQueryCondition] = useState({})
	const [filterValue, setFilterValue] = useState("")
	const [subcategoryModalOpen, setSubcategoryOpen] = useState(false)
	const [selectedSubCategory, setSelectedSubCategory] = useState({})
	const limitData = [{ label: "50", value: 50 }, { label: "100", value: 100 }, { label: "200", value: 200 }, { label: "500", value: 500 }]

	const [getSubCategoryList, { loading, data, refetch, error }] = useLazyQuery(SubCategoryList, {
		skipCache: true,
		variables: {
			limit: PageData.limit,
			offset: (PageData.pageNo - 1) * PageData.limit,
			where: queryCondition
		}
	})
	const handleSearch = (e) => {
		let value = e.target.value
		if (value.length >= 3) {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			conditionTemp = {
				...conditionTemp,
				_or: [
					{ name: { _ilike: `%${value}%` } },
					{ category: { name: { _ilike: `%${value}%` } } },
				]
			}
			setQueryCondition(conditionTemp)
		} else {
			let conditionTemp = {}
			if (props.queryCondition !== null) {
				conditionTemp = { ...props.queryCondition }
			}
			setQueryCondition(conditionTemp)
			refetch()
		}
	}
	const PageDown = () => {
		if (PageData.pageNo > 0)
			setPageData({ ...PageData, pageNo: PageData.pageNo - 1 })
	}
	const PageUp = () => {
		setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
	}
	const toggle = () => {
		setModal(!modal)
	}
	useEffect(() => {
		if (data) {
			setSubCategories(FormatSubCategoryData(data))
		}
	}, [data])
	const defaultSorted = []
	const count = data?.subcategories_aggregate?.aggregate?.count || 0
	const maxPage = Math.ceil(count / PageData.limit)

	const handleTableChange = (type, { page, searchText }) => {

	}
	const EcommerceSubCategoryColumns = (toggleModal) => [
		{
			text: "ID",
			dataField: "id",
			sort: false,
			// formatter: (cellContent, row) => <Link to={`/ecommerce-categories/details/${cellContent}`}>{cellContent}</Link>
		},
		{
			text: "Sub Category Name",
			dataField: "name",
			sort: true,
		},
		{
			text: "Category Name",
			dataField: "category_name",
			sort: true,
		},
		{
			text: "No. of Products",
			dataField: "products_count",
			sort: false
		},
		{
			text: "Created At",
			dataField: "created_at",
			sort: false,
			formatter: (cellContent, row) => moment(row.created_at).format("DD/MM/YYYY h:mm A"),
		},
		{
			dataField: "view",
			isDummyField: true,
			text: "Action",
			sort: false,
			hidden: !capabilities.includes("edit"),
			// eslint-disable-next-line react/display-name
			formatter: (cellContent, row) => (
				<Dropdown>
					<Dropdown.Toggle variant="transparent" id="dropdown-basic">
						<GoKebabVertical />
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item onClick={() => {
							setSelectedSubCategory(row)
							setSubcategoryOpen(true)
						}}>Edit</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			),
		},
	]
	useEffect(() => {
		if (props.queryCondition !== null) {
			setQueryCondition(props.queryCondition)
		} else {
			getSubCategoryList()
		}
	}, [props.queryCondition])
	useEffect(() => {
		if (Object.keys(queryCondition).length) {
			getSubCategoryList()
		}
	}, [queryCondition])
	var node = useRef()
	return (
		<>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<ToolkitProvider
								keyField="id"
								data={subcategories || []}
								columns={EcommerceSubCategoryColumns(toggle)}
								bootstrap4
								search
							>
								{toolkitProps => (
									<React.Fragment>
										<Row className="mb-2">
											<Col sm="4">
												<div className="search-box ms-2 mb-2 d-inline-block">
													<div className="position-relative">
														<Form onSubmit={(e) => {
															e.preventDefault()
														}}>
															<Form.Group className="mb-3" controlId="formBasicEmail">
																<i className="bx bx-search-alt search-icon" />
																<Form.Control type="text" placeholder="Search Sub Categories" onChange={handleSearch} />
															</Form.Group>
														</Form>

													</div>
												</div>
											</Col>
											{(props.showCreateBtn && capabilities.includes("create")) && (
												<Col sm="8">
													<div className="text-sm-end d-flex justify-content-end">
														{/* <Link to="/ecommerce-categories/create"> */}
														<Button color="primary" onClick={() => setSubcategoryOpen(true)}>
															<GoPlus style={{ marginRight: 5 }} />
															Create Sub Category
														</Button>
														{/* </Link> */}
														{/* <EcommerceProductsFilter
													onFilterChange={setFilterValue}
													activeFilters={props.activeFilters}
													filterQueryConditions={props.filterQueryConditions}
												/> */}
													</div>
												</Col>
											)}
										</Row>
										<Row>
											<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
												<SelectPicker
													data={limitData}
													searchable={false}
													style={{ width: 100 }}
													placeholder="Limit"
													value={PageData.limit}
													onChange={(value) => setPageData({ ...PageData, limit: value })}
													cleanable={false}
												/>
											</div>
										</Row>
										<Row>
											<Col xl="12">
												<div className="table-responsive">
													{loading ? <Loader /> :
														<BootstrapTable
															responsive
															bordered={false}
															striped={false}
															defaultSorted={defaultSorted}
															classes={"table align-middle table-nowrap"}
															keyField="id"
															{...toolkitProps.baseProps}
															onTableChange={handleTableChange}
															ref={node}
															noDataIndication={() => "No Sub Categories Found!"}
														/>
													}
												</div>
											</Col>
										</Row>
										<Row className="align-items-md-center mt-40">
											<Col className="pagination pagination-rounded  align-items-center justify-content-between mb-2 inner-custom-pagination">
												<Button disabled={PageData.pageNo <= 1} onClick={() => { PageDown() }}>
													Previous Page
												</Button>
												<div>{`Page No. ${PageData.pageNo} of ${maxPage}`}
												</div>
												<Button disabled={PageData.pageNo >= maxPage} onClick={() => { PageUp() }}>
													Next Page
												</Button>
											</Col>
										</Row>
									</React.Fragment>
								)}
							</ToolkitProvider>
						</CardBody>
					</Card>
				</Col>
			</Row>
			{capabilities.includes("create") && (
				<EcommerceCreateSubCategory
					open={subcategoryModalOpen}
					close={() => {
						setSelectedSubCategory({})
						setSubcategoryOpen(false)
					}}
					onSubmit={() => refetch()}
					subcategory={selectedSubCategory}
				/>
			)}
		</>
	)
}

EcommerceSubCategoriesTable.propTypes = {
	queryCondition: PropTypes.any,
	activeFilters: PropTypes.array,
	filterQueryConditions: PropTypes.any,
	showCreateBtn: PropTypes.bool
}

export default withRouter(EcommerceSubCategoriesTable)
