import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from 'prop-types'
import { Modal, Toggle } from "rsuite"
import axios from "axios"
import Swal from "sweetalert2"
import {
	Button,
	Card,
	CardBody,
	Col,
	Form,
	Input,
	Label,
	Row
} from "reactstrap"
import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"

//GraphQL
import { Insert_Category_One, Update_Category_One, Insert_Commission_One, Update_Commission_One } from "gql/Mutation"
import {CategoryGroupList, GetCommission} from "gql/query"
import { useMutation, useApolloClient } from "@apollo/client"

//Import Breadcrumb
import FileUploader from "components/Common/FileUploader"

const EcommerceCreateCategory = (props) => {
	const client = useApolloClient()
	const history = useHistory()
	const [CreateCategoryMutation] = useMutation(Insert_Category_One)
	const [UpdateCategoryMutation] = useMutation(Update_Category_One)
	const [CreateCommissionMutation] = useMutation(Insert_Commission_One)
	const [UpdateCommissionMutation] = useMutation(Update_Commission_One)

	const [name, setName] = useState("")
	const [commissionPercentage, setCommissionPercentage] = useState("")
	const [categoryGroup, setCategoryGroup] = useState("")
	const [image, setImage] = useState(null)
	const [isActive, setActive] = useState(true)

	const [btnLoading, setBtnLoading] = useState(false)

	const handleUploadFile = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()
				formData.append("uploads", image[0])
				const resp = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`, formData)
				if (resp.data?.success) {
					if (Array.isArray(resp.data?.data?.uploaded_files)) {
						const link = resp.data.data.uploaded_files[0].uploaded_file_url
						resolve(link)
					}
				} else {
					resolve(null)
				}
			} catch (err) {
				resolve(null)
			}
		})
	}
	const handleUpdateCommission = async(categoryId) =>{
		const { data } = await client.query({
			query: GetCommission,
			skipCache: true,
			variables: {
				where: {
					category_id: {
						_eq: categoryId
					}
				}
			}
		})
 		if(data.commissions?.length > 0){
			const commissionId = data.commissions[0]?.id
			await UpdateCommissionMutation({
				variables: {
					id:commissionId,
					object: {
						commission_value: commissionPercentage
					}
				},
			})
		}else{
			await CreateCommissionMutation({
				variables: {
					object: {
						category_id: categoryId,
						commission_type: "percentage",
						commission_value: commissionPercentage,
					}
				},
			})
		}
	}
	const handleCreateCategory = async (e) => {
		try {
			e.preventDefault()
			setBtnLoading(true)
			let photo_url = null
			if (image) {
				photo_url = await handleUploadFile()
			}
			if(props.category?.id){
				let objects = {
					name,
					category_group_id: categoryGroup,
					active: isActive
				}
				if(photo_url){
					objects = {...objects, logo: photo_url}
				}
				await UpdateCategoryMutation({
					variables: {
						id:props.category?.id,
						object: objects
					},
				})
				await handleUpdateCommission(props.category?.id)
			}else{
				const catResp = await CreateCategoryMutation({
					variables: {
						object: {
							name,
							sort_order: "Asc",
							logo: photo_url,
							category_group_id: categoryGroup,
						}
					},
				})
				if(catResp?.data?.insert_categories_one?.id){
					await handleUpdateCommission(catResp?.data?.insert_categories_one?.id)
				}
			}
			Swal.fire(`Category ${props.category?.id ? "Updated" : "Created"} Successfully`, "", "success").then(() => {
				props.onSubmit()
				props.close()
			})
		} catch (err) {
			Swal.fire("Something went wrong", "", "error")
		} finally {
			setBtnLoading(false)
		}
	}
	useEffect(()=>{
		if(props.category?.id){
			setActive(props.category.active)
			setName(props.category.name)
			setCategoryGroup(props.category.category_group_id)
			setCommissionPercentage(String(props.category.commission_value))
		}else{
			setName("")
			setCategoryGroup("")
			setCommissionPercentage("")
		}
	}, [props.category])
	return (
		<Modal open={props.open} onClose={() => props.close()}>
			<Modal.Header>
				<Modal.Title>{props.category?.id ? "Update Category" : "Create Category"}</Modal.Title>
			</Modal.Header>
			<Row>
				<Col xs="12">
					<Card>
						<CardBody>
							<Form id="categoryform" onSubmit={handleCreateCategory}>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="category_name">Category Name</Label>
											<Input
												id="category_name"
												name="category_name"
												defaultValue={name}
												type="text"
												className="form-control"
												onChange={(e) => setName(e.target.value)}
												required
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="category_group">Category Group</Label>
											<br />
											<SelectPickerPaginate
												query={CategoryGroupList}
												placeholder="Select Category Group"
												value={categoryGroup}
												onChange={setCategoryGroup}
												arrKey="categories_group"
												objectLabel="name"
												objectValue="id"
												style={{ width: "100%" }}
												paginate={false}
												filterCondition={null}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="commission_percentage">Commission (%)</Label>
											<br />
											<Input
												id="commission_percentage"
												name="commission_percentage"
												defaultValue={commissionPercentage}
												type="number"
												className="form-control"
												onChange={(e) => setCommissionPercentage(e.target.value)}
												required
												min={0}
												placeholder="Enter Commission"
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="commission_percentage">Active</Label>
											<Toggle 
												checked={isActive}
												onChange={(checked)=>setActive(checked)}
												style={{marginLeft: "1rem"}}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col sm="12">
										<div className="mb-3">
											<Label htmlFor="category_image">Category Image</Label>
											<FileUploader
												onChange={setImage}
											/>
										</div>
									</Col>
								</Row>
								<div className="d-flex flex-wrap gap-2">
									<Button
										color="primary"
										className="btn "
										type="submit"
										disabled={btnLoading}
									>
										Submit
									</Button>
									<Button
										type="button"
										color="secondary"
										className=" "
										onClick={(e) => props.close()}
									>
										Cancel
									</Button>
								</div>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Modal>
	)
}
EcommerceCreateCategory.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	onSubmit: PropTypes.func,
	category: PropTypes.any
}
export default withRouter(EcommerceCreateCategory)
