import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

import { useSelector } from "react-redux"
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  module,
  ...rest
}) => {
  const { user, modules: allowedModules } = useSelector((state) => state.Login)

   return(
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("TOKEN")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }else if(!isAuthProtected && localStorage.getItem("TOKEN")){
        return (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout 
          isAllowed={module ? allowedModules.includes(module) : true} 
        >
          <Component  {...props}  />
        </Layout>
      )
    }}
  />
)}
Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  module: PropTypes.string
}

export default Authmiddleware
