import React, { useState, useEffect } from "react"
import { CheckPicker, Button as RSButton } from 'rsuite'
import { useQuery, useApolloClient } from "@apollo/client"
import PropTypes from "prop-types"

const CheckPickerPaginate = (props) => {
	const client = useApolloClient()
	const [queryCondition, setQueryCondition] = useState({})
	const [PageData, setPageData] = useState({ 
		limit: 30,
		pageNo: 1
	})
	const [list, setList] = useState([])


	const { data: items, loading, error } = useQuery(props.query, {
		skipCache: true,
		variables: {
			limit: 30,
			offset: 0,
			where: queryCondition
		}
	})
 	const renderPickerFooter = () => {
		if (props.paginate) {
			return (
				<div style={{
					padding: '10px 2px',
					borderTop: '1px solid #e5e5e5'
				}}>
					<RSButton
						style={{
							float: 'right',
							marginRight: 10,
							marginTop: 2
						}}
						appearance="primary"
						size="sm"
						onClick={loadMore}
					>
						Load More
					</RSButton>
				</div>
			)
		} else {
			return null
		}
	}
	const handleSearch = (value) => {
		if (value.length >= 3) {
			setQueryCondition({
				...queryCondition,
				[props.objectLabel]: { _ilike: `%${value}%` },
			})
		} else {
			let conditionTemp = {}
			if (props.filterCondition !== null) {
				conditionTemp = { ...props.filterCondition }
			}
			setQueryCondition(conditionTemp)
		}
	}
	const loadMore = async () => {
		let conditionTemp = {}
		if (props.filterCondition !== null) {
			conditionTemp = { ...props.filterCondition }
		}
		let newPageNo = PageData.pageNo + 1
		const { data } = await client.query({
			query: props.query,
			skipCache: true,
			variables: {
				limit: PageData.limit,
				offset: (newPageNo - 1) * PageData.limit,
				where: conditionTemp
			}
		})
		let temp = []
		if (Array.isArray(data?.[props.arrKey])) {
			data[props.arrKey].map((item) => {
				if(props?.nestedObj){
					temp.push({
						label: item[props.nestedObj][props?.objectLabel],
						value: item[props.nestedObj][props?.objectValue]
					})

				}else{
					temp.push({
						label: item[props.objectLabel],
						value: props.getFullObj ? JSON.stringify(item) : item[props.objectValue]
					})
				}

			})
			setList([...list, ...temp])
			setPageData({ ...PageData, pageNo: PageData.pageNo + 1 })
		}
	}
	useEffect(() => {
		let temp = []
 		if (Array.isArray(items?.[props.arrKey])) {
 			items[props.arrKey]?.map((item) => {
				if(props?.nestedObj){
					temp.push({
						label: item[props.nestedObj][props?.objectLabel],
						value: item[props.nestedObj][props?.objectValue]
					})
				}else{
					temp.push({
						label: item[props.objectLabel],
						value: props.getFullObj ? JSON.stringify(item) : item[props.objectValue],
					})
				}
				
			})
			setList(temp)
		}
	}, [items])
	useEffect(() => {
		if (props.filterCondition !== null) {
			setQueryCondition(props.filterCondition)
		}
	}, [props.filterCondition])
	return (
		<CheckPicker
			{...props}
			data={list}
			placeholder={props.placeholder}
			onSearch={handleSearch}
			value={props.value}
			onChange={props.onChange}
			renderExtraFooter={renderPickerFooter}
			placement="bottomEnd"
		/>
	)
}
CheckPickerPaginate.propTypes = {
	query: PropTypes.any,
	placeholder: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	objectLabel: PropTypes.string,
	objectValue: PropTypes.string,
	arrKey: PropTypes.string,
	paginate: PropTypes.bool,
	filterCondition: PropTypes.any,
	getFullObj: PropTypes.any, 
	nestedObj: PropTypes.any,
}
export default CheckPickerPaginate


