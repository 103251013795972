import React from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Container, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
 import VehicleRentalsList from "./rental-vehicle-type-list"
import RentalVehicleTypeList from "./rental-vehicle-type-list"
//import "../../../../assets/scss/custom.scss"

const RentalVehicleTypes = props => {
  return (
    <>
      {
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Rental Vehicle Types | EEFind </title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs title="Rentals" breadcrumbItem="Rental Vehicle Types" />
              <RentalVehicleTypeList queryCondition={null} activeFilters={["type_name"]} filterQueryConditions={null} showCreateBtn={true} />
            </Container>
          </div>
        </React.Fragment>
      }
    </>
  )
}

RentalVehicleTypes.propTypes = {}
export default withRouter(RentalVehicleTypes)
