import React, { useState, useEffect, useRef } from "react"
import { Link, withRouter, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { Modal, Toggle } from "rsuite"
import axios from "axios"
import Swal from "sweetalert2"
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
// import SelectPickerPaginate from "components/Filters/SelectPickerPaginate"
import { SelectPicker } from "rsuite"

//GraphQL
import {
  Insert_Service_Category_One,
  Update_Service_Category_One,
} from "gql/Mutation"

import { ServiceTypeList } from "gql/query"
import { useQuery, useMutation, useApolloClient } from "@apollo/client"

// import serviceType from "constants/serviceType"

//Import Breadcrumb
import FileUploader from "components/Common/FileUploader"

const ServiceCreateCategory = (props) => {
  const client = useApolloClient()
  const history = useHistory()
  const [CreateCategoryMutation] = useMutation(Insert_Service_Category_One)
  const [UpdateCategoryMutation] = useMutation(Update_Service_Category_One)
  const [name, setName] = useState("")
  const [image, setImage] = useState(null)
  const [isActive, setActive] = useState(false)
  const [serviceTypeValue, setServiceTypeValue] = useState('')
  const [categoryServiceType, setCategoryServiceType] = useState([])

  const [btnLoading, setBtnLoading] = useState(false)

  const {
    loading,
    data: serviceType,
    refetch,
    error,
  } = useQuery(ServiceTypeList)

  useEffect(() => {
    if (Array.isArray(serviceType?.enum_service_type)) {
      let statusTemp = []
      serviceType.enum_service_type.map((status) => {
        statusTemp.push({
          label: status.comments,
          value: status.name,
        })
      })
      setCategoryServiceType(statusTemp)
    }
  }, [serviceType])

  const handleUploadFile = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        let formData = new FormData()
        formData.append("uploads", image[0])
        const resp = await axios.post(
          `${process.env.REACT_APP_SERVICE_BASE_URL}user/userimage/public`,
          formData
        )
        if (resp.data?.success) {
          if (Array.isArray(resp.data?.data?.uploaded_files)) {
            const link = resp.data.data.uploaded_files[0].uploaded_file_url
            resolve(link)
          }
        } else {
          resolve(null)
        }
      } catch (err) {
        resolve(null)
      }
    })
  }
  const handleCreateCategory = async (e) => {
    try {
      e.preventDefault()
      setBtnLoading(true)
      let photo_url = null
      if (image) {
        photo_url = await handleUploadFile()
      }
      if (props.category?.id) {
        let objects = {
          category: name,
          active: isActive,
          service_type: serviceTypeValue,
        }
        if (photo_url) {
          objects = { ...objects, image_url: photo_url }
        }
        await UpdateCategoryMutation({
          variables: {
            id: props.category?.id,
            object: objects,
          },
        })
      } else {
        await CreateCategoryMutation({
          variables: {
            object: {
              category: name,
              service_type: serviceTypeValue,
              image_url: photo_url,
            },
          },
        })
      }
      Swal.fire(
        `Category ${props.category?.id ? "Updated" : "Created"} Successfully`,
        "",
        "success"
      ).then(() => {
        props.onSubmit()
        props.close()
      })
    } catch (err) {
      Swal.fire("Something went wrong", "", "error")
    } finally {
      setBtnLoading(false)
    }
  }
  useEffect(() => {
    if (props.category?.id) {
      setActive(props.category.active)
      setName(props.category.name)
      setServiceTypeValue(props.category.type_name)
    } else {
      setName("")
    }
  }, [props.category])
  return (
    <Modal open={props.open} onClose={() => props.close()}>
      <Modal.Header>
        <Modal.Title>
          {props.category?.id ? "Update Category" : "Create Category"}
        </Modal.Title>
      </Modal.Header>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Form id="categoryform" onSubmit={handleCreateCategory}>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="category_name">Category Name</Label>
                      <Input
                        id="category_name"
                        name="category_name"
                        defaultValue={name}
                        type="text"
                        className="form-control"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label>Active</Label>
                      <Toggle
                        checked={isActive}
                        onChange={(checked) => setActive(checked)}
                        style={{ marginLeft: "1rem" }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="category_group">Service Type</Label>
                      <br />
                      {/* <SelectPickerPaginate
                        placeholder="Select Service Type"
                        value={serviceType}
                        onChange={setCategoryGroup}
                        arrKey="categories_group"
                        objectLabel="label"
                        objectValue="id"
                        style={{ width: "100%" }}
                        paginate={false}
                        filterCondition={null}
                      /> */}
                      <SelectPicker
                        data={categoryServiceType}
                        placeholder="Select Service Type"
                        onChange={(value) => setServiceTypeValue(value)}
                        value={serviceTypeValue}
                        defaultValue={serviceTypeValue}
                        searchable={false}
                        block
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <Label htmlFor="category_image">Category Image</Label>
                      <FileUploader onChange={setImage} />
                    </div>
                  </Col>
                </Row>
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    color="primary"
                    className="btn "
                    type="submit"
                    disabled={btnLoading}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    className=" "
                    onClick={(e) => props.close()}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}
ServiceCreateCategory.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onSubmit: PropTypes.func,
  category: PropTypes.any,
}
export default withRouter(ServiceCreateCategory)
